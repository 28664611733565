import { Component, OnInit, Input, OnChanges, SimpleChanges,Inject,Output,EventEmitter,NgZone } from '@angular/core';
import {WeightService} from '../../services/weight.service';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {BiService} from '../../services/BiService.service';import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {MagellanColorService} from '../../services/magellancolor.service';
import { CloseHoldingsChartModel } from '../../models/close-holdings-chart-model';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import {DateService } from '../../services/date.service';

@Component({
    selector: 'weight-sector-history-weights-sector-chart',
    templateUrl: './weight-sector-history-weights-sector-chart.component.html'
})
export class WeightSectorHistoryWeightsSectorChartComponent implements OnInit {

    chartAttributes: any;
    popupDataSource:any;
    popupTargetDataSource:any;
    popchartAttributes:any;
    chartColorRange: any;
    sectorHistoryWeightsSectorDataSource: any;
    currentIndex:any;
    colors : any ;
    startDate:any;
    chart:any;
    dateFormat: any;
    checkLoadFirstTime: boolean;
    dateFormatUK: boolean;
    dateFormatUSA: boolean;
    asofDates: any;
    timePeriod:any;
    indexMarkers: any;
    colorsRange:string;
    treeMapObject: any;
    selectedSectorChart : string = "icb";
    currencyCode:any="USD";
    SectorHistoryWeightsSectorChartLoader:boolean = true;
    @Output() chartCurentIndexMarker: EventEmitter<string> = new EventEmitter();
    constructor(private weightService: WeightService ,private indexMarkerService:IndexMarkerService, private exportService: ExportToExcelService,private publishIndexMarkerService:PublishIndexMarkerService,
        private dialog: MatDialog,private colorService : MagellanColorService,private biService:BiService,
        private zone:NgZone,private idwChartExportService: IDWChartExportService,private dateService : DateService) {
         
        this.chartAttributes = {                                           
            "showLegend" :"0",
            "exportEnabled": "1",
            "exportShowMenuItem": "0",
            "bgColor": "#ffffff",
            "numberSuffix":"%",
            "paletteColors": "",
            "borderAlpha": "20",
            "showCanvasBorder": "0",
            "usePlotGradientColor": "0",
            "plotBorderAlpha": "10",
            "plotFillAlpha": "100",
            "legendBorderAlpha": "0",
            "legendShadow": "0",
            "showValues": "0",
            "showBorder": "0",
            "showXAxisLine": "1",
            "xAxisLineColor": "#999999",
            "divlineColor": "#999999",
            "divLineDashed": "1",
            "showAlternateHGridColor": "0",
            "drawAnchors":"1",
            "anchorRadius":"5"
        };
    }
    
    
    ngOnInit() {
        this.dateFormat = this.dateService.getDateFormat(); 
            this.dateService.dateFormatPublish().subscribe(dateFormat=>{
                this.dateFormat =  dateFormat;      
                this.changeDateFormat(this.treeMapObject);
                                    
            });
        this.indexMarkers = this.indexMarkerService.getIndexMarkers();
        this.currentIndex = 0;  
        this.colors = this.colorService.getCurrentColorSchema();
        this.colorService.colorsPublish().subscribe(color => {     
            this.colors = color; 
            this.chartAttributes.paletteColors=  this.colors;   
        });
        this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
            this.indexMarkers = result;
            this.chartSetup(this.selectedSectorChart,this.treeMapObject);
        });
            this.SectorHistoryWeightsSectorChartLoader=true;                
    }

    chartInitialize(event){
        return (eve, arg) => { 
            this.zone.run(() => {
                this.chart =eve.sender;
            });  
            
        };
          
      }
    
      events = {
        initialized: this.chartInitialize(event)
    }

    private changeDateFormat(data: any) {
        if (this.dateFormat == "UK") {                  
            for (var i = 0; i < data.icbChartData.holdingscategories[0].category.length; i++) {
              data.icbChartData.holdingscategories[0].category[i].label = this.dateService.changeDateFormatUK(data.icbChartData.holdingscategories[0].category[i].label);
            }          
        }
        else {
          if (this.dateFormat == "USA" && this.checkLoadFirstTime == false) {
            for (var i = 0; i < data.icbChartData.holdingscategories[0].category.length; i++) {
              data.icbChartData.holdingscategories[0].category[i].label = this.dateService.changeDateFormatUSA(data.icbChartData.holdingscategories[0].category[i].label);
            }                
          }                   
        }
        this.checkLoadFirstTime = false;
      }

    carouselPrevious():void{
        var event: any = {eventName: "Sector Sector Weight chart carousel previous", inputParameters: [{Name: "Previous IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() },{Name : "Relevent TimePeriod" , Value: this.timePeriod},{Name: "Relevent As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);
        if(0 == this.currentIndex){
           this.currentIndex = this.indexMarkers.length -1;
        }else{
            this.currentIndex = this.currentIndex - 1;
        }
        this.SectorHistoryWeightsSectorChartLoader = true;
        this.chartCurentIndexMarker.emit(this.indexMarkers[this.currentIndex]);
    }
    
    carouselNext():void{
        var event: any = {eventName: "Sector Sector Weight chart carousel next", inputParameters: [{Name: "Next IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() },{Name : "Relevent TimePeriod" , Value: this.timePeriod},{Name: "Relevent As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);
       if((this.indexMarkers.length -1) == this.currentIndex){
            this.currentIndex = 0;
        }else{
            this.currentIndex = this.currentIndex + 1;
        }
        this.SectorHistoryWeightsSectorChartLoader = true;
        this.chartCurentIndexMarker.emit(this.indexMarkers[this.currentIndex]);
    }

    getCurrentIndex(){
        return this.indexMarkers[this.currentIndex];
    }

    update(closeHoldingsChartModel:CloseHoldingsChartModel,startDate:any,endDate:any,timePeriod:any){   
            this.startDate = startDate;
            this.asofDates = endDate;    
            this.timePeriod = timePeriod;
            this.chartAttributes.paletteColors = this.colors;    
              
            this.chartSetup(this.selectedSectorChart,closeHoldingsChartModel);
        
    }
    onChange(selectedChart,currencyCode){
        this.currencyCode=currencyCode;
        this.selectedSectorChart = selectedChart;  
        this.chartSetup(this.selectedSectorChart,this.treeMapObject);         
    }
    private chartSetup(selectedChart:string,closeHoldingsChartModel: CloseHoldingsChartModel) {
        this.SectorHistoryWeightsSectorChartLoader = false;
        
                this.chartAttributes.paletteColors = this.colors; 
                this.chartAttributes.caption= "FTSE "+this.indexMarkers[this.currentIndex]+"- Sector";  
                    
                    this.treeMapObject = closeHoldingsChartModel;
                    this.checkLoadFirstTime=true; 
                    this.changeDateFormat(this.treeMapObject); 
                    if(selectedChart == "icb" && 0 == this.treeMapObject.icbChartData.holdingsDataset.length){
                        this.sectorHistoryWeightsSectorDataSource = {};
                       }else if (selectedChart == "rgs" && 0 == this.treeMapObject.rgsChartData.holdingsDataset.length){
                        this.sectorHistoryWeightsSectorDataSource= {};
                       }
                       else{
                        this.sectorHistoryWeightsSectorDataSource = {
                            "chart": this.chartAttributes,
                            "categories":(selectedChart == "icb")?this.treeMapObject.icbChartData.holdingscategories:this.treeMapObject.rgsChartData.holdingscategories,
                           "dataset": (selectedChart == "icb")?this.treeMapObject.icbChartData.holdingsDataset:this.treeMapObject.rgsChartData.holdingsDataset,
                        };
                       }
                    
                    this.popchartAttributes = {
                        "showLegend" :"1",
                        "legendPosition":"RIGHT", 
                        "exportEnabled": "1",
                        "exportShowMenuItem": "0",  
                        
                        "bgColor": "#ffffff",
                        "paletteColors": this.colors,
                        "numberSuffix":"%",
                        "borderAlpha": "20",
                        "showCanvasBorder": "0",
                        "usePlotGradientColor": "0",
                        "plotBorderAlpha": "10",
                        "plotFillAlpha": "100",
                        "legendBorderAlpha": "0",
                        "legendShadow": "0",
                        "showValues": "0",
                        "showBorder": "0",
                        "showXAxisLine": "1",
                        "xAxisLineColor": "#999999",
                        "divlineColor": "#999999",
                        "divLineDashed": "1",
                        "showAlternateHGridColor": "0" ,
                        "drawAnchors":"1",
                        "anchorRadius":"5"  
                        };  
                    
                    this.popchartAttributes.caption= "FTSE "+this.indexMarkers[this.currentIndex]+"- Sector";
                    if(selectedChart == "icb" && 0 == this.treeMapObject.icbChartData.holdingsDataset.length){
                        this.popupDataSource = {};
                       }else if (selectedChart == "rgs" && 0 == this.treeMapObject.rgsChartData.holdingsDataset.length){
                        this.popupDataSource = {};
                       }
                       else{
                        this.popupDataSource={
                            "chart": this.popchartAttributes,
                            "categories":(selectedChart == "icb")?this.treeMapObject.icbChartData.holdingscategories:this.treeMapObject.rgsChartData.holdingscategories,
                           "dataset": (selectedChart == "icb")?this.treeMapObject.icbChartData.holdingsDataset:this.treeMapObject.rgsChartData.holdingsDataset,
                          } ;
                          this.popupTargetDataSource = {
                             "dataSource": this.popupDataSource,
                             "asOfDate":(this.dateFormat == "USA") ? this.dateService.changeDateFormat(this.asofDates) : this.dateService.changeDateFormatUK(this.asofDates)
                          };
                       }
                    
                     this.SectorHistoryWeightsSectorChartLoader=false;     
    }
    loadingRefresh(){
        this.SectorHistoryWeightsSectorChartLoader = true;
        this.sectorHistoryWeightsSectorDataSource = {};
    }
    loadingStop(){
        this.SectorHistoryWeightsSectorChartLoader = false;
    }
    failedLoad(){
        this.sectorHistoryWeightsSectorDataSource = {};
        this.SectorHistoryWeightsSectorChartLoader = false;
    }
 ExportXls(event):void{
    var event: any = {eventName: "Weight Sector Sector Weight Chart Exported", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name : "Exported TimePeriod" , Value: this.timePeriod},{Name: "Exported As Of Date", Value: this.asofDates.toString()}]};
    this.biService.addEvent(event);
    var inputData = {
        "exportType": "xls",
        "code": "weight-getSectorWeightsExportData",
        "xlsFileName": "sectorRawData",
        "asOfDate": this.asofDates,
        "indexMarkers": [this.indexMarkers[this.currentIndex]],
        "returnType": this.selectedSectorChart,
        "fromDate": this.startDate,
        "timePeriod": this.timePeriod
      };
      this.idwChartExportService.export(inputData);
      }

      ExportPNG(event):void{
        var event: any = {eventName: "Export Weight Sector Sector Weight Chart TO PNG", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name : "Exported TimePeriod" , Value: this.timePeriod},{Name: "Exported As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);
        this.popupTargetDataSource.dataSource.chart.showlegend = "1";
        var inputData = {
            "exportType": "png",
            "legend": false,
            "dataSource": this.popupTargetDataSource.dataSource,
            "chartid" : "sectorHistoryWeightsSectorExport",
            "type" : "stackedarea2d",
            "targetDiv" : "exportPngDiv",
            "chartReference": this.chart,
            "isIndexLegend": false,
            "pngFileName" : "sectorRawData",
            "chartTitle": "History Weights - Sector",
            "chartDate": this.asofDates
          
         };
         this.idwChartExportService.export(inputData);
      }

    openDialog(): void{ 
        var event: any = {eventName: "help dialog open of Weight Sector Sector Weight Chart", inputParameters: [{Name : "Expanded IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name : "Expanded TimePeriod" , Value: this.timePeriod},{Name: "Expanded As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);
        this.popchartAttributes.paletteColors = this.colors;
        this.popupTargetDataSource.dataSource.chart.showlegend = "1";
        this.popupTargetDataSource = {
            "dataSource": this.popupDataSource,
            "asOfDate":(this.dateFormat == "USA") ? this.dateService.changeDateFormat(this.asofDates) : this.dateService.changeDateFormatUK(this.asofDates)
         };
        let dialogRef = this.dialog.open(WeightSectorHistoryWeightsSectorChartComponentDialog, {
            width: '80%',
            height: '70%',
	    data: this.popupTargetDataSource
  });
}
}
@Component({
    selector: 'weight-sector-history-weights-sector-chart-dialog',
	templateUrl: './weight-sector-history-weights-sector-chart.component.dialog.html'
})
export class WeightSectorHistoryWeightsSectorChartComponentDialog{

    constructor(
    public dialogRef: MatDialogRef<WeightSectorHistoryWeightsSectorChartComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    onClose(): void {
        this.dialogRef.close();
  }
}