import { Injectable }    from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import { OpenTopBottomHolding } from '../models/open-holdings-top-bottom';
import {OpenSectorClassification} from '../models/open-holdings-sector-classification'
import {OpenHoldingsChartModel} from '../models/open-holdings-chart-model';
import {OpenCurrencyWeightHoldings} from '../models/open-holdings-currency-weights';

import {OpenHoldingsGridModel} from '../models/open-holdings-grid';
import {OpenHoldingsReturnsModel} from '../models/open-holdings-returns';

import {CountryWeightModel} from '../models/countryWeight.model';
import {CurrencyWeightModel} from '../models/currencyWeight.model';

import {S3UrlModel} from '../models/s3-url.model';
import {SanetizeStringService} from '../services/sanetize-string.service';


@Injectable()
export class OpenHoldingService {

    private headers = new Headers({'Content-Type': 'application/json'});
    
    constructor( private httpClient: HttpClient,
    private sanetizeStringService:SanetizeStringService) { 
    }

    getTopBottomHoldings(asOfDate: any,indexMarkers : any ,currencyCode : any) : Promise<OpenTopBottomHolding> {
         
         let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/openHoldings/gettopbottomholdings',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers,                
                CurrencyCode :currencyCode                
        }), {headers: headers})
        .toPromise()
        .then(response =>{
             return response as OpenTopBottomHolding
            })
        .catch(this.handleError);    
    }

    getBubbleDataset(asOfDate: any,indexMarkers : any,currencyCode : any) : Promise<OpenSectorClassification> {
        
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
       return this.httpClient.post(environment.dataServiceRoot + 'ui/openHoldings/getbubbledataset',
           JSON.stringify({
               AsOfDate : asOfDate,
               IndexMarkers : indexMarkers,               
               CurrencyCode :currencyCode               
       }), {headers: headers})
       .toPromise()
       .then(response =>{
            return response as OpenSectorClassification
           })
       .catch(this.handleError);    
   }

   getCountryWeights(asOfDate: any,indexMarkers : any,currencyCode : any) : Promise<OpenHoldingsChartModel> {
    
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/openHoldings/getcountryweights',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers,               
                CurrencyCode :currencyCode
        }), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as OpenHoldingsChartModel
            })
        .catch(this.handleError);    
    }

    getCurrencyWeights(asOfDate: any,indexMarkers : any,currencyCode : any) : Promise<OpenCurrencyWeightHoldings> {
        
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            return this.httpClient.post(environment.dataServiceRoot + 'ui/openHoldings/getcurrencyweights',
                JSON.stringify({
                    AsOfDate : asOfDate,
                    IndexMarkers : indexMarkers,
                    CurrencyCode :currencyCode,
            }), {headers: headers})
            .toPromise()
            .then(response =>{
                    return response as OpenCurrencyWeightHoldings
                })
            .catch(this.handleError);    
        }
        
        

        getPositions(asOfDate: any,indexMarkers : any,currencyCode : any) : Promise<OpenHoldingsGridModel> {
            
                let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
                return this.httpClient.post(environment.dataServiceRoot + 'ui/openHoldings/getpositions',
                    JSON.stringify({
                        AsOfDate : asOfDate,
                        IndexMarkers : indexMarkers,                        
                        CurrencyCode :currencyCode                        
                }), {headers: headers})
                .toPromise()
                .then(response =>{
                    console.log(response)
                        return response as OpenHoldingsGridModel
                    })
                .catch(this.handleError);    
            }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    

        getTopBottomHoldingsData(asOfDate:any,indexMarkers:any,currencyCode:any):Promise<OpenHoldingsReturnsModel[]>{
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            return this.httpClient.post(environment.dataServiceRoot + 'ui/openHoldings/gettopbottomholdingsdata',
                JSON.stringify({
                    AsOfDate : asOfDate,
                    IndexMarkers : indexMarkers,
                    CurrencyCode:currencyCode
                                
            }), {headers: headers})
            .toPromise()
            .then(response =>{
                    return response as OpenHoldingsReturnsModel[]
                })
            .catch(this.handleError);  
        }

        getCountryWeightData(asOfDate:any,indexMarkers:any):Promise<CountryWeightModel[]>{
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            return this.httpClient.post(environment.dataServiceRoot + 'ui/openHoldings/getcountryweightsdata',
                JSON.stringify({
                    AsOfDate : asOfDate,
                    IndexMarkers : indexMarkers                      
            }), {headers: headers})
            .toPromise()
            .then(response =>{
                    return response as CountryWeightModel[]
                })
            .catch(this.handleError);  
        }

        getCurrencyWeightData(asOfDate:any,indexMarkers:any):Promise<CurrencyWeightModel[]>{
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            return this.httpClient.post(environment.dataServiceRoot + 'ui/openHoldings/getcurrencyweightsdata',
                JSON.stringify({
                    AsOfDate : asOfDate,
                    IndexMarkers : indexMarkers                      
            }), {headers: headers})
            .toPromise()
            .then(response =>{
                    return response as CurrencyWeightModel[]
                })
            .catch(this.handleError);  
        }
        getGridHoldingstData(asOfDate:any,indexMarkers:any,selectedColumns:string[]):Promise<S3UrlModel>{
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            return this.httpClient.post(environment.dataServiceRoot + 'ui/openHoldings/getgridholdlings',
                JSON.stringify({
                    AsOfDate : asOfDate,
                    IndexMarkers : indexMarkers,
                    SelectedColumns:selectedColumns                      
            }), {headers: headers})
            .toPromise()
            .then(response =>{
                    return response as S3UrlModel
                })
            .catch(this.handleError);  
        }
        
        queueRequest(startDate:any,endDate:any,timePeriod:any,indexMarkers:any):Promise<any>{
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            var userId =  sessionStorage.getItem("LoggedInUser");
            var sanetizedUser = this.sanetizeStringService.sanitizeStringLiteral(userId);

            return this.httpClient.post(environment.dataServiceRoot + 'ui/openHoldings/queuerequest',
                JSON.stringify({
                    StartDate : startDate,
                    EndDate:endDate,
                    TimePeriod:timePeriod,
                    IndexMarkers : indexMarkers,
                    UserId:sanetizedUser                  
            }), {headers: headers})
            .toPromise()
            .then(response =>{
                    return response as any
                })
            .catch(this.handleError);  
        }
}