import { Component , OnInit,Input,ViewChild,AfterViewInit } from '@angular/core';
import { LandingPageChartsComponent } from '../landing-page-charts/landing-page-charts.component';
import {BiService} from '../../services/BiService.service';
import {BiHeartBeatServiceService} from '../../services/bi-heart-beat-service.service';
import { Router } from '@angular/router';
@Component({
    selector: 'landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit,AfterViewInit {    
  url: string;
    @ViewChild(LandingPageChartsComponent,{static: false}) landingPageChartsComponent;
    constructor(private biservice: BiService, private biHeartBeatServiceService:BiHeartBeatServiceService,public router: Router) {
      // this.url = router.url;
      // console.log(router.url)
    }
    ngOnInit(): void {        
       
      }

      ngAfterViewInit(){
        var pendingEventsString = localStorage.getItem('PendingsEvents');
        var pendingEvents = (null != pendingEventsString)?JSON.parse(pendingEventsString):null;
        if(null!= pendingEvents){
          this.biservice.addEvents(pendingEvents.pageEvents);
          this.biHeartBeatServiceService.heartBeat();
          localStorage.setItem('PendingsEvents',null);
        }
      }

      growthOfUnitCurrencyCodeChange(currencyCode:any){
         this.landingPageChartsComponent.growthOfUnitCurrencyCodeChange(currencyCode);
      }

      growthOfUnitReturnTypeChange(returnType:any){
        this.landingPageChartsComponent.growthOfUnitReturnTypeChange(returnType);
      }
      marketCapCurrencyCodeChange(currencyCode:any){
        this.landingPageChartsComponent.marketCapCurrencyCodeChange(currencyCode);
     }
      
 }