import { Component, OnInit,Input  } from '@angular/core';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import { BiService } from '../../services/BiService.service';
import{CurrencyService}from '../../services/currency.service';
import {DateService} from '../../services/date.service';
import {ReportService} from '../../services/report.service';
import {Observable, forkJoin} from 'rxjs';

@Component({
    selector: 'report-index-characteristics',
    templateUrl: './report-index-characteristics.component.html'
})
export class ReportIndexCharacteristicsComponent implements OnInit {

    @Input() visible: boolean = true;
    asOfDate : any;
    indexMarkers: any[]= [];    
    indexCharateristicsColumns:any[] = [];
    constituentsNumbers:any[] = [];
    marketCaps:any[] = [];
    dividendYields:any[] = [];
    netMCAPAvgs:any[] = [];
    netMCapLargests:any[] = [];
    netMCapSmallests:any[] = [];
    netMCapMedians:any[] = [];
    weightLargests:any[] = [];
    topTenholdings:any[] = [];
    currencyCode:any;
    indexCharacteristicsLoader: boolean = false;
    constructor(private publishIndexMarkerService:PublishIndexMarkerService,private indexMarkerService:IndexMarkerService,
    private exportService:ExportToExcelService, private biService:BiService, private currencyService:CurrencyService,
    private reportService:ReportService, private dateService:DateService){}
    ngOnInit(){
          this.indexMarkers =  this.indexMarkerService.getIndexMarkers();
          this.currencyCode = this.currencyService.getCurrencyCode();
          this.asOfDate = this.dateService.getCalendarDate().end;
    }

    public gridSetup(){
        this.indexCharacteristicsLoader = true;        
        var calls = [];
       for(var index of this.indexMarkers){
          calls.push(this.reportService.getIndexCharacteristicsAnalysis(this.asOfDate,
            [index],this.currencyCode));
       }
        forkJoin(calls).subscribe(results => {
            this.indexCharacteristicsLoader = false;
            this.indexMarkers =  this.indexMarkerService.getIndexMarkers();
            this.currencyCode = this.currencyService.getCurrencyCode();
            this.asOfDate = this.dateService.getCalendarDate().end;
            for(var result of results){
                this.indexCharateristicsColumns.push(result["data"].indexName);
                this.constituentsNumbers.push(result["data"].constituentCount);
                this.marketCaps.push(result["data"].netMarketCap);
                this.dividendYields.push(result["data"].dividendYield);
                this.netMCAPAvgs.push(result["data"].averageMarketCap);
                this.netMCapLargests.push(result["data"].largestMarketCap);
                this.netMCapSmallests.push(result["data"].smallestMarketCap);
                this.netMCapMedians.push(result["data"].medianMarketCap);
                this.weightLargests.push(result["data"].weightLargestConstituent);
                this.topTenholdings.push(result["data"].weightTopTenHoldings);
            }
        });
    }

    exportXls()
    {
        var event: any = {eventName: "Export Report Index Characteristics", inputParameters: [{Name : "CurrencyCode" , 
        Value: this.currencyCode},{Name : "AsOfDate" , Value: this.asOfDate},
        {Name : "IndexMarkers" , Value: this.indexMarkers}]};
        this.biService.addEvent(event);
         this.reportService.getIndexCharacteristicsAnalysisToExport(this.asOfDate,
        this.indexMarkers,this.currencyCode).then(res => {
            this.exportService.exportData(res["data"],"IndexCharacteristicsRawData");
        });
    }
}