import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-performance-table',
  templateUrl: './report-performance-table.component.html',
  styleUrls: ['./report-performance-table.component.css']
})
export class ReportPerformanceTableComponent implements OnInit {
  @Input() rowData: any;
  @Input() PortfolioType:string;
  lablestab:string;
  constructor() { }

  ngOnInit(): void {
    if (this.PortfolioType=='B')
    this.lablestab='IDX'
     else  this.lablestab='PF'
  }

}
