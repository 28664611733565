import { Component, OnInit } from '@angular/core';
import {MasterDataService} from '../../services/masterdatapack.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {NewMasterPopupComponent} from '../../components/new-masterdata-popup/new-masterdata-popup.component';
import {NewMetricForm} from '../../models/metric';
import { DownloadService } from '../../services/download.service';
import { data } from 'jquery';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { S3ManagerService } from '../../../services/s3-manager.service';
import * as CryptoJS from "crypto-js";
import { saveAs } from 'file-saver/FileSaver';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'meta-data-config-page',
  templateUrl: './meta-data-config-page.component.html',
  styleUrls: ['./meta-data-config-page.component.scss']
})
export class MetaDataConfigComponent implements OnInit {
   isVisible :boolean = false ;
  dataSource$ = this.masterDataService.fetchReports$(0, 5);
  passphrase: string = "EncryptionatRest";

  baseDialogConfig: MatDialogConfig = {
    closeOnNavigation: true,
    panelClass: 'custom-popup-panel',
  };
  constructor(private masterDataService: MasterDataService, private dialog: MatDialog, private exportService: ExportToExcelService, private s3ManagerService: S3ManagerService) {}

  ngOnInit(): void {
       if (sessionStorage.getItem("UserRoles").indexOf(environment.siAdminGroup) != -1) {
            this.isVisible=true;
        }
  }
  //downloadIndexes(): void {
  //  this.masterDataService.downloadExcel().subscribe((res) => {
  //    let data;
  //    try {
  //      data = JSON.parse(res)["data"];
  //    }
  //    catch  {
  //      data = JSON.parse(atob(res))["data"];
  //    }
  //    //this.exportService.exportData(data, "SImetricmasterlist");
  //    //alert("Click OK to download SImetricmasterlist");

  //  });

  downloadIndexes(): void {
    this.masterDataService.downloadExcel().then((res) => {
      let data=res;
      alert("Click OK to download SI Metric Index Master List");     
      if (data != undefined && data !== "") {
        // call download file method
        this.downloadFile(data);
        console.log(data);   // window.location.href = data;
        alert("SI Metric Index Master List download is ready");
      } else if (data == "") {
        alert("No data found.");
      }
    
    });

  }

  downloadFile(s3UrlData): void {
    console.log("FullS3 url: " + s3UrlData);
    var urlParts = s3UrlData.replace("//", "--").split("/");
    var bucketName = urlParts[1];
    var filePath = urlParts[urlParts.length - 1];
    var fileName = urlParts[urlParts.length - 1];

    console.log("BucketName url: " + bucketName);
    console.log("FilePath url: " + fileName);
    console.log("Session Message : " + sessionStorage.getItem('RestMessage'));
    this.s3ManagerService.getRawData(bucketName, filePath,
      JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('RestMessage'), this.passphrase).toString(CryptoJS.enc.Utf8))).then(result => { 
        saveAs(new Blob([result.Body]), fileName);  //  {type: result.ContentType}
        console.log(sessionStorage.getItem('RestMessage'));
      }, onerror => {
        console.log(onerror);
      });
  }

  openDialog(): void {
    const dialogRef: MatDialogRef<NewMasterPopupComponent, NewMetricForm> = this.dialog.open(NewMasterPopupComponent);
     dialogRef.afterClosed().subscribe((reportForm) => {
      if (reportForm) {
        var that = this;
        this.masterDataService.createMetricIndex(reportForm).subscribe((res) => {  
          that.dataSource$ = this.masterDataService.fetchReports$(0,5);
        });
      }
    });
  }

  editIndexMatric(report: NewMetricForm) {
    const dialogRef: MatDialogRef<NewMasterPopupComponent, NewMetricForm> = this.dialog.open(NewMasterPopupComponent, {
      ...this.baseDialogConfig,
      data: report,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var that = this;
        console.log(data);
        this.masterDataService.updateMetricIndex(data).subscribe(result => {            
          that.dataSource$ = this.masterDataService.fetchReports$(0,5);
        });
        ;
      }
    });

  }
  ngAfterViewInit() {
    let yourElem = <HTMLElement>document.querySelector('.fa-angle-double-left');
    if (yourElem != null) {
      yourElem.click();
    }
  }
  

}

