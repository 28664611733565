import { Component, OnInit } from '@angular/core';
import { EntitlementService } from '../../services/entitlement.service';
import { ActivatedRoute } from '@angular/router';
import { APIFieldsModel, EntitlementModel, DataModel } from '../../models/entitlement.model';
import { truncateSync } from 'fs';
import { iBcpRequestModel } from '../../models/bcprequest.model';

@Component({
  selector: 'app-alternate-data-delivery',
  templateUrl: './alternate-data-delivery.component.html',
  styleUrls: ['./alternate-data-delivery.component.scss']
})
export class AlternateDataDeliveryComponent implements OnInit {
  apis: any[] = new Array();
  userDetails: any[] = new Array();
  apiFields: APIFieldsModel[];
  inputApiFields : APIFieldsModel[];
  inputParameter: any[];
  dataTypes: any[] = new Array();
  data: DataModel;
  commitStatus: any;
  indexMakersReq: any;
  disabled:boolean= true;
  count : number = 0;
  private sub: any;
  clicked :boolean= false;
  isEmailSentOption: any;
  bcpRequests: iBcpRequestModel[]=[];
  emailId: string = "";   
  // pattern = "^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$"; // working one
  pattern = "^[a-z]+[a-z0-9._]+@lseg.com$"; 

  possibleDates : any[];
  isDates:boolean= false;
  constructor(private entitlementService: EntitlementService,private route: ActivatedRoute) { this.isEmailSentOption="Group" }

  ngOnInit() {
    this.data={
      apiMethodName:"",
      emailOption: this.isEmailSentOption,
      emailId: this.emailId,
      inputParameters :[] 
    }

    this.entitlementService.getAPIS().then(result => {
      this.apis = result;
    });

    this.GetBcpRequest();
  }  

  onAPIChange(selectedAPI) {
    this.commitStatus = "";
    this.data.inputParameters = [];
    this.disabled = true;
    this.userDetails = [];
    this.indexMakersReq=false;
    this.onItemChange('Group'); 
    this.isDates=false;
    this.entitlementService.getAPIFields(selectedAPI).then(result => {
      this.apiFields = result;
      this.data.inputParameters = this.apiFields.filter(x => x.isInputField == true);

      this.entitlementService.getUserClientDetails().then(results => {
        this.userDetails = results;
      });

    })

  }
  focusOutFunction(value){
    console.log(value);
    if(value != '0'){
     this.isDates=true;
    this.entitlementService.getPossibleTDates(value).then(results => {
      this.possibleDates = results;
      console.log(results);
    });
  }
  }
  onUserChange(selectedUser) {
    this.commitStatus = "";
    for (var i = 0; i < this.data.inputParameters.length; i++) {
      if (this.data.inputParameters[i].fieldName === 'UserId') {
        this.data.inputParameters[i].value = selectedUser;
      }

    }

  }
  onCheck(event) {
    this.commitStatus = "";
    if (event.target.checked) {
      this.disabled = true;
      this.indexMakersReq =false;
      for (var i = 0; i < this.data.inputParameters.length; i++) {
        if (this.data.inputParameters[i].fieldName === 'IndexMarkers') {
          this.data.inputParameters[i].value = '';
        }

      }
    }
    else {
     this.indexMakersReq =true;
      this.disabled = false;
    }
  }
  
 onItemChange(value){   
  this.isEmailSentOption = value;
  if(this.isEmailSentOption != 'Me'){this.emailId = '';}
  }

  saveChanges() {
    this.commitStatus = "";
    
      if (this.data.inputParameters != undefined) { 
        if (this.data.apiMethodName == "" || this.data.apiMethodName == "Select") {
          this.commitStatus = "Please Select API Name , ";
        }
      this.data.emailOption = this.isEmailSentOption;
      if(this.data.emailOption == "Me"){ this.data.emailId =this.emailId; }
      for (var i = 0; i < this.data.inputParameters.length; i++) {
       
        if (this.data.inputParameters[i].value == "Select" || this.data.inputParameters[i].value == "" || this.data.inputParameters[i].value == null) {
          if( this.commitStatus == '')
          {
          this.commitStatus = 'Please enter ';
          }
          switch (this.data.inputParameters[i].fieldName) {
            case 'UserId':
              this.commitStatus += 'UserId , ';
              break;
            case 'AsOfDate':
              this.commitStatus  += 'AsOfDate , '
              break;
              
              case 'IndexMarker':
                this.commitStatus  += 'IndexMarker , '
                break;
            case 'Year':
              this.commitStatus  += 'Year , '
              break;
            case 'Month':
              this.commitStatus  += 'Month , '
              break;
            case 'CalculationDate':
              this.commitStatus  += 'CalculationDate , '
              break;
              case 'SedolCode':
              this.commitStatus  += 'SedolCode , '
              break;
            case 'ISINCode':
              this.commitStatus  += 'ISINCode , '
              break;

            case 'ClientId':
              this.commitStatus  += 'ClientId , '
              break;

          }

        }
        if( this.commitStatus == '')
        {
        this.commitStatus = 'Please enter ';
        }
        if (this.indexMakersReq ==true && this.data.inputParameters[i].fieldName == 'IndexMarkers' )
        {
          if(this.data.inputParameters[i].value == ''  || this.data.inputParameters[i].value  ==null)
          {
            this.commitStatus  +=  "IndexMarkers , "; 
          } 
        }
        if(this.data.inputParameters[i].fieldName == 'Year'    )
        {
          var year = !isNaN( Number(this.data.inputParameters[i].value)) ;
          if(year == false)
          {
            this.commitStatus  +=  "only numbers in Year , "; 
          }
        }
        if(this.data.inputParameters[i].fieldName == 'AsOfDate' &&   this.data.inputParameters[i].value != null    )
        {
        
          var date =  this.data.inputParameters[i].value ;
          var validDate = !isNaN((new Date(date)).getDate()); 
          const asofdateArray=   date.split("-");  
         if(validDate == false )
         {
           this.commitStatus  += " valid date format for AsOfDate , "; 
         }
         else{
          if(asofdateArray.length <3 ) {

                this.commitStatus  +=  " valid date format for AsOfDate , "; 
           }
          else{
             if( asofdateArray[0].length!= 4 || asofdateArray[0]== '0000' || asofdateArray[1].length!= 2 || asofdateArray[2].length!= 2 )
               {
                this.commitStatus  +=  " valid date format for AsOfDate , "; 
               }
             }    

        }
        }
        if(this.data.inputParameters[i].fieldName == 'CalculationDate' &&   this.data.inputParameters[i].value != null )
        { 
          var date =  this.data.inputParameters[i].value ;
          var validDate = !isNaN((new Date(date)).getDate()); 
          const caldateArray=   date.split("-");  
         if(validDate == false )
         {
           this.commitStatus  += " valid date format for CalculationDate , "; 
         }
         else{
          if(caldateArray.length <3 ) {

                this.commitStatus  +=  " valid date format for CalculationDate , "; 
           }
          else{
             if( caldateArray[0].length!= 4  || caldateArray[0]== '0000'|| caldateArray[1].length!= 2 || caldateArray[2].length!= 2 )
               {
                this.commitStatus  +=  " valid date format for CalculationDate , "; 
               }
             }    

        }
      }
      } 
      if(this.emailId != null && this.isEmailSentOption == 'Me' && !this.emailId.match(this.pattern))
      {
        this.commitStatus+=' valid LSEG account Email Id , ';
      }  
      else if(this.emailId != null && this.isEmailSentOption == 'Me' 
      && (this.emailId.split("@")[1] != undefined && (this.emailId.split("@")[1]).indexOf(".") < 0)){
          this.commitStatus+=' valid Email Id , ';
      }
      else if(this.emailId != null && this.isEmailSentOption == 'Me' 
         && (this.emailId.split("@")[1]).split(".")[1] != undefined && (this.emailId.split("@")[1]).split(".")[1].length < 2){
        this.commitStatus+=' valid Email Id , ';
      }
      else if(this.emailId != null && this.isEmailSentOption == 'Me' 
      && (this.emailId.split("@")[1] != undefined && this.emailId.split("@")[1]  !== "lseg.com")){
        this.commitStatus+=' LSEG account Email Id , ';
      }     
      else if(this.emailId == "" && this.isEmailSentOption == 'Me')
      {
        this.commitStatus+=' Email Id , ';
      }    

      if(this.commitStatus  =='Please enter ')
      {
        this.commitStatus='';
      }
     else{
      var str =  this.commitStatus.replace(/,(?![^,]*,)/, '.');
      this.commitStatus='';
      this.commitStatus =str; 
     }
      if (this.commitStatus  == '') {
          
      this.clicked = true;
      this.entitlementService.saveAlternateData(this.data).then(result => {
        if (result != null) {
          console.log(result);
          alert("Request has been successfully submitted");
          this.GetBcpRequest();
          setTimeout(()=> {this.GetBcpRequest();}, 5000);
          setTimeout(()=> {this.GetBcpRequest();}, 10000);
          this.clicked = false;
        }
        else {
          alert("Request has not been submitted");
          this.clicked = false;
        }
      }); }

    }
  }

  GetBcpRequest() {
    this.entitlementService.getBcpRequests().then(result => {
      this.bcpRequests = result;

      this.bcpRequests.forEach((req:any) => {
        let count: number = 1;
        let multiline: string = '';
        if (req.indexMarker != '') {
          req.indexMarker = req.indexMarker.replace(/\s/g, "");
          req.indexMarker.split(',').forEach(idx => {
            if (count == 2) {
              multiline = multiline + idx + ',\n';
              count = 0;
            }
            else multiline = multiline + idx + ','            
            count++;
          })
          req.indexMarker = multiline;
        }
        req.indexMarker = req.indexMarker.slice(0, req.indexMarker.lastIndexOf(','));
      })
    });
  }

  refresh(row:any){ 
    this.entitlementService.VerifyBcpRequestStatus(row.requestId, row.s3Path).then(result => {
      if (result){
        this.GetBcpRequest();
      }
    });
  }
}