import {Component, NgModule, OnInit,Output,EventEmitter} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import { Daterangepicker,DaterangepickerConfig } from 'ng2-daterangepicker';
import { forkJoin } from "rxjs";
import * as moment from 'moment';
import { LandingService } from '../../services/landing.service';


@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html'
})

export class DateRangePickerComponent implements OnInit{

    @Output() notifyDate: EventEmitter<any> = new EventEmitter<any>();
    TodayDate:any ;
    OneDayAgo:any ;
    OneMonthAgo:any ;
    TwoMonthsAgo:any;
    StartMonth:any ;
    Quarter:any;
    ThreeMonthsAgo:any; 
    SixMonthsAgo:any; 
    OneYearAgo:any ;
    TwoYearsAgo:any ;
    ThreeYearsAgo:any ;
  
    
    public mainInput = {
        start: this.TwoMonthsAgo,
        end: this.OneDayAgo,
        label:'Daily',
    }    
    ngOnInit(){
        
        var today = this.landingService.getBusinessDate();
        var restDays = this.landingService.getTargetBusinessDate(moment().toDate());
        forkJoin([today,restDays]).subscribe(results=>{
            this.TodayDate = moment(results[0]).toDate();
            this.OneDayAgo= moment(results[1][0]).toDate();
            this.OneMonthAgo=moment(results[1][1]).toDate();
            this.TwoMonthsAgo = moment(results[1][2]).toDate();
            this.StartMonth = moment(results[1][3]).toDate();
            this.Quarter = moment(results[1][4]).toDate();
            this.ThreeMonthsAgo = moment(results[1][5]).toDate();
            this.SixMonthsAgo= moment(results[1][6]).toDate();
            this.OneYearAgo = moment(results[1][7]).toDate();
            this.TwoYearsAgo = moment(results[1][8]).toDate();
            this.ThreeYearsAgo =  moment(results[1][9]).toDate();
            this.mainInput.start = this.TwoMonthsAgo;
            this.mainInput.end = this.TodayDate;
            this.myDaterangepickerOptions.settings = {
             startDate:this.TwoMonthsAgo,
             endDate:this.TodayDate,   
             maxDate:this.TodayDate,
             locale: { format: 'MM/DD/YYYY' },
             alwaysShowCalendars: false,
             singleDatePicker:false,
             autoApply: true,
             linkedCalendars: false,
             ranges: {
                 'Daily': [this.ThreeMonthsAgo,this.TodayDate],
                 'Monthly': [this.SixMonthsAgo,this.TodayDate],
                 'Quarterly': [this.TwoYearsAgo,this.TodayDate],
                 'Yearly': [this.ThreeYearsAgo,this.TodayDate]                
             }
         };
         
         this.notifyDate.emit(this.mainInput);
        });                
    }

    constructor(private landingService: LandingService,private myDaterangepickerOptions: DaterangepickerConfig) {
       
         
    }  
    
    public selectedDate(value: any, dateInput: any) {
        dateInput.start = value.start;
        dateInput.end = value.end;
        this.notifyDate.emit(value);
    }   
}