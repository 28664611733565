import { bool } from "aws-sdk/clients/signer";
import { GridData } from "../pbr/models/common";

export enum IndexMappingDetailColumns
{
    indexMarker = "indexMarker",
    indexFamily = "indexFamily",
    currency = "currency",
    isActive = "isActive"
}

export const indexMappingDetailsColumnsNameMap: Record<IndexMappingDetailColumns, string> = {
    [IndexMappingDetailColumns.indexMarker]:'Index Marker',
    [IndexMappingDetailColumns.indexFamily]:'Index Family',
    [IndexMappingDetailColumns.currency]:'Currency',
    [IndexMappingDetailColumns.isActive]:'IsActive',
};

export interface ClientIndexMappingsDetails extends GridData<ClientIndexMappings> {}

export interface ClientIndexMappings
{
    indexMarker: string;
    indexFamily: string;
    currency: string;
    isActive: boolean;
}

export enum indexMappingDisplayName{
    indexMarker = 'Index Marker',
    indexFamily = 'Index Family',
    currency = 'Currency',
    isActive = 'IsActive',
};

export interface iDisplayNewRow {
    action:Action,
    displayInput: boolean
}

export enum Action {
    new,
    edit,
    duplicate,
    cancel,
    download,
}