import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AnalyticsFilter } from 'aws-sdk/clients/s3';


@Component({
  selector: 'chart-type',
  templateUrl: './chart-type.component.html',
  styleUrls: ['./chart-type.component.css']
})
export class ChartTypeComponent implements OnInit {

  @Input() dataSetType: any;
  @Output() chartTypeChange = new EventEmitter<string>();
  chartType: any[] = []
  defaultChartType: string;

  constructor() { }

  ngOnInit() {
     if (this.dataSetType == "simple") {
      this.chartType = [{ "key": "pie2d", "value": " Pie" },
      { "key": "doughnut2d", "value": "Donut" },
      { "key": "column2d", "value": "Vertical Bar" },
     { "key": "bar2d", "value": "Horizontal Bar" },
     { "key": "line", "value": "Line" }];
   }
   else {
      this.chartType = [{ "key": "stackedarea2d", "value": "Stacked Area" },
      { "key": "stackedbar2d", "value": "Stacked Horizontal" },
      { "key": "msColumn2D", "value": "Vertical Bar" },
      { "key": "msline", "value": "Column + Line" },
      { "key": "stackedcolumn2d", "value": "Stacked Vertical" },
      { "key": "stackedcolumn2dline", "value": "Stacked Vertical Line" }
    ]
   }
  }

  changedChartType(chart: string) {
      this.defaultChartType = chart;
      this.chartTypeChange.emit( this.defaultChartType);
    
  }
}
