import { Component, OnInit,Input,OnChanges,SimpleChanges,Inject,NgZone,ViewChild, AfterViewInit  } from '@angular/core';
import { OpenHoldingService } from '../../services/open-holdings.service';
import {OpenHoldingPageModel} from '../../models/open-holding-page-model';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {MagellanColorService} from '../../services/magellancolor.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { BiService } from '../../services/BiService.service';
import{CurrencyService}from '../../services/currency.service';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import {ChartTypeComponent} from '../chart-type/chart-type.component';

@Component({
    selector: 'open-country-weights-chart',
    templateUrl: './open-holdings-country-weights-chart.component.html'
})
export class OpenCountryWeightsChartComponent implements OnInit, AfterViewInit {
    @ViewChild(ChartTypeComponent,{static: false}) chartTypeComponent;
    chartAttributes: any;
    chartCategories: any;
    dataSource: any;
    CountryWeightChartLoader:boolean=true;
    currentIndex:any;
    toollbarTitle:any;
    popupDataSource:any;
    defaultActiveChart : any = "stackedcolumn2dline";
    datasetType = "multiple";
    colors : any;
    chart:any;
    selectedIndex: any;
    constructor(private openHoldingService: OpenHoldingService,private indexMarkerService:IndexMarkerService, private exportService: ExportToExcelService
        ,private publishIndexMarkerService:PublishIndexMarkerService, private magellanColorService : MagellanColorService
    ,private dialog: MatDialog,private biService: BiService,private currencyService:CurrencyService,private zone:NgZone,
    private idwChartExportService: IDWChartExportService) { }
    @Input() asofDates : any;
    indexMarkers : any;  
    currencyCode : string;

    ngOnInit(){    
        this.indexMarkers = this.indexMarkerService.getIndexMarkers();
        this.currencyCode = this.currencyService.getCurrencyCode();
        this.currentIndex = 0; 
        this.toollbarTitle = "Country Weights"; 
        
        this.colors = this.magellanColorService.getCurrentColorSchema();
        this.magellanColorService.colorsPublish().subscribe(color => { 
          this.colors=color;
            this.chartAttributes.paletteColors = this.colors;  
          });
         
    }
    ngAfterViewInit(){
        this.chartTypeComponent.changedChartType(this.defaultActiveChart);
        this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
            this.indexMarkers = result;
            this.CountryWeightChartLoader=true;
            this.chartSetup();
        });
    }
    chartInitialize(event){
        return (eve, arg) => { 
            this.zone.run(() => {
                this.chart =eve.sender;
            });  
            
        };
          
      }
    
      events = {
        initialized: this.chartInitialize(event)
    }

    carouselPrevious():void{
        var event: any = {eventName: "Open Holdings Country Weight Previous Carousel", inputParameters: [{Name: "Previous IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() }]};
        this.biService.addEvent(event);
        this.CountryWeightChartLoader=true;
        if(0 == this.currentIndex){
           this.currentIndex = this.indexMarkers.length -1;
        }else{
            this.currentIndex = this.currentIndex - 1;
        }
        this.colors = this.magellanColorService.getCurrentColorSchema();
        this.chartSetup();
    }
    
    carouselNext():void{
        var event: any = {eventName: "Open Holdings Country Weight next Carousel ", inputParameters: [{Name: "Next IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() }]};
        this.biService.addEvent(event);
        this.CountryWeightChartLoader=true;
       if((this.indexMarkers.length -1) == this.currentIndex){
            this.currentIndex = 0;
        }else{
            this.currentIndex = this.currentIndex + 1;
        }
        this.colors = this.magellanColorService.getCurrentColorSchema();
        this.chartSetup();
    }


    update(openHoldingPageModel:OpenHoldingPageModel){
        this.CountryWeightChartLoader=true;
        this.asofDates = openHoldingPageModel.asofdate;        
        this.currencyCode=openHoldingPageModel.currencyCode;
        this.chartSetup();
    }
    
    private chartSetup() {
        if(this.indexMarkers[this.currentIndex]==undefined)
        {
             this.currentIndex=0;
        }
        this.selectedIndex = this.indexMarkers[this.currentIndex]
        this.toollbarTitle = "Country Weights " ;
        this.openHoldingService.getCountryWeights(this.asofDates,[this.indexMarkers[this.currentIndex]] ,this.currencyCode ).then(data => {
            this.chartAttributes ={
                "paletteColors": this.colors,
                "bgcolor": "#ffffff",
                "plotgradientcolor": "",
                "showalternatehgridcolor": "0",
                "showplotborder": "0",
                "divlinecolor": "CCCCCC",
                "showvalues": "0",
                "showcanvasborder": "0",
                "labeldisplay": "STAGGER",
                "slantlabels": "1",
                "canvasborderalpha": "0",
                "showlegend": "0",
                "legendshadow": "0",
                "legendborderalpha": "0",
                "showborder": "0",
                "chartRightMargin": "-10",
                "exportEnabled": "1",
                "exportShowMenuItem": "0",
                "yAxisMaxValue":"0.1"
            }
            if(null!= data && null!= data.holdingsDataset && null!= data.holdingsDataset.data
            && 0< data.holdingsDataset.data.length){
                this.dataSource = {
                    "categories": data.holdingscategories,
                    "dataset": data.holdingsDataset,
                    "chart" :  this.chartAttributes
                  };
                
                  this.popupDataSource={
                      "title":this.toollbarTitle + this.indexMarkers[this.currentIndex],
                      "asOfDate":this.asofDates,
                      "type": this.defaultActiveChart,
                      "dataSource": this.dataSource                    
                    };
            }else{
                this.dataSource = {};
            }
            this.CountryWeightChartLoader=false;
            
          },onerror =>{
              console.log("country weight on error");
        this.CountryWeightChartLoader =false;       
     });
        
    }

    ExportXls(event):void{
        var event: any = {eventName: "Open Holdings Export Country Weight", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()}]};
        this.biService.addEvent(event);
        var inputData = {
            "exportType": "xls",
            "code": "openHoldings-getOpenHoldingsCountryWeightData",
            "xlsFileName": "OpenCountryWeightsRawData",
            "asOfDate": this.asofDates,
            "indexMarkers": [this.indexMarkers[this.currentIndex]]
          };
          this.idwChartExportService.export(inputData);
    }

    ExportPNG(event):void{
        var event: any = {eventName: "Open Holdings Export Country Weight TO PNG", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()}]};
        this.biService.addEvent(event);
        this.popupDataSource.dataSource.chart.showlegend = "1"; 
        var inputData = {
           "exportType": "png",
           "legend": false,
           "dataSource": this.popupDataSource.dataSource,
           "chartid" : "openHoldingsCountryWeightChartExport",
           "type" : "stackedcolumn2dline",
           "targetDiv" : "exportPngDiv",
           "chartReference": this.chart,
           "pngFileName" : "OpenHoldingsCountriesWeight",
           "chartTitle": this.toollbarTitle + this.indexMarkers[this.currentIndex],
           "isIndexLegend": false,
           "chartDate": this.asofDates
        };
        this.idwChartExportService.export(inputData);
      }

      openWidgetDialog():void{
        var event: any = {eventName: "Open Holdings Country weight help dialog open"};
        this.biService.addEvent(event);
        let dialogRef = this.dialog.open(OpenCountryWeightsChartComponentWidgetDialog, {
            width: '800px' , 
            data: this.indexMarkers     
          });
      }

    openDialog(): void{ 
        var event: any = {eventName: "Open Holdings Country Weight dialog open"};
        this.biService.addEvent(event);
        this.popupDataSource.dataSource.chart.showlegend = "1"; 
        let dialogRef = this.dialog.open(OpenCountryWeightsChartComponentDialog, {
            width: '80%',
            height: '85%',
	    data: this.popupDataSource
        });
    }

    onChartTypeChange(chart: string) {                
        this.defaultActiveChart = chart;       
        this.chartSetup();
    } 
    
}

@Component({
    selector: 'open-holdings-country-weights-chart-dialog',
	templateUrl: './open-holdings-country-weights-chart.component.dialog.html'
})
export class OpenCountryWeightsChartComponentDialog{

    constructor(
    public dialogRef: MatDialogRef<OpenCountryWeightsChartComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    onClose(): void {
        this.data.dataSource.chart.showlegend = "0";
        this.dialogRef.close();
  }
}

@Component({   
    templateUrl: './open-holdings-country-weights-chart.component.widget.dialog.html'
  })
  export class OpenCountryWeightsChartComponentWidgetDialog{
  
  @Input() indexes: string;
  constructor(
  public dialogRef: MatDialogRef<OpenCountryWeightsChartComponentWidgetDialog>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
    this.indexes="";
     data.forEach(element => {
         if( this.indexes.length<3)
         {
            this.indexes=element;
         }else{
        this.indexes += ", "+ element;
         }
     });   
   }
  
  onClose(): void {
      this.dialogRef.close();
  }
  }


