import { Injectable } from '@angular/core';
import {saveAs} from 'file-saver';
import {GridData} from '../models/common';
import {Subject} from 'rxjs/internal/Subject';
// tslint:disable-next-line:import-blacklist
import {Observable} from 'rxjs';
import {ErrorService} from './error.service';
import {BenchmarkDetailsColumns} from '../models/benchmark';
import {SanetizeStringService} from '../../services/sanetize-string.service';
import { config } from 'process';
import { formatDate, DatePipe } from '@angular/common'; 
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  readonly separator = ',';
  readonly escapeSign = '"';

  private brakeLineInToValues(row: string): string[] {
    const line = [];
    let value = '';
    let stack = [];
    for (let i = 0; i < row.length; i++) {
      if (!(stack.length % 2) && this.separator === row[i]) {
        line.push(value);
        stack = [];
        value = '';
      } else {
        if (row[i] === this.escapeSign) {
          stack.push(row[i]);
        }
        value += row[i];
      }
    }
    if (value) {
      line.push(value);
    }
    return line;
  }

  readFileData$<T>(file: File): Observable<GridData<T>> {
    const fileData$ = new Subject<GridData<T>>();
    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = (evt) => {
      if (!(evt.target) || typeof (evt.target as any).result !== 'string') {
        return;
      }
      const fileContent: string = (evt.target as any).result;
      const lines = fileContent.split(/\r\n|[\n\v\f\r\x85\u2028\u2029]/)
        .filter(Boolean)
        .map((v) => this.brakeLineInToValues(v))
        .map(line => line.map((value = '') => {
          const unwrappedStr = (value[0] === '"' && value[value.length - 1] === '"') ? value.slice(1, -1) : value;
          return unwrappedStr.replace(/""/g, '"');
        }));
      const [documentColumns, ...documentData] = lines;
      const fieldsMapping = {
        'SEDOL' : BenchmarkDetailsColumns.sedol,
        'CUSIP' : BenchmarkDetailsColumns.cusip,
        'Isin': BenchmarkDetailsColumns.isin,
        'ConsCode': BenchmarkDetailsColumns.consCode,
        'ConstituentName': BenchmarkDetailsColumns.constituentName,
        'CountryCode':BenchmarkDetailsColumns.countryCode,
        'Weight': BenchmarkDetailsColumns.weight,
        'PortfolioValue': BenchmarkDetailsColumns.portfolioValue,
        'CurrencyCode': BenchmarkDetailsColumns.currencyCode,
        'AsOfDate': BenchmarkDetailsColumns.asOfDate
      };
      const columns = [...Object.keys(BenchmarkDetailsColumns)];
      const columnsMap = columns.reduce((acc, item, i) => {
        acc[item] = i;
        return acc;
      }, {});
      const {length} = documentData;
      const data = Array.from({length}, () => []);
      documentData.forEach((row, rowIndex) =>
        row.forEach((columnValue, docColumnIndex) => {
          const documentColumnName = documentColumns[docColumnIndex];
          if (documentColumnName in fieldsMapping) {
            const columnIndex = columnsMap[fieldsMapping[documentColumnName]];
            if (!data[rowIndex][documentColumnName]) {
              const parser = this.getParser(fieldsMapping[documentColumnName]);
              data[rowIndex][documentColumnName] = parser(columnValue);
            }
          }
        }
      ));
      fileData$.next({columns, data, model: null});
    };
    reader.onerror = (evt) => {
      this.errorService.showError(evt);
    };

    return fileData$.asObservable();
  }

  getParser(fieldName: BenchmarkDetailsColumns): (rawValue: string) => string {
   // const DEFAULT_PRECISION = 12;
    const parserMapper: Partial<Record<BenchmarkDetailsColumns, (rawValue: string) => string>> = {
      [BenchmarkDetailsColumns.weight]: (rawValue) => {
        return (rawValue);
      }
    };
    return parserMapper[fieldName] || ((s) => s);
  }

  download(data: GridData<any>, fileName: string = 'file-' + new Date().toLocaleDateString(), reportFor: string = '', asOfDate: Date = new Date(),configData: any = null, portfolioType: string = ''): void { 
    const str = this.getCSV(data, reportFor, asOfDate, configData, portfolioType);
    const blob = new Blob([str], {type: 'text/csv'});
    saveAs(blob, `${fileName}.csv`);
  }
 

  generateCSV(csvstring: string, asOfDate: Date = new Date(), configData: any = null) : string {
    var row2: string[] = [];
    // Line 1
    csvstring = this.sanitizeStringLiteral('Climate Dashboard - Company Details');
    csvstring += '\r\n';

    // Line 2
    for(var i = 0; i <57; i++){
    if(i == 11) { csvstring += 'CARBON EXPOSURES,';    } 
    else if(i == 27) {  csvstring += 'SECTORAL TRANSITION METRICS,'; }
    else if(i == 52) {  csvstring += 'CLIMATE GOVERNANCE,'; }
    else { csvstring += ',';  }
    }
    csvstring += '\r\n';

    //Line 3
    for(var i = 0; i <57; i++){
      if(i == 0) { csvstring += 'Constituents,';    } 
      else if(i == 11) {  csvstring += 'Scope1and2PerRevenueRatio_tCO2ePerUSDm,'; }
     // else if(i == 19) {  csvstring += 'Scope1and2PerMktCapRatio_tCO2ePerUSDm,'; } // 19 ==> 27
      else if(i == 19) {  csvstring += 'Scope1and2PerEVICRatio_tCO2ePerUSDm,'; } // 27+8
      else if(i == 27) {  csvstring += 'GRPerRevenueTier123_PctShare,'; } // 7
      else if(i == 34) {  csvstring += 'ExposureToFossilFuelsICBSubsectors,'; } // 4
      else if(i == 38) {  csvstring += 'FFRPCO2Coal1PPerRevenueRatio_tCO2PerUSDm,'; }  // 7
      else if(i == 45) {  csvstring += 'FFRPCO2OilandGas2Por1PPerRevenueRatio_tCO2PerUSDm,'; }  // 7
      else if(i == 52) {  csvstring += 'TPIMQScore_0To5Score'; }
      else { csvstring += ',';  }
      }
      csvstring += '\r\n';
 
      //Line 4  
      var WaciDate= '';  var EvicaeDate= '';  var GRDate= '';  var FFPCoalDate= '';  var FFPOGDate= '';  var TPIMQDate= '';
      var WaciYear: Date = new Date();   var EvicaeYear: Date = new Date();   var GRYear: Date = new Date();  var FFPCoalYear: Date= new Date(); var FFPOGYear: Date= new Date();   var TPIMQYear: Date= new Date();
      
      for (var p=0; p < configData.length; p++)
      { 
        if (configData[p].classificationid == '166' && configData[p].classificationattributeid == '77' && (configData[p].classificationKeyColumn == '1' || configData[p].classificationKeyColumn == '2')){ WaciYear = configData[p].datasetDate_Year;  WaciDate = configData[p].datasetDate;   } 
        if (configData[p].classificationid == '203' && configData[p].classificationattributeid == '131' && (configData[p].classificationKeyColumn == '1' || configData[p].classificationKeyColumn == '2')){ EvicaeYear = configData[p].datasetDate_Year;  EvicaeDate = configData[p].datasetDate;   } 
        if (configData[p].classificationid == '135' && (configData[p].classificationattributeid == '73' || configData[p].classificationattributeid == '129' || configData[p].classificationattributeid == '130') && configData[p].classificationKeyColumn == 'GreenRevenue'){ GRYear = configData[p].datasetDate_Year;  GRDate = configData[p].datasetDate;   } 
        if (configData[p].classificationid == '206' && configData[p].classificationattributeid == '134' && configData[p].classificationKeyColumn == '1'){ FFPCoalYear = configData[p].datasetDate_Year;  FFPCoalDate = configData[p].datasetDate;   } 
        if (configData[p].classificationid == '205' && configData[p].classificationattributeid == '133' && configData[p].classificationKeyColumn == '1'){ FFPOGYear = configData[p].datasetDate_Year;  FFPOGDate = configData[p].datasetDate;   } 
        if (configData[p].classificationid == '204' && configData[p].classificationattributeid == '132' && configData[p].classificationKeyColumn == '1'){ TPIMQYear = configData[p].datasetDate_Year;  TPIMQDate = configData[p].datasetDate;   } 
      } 

     for(var i = 0; i <57; i++){
      if(i == 0) { csvstring += 'WeightsDate,';    }  else if(i == 1) { csvstring +=  formatDate( asOfDate, 'dd/MM/yyyy', 'en-US') +',';    } 
      else if(i == 11) {  csvstring += 'YearOfData,'; } else if(i == 12) { csvstring += WaciYear +',';    } 
      else if(i == 19) {  csvstring += 'YearOfData,'; } else if(i == 20) { csvstring += WaciYear +',';    } 
      else if(i == 27) {  csvstring += 'YearOfData,'; } else if(i == 28) { csvstring +=  GRYear+',';    } 
      else if(i == 34) {  csvstring += 'YearOfData,'; } else if(i == 35) { csvstring +=  formatDate( asOfDate, 'yyyy', 'en-US')+',';    } 
      else if(i == 38) {  csvstring += 'YearOfData,'; } else if(i == 39) { csvstring += FFPCoalYear +',';    } 
      else if(i == 45) {  csvstring += 'YearOfData,'; } else if(i == 46) { csvstring += FFPOGYear +',';    } 
      else if(i == 52) {  csvstring += 'YearOfData,'; } else if(i == 53) { csvstring += 'Latest (2Y max lag)' +','; }  //" TPIMQYear +',';    } 
      else { csvstring += ',';  }
      }
      csvstring += '\r\n';

      
      //Line 5
    for(var i = 0; i <57; i++){ 
      if(i == 11) {  csvstring += 'DatasetDate,'; } else if(i == 12) { csvstring += WaciDate +',';  } 
      else if(i == 19) {  csvstring += 'DatasetDate,'; } else if(i == 20) { csvstring += WaciDate +',';    }  //csvstring += EvicaeDate +',';   
      else if(i == 27) {  csvstring += 'DatasetDate,'; } else if(i == 28) { csvstring += GRDate +',';   }  
      else if(i == 34) {  csvstring += 'DatasetDate,'; } else if(i == 35) { csvstring += formatDate( asOfDate, 'dd/MM/yyyy', 'en-US')+',';     } 
      else if(i == 38) {  csvstring += 'DatasetDate,'; } else if(i == 39) { csvstring += FFPCoalDate +',';    } 
      else if(i == 45) {  csvstring += 'DatasetDate,'; } else if(i == 46) { csvstring += FFPOGDate +',';   } 
      else if(i == 52) {  csvstring += 'DatasetDate,'; } else if(i == 53) { csvstring += TPIMQDate +',';  } 
      else { csvstring += ',';  }
      }
      csvstring += '\r\n';

    return csvstring;
  }

  getCSV(params: GridData<any>, reportFor: string = '', asOfDate: Date = new Date(), configData: any = null, portfolioType: string = '') :string {
    var csvstring : any = '';
    if(reportFor ==  'ClimateReport'){
     // var asofDateforCSV= params[0].asofDate;
      csvstring = this.generateCSV(csvstring, asOfDate, configData);

      for(var p=0; p<params.columns.length;p++ ) { 
        if(params.columns[p] == 'sedol'){ params.columns[p] = 'SEDOL'; }
        if(params.columns[p] == 'cusip'){ params.columns[p] = 'CUSIP'; }
        if(params.columns[p] == 'isin'){ params.columns[p] = 'Isin'; }
        if(params.columns[p] == 'consCode'){ params.columns[p] = 'ConsCode'; }
        if(params.columns[p] == 'name'){ params.columns[p] = 'Name'; }
        if(params.columns[p] == 'countryCode'){ params.columns[p] = 'CountryCode'; }
        if(params.columns[p] == 'icbIndustry'){ params.columns[p] = 'ICBIndustry'; }
        if(params.columns[p] == 'icbIndustryCode'){ params.columns[p] = 'ICBIndCode'; }
        if(params.columns[p] == 'pfWeight'){ params.columns[p] =  portfolioType == 'Benchmark Analysis' ?  'IDXWeight' : 'PFWeight'; }
        if(params.columns[p] == 'bmWeight'){ params.columns[p] = 'BMWeight'; }
        if(params.columns[p] == 'pfMktValue'){ params.columns[p] = portfolioType == 'Benchmark Analysis' ?  'IDXMktValue' : 'PFMktValue'; }

        if(params.columns[p] == 'scope1and2PerRevenueRatio_tCO2ePerUSDm_Scope1GHG'){ params.columns[p] = 'Scope1GHG'; }
        if(params.columns[p] == 'scope1and2PerRevenueRatio_tCO2ePerUSDm_Scope2GHG'){ params.columns[p] = 'Scope2GHG'; }
        if(params.columns[p] == 'scope1and2PerRevenueRatio_tCO2ePerUSDm_Revenue'){ params.columns[p] = 'Revenue'; }
        if(params.columns[p] == 'revenueRatio_Scope1and2PerRevenueRatio'){ params.columns[p] = 'Scope1and2PerRevenueRatio'; }
        if(params.columns[p] == 'scope1and2PerRevenueRatio_tCO2ePerUSDm_PFRatedWeight'){ params.columns[p] = portfolioType == 'Benchmark Analysis' ?  'IDXRatedWeight' : 'PFRatedWeight'; }
        if(params.columns[p] == 'scope1and2PerRevenueRatio_tCO2ePerUSDm_PFContributionToWA'){ params.columns[p] = portfolioType == 'Benchmark Analysis' ?  'IDXContributionToWA' : 'PFContributionToWA'; }
        if(params.columns[p] == 'scope1and2PerRevenueRatio_tCO2ePerUSDm_BMRatedWeight'){ params.columns[p] = 'BMRatedWeight'; }
        if(params.columns[p] == 'scope1and2PerRevenueRatio_tCO2ePerUSDm_BMContributionToWA'){ params.columns[p] = 'BMContributionToWA'; }

        if(params.columns[p] == 'scope1and2PerEVICRatio_tCO2ePerUSDm_Scope1GHG'){ params.columns[p] = 'Scope1GHG'; }
        if(params.columns[p] == 'scope1and2PerEVICRatio_tCO2ePerUSDm_Scope2GHG'){ params.columns[p] = 'Scope2GHG'; }
        if(params.columns[p] == 'scope1and2PerEVICRatio_tCO2ePerUSDm_EVIC'){ params.columns[p] = 'EVIC'; }
        if(params.columns[p] == 'evicRatio_Scope1and2PerEVICRatio'){ params.columns[p] = 'Scope1and2PerEVICRatio'; }
        if(params.columns[p] == 'scope1and2PerEVICRatio_tCO2ePerUSDm_PFRatedWeight'){ params.columns[p] =  portfolioType == 'Benchmark Analysis' ?  'IDXRatedWeight' : 'PFRatedWeight'; }
        if(params.columns[p] == 'scope1and2PerEVICRatio_tCO2ePerUSDm_PFContributionToWA'){ params.columns[p] =  portfolioType == 'Benchmark Analysis' ?  'IDXContributionToWA' : 'PFContributionToWA'; }
        if(params.columns[p] == 'scope1and2PerEVICRatio_tCO2ePerUSDm_BMRatedWeight'){ params.columns[p] = 'BMRatedWeight'; }
        if(params.columns[p] == 'scope1and2PerEVICRatio_tCO2ePerUSDm_BMContributionToWA'){ params.columns[p] = 'BMContributionToWA'; }

        if(params.columns[p] == 'grPerRev_GRPerRevenueTier123'){ params.columns[p] = 'GRPerRevenueTier123'; }
        if(params.columns[p] == 'grPerRev_GRPerRevenueTier12'){ params.columns[p] = 'GRPerRevenueTier12'; }
        if(params.columns[p] == 'grPerRev_GRPerRevenueEUTaxonomy'){ params.columns[p] = 'GRPerRevenueEUTaxonomy'; }
        if(params.columns[p] == 'grPerRev_PFRatedWeight'){ params.columns[p] =  portfolioType == 'Benchmark Analysis' ?  'IDXRatedWeight' : 'PFRatedWeight'; }
        if(params.columns[p] == 'grPerRev_PFContributionToWA'){ params.columns[p] =  portfolioType == 'Benchmark Analysis' ?  'IDXContributionToWA' : 'PFContributionToWA'; }
        if(params.columns[p] == 'grPerRev_BMRatedWeight_Pct'){ params.columns[p] = 'BMRatedWeight_Pct'; }
        if(params.columns[p] == 'grPerRev_BMContributionToWA'){ params.columns[p] = 'BMContributionToWA'; }

        if(params.columns[p] == 'expToFFICBSubsectors_ICBSubsector'){ params.columns[p] = 'ICBSubsector'; }
        if(params.columns[p] == 'expToFFICBSubsectors_ICBSubsectorCode'){ params.columns[p] = 'ICBSubsectorCode'; }
        if(params.columns[p] == 'expToFFICBSubsectors_PFRatedWeight'){ params.columns[p] =  portfolioType == 'Benchmark Analysis' ?  'IDXRatedWeight' : 'PFRatedWeight'; }
        if(params.columns[p] == 'expToFFICBSubsectors_BMRatedWeight'){ params.columns[p] = 'BMRatedWeight'; }
        
        // ---- FFRPCO2Coal1P_Revenue  ==> Revenue
        if(params.columns[p] == 'ffrpcO2Coal1P'){ params.columns[p] = 'FFRPCO2Coal1P'; }
        if(params.columns[p] == 'ffrpcO2Coal1P_Revenue'){ params.columns[p] = 'Revenue'; }
        if(params.columns[p] == 'ffrpcO2Coal1PPerRevenueRatio'){ params.columns[p] = 'FFRPCO2Coal1PPerRevenueRatio'; }
        if(params.columns[p] == 'ffrpcO2Coal1P_PFRatedWeight'){ params.columns[p] =  portfolioType == 'Benchmark Analysis' ?  'IDXRatedWeight' : 'PFRatedWeight'; }
        if(params.columns[p] == 'ffrpcO2Coal1P_PFContributionToWA'){ params.columns[p] =  portfolioType == 'Benchmark Analysis' ?  'IDXContributionToWA' : 'PFContributionToWA'; }
        if(params.columns[p] == 'ffrpcO2Coal1P_BMRatedWeight'){ params.columns[p] = 'BMRatedWeight'; }
        if(params.columns[p] == 'ffrpcO2Coal1P_BMContributionToWA'){ params.columns[p] = 'BMContributionToWA'; }

        if(params.columns[p] == 'ffrpcO2OilandGas2Por1P'){ params.columns[p] = 'FFRPCO2OilandGas2Por1P'; }
        if(params.columns[p] == 'ffrpcO2OilandGas2Por1P_Revenue'){ params.columns[p] = 'Revenue'; }
        if(params.columns[p] == 'ffrpcO2OilandGas2Por1PPerRevenueRatio'){ params.columns[p] = 'FFRPCO2OilandGas2Por1PPerRevenueRatio'; }
        if(params.columns[p] == 'ffrpcO2OilandGas2Por1P_PFRatedWeight'){ params.columns[p] =  portfolioType == 'Benchmark Analysis' ?  'IDXRatedWeight' : 'PFRatedWeight'; }
        if(params.columns[p] == 'ffrpcO2OilandGas2Por1P_PFContributionToWA'){ params.columns[p] =  portfolioType == 'Benchmark Analysis' ?  'IDXContributionToWA' : 'PFContributionToWA'; }
        if(params.columns[p] == 'ffrpcO2OilandGas2Por1P_BMRatedWeight'){ params.columns[p] = 'BMRatedWeight'; }
        if(params.columns[p] == 'ffrpcO2OilandGas2Por1P_BMContributionToWA'){ params.columns[p] = 'BMContributionToWA'; }
             
        if(params.columns[p] == 'tpimqScore_0To5Score_TPIMQScore'){ params.columns[p] = 'TPIMQScore'; }
        if(params.columns[p] == 'tpimqScore_0To5Score_PFRatedWeight'){ params.columns[p] =  portfolioType == 'Benchmark Analysis' ?  'IDXRatedWeight' : 'PFRatedWeight'; }
        if(params.columns[p] == 'tpimqScore_0To5Score_PFContributionToWA'){ params.columns[p] = portfolioType == 'Benchmark Analysis' ?  'IDXContributionToWA' : 'PFContributionToWA'; }
        if(params.columns[p] == 'tpimqScore_0To5Score_BMRatedWeight_Pct'){ params.columns[p] = 'BMRatedWeight_Pct'; }
        if(params.columns[p] == 'tpimqScore_0To5Score_BMContributionToWA'){ params.columns[p] = 'BMContributionToWA'; } 
      }
    }
    
    csvstring += params.columns.join(',');
    csvstring += '\r\n';

    if(reportFor ==  'ClimateReport'){
      var SubHeaderColumnNames : string[] = ['Code', 'Code', 'Code', 'Code', 'Name', 'Code', 'Category', 'Code', 'Pct', 'Pct', 'USD', 'tCO2e', 'tCO2e', 'USD', 'tCO2ePerUSDm', 'Pct', 'tCO2ePerUSDm', 'Pct', 'tCO2ePerUSDm',
      'tCO2e', 'tCO2e', 'USD', 'tCO2ePerUSDm', 'Pct', 'tCO2ePerUSDm', 'Pct', 'tCO2ePerUSDm', 'PctShare', 'PctShare', 'PctShare', 'Pct', 'PctShare', 'Pct', 'PctShare', 'Code', 'Category', 'Pct', 'Pct', 'Bn tCO2', 'USD', 'tCO2PerUSDm', 'Pct', 'tCO2PerUSDm', 'Pct', 'tCO2PerUSDm', 'Bn tCO2', 'USD', 'tCO2PerUSDm', 'Pct', 'tCO2PerUSDm', 'Pct', 'tCO2PerUSDm',  '0To5Score', 'Pct', '0To5Score', 'Pct', '0To5Score'];

      for(var i=0;i<SubHeaderColumnNames.length;i++){
        csvstring += SubHeaderColumnNames[i] +',';
      }
      csvstring += '\r\n';
    }

    for(var i=0;i<params.model.length;i++) {
        for(var j=0; j<params.columns.length;j++ ) {
             if(params.model[i][params.columns[j]] == undefined)
            {
              params.model[i][params.columns[j]]= '';
            }
           // csvstring +=  params.model[i][params.columns[j]] + "," ;
            if(reportFor == '' &&  params.columns[j] == 'asOfDate'){ 
              params.model[i][params.columns[j]] = (params.model[i][params.columns[j]] == "") ? null : 
               moment(params.model[i][params.columns[j]]).format("MM/DD/YYYY") ; //formatDate(params.model[i][params.columns[j]], 'yyyy-MM-dd', 'en-US'); 
              csvstring += params.model[i][params.columns[j]].toString().replace(/,/g, ' ').replace("'", "")  + "," ;
            } else
            csvstring += params.model[i][params.columns[j]].toString().replace(/,/g, ' ') + "," ;
          }
        csvstring += '\r\n';
      }
   for(var i=0;i<params.data.length;i++) {
      for(var j=0; j<params.data[i].length;j++ ){
      if(params.data[i][j] == undefined){
        params.data[i][j]= '';    }
       // csvstring +=  params.data[i][j] + "," ;
        csvstring +=  params.data[i][j].toString().replace(/,/g, ' ') + "," ;
    }
    csvstring += '\r\n';
    }

    if(reportFor ==  'ClimateReport'){
      csvstring += '\r\nNotes:';
      csvstring += '\r\n'+ '"ExposureToFossilFuelsICBSubsectors: Based on Integrated Oil and Gas, Oil: Crude Producers, Offshore Drilling and Other Services, Oil Refining and Marketing, Oil Equipment and Services, Pipelines, Coal, Conventional Electricity (note: can include nuclear energy), Gas Distribution, Multi-Utilities"'; 
      csvstring += '\r\nSome BM data may not be displayed when similar data are not applicable to the PF';
    }

    return csvstring ;
}


  getCSVString(params: GridData<any>): string {
    const {columns, data} = this.getEscapedData(params);
    return [
      columns.join(','),
      ...(data.map(r => r.join(',')) || [])
    ].join('\r\n');
  }

  getEscapedData({data, columns}: { columns: string[], data: string[][] }): { columns: string[], data: string[][] } {
    return {
      data: (data || []).map(r => (r || []).map(this.escape)),
      columns: (columns || []).map(this.escape)
    };
  }
  constructor(private errorService: ErrorService) { }

  escape(s: string = ''): string {
    return `"${s.replace(/"/g, '""')}"`;
  }

  sanitizeStringLiteral(userName) {
    var res = "";
    var intermediate = "";
    for(var counter = userName.length - 1;counter>=0; counter--){
        if(userName[counter]!= '\\'){
            intermediate+=userName[counter];
        }
    }
    for(var lastCounter = intermediate.length - 1; lastCounter>=0;lastCounter--){
        res+=intermediate[lastCounter];
    }
    return res;
}
}
