import { Injectable }    from '@angular/core';
import {IDWChartXlsExportService} from './idw-chart-xls-export.service';
import {IDWChartPngExportService} from './idw-chart-png-export.service';

@Injectable()
export class IDWChartExportService {
    fileExtenstionExportMethodMatrix:any[] = [];
    dataInput :any;
    constructor(private idwChartXlsExportService:IDWChartXlsExportService
        , private idwChartPngExportService:IDWChartPngExportService){
        this.fileExtenstionExportMethodMatrix["xls"] = this.xlsExportMethod;
        this.fileExtenstionExportMethodMatrix["png"] = this.pngExportMethod;
    }

    export(inputData:any){
        var exportMethod = this.fileExtenstionExportMethodMatrix[inputData["exportType"]];
        this.dataInput = inputData;
        exportMethod.apply(this);
    }

    private xlsExportMethod(){
        this.idwChartXlsExportService.exportData(this.dataInput);
    }

    private pngExportMethod(){
       this.idwChartPngExportService.exportImage(this.dataInput);
       
    }

   
}