import { Component, OnInit } from '@angular/core';
import {ReportsService} from '../../services/reports.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {NewReportPopupComponent} from '../../components/new-report-popup/new-report-popup.component';
import {NewReportForm, Report} from '../../models/report';

@Component({
  selector: 'app-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss']
})
export class ReportsPageComponent implements OnInit {

  dataSource$ = this.reportsService.fetchReports$(0,5);
  baseDialogConfig: MatDialogConfig = {
    closeOnNavigation: true,
    panelClass: 'custom-popup-panel',
  };
  constructor(private reportsService: ReportsService, private dialog: MatDialog) {}

  ngOnInit(): void {
  }

  openDialog(): void {
    const dialogRef: MatDialogRef<NewReportPopupComponent, NewReportForm> = this.dialog.open(NewReportPopupComponent);

    dialogRef.afterClosed().subscribe((reportForm) => {
      if (reportForm) {
        var that = this;
        this.reportsService.createReport(reportForm).subscribe((res) => {  
          that.dataSource$ = this.reportsService.fetchReports$(0,5);
        });
      }
    });
  }

  editReport(report: NewReportForm) {
    const dialogRef: MatDialogRef<NewReportPopupComponent, NewReportForm> = this.dialog.open(NewReportPopupComponent, {
      ...this.baseDialogConfig,
      data: report,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var that = this;
        console.log(data);
        this.reportsService.updatePortfolio(data).subscribe(result => {            
          that.dataSource$ = this.reportsService.fetchReports$(0,5);
        });
        ;
      }
    });
  }
  ngAfterViewInit(){
    let yourElem= <HTMLElement>document.querySelector('.fa-angle-double-left');
    if(yourElem!=null)
    {
    yourElem.click();
    }
  }
}
