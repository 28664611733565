import { Component, Input,  ViewChild, AfterViewInit,Output,EventEmitter } from '@angular/core';
import { ChartOptionsComponent } from '../chart-options/chart-options.component';
import {DateService} from '../../services/date.service';
import { LandingService } from '../../services/landing.service';

@Component({
  selector: 'chart-toolbar',
  templateUrl: './chart-toolbar.component.html'
})
export class ChartToolbarComponent implements AfterViewInit {

  @Input() title: string;
  @Input() asofDates: string;
  @Input() showChartOptions: boolean = true;
  @Input() showPopUpOptions: boolean = true;
  @Input() showGraphOptions: boolean = true  ;
  @Input() showSettingOptions: boolean = true;
  @Input() showWidget: boolean = true;
  @Input() showExportPNG:boolean = true;
  @ViewChild(ChartOptionsComponent,{static: false}) chartOptions;
  @Output() exportToolbarElement: EventEmitter<string> = new EventEmitter();
  @Output() exportToolbarPNG: EventEmitter<string> = new EventEmitter();
  @Output() PopupToolbarElement: EventEmitter<string> = new EventEmitter();
  @Input() asofDatesVisible: boolean = false;
  @Output() PopupInfoElement: EventEmitter<string> = new EventEmitter();

  constructor(private dateService:DateService,private landingService: LandingService,) { }

  ngOnInit() {
    this.asofDates = this.dateService.getCalendarDate().end;
  }


  ngAfterViewInit() {
    this.showChartOptions = this.chartOptions.visible;
    this.showPopUpOptions = this.chartOptions.popUpVisible;
    this.showGraphOptions = this.chartOptions.graphVisible;
    this.showSettingOptions = this.chartOptions.settingVisible;
    this.showWidget = this.chartOptions.widgetVisible;    
  }

  ExportXls(event):void{
    this.exportToolbarElement.emit(' ');
  }

  ExportPNG(event):void{
    this.exportToolbarPNG.emit(' ');
  }

  PopupChart(event):void{
    this.PopupToolbarElement.emit(' ');
  }

  PopupWidget(event):void{
    this.PopupInfoElement.emit(' ');
  }
}