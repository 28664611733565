import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-set-value-dialog',
  templateUrl: './go-to-page-popup.component.html'
})
export class GoToPageDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<GoToPageDialogComponent>) { }

  @HostListener('window:keyup.Enter', ['$event'])
  onDialogClick(event: KeyboardEvent): void {
    this.close();
  }

  input: string;

  get checkInvalid() {
    if (this.input == '' || this.input == null) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close(this.input);
  }
}