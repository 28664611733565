export const environment = {
    production: false,
    adfsLogoutUrl: "https://sts.lseg.com/adfs/ls/?wa=wsignout1.0&wreply=",
swaggerApiUrl: "https://7n0typjaaa.execute-api.us-east-1.amazonaws.com/QA",
cognitoUserPoolId: "eu-west-1_USTZ7PRp1",
clientId: "2ki5ruva4ven84mbi12alve95n",
region: "eu-west-1",
identityPool: "eu-west-1:793dc1c9-eb43-4375-a606-2ce5830350b0",
adfsUrl: "https://myapps.microsoft.com/signin/7279e1cb-8395-49e8-adb2-9ce514160b75?tenantId=287e9f0e-91ec-4cf0-b7a4-c63898072181",
samlIdpArn: "arn:aws:iam::464420683225:saml-provider/Azure_AD",
roleSelectedArn: "arn:aws:iam::464420683225:role/ftse-ciidw-qa-cognito-identitypool-auth-role",
relayingPartyId: "https://qa-clientportal.ftserussell.com/",
accountId: "464420683225",
environmentName: "QA",
dataServiceRoot: "https://7r86kqtcf6.execute-api.eu-west-1.amazonaws.com/QA/",
dateFormat: "MM/dd/yyyy",
coreSecret: "thisismyubersecuresecret",
  downloadBucket: "q-idw-fo-ui-redshiftunload-s3",
  deltaBucket: "ftse-ciidw-qa-delta-file-s3",
awsBasicUrl: "https://s3.amazonaws.com/",
role: "Admin",
servicePortal: "http://q-serviceportal-fo-ui-s3.s3-website-us-east-1.amazonaws.com",
adminGroup: "RES-RUS-IDW-Admin",
sedolOpsGroup: "RES-RUS-IDW-SedolOps",
siOpsGroup: "RES-RUS-IDW-SIOPS",
clientServiceOpsGroup:"RES-RUS-IDW-ClientServiceOps",
siAdminGroup: "RES-RUS-IDW-SI-Admin",
"cognitoUserId": "optimustest",
"cognitoUserPassword": "Welcome@1234",
}
