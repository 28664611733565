import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BiService } from '../../services/BiService.service';
import {UserTabChangeService} from '../../services/user-tab-change.service';
import { DataqualityserviceService } from '../../services/dataqualityservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FundamentalsReportComponent } from '../fundamentals-report/fundamentals-report.component';

export class TabInfo {
  displayText: string;
  routerLink: string;
  isClicked: boolean;
}
@Component({
  selector: 'top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})

export class TopNavComponent implements OnInit {
  selectedTab : string;
  hold :string="" ;
  
  isCollapsable:boolean = true;
  routeDataSourceNameMacher: any[] = [];
  defaultDataSourceName = "IndexReturns";
  client : string ;
  clients: any[]= [ { "key": "", "value": "Loading" }];
  defaultClient:string="ALL";
  link :any ;
  url:string;
  TABS: TabInfo[] = [
    { displayText: "Holdings", routerLink: "/closeHoldings",isClicked: false  },
    { displayText: "Performance", routerLink: "/performance",isClicked: false },
    { displayText: "Values", routerLink: "/values",isClicked: false },
    { displayText: "Sector", routerLink: "/weights",isClicked: false },
    { displayText: "Reporting", routerLink: "/reporting",isClicked: false },
    { displayText: "Reference", routerLink: "/reference",isClicked: false },
    { displayText: "Portfolios", routerLink: "/pbr",isClicked: false },
    { displayText: "Re Issue", routerLink: "/ddstracker",isClicked: false },
    { displayText: "Data Quality", routerLink: "other",isClicked: false },
    { displayText: "Alternate Data Delivery", routerLink:"/alternate-data-delivery", isClicked:false}
    ];
    DATAQUALITYTABS: TabInfo[] =
    [
      { displayText: "Summary", routerLink: "/summary-data-quality",isClicked: false },
      { displayText: "Close Price", routerLink: "/price-data-quality",isClicked: false },
      {displayText:"Market Value ", routerLink:"/market-value-dataquality", isClicked: false},
      { displayText: "Open Price", routerLink: "/openprice-data-quality",isClicked: false },
      { displayText: "Shares Outstanding", routerLink: "/shares-data-quality",isClicked: false },
      { displayText: "Country", routerLink: "/country-data-quality",isClicked: false },
      { displayText: "RGS Sector", routerLink: "/rgs-sector-data-quality", isClicked: false},
      { displayText: "ICB Sector", routerLink: "/sector-data-quality", isClicked: false},
      { displayText: "NICB Sector", routerLink: "/nicb-sector-data-quality", isClicked: false}

    ];
  ENTITLEMENTTABS: TabInfo[] =
  [
    { displayText: "Company", routerLink: "/company-search" ,isClicked: false},
    { displayText: "APIKeys", routerLink: "/apikey-search" ,isClicked: false},
    { displayText: "User Profile", routerLink: "/user-search",isClicked: false },
    { displayText: "APIs", routerLink: "/company-api-search",isClicked: false },
    { displayText: "Index Of Interest", routerLink: "/index-of-interest", isClicked: false},
    { displayText: "License Bands", routerLink: "/licence-search-bands", isClicked: false}
  ];

  AUTHORIZATIONTABS: TabInfo[] =
  [
    { displayText: "User / Groups Setup", routerLink: "/usergroup-search" ,isClicked: false},
    { displayText: "Role Page Mapping", routerLink: "/role-page-mapping-search",isClicked: false},
    { displayText: "User Role Mapping", routerLink: "/user-role-mapping", isClicked: false },
    { displayText: "Menu Items", routerLink: "/menu-items", isClicked: false}

  ];

  CLIENTSTABS: TabInfo[] =
  [
    { displayText: "API Report", routerLink: "/apireport",isClicked: false },
    { displayText: "Waves", routerLink: "/waves",isClicked: false },
    { displayText: "KPIs", routerLink: "/lam",isClicked: false },
    { displayText: "API Validation Test", routerLink: "/performance-indicators-report",isClicked: false }
  ];

  TRACKINGTABS: TabInfo[] =
  [
    { displayText: "File Status", routerLink: "/fileloadstatus",isClicked: false }

  ];

  ReviewsTab: TabInfo[] =
  [
    { displayText: "Alerts/Notifications", routerLink: "/reviewalerts",isClicked: false},
    { displayText: "Review Schedule", routerLink: "/reviewschedules",isClicked: false},
    { displayText: "Review Products", routerLink: "/reviewproducts",isClicked: false },
    { displayText: "Delayed-On Hold", routerLink: "/delayedonhold",isClicked: false }
  ];


  constructor( private biService: BiService,
    private userTabChangeService:UserTabChangeService ,public router:Router ,private dataqualityservice:DataqualityserviceService) {
      console.log("UserRoles" +  sessionStorage.getItem("UserRoles"));
    this.url= router.url;
    if (sessionStorage.getItem("UserRoles") == environment.role || sessionStorage.getItem("UserRoles").indexOf(environment.adminGroup) !== -1) {
      console.log("ADMIN Page");
      this.TABS.push(
        { displayText: "Factors", routerLink: "/factors",isClicked: false },
        { displayText: "Entitlement", routerLink: "other",isClicked: false },
        { displayText: "Client", routerLink: "other" ,isClicked: false},
        { displayText: "Tracking", routerLink: "other",isClicked: false },
        { displayText: "Review Timetable", routerLink: "reviews",isClicked: false },
        { displayText: "Telemetry Logs", routerLink: "/telemetry-logs",isClicked: false},
        { displayText: "Sedol Alert", routerLink: "/sedol-search",isClicked: false},
        { displayText: "Authorization", routerLink: "other",isClicked: false}
      );
    }
    else if(sessionStorage.getItem("UserRoles").indexOf(environment.sedolOpsGroup) !== -1) {
      this.TABS.push(
        { displayText: "Entitlement", routerLink: "other",isClicked: false },
        { displayText: "Telemetry Logs", routerLink: "/telemetry-logs",isClicked: false},
        { displayText: "Sedol Alert", routerLink: "/sedol-search",isClicked: false}
      );
    }
    this.TABS.push({ displayText: "", routerLink: "/search" ,isClicked: false});
  }
  ngOnInit(): void {
    this.routeDataSourceNameMacher["CloseHoldings"] = "CloseHoldings";
    this.routeDataSourceNameMacher["Holdings"] = "CloseHoldings";
    this.routeDataSourceNameMacher["OpenHoldings"] = "OpenHoldings";
    this.routeDataSourceNameMacher["Values"] = "Values";
    this.routeDataSourceNameMacher["Weights"] = "CloseHoldings";
    this.routeDataSourceNameMacher["Performance"] = "IndexReturns";
    this.selectedTab = "";
  }
removeSubSelection():void{
  if(  this.DATAQUALITYTABS.find(i=>i.isClicked==true)){
    this.DATAQUALITYTABS.find(i=>i.isClicked==true).isClicked=false;
    }
  if(  this.ENTITLEMENTTABS.find(i=>i.isClicked==true)){
    this.ENTITLEMENTTABS.find(i=>i.isClicked==true).isClicked=false;
    }
    if(  this.CLIENTSTABS.find(i=>i.isClicked==true)){
      this.CLIENTSTABS.find(i=>i.isClicked==true).isClicked=false;
      }
      if(  this.TRACKINGTABS.find(i=>i.isClicked==true)){
        this.TRACKINGTABS.find(i=>i.isClicked==true).isClicked=false;
        }
        if(  this.ReviewsTab.find(i=>i.isClicked==true)){
          this.ReviewsTab.find(i=>i.isClicked==true).isClicked=false;
          }
          if(  this.AUTHORIZATIONTABS.find(i=>i.isClicked==true)){
            this.AUTHORIZATIONTABS.find(i=>i.isClicked==true).isClicked=false;
            }
}

removeAllSelection():void{
  if(  this.TABS.find(i=>i.isClicked==true)){
    this.TABS.find(i=>i.isClicked==true).isClicked=false;
    }
    this.removeSubSelection();
    this.ngOnInit();
}
  handleClick(tabName): void {
    this.removeSubSelection();
    this.hold = tabName;
    if(tabName == "Holdings")
    {
      this.hold = "CloseHoldings"
    }
    if(  this.TABS.find(i=>i.isClicked==true)){
    this.TABS.find(i=>i.isClicked==true).isClicked=false;
    }
    if( tabName=='CloseHoldings' || tabName=='OpenHoldings')
    {
      this.TABS.find(i => i.displayText == 'Holdings').isClicked=true;
      tabName = "Holdings";
    }
    if(this.TABS.find(i => i.displayText == tabName))
    {
      this.TABS.find(i => i.displayText == tabName).isClicked=true;
      var selectedRoute = this.TABS.find(i => i.displayText == tabName).routerLink;
      this.selectedTab = tabName;
    }

    var isRouteExists = (tabName in this.routeDataSourceNameMacher)?true:false;
    this.userTabChangeService.publish((isRouteExists == true)?this.routeDataSourceNameMacher[tabName]
    :this.defaultDataSourceName);

    this.biService.addEvent2("TopNav Click", [{ Name: "item", Value: tabName }]);

    if(this.selectedTab == 'Data Quality')
    {
      this.defaultClient="ALL";
        this.dataqualityservice.getClient().then(data=>{
            this.clients=(data.length>0)? data: [ { "key": "", "value": "Currency not found" }];
        });
    }
}
handleDataQualityClick(tabName): void {

  if(  this.DATAQUALITYTABS.find(i=>i.isClicked==true)){
  this.DATAQUALITYTABS.find(i=>i.isClicked==true).isClicked=false;
  }
  if(this.DATAQUALITYTABS.find(i => i.displayText == tabName))
  {
    this.DATAQUALITYTABS.find(i => i.displayText == tabName).isClicked=true;
    this.link =this.DATAQUALITYTABS.find(i => i.displayText == tabName).routerLink
    this.router.navigate([this.DATAQUALITYTABS.find(i => i.displayText == tabName).routerLink , {Client:this.defaultClient}]);
      }
  this.biService.addEvent2("SubNav Click", [{ Name: "item", Value: tabName }]);
}
handleEntitlementClick(tabName): void {

  if(  this.ENTITLEMENTTABS.find(i=>i.isClicked==true)){
  this.ENTITLEMENTTABS.find(i=>i.isClicked==true).isClicked=false;
  }
  if(this.ENTITLEMENTTABS.find(i => i.displayText == tabName))
  {
    this.ENTITLEMENTTABS.find(i => i.displayText == tabName).isClicked=true;
      }
  this.biService.addEvent2("SubNav Click", [{ Name: "item", Value: tabName }]);
}
handleTrackingClick(tabName): void {

  if(  this.TRACKINGTABS.find(i=>i.isClicked==true)){
  this.TRACKINGTABS.find(i=>i.isClicked==true).isClicked=false;
  }
  if(this.TRACKINGTABS.find(i => i.displayText == tabName))
  {
    this.TRACKINGTABS.find(i => i.displayText == tabName).isClicked=true;
      }
  this.biService.addEvent2("SubNav Click", [{ Name: "item", Value: tabName }]);
}
handleAuthorizationClick(tabName): void {

  if(  this.AUTHORIZATIONTABS.find(i=>i.isClicked==true)){
  this.AUTHORIZATIONTABS.find(i=>i.isClicked==true).isClicked=false;
  }
  if(this.AUTHORIZATIONTABS.find(i => i.displayText == tabName))
  {
    this.AUTHORIZATIONTABS.find(i => i.displayText == tabName).isClicked=true;
      }
  this.biService.addEvent2("SubNav Click", [{ Name: "item", Value: tabName }]);
}

handleReviewClick(tabName): void {

  if(  this.ReviewsTab.find(i=>i.isClicked==true)){
  this.ReviewsTab.find(i=>i.isClicked==true).isClicked=false;
  }
  if(this.ReviewsTab.find(i => i.displayText == tabName))
  {
    this.ReviewsTab.find(i => i.displayText == tabName).isClicked=true;
      }
  this.biService.addEvent2("SubNav Click", [{ Name: "item", Value: tabName }]);
}

handleClientClick(tabName): void {

  if(  this.CLIENTSTABS.find(i=>i.isClicked==true)){
  this.CLIENTSTABS.find(i=>i.isClicked==true).isClicked=false;
  }
  if(this.CLIENTSTABS.find(i => i.displayText == tabName))
  {
    this.CLIENTSTABS.find(i => i.displayText == tabName).isClicked=true;
      }
  this.biService.addEvent2("SubNav Click", [{ Name: "item", Value: tabName }]);
}

setCollapseMenu(){
  this.isCollapsable = (this.isCollapsable == true)?false:true ;
}
getBackround(tab){
  return (tab.isClicked && tab.displayText == this.selectedTab)?"selectTabMenuNav":"nonSelectTabMenuNav";
}
getColor(tab){
  return (tab.isClicked && tab.displayText == this.selectedTab)?"selectTabMenuLink":"nonSelectTabMenuLink";
}
onClientChange(client: string) {
  if(this.link!= undefined){
  this.defaultClient = client;
  this.router.navigate([this.link ,{Client :this.defaultClient}]);
  var event: any = {eventName: "When changing the Client Name", inputParameters: [{Name: "Client", Value: client }]};
  this.biService.addEvent(event);
  }

}
}
