import { Component, OnInit,Inject,Input } from '@angular/core';
import {ReportService} from '../../services/report.service';
import { PublishIndexMarkerService } from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {DateService} from '../../services/date.service';
import { BooleanFlag } from '../../../../node_modules/aws-sdk/clients/directconnect';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import { BiService } from '../../services/BiService.service';
@Component({
    selector: 'fundamentals-report',
    templateUrl: './fundamentals-report.component.html',
    styleUrls: ['./fundamentals-report.component.css']
  })
  export class FundamentalsReportComponent implements OnInit {
    @Input() visible: boolean = false;
    showGeneral:boolean = true;
    showPortfolio:boolean = false;
    showDividend:boolean = false;
    showWeighted:boolean = false;
    showEPS: boolean = false;
    showPreTax:boolean = false;
    showReturns: boolean = false;
    showOthers:boolean = false;
    dateFormat: any;
    FundamentalsReportLoader:boolean=false;
    selectedFactor:string = "Outlier";
      asOfDate:any;
      indexMarkers:any[] = [];
      fundamentalsReports:any[];
      defaultSelectedMenu = "General";
      date :any[]=[];
    constructor(private reportService: ReportService,private dateService: DateService,
    private indexMarkerService: IndexMarkerService,private publishIndexMarkerService: PublishIndexMarkerService,
    private exportService:ExportToExcelService, private biService:BiService, private dialog: MatDialog){

    }
    ngOnInit(){
        this.asOfDate = this.dateService.getCalendarDate().end;
        this.getRussellIndexes(this.indexMarkerService.getIndexMarkers());
        this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
            this.fetchDataAfterIndexMarkerChange(result);
        });
        this.dateService.dateFormatPublish().subscribe(dateFormat=>{
            this.dateFormat =  dateFormat;                          
        })        
    }

    fetchDataAfterIndexMarkerChange(indexMarkers){
        this.indexMarkerService.getRussellIndexes(indexMarkers)
        .then(res=> {
           this.indexMarkers = res;           
           if(this.indexMarkers.length>=0){
               this.fetchData();
           }
        }).catch(err=>{
            console.log(err);
        });
    }
    getRussellIndexes(indexMarkers:any){
        this.indexMarkerService.getRussellIndexes(indexMarkers)
        .then(res=> {
           this.indexMarkers = res;
        }).catch(err=>{
            console.log(err);
        });
    }
    fetchData(){        
        if(this.indexMarkers.length >= 0){  
            this.FundamentalsReportLoader = true;
            this.asOfDate = this.dateService.getCalendarDate().end;
            this.reportService.getFundamentalsReports(this.asOfDate,this.indexMarkers,this.selectedFactor)
            .then(res=>{  
                if(res.length==0)
                {
                  this.fundamentalsReports = res; 
                    
                    this.reportService.getDate(this.indexMarkers).then(re=>{
                    this.date=re;
                    this.dateFormat= this.dateService.getDateFormat(); 
                    this.openDialog();

                });
                    this.FundamentalsReportLoader = false; 
                }else{           
               this.dateFormat = this.dateService.getDateFormat();
               this.fundamentalsReports = res;   
               this.FundamentalsReportLoader = false;
                }
            }).catch(err=>{
                this.FundamentalsReportLoader = false;
                console.log(err);
            });
        }
        
    }

    changeTabGeneral(){
        this.defaultSelectedMenu = "General";
        this.showGeneral = true;
        this.showPortfolio = false;
        this.showDividend = false;
        this.showWeighted = false;
        this.showEPS = false;
        this.showPreTax = false;
        this.showReturns = false;
        this.showOthers = false;
    }

    changeTabPortfolio(){
        this.defaultSelectedMenu = "Portfolio";
        this.showGeneral = false;
        this.showPortfolio = true;
        this.showDividend = false;
        this.showWeighted = false;
        this.showEPS = false;
        this.showPreTax = false;
        this.showReturns = false;
        this.showOthers = false;
    }

    changeTabDividend(){
        this.defaultSelectedMenu = "Dividend";
        this.showGeneral = false;
        this.showPortfolio = false;
        this.showDividend = true;
        this.showWeighted = false;
        this.showEPS = false;
        this.showPreTax = false;
        this.showReturns = false;
        this.showOthers = false;
    }

    changeTabWeighted(){
        this.defaultSelectedMenu = "Weighted";
        this.showGeneral = false;
        this.showPortfolio = false;
        this.showDividend = false;
        this.showWeighted = true;
        this.showEPS = false;
        this.showPreTax = false;
        this.showReturns = false;
        this.showOthers = false;
    }

    changeTabEPS(){
        this.defaultSelectedMenu = "EPS";
        this.showGeneral = false;
        this.showPortfolio = false;
        this.showDividend = false;
        this.showWeighted = false;
        this.showEPS = true;
        this.showPreTax = false;
        this.showReturns = false;
        this.showOthers = false;
    }

    changeTabPreTax(){
        this.defaultSelectedMenu = "Pretax";
        this.showGeneral = false;
        this.showPortfolio = false;
        this.showDividend = false;
        this.showWeighted = false;
        this.showEPS = false;
        this.showPreTax = true;
        this.showReturns = false;
        this.showOthers = false;
    }

    changeTabReturns(){
        this.defaultSelectedMenu = "Returns";
        this.showGeneral = false;
        this.showPortfolio = false;
        this.showDividend = false;
        this.showWeighted = false;
        this.showEPS = false;
        this.showPreTax = false;
        this.showReturns = true;
        this.showOthers = false;
    }

    changeTabOthers(){
        this.defaultSelectedMenu = "Others";
        this.showGeneral = false;
        this.showPortfolio = false;
        this.showDividend = false;
        this.showWeighted = false;
        this.showEPS = false;
        this.showPreTax = false;
        this.showReturns = false;
        this.showOthers = true;
    }

    getActiveDisplay(menuName){
         return (this.defaultSelectedMenu == menuName)?"selectTabMenu":"nonSelectTabMenu";
    }

    getActiveDisplayLink(menuNameLink){
        return (this.defaultSelectedMenu == menuNameLink)?"selectTabMenuLink":"nonSelectTabMenuLink";
    }
 
    FilterChanged(){
        this.fetchData();
    } 
    exportXls(){
        var event: any = {eventName: "Export Fundamentals Reports", inputParameters: [{Name : "AsOfDate" , Value: this.asOfDate},
        {Name : "IndexMarkers" , Value: this.indexMarkers}]};
        this.biService.addEvent(event);
         this.reportService.getFundamentalsReportsToExport(this.asOfDate,
        this.indexMarkers,this.selectedFactor).then(res => {
            this.exportService.exportData(res,"FundamentalsReportRawData");
        });
    }
    openDialog(): void{ 
        
        let dialogRef = this.dialog.open(FundComponentDialog, {
            width: '80%',
          
        data :this.date
       
        });
      
    }
}
@Component({
   
	templateUrl: './fundamentals-report.component.dialog.html'
})
export class FundComponentDialog{

    constructor(
    public dialogRef: MatDialogRef<FundComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    onClose(): void {
       
        this.dialogRef.close();
  }
}