import { Component, Inject, Input, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NewReportForm, PortfolioReportForm} from '../../models/report';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Report } from '../../models/report';
import { ReportsService } from '../../services/reports.service';
import { formatDate,DatePipe } from '@angular/common';

const currencies = ['USD'];
const assetsClasses = ['Equity','Fixed Income', 'Soverign'];
var templates = ['Portfoilo Report Type', 'PrimeID Portfolio Report Type', 'Benchmark Report Type', 'Prime ID Benchmark Report Type']// ['Corporate carbon footprint123'];
const metrics = ['Carbon intensity of revenues'];

@Component({
  selector: 'app-new-report-popup',
  templateUrl: './new-report-popup.component.html',
  styleUrls: ['./new-report-popup.component.scss']
})
export class NewReportPopupComponent implements OnInit {

  form: FormGroup = this.fb.group({
    id :[0],
    name: ['', Validators.required],
    asOfDate: [''],
    assetsClass: ['', Validators.required],
    benchmarkIndexMarker: [''],
    date: ['', Validators.required],
    metrics: ['', Validators.required],
    portfolioIndexMarker: ['', Validators.required],
    template: ['', Validators.required],
    currency: [''],
    portfolio : [''],
  } as Record<keyof NewReportForm, any[]>);

  currencies = currencies;
  assetsClasses = assetsClasses;
  templates = templates;
  metrics = metrics;
  isUpdateForm = false;  

  updateReport : boolean = false;
  isreportForm = false;
  parentData : any;
 public  asofdate:any;
 public  currencysel:any;
 public  benchmark:any;
 public portfoliosel: any;
 public assetclass: any;
 public template:any;
  
  constructor(private reportService: ReportsService,private fb: FormBuilder, public dialogRef: MatDialogRef<NewReportPopupComponent> ,@Inject(MAT_DIALOG_DATA) public rowdata: any) {
    console.log(rowdata);
   
   }

  ngOnInit(): void {
    this.form.patchValue({
      currency: this.currencies[0],
      assetsClass: this.assetsClasses[0],
      template: this.templates[0],
      metrics: this.metrics[0]
    } as Partial<Record<keyof NewReportForm, string>>);    

    if (this.rowdata) {
      debugger;
      console.log(this.rowdata);
      
      this.updateReport = true;
      this.form.patchValue({
        id : this.rowdata.id,
        name : this.rowdata.name,                
        asOfDate :this.rowdata.asOfDate,
        portfolioIndexMarker : this.rowdata.portfolioId,    
        currency : this.rowdata.currency,
        benchmarkIndexMarker : this.rowdata.benchMark,
        date : this.rowdata.reportDate,
        assetsClass : this.rowdata.assetClass,
        template : this.rowdata.template,
        metrics : this.rowdata.metrics,
       
      })
      debugger;
      const data = this.reportService.getPortfoliobyId(this.rowdata.portfolioId).then
        (data => {
          this.asofdate = this.getFormatedDate(data.asOfDate, 'MM/dd/yyyy');
          this.benchmark = data.benchMark;
          this.currencysel = data.currency;
          this.portfoliosel = data.portfolioType;
          this.assetclass = data.assetClass;
        });
     
      this.template = this.rowdata.template;
      this.updateTemplates();
    }                
  }

  onSubmit(): void {
    console.log(this.form.getRawValue());
    if (this.form.valid) {
      const data = this.form.getRawValue();
      data.benchmarkIndexMarker = this.benchmark;
      data.asOfDate = this.asofdate;
      data.currency = this.currencysel;
      data.assetsClass = this.assetclass;
       if(typeof(data.portfolioIndexMarker)  == 'number'){        
        data.portfolioIndexMarker = {id : this.rowdata.portfolioId, name : this.rowdata.portfolioName}
      }      
      this.dialogRef.close(data);
    }
    if (this.form.invalid) {
      this.isreportForm = true;
      return;
    }
  }
  public GetData(data: any): void {
    debugger;
    console.log('Parent Data: ', data);
    let newDate = new Date(data.asOfDate);
    this.asofdate = this.getFormatedDate(newDate, 'MM/dd/yyyy');
    this.benchmark = data.benchMark;
    this.currencysel = data.currency;
    this.portfoliosel = data.portfolioType;
    this.assetclass = data.assetClass;
    this.updateTemplates();
   
  }

  public updateTemplates():void {
    if(this.portfoliosel == "Portfolio Analysis"){
       if(this.assetclass == "Fixed Income")
       this.templates = ['PrimeID Portfolio Report Type']
       else 
       this.templates = ['Portfoilo Report Type', 'PrimeID Portfolio Report Type']
    }else {
        if(this.assetclass == "Fixed Income")
        this.templates = ['PrimeID Benchmark Report Type']
        else 
        this.templates = ['Benchmark Report Type', 'PrimeID Benchmark Report Type']
    }
    if(this.template == null)
      this.template = this.templates[0];
  }

getFormatedDate(date: Date, format: string) {
  const datePipe = new DatePipe('en-US');
  return datePipe.transform(date, format);
}


}
