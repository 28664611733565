import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {PortfolioForm} from '../../models/portfolio';

@Component({
  selector: 'app-new-portfolio-benchmark-popup',
  templateUrl: './new-portfolio-benchmark-popup.component.html',
  styleUrls: ['./new-portfolio-benchmark-popup.component.scss']
})
export class NewPortfolioBenchmarkPopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NewPortfolioBenchmarkPopupComponent>) {}

  ngOnInit(): void {}

  onSubmit(data: PortfolioForm): void {
    this.dialogRef.close(data);
  }
}
