import { Injectable }    from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import {CloseHoldingsChartModel} from '../models/close-holdings-chart-model';
import {TopLevelSector} from '../models/top-Level-Sector';
import {CloseHoldingsReturnsModel} from '../models/close-holdings-returns';
import {SectorWeight} from '../models/sector-weight-model';
import { ResponseModel } from '../models/landing-page-model';
import { Subject } from 'rxjs';

@Injectable()
export class WeightService{

    private headers = new Headers({'Content-Type': 'application/json'});

    public DataLoaded = new Subject<any>();
    constructor( private httpClient: HttpClient) { 

    }

    getTopLevelSector(asOfDate: any,indexMarkers:any,currencyCode:any):Promise<ResponseModel>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/weights/gettoplevelsector',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers,
                CurrencyCode:currencyCode
        }), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as ResponseModel
            })
        .catch(this.handleError);
    }

    getTopLevelSectorData(asOfDate:any,indexMarkers:any,returnType,currencyCode:any , startDate : any):Promise<CloseHoldingsReturnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.DataLoaded.next(null);
        return this.httpClient.post(environment.dataServiceRoot + 'ui/weights/gettoplevelsectordata',

            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers,
                ReturnType : returnType,
                CurrencyCode:currencyCode ,
                FromDate:startDate                 
        }), {headers: headers})
        .toPromise()
        .then(response =>{
            if(response == null ){
                this.DataLoaded.next("Nodata"); 
            } else  if( Object.keys(response).length ==0 ){
                this.DataLoaded.next("Nodata");
            }else{
            this.DataLoaded.next("Success"); 
            }
                return response as CloseHoldingsReturnsModel[];                
            })
            .catch((err) => { this.DataLoaded.next("Fail");  return this.handleError(err); }  ); 
    }

    getSectorWeight(asOfDate: any,indexMarkers:any,currencyCode:any):Promise<SectorWeight>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/weights/getsectorweight',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers,
                CurrencyCode:currencyCode
        }), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as SectorWeight
            })
        .catch(this.handleError);
    }


    getCountryWeights(asOfDate: any,indexMarkers : any,startDate:any) : Promise<CloseHoldingsChartModel> {
    
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/weights/getcountryweights',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers,
                FromDate:startDate         
               
        }), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CloseHoldingsChartModel
            })
        .catch(this.handleError);    
    }
    getCountryWeightsExportData(asOfDate: any,indexMarkers : any,startDate:any,timePeriod:any) : Promise<CloseHoldingsChartModel> {
    
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/weights/getCountryWeightsExport',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers,
                FromDate:startDate ,
                TimePeriod:timePeriod                
               
        }), {headers: headers})
        .toPromise()
        .then(response =>{
            if( Object.keys(response).length ==0 ){
                this.DataLoaded.next("Nodata"); 
            }else{
            this.DataLoaded.next("Success"); 
            }
                return response as CloseHoldingsChartModel
            })
            .catch((err) => { this.DataLoaded.next("Fail");  return this.handleError(err); }  );  
    }
    getSectorWeightsExportData(asOfDate: any,indexMarkers : any,returnType,startDate:any,timePeriod:any) : Promise<CloseHoldingsChartModel> {
    
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/weights/getSectorWeightsExport',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers ,
                ReturnType:returnType ,
                FromDate:startDate  ,
                TimePeriod:timePeriod              
               
        }), {headers: headers})
        .toPromise()
        .then(response =>{
            var data= response as CloseHoldingsChartModel;
            if( Object.keys(data).length ==0 ){
                this.DataLoaded.next("Nodata"); 
            }else{
            this.DataLoaded.next("Success"); 
            }
                return data ;
            })
            .catch((err) => { this.DataLoaded.next("Fail");  return this.handleError(err); }  );     
    }
    getSubSectorWeightsExportData(asOfDate: any,indexMarkers : any,returnType,startDate:any,timePeriod:any) : Promise<CloseHoldingsChartModel> {
    
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/weights/getSubSectorWeightsExport',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers ,
                ReturnType:returnType ,
                FromDate:startDate ,
                TimePeriod:timePeriod                
               
        }), {headers: headers})
        .toPromise()
        .then(response =>{
            if( Object.keys(response).length ==0 ){
                this.DataLoaded.next("Nodata"); 
            }else{
            this.DataLoaded.next("Success"); 
            }
                return response as CloseHoldingsChartModel
            })
            .catch((err) => { this.DataLoaded.next("Fail");  return this.handleError(err); }  );    
    }
    getIndustryWeights(asOfDate: any,indexMarkers : any,startDate:any) : Promise<CloseHoldingsChartModel> {
    
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/weights/getindustryweights',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers,
            FromDate:startDate       
               
        }), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CloseHoldingsChartModel
            })
        .catch(this.handleError);    
    }
    getSuperSectorWeights(asOfDate: any,indexMarkers : any,startDate:any) : Promise<CloseHoldingsChartModel> {
    
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/weights/getsupersectorweights',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers,
                FromDate:startDate        
               
        }), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CloseHoldingsChartModel
            })
        .catch(this.handleError);    
    }
	getIndustryWeightData(asOfDate:any,indexMarkers:any,returnType,currencyCode:any,startDate,timePeriod):Promise<CloseHoldingsReturnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/weights/getindustryweightsexport',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers,             
                CurrencyCode:currencyCode  ,
                ReturnType:returnType,
                FromDate:startDate ,
                TimePeriod:timePeriod                
        }), {headers: headers})
        .toPromise()
        .then(response =>{
            var data = response as CloseHoldingsReturnsModel[]
            if( data.length == 0 ){
                this.DataLoaded.next("Nodata"); 
            }else{
            this.DataLoaded.next("Success"); 
            }
                return data;
            })
            .catch((err) => { this.DataLoaded.next("Fail");  return this.handleError(err); }  );  
    }
    getSuperSectorWeightData(asOfDate:any,indexMarkers:any,returnType,currencyCode:any,startDate:any,timePeriod:any):Promise<CloseHoldingsReturnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/weights/getsupersectorweightsexport',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers,              
                CurrencyCode:currencyCode,
                ReturnType:returnType  ,
                FromDate:startDate ,
                TimePeriod:timePeriod                    
        }), {headers: headers})
        .toPromise()
        .then(response =>{
            if( Object.keys(response).length ==0 ){
                this.DataLoaded.next("Nodata"); 
            }else{
            this.DataLoaded.next("Success"); 
            }
                return response as CloseHoldingsReturnsModel[]
            })
            .catch((err) => { this.DataLoaded.next("Fail");  return this.handleError(err); }  );  
    }
    getSectorWeights(asOfDate: any,indexMarkers : any,startDate:any) : Promise<CloseHoldingsChartModel> {
    
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/weights/getSectorWeights',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers,
                FromDate:startDate                        
                
        }), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CloseHoldingsChartModel
            })
        .catch(this.handleError);    
    }    
    getSubSectorWeights(asOfDate: any,indexMarkers : any,startDate:any) : Promise<CloseHoldingsChartModel> {
    
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/weights/getSubSectorWeights',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers,
                FromDate:startDate                       
                
        }), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CloseHoldingsChartModel
            })
        .catch(this.handleError);    
    }
    

    
    getWeightTabData(asOfDate: any,indexMarkers : any,startDate:any,timePeriod:any) : Promise<ResponseModel> {
        
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            return this.httpClient.post(environment.dataServiceRoot + 'ui/weights/GetWeightTabData',
                JSON.stringify({
                    AsOfDate : asOfDate,
                    ToDate:asOfDate,
                    IndexMarkers : indexMarkers,
                    FromDate:startDate,
                    TimePeriod: timePeriod          
                   
            }), {headers: headers})
            .toPromise()
            .then(response =>{
                    return response as ResponseModel
                })
            .catch(this.handleError);    
        }

        getAllSectorWeights(asOfDate: any,indexMarkers : any) : Promise<ResponseModel> {
            
                let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
                return this.httpClient.post(environment.dataServiceRoot + 'ui/weights/GetAllSectorWeights',
                    JSON.stringify({
                        AsOfDate : asOfDate,
                        IndexMarkers : indexMarkers      
                       
                }), {headers: headers})
                .toPromise()
                .then(response =>{
                        return response as ResponseModel
                    })
                .catch(this.handleError);    
            }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

}