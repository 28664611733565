import { Component, OnInit ,Input,AfterViewInit,Inject,NgZone, ViewChild, ElementRef } from '@angular/core';
import { ValuesService } from '../../services/values.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { ValuePageModel } from '../../models/value-page-model';
import {MagellanColorService} from '../../services/magellancolor.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import { LandingService } from '../../services/landing.service';
import {DateService} from '../../services/date.service';
import { BiService } from '../../services/BiService.service';
import {CurrencyService} from '../../services/currency.service';
import * as moment from 'moment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import{Chart} from 'chart.js';
import { saveAs } from 'file-saver/FileSaver';
import { Buffer } from 'buffer';

@Component({
  selector: 'landing-constituent-count',
  templateUrl: './landing-risk-return-comparison.component.html'
})
export class LandingRiskReturnComparisonComponent  {
  
  asofDates : any;
  indexMarkers : any; 
  @Input() currencyCode : string;  
  chartAttributes: any;  
  dataSource: any; 
  constituentCountData : any;
  ConstituentChartLoader:boolean=true;
  colors : any[]=new Array();;
  chart:any;
  events:any;
  ConstituentChart:any;
  constructor(private landingService: LandingService,private dateService:DateService,private valuesService: ValuesService,
    private exportService: ExportToExcelService,private indexMarkerService:IndexMarkerService ,
    private publishIndexMarkerService:PublishIndexMarkerService, private magellanColorService : MagellanColorService,private biService: BiService,
    private zone:NgZone,private currencyService:CurrencyService,public dialog: MatDialog,
    private idwChartExportService: IDWChartExportService ,private elementRef: ElementRef) { }
  
 
  ngOnInit() {
    this.indexMarkers = this.indexMarkerService.getIndexMarkers();
    this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{
      this.ConstituentChartLoader=true;
      this.indexMarkers=result;
      this.chartSetup(result);
     });
     this.colors = this.magellanColorService.getCurrentColorSchema().split(',');
      this.magellanColorService.colorsPublish().subscribe(color => { 
        this.colors= color.split(',');   
        this.ConstituentChart.data.datasets[0].backgroundColor= this.colors;
        this.ConstituentChart.update();    
      });
      this.indexMarkers = this.indexMarkerService.getIndexMarkers();
      this.currencyCode =this.currencyService.getCurrencyCode();
      this.asofDates = this.dateService.getCalendarDate().end.substring(0,10);
      this.chartSetup(this.indexMarkers);
      
      this.dateService.calendarDatePublish().subscribe(dateRange=>{
        this.asofDates =  this.dateService.changeDateFormat(dateRange.end);
        this.chartSetup(this.indexMarkers);
    });
    this.events = {
      initialized: this.chartInitialized(event)
  }
      
    this.currencyService.currencyCodePublish().subscribe(currency=>{
      this.currencyCode =  currency;        
     this.chartSetup(this.indexMarkers);
  }); 
  }

  chartInitialized(event){
    return (eve, arg) => { 
        this.zone.run(() => {
            this.chart =eve.sender;
        });  
        
    };
      
  }

  openWidgetDialog():void{
    var event: any = {eventName: "Constituent count help dialog open"};
    this.biService.addEvent(event);
    let dialogRef = this.dialog.open(LandingRiskReturnComparisonComponentDialog, {
        width: '800px' , 
        data: this.indexMarkers     
      });
  }
  
  private chartSetup(indexMarkers:any) {
    this.valuesService.getIndexValuesConstituentCountMSChart(this.asofDates,indexMarkers ,this.currencyCode).then(data => {
      if(0 < data.data.dataset[0].data.length){
      this.constituentCountData = data.data;
      this.bindConstituentCount(this.constituentCountData);
      this.ConstituentChart.update();
      }
      else{
       // this.dataSource = {};
        this.ConstituentChart.data.datasets[0].data = [];
      this.ConstituentChart.update();
       //this.bindConstituentCount(this.dataSource);
      }
       
    },onerror =>{
        this.ConstituentChartLoader =false;       
     }); 
  //    this.chartAttributes = {
  //     "paletteColors": this.colors,
  //     "bgColor": "#ffffff",
  //     "showBorder": "0",
  //     "showCanvasBorder": "0",
  //     "maxColWidth" :"6",
  //     "showYAxisLine": "1",
  //     "usePlotGradientColor": "0",
  //     "showalternatehgridcolor": "0",
  //     "showplotborder": "0",
  //     "plotBorderAlpha": "10",
  //     "showValue": "0",
  //     "showValues": "0",
  //     "axisLineAlpha": "25",
  //     "divLineAlpha": "10",
  //     "alignCaptionWithCanvas": "0",
  //     "showAlternateVGridColor": "0",
  //     "captionFontSize": "14",
  //     "subcaptionFontSize": "14",
  //     "subcaptionFontBold": "0",
  //     "toolTipColor": "#ffffff",
  //     "toolTipBorderThickness": "0",
  //     "toolTipBgColor": "#000000",
  //     "toolTipBgAlpha": "80",
  //     "toolTipBorderRadius": "2",
  //     "toolTipPadding": "5",
  //     "chartRightMargin": "-10",
  //     "exportEnabled": "1",
  //     "exportShowMenuItem": "0",
  //     "formatNumberScale":"1"
  // };

  }

  bindConstituentCount(data: any){
    // this.dataSource = {
    //   "chart": this.chartAttributes,
    //   "categories": data.categories,
    //   "data": data.dataset[0].data
    // }  
    var i;
    var value: any[] = new Array();
    var label: any[] = new Array();
    for(i=0;i<data.dataset[0].data.length;i++)
    {
      value[i]=data.dataset[0].data[i].value ;
      label[i]=data.dataset[0].data[i].label;
    }
    if (this.ConstituentChart) this.ConstituentChart.destroy();
    this.ConstituentChart = new Chart('ConstituentChart', {
      type: 'bar',
      data: {
          labels: label,
          datasets: [{
              label: '',
              barThickness: 6,
              barPercentage: 1,
              data: value,
              backgroundColor: this.colors,
              borderColor: this.colors,
              borderWidth: 0,
          }]
      },
      options: {
          legend: {
              display: false
          },
          tooltips: {      
            callbacks: {
              label: function(tooltipItem, data) {
                  var value = (data.datasets[0].data[tooltipItem.index] /1000);
                  return value.toFixed(2)+ 'K';
              }
        }
        },
          maintainAspectRatio: false,
          scales: {
              xAxes: [{
                  gridLines: {
                    display: false, // must be false since we're going to draw our own 'gridlines'!
                    lineWidth: 5, // can still set the width.
                  },
                  ticks: {
                      autoSkip: false,
                      maxRotation: 0,
                      minRotation: 0,
                      fontSize: 8,
                      fontColor: '#000000',
                      fontFamily: 'Roboto',
                  }
                }],
              yAxes: [{
                  angleLines: {
                      display: false
                    },
                  ticks: {
                      beginAtZero: true,
                      maxTicksLimit: 10,
                      callback: function(value, index, values) {
                          return  value/1000  + 'k';
                      },
                      fontSize: 9,
                      fontColor: '#000000',
                      fontFamily: 'Roboto',
                      
                  }
              }]
          }
      },
      plugins: [{
        afterDraw: function(chart) {
          if (chart.data.datasets[0].data.length == 0) {
            // No data is present
            var ctx = chart.chart.ctx;
            var width = chart.chart.width;
            var height = chart.chart.height
            chart.clear();
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.font = "20px Roboto 'Helvetica Nueue'";
          ctx.fillText('No data to display', width / 2, height / 2);
             ctx.restore();
        }
        }
  }]
  }); 
    this.ConstituentChartLoader=false;    
  }

  ExportXls(event):void{
    var event: any = {eventName: "Export Constituent Count ", inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode}]};
    this.biService.addEvent(event);
     var inputData = {
      "exportType": "xls",
      "code": "value-getIndexValuesConstituentCountMSChartData",
      "xlsFileName": "ConstituentCountRawData",
      "asOfDate": this.asofDates,
      "indexMarkers": this.indexMarkers,
      "currencyCode": this.currencyCode
    };
    this.idwChartExportService.export(inputData);
  }

  ExportPNG(event):void{
    var event: any = {eventName: "Export Constituent Count TO PNG", inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode}]};
    this.biService.addEvent(event);
  //   var inputData = {
  //     "exportType": "png",
  //     "legend": false,
  //     "dataSource": this.myCharti,
  //     "chartid" : "indexValuesColumnChartExport",
  //     "type" : "column2d",
  //     "targetDiv" : "exportPngDiv",
  //     "chartReference": this.chart,
  //     "pngFileName" : "ConstituentCountRawData",
  //     "chartTitle": "Constituent Count",
  //     "chartDate": this.asofDates
  //  };  
  //this.idwChartExportService.export(inputData);
  var canvas = <HTMLCanvasElement> document.getElementById('ConstituentChart'); 
  var imageUrl = canvas.toDataURL("image/png");
  var base64 = imageUrl.split("base64,")[1];
  var bufferArr = Buffer.from(base64,'base64');
  var blob = new Blob([bufferArr], { type: 'image/png' });
  saveAs(blob, "Constituent Count.png");
  }

}


@Component({ 
  selector: 'top-bottom',   
  templateUrl: './landing-risk-return-comparison.component.widget.dialog.html'
})
export class LandingRiskReturnComparisonComponentDialog{

@Input() indexes: string;
constructor(
public dialogRef: MatDialogRef<LandingRiskReturnComparisonComponentDialog>,
@Inject(MAT_DIALOG_DATA) public data: any) {
  this.indexes="";
   data.forEach(element => {
       if( this.indexes.length<3)
       {
          this.indexes=element;
       }else{
      this.indexes += ", "+ element;
       }
   });   
 }

onClose(): void {
    this.dialogRef.close();
}
}