import { Injectable } from '@angular/core';
// tslint:disable-next-line:import-blacklist

import { BehaviorSubject, Observable, of, from, Subject } from 'rxjs';
import { NewMetricForm } from '../models/metric';
import { map } from 'rxjs/operators';
import { Portfolio } from '../models/portfolio';
import { Benchmark } from '../models/benchmark';
import { ResponseModel } from '../../models/landing-page-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { debug } from 'console';



@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  public isFormValid = true;
  constructor(private httpClient: HttpClient) { }

  private _listners = new Subject<any>();
  clientPublish(): Observable<any> {
    return this._listners.asObservable();
  }


  publishClient(client: string) {
    this._listners.next(client);
  }

  fetchReports$(pageIndex, pageSize): Observable<any> {
    //return this.reports$.asObservable();
    return from(this.GetMasterData(pageIndex, pageSize));
  }
  fetchMasterData$(pageIndex, pageSize,filter): Observable<any> {
    //return this.reports$.asObservable();
    return from(this.GetMasterDataByFilter(pageIndex, pageSize,filter));
  }
  
  createMetricIndex(metricForm: NewMetricForm): Observable<string> {


    //const report = this.fromReportForm(reportForm);
    this.saveNewMetric(metricForm).then((a) => {
      if (a.data != 0) {
        //this.publishClient("created metric data successfully!");
      }
      else {
        //this.publishClient(a.meta.message);
      }
    });
    return of(metricForm.indexCode);

  }

  updateMetricIndex(metricForm: NewMetricForm): Observable<string> {
    this.saveMetric(metricForm).then((a) => {
      if (a.data != 0) {
        // this.publishClient("Updated metric data successfully!");
      }
      else {
        // this.publishClient(a.data.message);
      }
    });
    return of(metricForm.indexCode);
  }

  validateSIMetrics(indexCode: string): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SIMetricIndexConfig/ValidateSIMetrics', JSON.stringify({ indexCode: indexCode }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data
      }).catch(this.handleErr);
  }
  validateSIMetricIndex(indexCode: string): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SIMetricIndexConfig/ValidateSIMetricsIndex', JSON.stringify({ indexCode: indexCode }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data
      }).catch(this.handleErr);
  }
  validateSIMetricsIndexFamily(indexCode: string): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SIMetricIndexConfig/ValidateSIMetricsIndexFamily', JSON.stringify({ indexCode: indexCode }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data
      }).catch(this.handleErr);
  }
  saveNewMetric(metricForm: NewMetricForm): any {
    if (metricForm.indexCode.toLowerCase() != metricForm.parentIndexCode.toLowerCase()) {
      this.validateSIMetrics(metricForm.indexCode).then((res) => {
        if (res) {
          alert('Index data already exists!');
        }
        else {   
          this.isFormValid = true;
          let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          return this.httpClient.post(
            environment.dataServiceRoot + 'ui/SIMetricIndexConfig/AddSIMetricsIndex',
            JSON.stringify({
              IndexFamilyId: metricForm.indexFamilyId,
              IndexFamily: metricForm.indexFamily,
              Index: metricForm.index,
              IndexCode: metricForm.indexCode,
              ParentIndex: metricForm.parentIndex,
              ParentIndexCode: metricForm.parentIndexCode,
              AssetClass: metricForm.assetClass,
              UrgentFlag: metricForm.urgentFlag,
              Comments: metricForm.comments
            }),

            { headers: headers }).toPromise()
            .then(response => {
              var result = response as ResponseModel;
              if (result.data != 0) {
                this.publishClient("Index data created successfully!");
              }
              else {
                this.publishClient(result.meta.message);
              }
              // return result;
            }).catch(this.handleErr);
          //              }
          //            });
          //          }
          //        });
          //      }
          //    });
          //  }
          //});
        }
      });
    }
    else {
      alert('Index code and Parent Index code must be different');
    }
  }

  saveMetricUrgent(metricForm: any): any {
    
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(
        environment.dataServiceRoot + 'ui/SIMetricIndexConfig/UpdateSIMetricsIndex',
        JSON.stringify(metricForm),

        { headers: headers }).toPromise()
        .then(response => {
          var result = response as ResponseModel;

          if (result.data != 0) {
            this.publishClient("Urgent Flag updated successfully!");
          }
          else {
            this.publishClient(result.meta.message);
          }
          return result;
        }).catch(this.handleErr);  
  }



  saveMetric(metricForm: NewMetricForm): any {

  if (metricForm.indexCode.toLowerCase() != metricForm.parentIndexCode.toLowerCase()) {
      this.isFormValid = true;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(
        environment.dataServiceRoot + 'ui/SIMetricIndexConfig/UpdateSIMetricsIndex',
        JSON.stringify({
          IndexFamilyId: metricForm.indexFamilyId,
          IndexFamily: metricForm.indexFamily,
          Index: metricForm.index,
          IndexCode: metricForm.indexCode,
          ParentIndex: metricForm.parentIndex,
          ParentIndexCode: metricForm.parentIndexCode,
          AssetClass: metricForm.assetClass,
          UrgentFlag: metricForm.urgentFlag,
          Comments: metricForm.comments
        }),

        { headers: headers }).toPromise()
        .then(response => {
          var result = response as ResponseModel;
          if (result.data != 0) {
            this.publishClient("Updated Index data successfully!");
          }
          else {
            this.publishClient(result.meta.message);
          }
        }).catch(this.handleErr);
    } else {
      alert('Index code and Parent Index code must be different');
    }
  }

  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }



  GetMasterData(pageIndex, pageSize): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SIMetricIndexConfig/SIMetricsIndexList', JSON.stringify({ page: pageIndex, pageSize: pageSize }),
      { headers: headers });
  }
  GetMasterDataByFilter(pageIndex, pageSize, filter): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SIMetricIndexConfig/SIMetricsIndexListByFilter', JSON.stringify({ pageSize: pageSize, page: pageIndex, filter: filter }),
      { headers: headers });
  }
  GetindexList(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SIMetricIndexConfig/IndexList',null,
      { headers: headers });
  } 
  deleteMasterData(metricForm: any): any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SIMetricIndexConfig/DeleteSIMetricsIndex',
      JSON.stringify({
        IndexCode: metricForm.IndexCode
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        this.publishClient("Index data has been deleted");
        return result;
      }).catch(this.handleErr);
  }
//  downloadExcel(): Observable<any> {
//    let headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
//    return this.httpClient.get(
//      environment.dataServiceRoot + 'ui/SIMetricIndexConfig/DownloadExcel',
//      { headers: headers, responseType: 'text' });
//       }
//}

  downloadExcel(): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SIMetricIndexConfig/DownloadExcel',null,
      { headers: headers }).toPromise()
      .then(response => {
          var result = response;
          return result;
    })
        .catch (this.handleErr);
  }

  }

