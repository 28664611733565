import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../../services/reports.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NewReportPopupComponent } from '../../components/new-report-popup/new-report-popup.component';
import { NewReportForm } from '../../models/report';
import { HistoryDataService } from '../../services/historydata.service';

@Component({
  selector: 'app-historyreports-page',
  templateUrl: './history-reports-page.component.html',
  styleUrls: ['./history-reports-page.component.scss']
})
export class HistoryReportsPageComponent implements OnInit {
  isDisabled: boolean = false;
  baseDialogConfig: MatDialogConfig = {
    closeOnNavigation: true,
    panelClass: 'custom-popup-panel',
  };
  
  constructor(private dialog: MatDialog, private historydataservice: HistoryDataService) {}

  ngOnInit(): void {
    this.enableDisableGenerateReportButton();
  }
  editReport(report: NewReportForm) {
  }
  enableDisableGenerateReportButton() {
    this.historydataservice.fetchReports$().subscribe(res => {
      if (res.data.length > 0) {
        for (let item of res.data) {
          debugger;
          if (item.historyReportGenerationStatus == 0) {
            this.isDisabled = false;
            break;
          }
          else {
            this.isDisabled = true;
          }
        }
      } else {
          this.isDisabled = true;
        }      

    });
  }
  generateHistoryReport() {
    this.isDisabled = true;
    this.historydataservice.generateHistoryReport();
  }
  getReports() {

    this.enableDisableGenerateReportButton();
    this.historydataservice.publishClient("List refreshed");
  }
  ngAfterViewInit(){
    let yourElem= <HTMLElement>document.querySelector('.fa-angle-double-left');
    if(yourElem!=null)
    {
    yourElem.click();
    }
  }
}
