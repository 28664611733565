
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { Component, OnInit,Inject, Input,NgZone,ViewChild, AfterViewInit} from '@angular/core'
import { CloseHoldingService } from '../../services/close-holdings.service';
import {CloseHoldingPageModel} from '../../models/close-holding-page-model';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {MagellanColorService} from '../../services/magellancolor.service';
import { BiService } from '../../services/BiService.service';
import{CurrencyService}from '../../services/currency.service';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import {ChartTypeComponent} from '../chart-type/chart-type.component';

import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'close-top-bottom-holdings-chart',
  templateUrl: './close-holdings-top-bottom-holdings-chart.component.html'
})
export class CloseTopBottomHoldingsChartComponent implements OnInit,AfterViewInit {
  @ViewChild(ChartTypeComponent,{static: false}) chartTypeComponent;
  topBottomHoldingsChart: string;
  defaultActiveChart: string = "bar2d";
  datasetType = "simple";
  asofDates : any;
  topbottomflag:any = true;
  indexMarkers : any;  
  currencyCode : string;  
  chartAttributes: any;  
  dataSource: any;
  TopBottomChartLoader:boolean = true;
  colors:any;
  currentIndex:any;
  toollbarTitle:any;
  popupDataSource:any;
  topHoldings: boolean;
  bottomHoldings: boolean;
  topholdingsDataset: any;
  bottomholdingsDataset: any;
  chart:any;
  selectedIndex:any;
 
  constructor(private closeHoldingService: CloseHoldingService,private indexMarkerService:IndexMarkerService, 
    private publishIndexMarkerService:PublishIndexMarkerService, private magellanColorService : MagellanColorService,
    private dialog: MatDialog,private biService: BiService, private zone:NgZone, private currencyService:CurrencyService
    ,private idwChartExportService: IDWChartExportService, private exportService: ExportToExcelService) { }


  ngOnInit(){    
  
    this.topBottomHoldingsChart="topBottomHoldingsChart";
    this.indexMarkers = this.indexMarkerService.getIndexMarkers();
    this.currencyCode = this.currencyService.getCurrencyCode();
    this.currentIndex = 0; 
    this.toollbarTitle = "Top Holdings "; 
   
    this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
      this.indexMarkers = result;
      this.TopBottomChartLoader = true;
      this.chartSetup(this.topbottomflag);
  }); 

    this.colors = this.magellanColorService.getCurrentColorSchema();
    this.magellanColorService.colorsPublish().subscribe(color => { 
      this.colors= color;
      this.chartAttributes.paletteColors = this.colors;  
      this.popupDataSource={
        "title":this.toollbarTitle,
        "asOfDate": this.asofDates,
        "type": this.defaultActiveChart,
        "dataSource": this.dataSource,
        "legend": this.indexMarkerService.getIndexMarkersNames(), 
        "colors":this.colors,
        "chartType" : this.defaultActiveChart    
      }
      });
      this.chartAttributes = {
        "exportEnabled": "1",
        "exportShowMenuItem": "0",
        "paletteColors": this.colors,
        "bgColor": "#ffffff",
        "bgAlpha": "0",
        "showBorder": "0",
        "showCanvasBorder": "0",
        "showLabels": '1',
        "showValues": "0",
        "plotBorderAlpha": "10",
        "enableSmartLabels": "0",
        "showLegend": "1",
        "legendPosition": "right",
        "legendBorderColor": "#ffffff",
        "legendBorderThickness": "0",
        "legendBorderAlpha": "0",
        "legendShadow": "0",
        "usePlotGradientColor": "0",
        "canvasBgAlpha": "0",
        "canvasBorderColor": "#ffffff",
        "canvasBorderThickness": "0",
        "canvasBorderAlpha": "0",
        "alignCaptionWithCanvas": "0",
        "showShadow": "0",
        "chartLeftMargin": "0",
        "chartRightMargin": "5",
        "chartTopMargin": "3",
        "use3DLighting": "0",
        "axisLineAlpha": "25",
        "divLineAlpha": "10",
        "showAlternateVGridColor": "0",
        "showAlternateHGridColor": "0",
        "captionFontSize": "14",
        "subcaptionFontSize": "14",
        "subcaptionFontBold": "0",
        "toolTipColor": "#ffffff",
        "toolTipBorderThickness": "0",
        "toolTipBgColor": "#000000",
        "toolTipBgAlpha": "80",
        "toolTipBorderRadius": "2",
        "toolTipPadding": "5",
        "showValue": "0",
       
    }
     
  }

  ngAfterViewInit(){
    this.chartTypeComponent.defaultChartType=this.defaultActiveChart;
   
  }
  chartInitialize(event){
    return (eve, arg) => { 
        this.zone.run(() => {
            this.chart =eve.sender;
        });  
        
    };
      
  }

  events = {
    initialized: this.chartInitialize(event)
}

  carouselPrevious():void{
    var event: any = {eventName: "TopBottom Chart Previous Carousel", inputParameters: [{Name: "Previous IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() }]};
    this.biService.addEvent(event);
    this.TopBottomChartLoader = true;
    if(0 == this.currentIndex){
       this.currentIndex = this.indexMarkers.length -1;
    }else{
        this.currentIndex = this.currentIndex - 1;
    }
    this.colors = this.magellanColorService.getCurrentColorSchema();
    this.chartSetup(this.topbottomflag);
}

carouselNext():void{
  var event: any = {eventName: "TopBottom chart next Carousel", inputParameters: [{Name: "Next IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() }]};
    this.biService.addEvent(event);
  this.TopBottomChartLoader = true;
   if((this.indexMarkers.length -1) == this.currentIndex){
        this.currentIndex = 0;
    }else{
        this.currentIndex = this.currentIndex + 1;
    }
    this.colors = this.magellanColorService.getCurrentColorSchema();
    this.chartSetup(this.topbottomflag);
}
  update(holdingPageModel:CloseHoldingPageModel){
    this.TopBottomChartLoader = true;
    this.asofDates = holdingPageModel.asofdate; 
    this.currencyCode=holdingPageModel.currencyCode;
    this.topbottomflag = holdingPageModel.topBottomFlag;  
    this.chartSetup(this.topbottomflag);
  }


  private chartSetup(flagTopBottom:any) {
    var oneIndexMarkers:string[] = [];
    if(this.indexMarkers[this.currentIndex]==undefined)
    {
         this.currentIndex=0;
    }
    oneIndexMarkers.push(this.indexMarkers[this.currentIndex]);
    this.topbottomflag = flagTopBottom;
    this.selectedIndex= this.indexMarkers[this.currentIndex];
    this.toollbarTitle = flagTopBottom? "Top Holdings"   :  "Bottom Holding" ; 
       
    this.closeHoldingService.getTopBottomHoldings(this.asofDates,oneIndexMarkers ,this.currencyCode).then(data => { 
      if(null != data){
        if(undefined!= data && undefined!= data.bottomHoldings && undefined!= data.bottomHoldings){
          this.dataSource = {
            "chart": this.chartAttributes,
            "categories": [{ "category": [{ "Label": "GEISAC", "StepSkipped": "false", "AppliedSmartLabel": "false", "X": "null", "Showverticalline": "null", "Vline": "null", "Color": "null" }, { "Label": "GEISAC", "StepSkipped": "false", "AppliedSmartLabel": "false", "X": "null", "Showverticalline": "null", "Vline": "null", "Color": "null" }, { "Label": "GEISAC", "StepSkipped": "false", "AppliedSmartLabel": "false", "X": "null", "Showverticalline": "null", "Vline": "null", "Color": "null" }, { "Label": "GEISAC", "StepSkipped": "false", "AppliedSmartLabel": "false", "X": "null", "Showverticalline": "null", "Vline": "null", "Color": "null" }, { "Label": "GEISAC", "StepSkipped": "false", "AppliedSmartLabel": "false", "X": "null", "Showverticalline": "null", "Vline": "null", "Color": "null" }, { "Label": "GEISAC", "StepSkipped": "false", "AppliedSmartLabel": "false", "X": "null", "Showverticalline": "null", "Vline": "null", "Color": "null" }] }],
            "data": flagTopBottom? data.topHoldings[0].data: data.bottomHoldings[0].data
        };
        }
        else {
          this.dataSource ={}
        }
  
  
          this.popupDataSource={
            "title":this.toollbarTitle+" - " +this.indexMarkers[this.currentIndex],
            "type": this.defaultActiveChart,
            "asOfDate": this.asofDates,
            "dataSource": this.dataSource,
            "legend": this.indexMarkerService.getIndexMarkersNames(), 
            "colors":this.colors.split(',') ,
            "chartType" : this.defaultActiveChart     
          }
      } else{
        this.dataSource = {};
      }     
      
      
      this.TopBottomChartLoader = false;
    },onerror =>{
        this.TopBottomChartLoader =false;       
     });

  

  }
    ExportXls(event):void{
      var event: any = {eventName: "Close HoldingsExport TopBottom Chart", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()}
        ,{Name : "CurrencyCode" , Value: this.currencyCode}]};
        this.biService.addEvent(event);
        this.closeHoldingService.getTopBottomHoldings(this.asofDates,[this.indexMarkers[this.currentIndex]],this.currencyCode ).then(data => {
            if(this.topbottomflag == true){
              if(null!= data && undefined!= data){
                this.exportService.exportData(data.topHoldings[0].data,"topHoldings"+[this.indexMarkers[this.currentIndex]]);
               } 
            }else{
              if(null!= data && undefined!= data){
                this.exportService.exportData(data.bottomHoldings[0].data,"bottomHoldings"+[this.indexMarkers[this.currentIndex]]);
                      } 
            }
        });

  }

  ExportPNG(event):void{
    var event: any = {eventName: "Close HoldingsExport TopBottom Chart TO PNG",  inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()}
    ,{Name : "CurrencyCode" , Value: this.currencyCode}]};
    this.biService.addEvent(event);
    var legendClassNames = ["legend","legendcolor","legendlabel"];
    this.popupDataSource.dataSource.chart.showlegend = "1";
    var inputData = {
      "exportType": "png",
      "legend": false,
      "dataSource": this.popupDataSource.dataSource,
      "chartid" : "topBottomHoldingsChartExport",
      "type" : this.defaultActiveChart,
      "targetDiv" : "exportPngDiv",
      "chartReference": this.chart,
      "pngFileName" : "topBottomHoldingsRawData",
      "chartTitle": this.toollbarTitle+ " - " + this.indexMarkers[this.currentIndex] ,
      "chartDate": this.asofDates,
      "isIndexLegend": false,
      "indexLegendNames": this.popupDataSource.legend,
      "indexLegendColors": this.popupDataSource.colors,
      "indexLegendClassNames" : legendClassNames
   };
   this.idwChartExportService.export(inputData);
  }
  onChartTypeChange(chart: string) {
    this.TopBottomChartLoader = true;
    this.defaultActiveChart = chart;
    if (chart == 'column2d' || chart == 'bar2d' || chart == 'line') {
      this.chartAttributes.chartLeftMargin = "0";
      this.chartAttributes.chartRightMargin = "3";
      this.chartAttributes.chartTopMargin = "5";
      this.chartAttributes.use3DLighting = "0";
      this.chartAttributes.showLabels = 1;
    }
    else {
        this.chartAttributes.showLabels = 0;
        this.chartAttributes.chartLeftMargin = "0";
        this.chartAttributes.chartRightMargin = "0";
        this.chartAttributes.chartTopMargin = "-15";
        this.chartAttributes.use3DLighting = "0";

    }
    this.chartSetup(this.topbottomflag);

}
  openWidgetDialog():void{
    var event: any = {eventName: "TopBottom chart help dialog open"};
    this.biService.addEvent(event);
    let dialogRef = this.dialog.open(CloseTopBottomHoldingsChartComponentWidgetDialog, {
        width: '800px' , 
        data: this.indexMarkers     
      });
  }

  openDialog(): void{ 
    var event: any = {eventName: "TopBottom dialog open"};
    this.biService.addEvent(event);
    this.popupDataSource.dataSource.chart.showlegend = "1"; 
    let dialogRef = this.dialog.open(CloseTopBottomHoldingsChartComponentDialog, {
      width: '80%',
      height: '90%',
      data: this.popupDataSource,    
  });
}
}


@Component({
    selector: 'close-top-bottom-holdings-chart-dialog',
	templateUrl: './close-holdings-top-bottom-holdings-chart.component.dialog.html'
})
export class CloseTopBottomHoldingsChartComponentDialog{
  
    constructor(
    public dialogRef: MatDialogRef<CloseTopBottomHoldingsChartComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    onClose(): void {
        this.dialogRef.close();
  }
}



@Component({   
  templateUrl: './close-holdings-top-bottom-holdings-chart.component.widget.dialog.html'
})
export class CloseTopBottomHoldingsChartComponentWidgetDialog{

@Input() indexes: string;
constructor(
public dialogRef: MatDialogRef<CloseTopBottomHoldingsChartComponentWidgetDialog>,
@Inject(MAT_DIALOG_DATA) public data: any) {
  this.indexes="";
   data.forEach(element => {
       if( this.indexes.length<3)
       {
          this.indexes=element;
       }else{
      this.indexes += ", "+ element;
       }
   });   
 }

onClose(): void {
    this.dialogRef.close();
}
}