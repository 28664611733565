import { Injectable } from '@angular/core';
// tslint:disable-next-line:import-blacklist
import {Subject, BehaviorSubject, Observable, of, from} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  DEFAULT_COLUMNS,
  PortfolioForm,
  Portfolio,
  PortfolioDetails,
  PortfolioDetailsMockData,
  newPortfolio,
  portfolioFormToPortfolio
} from '../models/portfolio';
import {DownloadService} from './download.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResponseModel } from '../../models/landing-page-model';
import * as moment from 'moment';

export function createPortfolioDetails({data, columns, model}: Partial<PortfolioDetails> = {}): PortfolioDetails {
  return {
    columns: columns || [...DEFAULT_COLUMNS],
    data: data || [[]],
    model: newPortfolio(model),
  };
}

const dataSample: PortfolioDetailsMockData = {
  'Portfolio 1': {
    model: <any>{},
    columns: [...DEFAULT_COLUMNS],
    data: [
      ['', '', 'US0378331005', '', 'APPLE', 'USA', '0,0'],
      ['', '', 'US5949181045', '', 'MICROSOFT', 'USA', '0,7'],
      ['', '', 'US0231351067', '', 'AMAZON.COM', 'USA', '0,7'],
      ['', '', 'US30303M1027', '', 'FACEBOOK CLASS A', 'USA', '0,7'],
      ['', '', 'US46625H1005', '', 'JP MORGAN CHASE & CO.', 'USA', '0,7'],
      ['', '', 'US4781601046', '', 'JOHNSON & JOHNSON', 'USA', '0,7'],
      ['', '', 'US30231G1022', '', 'EXXON MOBIL', 'USA', '0,7'],
      ['', '', 'US02079K1079', '', 'ALPHABET \'C', 'USA', '0,7'],
      ['', '', 'US0605051046', '', 'BANK OF AMERICA', 'USA', '0,7'],
      ['', '', 'US9497461015', '', 'WELLS FARGO & CO', 'USA', '0,7'],
      ['', '', 'US0846707026', '', 'BERKSHIRE HATHAWAY \'B\'', 'USA', '0,7'],
      ['', '', 'US00206R1023', '', 'AT&T', 'USA', '0,7'],
      ['', '', 'US1667641005', '', '', 'USA', '0,7'],
      ['', '', 'US7427181091', '', 'PROCTER & GAMBLE', 'USA', '0,7'],
      ['', '', 'US4370761029', '', 'HOME DEPOT', 'USA', '0,7'],
      ['', '', 'US4581401001', '', 'INTEL', 'USA', '0,7'],
    ]}
};

const portfoliosDataMock: Portfolio[] = [
  {
    id: 0,
    name: 'Portfolio 1',
    description: 'Cras quis nulla commodo',
    marketValue: '3,992,334.00',
    benchMark: 'FAWDGRI',
    benchMarkDest: '',
    assetClass: 'Real estate',
    currency: 'USD',
    asOfDate: '2020-08-01',
    isMarketValueFromTemplate: false,
    sourceBenchmark : '',
    children: dataSample['Portfolio 1']
  }, {
    id: 1,
    name: 'Portfolio 2',
    description: 'veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
    marketValue: '4,800,000.00',
    benchMark: 'ET100',
    benchMarkDest: '',
    assetClass: 'Equities',
    currency: 'USD',
    asOfDate: '2020-01-18',
    sourceBenchmark: '',
    isMarketValueFromTemplate: false,
  }, {
    id: 2,
    name: 'Untitled',
    description: 'laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore',
    marketValue: '3,750,000.00',
    benchMark: 'EOAS',
    benchMarkDest: '',
    assetClass: 'Fixed Income',
    currency: 'GBP',
    asOfDate: '2020-10-10',
    sourceBenchmark :'',
    isMarketValueFromTemplate: false,
  }, {
    id: 3,
    name: '23_General - 2',
    description: 'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?',
    marketValue: '7,200.000.00',
    benchMark: 'EO100',
    benchMarkDest: '',
    assetClass: 'Real estate',
    currency: 'USD',
    asOfDate: '2020-06-17',
    sourceBenchmark:'',
    isMarketValueFromTemplate: false,
  }, {
    id: 4,
    name: '03_13_20 Portfolio',
    description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
    marketValue: '3,000,000.00',
    benchMark: 'FAWDGRI',
    benchMarkDest: '',
    assetClass: 'Commodities',
    currency: 'EUR',
    asOfDate: '2020-03-18',
    sourceBenchmark:'',
    isMarketValueFromTemplate: false,
  }
];

@Injectable({
  providedIn: 'root'
})
export class PortfoliosService {
  dataMock$ = new BehaviorSubject(portfoliosDataMock);
  constructor(private downloadService: DownloadService,  private httpClient: HttpClient) { 

  }

  fetchPortfolios$(pageIndex, pageSize): Observable<Portfolio[]> {
    return from(this.GetPortfolios(pageIndex, pageSize));
    //return this.dataMock$.asObservable();
  }
  ValidateportfolioName$(portfolioName:string): Promise<Portfolio[]> {
    return this.ValidateportfolioName(portfolioName);
  }
  ValidateportfolioName(portfolioname:string):Promise<Portfolio[]>
  {
   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
  return this.httpClient.post( 
    environment.dataServiceRoot + 'client/api/mvp/ValidatePortfolioName',  JSON.stringify({ portfolioName :portfolioname}),
    {headers: headers}).toPromise()
    .then(response => {
      var result = response as ResponseModel;
      return result.data 
    }).catch(this.handleErr);
  }
  GetPortfolios(pageIndex, pageSize): Observable<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'client/api/mvp/GetAllMVPPortfoliosByPage', JSON.stringify({ pageIndex : pageIndex, pageSize: pageSize}),
      {headers: headers});
  }

  getCurrencies():Observable<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'client/api/mvp/GetCurrenciesList',null,
      {headers: headers});
  }

  createPortfolio$(portfolioForm: PortfolioForm): Observable<string> {
    var prtdtls = [], result = [];
    var portfolioDetails: any;
    var valid = false;
    if (!portfolioForm.file) {
      this.savePortfolio(portfolioForm, null).then((a) => {
        if (a) {
          this.publishClient("Save click");
        }
      });
      return of(portfolioForm.name);
    }

    return this.downloadService.readFileData$<Portfolio>(portfolioForm.file).pipe(
      map((data) => {
        console.log(data.data);
        data.data.forEach(item => {
          prtdtls.push(Object.assign({}, item));
        });
        if (prtdtls.length > 0) {
          valid = true;
          var row =2;
          for (let detail of prtdtls) {
            portfolioDetails = detail;
            //console.log(portfolioDetails.AsOfDate)
            var formattedDate = '';
            var inComingDate = portfolioDetails.AsOfDate;
            //console.log(typeof inComingDate);
            const isValidDate = (inComingDate: string) => {
              typeof inComingDate == 'string' && (inComingDate = inComingDate.split('-').join('/'));
              if (typeof inComingDate !== 'string' || inComingDate.split('/').length !== 3 || !/^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(inComingDate)){
                return false;
              }
              const mmDDyy = inComingDate.split('/');
              formattedDate = mmDDyy[2] + '-' + mmDDyy[0] + '-' + mmDDyy[1];
              const d = new Date(inComingDate);
              if(d.toString() === 'Invalid Date'){
                return false;
              }
              if(parseInt(mmDDyy[0]) === d.getMonth()+1 && parseInt(mmDDyy[1]) === d.getDate() && parseInt(mmDDyy[2]) === d.getFullYear()){
                return true;
              } else{
                return false;
              }
            }
            // var regex1 = new RegExp("^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$");
            // var date = regex1.test(portfolioDetails.AsOfDate);
           // console.log(date)
            const value = isValidDate(inComingDate);
            console.log(portfolioForm.asOfDate);
             //alert('parentDate : ' + portfolioForm.asOfDate + ' formattedDate : ' + formattedDate);
           // console.log(value);
            if (portfolioDetails == ""|| value == false ) {
              this.publishClient(`Row ${row}:Invalid Asofdate`)
              valid = false;
              break;
            }
            else if (portfolioDetails.Weight == "") {
              this.publishClient(`Row ${row}:Invalid Weight`)
              valid = false;
              break;
            }
            else if (portfolioDetails.Isin == "") {
              if (portfolioDetails.CUSIP == "") {
                if (portfolioDetails.SEDOL == "") {
                  this.publishClient(`Row ${row}:Invalid Sedol`)
                  valid = false;
                  break;
                }
              }
            }
            else if (portfolioDetails.PortfolioValue != "" ? (isNaN(portfolioDetails.PortfolioValue)) : false) {
              this.publishClient(`Row ${row}:Invalid Portfolio Value`);
              valid = false;
              break;
            }
            else if (portfolioDetails.Weight != "" ? (isNaN(portfolioDetails.Weight) ? true: (portfolioDetails.Weight < 0)) : false) {
              this.publishClient(`Row ${row}:Invalid Weight`);
              valid = false;
              break;
            }
            else if (portfolioDetails.SEDOL != "" ? (portfolioDetails.SEDOL.length != 7) : false) {
              this.publishClient(`Row ${row}:Invalid SEDOL`);
              valid = false;
              break;
            }
            else if (portfolioDetails.CUSIP != "" ? (portfolioDetails.CUSIP.length != 9) : false) {
              this.publishClient(`Row ${row}:Invalid CUSIP`);
              valid = false;
              break;
            }
            else if (portfolioDetails.CUSIP != "" ? (portfolioDetails.CUSIP.length != 9) : false) {
              this.publishClient(`Row ${row}:Invalid CUSIP`);
              valid = false;
              break;
            }
            else if (portfolioDetails.Isin != "" ? (portfolioDetails.Isin.length != 12) : false) {
              this.publishClient(`Row ${row}:Invalid Isin`);
              valid = false;
              break;
            }
            else if ((portfolioDetails.CurrencyCode != undefined ? (portfolioDetails.CurrencyCode != prtdtls[0].CurrencyCode) : false) ||
             (portfolioDetails.CurrencyCode != undefined ? (portfolioDetails.CurrencyCode != prtdtls[1].CurrencyCode) : false))
            {
              this.publishClient(`Row ${row}:Invalid Currency Code`);
              valid = false;
              break;
            }
            // else if ((portfolioDetails.AsOfDate != undefined ? (portfolioDetails.AsOfDate != prtdtls[0].AsOfDate) : false) || 
            // (portfolioDetails.AsOfDate != undefined ? (portfolioDetails.AsOfDate != prtdtls[1].AsOfDate) : false))
            // {
            //   this.publishClient(`Row ${row}:Invalid AsOfDate`);
            //   valid = false;
            //   break;
            // }
            else if(portfolioForm.asOfDate != formattedDate)
            {
              this.publishClient(`Row ${row}:Date selected in the form is not matching with template date`);
              valid = false;
              break;
            }

            /*if (
              (portfolioDetails.PortfolioValue != "" ? (isNaN(portfolioDetails.PortfolioValue)) : false) ||
              (portfolioDetails.Weight != "" ? (isNaN(portfolioDetails.Weight)) : false) ||
              (portfolioDetails.SEDOL != "" ? (portfolioDetails.SEDOL.length != 7) : false) ||
              (portfolioDetails.CUSIP != "" ? (portfolioDetails.CUSIP.length != 9) : false) ||
              (portfolioDetails.Isin != "" ? (portfolioDetails.Isin.length != 12) : false) ||
              (portfolioDetails.CurrencyCode != undefined ? (portfolioDetails.CurrencyCode != prtdtls[0].CurrencyCode) : false) ||
              (portfolioDetails.CurrencyCode != undefined ? (portfolioDetails.CurrencyCode != prtdtls[1].CurrencyCode) : false) ||
              (portfolioDetails.AsOfDate != undefined ? (portfolioDetails.AsOfDate != prtdtls[0].AsOfDate) : false) ||
              (portfolioDetails.AsOfDate != undefined ? (portfolioDetails.AsOfDate != prtdtls[1].AsOfDate) : false)) {
              console.log(portfolioDetails)
              this.publishClient("Invalid");
              valid = false;
              break;
            }*/
            row++;
         }
        }
        else{
          this.publishClient("Invalid");
        }

          if (valid == true) {
            for (let detail of prtdtls) {
              detail.AsOfDate = (detail.AsOfDate == "") ? portfolioForm.asOfDate : detail.AsOfDate;
              detail.PortfolioValue = (detail.PortfolioValue == "") ? 0 : detail.PortfolioValue;
              detail.Weight = (detail.Weight == "") ? 0 : detail.Weight;
              detail.SEDOL = (detail.SEDOL == "") ? null : detail.SEDOL;
              detail.CUSIP = (detail.CUSIP == "") ? null : detail.CUSIP;
              detail.Isin = (detail.Isin == "") ? (detail.Isin == "" ? detail.SEDOL !== "" : detail.CUSIP !== "") : detail.Isin;
              detail.ConsCode = (detail.ConsCode == "") ? null : detail.ConsCode;
              detail.ConstituentName = (detail.ConstituentName == "") ? null : detail.ConstituentName;
              detail.CountryCode = (detail.CountryCode == "") ? null : detail.CountryCode;
              detail.CurrencyCode = (detail.CurrencyCode == "") ? portfolioForm.currency : detail.CurrencyCode;
            }
            result = this.getUniqueDataResult(prtdtls, ['Isin', 'CUSIP', 'SEDOL'])
            console.log(result)
            this.savePortfolio(portfolioForm, result).then((a) => {
              if (a) {
                this.publishClient("Save click");
              }
            });
          }
          // if(valid == false){
          //   this.publishClient("Invalid");
          // }
          dataSample[portfolioForm.name] = data;
          // this.dataMock$.next([...this.dataMock$.value, newPortfolio({
          //   ...this.createPortfolio(portfolioForm),
          //   children: data,
          // })]);
          return portfolioForm.name;
        })
    );
  }
  getUniqueDataResult(details: any[], properties: any[]): any {
    for (var prop = 0; prop < properties.length; prop++) {
      var temp = {}, result = [];
      details.forEach((i) => {
        var value = i[properties[prop]];
        if (value != null) {
          if (!temp[value]) {
            temp[value] = i;
          } else {
            temp[value].Weight = (parseFloat(temp[value].Weight) + parseFloat(i.Weight)).toString();
            temp[value].PortfolioValue = (parseFloat(temp[value].PortfolioValue) + parseFloat(i.PortfolioValue)).toString();
          }
        }
        else {
          result.push(i);
        }
      })
      Object.keys(temp).forEach((key) => {
        result.push(temp[key]);
      })
      details.splice(0, details.length);
      details = [...result];
    }
    return result;
  }

  savePortfolio(portfolioForm: PortfolioForm, children:any ) : any {
    if(portfolioForm.id==null) {
      portfolioForm.id =0 
    }
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });  
    return this.httpClient.post( 
      environment.dataServiceRoot + 'client/api/mvp/SaveMVPPortfolio',
       JSON.stringify({Id:portfolioForm.id, Name : portfolioForm.name, Description : portfolioForm.description, MarketValue: portfolioForm.marketValue,IsMarketValueFromTemplate :portfolioForm.isMarketValueFromTemplate, BenchMark: portfolioForm.selectBenchmark, BenchMarkDest: portfolioForm.selectDestBenchmark,AssetClass: portfolioForm.assetClass, Currency: portfolioForm.currency, AsOfDate: portfolioForm.asOfDate,Children :children }),
      
      {headers: headers}).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as number;
      }).catch(this.handleErr);
  }

   updatePortfolio(portfolioForm: PortfolioForm): any {
    // const data = this.dataMock$.value;
    // this.dataMock$.next(data.map(p => {
    //   if (p.name === portfolioForm.name) {
    //     p = this.createPortfolio(portfolioForm);
    //   }
    //   return {...p};
    // })
    return this.savePortfolio(portfolioForm,[]);
   }

  createPortfolio(form: PortfolioForm): Portfolio {
    return portfolioFormToPortfolio(form);
  }

  async fetchPortfolioById(id: string): Promise<PortfolioDetails> {
    //const model = this.dataMock$.value.find(({name}) => name === id);
    //this.GetPortfolios(1,5);
    const model =await this.GetPortfolioById(parseInt(id));
    const portfolioDetails =  createPortfolioDetails({model});
    // const portfolioDetails = dataSample[id] ? {
    //   ...dataSample[id],
    //   portfolio: model
    // } : createPortfolioDetails({model});
    return (portfolioDetails);
  }

  GetPortfolioById(id: number): any
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'client/api/mvp/GetMVPPortfolio', JSON.stringify({Id:id }),
      {headers: headers}).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data ;
      }).catch(this.handleErr);

  }

 

  updatePortfolioDetails(updated :any) {
    // dataSample[model.name] = {
    //   columns,
    //   data,
    //   model
    // };
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'client/api/mvp/UpdatePortfolioDetails', JSON.stringify({Children :updated}),
      {headers: headers}).toPromise()
      .then(response => {        
        var result = response as ResponseModel;
        return result.data ;
      }).catch(this.handleErr);
  }  

  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  private _listners= new Subject<any>(); 
  clientPublish():Observable<any>{
    return this._listners.asObservable();
  }


  publishClient(client: string) {
    this._listners.next(client);
  } 

  deletePortfolioDetails(portfolioDetails):any{
       let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    
    return this.httpClient.post(
      environment.dataServiceRoot + 'client/api/mvp/DeletePortfolioDetails',
      JSON.stringify({Id:portfolioDetails.model.id, Name : "", Description : "", MarketValue: "", BenchMark: "", AssetClass: "", Currency: "", AsOfDate: new Date(),PortfolioValue:0,Children :null }),

      {headers: headers}).toPromise()
       .then(response => {
        var result = response as ResponseModel;
         return result.data as any;
       }).catch(this.handleErr);
      
  }
}
