import { Component, OnInit, Input, OnChanges, SimpleChanges,Inject,Output,EventEmitter,NgZone } from '@angular/core';
import {WeightService} from '../../services/weight.service';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {BiService} from '../../services/BiService.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MagellanColorService} from '../../services/magellancolor.service';
import { CloseHoldingsChartModel } from '../../models/close-holdings-chart-model';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import {DateService } from '../../services/date.service';

@Component({
    selector: 'weight-sector-industry-chart',
    templateUrl: './weight-sector-history-weights-industry-chart.component.html'
})

export class WeightSectorHistoryIndustryComponent implements OnInit {

    chartAttributes: any;
    popupChartAttributes: any;
    chartColorRange: any;
    WeightsSectorIndustryDataSource: any;
    currentIndex:any;
    colors : any;
    popupData: any;
    WeightsSectorIndustryChartLoader:boolean = true;
    popupDataSource: any;
    chart:any;
    checkLoadFirstTime: boolean;
    dateFormatUK: boolean;
    dateFormatUSA: boolean;
    asofDates: any;
    startDate:any;
    colorsRange:string;
    timePeriod:any;
    indexMarkers: any ;
    treeMapObject: any;
    selectedSectorChart : string = "icb";
    currencyCode:any="USD";
    dateFormat: any;
    @Output() chartCurentIndexMarker: EventEmitter<string> = new EventEmitter();

    constructor(private weightService: WeightService ,private indexMarkerService:IndexMarkerService, private exportService: ExportToExcelService,private publishIndexMarkerService:PublishIndexMarkerService,
        private dialog: MatDialog,private colorService : MagellanColorService,private biService:BiService,
        private zone:NgZone,private idwChartExportService: IDWChartExportService,private dateService : DateService) {
            this.chartAttributes = {                            
                "bgColor": "#ffffff", 
                "exportEnabled": "1",
                "exportShowMenuItem": "0",               
                "numberSuffix":"%",
                "paletteColors": "#7FBA00,#00B294,#FF8C00,#0072C6,#68217A,#00188F,#808080",
                "borderAlpha": "20",
                "showCanvasBorder": "0",
                "usePlotGradientColor": "0",
                "plotBorderAlpha": "10",              
                "legendBorderAlpha": "0",
                "legendShadow": "0",
                "showValues": "0",
                "showBorder": "0",
                "plotFillAlpha": "100",
                "showXAxisLine": "1",
                "xAxisLineColor": "#999999",
                "divlineColor": "#999999",
                "divLineDashed": "1",
                "showAlternateHGridColor": "0",
                "showLegend":"0"
            };
            this.chartColorRange = {
                "mapbypercent": "1",
                "code": "e24b1a",
                "color": [
                    {
                        "code": "ffffff",
                        "maxvalue": "0",
                        "label": "Static"
                    },
                    {
                        "code": "6da81e",
                        "maxvalue": "25",
                        "label": "AVERAGE"
                    }
                ]
            }    
              
        }
        
        ngOnInit() {            
            this.dateFormat = this.dateService.getDateFormat(); 
            this.dateService.dateFormatPublish().subscribe(dateFormat=>{
                this.dateFormat =  dateFormat;      
                this.changeDateFormat(this.treeMapObject);
                                    
            });
            this.colors = this.colorService.getCurrentColorSchema();
            this.colorService.colorsPublish().subscribe(color => {     
                this.colors = color; 
                this.chartAttributes.paletteColors= color;    
            });         
            this.indexMarkers = this.indexMarkerService.getIndexMarkers();
            this.currentIndex = 0;  
            this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
                this.indexMarkers = result;
                this.chartSetup(this.selectedSectorChart,this.treeMapObject);
            });
                this.WeightsSectorIndustryChartLoader=true;    
                           
        }

        chartInitialize(event){
            return (eve, arg) => { 
                this.zone.run(() => {
                    this.chart =eve.sender;
                });  
                
            };
              
          }
        
          events = {
            initialized: this.chartInitialize(event)
        }
       
        private changeDateFormat(data: any) {
            if (this.dateFormat == "UK" ) {                      
                for (var i = 0; i < data.icbChartData.holdingscategories[0].category.length; i++) {
                  data.icbChartData.holdingscategories[0].category[i].label = this.dateService.changeDateFormatUK(data.icbChartData.holdingscategories[0].category[i].label);
                }
            }
            else {
              if (this.dateFormat == "USA" && this.checkLoadFirstTime == false) {
                for (var i = 0; i < data.icbChartData.holdingscategories[0].category.length; i++) {
                  data.icbChartData.holdingscategories[0].category[i].label = this.dateService.changeDateFormatUSA(data.icbChartData.holdingscategories[0].category[i].label);
                }                
              }             
            }
            this.checkLoadFirstTime = false;
          }

        carouselPrevious():void{

            var event: any = {eventName: "Sector Industry Weight chart carousel previous", inputParameters: [{Name: "Previous IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() },{Name : "Relevent TimePeriod" , Value: this.timePeriod},{Name: "Relevent As Of Date", Value: this.asofDates.toString()}]};
            this.biService.addEvent(event);
            if(0 == this.currentIndex){
               this.currentIndex = this.indexMarkers.length -1;
            }else{
                this.currentIndex = this.currentIndex - 1;
            }
            this.WeightsSectorIndustryChartLoader = true;
            this.chartCurentIndexMarker.emit(this.indexMarkers[this.currentIndex]);
        }
        
        carouselNext():void{
            var event: any = {eventName: "Sector Industry Weight chart carousel Next", inputParameters: [{Name: "Next IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() },{Name : "Relevent TimePeriod" , Value: this.timePeriod},{Name: "Relevent As Of Date", Value: this.asofDates.toString()}]};
            this.biService.addEvent(event);
           if((this.indexMarkers.length -1) == this.currentIndex){
                this.currentIndex = 0;
            }else{
                this.currentIndex = this.currentIndex + 1;
            }
            this.WeightsSectorIndustryChartLoader = true;
            this.chartCurentIndexMarker.emit(this.indexMarkers[this.currentIndex]);
        }
    
        getCurrentIndex(){
            return this.indexMarkers[this.currentIndex];
        }

        update(closeHoldingsChartModel: CloseHoldingsChartModel,startDate:any,endDate:any,timePeriod:any){  
            this.startDate = startDate;
            this.asofDates = endDate;   
            this.timePeriod = timePeriod;
            this.chartSetup(this.selectedSectorChart,closeHoldingsChartModel);
        }
    
        onChange(selectedChart,currencyCode){
            this.currencyCode=currencyCode;
            this.selectedSectorChart = selectedChart;  
            this.chartSetup(this.selectedSectorChart,this.treeMapObject);         
                            
        }
        private chartSetup(selectedChart:string,closeHoldingsChartModel: CloseHoldingsChartModel) {
            this.WeightsSectorIndustryChartLoader = false;                       
            this.treeMapObject = closeHoldingsChartModel; 
            this.checkLoadFirstTime=true; 
            this.changeDateFormat(this.treeMapObject);              
                        
            this.chartAttributes = {                            
                "bgColor": "#ffffff", 
                "exportEnabled": "1",
                "exportShowMenuItem": "0",               
                "numberSuffix":"%",
                "paletteColors":  this.colors,
                "borderAlpha": "20",
                "showCanvasBorder": "0",
                "usePlotGradientColor": "0",
                "plotBorderAlpha": "10",              
                "legendBorderAlpha": "0",
                "legendShadow": "0",
                "showValues": "0",
                "showBorder": "0",
                "plotFillAlpha": "100",
                "showXAxisLine": "1",
                "xAxisLineColor": "#999999",
                "divlineColor": "#999999",
                "divLineDashed": "1",
                "showAlternateHGridColor": "0",
                "showLegend":"0",
                "drawAnchors":"1",
                "anchorRadius":"5"
            };    
            this.chartAttributes.caption= "FTSE "+this.indexMarkers[this.currentIndex]+"- Industry"; 
            this.chartAttributes.caption= "FTSE "+this.indexMarkers[this.currentIndex]+"- Industry";
                   if(selectedChart == "icb" && 0 == this.treeMapObject.icbChartData.holdingsDataset.length){
                    this.WeightsSectorIndustryDataSource = {};
                   }else if (selectedChart == "rgs" && 0 == this.treeMapObject.rgsChartData.holdingsDataset.length){
                    this.WeightsSectorIndustryDataSource = {};
                   }
                   else{
                    this.WeightsSectorIndustryDataSource = {
                        "chart": this.chartAttributes,
                        "categories": (selectedChart == "icb")?this.treeMapObject.icbChartData.holdingscategories:this.treeMapObject.rgsChartData.holdingscategories,
                       "dataset": (selectedChart == "icb")?this.treeMapObject.icbChartData.holdingsDataset:this.treeMapObject.rgsChartData.holdingsDataset,
                    } ;
                   }
                      

                    this.popupChartAttributes={
                        "bgColor": "#ffffff",                
                        "numberSuffix":"%",
                        "paletteColors":  this.colors,
                        "borderAlpha": "20",
                        "showCanvasBorder": "0",
                        "usePlotGradientColor": "0",
                        "plotBorderAlpha": "10",              
                        "legendBorderAlpha": "0",
                        "legendShadow": "0",
                        "showValues": "0",
                        "showBorder": "0",
                        "plotFillAlpha": "100",
                        "showXAxisLine": "1",
                        "xAxisLineColor": "#999999",
                        "divlineColor": "#999999",
                        "divLineDashed": "1",
                        "showAlternateHGridColor": "0",
                        "showLegend":"1",
                        "legendPosition":"RIGHT",
                        "showToolTip":"1",
                        "drawAnchors":"1",
                        "anchorRadius":"5"
                    };            
                    this.popupChartAttributes.caption= "FTSE "+this.indexMarkers[this.currentIndex]+"- Industry";
                    if(selectedChart == "icb" && 0 == this.treeMapObject.icbChartData.holdingsDataset.length){
                        this.popupData= {};
                       }else if (selectedChart == "rgs" && 0 == this.treeMapObject.rgsChartData.holdingsDataset.length){
                        this.popupData = {};
                       }
                       else{
                        this.popupData= {
                            "chart": this.popupChartAttributes,
                            "categories":(selectedChart == "icb")?this.treeMapObject.icbChartData.holdingscategories:this.treeMapObject.rgsChartData.holdingscategories,
                            "dataset": (selectedChart == "icb")?this.treeMapObject.icbChartData.holdingsDataset:this.treeMapObject.rgsChartData.holdingsDataset,
                        } ;
                        this.popupDataSource = {
                          "dataSource": this.popupData,
                          "asOfDate": (this.dateFormat == "USA") ? this.dateService.changeDateFormat(this.asofDates) : this.dateService.changeDateFormatUK(this.asofDates)
                        };
                       } 
        }

        loadingRefresh(){
            this.WeightsSectorIndustryChartLoader = true;
            this.WeightsSectorIndustryDataSource = {};
        }
         
        loadingStop(){
            this.WeightsSectorIndustryChartLoader = false;
        }
        failedLoad(){
            this.WeightsSectorIndustryDataSource = {};
            this.WeightsSectorIndustryChartLoader = false;
        }
      
     ExportXls(event):void{
        var event: any = {eventName: "Weight Sector Industry Weight Chart Exported", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name : "Exported TimePeriod" , Value: this.timePeriod},{Name: "Exported As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);
        var inputData = {
            "exportType": "xls",
            "code": "weight-getIndustryWeightData",
            "xlsFileName": "IndustryRawData",
            "asOfDate": this.asofDates,
            "indexMarkers": [this.indexMarkers[this.currentIndex]],
            "returnType": this.selectedSectorChart,
            "currencyCode": this.currencyCode,
            "isIndexLegend": false,
            "fromDate": this.startDate,
            "timePeriod": this.timePeriod
          };
          this.idwChartExportService.export(inputData);
          }

          ExportPNG(event):void{
            var event: any = {eventName: "Export Weight Sector Industry Weight Chart TO PNG", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name : "Exported TimePeriod" , Value: this.timePeriod},{Name: "Exported As Of Date", Value: this.asofDates.toString()}]};
            this.biService.addEvent(event);
            this.popupDataSource.dataSource.chart.showlegend = "1";
            var inputData = {
                "exportType": "png",
                "legend": false,
                "dataSource": this.popupDataSource.dataSource,
                "chartid" : "WeightsSectorIndustryChartExport",
                "type" : "stackedarea2d",
                "targetDiv" : "exportPngDiv",
                "chartReference": this.chart,
                "pngFileName" : "IndustryRawData",
                "isIndexLegend": false,
                "chartTitle": "History Weights - Industry",
                "chartDate": this.asofDates
             };
             this.idwChartExportService.export(inputData);
          }

          openDialog(): void{ 
            var event: any = {eventName: "help dialog open of Weight Sector Industry Weight Chart", inputParameters: [{Name : "Expanded IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name : "Expanded TimePeriod" , Value: this.timePeriod},{Name: "Expanded As Of Date", Value: this.asofDates.toString()}]};
            this.biService.addEvent(event);
            this.popupChartAttributes.paletteColors = this.colors
            this.popupDataSource = {
                "dataSource": this.popupData,
                "asOfDate": (this.dateFormat == "USA") ? this.dateService.changeDateFormat(this.asofDates) : this.dateService.changeDateFormatUK(this.asofDates)
              };
            this.popupDataSource.dataSource.chart.showlegend = "1";
            let dialogRef = this.dialog.open(WeightSectorHistoryIndustryComponentDialog, {
                width: '80%',
                height: '70%',
            data: this.popupDataSource
      });
    }
                         
    }
    
    
@Component({
    selector: 'weight-sector-history-weights-industry-chart-dialog',
	templateUrl: './weight-sector-history-weights-industry-chart.component.dialog.html'
})
export class WeightSectorHistoryIndustryComponentDialog{

    constructor(
    public dialogRef: MatDialogRef<WeightSectorHistoryIndustryComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    onClose(): void {
        this.dialogRef.close();
  }

   
}
