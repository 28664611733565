import { Injectable } from '@angular/core';
// tslint:disable-next-line:import-blacklist

import { BehaviorSubject, Observable, of, from, Subject } from 'rxjs';
import { NewReportForm, Report, ReportData, ExportReportDetails, ExportReportDetailsColumns, ExportReportData } from '../models/report';
import { map } from 'rxjs/operators';
import { Portfolio } from '../models/portfolio';
import { Benchmark } from '../models/benchmark';
import { SIDataCalculationModel } from '../models/sidatacalculation';
import { ResponseModel } from '../../models/landing-page-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SIDataCalculationService {
  
  

  constructor( private httpClient: HttpClient) { }
  
  private _listners= new Subject<any>(); 
  clientPublish():Observable<any>{
    return this._listners.asObservable();
  }


  publishClient(client: string) {
    this._listners.next(client);
  } 
  FetchSIFeeds(): Observable<any> {
    return from(this.GetSIFeeds());
  }
  
  fetchTriggers$(): Observable<any> {
    //return this.reports$.asObservable();
    return from(this.GetFeedTriggers());
  }

  GetFeedTriggers(): Observable<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SIDataCalculations/GetFeedTriggers',null,
      {headers: headers});   
  }
  fetchTriggerFeedData$(feedName, asofDate): Observable<any> {
    return from(this.TriggerFeed(feedName, asofDate));

  }
   TriggerFeed(feedName, asofDate): Observable<any> {
    debugger;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SIDataCalculations/TriggerFeeds',JSON.stringify({ FeedName: feedName, AsofDate: asofDate }),
      { headers: headers });
  }
  GetSIFeeds(): Observable<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SIDataCalculations/GetSIFeeds',null,
      {headers: headers});   
  }
  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}

