import { Injectable }    from '@angular/core';

@Injectable()
export class SanetizeStringService{


    sanitizeStringLiteral(userName){
        var res = "";
        var intermediate = "";
        for(var counter = userName.length - 1;counter>=0; counter--){
            if(userName[counter]!= '\\'){
                intermediate+=userName[counter];
            }
        }
        for(var lastCounter = intermediate.length - 1; lastCounter>=0;lastCounter--){
            res+=intermediate[lastCounter];
        }
        return res;
    }
}