import { Component, OnInit,Input  } from '@angular/core';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import { BiService } from '../../services/BiService.service';
import{CurrencyService}from '../../services/currency.service';
import {DateService} from '../../services/date.service';
import {DataqualityserviceService} from '../../services/dataqualityservice.service';
import {Observable, forkJoin} from 'rxjs';
import { data } from 'jquery';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-price-data-quality',
  templateUrl: './price-data-quality.component.html',
  styleUrls: ['./price-data-quality.component.css']
})
export class PriceDataQualityComponent implements OnInit {
  
  asOfDate : any;
  indexDetailsModel : any;
  indexMarkers: any[]= [];    
  currencyCode:any;
  pricedata:any;
  DataGridLoader:boolean =true;
  RowExpandLoader:boolean[] =[]; 
  panelOpenState = false;
  client : string ;
  constructor(private publishIndexMarkerService:PublishIndexMarkerService,private indexMarkerService:IndexMarkerService,
    private exportService:ExportToExcelService, private biService:BiService, private currencyService:CurrencyService,
     private dataqualityservice:DataqualityserviceService,private dateService:DateService 
     ,private route: ActivatedRoute) { }

  ngOnInit() {
          this.indexMarkers =  this.indexMarkerService.getIndexMarkers();
          this.currencyCode = this.currencyService.getCurrencyCode();
          this.asOfDate = this.dateService.getCalendarDate().end;
          var that = this;
          this.dateService.calendarDatePublish().subscribe(result=>{ 
            that.asOfDate = moment(result.end).format('MM/DD/YYYY');
            that.gridSetup();
        });
         var sub = this.route.queryParams.subscribe(params => {  
          this.client =params['Client'] ;
          if(this.client== undefined)
          {
            this.client= "ALL";
          }
          this.gridSetup();
        });       
  }
  getClosePriceSearchDetails(element,index){
    if(this.panelOpenState == true){
      this.RowExpandLoader[index] = true;
      this.dataqualityservice.getDataQualityPriceDifferenceDetails(element.securityId ,this.asOfDate ,this.client).then(data=>{
      this.indexDetailsModel = data["data"] ;
      this.RowExpandLoader[index] = false;
    },onerror =>{
      this.RowExpandLoader[index] =false; 
  });
  }
  }
  gridSetup(){
    var that = this;
    that.RowExpandLoader = [];
          this.DataGridLoader   = true;
          this.dataqualityservice.getPriceData(this.asOfDate , this.client).then(data=>{
          this.pricedata=data;
          var index = 0;
          data.forEach(element => {
            that.RowExpandLoader[index] = false;
            index++;
          });
          this.DataGridLoader=false;
          },onerror =>{
          this.DataGridLoader =false; 
          });
    }

    ExportXls(element):void{
      this.dataqualityservice.GetClosePriceDifferenceDetailsExport(this.asOfDate,element.securityId ,this.client).then(res => {
        
              this.exportService.exportData(res["data"],"Close price data");
                
              }).catch(err=> {
                 
              }); 
    }

}
