import { Component, OnInit, Output, AfterViewInit, ViewChild, EventEmitter } from '@angular/core';
import { IndexMarkerService } from '../../services/indexmarker.service';
import { PublishIndexMarkerService } from '../../services/publish-index-marker.service';
import { CloseTopLevelSectorChartComponent } from '../close-holdings-top-level-sector-chart/close-holdings-top-level-sector-chart.component';
import { WeightSectorWeightsTreeComponent } from '../weight-sector-weights-tree/weight-sector-weights-tree.component';
import { CloseHoldingService } from '../../services/close-holdings.service';
import { WeightService } from '../../services/weight.service';
import {BiService} from '../../services/BiService.service';
import { DateService } from '../../services/date.service';
import { WeightSectorHistoryWeightsCountryChartComponent } from '../weight-sector-history-weights-country-chart/weight-sector-history-weights-country-chart.component'
import { WeightSectorHistoryWeightsSectorChartComponent } from '../weight-sector-history-weights-sector-chart/weight-sector-history-weights-sector-chart.component'
import { WeightSectorHistoryWeightsSubSectorChartComponent } from '../weight-sector-history-weights-subsector-chart/weight-sector-history-weights-subsector-chart.component'
import { WeightSectorHistoryIndustryComponent } from '../weight-sector-history-weights-industry-chart/weight-sector-history-weights-industry-chart.component'
import { WeightSectorHistorySuperSectorComponent } from '../weight-sector-history-weights-supersector-chart/weight-sector-history-weights-supersector-chart.component'
import { CurrencyService } from '../../services/currency.service';
import { ExportToExcelService } from '../../services/exportToExcel.service';
import { IDWChartExportService } from '../../services/idw-chart-export.service';
import { async } from '@angular/core/testing';

@Component({
    selector: 'weight-page',
    templateUrl: './weight-page.component.html',
    styleUrls: ['./weight-page.component.css']
})
export class WeightPageComponent implements OnInit {

    public singlePicker = {
        locale: { format: 'MM/DD/YYYY' },
        alwaysShowCalendars: false,
        singleDatePicker: true,
        autoApply: true,
        linkedCalendars: false
    }

    // @ViewChild(CloseTopLevelSectorChartComponent, { static: false }) topLevelSectorChartComponent;
    // @ViewChild(WeightSectorWeightsTreeComponent, { static: false }) weightSectorWeightsTreeComponent;
    // @ViewChild(WeightSectorHistoryWeightsCountryChartComponent, { static: false }) weightSectorHistoryWeightsCountryChartComponent;
    // @ViewChild(WeightSectorHistoryWeightsSectorChartComponent, { static: false }) weightSectorHistoryWeightsSectorChartComponent;
    // @ViewChild(WeightSectorHistoryWeightsSubSectorChartComponent, { static: false }) weightSectorHistoryWeightsSubSectorChartComponent;
    // @ViewChild(WeightSectorHistoryIndustryComponent, { static: false }) weightSectorHistoryIndustryComponent;
    // @ViewChild(WeightSectorHistorySuperSectorComponent, { static: false }) weightSectorHistorySuperSectorComponent;
    @Output() calendarDate: any;
    @Output() startDate: any;
    @Output() indexMarkers: any;
    @Output() selectedSectorChart: any;
    @Output() currencyCode: any;
    timePeriod: any;
    Loader:boolean = false;
    constructor(private dateService: DateService, private closeHoldingService: CloseHoldingService,
        private indexMarkerService: IndexMarkerService, private publishIndexMarkerService: PublishIndexMarkerService,
        private weightService: WeightService, private idwChartExportService: IDWChartExportService, private exportService: ExportToExcelService, private biService: BiService, private currencyService: CurrencyService) {
    }

    defaultActiveCurrency: string = "USD";
    currencies: any[] = [
        { "key": "£", "value": "POUND" },
        { "key": "€", "value": "EURO" },
        { "key": "¥", "value": "YEN" },
        { "key": "$", "value": "USD" }
    ];
    currentIndex: any;
    selectindexmarker: any;
    indexData: any[] = [];
    weights: any[] = ['Industry', 'SuperSector', 'Sector', 'SubSector', 'Top Level Sector', 'Country'];
    selectWeight: string = 'Industry';
    historyResults: any[] = [];
    sub : any;
    ngOnInit(): void {
        this.selectedSectorChart = "icb";
        this.currencyCode = this.currencyService.getCurrencyCode();
        this.indexMarkers = this.indexMarkerService.getIndexMarkers();
        this.selectindexmarker = this.indexMarkers[0];
        this.calendarDate = this.dateService.getCalendarDate().end.substring(0, 10);
        this.startDate = this.dateService.getCalendarDate().start.substring(0, 10);
        this.timePeriod = this.dateService.getTimePeriod();
        // this.singleSelect(this.dateService.getCalendarDate());
        this.publishIndexMarkerService.indexMarkerPublish().subscribe(result => {
            this.indexMarkers = result;
            this.selectindexmarker = this.indexMarkers[0];
        });

        this.currencyService.currencyCodePublish().subscribe(currency => {
            this.onNotifyCurrency(currency);
        });

        this.dateService.calendarDatePublish().subscribe(result => {
            this.startDate = this.dateService.changeDateFormat(result.start);
            this.calendarDate = this.dateService.changeDateFormat(result.end);
            // this.weightSectorHistoryIndustryComponent.loadingRefresh();
            // this.weightSectorHistorySuperSectorComponent.loadingRefresh();
            // this.weightSectorHistoryWeightsCountryChartComponent.loadingRefresh();
            // this.weightSectorHistoryWeightsSectorChartComponent.loadingRefresh();
            // this.weightSectorHistoryWeightsSubSectorChartComponent.loadingRefresh();
            // this.topLevelSectorChartComponent.loadingRefresh();
            // this.weightSectorWeightsTreeComponent.loadingRefresh();
            // this.singleSelect(result); 
        });
        this.dateService.timePeriodPublish().subscribe(res => {
            // this.weightSectorHistoryIndustryComponent.loadingRefresh();
            // this.weightSectorHistorySuperSectorComponent.loadingRefresh();
            // this.weightSectorHistoryWeightsCountryChartComponent.loadingRefresh();
            // this.weightSectorHistoryWeightsSectorChartComponent.loadingRefresh();
            // this.weightSectorHistoryWeightsSubSectorChartComponent.loadingRefresh();
            // this.topLevelSectorChartComponent.loadingRefresh();
            // this.weightSectorWeightsTreeComponent.loadingRefresh();
            this.timePeriodChange(res);
        });
        var that = this;
        // this.weightService.getAllSectorWeights(this.calendarDate, this.indexMarkers).then(res => {
        //     if (null != res && undefined != res && null != res.data && undefined != res.data) {
        //         for (let indexmarker of that.indexMarkers) {
        //             that.indexData[indexmarker] = res.data[indexmarker];
        //         }
        //     } else {
        //         // this.weightSectorHistoryIndustryComponent.loadingStop();
        //         // this.weightSectorHistorySuperSectorComponent.loadingStop();
        //         // this.weightSectorHistoryWeightsCountryChartComponent.loadingStop();
        //         // this.weightSectorHistoryWeightsSectorChartComponent.loadingStop();
        //         // this.weightSectorHistoryWeightsSubSectorChartComponent.loadingStop();
        //         // this.topLevelSectorChartComponent.loadingStop();
        //         // this.weightSectorWeightsTreeComponent.loadingStop();
        //     }

        // }).catch(err => {
        //     //     this.weightSectorHistoryIndustryComponent.failedLoad();
        //     // this.weightSectorHistorySuperSectorComponent.failedLoad();
        //     // this.weightSectorHistoryWeightsCountryChartComponent.failedLoad();
        //     // this.weightSectorHistoryWeightsSectorChartComponent.failedLoad();
        //     // this.weightSectorHistoryWeightsSubSectorChartComponent.failedLoad();
        //     // this.topLevelSectorChartComponent.failedLoad();
        //     // this.weightSectorWeightsTreeComponent.failedLoad();
        // });;
    }

    ngAfterViewInit() {
        var event: any = { eventName: "Weight Sector Sector Weight DataTree Load" };
        this.biService.addEvent(event);
    }
    onChange(selectedChart) {
        this.selectedSectorChart = selectedChart;
        if(this.selectedSectorChart== 'icb')
        {
            this.weights = ['Industry', 'SuperSector', 'Sector', 'SubSector', 'Top Level Sector', 'Country'];
            this.selectWeight = 'Industry';
        }else{
            this.weights = ['Industry', 'Sector', 'SubSector', 'Top Level Sector', 'Country'];
            this.selectWeight = 'Industry';
        }
        //     this.topLevelSectorChartComponent.onChange(selectedChart,this.currencyCode);  
        //     this.weightSectorHistoryWeightsCountryChartComponent.onChange(this.selectedSectorChart,this.currencyCode);
        //     this.weightSectorHistoryWeightsSectorChartComponent.onChange(selectedChart,this.currencyCode);
        //    this.weightSectorHistoryWeightsSubSectorChartComponent.onChange(selectedChart,this.currencyCode);
        //    this.weightSectorHistoryIndustryComponent.onChange(selectedChart,this.currencyCode);  
        //    this.weightSectorHistorySuperSectorComponent.onChange(selectedChart,this.currencyCode); 
    }
    // singleSelect(value: any) {

    //     this.calendarDate = this.dateService.changeDateFormat(value.end);
    //     this.startDate = this.dateService.changeDateFormat(value.start);
    //     if (undefined != this.topLevelSectorChartComponent) {
    //         this.topLevelSectorChartComponent.update(this.calendarDate);
    //     }
    //     this.weightTabUpdate(this.indexMarkers[0], this.startDate, this.calendarDate, this.timePeriod);
    // }

    timePeriodChange(value: any) {
        this.timePeriod = value;
        //this.weightTabUpdate(this.indexMarkers[0], this.startDate, this.calendarDate, this.timePeriod);
    }

    // historyChange(event) {
    //     this.weightTabUpdate(event, this.startDate, this.calendarDate, this.timePeriod);
    // }


    // private weightTabUpdate(indexmarker, startDate, endDate, timePeriod) {
    //     if (undefined != this.historyResults[indexmarker + startDate + endDate + timePeriod]) {
    //         this.updateWeightTabChart(startDate, endDate, timePeriod);
    //     } else {

    //         var countryEvent: any = { eventName: "Weight Sector Country Weight Chart Load", inputParameters: [{ Name: "Relevent IndexMarker", Value: indexmarker }, { Name: "Relevent TimePeriod", Value: this.timePeriod }, { Name: "Relevent StartDate", Value: startDate }, { Name: "Relevent EndDate", Value: endDate }] };
    //         var industryEvent: any = { eventName: "Weight Sector Industry Weight Chart Load", inputParameters: [{ Name: "Relevent IndexMarker", Value: indexmarker }, { Name: "Relevent TimePeriod", Value: this.timePeriod }, { Name: "Relevent StartDate", Value: startDate }, { Name: "Relevent EndDate", Value: endDate }] };
    //         var sectorEvent: any = { eventName: "Weight Sector Sector Weight Chart Load", inputParameters: [{ Name: "Relevent IndexMarker", Value: indexmarker }, { Name: "Relevent TimePeriod", Value: this.timePeriod }, { Name: "Relevent StartDate", Value: startDate }, { Name: "Relevent EndDate", Value: endDate }] };
    //         var subSectorEvent: any = { eventName: "Weight Sector SubSector Weight Chart Load", inputParameters: [{ Name: "Relevent IndexMarker", Value: indexmarker }, { Name: "Relevent TimePeriod", Value: this.timePeriod }, { Name: "Relevent StartDate", Value: startDate }, { Name: "Relevent EndDate", Value: endDate }] };
    //         var superSectorEvent: any = { eventName: "Weight Sector SuperSector Weight Chart Load", inputParameters: [{ Name: "Relevent IndexMarker", Value: indexmarker }, { Name: "Relevent TimePeriod", Value: this.timePeriod }, { Name: "Relevent StartDate", Value: startDate }, { Name: "Relevent EndDate", Value: endDate }] };

    //         this.biService.addEvent(countryEvent);
    //         this.biService.addEvent(industryEvent);
    //         this.biService.addEvent(sectorEvent);
    //         this.biService.addEvent(subSectorEvent);
    //         this.biService.addEvent(superSectorEvent);
    //         this.weightService.getWeightTabData(this.calendarDate, [indexmarker], this.startDate, timePeriod)
    //             .then(res => {
    //                 if (null != res && undefined != res && null != res.data && undefined != res.data) {
    //                     this.historyResults[indexmarker + startDate + endDate + timePeriod] = res.data;
    //                     this.updateWeightTabChart(startDate, endDate, timePeriod);
    //                 } else {
    //                     this.weightSectorHistoryIndustryComponent.loadingStop();
    //                     this.weightSectorHistorySuperSectorComponent.loadingStop();
    //                     this.weightSectorHistoryWeightsCountryChartComponent.loadingStop();
    //                     this.weightSectorHistoryWeightsSectorChartComponent.loadingStop();
    //                     this.weightSectorHistoryWeightsSubSectorChartComponent.loadingStop();
    //                     this.topLevelSectorChartComponent.loadingStop();
    //                     this.weightSectorWeightsTreeComponent.loadingStop();
    //                 }

    //             }).catch(err => {
    //                 this.weightSectorHistoryIndustryComponent.failedLoad();
    //                 this.weightSectorHistorySuperSectorComponent.failedLoad();
    //                 this.weightSectorHistoryWeightsCountryChartComponent.failedLoad();
    //                 this.weightSectorHistoryWeightsSectorChartComponent.failedLoad();
    //                 this.weightSectorHistoryWeightsSubSectorChartComponent.failedLoad();
    //                 this.topLevelSectorChartComponent.failedLoad();
    //                 this.weightSectorWeightsTreeComponent.failedLoad();
    //             });
    //     }

    // }

    // private updateWeightTabChart(startDate, endDate, timePeriod) {
    //     this.weightSectorHistoryWeightsCountryChartComponent.update(
    //         this.historyResults[this.weightSectorHistoryWeightsCountryChartComponent.getCurrentIndex()
    //         + startDate + endDate + timePeriod]["GetCountryWeights"], startDate, endDate, timePeriod);
    //     this.weightSectorHistoryWeightsSectorChartComponent.update(
    //         this.historyResults[this.weightSectorHistoryWeightsSectorChartComponent.getCurrentIndex()
    //         + startDate.toString() + endDate.toString() + timePeriod]
    //         ["GetSectorWeights"], startDate, endDate, timePeriod);
    //     this.weightSectorHistoryWeightsSubSectorChartComponent.update(
    //         this.historyResults[this.weightSectorHistoryWeightsSubSectorChartComponent.getCurrentIndex()
    //         + startDate.toString() + endDate.toString() + timePeriod]
    //         ["GetSubSectorWeights"], startDate, endDate, timePeriod);
    //     this.weightSectorHistoryIndustryComponent.update(
    //         this.historyResults[this.weightSectorHistoryIndustryComponent.getCurrentIndex()
    //         + startDate.toString() + endDate.toString() + timePeriod]
    //         ["getindustryweights"], startDate, endDate, timePeriod);
    //     this.weightSectorHistorySuperSectorComponent.update(
    //         this.historyResults[this.weightSectorHistorySuperSectorComponent.getCurrentIndex()
    //         + startDate.toString() + endDate.toString() + timePeriod]
    //         ["getsupersectorweights"], startDate, endDate, timePeriod);
    //     this.topLevelSectorChartComponent.update(this.calendarDate);
    // }


    onNotifyCurrency(currency: any) {
        this.currencyCode = currency;
        this.defaultActiveCurrency = currency;
        // this.topLevelSectorChartComponent.onChange(this.selectedSectorChart, this.currencyCode);
        // this.weightSectorHistoryWeightsCountryChartComponent.onChange(this.selectedSectorChart, this.currencyCode);
        // this.weightSectorHistoryWeightsSectorChartComponent.onChange(this.selectedSectorChart, this.currencyCode);
        // this.weightSectorHistoryWeightsSubSectorChartComponent.onChange(this.selectedSectorChart, this.currencyCode)
        // this.weightSectorHistoryIndustryComponent.onChange(this.selectedSectorChart, this.currencyCode);
        // this.weightSectorHistorySuperSectorComponent.onChange(this.selectedSectorChart, this.currencyCode);
    }

    exportIndustryWeights() {
        var event: any = { eventName: "Weight Sector Industry Weight Chart Exported", inputParameters: [{ Name: "Exported IndexMarker", Value: this.selectindexmarker.toString() }, { Name: "Exported TimePeriod", Value: this.timePeriod }, { Name: "Exported As Of Date", Value: this.calendarDate.toString() }] };
        this.biService.addEvent(event);
        var inputData = {
            "exportType": "xls",
            "code": "weight-getIndustryWeightData",
            "xlsFileName": "IndustryRawData",
            "asOfDate": this.calendarDate,
            "indexMarkers": [this.selectindexmarker],
            "returnType": this.selectedSectorChart,
            "currencyCode": this.currencyCode,
            "isIndexLegend": false,
            "fromDate": this.startDate,
            "timePeriod": this.timePeriod
        };
        this.idwChartExportService.export(inputData);
       
    }
    exportSuperSectorWeights(): void {
        var event: any = { eventName: "Weight Sector SuperSector Weight Chart Exported", inputParameters: [{ Name: "Exported IndexMarker", Value: this.selectindexmarker.toString() }, { Name: "Exported TimePeriod", Value: this.timePeriod }, , { Name: "Exported As Of Date", Value: this.calendarDate.toString() }] };
        this.biService.addEvent(event);
        var inputData = {
            "exportType": "xls",
            "code": "weight-getSuperSectorWeightData",
            "xlsFileName": "SuperSectorRawData",
            "asOfDate": this.calendarDate,
            "indexMarkers": [this.selectindexmarker],
            "returnType": this.selectedSectorChart,
            "currencyCode": this.currencyCode,
            "fromDate": this.startDate,
            "timePeriod": this.timePeriod
        };
        this.idwChartExportService.export(inputData);
    }

    exportSectorWeights(): void {
        var event: any = { eventName: "Weight Sector Sector Weight Chart Exported", inputParameters: [{ Name: "Exported IndexMarker", Value: this.selectindexmarker.toString() }, { Name: "Exported TimePeriod", Value: this.timePeriod }, { Name: "Exported As Of Date", Value: this.calendarDate.toString() }] };
        this.biService.addEvent(event);
        var inputData = {
            "exportType": "xls",
            "code": "weight-getSectorWeightsExportData",
            "xlsFileName": "sectorRawData",
            "asOfDate": this.calendarDate,
            "indexMarkers": [this.selectindexmarker],
            "returnType": this.selectedSectorChart,
            "fromDate": this.startDate,
            "timePeriod": this.timePeriod
        };
        this.idwChartExportService.export(inputData);
    }

    exportSubSectorWeights(): void {
        var event: any = { eventName: "Weight Sector SubSector Weight Chart Exported", inputParameters: [{ Name: "Exported IndexMarker", Value: this.selectindexmarker.toString() }, { Name: "Exported TimePeriod", Value: this.timePeriod }, { Name: "Exported As Of Date", Value: this.calendarDate.toString() }] };
        this.biService.addEvent(event);
        var inputData = {
            "exportType": "xls",
            "code": "weight-getSubSectorWeightsExportData",
            "xlsFileName": "subSectorRawData",
            "asOfDate": this.calendarDate,
            "indexMarkers": [this.selectindexmarker],
            "returnType": this.selectedSectorChart,
            "fromDate": this.startDate,
            "timePeriod": this.timePeriod
        };
        this.idwChartExportService.export(inputData);
    }

    exportCountryWeights(): void {
        var event: any = { eventName: "Weight Sector Country Weight Chart Exported", inputParameters: [{ Name: "Exported IndexMarker", Value: this.selectindexmarker.toString() }, { Name: "Exported TimePeriod", Value: this.timePeriod }, { Name: "Exported As Of Date", Value: this.calendarDate.toString() }] };
        this.biService.addEvent(event);
        var inputData = {
            "exportType": "xls",
            "code": "weight-getCountryWeightsExportData",
            "xlsFileName": "countrySectorRawData",
            "asOfDate": this.calendarDate,
            "indexMarkers": [this.selectindexmarker],
            "fromDate": this.startDate,
            "timePeriod": this.timePeriod
        };
        this.idwChartExportService.export(inputData);
    }
    async exportTopLevelSector() {
        var event: any = { eventName: "Weight Top Level Sector Chart Exported", inputParameters: [{ Name: "Exported IndexMarker", Value: this.selectindexmarker.toString() }, { Name: "Exported As Of Date", Value: this.calendarDate.toString() }] };
        this.biService.addEvent(event);
        var inputData = {
            "exportType": "xls",
            "code": "weight-getTopLevelSectorData",
            "xlsFileName": "closeTopLevelSectorRawData",
            "asOfDate": this.calendarDate,
            "indexMarkers": [this.selectindexmarker],
            "returnType": this.selectedSectorChart,
            "currencyCode": this.currencyCode,
            "fromDate": this.startDate
        };
        this.idwChartExportService.export(inputData);
    }
    selectIndexmarker(indexmarker: string) {
        this.selectindexmarker = indexmarker;
    }
    selectWeights(weight: string) {
        this.selectWeight = weight;
    }
    ExportXLS() {     
        this.weightService.DataLoaded.observers=[];
        this.Loader = true ;
        if (this.selectWeight == 'Industry') {
            this.exportIndustryWeights();
        }else if (this.selectWeight == 'SuperSector') {
            this.exportSuperSectorWeights();
        }else if (this.selectWeight == 'Sector') {
            this.exportSectorWeights();
        }else if (this.selectWeight == 'SubSector') {
            this.exportSubSectorWeights();
        }else if (this.selectWeight == 'Top Level Sector') {
            this.exportTopLevelSector();
        }else if (this.selectWeight == 'Country') {
            this.exportCountryWeights();
        }
       if(this.sub == true) this.sub.unsubscribe();
        this.sub = this.weightService.DataLoaded.subscribe(
            (data: any) => {
                this.Loader =  false ;  
                if(data == "Nodata"){
                    alert("No data is available to export for selected Date Range.");
                }else if(data == "Success"){

                }else if(data == "Fail" ){
                    alert("Please Contact Administrator or Check for different calendar date");
                } 
            });
    }

    ngOnDestroy() {
        if(this.sub){
        this.sub.unsubscribe();
        }
      }
}