import { Component, OnInit,Input,SimpleChanges, Inject,NgZone } from '@angular/core';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import { ValuesService } from '../../services/values.service';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {MagellanColorService} from '../../services/magellancolor.service';
import {DateService} from '../../services/date.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { BiService } from '../../services/BiService.service';
import {CurrencyService} from '../../services/currency.service';
import * as moment from 'moment';
import{Chart} from 'chart.js';
import { saveAs } from 'file-saver/FileSaver';
import { Buffer } from 'buffer';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
@Component({
  selector: 'app-growth-of-unit',
  templateUrl: './growth-of-unit.component.html',
})

export class GrowthOfUnitComponent implements OnInit {

    indexMarkers : any; 
    currencyCode : string;  
    timePeriod : any;    
    fromDate : any;
    GOILoader : boolean = true;  
    chartAttributes: any;  
    dataSource: any ;  
    toDate : any;
    returnType : any;
    chartOptions : any;
    chartColors : any;
    chartLabels : any;
    growthofunitData : any;
    colors : any;   
    chart:any;
    events:any;
    growthofUnitLanding :any;
  constructor(private dateService : DateService, private valService: ValuesService,private publishIndexMarkerService:PublishIndexMarkerService,private indexMarkerService:IndexMarkerService,
     private magellanColorService : MagellanColorService, private exportService:ExportToExcelService,public dialog: MatDialog,private biService: BiService,
     private zone:NgZone,private currencyService:CurrencyService,private idwChartExportService: IDWChartExportService) { }
  
  ngOnInit() { 
  
    this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
        this.chartOptions = {};
        this.chartLabels = []; 
        this.indexMarkers = result;       
        this.chartSetup(this.indexMarkers);               
      });
      
      this.colors = this.magellanColorService.getCurrentColorSchema().split(',');
      this.magellanColorService.colorsPublish().subscribe(color => { 
        this.colors= color.split(',');    
        for(var i=0;i<this.indexMarkers.length;i++){
          const canvas = <HTMLCanvasElement> document.getElementById('growthofUnitLanding');
          const ctx = canvas.getContext('2d');
          var gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0,this.colors[i] );
          gradient.addColorStop(0.7, 'rgba(255, 255, 255, 0)');
          gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
          this.growthofUnitLanding .data.datasets[i].backgroundColor=gradient;
          this.growthofUnitLanding .data.datasets[i].borderColor =this.colors[i];
          this.growthofUnitLanding.update();
          //growthofunitData.data.dataSet[i].backgroundColor =gradient;
        }
       // this.chartAttributes.paletteColors = this.colors;     
      });
         
      this.dateService.calendarDatePublish().subscribe(dateRange=>{
        this.fromDate =  this.dateService.changeDateFormat(dateRange.start);
        this.toDate =  this.dateService.changeDateFormat(dateRange.end);
        this.chartSetup(this.indexMarkers);
    });

    this.GOILoader =true; 
    this.indexMarkers = this.indexMarkerService.getIndexMarkers();
    this.fromDate = this.dateService.getCalendarDate().start.substring(0,10);
    this.toDate = this.dateService.getCalendarDate().end.substring(0,10);        
    this.returnType = 'TOTAL'; 
    this.currencyCode = this.currencyService.getCurrencyCode(); 
    this.timePeriod= this.dateService.getTimePeriod();

    this.chartSetup(this.indexMarkers);

    this.dateService.timePeriodPublish().subscribe(timePeriod=>{
        this.timePeriod =  timePeriod;        
       this.chartSetup(this.indexMarkers);
    });
    this.events = {
        initialized: this.chartInitialize(event)
    }
          
    this.currencyService.currencyCodePublish().subscribe(currency=>{
        this.currencyCode =  currency;        
       this.chartSetup(this.indexMarkers);
    });   
  }
  chartInitialize(event){
    return (eve, arg) => { 
        this.zone.run(() => {
            this.chart =eve.sender;
        });  
        
    };
      
  }

  
  openWidgetDialog():void{
    var event: any = {eventName: "GOU help dialog open"};
    this.biService.addEvent(event);
    let dialogRef = this.dialog.open(GrowthOfUnitComponentDialog, {
        width: '800px' , 
        data: this.indexMarkers     
      });
  }

  ExportXls():void{   
    var event: any = {eventName: "Export Growth of Unit", inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode},{Name : "TimePeriod" , Value: this.timePeriod},{Name : "ReturnType" , Value: this.returnType}]};
    this.biService.addEvent(event);
    var inputData = {
        "exportType": "xls",
        "code": "value-getGrowthofUnitByValuesData",
        "xlsFileName": "GrowthOfUnit",
        "fromDate": this.fromDate,
        "toDate": this.toDate,
        "indexMarkers": this.indexMarkers,
        "currencyCode": this.currencyCode,
        "timePeriod" : this.timePeriod,
        "returnType" : this.returnType
      };
      this.idwChartExportService.export(inputData);
  }

  ExportPNG(event):void{
    var event: any = {eventName: "Export Growth of Unit TO PNG", inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode},{Name : "TimePeriod" , Value: this.timePeriod},{Name : "ReturnType" , Value: this.returnType}]};
    this.biService.addEvent(event);
    // this.chart.height= "50%";
    // var inputData = {
    //     "exportType": "png",
    //     "legend": false,
    //     "dataSource": this.dataSource,
    //     "chartid" : "growthofUnitLandingExport",
    //     "type" : "msline",
    //     "targetDiv" : "exportPngDiv",
    //     "chartReference": this.chart,
    //     "pngFileName" : "GrowthOfUnit",
    //     "chartTitle": "Growth Of Unit",
    //     "chartDate": this.toDate
    //  };
    //  this.idwChartExportService.export(inputData);
    //  this.chart.height = "1250%";
     var canvas = <HTMLCanvasElement> document.getElementById('growthofUnitLanding'); 
     var imageUrl = canvas.toDataURL("image/png");
     var base64 = imageUrl.split("base64,")[1];
     var bufferArr = Buffer.from(base64,'base64');
     var blob = new Blob([bufferArr], { type: 'image/png' });
     saveAs(blob, "GrowthOfUnit.png"); 
  }

  chartSetup(indexMarkers:any) {  
        this.GOILoader =true;   
        this.timePeriod = this.dateService.getTimePeriod();    
        this.valService.getGrowthofUnitByValues( this.fromDate,this.toDate,indexMarkers ,this.currencyCode,this.timePeriod,this.returnType).then(result => {                       
        this.growthofunitData = result;
        this.bindGrowthofUnit(this.growthofunitData);
        },onerror =>{
            this.GOILoader =false;         
        }); 
        // this.chartAttributes = {
        //     "theme": "fint",
        //     "exportShowMenuItem": "0",
        //     "exportenabled": "1",
        //     "exportatclient": "0",
        //     "paletteColors": this.colors ,
        //     "bgColor": "#ffffff",
        //     "bgAlpha": "0",
        //     "canvasBorderAlpha": "0",
        //     "canvasBgColor": "#ffffff",
        //     "showBorder": "0",
        //     "showCanvasBorder": "0",
        //     "divlineThickness": "1",
        //     "yAxisMinValue": "85",
        //     "anchoralpha": "100",
        //     "anchorgbalpha": "50",
        //     "linethickness": "1",
        //     "showvalues": "0",
        //     "formatnumberscale": "0",
        //     "anchorradius": "2",
        //     "divlinecolor": "#c0c0c0",
        //     "divlinealpha": "30",
        //     "divlineisdashed": "0",
        //     "bgcolor": "FFFFFF",
        //     "showalternatehgridcolor": "0",
        //     "labelpadding": "10",
        //     "canvasborderthickness": "1",
        //     "legendshadow": "0",
        //     "showborder": "0",
        //     "showLegend": "0",
        //     "xAxisLineColor": "#c0c0c0",
        //     "yAxisLineColor": "#c0c0c0",
        //     "labelDisplay": "rotate"
        // };    
    }

    bindGrowthofUnit(growthofunitData)
    {   
      if(growthofunitData.data==null){
        this.growthofUnitLanding.data.datasets[0].data = [];
        this.growthofUnitLanding.update();
      }else{
        // this.chartAttributes["yAxisMinValue"] = growthofunitData.data.yAxisMinValue;
        // this.chartAttributes["yAxisMaxValue"] = growthofunitData.data.yAxisMaxValue;
        // var indexPosition = [];
        // for(var counter = 0; counter< growthofunitData.data.growthOfUnitMultiDataset.length;counter++){
        //     indexPosition[ growthofunitData.data.growthOfUnitMultiDataset[counter].seriesName] = counter;
        // }
        // this.dataSource = {
        //     "chart": this.chartAttributes,
        //     "categories": growthofunitData.data.growthOfUnitcategories,
        //     "dataset": growthofunitData.data.growthOfUnitMultiDataset
        //   };
        for(var i=0;i<growthofunitData.data.dataSet.length;i++){
          const canvas = <HTMLCanvasElement> document.getElementById('growthofUnitLanding');
          const ctx = canvas.getContext('2d');
          var gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0,this.colors[i] );
          gradient.addColorStop(0.7, 'rgba(255, 255, 255, 0)');
          gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
          growthofunitData.data.dataSet[i].borderColor =this.colors[i];
          growthofunitData.data.dataSet[i].backgroundColor =gradient;
        }
        if (this.growthofUnitLanding) this.growthofUnitLanding.destroy();
        this.growthofUnitLanding = new Chart('growthofUnitLanding', {
            type: 'line',
            data: {
              labels: growthofunitData.data.xLabe1,
               showInLegend: true,
              datasets: growthofunitData.data.dataSet,
            },
            options: {
              maintainAspectRatio: false,
              layout: {
                padding: {
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0
                }
              },
              scales: {
                xAxes: [{
                  display:true,
                  time: {
                    unit: 'date'
                  },
                  gridLines: {
                    display: false,
                    drawBorder: false
                  },
                  ticks: {
                    fontColor: '#000000',
                    fontFamily: 'Roboto',
                    fontSize: 9,
                    maxRotation: 90,
                    minRotation: 90,
                  }
                }],
                yAxes: [{
                  ticks: {
                    min: growthofunitData.data.yAxisMinValue,
                    max : growthofunitData.data.yAxisMaxValue,
                    stepSize: 1,
                    padding: 5,
                    fontColor: '#000000',
                    fontFamily: 'Roboto',
                    fontSize: 9,
                    // Include a dollar sign in the ticks
                    callback: function (value, index, values) {
                      return value;
                    }
                  },
                  gridLines: {
                    color: "rgb(234, 236, 244)",
                    zeroLineColor: "rgb(193, 193, 193)",
                    drawBorder: false,
                    borderDash: [0],
                    zeroLineBorderDash: [0]
                  },
                  // ticks: {
                  //   fontColor: '#000000',
                  //   fontFamily: 'Roboto',
                  //   fontSize: 10
                  // }
                }],
              },
              legend: {
                display: true,
                lineWidth: 0,
                position: 'top',
                
                labels: {
                  fontColor: '#000000',
                    fontFamily: 'Roboto',
                    fontSize: 9,
                  filter: function (legendItem, data) {
                    return legendItem.index != 1
                  }
                }
              },
              tooltips: {
                backgroundColor: "rgb(255,255,255)",
                bodyFontColor: "#858796",
                titleMarginBottom: 10,
                titleFontColor: '#6e707e',
                titleFontSize: 11,
                borderColor: '#dddfeb',
                borderWidth: 1,
                xPadding: 15,
                yPadding: 15,
                displayColors: false,
                intersect: false,
                mode: 'index',
                caretPadding: 10,
                callbacks: {
                  label: function (tooltipItem, chart) {
                    var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                    return datasetLabel + ':' + tooltipItem.yLabel ;
                  }
                }
              }
            },
            plugins: [{
              afterDraw: function(chart) {
                if (chart.data.datasets[0].data.length == 0) {
                  // No data is present
                  var ctx = chart.chart.ctx;
                  var width = chart.chart.width;
                  var height = chart.chart.height
                  chart.clear();
                  ctx.save();
                  ctx.textAlign = 'center';
                  ctx.textBaseline = 'middle';
                  ctx.font = "bold 20px Roboto 'Helvetica Nueue'";
                  ctx.fillStyle = "black";
                   ctx.fillText('No data to display', width / 2, height / 2);
                   ctx.restore();
              }
              }
        }]
          });}
          this.GOILoader =false;
    }

    growthOfUnitCurrencyCodeChange(currencyCode:any){
        this.currencyCode = currencyCode;
        this.chartSetup(this.indexMarkers);
    }
   
    growthOfUnitReturnTypeChange(returnType:any){
      this.returnType = returnType;
      this.chartSetup(this.indexMarkers);
    }
}


@Component({
    selector: 'growth-of-unit',
templateUrl: './growth-of-unit.component.widget.dialog.html'
})
export class GrowthOfUnitComponentDialog{
  
  @Input() indexes: string;
  constructor(
  public dialogRef: MatDialogRef<GrowthOfUnitComponentDialog>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
    this.indexes="";
     data.forEach(element => {
         if( this.indexes.length<3)
         {
            this.indexes=element;
         }else{
        this.indexes += ", "+ element;
         }
     });   
   }

  onClose(): void {
      this.dialogRef.close();
}
}