import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import { JsonValue } from 'aws-sdk/clients/glue';
import { CompanyModel, APIFieldsModel, EntitlementModel, LicenceBandModel, UserProfileModel, APIKeyModel, DataModel, UserClientDetails, ReportTypeModel } from '../models/entitlement.model';
import { HttpParams } from '@angular/common/http';
import { ResponseModel } from '../models/landing-page-model';
import { AnyARecord } from 'dns';
import { iBcpRequestModel } from '../models/bcprequest.model';
import { iResponseModel } from '../models/response.model';

@Injectable()
export class EntitlementService {
  constructor(
    private httpClient: HttpClient) {

  }

  getCompanies(): Promise<CompanyModel[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/GetCompanies', null, { headers: headers }).toPromise().
      then(response => {
        var result = response as ResponseModel;
        return result.data as CompanyModel;
      })
      .catch(this.handleError)
  }

  getAPIS(): Promise<any[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/GetAPIS',
      null, { headers: headers }).toPromise().
      then(response => {
        var result = response as ResponseModel;
        return result.data as any;
      })
      .catch(this.handleError)
  }
  getAPIKeys(): Promise<APIKeyModel[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/GetAPIKeys',
      null, { headers: headers }).toPromise().
      then(response => {
        var result = response as ResponseModel;
        return result.data as APIKeyModel;
      })
      .catch(this.handleError)
  }
  getLicenceBands(): Promise<LicenceBandModel[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/GetLicenceBands',
      null, { headers: headers }).toPromise().
      then(response => {
        var result = response as ResponseModel;
        return result.data as LicenceBandModel;
      })
      .catch(this.handleError)

  }

  getEntitement(companyId, apiName): Promise<EntitlementModel[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/GetEntitlement',
      JSON.stringify({ companyId: companyId, apiName: apiName }),
      {
        headers: headers
      }).toPromise().
      then(response => {
        var result = response as ResponseModel;
        return result.data as EntitlementModel;
      })
      .catch(this.handleError)
  }

  getCompanyAPIS(): Promise<EntitlementModel[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/GetCompanyAPIS', null, { headers: headers }).toPromise().
      then(response => {
        var result = response as ResponseModel;
        return result.data as EntitlementModel;
      })
      .catch(this.handleError)
  }

  getAPIFields(apiName): Promise<APIFieldsModel[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/GetAPIFields',
      JSON.stringify({ apiName: apiName }),
      { headers: headers },

    ).toPromise().
      then(response => {
        var result = response as ResponseModel;
        return result.data as APIFieldsModel;
      })
      .catch(this.handleError)
  }



  saveCompany(company): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/saveCompany',
      JSON.stringify(company),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as Array<any>;
      }).catch(this.handleError);
  }

  saveAPIKeys(apikeys): Promise<any> {
    var inputData = JSON.stringify(apikeys);
    console.log(inputData);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/CreateUserProfileApiKey',
      inputData,
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as Array<any>;
      }).catch(this.handleError);
  }


  resendEmail(apikeys): Promise<any> {
    var inputData = JSON.stringify(apikeys);
    console.log(inputData);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/ResendConfirmationEmail',
      inputData,
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        return result;
      }).catch(this.handleError);
  }

  getUsers(): Promise<UserProfileModel[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/GetUsers', null, { headers: headers })
      .toPromise().
      then(response => {
        var result = response as ResponseModel;
        return result.data as UserProfileModel;
      })
      .catch(this.handleError)
  }

  getUserClientDetails(): Promise<UserClientDetails[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/datadelivery/GetUserClientDetails', null, { headers: headers })
      .toPromise().
      then(response => {
        var result = response as ResponseModel;
        return result.data as UserProfileModel;
      })
      .catch(this.handleError)
  }
  getPossibleTDates(calculationDate): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/datadelivery/getPossibleTDates', JSON.stringify({
      CalculationDate: calculationDate
    }), { headers: headers })
      .toPromise().
      then(response => {
        var result = response as ResponseModel;
        return result.data as Array<any>;
      })
      .catch(this.handleError)
  }

  saveUser(user): Promise<any> {
    var data = JSON.stringify(user);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/saveUser',
      data,
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as Array<any>;
      }).catch(this.handleError);
  }

  deleteEntitlement(entitlementRequest): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/DeleteEntitlement',
      JSON.stringify(entitlementRequest),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as Array<any>;
      }).catch(this.handleError);
  }

  saveEntitlement(entitlement): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/SaveEntitlement',
      JSON.stringify(entitlement),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as Array<any>;
      }).catch(this.handleError);
  }
  saveAlternateData(data): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/datadelivery/DataDelivery',
      JSON.stringify(data),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as any;
        console.log(response);
        console.log(result)
        return result;


      }).catch(this.handleError);
  }

  getIndexOfInterest(companyName: any, targetDate: any): Promise<any[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/GetIndexOfInterest',
      JSON.stringify({
        targetDate: targetDate,
        CompanyName: companyName
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as any[];
      }).catch(this.handleError);
  }

  saveIndexOfInterest(companyName: any, companyId: any, indexChoices: any): Promise<any[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/SaveIndexOfInterst',
      JSON.stringify({
        IndexChoices: indexChoices,
        CompanyName: companyName,
        CompanyId: companyId
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as any;
      }).catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  getUser(endPointURL): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(endPointURL, null, { headers: headers })
      .toPromise()
      .then(response => {
        console.log(response)
        return response as JSON;
      })
      .catch(this.handleError);
  }

  deleteAPIKey(apiKeyInfo: APIKeyModel): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/DeleteAPIKey',
      JSON.stringify(apiKeyInfo),
      { headers: headers }).toPromise()
      .then(response => {
        return response;
      }).catch(this.handleError);

  }

  deleteCompany(comp: CompanyModel): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/DeleteCompany',
      JSON.stringify(comp),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        return result;
      }).catch(this.handleError);
  }

  getReportType(): Promise<ReportTypeModel[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'entitlementApi/EntitlementAdmin/GetReportType',
      null, { headers: headers }).toPromise().
      then(response => {
        var result = response as ResponseModel;
        return result.data as ReportTypeModel;
      })
      .catch(this.handleError)

  }

  getBcpRequests(): Promise<iBcpRequestModel[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/datadelivery/GetRequests',
      null, { headers: headers }).toPromise().
      then(response => {
        var result = response as ResponseModel;
        console.log(response);
        return result.data as iBcpRequestModel[];
      })
      .catch(this.handleError)
  }

  async VerifyBcpRequestStatus(id: number, s3Path): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/datadelivery/UpdateRequestStatus',
    JSON.stringify({ requestId : id, s3Path: s3Path}), { headers: headers }).toPromise().
    then(response => {
      var result = response as iResponseModel;
      return result.data;
    })
    .catch(this.handleError)
  }



}
