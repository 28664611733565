import { Component, OnInit } from '@angular/core';
import { ReviewsService } from '../../services/reviews.service';
import { ReviewSchedule } from '../../models/review-schedule.model';
import { PagerService } from '../../services/pager.service';
import {FilterReviewSchedulePipe} from '../../filter.pipe';
import { stringify } from '@angular/compiler/src/util';
import {ReviewScheduleDropdowns} from '../../models/review-schedule-dropdowns.model';

@Component({
  selector: 'app-review-schedule',
  templateUrl: './review-schedule.component.html',
  styleUrls: ['./review-schedule.component.css'],
  })
export class ReviewScheduleComponent implements OnInit {

  didFail: any;
  today = new Date(Date.now());
  frequencyDate ='';
  reviewSchedules: ReviewSchedule[] =[];
  reviewSchedulesFail = false;
  indexFilter: any;
  indexSeriesFilter: any;
  universeBenchmarkFilter: any;
  cutOffDateFilter: any;
  CutOffCalendarDateFilter: any;
  announceDateFilter: any;
  announceCalenderDateFilter: any;
  techNoticeDeliveryDateFilter: any;
  techNoticeDeliveryCalenderDateFilter: any;
  filterReviewSchedules : any;

  pager: any = {};
  pagedItems: ReviewSchedule[];
  constructor(private reviewsService: ReviewsService, private pagerService: PagerService) {  }

  reviewResult : ReviewSchedule[] =[];
  reviewScheduleResult: ReviewScheduleDropdowns; 
  reviewSchedulesType: ReviewScheduleDropdowns;  
  typeArray = [];
  regionArray = [];
  frequencyArray = [];
  ownerArray = [];
  globalImpSheetArray = [];
  mockReviewArray = [];
  calculate(date: any)
  {
    this.reviewsService.calculateDate(date).then(function (result) {            
      alert(result.substring(0,10));
    });  
     
  }

  ngOnInit() {
    this.today.setDate(this.today.getDate());
    this.frequencyDate= this.today.toISOString().split('T')[0];
    var t = this;
    this.reviewsService.getReviewSchedule().then(function (result) { 

      for (let item of result) {       
                 
        t.reviewSchedules.push(item);        
      }     
      t.reviewResult = t.reviewSchedules;
      t.setPage(1);  
    });
  
    var t = this;
    this.reviewsService.getReviewScheduleDropdown().then(function (result2) { 
      t.typeArray = result2.reviewTypes;
      t.regionArray = result2.reviewRegion;
      t.frequencyArray = result2.reviewFrequency;
      t.ownerArray = result2.reviewCurrentOwner;      
      t.mockReviewArray = result2.reviewMockReviewReviewOwner;     
      for (let reviewGlobalImp of result2.reviewOnTheGlobalImpSheet) {
           if (reviewGlobalImp!= null && reviewGlobalImp!="") {           
                      t.globalImpSheetArray.push(reviewGlobalImp);
                   }  
        }  
        t.setPage(1);  
    });
  }

  setPage(page: number) {    
    this.pager = this.pagerService.getPager( this.reviewResult.length, page);    
    this.pagedItems =  this.reviewResult.slice(this.pager.startIndex, this.pager.endIndex + 1);    
  }

  onindexFilterKey(event: any) { 
    var returnTypeFilterPipe = new FilterReviewSchedulePipe();
    this.reviewResult= returnTypeFilterPipe.transform(this.reviewSchedules,event.target.value, this.indexSeriesFilter, this.universeBenchmarkFilter, this.cutOffDateFilter, this.CutOffCalendarDateFilter, this.announceDateFilter, this.announceCalenderDateFilter, this.techNoticeDeliveryDateFilter, this.techNoticeDeliveryCalenderDateFilter);
    this.pagedItems =   this.reviewResult;
    this.setPage(1);
  }

  onindexSeriesFilterKey(event: any) { 
    var returnTypeFilterPipe = new FilterReviewSchedulePipe();
    this.reviewResult= returnTypeFilterPipe.transform(this.reviewSchedules,this.indexFilter, event.target.value, this.universeBenchmarkFilter, this.cutOffDateFilter, this.CutOffCalendarDateFilter, this.announceDateFilter, this.announceCalenderDateFilter, this.techNoticeDeliveryDateFilter, this.techNoticeDeliveryCalenderDateFilter);
    this.pagedItems =   this.reviewResult;
    this.setPage(1);
  }
  onuniverseBenchmarkFilterKey(event: any) { 
    var returnTypeFilterPipe = new FilterReviewSchedulePipe();
    this.reviewResult= returnTypeFilterPipe.transform(this.reviewSchedules,this.indexFilter, this.indexSeriesFilter, event.target.value, this.cutOffDateFilter, this.CutOffCalendarDateFilter, this.announceDateFilter, this.announceCalenderDateFilter, this.techNoticeDeliveryDateFilter, this.techNoticeDeliveryCalenderDateFilter);
    this.pagedItems =   this.reviewResult;
    this.setPage(1);
  }

  oncutOffDateFilterKey(event: any) { 
    var returnTypeFilterPipe = new FilterReviewSchedulePipe();
    this.reviewResult= returnTypeFilterPipe.transform(this.reviewSchedules,this.indexFilter, this.indexSeriesFilter, this.universeBenchmarkFilter, event.target.value, this.CutOffCalendarDateFilter, this.announceDateFilter, this.announceCalenderDateFilter, this.techNoticeDeliveryDateFilter, this.techNoticeDeliveryCalenderDateFilter);
    this.pagedItems =   this.reviewResult;
    this.setPage(1);
  }
  onCutOffCalendarDateKey(event: any) { 
    var returnTypeFilterPipe = new FilterReviewSchedulePipe();
    this.reviewResult= returnTypeFilterPipe.transform(this.reviewSchedules,this.indexFilter, this.indexSeriesFilter, this.universeBenchmarkFilter, this.cutOffDateFilter, event.target.value, this.announceDateFilter, this.announceCalenderDateFilter, this.techNoticeDeliveryDateFilter, this.techNoticeDeliveryCalenderDateFilter);
    this.pagedItems =   this.reviewResult;
    this.setPage(1);
  }

  onannounceDateKey(event: any) { 
    var returnTypeFilterPipe = new FilterReviewSchedulePipe();
    this.reviewResult= returnTypeFilterPipe.transform(this.reviewSchedules,this.indexFilter, this.indexSeriesFilter, this.universeBenchmarkFilter, this.cutOffDateFilter, this.CutOffCalendarDateFilter,event.target.value, this.announceCalenderDateFilter, this.techNoticeDeliveryDateFilter, this.techNoticeDeliveryCalenderDateFilter);
    this.pagedItems =   this.reviewResult;
    this.setPage(1);
  }

  ontechNoticeKey(event: any) { 
    var returnTypeFilterPipe = new FilterReviewSchedulePipe();
    this.reviewResult= returnTypeFilterPipe.transform(this.reviewSchedules,this.indexFilter, this.indexSeriesFilter, this.universeBenchmarkFilter, this.cutOffDateFilter, this.CutOffCalendarDateFilter, this.announceDateFilter, this.announceCalenderDateFilter, event.target.value, this.techNoticeDeliveryCalenderDateFilter);
    this.pagedItems =   this.reviewResult;
    this.setPage(1);
  }

  onannounceCalenderKey(event: any) { 
    var returnTypeFilterPipe = new FilterReviewSchedulePipe();
    this.reviewResult= returnTypeFilterPipe.transform(this.reviewSchedules,this.indexFilter, this.indexSeriesFilter, this.universeBenchmarkFilter, this.cutOffDateFilter, this.CutOffCalendarDateFilter, this.announceDateFilter, this.announceCalenderDateFilter, this.techNoticeDeliveryDateFilter, event.target.value);
    this.pagedItems =   this.reviewResult;
    this.setPage(1);
  }

  ontechNoticeDeliveryKey(event: any) { 
    var returnTypeFilterPipe = new FilterReviewSchedulePipe();
    this.reviewResult= returnTypeFilterPipe.transform(this.reviewSchedules,this.indexFilter,this.indexSeriesFilter, this.universeBenchmarkFilter, this.cutOffDateFilter, this.CutOffCalendarDateFilter, this.announceDateFilter, this.announceCalenderDateFilter, event.target.value, this.techNoticeDeliveryCalenderDateFilter);
    this.pagedItems =   this.reviewResult;
    this.setPage(1);
  }
}
