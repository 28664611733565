import { Component, OnInit,Input,Inject,ViewChild,NgZone,AfterViewInit} from '@angular/core';
import { OpenHoldingService } from '../../services/open-holdings.service';
import {OpenHoldingPageModel} from '../../models/open-holding-page-model';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {MagellanColorService} from '../../services/magellancolor.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { BiService } from '../../services/BiService.service';
import{CurrencyService}from '../../services/currency.service';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import {ChartTypeComponent} from '../chart-type/chart-type.component';

@Component({
    selector: 'open-currency-weights-chart',
    templateUrl: './open-holdings-currency-weights-chart.component.html'
})
export class OpenCurrencyWeightsChartComponent implements OnInit, AfterViewInit {
    @ViewChild(ChartTypeComponent,{static: false}) chartTypeComponent;
    currencyWeightChartID: string;
    defaultActiveChart: string  = "pie2d";
    datasetType = "simple";
    chartAttributes: any;
    chartCategories: any;
    chartDataset: any;
    dataSource: any;
    CurrencyWeightChartLoader:boolean =true;
    
    constructor(private openHoldingService: OpenHoldingService,private indexMarkerService:IndexMarkerService, private exportService: ExportToExcelService,
        private publishIndexMarkerService:PublishIndexMarkerService, private magellanColorService : MagellanColorService
    ,private dialog: MatDialog,private biService: BiService,private zone:NgZone,private currencyService:CurrencyService,
    private idwChartExportService: IDWChartExportService) { }
    currentIndex:any;
    toolbarTitle:any;
    toollbarTitle:any;    
    popupDataSource:any;
    selectedIndex: any;
    @Input() asofDates : any;
    indexMarkers : any;  
    currencyCode : string;
    colors : any;
    chart:any;

    ngOnInit(){    
        this.indexMarkers = this.indexMarkerService.getIndexMarkers();
        this.currencyCode = this.currencyService.getCurrencyCode();
        this.currentIndex = 0;
        this.toollbarTitle = "Currency Weights";

        

        this.colors = this.magellanColorService.getCurrentColorSchema();
        this.magellanColorService.colorsPublish().subscribe(color => {
            this.colors = color;
            this.chartAttributes.paletteColors = this.colors;
            this.popupDataSource = {
                "title": this.toollbarTitle,
                "type":this.defaultActiveChart,
                "dataSource": this.dataSource,
                "legend": this.indexMarkerService.getIndexMarkersNames(),
                "colors": this.colors,
                "chartType" : this.defaultActiveChart          
            }
        });
        this.chartAttributes = {
            "exportEnabled": "1",
            "exportShowMenuItem": "0",
            "paletteColors": this.colors,
            "bgColor": "#ffffff",
            "bgAlpha": "0",
            "showBorder": "0",
            "showCanvasBorder": "0",
            "showLabels": '0',
            "showValues": "0",
            "plotBorderAlpha": "10",
            "enableSmartLabels": "0",
            "showLegend": "1",
            "legendPosition": "right",
            "legendBorderColor": "#ffffff",
            "legendBorderThickness": "0",
            "legendBorderAlpha": "0",
            "legendShadow": "0",
            "usePlotGradientColor": "0",
            "canvasBgAlpha": "0",
            "canvasBorderColor": "#ffffff",
            "canvasBorderThickness": "0",
            "canvasBorderAlpha": "0",
            "alignCaptionWithCanvas": "0",
            "showShadow": "0",
            "chartLeftMargin": "0",
            "chartRightMargin": "0",
            "chartTopMargin": "-15",
            "use3DLighting": "0",
            "axisLineAlpha": "25",
            "divLineAlpha": "10",
            "showAlternateVGridColor": "0",
            "showAlternateHGridColor": "0",
            "captionFontSize": "14",
            "subcaptionFontSize": "14",
            "subcaptionFontBold": "0",
            "toolTipColor": "#ffffff",
            "toolTipBorderThickness": "0",
            "toolTipBgColor": "#000000",
            "toolTipBgAlpha": "80",
            "toolTipBorderRadius": "2",
            "toolTipPadding": "5",
        }
        
    }
    ngAfterViewInit(){
        this.chartTypeComponent.changedChartType(this.defaultActiveChart);
        this.publishIndexMarkerService.indexMarkerPublish().subscribe(result => {
            this.indexMarkers = result;
            this.CurrencyWeightChartLoader = true;
            this.chartSetup();
        });
    }
    chartInitialize(event) {
        return (eve, arg) => {
            this.zone.run(() => {
                this.chart = eve.sender;
            });

        };

    }

    events = {
        initialized: this.chartInitialize(event)
    }

    carouselPrevious(): void {
        var event: any = { eventName: "Open Holdings Carousel Previous click on Currency Weight chart", inputParameters: [{ Name: "Previous IndexMarker", Value: this.indexMarkers[this.currentIndex].toString() }] };
        this.biService.addEvent(event);
        this.CurrencyWeightChartLoader = true;
        if (0 == this.currentIndex) {
            this.currentIndex = this.indexMarkers.length - 1;
        } else {
            this.currentIndex = this.currentIndex - 1;
        }
        this.colors = this.magellanColorService.getCurrentColorSchema();
        this.chartSetup();
    }

    carouselNext(): void {
        var event: any = { eventName: "Open Holdings Carousel Next click on Currency Weight chart", inputParameters: [{ Name: "Next IndexMarker", Value: this.indexMarkers[this.currentIndex].toString() }] };
        this.biService.addEvent(event);
        this.CurrencyWeightChartLoader = true;
        if ((this.indexMarkers.length - 1) == this.currentIndex) {
            this.currentIndex = 0;
        } else {
            this.currentIndex = this.currentIndex + 1;
        }
        this.colors = this.magellanColorService.getCurrentColorSchema();
        this.chartSetup();
    }

    update(openHoldingPageModel: OpenHoldingPageModel) {
        this.CurrencyWeightChartLoader = true;
        this.asofDates = openHoldingPageModel.asofdate;
        this.currencyCode=openHoldingPageModel.currencyCode;
        this.chartSetup();
    }

    chartSetup() {
        if(this.indexMarkers[this.currentIndex]==undefined)
        {
             this.currentIndex=0;
        }
        var oneIndexMarkers: string[] = [];
        oneIndexMarkers.push(this.indexMarkers[this.currentIndex]);
        this.selectedIndex = this.indexMarkers[this.currentIndex];
        this.toollbarTitle = "Currency Weights";
         this.openHoldingService.getCurrencyWeights(this.asofDates,oneIndexMarkers, this.currencyCode).then(data => {
            if(null!= data){
            if (0 < data.dataset[0].data.length) {
                 this.dataSource = {
                     "categories": data.categories,
                     "data": data.dataset[0].data,
                     "chart": this.chartAttributes,
                     "chartType"    : this.defaultActiveChart
                 };
                 this.popupDataSource = {
                    "title": this.toollbarTitle + this.indexMarkers[this.currentIndex],
                    "asOfDate": this.asofDates,
                    "type":this.defaultActiveChart,
                    "dataSource": this.dataSource,
                    "legend": this.indexMarkerService.getIndexMarkersNames(),
                    "colors": this.colors.split(','),
                    "chartType"    : this.defaultActiveChart 
                };
             }
             else {
                 this.dataSource = {};
             }

             
            }
            else{            
                this.dataSource = {};
               }
             this.CurrencyWeightChartLoader = false;
         }, onerror => {
             this.CurrencyWeightChartLoader = false;
         });

     }
     onChartTypeChange(chart: string) {                
         this.defaultActiveChart = chart;
         if (chart == 'column2d' || chart == 'bar2d' || chart == 'line') {
             this.chartAttributes.chartLeftMargin = "0";
             this.chartAttributes.chartRightMargin = "0";
             this.chartAttributes.chartTopMargin = "5";
             this.chartAttributes.use3DLighting = "0";
            //this.chartAttributes.showLabels = 1;

         }
         else {
             this.chartAttributes.showLabels = 0;
            this.chartAttributes.chartLeftMargin = "0";
             //this.chartAttributes.chartRightMargin = "40";
            this.chartAttributes.chartTopMargin = "-15";
             this.chartAttributes.use3DLighting = "0";

        }
         this.chartSetup();
    }
    
    ExportXls(event): void {
        var event: any = { eventName: "Open Holdings Export Currency Weight Chart", inputParameters: [{ Name: "Exported IndexMarker", Value: this.indexMarkers[this.currentIndex].toString() }] };
        this.biService.addEvent(event);

        var inputData = {
            "exportType": "xls",
            "code": "openHoldings-getOpenHoldingsCurrencyWeightData",
            "xlsFileName": "OpenCurrencyWeightsRawData",
            "asOfDate": this.asofDates,
            "indexMarkers": [this.indexMarkers[this.currentIndex]]
          };
          this.idwChartExportService.export(inputData);

    }

    ExportPNG(event): void {
        var event: any = { eventName: "Open Holdings Export Currency Weight Chart TO PNG", inputParameters: [{ Name: "Exported IndexMarker", Value: this.indexMarkers[this.currentIndex].toString() }] };
        this.biService.addEvent(event);
        this.popupDataSource.dataSource.chart.showlegend = "1"; 
        var inputData = {
            "exportType": "png",
            "legend": false,
            "dataSource": this.popupDataSource.dataSource,
            "chartid" : "openHoldingsCurrencyWeightChartExport",
            "type" : this.defaultActiveChart,
            "targetDiv" : "exportPngDiv",
            "chartReference": this.chart,
            "isIndexLegend": false,
            "pngFileName" : "OpenCurrencyWeightsRawData",
            "chartTitle": this.toollbarTitle +this.indexMarkers[this.currentIndex],
            "chartDate": this.asofDates
         };
         this.idwChartExportService.export(inputData);
      }


    openWidgetDialog(): void {
        var event: any = { eventName: "Currency weight help dialog open" };
        this.biService.addEvent(event);
        let dialogRef = this.dialog.open(OpenCurrencyWeightsChartComponentWidgetDialog, {
            width: '800px',
            data: this.indexMarkers
        });
    }

    openDialog(): void {
        var event: any = { eventName: "User clicked on popup dialog of Holdings Currency Weight Chart" };
        this.biService.addEvent(event);
        this.popupDataSource.dataSource.chart.showlegend = "1"; 
        let dialogRef = this.dialog.open(OpenCurrencyWeightsChartComponentDialog, {
            width: '75%',
            height: '75%',
            data: this.popupDataSource
        });
    }
}

@Component({
    selector: 'open-currency-weights-chart-dialog',
    templateUrl: './open-holdings-currency-weights-chart.component.dialog.html'
})
export class OpenCurrencyWeightsChartComponentDialog {

    constructor(
        public dialogRef: MatDialogRef<OpenCurrencyWeightsChartComponentDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    onClose(): void {
        this.dialogRef.close();
    }
}



@Component({
    templateUrl: './open-holdings-currency-weights-chart.component.widget.dialog.html'
})
export class OpenCurrencyWeightsChartComponentWidgetDialog  {

    @Input() indexes: string;
    constructor(
        public dialogRef: MatDialogRef<OpenCurrencyWeightsChartComponentWidgetDialog >,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.indexes = "";
        data.forEach(element => {
            if (this.indexes.length < 3) {
                this.indexes = element;
            } else {
                this.indexes += ", " + element;
            }
        });
    }

    onClose(): void {
        this.dialogRef.close();
    }
}

