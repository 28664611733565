import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewSovereignConfigurationForm} from '../../models/sovereignconfiguration';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report } from '../../models/report';
import { formatDate, DatePipe } from '@angular/common';
import { SovereignConfigurationDataService } from '../../services/sovereignconfiguration.service';
import { debug } from 'console';

@Component({
  selector: 'app-new-sovereignconfigurationdata-popup',
  templateUrl: './new-sovereignconfigurationdata-popup.component.html',
  styleUrls: ['./new-sovereignconfigurationdata-popup.component.scss']
})
export class NewSovereignconfigurationPopupComponent implements OnInit {

 form: FormGroup = this.fb.group({
    indexCode: ['', Validators.required],
   parentIndexCode: ['', Validators.required],
    currency: ['']
 } as Record<keyof NewSovereignConfigurationForm, any[]>);

  updateReport: boolean = false;
  isreportForm = false;
  currencies: string[] ;
  public currenciesfilteredList : string[];
  constructor(private fb: FormBuilder, private sovereignConfigurationDataService: SovereignConfigurationDataService, public dialogRef: MatDialogRef<NewSovereignconfigurationPopupComponent>, @Inject(MAT_DIALOG_DATA) public rowdata: any) {

    console.log(rowdata);

  }

  ngOnInit(): void {
    if (this.rowdata) {
      this.updateReport = true;
      this.form.patchValue({
        indexCode: this.rowdata.IndexCode,
        parentIndexCode: this.rowdata.ParentIndexCode,
        currency: this.rowdata.Currency
      })   
    }
    this.sovereignConfigurationDataService.getCurrencies().subscribe(data =>{
      this.currencies =data.data;
      this.currenciesfilteredList = data.data;
    }) ;
  }

  public onClickHandler(event: any): void {
 
  }

  onSubmit(): void {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      this.dialogRef.close(data);
    }
    if (this.form.invalid) {
      this.isreportForm = true;
      return;
    }
  } 

  }
