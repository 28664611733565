import { Component, OnInit,Inject,NgZone, ViewChild, AfterViewInit } from '@angular/core';
import { PerformanceService } from '../../services/performance.service';
import {PerformancePageModel} from '../../models/performance-page-model';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {MagellanColorService} from '../../services/magellancolor.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DateService } from '../../services/date.service';
import { IndexReturns } from '../../models/performance-index-returns';
import { ChartModel } from '../../models/performance-chart-model';
import { BiService } from '../../services/BiService.service';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import {ChartTypeComponent} from '../chart-type/chart-type.component';

@Component({
  selector: 'performance-calendar-returns-chart',
  templateUrl: './performance-calendar-return-chart.component.html',
})
  
export class PerformanceCalendarReturnsComponent implements OnInit,AfterViewInit {           
  calendarReturnChartdidFail: boolean=false;
  chartDatadidFail: boolean=false;
    calendarReturnsDataset: any;  
    CalendarReturnsChartLoader:boolean=true;
    constructor(private dateService: DateService,private performanceService: PerformanceService, private exportService: ExportToExcelService,private publishIndexMarkerService:PublishIndexMarkerService,private indexMarkerService:IndexMarkerService,public dialog: MatDialog,
      private magellanColorService : MagellanColorService,private biService: BiService,private zone:NgZone,
      private idwChartExportService: IDWChartExportService) { }

    asofDates : any;
    indexMarkers : any;
    timePeriod : any;
    currencyCode : string;
    returnType : any;
    colors : any;
    chartAttributes : any;
    popupDataSource : any;
    chart:any;
    @ViewChild(ChartTypeComponent,{static: false}) chartTypeComponent;   
    defaultActiveChart: string  = "msline";
    datasetType = "multiple";
    iscarousel: boolean= false;

    openDialog(): void{ 
     var event: any = {eventName: "Performance Calendar Returns dialog open"};
     this.biService.addEvent(event);  
     this.popupDataSource.dataSource.chart.showlegend = "1";        
     let dialogRef = this.dialog.open(PerformanceCalendarReturnsComponentDialog, {
      width: '80%',
      height: '90%',
      data: this.popupDataSource
      });
    }

    openWidgetDialog(): void{ 
      var event: any = {eventName: "Performance Calendar Returns help dialog open"};
      this.biService.addEvent(event);          
      let dialogRef = this.dialog.open(PerformanceCalendarReturnsComponentWidgetDialog, {
         width: '800px',
         data: this.calendarReturnsDataset
       });
     }
     chartInitialize(event){
      return (eve, arg) => { 
          this.zone.run(() => {
              this.chart =eve.sender;
          });  
          
      };
        
    }
  
    events = {
      initialized: this.chartInitialize(event)
  }
  
    ngOnInit(){    

    this.indexMarkers = this.indexMarkerService.getIndexMarkers();
    this.colors = this.magellanColorService.getCurrentColorSchema();
    this.magellanColorService.colorsPublish().subscribe(color => { 
      this.colors= color;
      this.chartAttributes.paletteColors = this.colors;  
      this.popupDataSource={     
        "dataSource": this.calendarReturnsDataset,
        "asOfDate": this.asofDates,       
        "colors": this.colors ,
        "chartType"    : this.defaultActiveChart      
      }
  });
  this.asofDates= this.dateService.getTransactionDate();
  
  }
  
  ngAfterViewInit(){
    this.chartTypeComponent.defaultChartType=this.defaultActiveChart;
    this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{
      this.CalendarReturnsChartLoader=true; 
      this.indexMarkers=result;
        this.chartSetup(result);
    });
  }
    update(performancePageModel:PerformancePageModel){
    this.returnType = performancePageModel.returntype;
    this.currencyCode = performancePageModel.currencyCode;
    this.asofDates = performancePageModel.selectedDates.end;
    this.chartSetup(this.indexMarkers);
  }
  
  onChartTypeChange(chart: string) {                
    this.defaultActiveChart = chart;       
    this.chartSetup(this.indexMarkers);
}  

  private chartSetup(indexMarkers:any) {    
    this.performanceService.calendarReturnsChartLoaded.observers=[];
    this.performanceService.getCalendarReturnsChart(this.asofDates,indexMarkers   ,this.currencyCode ,this.returnType);
      this.performanceService.calendarReturnsChartLoaded.subscribe(
        (data:ChartModel) => {
          if(null != data){
            if(0< data.multiDataset.length){
              this.bindCalendarReturn(data);
            }else {
              this.calendarReturnsDataset = { };
              this.CalendarReturnsChartLoader =false;
            } 
          }else{
            this.calendarReturnsDataset = { };
            this.CalendarReturnsChartLoader =false;
          }
           
        });
      
       this.performanceService.calendarReturnsChartFailed.subscribe(
        (didFail: boolean) => this.calendarReturnChartdidFail = didFail
      ); 
          
      
  }

  bindCalendarReturn(data){
    this.chartAttributes = {
      "theme": "fint",
      "showvalues": "0",
      "exportenabled": "1",
      "exportatclient": "0",
      "exportShowMenuItem": "0",
      "paletteColors": this.colors,
      "bgColor": "#ffffff",
      "bgAlpha": "0",
      "canvasBorderAlpha": "0",
      "canvasBgColor": "#ffffff",
      "canvasBgAlpha": "0",
      "showBorder": "0",
      "showCanvasBorder": "0",
      "rotateValues": "0",       
      "alternateHGridColor": "#FFFFFF",
      "alternateHGridAlpha": "0",
      "showLegend": "0",
      "labelStep": "2",
      "rotateLabels": "0",
      "showAlternateVGridColor": "0",
      "labelDisplay": "None"
    } 
    this.calendarReturnsDataset = {                   
      "dataset": data.multiDataset,
      "categories":data.categories,
      "chart": this.chartAttributes
                   
    }  
  this.CalendarReturnsChartLoader=false; 

  this.popupDataSource={     
    "dataSource": this.calendarReturnsDataset,
    "asOfDate": this.asofDates,    
    "colors":this.colors.split(','),
    "chartType" : this.defaultActiveChart          
  }

  }

  ExportXls(event):void{
    var event: any = {eventName: "Export Peformance Calendar Returns", inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode},{Name : "ReturnType" , Value: this.returnType}]};
    this.biService.addEvent(event);

      var inputData = {
        "exportType": "xls",
        "code": "performance-getCalendarReturnsChartData",
        "xlsFileName": "PerformanceCalendarReturnsRawData",
        "asOfDate": this.asofDates,
        "indexMarkers": this.indexMarkers,
        "timePeriod": this.timePeriod,
        "currencyCode": this.currencyCode,
        "returnType": this.returnType
      };
      this.idwChartExportService.export(inputData);
     
 
  }

  ExportPNG(event):void{
    var event: any = {eventName: "Export Peformance Calendar Returns Chart TO PNG",   inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode},{Name : "ReturnType" , Value: this.returnType}]};
    this.biService.addEvent(event);
    this.popupDataSource.dataSource.chart.showlegend = "1";
    var legendClassNames = ["legend","legendcolor","legendlabel"];
    var inputData = {
      "exportType": "png",
      "legend": false,
      "dataSource": this.popupDataSource.dataSource,
      "chartid" : "performanceCalendarReturnsExport",
      "type" : this.defaultActiveChart,
      "targetDiv" : "exportPngDiv",
      "chartReference": this.chart,
      "pngFileName" : "PerformanceCalendarReturnsRawData",
      "chartTitle": "Calendar Returns",
      "chartDate": this.asofDates,
      "isIndexLegend": false,
      "indexLegendNames": this.popupDataSource.legend,
      "indexLegendColors": this.popupDataSource.colors,
      "indexLegendClassNames" : legendClassNames
   };
   this.idwChartExportService.export(inputData);
   this.popupDataSource.dataSource.chart.showlegend = "0"; 
  }

}

@Component({
      selector: 'performance-calendar-returns-chart-dialog',
  templateUrl: './performance-calendar-return-chart.component.dialog.html'
})
export class PerformanceCalendarReturnsComponentDialog{

    constructor(
    public dialogRef: MatDialogRef<PerformanceCalendarReturnsComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    onClose(): void {
      this.data.dataSource.chart.showlegend = "0";  
        this.dialogRef.close();
  }
}



@Component({
  selector: 'performance-calendar-returns-chart-dialog',
templateUrl: './performance-calendar-return-chart.component.widget.dialog.html'
})
export class PerformanceCalendarReturnsComponentWidgetDialog{

constructor(
public dialogRef: MatDialogRef<PerformanceCalendarReturnsComponentWidgetDialog>,
@Inject(MAT_DIALOG_DATA) public data: any) { }

onClose(): void {
    this.dialogRef.close();
}
}

