import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticateService } from '../../services/authenticate.service';
import { environment } from '../../../environments/environment';

export interface NavItem {
  menuName: string;
  iconName: string;
  route?: string;
  children?: NavItem[];
}
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  isClicked = true;
  linkName: string;
  iconName: string;
  userId: string;
  route?: string;
  children?: NavItem[];
  public isExpanded = true;
  @Input()
  data: any;
  topmenu: NavItem[] = [
   
    {
      menuName: 'Dashboards',
      iconName: '../../assets/icons/tachometer-alt.svg',
      route: '/'
    },
    {
      menuName: 'Holdings',
      iconName: '../../assets/icons/suitcase.svg',
      children: [
        {
          menuName: 'Close Holdings',
          iconName: '',
          route: '/closeHoldings'
        },
        {
          menuName: 'Open Holdings',
          iconName: '',
          route: '/openHoldings'
        }
      ],
      
      
    },
    {
      menuName: 'Performance',
      iconName: '../../assets/icons/chart-line.svg',
      route: 'performance'
    },
    {
      menuName: 'Values',
      iconName: '../../assets/icons/folder.svg',
      route: 'values'
    },
    {
      menuName: 'Sector',
      iconName: '../../assets/icons/poll.svg',
          route: 'weights'
    },
        {
          menuName: 'Reporting',
          iconName: '../../assets/icons/user-friends.svg',
          route: 'reporting'
        },
        {
          menuName: 'Reference',
          iconName: '../../assets/icons/sync-alt.svg',
          route: 'reference'
        },
        {
          menuName: 'Re Issue',
          iconName: '../../assets/icons/calendar-check.svg',
          route: 'ddstracker'
        },
        {
          menuName: 'Data Quality',
          iconName: '../../assets/icons/file-download.svg',
          children: [
            {
              menuName: 'Summary',
              iconName: '',
              route: '/summary-data-quality'
            },
            {
              menuName: 'Close Price',
              iconName: '',
              route: '/price-data-quality'
            },
            {
              menuName: 'Market Value',
              iconName: '',
              route: '/market-value-dataquality'
            },
            {
              menuName: 'Open Price',
              iconName: '',
              route: '/openprice-data-quality'
            },
            {
              menuName: 'Shares Outstandings',
              iconName: '',
              route: '/shares-data-quality'
            },
            {
              menuName: 'Country',
              iconName: '',
              route: '/country-data-quality'
            },
            {
              menuName: 'RGS Sector',
              iconName: '',
              route: '/rgs-sector-data-quality'
            },
            {
              menuName: 'ICB Sector',
              iconName: '',
              route: '/sector-data-quality'
            },
            {
              menuName: 'NICB Sector',
              iconName: '',
              route: '/nicb-sector-data-quality'
            }
          ],
        },

        
     
    {
      menuName: 'Tracking',
      iconName: '../../assets/icons/calendar-check.svg',
      children: [
        {
          menuName: 'File Status',
          iconName: '',
          route: 'fileloadstatus'
        }
      ]
    } 
  ];
  // bottommenu: NavItem[] = [
  //   {
  //     menuName: 'Admin',
  //     iconName: '../../assets/icons/client.svg',
  //     route: '',
  //   }
  // ];
  constructor(private elem: ElementRef, private router: Router, private authService: AuthenticateService) { 
    console.log("UserRoles" + sessionStorage.getItem("UserRoles")); 
    
    console.log('UserRoles Loggedin:' + sessionStorage.getItem("UserRoles") + ', ' + environment.role)
    if (sessionStorage.getItem("UserRoles") == environment.role
    || (sessionStorage.getItem("UserRoles") !== null && sessionStorage.getItem("UserRoles").split(',').indexOf(environment.adminGroup) !== -1)) {    
      this.topmenu.push(
        {
          menuName: 'Alternate Data Delivery',
          iconName: '../../assets/icons/calendar-check.svg',
          route: 'alternate-data-delivery'
        });
      this.topmenu.push(
        {
          menuName: 'DataRestore',
          iconName: '../../assets/icons/calendar-check.svg',
          route: 'data-restore'
        });

      this.topmenu.push(
        {
          menuName: 'Client',
          iconName: '../../assets/icons/calendar-check.svg',
          children: [
            {
              menuName: 'API Report',
              iconName: '',
              route: '/apireport'
            },
            {
              menuName: 'Waves',
              iconName: '',
              route: '/waves'
            },
            {
              menuName: 'KPIS',
              iconName: '',
              route: '/lam'
            },
            {
              menuName: 'API Validation Test',
              iconName: '',
              route: '/performance-indicators-report'
            },
            
          ],
        });

      this.topmenu.push(
      
        {
          menuName: 'Entitlement',
          iconName: '../../assets/icons/calendar-check.svg',
          children: [
            {
              menuName: 'Company',
              iconName: '',
              route: '/company-search'
            },
            {
              menuName: 'APIKeys',
              iconName: '',
              route: '/apikey-search'
            },
            {
              menuName: 'User Profile',
              iconName: '',
              route: '/user-search'
            },
            {
              menuName: 'APIs',
              iconName: '',
              route: '/company-api-search'
            },
            {
              menuName: 'Index Of Interest',
              iconName: '',
              route: '/index-of-interest'
            },
            {
              menuName: 'License Bands',
              iconName: '',
              route: '/licence-search-bands'
            },
            {
              menuName: 'Client Index Mapping',
              iconName: '',
              route: '/client-index-mapping'
            },
            {
              menuName: 'Index Master',
              iconName: '',
              route: '/index-master'
            }
          ],
        });

        this.topmenu.push(
          {
            menuName: 'Factors',
            iconName: '../../assets/icons/calendar-check.svg',
            route: 'factors'
          });

        this.topmenu.push(
          {
            menuName: 'Review Timetable',
            iconName: '../../assets/icons/calendar-check.svg',
            children: [
              {
                menuName: 'Alerts/Notifications',
                iconName: '',
                route: 'reviews'
              },
              {
                menuName: 'Review Schedule',
                iconName: '',
                route: 'reviewschedules'
              },
              {
                menuName: 'Review Products',
                iconName: '',
                route: 'reviewproducts'
              },
              {
                menuName: 'Delay-On Hold',
                iconName: '',
                route: ''
              }
            ]
          });

        this.topmenu.push({
          menuName: 'Autorization',
          iconName: '../../assets/icons/client.svg',
          children: [
            {
              menuName: 'User/Groups Setup',
              iconName: '',
              route: 'usergroup-search'
            },
            {
              menuName: 'Role Page Mapping',
              iconName: '',
              route: 'role-page-mapping-search'
            },
            {
              menuName: 'User Role Mapping',
              iconName: '',
              route: 'user-role-mapping'
            },
            {
              menuName: 'Menu Items',
              iconName: '',
              route: 'menu-items'
            }
          ]
        });
        
        this.topmenu.push(
          {
            menuName: 'Telemetry Logs',
            iconName: '../../assets/icons/client.svg',
            route: 'telemetry-logs',
          });
        this.topmenu.push(
          {
            menuName: 'Sedol Alert',
            iconName: '../../assets/icons/client.svg',
            route: 'sedol-search',
          });
   
        this.topmenu.push(
          {
            menuName: 'SI Reporting',
            iconName: '../../assets/icons/calendar-check.svg',
            children: [
              {
                menuName: 'SI Metric Indices',
                iconName: '',
                route: 'metadataconfig'
              },
              {
                menuName: 'Portfolios',
                iconName: '',
                route: 'pbr'
              },
              {
                menuName: 'Benchmarks',
                iconName: '',
                route: 'benchmarks'
              },
              {
                menuName: 'Portfolio Reports',
                iconName: '',
                route: 'reports'
              },
              {
                menuName: 'Climate Datapack Reports',
                iconName: '',
                route: 'climatereports'
              },
              {
                menuName: 'SI Metrics History Reports',
                iconName: '',
                route: 'historyreports'
              } ,
              
              {
                menuName: 'SI Tables Configuration',
                iconName: '',
                route: 'sitablesconfig'
              },
              {
                menuName: 'SI Data Calculation',
                iconName: '',
                route: 'sidatacalculation'
              }

            ],

          })
     
      } else if (sessionStorage.getItem("UserRoles").indexOf(environment.sedolOpsGroup) !== -1) {
        console.log("Else userroles");
        this.topmenu.push(
          {
            menuName: 'Entitlement',
            iconName: '../../assets/icons/calendar-check.svg',
            children: [
              {
                menuName: 'Company',
                iconName: '',
                route: '/company-search'
              },
              {
                menuName: 'APIKeys',
                iconName: '',
                route: '/apikey-search'
              },
              {
                menuName: 'User Profile',
                iconName: '',
                route: '/user-search'
              },
              {
                menuName: 'APIs',
                iconName: '',
                route: '/company-api-search'
              },
              {
                menuName: 'Index Of Interest',
                iconName: '',
                route: '/index-of-interest'
              }
            ],
          });

        this.topmenu.push(
          {
            menuName: 'Telemetry Logs',
            iconName: '../../assets/icons/client.svg',
            route: 'telemetry-logs',
          });

        this.topmenu.push(
          {
            menuName: 'Sedol Alert',
            iconName: '../../assets/icons/client.svg',
            route: 'sedol-search',
          });
      } else if (sessionStorage.getItem("UserRoles").indexOf(environment.siOpsGroup) !== -1) {
        console.log("Else userroles");
      
      //this.topmenu.push(
      //  {
      //    menuName: 'Portfolios',
      //    iconName: '../../assets/icons/calendar-check.svg',
      //    route: 'pbr'
      //  },
      //  {
      //    menuName: 'Benchmarks',
      //    iconName: '../../assets/icons/chart-line.svg',
      //    route: 'benchmarks'
      //  },
      //  {
      //    menuName: 'Portfolio Reports',
      //    iconName: '../../assets/icons/chart-line.svg',
      //    route: 'reports'
      //  },
      //  {
      //    menuName: 'Climate Datapack Reports',
      //    iconName: '../../assets/icons/chart-line.svg',
      //    route: 'climatereports'
      //  }
      //);
      
        this.topmenu.push(
          {
            menuName: 'SI Reporting',
            iconName: '../../assets/icons/calendar-check.svg',
            children: [
              {
                menuName: 'SI Metric Indices',
                iconName: '',
                route: 'metadataconfig'
              },
              {
                menuName: 'Portfolios',
                iconName: '',
                route: 'pbr'
              },
              {
                menuName: 'Benchmarks',
                iconName: '',
                route: 'benchmarks'
              },
              {
                menuName: 'Portfolio Reports',
                iconName: '',
                route: 'reports'
              },
              {
                menuName: 'Climate Datapack Reports',
                iconName: '',
                route: 'climatereports'
              },
              {
                menuName: 'SI Metrics History Reports',
                iconName: '',
                route: 'historyreports'
              },              
              {
                menuName: 'SI Tables Configuration',
                iconName: '',
                route: 'sitablesconfig'
              },
              {
                menuName: 'SI Data Calculation',
                iconName: '',
                route: 'sidatacalculation'
              }
              
            ]
          })
      
    } else if(sessionStorage.getItem("UserRoles").indexOf(environment.clientServiceOpsGroup) !== -1) {
      console.log("Else userroles");
      this.topmenu.push(
        {
          menuName: 'Alternate Data Delivery',
          iconName: '../../assets/icons/calendar-check.svg',
          route: 'alternate-data-delivery'
        });  

        this.topmenu.push(
      
          {
            menuName: 'Entitlement',
            iconName: '../../assets/icons/calendar-check.svg',
            children: [
              {
                menuName: 'Company',
                iconName: '',
                route: '/company-search'
              },
              {
                menuName: 'APIKeys',
                iconName: '',
                route: '/apikey-search'
              },
              {
                menuName: 'User Profile',
                iconName: '',
                route: '/user-search'
              },
              {
                menuName: 'APIs',
                iconName: '',
                route: '/company-api-search'
              },
              {
                menuName: 'Index Of Interest',
                iconName: '',
                route: '/index-of-interest'
              },
              {
                menuName: 'License Bands',
                iconName: '',
                route: '/licence-search-bands'
              },
              {
                menuName: 'Client Index Mapping',
                iconName: '',
                route: '/client-index-mapping'
              },
              {
                menuName: 'Index Master',
                iconName: '',
                route: '/index-master'
              }
            ],
          });
    }
  }

  ngOnInit() {
    if (this.authService.getParameterByName('code', '') === null && sessionStorage.length !== 0) {
      this.userId = sessionStorage.getItem('LoggedInUser');
  }
}

  ngAfterViewInit(): void {
    const element = this.elem.nativeElement.getElementsByClassName('mat-expansion-indicator');
    if (!this.isExpanded) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < element.length; i++) {
        element[i].style.display = 'none';
      }
    }
  }

  onSideNavToggle() {
    this.isExpanded = !this.isExpanded;

    const element = this.elem.nativeElement.getElementsByClassName('mat-expansion-indicator');
    if (!this.isExpanded) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < element.length; i++) {
        element[i].style.display = 'none';
      }
    } else {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < element.length; i++) {
        element[i].style.display = 'inline-block';
      }
    }
  }


}
