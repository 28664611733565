import { Component,OnInit,ViewChild,AfterViewInit,EventEmitter,Output, Inject, Input } from '@angular/core';
import {CustomTableComponent} from '../custom-table/custom-table.component';
import { LandingService } from '../../services/landing.service';
import {FilterPipe, FilterReturnTypePipe} from '../../filter.pipe';
import {ColumnChooserService} from '../../services/column-chooser.service';
import {CustomColumnsModel} from '../../models/custom-columns-model';
import { IndexReturns } from '../../models/performance-index-returns';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import * as moment from 'moment';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DateService} from '../../services/date.service';
import {DateDisplayService} from '../../services/date.display.service';
import{CurrencyService}from '../../services/currency.service';
import {ReportService} from '../../services/report.service';

@Component({
    selector: 'factors-performance-ratios-grid',
    templateUrl: './factors-performance-ratios-grid.component.html'
})
export class FactorsPerformanceRatiosGridComponent {
    constructor(private dateDisplayService: DateDisplayService,private landingService: LandingService,private dateService:DateService,private publishIndexMarkerService:PublishIndexMarkerService,
        private indexMarkerService:IndexMarkerService,private columnChooserService:ColumnChooserService,
        private exportService: ExportToExcelService,public dialog: MatDialog,private currencyService:CurrencyService,
        private reportService: ReportService) { }
        
    @ViewChild(CustomTableComponent,{static: false}) customTableComponent; 
    defaultActiveCurrency: string = "USD";
    defaultActiveFilter: string = "T";       
    PerformanceRatioGridLoader:boolean =true;
    indexMarkers : any;     
    currencyCode : string;
    dataTableRawData : any;
    datatable : any;
    gridCustomColumns: any[];
    gridData:any;
    returnType : any;
    asOfDate :any;
    startDate:any;
    timePeriod:any;
    
    ngOnInit() {              
        this.asOfDate = this.dateService.getCalendarDate().end.substring(0,10);
        this.callSetup(); 
       
    } 
   
    callSetup(){
        this.asOfDate = this.dateService.getCalendarDate().end.substring(0,10);
        this.startDate = this.dateService.getCalendarDate().start.substring(0,10);
        this.currencyCode = this.currencyService.getCurrencyCode(); 
        this.returnType = 'T'; 
        this.timePeriod = 'DAILY';
        this.indexMarkers = this.indexMarkerService.getIndexMarkers();  
        this.gridSetup( this.asOfDate,this.indexMarkers);   
    }
    gridSetup( asOfDate,indexMarkers:any) { 
        this.PerformanceRatioGridLoader = true;
         
             this.gridCustomColumns = [
                {columnName: "Select All", columnVisibility: false, columnCssClass: "text-left", uiDisplayValue: "Select All", filter: "",hasFilter : true },
                {columnName: "RewardRisk", columnVisibility: true, columnCssClass: "text-left", uiDisplayValue: "Reward / Risk", filter: "",hasFilter : true},
                {columnName: "maxDD", columnVisibility: true, columnCssClass: "text-left", uiDisplayValue: "Max Drawdown (%)", filter : "number:'.1-2'",hasFilter : true },
                {columnName: "informationRatio", columnVisibility: true, columnCssClass: "text-left", uiDisplayValue: "Information", filter : "number:'.1-2'",hasFilter :true },
                {columnName: "rawSharpeRatio", columnVisibility: true, columnCssClass: "text-left", uiDisplayValue: "Sharpe Ratio", filter : "number:'.1-2'",hasFilter: true },
                {columnName: "UpCapture", columnVisibility: true, columnCssClass: "text-right", uiDisplayValue: "Up Capture", filter: "number:'.1-2'",hasFilter: true },
                {columnName: "DownCapture", columnVisibility: true, columnCssClass: "text-right", uiDisplayValue: "Down Capture", filter: "number:'.1-2'",hasFilter: true },
                 {columnName: "TwentyFiveYearAnnualizedReturn", columnVisibility: false, columnCssClass: "text-right", uiDisplayValue: "25", filter: "number:'.1-2'", hasFilter: true},
                ];
             
                 
                                 
                this.reportService.getRiskReturnList(this.startDate,this.asOfDate,this.indexMarkers,
                    this.currencyCode,this.timePeriod).then(res=>{
    
                        this.gridData = res["data"];
                        this.customTableComponent.bind(this.dateDisplayService.getArrayDateDisplay(this.gridData,"asofdate"),this.gridCustomColumns);       
                        this.PerformanceRatioGridLoader =false;
                    }).catch(err => {
                        this.PerformanceRatioGridLoader =false;
                    });
                 
                    
     }  
    

   

    
    PopupInfo(): void{         
        let dialogRef = this.dialog.open(FactorsPerformanceRatiosGridComponentDialog, {
          width: '800px',
          data: this.indexMarkers                  
        });
      }

      Exportxls():void{
        this.reportService.getRiskReturnList(this.startDate,this.asOfDate,this.indexMarkers,
            this.currencyCode,this.timePeriod).then(res=>{
                this.exportService.exportData(res["data"],"Factors Performance Ratio GridData"); 
                this.PerformanceRatioGridLoader =false;
            }).catch(err => {
                this.PerformanceRatioGridLoader =false;
            });
      }

}


@Component({
    selector: 'factors-performance-ratios-grid.component.widget.dialog',
    templateUrl: './factors-performance-ratios-grid.component.widget.dialog.html'
})
export class FactorsPerformanceRatiosGridComponentDialog {
    @Input() indexes: string;
    constructor(
    public dialogRef: MatDialogRef<FactorsPerformanceRatiosGridComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.indexes="";
       data.forEach(element => {
           if( this.indexes.length<3)
           {
              this.indexes=element;
           }else{
          this.indexes += ", "+ element;
           }
       });   
     }
  
  onClose(): void {
      this.dialogRef.close();
  }
}