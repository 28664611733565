import { Component, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { APIKeyModel } from '../../models/entitlement.model';
import { APIFieldsModel } from '../../models/entitlement.model';
import { EntitlementService } from '../../services/entitlement.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-user-search',
  templateUrl: './apikey-search.component.html',
  styleUrls: ['./apikey-search.component.css']
})

export class ApiKeySearchComponent implements OnInit, OnChanges {
  apikeysearch: APIKeyModel[];
  filteredCompanies: APIKeyModel[];
  selectedCompany: any;
  
  constructor(private entitlementService: EntitlementService) { }

  ngOnInit() {
    this.apikeysearch = [{
      apiKeyId: "", apiKeyName: "", apiKeyDescription: "",
      apiKeyValue: "", isDisabled: false, apiId: "", usagePlanId: null, usagePlanName: "",
      usagePlanKeyId: null, usagePlanDescription: "", isEnabled: false, isTermsCheckReq: false,
      isConfirmed: false, stageName: "", burstLimit: "", rateLimit: "", companyId: "", companyName: "",
      id: "", isResend: false, licenceBand: "", isHistoryAPI: false, isMasterAPI: false
    }];
    this.entitlementService.getAPIKeys().then(result => {
      this.apikeysearch = result;
      this.filteredCompanies = this.apikeysearch;

    })
    
  }
  onCompanyChange(selectedCompany) {
    if (selectedCompany != 'undefined') {
      var selectedComapnyId;
      for (var i = 0; i < this.apikeysearch.length; i++) {
        if (this.apikeysearch[i].companyName == selectedCompany) {
          selectedComapnyId = this.apikeysearch[i].companyId;
        }
      }
      this.filteredCompanies = this.apikeysearch.filter(x => x.companyId == selectedComapnyId);
    }
    else{
        this.filteredCompanies = this.apikeysearch;
    }
  }

  ngOnChanges(changes: SimpleChanges) { }
  deleteAPIKey(apiKeyInfo: any) {
    let values = confirm('Do you want to Delete ?')
    if (values == true) {
      this.entitlementService.deleteAPIKey(apiKeyInfo).then(res => {
        //alert("returnvalue:" + res);
        console.log("result" + res);
        if (res == "1") {
          alert("API Key: " + apiKeyInfo.apiKeyName + " " + 'deleted successfully');
          window.location.href = "/apikey-search"
        }
        else {
          alert("Error while deleting the API Key: " + apiKeyInfo.apiKeyName);
        }

      });
      this.entitlementService.getAPIKeys().then(result => {
        this.apikeysearch = result;
      })

    }

  }


}

