import { Component, OnInit, Input, OnChanges, SimpleChanges,Inject,NgZone } from '@angular/core';
import {WeightService} from '../../services/weight.service';
import {BiService} from '../../services/BiService.service';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MagellanColorService} from '../../services/magellancolor.service';
import{CurrencyService}from '../../services/currency.service';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import {DateService } from '../../services/date.service';

@Component({
    selector: 'close-top-level-sector-chart',
    templateUrl: './close-holdings-top-level-sector-chart.component.html'
})
export class CloseTopLevelSectorChartComponent implements OnInit {

    chartAttributes: any;
    chartColorRange: any;
    topLevelSectorDataSource: any;
    popUpDataSource: any;
    TopLevelSectorChartLoader:boolean=true;
    currentIndex:any;
    colors : any;
    chart:any;
    dateFormat: any;

    constructor(private weightService: WeightService ,private indexMarkerService:IndexMarkerService, private exportService: ExportToExcelService,private publishIndexMarkerService:PublishIndexMarkerService,
        private dialog: MatDialog,private colorService : MagellanColorService,
         private biService:BiService,private zone:NgZone, private currencyService:CurrencyService,
         private idwChartExportService: IDWChartExportService,private dateService : DateService) {

    

        this.chartAttributes = {
            "animation": "0",  
            "exportEnabled": "1",
            "exportShowMenuItem": "0",         
            "horizontalPadding": "0",
            "verticalPadding": "0",
            "plotborderthickness": ".5",
            "plotbordercolor": "b3b3b3",
            "labelGlow": "0",
            "showLegend": "1",            
            "labelFontColor": "000000",
            "labelFontSize": "9",
            "showchildlabels": "1",
            "theme": "zune",
            "chartTopMargin": "-25",
             "bgColor" : "#FFFFFF",
             "borderThickness" : "0"
           
        };
        this.chartColorRange = {
            "mapbypercent": "1",
            "code": "e24b1a",
            "color": [
                {
                    "code": "ffffff",
                    "maxvalue": "0",
                    "label": "Static"
                },
                {
                    "code": "6da81e",
                    "maxvalue": "25",
                    "label": "AVERAGE"
                }
            ]
        }
    }
    
    asofDates: any;
    indexMarkers: any ;
    treeMapObject: any;
    selectedSectorChart : string = "icb";
    currencyCode:any="USD";
    ngOnInit() {
        this.dateFormat = this.dateService.getDateFormat(); 
            this.dateService.dateFormatPublish().subscribe(dateFormat=>{
                this.dateFormat =  dateFormat;                      
            });
        this.indexMarkers = this.indexMarkerService.getIndexMarkers();
        this.currentIndex = 0;  
        this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
            this.indexMarkers = result;
            this.TopLevelSectorChartLoader=true;
            this.chartSetup(this.selectedSectorChart);
        });

        this.colors = this.colorService.getCurrentColorSchema().split(",");
        this.colorService.colorsPublish().subscribe(color => { 
            this.colors = color.split(",");           
            this.fillColor();      
        });
    }

    chartInitialize(event){
        return (eve, arg) => { 
            this.zone.run(() => {
                this.chart =eve.sender;
            });  
            
        };
          
      }
    
      events = {
        initialized: this.chartInitialize(event)
    }
   
    carouselPrevious():void{
        var event: any = {eventName: "Weight Top Level Sector chart carousel previous", inputParameters: [{Name: "Next IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() },{Name: "Relevent As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);
        this.TopLevelSectorChartLoader=true;
        if(0 == this.currentIndex){
           this.currentIndex = this.indexMarkers.length -1;
        }else{
            this.currentIndex = this.currentIndex - 1;
        }
        this.chartSetup(this.selectedSectorChart);
    }
    
    carouselNext():void{
        var event: any = {eventName: "Weight Top Level Sector chart carousel next", inputParameters: [{Name: "Next IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() },{Name: "Relevent As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);
        this.TopLevelSectorChartLoader=true;
       if((this.indexMarkers.length -1) == this.currentIndex){
            this.currentIndex = 0;
        }else{
            this.currentIndex = this.currentIndex + 1;
        }
        this.chartSetup(this.selectedSectorChart);
    }

    update(calendarDate:any){
        this.indexMarkers = this.indexMarkerService.getIndexMarkers();
        this.currentIndex = 0;  
        this.currencyCode = this.currencyService.getCurrencyCode();
        this.TopLevelSectorChartLoader=true;
        this.asofDates = calendarDate;            
        this.chartSetup(this.selectedSectorChart);
    }

    onChange(selectedChart,currencyCode){
        this.currencyCode=currencyCode;
        this.selectedSectorChart = selectedChart;  
        this.chartSetup(this.selectedSectorChart);         
        this.fillColor();
        this.topLevelSectorDataSource = {
            "chart": this.chartAttributes,
            "data": (this.selectedSectorChart == "icb")?this.treeMapObject.icbDataset:this.treeMapObject.rgsDataset,
            "colorrange": this.chartColorRange
        }
        this.popUpDataSource = {
            "dataSource": this.topLevelSectorDataSource,
            "asOfDate": (this.dateFormat == "USA") ? this.dateService.changeDateFormat(this.asofDates) : this.dateService.changeDateFormatUK(this.asofDates)
        }
        
    }
    private chartSetup(selectedChart:string) {    
        var event: any = {eventName: "Weight Top Level Sector Chart Load", inputParameters: [{Name : "Loaded IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name: "Relevent As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);   
        this.weightService.getTopLevelSector(this.asofDates, [this.indexMarkers[this.currentIndex]],this.currencyCode).then(data => {            
            this.treeMapObject = data.data;
            this.fillColor();
            this.topLevelSectorDataSource = {
                "chart": this.chartAttributes,
                "data": (selectedChart == "icb")?this.treeMapObject.icbDataset:this.treeMapObject.rgsDataset,
                "colorrange": this.chartColorRange
            }
            this.popUpDataSource = {
                "dataSource": this.topLevelSectorDataSource,
                "asOfDate": (this.dateFormat == "USA") ? this.dateService.changeDateFormat(this.asofDates) : this.dateService.changeDateFormatUK(this.asofDates)
            }
             this.TopLevelSectorChartLoader=false;
        },onerror =>{
        this.TopLevelSectorChartLoader =false;       
     });     
    }
     
    loadingRefresh(){
        this.TopLevelSectorChartLoader = true;
    }

    loadingStop(){
        this.TopLevelSectorChartLoader = false;
    }
    
    fillColor():void{
        if(this.treeMapObject.icbDataset != undefined){
            this.treeMapObject.icbDataset[0].fillColor = "#e6e6e6";
            var repeatcolor=0
            for(var level0 = 0;  level0 < this.treeMapObject.icbDataset[0].data.length  ; level0++){
                if(repeatcolor== this.colors.length-1){
                    repeatcolor=0;
                }
                this.treeMapObject.icbDataset[0].data[level0].fillColor = this.colors[repeatcolor];   
                repeatcolor ++;             
            }           
        }
      
        if(this.treeMapObject.rgsDataset != undefined){
            var repeatcolor=0
            this.treeMapObject.rgsDataset[0].fillColor = "#e6e6e6";
            for(var level0 = 0;  level0 < this.treeMapObject.rgsDataset[0].data.length  ; level0++){
                if(repeatcolor==this.colors.length-1){
                    repeatcolor=0;
                }
                this.treeMapObject.rgsDataset[0].data[level0].fillColor = this.colors[repeatcolor];   
                repeatcolor ++;             
            }           
        }  
    
     } 

 ExportXls(event):void{
    var event: any = {eventName: "Weight Top Level Sector Chart Exported", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name: "Exported As Of Date", Value: this.asofDates.toString()}]};
    this.biService.addEvent(event);
        var inputData = {
            "exportType": "xls",
            "code": "weight-getTopLevelSectorData",
            "xlsFileName": "closeTopLevelSectorRawData",
            "asOfDate": this.asofDates,
            "indexMarkers": [this.indexMarkers[this.currentIndex]],
            "returnType":this.selectedSectorChart,
            "currencyCode":this.currencyCode
          };
          this.idwChartExportService.export(inputData);
      }


      ExportPNG(event):void{
        var event: any = {eventName: "Export Weight Top Level Sector Chart TO PNG",   inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name: "Exported As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);
        var inputData = {
            "exportType": "png",
            "legend": false,
            "dataSource": this.popUpDataSource.dataSource,
            "chartid" : "topLevelSectorChartExport",
            "type" : "treemap",
            "targetDiv" : "exportPngDiv",
            "chartReference": this.chart,
            "pngFileName" : "closeTopLevelSectorRawData",
            "chartTitle": "Top Level Sector",
            "chartDate": this.asofDates
         };
         this.idwChartExportService.export(inputData);
      }
    openDialog(): void{ 
        var event: any = {eventName: "Weight Top Level Sector Chart Expanded", inputParameters: [{Name : "Expanded IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name: "Expanded As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);
        this.popUpDataSource = {
            "dataSource": this.topLevelSectorDataSource,
            "asOfDate": (this.dateFormat == "USA") ? this.dateService.changeDateFormat(this.asofDates) : this.dateService.changeDateFormatUK(this.asofDates)
        }
        let dialogRef = this.dialog.open(CloseTopLevelSectorChartComponentDialog, {
            width: '80%',
            height: '85%',
	    data: this.popUpDataSource
  });
}

}

@Component({
    selector: 'close-top-level-sector-chart-dialog',
	templateUrl: './close-holdings-top-level-sector-chart.component.dialog.html'
})
export class CloseTopLevelSectorChartComponentDialog{

    constructor(
    public dialogRef: MatDialogRef<CloseTopLevelSectorChartComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    onClose(): void {
        this.dialogRef.close();
  }

   
}