import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewEsgconfigurationForm } from '../../models/esgconfiguration';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report } from '../../models/report';
import { formatDate, DatePipe } from '@angular/common';
import { EsgconfigurationDataService } from '../../services/esgconfigurations.service';
import { debug } from 'console';

@Component({
  selector: 'app-new-esgconfigurationdata-popup',
  templateUrl: './new-esgconfigurationdata-popup.component.html',
  styleUrls: ['./new-esgconfigurationdata-popup.component.scss']
})
export class NewEsgconfigurationPopupComponent implements OnInit {

 form: FormGroup = this.fb.group({
    indexCode: ['', Validators.required],
    indexFamilyId: [''],
    derivedIndex: [''],
    parentIndexCode: [''],
    nicbIndustry: [''],
    currency: [''],
    country:['']
  } as Record<keyof NewEsgconfigurationForm, any[]>);

  updateReport: boolean = false;
  isreportForm = false;
  currencies: string[] ;
  public currenciesfilteredList : string[];
    countries: string[] ;
  public countriesfilteredList : string[];
  constructor(private fb: FormBuilder, private esgconfigurationdataservice: EsgconfigurationDataService, public dialogRef: MatDialogRef<NewEsgconfigurationPopupComponent>, @Inject(MAT_DIALOG_DATA) public rowdata: any) {

    console.log(rowdata);

  }

  ngOnInit(): void {
    if (this.rowdata) {
      this.updateReport = true;
      this.form.patchValue({
        indexCode: this.rowdata.IndexCode,
        parentIndexCode: this.rowdata.ParentIndexCode,
        indexFamilyId: this.rowdata.IndexFamilyId,
        currency: this.rowdata.Currency,
        country: this.rowdata.Country,
        derivedIndex: this.rowdata.DerivedIndex,
        nicbIndustry: this.rowdata.NicbIndustry
      })   
    }
    this.esgconfigurationdataservice.getCurrencies().subscribe(data =>{
      this.currencies =data.data;
      this.currenciesfilteredList = data.data;
    }) ;
    this.esgconfigurationdataservice.getCountries().subscribe(data =>{
      this.countries =data.data;
      this.countriesfilteredList = data.data;
    }) ;
  }

  public onClickHandler(event: any): void {
 
  }

  onSubmit(): void {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      this.dialogRef.close(data);
    }
    if (this.form.invalid) {
      this.isreportForm = true;
      return;
    }
  } 

  }
