import { GridData } from "../pbr/models/common";
import { IndexMappingDetailColumns } from "./client-index-mapping";

export const DEFAULT_COLUMNS = [
    ...Object.values(IndexMappingDetailColumns),
];

export interface ClientIndex {
    id: number;
    companyId: number;
    company: string;
    reportTypeId: number;
    reportType: string;
    totalCount: number;
    children?: ClientIndexDetails;
}

export const newIndexMappingDetails = ({    
    id = 0,
    companyId = 0,
    company = '',
    reportTypeId = 0,
    reportType = '',
    totalCount = 0,    
    children,
}: Partial<ClientIndex> = {}): ClientIndex => {
    const mapping =
    {
        id:id,
        companyId:companyId,
        company:company,
        reportTypeId:reportTypeId,
        reportType:reportType,
        totalCount:totalCount,
        children:children
    };
    if (children) {
        mapping.children.model = mapping;
    }
    return mapping;
};


export interface ClientIndexDetails extends GridData<ClientIndex> { }

export enum ClientIndexMappingColumns
{
    indexMarker = "indexmarker",
    indexFamily = "indexfamily",    
    currency = "currency",
    isActive = "isactive",
}

export interface ClientIndexMappingForm {
    id: number;
    company: string;
    companyId: number;
    reportType: number[];
    reportTypeId: number;
    previousCompanyId : number;
    previousReportTypeId : number;
    reportGroup : string;
    file: File;
  }
