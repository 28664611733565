import { Injectable }    from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ReferenceService{

    
    private methodSubject = new Subject<any>()
    constructor( private httpClient: HttpClient) { 
        
    }
    getIndexMarker$: Observable<any>=this.methodSubject.asObservable();
    setIndexMarker(data) {
        this.methodSubject.next(data);
    }
    
    getIndexPropertiesList(indexMarkers:any,endDate:any):Promise<any[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
  
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reference/getIndexPropertiesList', JSON.stringify({
                IndexMarkers : indexMarkers,
                ToDate : endDate
        }),
              {headers: headers})
              .toPromise()
              .then(response =>{
                   return response as any[]
                  })
              .catch(this.handleError);
    }

    getIndexPropertiesGridToExport(indexMarkers : any,asOfDate:any,selectedColumns:string[]):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        
           return this.httpClient.post(environment.dataServiceRoot + 'ui/reference/GetIndexPropertiesGridToExport',
               JSON.stringify({     
                ToDate:asOfDate,      
                   IndexMarkers : indexMarkers,                  
                   SelectedColumns:selectedColumns          
           }), {headers: headers})
           .toPromise()
           .then(response =>{
                return response as any
               })
           .catch(this.handleError);
    }
   
    getDistinctProductFamilies():Promise<any[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
        
              return this.httpClient.post(environment.dataServiceRoot + 'ui/reference/getDistinctProductFamilies', null,
                    {headers: headers})
                    .toPromise()
                    .then(response =>{
                         return response as any[]
                        })
                    .catch(this.handleError);
    }


    getProductIndexMarker(page:any,pageSize:any,productIndexMarkerFilter:any):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
        
              return this.httpClient.post(environment.dataServiceRoot + 'ui/reference/getProductIndexMarker', 
              JSON.stringify({
                 Page: page,
                 PageSize: pageSize,
                 ProductIndexMarkerFilter: {
                     Prefix : productIndexMarkerFilter.Prefix,
                     FileDescription: productIndexMarkerFilter.FileDescription,
                     FileType : productIndexMarkerFilter.FileType,
                     FolderPath: productIndexMarkerFilter.FolderPath,
                     ProductIndexMarker: productIndexMarkerFilter.ProductIndexMarker
                 }
             
        }),         {headers: headers})
                    .toPromise()
                    .then(response =>{
                         return response as any
                        })
                    .catch(this.handleError);
    }
 
    getSeuritySearchDetails(securityId:any,securityExpansionFilter:any,asOfDate:any):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
        
              return this.httpClient.post(environment.dataServiceRoot + 'ui/reference/getSeuritySearchDetails', 
              JSON.stringify({
                SecurityID: securityId,
                ToDate: asOfDate,
                SecurityExpansionFilter:{
                    IndexName : securityExpansionFilter.IndexName,
                    IndexMarker : securityExpansionFilter.IndexMarker,
                    SecurityID : securityExpansionFilter.SecurityId,
                    ProductCode : securityExpansionFilter.ProductCode
                }
        }),         {headers: headers})
                    .toPromise()
                    .then(response =>{
                         return response as any
                        })
                    .catch(this.handleError);
    }

    getSeuritySearchwithoutpaging(endDate:any){
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
        
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reference/getSeuritySearchwithoutpaging', 
        JSON.stringify({          
           ToDate : endDate       
  }),         {headers: headers})
              .toPromise()
              .then(response =>{
                   return response as any
                  })
              .catch(this.handleError);   
    }
    getsecuritySearch(page:any,pageSize:any,securitySearchFilter:any,endDate:any):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
        
              return this.httpClient.post(environment.dataServiceRoot + 'ui/reference/getSeuritySearch', 
              JSON.stringify({
                 Page: page,
                 PageSize: pageSize,
                 SecuritySearchFilter: {
                    SecurityIdentifier: securitySearchFilter.SecurityIdentifier,
                    SecurityName: securitySearchFilter.SecurityName,
                    SecurityId: securitySearchFilter.SecurityId,
                    ProductCode: securitySearchFilter.ProductCode
                 },
                 ToDate : endDate
             
        }),         {headers: headers})
                    .toPromise()
                    .then(response =>{
                         return response as any
                        })
                    .catch(this.handleError);
    }


    getProductIndexMarkerData():Promise<any[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
        
              return this.httpClient.post(environment.dataServiceRoot + 'ui/reference/GetProductIndexMarkerData', null,
                    {headers: headers})
                    .toPromise()
                    .then(response =>{
                         return response as any[]
                        })
                    .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); 
        return Promise.reject(error.message || error);
    }
}