import { Component, OnInit,Input  } from '@angular/core';
import {DateService} from '../../services/date.service';
import {DataqualityserviceService} from '../../services/dataqualityservice.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-sector-data-quality',
    templateUrl: './sector-data-quality.component.html',
    styleUrls: ['./sector-data-quality.component.css']
  })
  export class SectorDataQualityComponent implements OnInit {
        
    asOfDate : any;
    icbSectorData:any;
    icbIndexDetailsModel : any;
    icbDataGridLoader:boolean =true;
    icbRowExpandLoader:boolean[] =[]; 
    icbPanelOpenState:Boolean = false;
    client : string ;
    
  constructor(
     private dataqualityservice:DataqualityserviceService,private dateService:DateService,
    private exportService:ExportToExcelService ,private route: ActivatedRoute) { }
    ngOnInit() {
      var that = this;
      this.asOfDate = this.dateService.getCalendarDate().end;     
      this.dateService.calendarDatePublish().subscribe(result=>{
        that.asOfDate = moment(result.end).format('MM/DD/YYYY');
        that.icbGridSetup();
       });
       var sub = this.route.queryParams.subscribe(params => {  
        this.client =params['Client'] ;
        if(this.client== undefined)
        {
          this.client= "ALL";
        }
        this.icbGridSetup();
      });  
    }

    icbGridSetup(){

        var that = this;
        that.icbRowExpandLoader = [];
              this.icbDataGridLoader   = true;
              this.dataqualityservice.GetIcbSectorClassifictionDataQualities(this.asOfDate ,this.client).then(data=>{
                that.icbSectorData=data;
              var index = 0;
              data.forEach(element => {
                that.icbRowExpandLoader[index] = false;
                index++;
              });
              this.icbDataGridLoader=false;
              },onerror =>{
              this.icbDataGridLoader =false; 
              });
    }

    getIcbSearchDetails(element,index){
      if(this.icbPanelOpenState == true){
        this.icbRowExpandLoader[index] = true;
        this.dataqualityservice.GetIcbSectorClassificationDataQualityDetails(this.asOfDate,element.securityId ,this.client).then(data=>{
        this.icbIndexDetailsModel = data ;
        this.icbRowExpandLoader[index] = false;
      },onerror =>{
        this.icbRowExpandLoader[index] =false; 
    });
    }
    }

    ExportXls(element):void{
      this.dataqualityservice.GetIcbSectorClassificationDataQualityDetailsExportData(this.asOfDate,element.securityId ,this.client).then(res => {
              this.exportService.exportData(res,"ICB Data");
              }).catch(err=> { 
              }); 
    }


    

}  