import { Component, OnInit, Input, OnChanges,SimpleChanges} from '@angular/core';
import { SearchService } from '../../services/search.service'
import {Observable, of} from 'rxjs';



import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';







import * as AWS from 'aws-sdk';
import * as AWSCognito from 'amazon-cognito-identity-js';
import { SearchResultModel } from '..//../models/search-model'; 
import { SearchSet } from '..//../models/search-model'; 
import { myfilter } from '..//../models/search-model'; 
import { IndexMarkerService } from '../../services/indexmarker.service';
import { PublishIndexMarkerService } from '../../services/publish-index-marker.service';
import { CompanyModel } from '../../models/entitlement.model';
import { EntitlementService } from '../../services/entitlement.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
// This is a test page for entitlement. this will go away once the entitlement is completely setup.
@Component({
  selector: 'app-entitlement',
  templateUrl: './entitlementtest.component.html',
  styleUrls: ['./entitlementtest.component.css']
})
export class EntitlementtestComponent implements OnInit {
  endpointURL: any;
  responseData : any;
  sendRequest()
  {
      this.entitlementService.getUser(this.endpointURL).then(result => {   
        this.responseData = JSON.stringify(result, undefined, 4)                     
      console.log(result);
     },onerror =>{
     console.log("error occoured");
    });
  }

  sendRequestAndSave()
  {
    this.Export(this.endpointURL);
  }

  Export(endPointURL):void{       
    AWS.config.update({
        region: 'eu-west-1'
    });         
     var that = this;
    var data = {
        UserPoolId : '',
        ClientId : '' // Your client id here
    };
    var userPool = new AWSCognito.CognitoUserPool(data);
    var cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
        cognitoUser.getSession(function(err, session) {
            if (err) {
                return;
            }
            var authData = {
                Username: 'idwtest',
                Password: ''
              };
              var authDetails = new AWSCognito.AuthenticationDetails(authData);                  
              cognitoUser.authenticateUser(authDetails,{
                  onSuccess: function(data){
                    var logins = {};
                    logins[''] = data.getIdToken().getJwtToken();
                    var cognitoIdentity = new AWS.CognitoIdentity();
                    var accountId = '';
                    var identityPoolID = '';
                    var request = { AccountId: accountId,IdentityPoolId: identityPoolID,Logins: logins };
                    cognitoIdentity.getId(request,function(err,dataId){
                        if(err){
                            console.log(err);
                            return;
                        }else{
                            var identityRequest = {IdentityId: dataId.IdentityId,Logins: logins };  
                            cognitoIdentity.getCredentialsForIdentity(identityRequest,function(err,tempCredentials){
                                if(err){
                                    console.log(err);
                                    return;
                                }else{
                                    // Instantiate aws sdk service objects now that the credentials have been updated.
                                    that.entitlementService.getUser(endPointURL).then(result => { 
                               var s3Url = result[0];
                               AWS.config.update({
                                region: 'eu-west-1',
                                accessKeyId: tempCredentials.Credentials.AccessKeyId,
                                secretAccessKey: tempCredentials.Credentials.SecretKey,
                                sessionToken: tempCredentials.Credentials.SessionToken
                             });
                           var s3 = new AWS.S3({
                           });
                             var urlParts = s3Url.split("/");
    
                             var getParams = {
                               Bucket: urlParts[urlParts.length - 2],
                               Key: urlParts[urlParts.length - 1]
                            }
                            s3.getObject(getParams,function(err,data){
                               if(err){
                                 console.log(err);
                                 return;
                                }else{
                                  that.exportService.SaveJsonFile(data.Body.toString(),"ent" + urlParts[urlParts.length - 1]);
                                 }
                    });
                        
              });
             }
       });
     }
  });
                    
},
onFailure: function(err){
  console.log(err);
    }
});
            
});
}
}

 constructor(private entitlementService: EntitlementService, private exportService: ExportToExcelService) 
 {}

  ngOnInit(){
  this.endpointURL = ""
  }

}


