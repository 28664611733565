import { Injectable }    from '@angular/core';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class CurrencyService {
  private subject = new Subject<any>();  
  private subjectCurrencyCode= new Subject<any>(); 
  constructor(){}
 
  setCurrencyCode(currencyCode:any):void{
    localStorage.setItem("CurrencyCode",currencyCode);
    this.publishCurrencyCode(currencyCode);
  }

  getCurrencyCode(){  
    return localStorage.getItem("CurrencyCode"); 
  }

  publishCurrencyCode(currencyCode: any){
     this.subjectCurrencyCode.next(currencyCode);
   }

  currencyCodePublish():Observable<any>{
      return this.subjectCurrencyCode.asObservable();
  }
}
