import { Component, OnInit } from '@angular/core';
import { ClientIndexDetails } from '../../models/client-index-mapping-details';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, IndexMappingDetailColumns, iDisplayNewRow, indexMappingDetailsColumnsNameMap, indexMappingDisplayName } from '../../models/client-index-mapping';
import { ClientIndexMappingService } from '../../services/client-index-mapping.service';
import { DownloadService } from '../../pbr/services/download.service';
import { GridData } from '../../pbr/models/common';
import { ClientIndexMappingForm } from '../../models/client-index-mapping-details';
import { _isNumberValue } from '@angular/cdk/coercion';
import { iResponseModel } from '../../models/response.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-client-index-mapping-detail',
  templateUrl: './client-index-mapping-detail.component.html',
  styleUrls: ['./client-index-mapping-detail.component.scss']
})
export class ClientIndexMappingDetailComponent implements OnInit {
  private readonly DEFAULT_PORTFOLIO_NAME = 'Client Index Mapping';
  clientMappingDetails: ClientIndexDetails;
  indexMarkerDetails: any; 
  updateObject: string[][] = [];
  nameFC = new FormControl('');
  companyId: number;
  reportTypeId: number;
  expandedColumnsToDisplayHeadingMap: Record<IndexMappingDetailColumns, string> = indexMappingDetailsColumnsNameMap;
  company: string;
  reportType: number[];
  displayNewRow: iDisplayNewRow = { action: Action.new, displayInput: false };;
  deleteIds: number[] = [];
  isDeleteAll: boolean;
  clearFilter: boolean = false; 
  viewOnlyAccess:Boolean = true; 
  constructor(
    private clientIndexMappingService: ClientIndexMappingService,
    private router: Router,
    private downloadService: DownloadService) {

    const param = localStorage.getItem('ClientIndexMapping');
    this.companyId = JSON.parse(param).companyId;
    this.company = JSON.parse(param).company;
    this.reportTypeId = JSON.parse(param).reportTypeId;
    this.reportType = JSON.parse(param).reportType;
    console.log(param);

    this.GetClientIndexMapping();
  }

  GetClientIndexMapping() {
    this.clientIndexMappingService.fetchClientIndexById(this.companyId, this.reportTypeId)
      .then((data) => {
        this.isDeleteAll = false; this.deleteIds = [];
        this.clientMappingDetails = data;
        this.indexMarkerDetails = JSON.parse(JSON.stringify(this.clientMappingDetails.model.children.data));
        this.clearFilter = false;
      });
  }

  MapIsActive(data: any, action: Action): any {
    switch (action) {     
      case Action.download: {
        data.forEach(indexmarker => {
          indexmarker.isActive = indexmarker.isActive == true ? "Yes" : "No";
        });
        break;
      }
    }
  }

  ngOnInit(): void {
    if (sessionStorage.getItem("UserRoles") == environment.role 
      || sessionStorage.getItem("UserRoles").indexOf(environment.adminGroup) !== -1
      || sessionStorage.getItem("UserRoles").indexOf(environment.clientServiceOpsGroup) !== -1)
  {
    this.viewOnlyAccess = false;
  }
  }

  update(updateObject: string[][]) {
    this.updateObject = updateObject;
    //Update current view
    var updatedMappingDetails = this.updateDatasource(this.clientMappingDetails.model.children.data, this.clientMappingDetails.columns, this.updateObject);
    updatedMappingDetails.forEach(element => {
      let row = this.clientMappingDetails.model.children.data.find((x: any) => x.configId == element.configId) as any;
      row.indexmarker = element.indexmarker;
      row.indexFamily = element.indexFamily;
      row.currency = element.currency;
      row.isActive = element.isActive;
    });
  }

  download(clientIndexDetails: ClientIndexDetails) {
    let columns: string[] = [];
    columns[0] = "Company";
    columns[1] = "Report Type";
    columns[2] = "Index Marker";
    columns[3] = "Index Family";
    columns[4] = "Currency";
    columns[5] = "IsActive";

    let data: string[][] = [];
    const downloadData = JSON.parse(JSON.stringify(clientIndexDetails.model.children.data));
    this.MapIsActive(downloadData, Action.download);
    for (let i = 0; i < downloadData.length; i++) {
      data[i] = [];
      data[i]['Company'] = this.clientMappingDetails.model.company;
      data[i]['Report Type'] = this.clientMappingDetails.model.reportType;
      data[i]['Index Marker'] = downloadData[i]['indexMarker'];
      data[i]['Index Family'] = downloadData[i]['indexFamily'];
      data[i]['Currency'] = downloadData[i]['currency'];
      data[i]['IsActive'] = downloadData[i]['isActive'];
    }

    const dsCopy: GridData<any> = {
      model: data,
      columns: columns.map(c => c),
      data: data.map(row => row.map(cell => cell))
    };
    this.downloadService.download(dsCopy, clientIndexDetails.model.company || this.DEFAULT_PORTFOLIO_NAME);
  }

  return() {
    this.router.navigate(['client-index-mapping',]);
  }

  save(clientIndexDetails: ClientIndexDetails) {
    var valid = true;

    if (this.displayNewRow.displayInput) { alert('Please click on add to grid or cancel for new mapping, before saving record'); return false; }

    var updatedMappingDetails = this.updateDatasource(clientIndexDetails.model.children.data, clientIndexDetails.columns, this.updateObject);

    if (updatedMappingDetails.length <= 0)
      return false;

    valid = this.Validate(updatedMappingDetails);

    if (!valid)
      return false;

    const clientIndexMappingForm: ClientIndexMappingForm = {
      id: 0,
      company: this.companyId.toString(),
      companyId: this.companyId,
      reportType: [this.reportTypeId],
      reportTypeId: 0,
      previousCompanyId: 0,
      previousReportTypeId: 0,
      reportGroup: this.company,
      file: null
    }

    //Update current view
    updatedMappingDetails.forEach(element => {
      let row = this.clientMappingDetails.model.children.data.find((x: any) => x.configId == element.configId) as any;
      row.isActive = element.isActive;
    });

    console.log('Update mappings\n' + JSON.stringify(updatedMappingDetails));

    this.clientIndexMappingService.updateClientIndexMappingDetails(clientIndexMappingForm, updatedMappingDetails).then((res: iResponseModel) => {
      if (res.uiNotification.length <= 0) {
        alert('Client index marker updated successfully');
        this.updateObject = [];
        this.clearFilter = true;
        this.GetClientIndexMapping();
      } else {
        alert(res.uiNotification.join('\n'));
        valid = false;
      }
    });
  }

  Insert(insertMappingDetails: any) {
    const clientIndexMappingForm: ClientIndexMappingForm = {
      id: 0,
      company: this.companyId.toString(),
      companyId: this.companyId,
      reportType: [this.reportTypeId],
      reportTypeId: 0,
      previousCompanyId: 0,
      previousReportTypeId: 0,
      reportGroup: this.company,
      file: null
    }

    //this.MapIsActive(insertMappingDetails, Action.toData);
    console.log('Insert mappings\n' + JSON.stringify(insertMappingDetails));

    this.clientIndexMappingService.saveClientIndex(clientIndexMappingForm, insertMappingDetails).then((res: iResponseModel) => {
      if (res.uiNotification.length <= 0) {
        alert('Client index marker added successfully')
        this.displayNewRow = { action: Action.edit, displayInput: false };
        this.clearFilter = true;
        this.GetClientIndexMapping();
      } else {
        alert(res.uiNotification.join('\n'));
        this.displayNewRow = { action: Action.edit, displayInput: true };
      };
    })
  }

  Validate(updatedMappingDetails: any): boolean {
    let isValid: boolean = true;
    for (let item of updatedMappingDetails) {

      if (item.indexMarker == '' && item.indexFamily == '' && item.currency == '') {
        isValid = false;
        alert('All fields cannot be blank');
        break;
      }
      isValid = this.NullCheck(item.indexMarker, indexMappingDisplayName.indexMarker);
      if (!isValid) break;
      isValid = this.containsSpecialChars(item.indexMarker, indexMappingDisplayName.indexMarker);
      if (!isValid) break;
      isValid = this.ValidateLength(50, item.indexMarker, indexMappingDisplayName.indexMarker);
      if (!isValid) break;

      isValid = this.NullCheck(item.indexFamily, indexMappingDisplayName.indexFamily);
      if (!isValid) break;
      isValid = this.containsSpecialChars(item.indexFamily, indexMappingDisplayName.indexFamily);
      if (!isValid) break;
      isValid = this.ValidateLength(25, item.indexFamily, indexMappingDisplayName.indexFamily);
      if (!isValid) break;

      isValid = this.containsSpecialChars(item.currency, indexMappingDisplayName.currency);
      if (!isValid) break;
      isValid = this.ValidateLength(5, item.currency, indexMappingDisplayName.currency);
      if (!isValid) break;
    }

    return isValid;
  }
  NullCheck(value: string, field: string): boolean {
    if (!value) {
      alert(`${field} cannot be blank`);
      return false;
    } else return true;
  }

  containsSpecialChars(value: string, field: string): boolean {
    const specialChars = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialChars.test(value)) {
      alert(`Please Update Valid details for ${field} in ${value}.\nRemove special characters.`);
      return false;
    } else return true;
  }

  ValidateLength(len: number, value: string, field: string): boolean {
    if (value && value.length > len) {
      alert(`${field} length cannot be more then ${len}.`);
      return false;
    } else return true;
  }

  updateDatasource(dataSource: string[][], columns: string[], updateObject: string[][] = [],): any {
    var updatedData: any[] = [];
    // update current dataSource
    updateObject.forEach((row, rowIndex) => {
      row.forEach((colValue, columnIndex) => {
        if (dataSource) {
          dataSource[rowIndex][columns[columnIndex]] = colValue;
        }
      })

      updatedData.push(JSON.parse(JSON.stringify(dataSource[rowIndex])));
    });
    return updatedData;
  }

  deleteRecord(configId: number) {
    this.delete(configId);
  }

  delete(id: number = 0) {
    let ids: number[]=[];
    if (id == 0)
      ids = this.deleteIds;
    else ids.push(id);

    if (ids.length <= 0) { alert('Please select record to delete'); return false; }

    const isDeleteAll = this.clientMappingDetails.model.children.data.length == ids.length;
    const indexmarkers = this.clientMappingDetails.model.children.data
          .filter(x => ids.indexOf((x as any).configId) !== -1)
          .map((e)=> { return (e as any).indexMarker; }).join(', ');

    let message = isDeleteAll ? 'Do you want to delete all index markers?' 
    : `Do you want to delete these index marker(s)?\n${indexmarkers}`;
    
    if (ids.length == 1)
      message = `Do you want to delete this index marker?\n${indexmarkers}`;

    if (confirm(message)) {
      this.clientIndexMappingService.deleteClientIndexMappingDetails(ids).then((result: iResponseModel) => {
        if (result.uiNotification.length <= 0) {
          {
            alert('Index Marker deleted successfully');
            if (isDeleteAll)
              this.router.navigate(['client-index-mapping']);
            else this.GetClientIndexMapping();
            
          }
        } else {
          alert(result.uiNotification.join('\n'));
        }
      });
    }
  }

  AddRow() {
    this.displayNewRow = { action: Action.new, displayInput: true };
  }

  newRecord(item: string[]) {
    var newRow: any[] = [{
      'configId': 0,
      'clientIndexId': 0,
      'indexMarker': item[0] == undefined ? '' : item[0],
      'indexFamily': item[1] == undefined ? '' : item[1],
      'currency': item[2] == undefined ? '' : item[2],
      'isActive': item[3] == undefined ? true : item[3]
    }];
    if (this.Validate(newRow)) {
      this.Insert(newRow);
    } else this.displayNewRow = { action: Action.edit, displayInput: true };
  }

  cancelRecord() {
    this.displayNewRow = { action: Action.cancel, displayInput: false };
  }

  duplicateRecord() {
    this.displayNewRow = { action: Action.duplicate, displayInput: true };
  }

  selectRecord(deleteData: string) {
    const row = JSON.parse(deleteData);
    if (row.isChecked) {//add
      if (row.configId == 0) {
        this.clientMappingDetails.model.children.data.forEach(item => {
          const id = (item as any).configId;
          if (this.deleteIds.indexOf(id) === -1)
            this.deleteIds.push((item as any).configId);
        });
      } else {
        if (this.deleteIds.indexOf(row.configId) === -1)
          this.deleteIds.push(row.configId);
      }
    } else {//remove
      if (row.configId == 0) {
        this.deleteIds = [];
      } else {
        const index = this.deleteIds.indexOf(row.configId);
        if (index !== -1)
          this.deleteIds.splice(index, 1);
      }
    }
    //Update delete all flag
    if (this.deleteIds.length <= 0) { this.isDeleteAll = false; }
    else if (this.deleteIds.length != this.clientMappingDetails.model.children.data.length) { this.isDeleteAll = false; }
    else { this.isDeleteAll = true; }
    console.log(this.deleteIds);
  }

  searchRecord(search: string) {    
    this.clientMappingDetails.model.children.data = this.indexMarkerDetails
    .filter(x => (x as any).indexMarker.toLowerCase().indexOf(search.toLocaleLowerCase()) != -1);
  }
}
