import { Component, OnInit } from '@angular/core';
import { PageAuthenticationService } from '../../services/page-authentication.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './role-page-mapping-search.component.html',
  styleUrls: ['./role-page-mapping-search.component.css']
})
export class RolePageMappingSearchComponent implements OnInit {
  getRoleItemsValue:any;
  getAuthValues: any;
  deleteStatus:any;
  constructor(private services:PageAuthenticationService) { }

  ngOnInit() {
    this.GetRoles();
  }

  GetRoles(){
    this.services.roleNames().then(data =>{
      this.getRoleItemsValue=data;
      
      console.log('roles', this.getRoleItemsValue)
      
          })
  }

  initialize(){
    this.deleteStatus = "";
  }


  deleteRole(element){
    let values = confirm('Do you want to Delete ?')
    if(values== true){
      this.services.Delete({ID: element}).then(result =>{
      
        if(result == "1")
        {
          this.initialize();
           this.deleteStatus = "Record Deleted";
        }
        else{
          this.deleteStatus = "Not deleted";
        }
       this.GetRoles();
      })
    }
    // else{
    //   alert('not');
    //   this.GetRoles();
    // }
    
  
  }

}
