import { BiEvent, Events, InputParameter, GlobalParameters } from '../models/events';
import { Injectable } from '@angular/core';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';

@Injectable()
export class BiService {
    GlobalInputs: GlobalParameters = { IndexMarkers: "", StartDate: null, EndDate: null };
    private events: Events = { userName: '', pageEvents: [] };;
    constructor() { }

    addUserInfo(userInfo: any): void {
        this.events.userName = userInfo;
    }

    addEvent(event: BiEvent): void {
        event.source = document.URL;
        event.eventOccuredDateTime = new Date();
        if (event.inputParameters == null) {
            event.inputParameters = [];
        }
        var globalPrameters: InputParameter[] = [{ Name: "IndexMarkers", Value: this.GlobalInputs.IndexMarkers }, { Name: "StartDate", Value: this.GlobalInputs.StartDate }, { Name: "EndDate", Value: this.GlobalInputs.EndDate }];
        [].push.apply(event.inputParameters, globalPrameters);
        this.events.pageEvents.push(event);
    }

    addEvent2(eventName: string, params: InputParameter[]): void {
        let event: BiEvent = {
            eventName: eventName,
            inputParameters: params,
            source: document.URL,
            eventOccuredDateTime: new Date(),
        }
        if (!event.inputParameters) {
            event.inputParameters = [];
        }
        event.inputParameters.push({ Name: "IndexMarkers", Value: this.GlobalInputs.IndexMarkers });
        event.inputParameters.push({ Name: "StartDate", Value: this.GlobalInputs.StartDate });
        event.inputParameters.push({ Name: "EndDate", Value: this.GlobalInputs.EndDate });
        this.events.pageEvents.push(event);
    }

    addEvents(events: BiEvent[]): void {
        [].push.apply(this.events.pageEvents, events);
    }

    getEvents() {
        return this.events.pageEvents.length > 0 ? this.events : null;
    }

    clearEvents() {
        this.events.pageEvents = [];
    }

    arePendingEvents() {
        return this.events.pageEvents.length > 0
    }

}