import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { CompanyModel } from '../../models/entitlement.model';
import { EntitlementService } from '../../services/entitlement.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.css']
})

export class CompanySearchComponent implements OnInit, OnChanges {
  companies: CompanyModel[];
  filteredCompanies: CompanyModel[];
  selectedCompany: any;
  
  constructor(private entitlementService: EntitlementService) { }
  ngOnInit() {
   
    // this.companies = [{
    //   companyName: "", companyId: "", address: "", emailAddress: "", 
    //   isActive: false, parentCompany: "", phoneNumber: "", companyValue:"" 
    // }];
    this.entitlementService.getCompanies().then(result => {
      this.companies = result;
      this.filteredCompanies = this.companies;
      // console.log(this.companies) 
    })
    this.filteredCompanies = this.companies;
    console.log(this.filteredCompanies)
  }
  ngOnChanges(changes: SimpleChanges) {

  }

  onCompanyChange(selectedCompany) {
    if (selectedCompany != 'undefined') {
      var selectedComapnyId;
      for (var i = 0; i < this.companies.length; i++) {
        if (this.companies[i].companyName == selectedCompany) {
          selectedComapnyId = this.companies[i].companyId;

        }
      }
      this.filteredCompanies = this.companies.filter(x => x.companyId == selectedComapnyId);
    }
    else {
      this.filteredCompanies = this.companies;
    }

  }

  deleteCompany(comp) {
    let values = confirm('Do you want to Delete ?')
    if (values == true) {
      this.entitlementService.deleteCompany(comp).then(res => {
        console.log(values)
        if (res == "1") {
          alert("Company: " + comp.companyName + " " + 'deleted successfully');
          window.location.href = "/company-search"
        }
        else {
          alert("Error while deleting the Company: " + comp.companyName);
        }
      });
    }
    this.entitlementService.getCompanies().then(result => {
      this.companies = result;
      this.filteredCompanies = this.companies;
      console.log(this.companies);
    })

  }
}