import { Component, ElementRef, ViewChild, OnInit, AfterContentInit, AfterViewInit, Input } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Chart } from 'chart.js';
import { ReportsService } from '../../services/reports.service';
import { ReportData } from '../../models/report';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-sales-exposure-chart',
  templateUrl: './sales-exposure-chart.component.html',
  styleUrls: ['./sales-exposure-chart.component.css']
})
export class SalesExposureChartComponent implements OnInit {
  stackBar: any;
  @Input() data: any;
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement> | undefined;
  @Input() PortfolioType:string;
  lablestab:string;

  constructor(private reportService: ReportsService, private router: Router, private route: ActivatedRoute) { }
  ngOnInit(): void {
    this.chartSetup(this.data,this.PortfolioType);
  }

  // ngAfterViewInit(){
  //    this.chartSetup(this.data);
  //  }

  // ngOnChanges(changes: any) {

  //   console.log(this.data);
  //   console.log(changes);

  //   this.chartSetup(this.data);
  // }

  chartSetup(data: any, PortfolioType:string): void {
    if (this.stackBar) this.stackBar.destroy();

    
if (this.PortfolioType=='B')
this.lablestab='IDX'
 else  this.lablestab='PF'

    if (data != undefined) {
      Chart.plugins.register({
        beforeDraw: function (chartInstance) {
          const ctx = chartInstance.chart.ctx;
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
        }
      });
      const optionsBarGreen = {
        type: 'bar',
        data: {
          labels: [this.lablestab, 'BM'],
          datasets: [{
            label: 'Scope 1',
            data: [data.pfScope1, data.bmScope1],
            backgroundColor: [
              "#001EFF",
              "#001EFF"
            ],
            borderColor: "#FFFFFF",
            borderWidth: 1, 
          },
          {
            label: 'Scope 2',
            data: [data.pfScope2, data.bmScope2],
            backgroundColor: [
              "#7F7F7F",
              "#7F7F7F"
            ],
            borderColor: "#FFFFFF",
            borderWidth: 1, 
          },
          {
            type: 'bubble',
            label: 'Scopes 1 + 2',
            data: [data.pfScope12, data.bmScope12],
            backgroundColor: [
              "#000000", "#000000"
            ],
            borderColor: "#FFFFFF",
            borderWidth: 1, 
            legend: {
              display: true,
              //position: 'left',
              usePointStyle: true,
            },
          }
          ]
        },
        options: {
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var value = tooltipItem.datasetIndex == 0 ? data.datasets[0].data[tooltipItem.index] : tooltipItem.datasetIndex == 1 ? data.datasets[1].data[tooltipItem.index] : data.datasets[2].data[tooltipItem.index];
                   return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            }
          },  
          scales: {
            yAxes: [{
              ticks: { beginAtZero: true,
                 callback: function (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              } },
              gridLines: {
                drawBorder: false,
              },
              stacked: true
            }],
            xAxes: [{
              gridLines: {
                display: false
              },
              stacked: true,
              barPercentage: 0.3
            }]
          },
          legend: {
            position: 'bottom',
            onClick: (e) => e.stopPropagation()
          },
          plugins: {
            labels: {
              render: () => { }
            }
          },
          "animation": {
            "duration": 1,
            "onComplete": function () {
              var chartInstance = this.chart,
                ctx = chartInstance.ctx;

              ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';
              ctx.fillStyle = "rgba(0, 0, 0, 1)";

              this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);                
                if (i == 2) {
                  meta.data.forEach(function (bar, index) {                  
                    var data = dataset.data[index];
                    ctx.fillText(data.toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), bar._model.x - 5, bar._model.y - 5,);
                  });
                }
              });
            }
          },
        }
      };
      const chart = new Chart(this.canvas.nativeElement, optionsBarGreen);
    }
  }

}

@Component({
  selector: 'app-sales-exposure-chart-line',
  template: `<div class="row">
  <div style="width:400px;">
  <canvas height="250px" id="linechart" ></canvas></div>
</div>`
  //styleUrls: ['./sales-exposure-chart.component.css']
})

export class SalesExposureChartLineComponent implements OnInit {
  lineChart: any;
  @Input() data: any;

  constructor(private reportService: ReportsService, private router: Router, private route: ActivatedRoute) { }
  ngOnInit(): void {
    this.linechartSetup(this.data);
  }

  // ngAfterViewInit(){
  //    this.linechartSetup(this.result);
  //  }

  private linechartSetup(data: any) {

    if (this.lineChart) this.lineChart.destroy();
    if (data != undefined) {
      this.lineChart = new Chart('linechart', {
        type: 'line',
        data: {
          labels: ['2018', '2019'],
          datasets: [{
            label: 'PF',
            data: [data.pfScope1, data.pfScope2],
            fill: false,
            borderColor: "#001EFF",
            pointBackgroundColor: "#001EFF"
          },
          {
            label: 'BM',
            data: [data.bmScope1, data.bmScope2],
            fill: false,
            borderColor: "#7F7F7F",
            pointBackgroundColor: "#7F7F7F"
          }
          ]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: { beginAtZero: true }
            }]
          },
          legend: {
            position: 'bottom',
            onClick: (e) => e.stopPropagation()
          }
        }
      });
    }
  }
}

@Component({
  selector: 'app-bar-chart',
  template: `<div class="row">
  <div class="column">
  <canvas #canvas height="400px" width="400px" id="simplebarchart" ></canvas>
  </div>
</div>`
  //styleUrls: ['./sales-exposure-chart.component.css']
})

export class BarChartComponent implements OnInit {
  @Input() chartType: string;
  @Input() data: number[];
  @Input() labels: string[];
  @Input() PortfolioType:string;
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement> | undefined;

  stackBar: any;
  constructor() { }
  ngOnInit(): void {

   
    if(this.labels[0]=='PF')
    { 
      if (this.PortfolioType=='B')
    this.labels[0]='IDX'
     
    }
  

    this.barchartSetup(this.data, this.labels,this.chartType);
  }
 
  barchartSetup(data: number[], labelNames: string[],chartType:string) { 
    if (this.stackBar) this.stackBar.destroy();
    if (data != undefined && labelNames != undefined) {
      Chart.plugins.register({
        beforeDraw: function (chartInstance) {
          const ctx = chartInstance.chart.ctx;
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
        }
      });    
      console.log(labelNames);  
      //this.stackBar = new Chart('simplebarchart', {
      const optionsAppBar = {
        type: 'bar',
        data: {
          labels: labelNames,
          datasets: [
            { 
              data: data,
              backgroundColor: ["#001EFF", "#001EFF"],
              borderColor: "#FFFFFF",
            borderWidth: 1
            }
          ]
        },       
        options: {
          tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                   var value = data.datasets[0].data[tooltipItem.index];
                   return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            }
          },         
          scales: {
            yAxes: [
              {
                gridLines: {
                  drawBorder: false
                },
                ticks: {
                  beginAtZero: true,
                  callback: function (value) {
                     if(chartType == "Singlevalue"){
                      return value + " %";
                    } 
                    else{
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");}
                  }                  
                }
              }             
            ],
            xAxes: [{
              gridLines: {
                display: false
                
              },
              barPercentage: 0.7
            }]
          },
          legend: {
            display: false
          },
          plugins: {
            labels: {
              render: () => { }
            }
          }
        }
      };
      const chart = new Chart(this.canvas.nativeElement, optionsAppBar);
    }
  }
}


@Component({
  selector: 'app-jointbar-chart',
  template: `<div class="row">
    <div class="column">
    <canvas #canvas height="400px" width="400px" id="jointbarchart" ></canvas></div>
  </div>`
  //styleUrls: ['./sales-exposure-chart.component.css']
})

export class JointBarChartComponent implements OnInit {


  @Input() inpuData: any;
  @Input() chartType: string;
  @ViewChild('canvas', {static: true}) canvas: ElementRef<HTMLCanvasElement> | undefined;  
  Segment_compBar: any;
  inputChartData: any;  
  @Input() PortfolioType:string;
  lablestab:string;
  constructor() { }

  ngOnInit(): void {

    if (this.PortfolioType=='B')
    this.lablestab='IDX'
     else  this.lablestab='PF'

    this.SetUpChartData(this.chartType,this.inpuData,this.lablestab)
    this.jointBarchartSetup(this.inpuData,this.chartType);
  }

  private SetUpChartData(type: string,data: any,lablestab:string){

    if(type == "twoJoint"){
      this.inputChartData = {
        labels: [
          lablestab,
          "BM"
        ],
        datasets: [
          {
            label: "Weights incl. Multi Utilities",
            backgroundColor: "#001EFF",
            borderColor: "#FFFFFF",
            borderWidth: 1, 
            data: [data.find(i => i.label == "pfwMU").data, data.find(i => i.label == "bmwMU").data]
          },
          {
            label: "Weights excl. Multi Utilities",
            backgroundColor: "#7F7F7F",
            borderColor: "#FFFFFF",
            borderWidth: 1, 
            data: [data.find(i => i.label == "pfwoMU").data, data.find(i => i.label == "bmwoMU").data]
          }          
        ]
      }
    }
    if(type == "threeJoint"){
      this.inputChartData = {
        labels: [
          lablestab,
          "BM"
        ],
        datasets: [
          {
            label: "Tier 1/2/3",
            backgroundColor: "#001EFF",
            borderColor: "#FFFFFF",
            borderWidth: 1,            
            data: [data.find(i => i.label == "Tier_1/2/3_PF").data, data.find(i => i.label == "Tier_1/2/3_BM").data]
          },
          {
            label: "Tier 1/2",
            backgroundColor: "#7F7F7F",
            borderColor: "#FFFFFF",
            borderWidth: 1,  
            data: [data.find(i => i.label == "Tier_1/2_PF").data, data.find(i => i.label == "Tier_1/2_BM").data]
          },
          {
            label: "EU Taxonomy estimate",
            backgroundColor: "#99A5FF",
            borderColor: "#FFFFFF",
            borderWidth: 1,  
            data: [data.find(i => i.label == "EU Taxonimy estimate_PF").data, data.find(i => i.label == "EU Taxonimy estimate_BM").data]
          }
        ]
      }
    }
  }
  


  private jointBarchartSetup(data: any,type: string) {
    if (this.Segment_compBar) this.Segment_compBar.destroy();
    if (data != undefined) {
      Chart.plugins.register({
        beforeDraw: function(chartInstance) {
          const ctx = chartInstance.chart.ctx;
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
        }
      });
      const Segment_compBar = {
        type: "bar",
        data: this.inputChartData,

        options: {
          responsive: true,
          legend: {
            position: "bottom"
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: false
              },
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  if(type == "twoJoint"){
                    return value + " %";
                  }
                  else if(type == "threeJoint"){
                    return value + " %";
                  } 
                  else{
                    return value ;
                  }
                  
                }
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              },
              // categoryPercentage: 1.0,
              barPercentage: 0.9
            }]
          },
          plugins: {
            labels: {
              render: () => { }
            }
          }
        }
      };
      const chart = new Chart(this.canvas.nativeElement, Segment_compBar);
    }
  }



}


@Component({
  selector: 'app-grouped-horizontal-bar-chart',
  template: `<div *ngIf="calculationType =='Industry'" class="row" >
    <div class="column">
    <canvas height="341px" width="400px" id="industry" ></canvas></div>
  </div>
  <div *ngIf="calculationType =='Country'" class="row" >
    <div class="column">
    <canvas height="350px" width="400px" id="country" ></canvas></div>
  </div>`
})

export class GroupedHorizontalBarChartComponent implements OnInit {


  @Input() pf_data: any;
  @Input() bm_data: any;
  @Input() labels: string[];
  @Input() calculationType: string;
  @Input() PortfolioType:string;
  lablestab:string;
  stackBar: any;
  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {

    if (this.PortfolioType=='B')
    this.lablestab='IDX'
     else  this.lablestab='PF'

    if (this.calculationType == 'Industry')
      this.groupedBarchartSetup(this.pf_data, this.bm_data, this.labels, 'industry',this.PortfolioType,this.lablestab);
    if (this.calculationType == 'Country')
      this.groupedBarchartSetup(this.pf_data, this.bm_data, this.labels, 'country',this.PortfolioType,this.lablestab);
     
    
  }
  private groupedBarchartSetup(pf_data: any, bm_data: any, labelData: any, id: string,PortfolioType:string,lablestab:string) {

    if (this.stackBar) this.stackBar.destroy();
    if (pf_data != undefined && bm_data != undefined && labelData != undefined) {
      this.stackBar = new Chart(id, {
        type: 'horizontalBar',
        data: {
          labels: labelData,
          datasets: [
            {
              label: lablestab+' Weight',
              data: pf_data,
             // backgroundColor: '#ed7d31',orange
              backgroundColor: '#001EFF',
              borderColor: "#FFFFFF",
            borderWidth: 1,
            },
            {
              label: 'BM Weight',
              data: bm_data,
              backgroundColor: '#7F7F7F',
              borderColor: "#FFFFFF",
            borderWidth: 1
            }
          ]
        },
        options: {

          legend: {
            display: true,
            position: 'bottom',
          },
          showLines: false,
          responsive: true,
          maintainAspectRatio: false,
          devicePixelRatio: 3,
          scales: {
            xAxes: [{
              gridLines: {
                drawBorder: false
              },
              afterBuildTicks: function(scale){
                if(labelData.length == 1 && scale.ticks.length > 2){
                  if(parseFloat(pf_data) > parseFloat(bm_data)){
                   scale.ticks = [0.0,pf_data];
                  }else scale.ticks = [0.0,bm_data];
                }
              },
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  if(Number.isInteger(value))
                    return value + ".0% ";
                  else return value + "% ";
                 
                },
              }
            }],
            yAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                lineHeight: 1,
                padding: -8,
              },
              barPercentage: 0.9,
              categoryPercentage: 0.55,
            }]
          },
          plugins: {
            labels: {
              render: () => { }
            }
          }
        }
      });
    }
  }
}


@Component({
  selector: 'app-attribution-chart',
  template: `<div *ngIf="calculationType=='waciattributionEffect'">
 
  <canvas height="350px" width="450px" id="waciattreffectchart" ></canvas>
  </div>

<div *ngIf="calculationType=='waciattributionEffectByScope'">
  
  <canvas height="350px" width="450px" id="waciattreffectbyscopechart" ></canvas>
</div>
<div *ngIf="calculationType=='evicattributionEffect'">
 
  <canvas height="350px" width="450px" id="evicattreffectchart" ></canvas>
  </div>

<div *ngIf="calculationType=='evicattributionEffectByScope'">
  
  <canvas height="350px" width="450px" id="evicattreffectbyscopechart" ></canvas>
</div>`
})

export class AttributionEffectChartComponent implements OnInit {
  @Input() waciAttributionEffectData: any;
  @Input() waciAttributionEffectByScopeData: any;
  @Input() calculationType: string ;
  attrEffectChart: any;
  attrEffectScopeChart: any;
  @Input() PortfolioType:string;
  lablestab:string;
  

  constructor() { }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    if (this.PortfolioType=='B')
    this.lablestab='IDX'
     else  this.lablestab='PF'

    if (this.calculationType === "waciattributionEffect"){
      this.AttributionEffectChartSetup(this.waciAttributionEffectData, "waciattreffectchart",this.lablestab);
    }
    if (this.calculationType === "waciattributionEffectByScope"){
      this.AttributionEffectByScopeChartSetup(this.waciAttributionEffectByScopeData, "waciattreffectbyscopechart",this.lablestab);    
    }
    if (this.calculationType === "evicattributionEffect"){
      this.AttributionEffectChartSetup(this.waciAttributionEffectData, "evicattreffectchart",this.lablestab);
    }
    if (this.calculationType === "evicattributionEffectByScope"){
      this.AttributionEffectByScopeChartSetup(this.waciAttributionEffectByScopeData, "evicattreffectbyscopechart",this.lablestab);    
    }

    
  }

  private AttributionEffectChartSetup(data: any, id: string,lablestab:string) {
    if (this.attrEffectChart) this.attrEffectChart.destroy();
    if (data != undefined) {
      Chart.plugins.register({
        beforeDraw: function(chartInstance) {
          const ctx = chartInstance.chart.ctx;
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
        }
      });
      const attrEffectChart = {
        type: 'bar',
        data: {
          labels: [
            ["Total "+lablestab+" vs BM gap", "(%)"],
            ["Sector allocation effect", "(%)"],
            [" Company selection effect", "(%)"]
          ],
          datasets: [
            {
              data: [data.find(i => i.label == "totalPFvsBM_gapAF").data, data.find(i => i.label == "sectorAllocEffect").data, data.find(i => i.label == "companySelectEffect").data],
              backgroundColor: "#001EFF",
              borderColor: "#FFFFFF",
            borderWidth: 1
            }
          ]
        },
        options: {
          scales: {
            yAxes: [
              {
                gridLines: {
                  drawBorder: false
                },
                ticks: {
                  beginAtZero: true, 
                  callback: function (value) {
                    return value + " %";
                  }
                }
              }
            ],

            xAxes: [{
              ticks: {
                minRotation: 0,
                maxRotation: 0,
              },
              gridLines: {
                display: false
              },
              barPercentage: 0.7
            }]
          },
          legend: {
            display: false
          },
          plugins: {
            labels: {
              render: () => { }
            }
          }
        }
      };
      const chart = new Chart(id, attrEffectChart);
    }
  }

  private AttributionEffectByScopeChartSetup(data: any, id: string,lablestab:string) {
    if (this.attrEffectScopeChart) this.attrEffectScopeChart.destroy();
    if (data != undefined) {
      Chart.plugins.register({
        beforeDraw: function(chartInstance) {
          const ctx = chartInstance.chart.ctx;
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
        }
      });
      const attrEffectScopeChart =  {
        type: "bar",
        data: {
          labels: [
            ["Total "+lablestab+" vs BM gap", "(%)"],
            ["Sector allocation effect", "(%)"],
            [" Company selection effect", "(%)"]
          ],
          datasets: [
            {
              label: "Scope 1",
              backgroundColor: "#001EFF",
              borderColor: "#FFFFFF",
            borderWidth: 1,
              data: [data.find(i => i.label == "totalPFvsBM_gapScope1").data, data.find(i => i.label == "sectorAllocEffectScope1").data, data.find(i => i.label == "companySelectEffectScope1").data]
            },
            {
              label: "Scope 2",
              backgroundColor: "#7F7F7F",
              borderColor: "#FFFFFF",
            borderWidth: 1,
              data: [data.find(i => i.label == "totalPFvsBM_gapScope2").data, data.find(i => i.label == "sectorAllocEffectScope2").data, data.find(i => i.label == "companySelectEffectScope2").data]
            }
          ]
        },

        options: {
          responsive: true,
          legend: {
            position: "bottom"
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: false
              },
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  return value + " %";
                }             
              }
            }],
            xAxes: [{
              ticks: {
                maxRotation: 0,
                minRotation: 0,
              },
              gridLines: {
                display: false
              },
              // categoryPercentage: 1.0,
              barPercentage: 0.9
            }]
          },
          plugins: {
            labels: {
              render: () => { }
            }
          }
        }
      };
      const chart = new Chart(id, attrEffectScopeChart);
    }
  }


}


@Component({
  selector: 'app-grouped-vertical-bar-chart',
  template: `<div *ngIf="calculationType =='WaciSector'" class="row" >
    <div class="column">
    <canvas height="400px" width="900px" id="sectorCtb" ></canvas></div>
  </div>
  <div *ngIf="calculationType =='EvicSector'" class="row" >
    <div class="column">
    <canvas height="400px" width="900px" id="sectorCtbEvic" ></canvas></div>
  </div>`
 })

export class GroupedVerticalBarChartComponent implements OnInit {


  @Input() pf_data: any;
  @Input() bm_data: any;
  @Input() labels: string[];
  @Input() calculationType:string;
  @Input() PortfolioType:string;
  lablestab:string;
  stackBar: any;
  constructor() { }

  ngOnInit(): void {
    //this.groupedBarchartSetup(this.pf_data, this.bm_data, this.labels);
  }
  ngAfterViewInit(): void {

    if (this.PortfolioType=='B')
    this.lablestab='IDX'
     else  this.lablestab='PF'

     this.labels=this.labels.map(seg => seg.replace('Telecommunications', 'Telecom'));
     this.labels=this.labels.map(seg => seg.replace('Consumer Discretionary', 'Consumer Discr.'));
     console.log(this.labels+'rashmi');

    if (this.calculationType == 'WaciSector')
      this.groupedBarchartSetup(this.pf_data, this.bm_data, this.labels, 'sectorCtb',this.lablestab);
    if (this.calculationType == 'EvicSector')
      this.groupedBarchartSetup(this.pf_data, this.bm_data, this.labels, 'sectorCtbEvic',this.lablestab);
  }
  private groupedBarchartSetup(pf_data: any, bm_data: any, labelData: any,id:any,lablestab:string) {

    if (this.stackBar) this.stackBar.destroy();
    if (pf_data != undefined && bm_data != undefined && labelData != undefined) {
      this.stackBar = new Chart(id, {
        type: 'bar',
        data: {
          labels: labelData,
          datasets: [
            {
              label: lablestab,
              data: pf_data,
              backgroundColor: '#001EFF',
              borderColor: "#FFFFFF",
            borderWidth: 1,
            },
            {
              label: 'BM',
              data: bm_data,
              backgroundColor: '#7F7F7F',
              borderColor: "#FFFFFF",
            borderWidth: 1
            }
          ]
        },
        options: {
          responsive: true,
          legend: {
            position: "bottom"
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: false
              },
              ticks: {
                beginAtZero: true,               
                callback: function (value) {
                  return value;
                }
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              },              
              barPercentage: 0.9,
              ticks: {
                callback: function(label, index, labels) {
                  if (/\s/.test(label)) {
                    return label.split(" ");
                  }else{
                    return label;
                  }              
                },
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0
              }
            }]
          },
          plugins: {
            labels: {
              render: () => { }
            }
          }
        }
      });
    }
  }
}

@Component({
  selector: 'app-line-chart',
  template: `<div *ngIf="calculationType =='waci'" class="row" >
                <div class="column">
                    <canvas height="350px" width="400px" id="waci" ></canvas></div>
                </div>
            <div *ngIf="calculationType =='evicae'" class="row" >
                <div class="column">
                    <canvas height="350px" width="400px" id="evicae" ></canvas>
            </div>
 </div>`
})

export class LineChartComponent implements OnInit {
  lineChart: any;
  @Input() pfData: any;
  @Input() bmData: any;
  @Input() labels: any;
  @Input() calculationType: string;
  @Input() PortfolioType:string;
  lablestab:string;
  constructor(private reportService: ReportsService, private router: Router, private route: ActivatedRoute) { }
  ngOnInit(): void {
    //this.linechartSetup(this.pfData, this.bmData, this.labels);
  }

  ngAfterViewInit() {
    if (this.PortfolioType=='B')
    this.lablestab='IDX'
     else  this.lablestab='PF'
    if (this.calculationType == 'waci')
      this.linechartSetup(this.pfData, this.bmData, this.labels, 'waci',this.lablestab);
    if (this.calculationType == 'evicae')
      this.linechartSetup(this.pfData, this.bmData, this.labels, 'evicae',this.lablestab);
  }

  private linechartSetup(pfdata: any, bmdata: any, labelData: any, id: string,lablestab:string) {

    if (this.lineChart) this.lineChart.destroy();
    if (pfdata != undefined && bmdata != undefined && labelData != undefined) {
      this.lineChart = new Chart(id, {
        type: 'line',
        data: {
          labels: labelData,
          datasets: [{
            label: lablestab,
            data: pfdata,
            fill: false,
            borderColor: "#001EFF"
          },
          {
            label: 'BM',
            data: bmdata,
            fill: false,
            borderColor: "#7F7F7F"
          }
          ]
        },
        options: {
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: false
              },
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  return value + ".0";
                }
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          },
          elements: {
            point: {
              radius: 0
            }
          },
          legend: {
            position: 'bottom',
            onClick: (e) => e.stopPropagation()
          }
        }
      });
    }
  }
}

@Component({
  selector: 'app-grouped-bar-chart',
  template: `<div *ngIf="calculationType =='waci'" class="row" >
  <div class="column">
      <canvas height="350px" width="400px" id="waci_cagr" ></canvas></div>
  </div>
<div *ngIf="calculationType =='evicae'" class="row" >
  <div class="column">
      <canvas height="350px" width="400px" id="evicae_cagr" ></canvas>
</div>
</div>`
})

export class GroupedBarChartComponent implements OnInit {


  @Input() total_data: any;
  @Input() labels: string[];
  @Input() wYears: string[];
  stackBar: any;
  @Input() calculationType: string;
  constructor() { }

  ngOnInit(): void {
    //this.groupedBarchartSetup(this.total_data, this.labels);
  }

  ngAfterViewInit() {
    if (this.calculationType == 'waci')
      this.groupedBarchartSetup(this.total_data, this.labels, this.wYears, 'waci_cagr');
    if (this.calculationType == 'evicae')
      this.groupedBarchartSetup(this.total_data, this.labels, this.wYears, 'evicae_cagr');
  }
  private groupedBarchartSetup(data: any, labelData: any,waciYears:string[], id: string) {

    if (this.stackBar) this.stackBar.destroy();
    if (data != undefined && labelData != undefined) {
      this.stackBar = new Chart(id, {
        type: 'bar',
        plugins: [{
          afterDraw: chart => {
            let ctx = chart.chart.ctx;
            ctx.save();
            let xAxis = chart.scales['x-axis-0'];
            let xCenter = (xAxis.left + xAxis.right) / 2;
            let yBottom = chart.scales['y-axis-0'].bottom;
            ctx.textAlign = 'center';
            ctx.font = '12px Arial';
            ctx.fillText(chart.data.categories[0], (xAxis.left + xCenter) / 2, yBottom + 40);
            ctx.fillText(chart.data.categories[1], (xCenter + xAxis.right) / 2, yBottom + 40);
            ctx.strokeStyle = 'gray';
            [xAxis.left, xCenter, xAxis.right].forEach(x => {
              ctx.beginPath();
              ctx.moveTo(x, yBottom);
              ctx.lineTo(x, yBottom + 40);
              ctx.stroke();
            });
            ctx.restore();
          }
        }],
        data: {
          labels: labelData,
          categories: [waciYears[0] + '-' + waciYears[5], parseInt(waciYears[5]) -1 + '-' + waciYears[5]],
          datasets: [
            {
              label: 'Total',
              data: [data.find(i => i.label == "AllHY_PF_scope12").data, data.find(i => i.label == "AllHY_BM_scope12").data, data.find(i => i.label == "Last2HY_PF_scope12").data, data.find(i => i.label == "Last2HY_BM_scope12").data],
              backgroundColor: '#001EFF',
              borderWidth: 1,
              borderColor: "#FFFFFF"
            },
            {
              label: 'Scope 1',
              data: [data.find(i => i.label == "AllHY_PF_scope1").data, data.find(i => i.label == "AllHY_BM_scope1").data, data.find(i => i.label == "Last2HY_PF_scope1").data, data.find(i => i.label == "Last2HY_BM_scope1").data],
              backgroundColor: '#7F7F7F',
              borderColor: "#FFFFFF",
            borderWidth: 1
            },
            {
              label: 'Scope 2',
              data: [data.find(i => i.label == "AllHY_PF_scope2").data, data.find(i => i.label == "AllHY_BM_scope2").data, data.find(i => i.label == "Last2HY_PF_scope2").data, data.find(i => i.label == "Last2HY_BM_scope2").data],
              backgroundColor: '#99A5FF',
              borderColor: "#FFFFFF",
            borderWidth: 1
            }
          ]
        },
        options: {
          responsive: true,
          legend: {
            position: "bottom",
            labels: {
              padding: 30
            }
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: false
              },
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  return value + "%";
                }
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              },
              barPercentage: 0.9
            }]
          },
          plugins: {
            labels: {
              render: () => { }
            }
          }
        }
      });
    }
  }
}

@Component({
  selector: 'app-stacked-bar-chart',
  template: `<div *ngIf="calculationType =='oil'" class="row" >
  <div class="column">
      <canvas height="350px" width="400px" id="crpi_oil" ></canvas></div>
  </div>
<div *ngIf="calculationType =='coal'" class="row" >
  <div class="column">
      <canvas height="350px" width="400px" id="crpi_coal" ></canvas>
</div>
</div>`
})
export class VerticalStackedBarChartComponent implements OnInit {
  stackBar: any;
  @Input() oildata: any;
  @Input() coaldata: any;
  @Input() calculationType: string;

  constructor(private reportService: ReportsService, private router: Router, private route: ActivatedRoute) { }
  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.calculationType == 'oil')
      this.chartSetup(this.oildata, this.coaldata, 'crpi_oil');
    if (this.calculationType == 'coal')
    this.chartSetup(this.oildata, this.coaldata, 'crpi_coal');
  }
  chartSetup(oil_data: any,coal_data:any,id:any): void {
    if (this.stackBar) this.stackBar.destroy();
    if (oil_data != undefined && coal_data != undefined) {
      this.stackBar = new Chart(id, {
        type: 'bar',
        data: {
          labels: ['Coal','Oil & gas'],
          datasets: [
            {
              label: 'Covered',
              data: [coal_data["Covered"], oil_data["Covered"]] ,
              backgroundColor: '#001EFF',
              borderColor: "#FFFFFF",
            borderWidth: 1
            },
            {
              label: 'Non-Covered',
              data:[coal_data["Non-Covered"], oil_data["Non-Covered"]],
              backgroundColor: '#7F7F7F',
              borderColor: "#FFFFFF",
            borderWidth: 1
            }
          ]
        },
        options: {
          responsive: true,
          legend: {
            position: "bottom"
          },
          
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: false
              },
              stacked: true,
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  return value + "%";
                }
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              },
              stacked: true,
              barPercentage: 0.75
            }]
          },
          plugins: {
            labels: {
              render: () => { }
            }
          },
          "animation": {
            "duration": 1,
            "onComplete": function () {
              var chartInstance = this.chart,
                ctx = chartInstance.ctx;
              ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';
              ctx.fillStyle = "rgba(0, 0, 0, 1)";
              this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                  meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];                   
                      ctx.fillText(data+"%", bar._model.x , bar._model.y+10);
                  });
              });
            }
          },
      }
    });
    }

  }
}
