import { Component,EventEmitter,Output,ViewChild,AfterViewInit, Inject } from '@angular/core';
import {CustomTableHoldingsComponent} from '../custom-table-holdings/custom-table-holdings.component';
import { CloseHoldingService } from '../../services/close-holdings.service';
import {FilterHoldingsCurrencyTypePipe} from '../../filter.pipe';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { environment } from '../../../environments/environment';
import {CloseHoldingPageModel} from '../../models/close-holding-page-model';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {ColumnChooserService} from '../../services/column-chooser.service';
import {CustomColumnsModel} from '../../models/custom-columns-model';
import { Daterangepicker,DaterangepickerConfig } from 'ng2-daterangepicker';
import * as AWS from 'aws-sdk';
import * as AWSCognito from 'amazon-cognito-identity-js';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DateService } from '../../services/date.service';
import {DateDisplayService} from '../../services/date.display.service';
import { BiService } from '../../services/BiService.service';
import{CurrencyService}from '../../services/currency.service';
import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'close-holdings-position-grid',
    templateUrl: './close-holdings-position-grid.component.html'
})
export class CloseHoldingsPositionGridComponent {
    calendarDate : any;
    public singlePicker = {
       // startDate:this.calendarDate,
        locale: { format: 'MM/DD/YYYY' },
        alwaysShowCalendars: false,
        singleDatePicker:true,
        autoApply: true,
        linkedCalendars: false,
      }    
      gridCustomColumns: any[];
      gridData:any;
      s3Credentials:any;
      passphrase: string = "EncryptionatRest";
    @ViewChild(CustomTableHoldingsComponent,{static: false}) CustomTableHoldingsComponent; 
    @Output() notifyPageModel : EventEmitter<CloseHoldingPageModel> = new EventEmitter<CloseHoldingPageModel>();
    minimumDate: Date = new Date(1999,1,1);
    maximumDate: Date = new Date(2020, 5, 22);
    HoldingsGridLoader:boolean =true;    

    constructor(private dateDisplayService: DateDisplayService,private dateService : DateService,private closeHoldingService: CloseHoldingService 
        ,private indexMarkerService:IndexMarkerService,
         private exportService: ExportToExcelService,private publishIndexMarkerService:PublishIndexMarkerService,
          private columnChooserService:ColumnChooserService,public dialog: MatDialog,
          private biService: BiService,private currencyService:CurrencyService) { }
    
      
      indexMarkers : any;
      timePeriod : any;
      currencyCode : string;
      dataTableRawData : any;
      datatable : any;
      topBottomFlag : any;
      currentIndex : any;
    
    ngOnInit() {
      this.calendarDate = this.dateService.getCalendarDate().end; 
      
      this.indexMarkers = this.indexMarkerService.getIndexMarkers();
      this.currencyCode =this.currencyService.getCurrencyCode();  
      this.currencyService.currencyCodePublish().subscribe(currency=>{        
        this.onNotifyCurrency(currency);
      }); 
      var pageModel = new CloseHoldingPageModel();      
      pageModel.asofdate = this.calendarDate;   
      pageModel.topBottomFlag = true;              
      this.notifyPageModel.emit(pageModel);  

      this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
        this.indexMarkers = result;
        this.gridSetup();
        });
      this.onNotify(this.dateService.getCalendarDate());
    }  
    ngAfterViewInit(){
        this.calendarDate = this.dateService.getCalendarDate().end.substring(0,10); 
        this.gridSetup();
    }
    update(closeHoldingPageModel:CloseHoldingPageModel){
        this.topBottomFlag = closeHoldingPageModel.topBottomFlag; 
        this.currencyCode=closeHoldingPageModel.currencyCode;
        this.calendarDate = closeHoldingPageModel.asofdate;       
        this.gridSetup();
    }
    refreshGrid(customColumns:CustomColumnsModel[]){
        var event: any = {eventName: "Column chooser clicked", inputParameters: [{Name : "ColumnName", Value :JSON.stringify(customColumns)}]};
        this.biService.addEvent(event); 
        this.gridCustomColumns = customColumns;
        this.CustomTableHoldingsComponent.bind(this.gridData,this.gridCustomColumns); 
    }
    gridSetup() {   
    this.columnChooserService.getCloseHoldingsCustomColumns().then(customs=>{
      this.gridCustomColumns = customs;
    });    
    
   
     
    this.closeHoldingService.getPositions(this.calendarDate,this.indexMarkers ,this.currencyCode , "").then(data =>{                          
        this.HoldingsGridLoader =false;          
        if (Object.keys(data).length){
            this.currentIndex =data[0].indexMarker;                      
        }
        else{
            this.currentIndex ="";            
        }
       
        this.gridData = data;
        this.CustomTableHoldingsComponent.bind(this.dateDisplayService.getArrayDateDisplay(this.gridData,"asOfDate"),this.gridCustomColumns);   
     },onerror =>{
        this.HoldingsGridLoader =false;       
     }); 
    } 

    private filterData(data:any,currencyFilter:string):any{           
        var currencyFilterPipe = new FilterHoldingsCurrencyTypePipe();        
        return  currencyFilterPipe.transform(data,this.currencyCode);  
    }

    
    PopupWidget (): void{    
        var event: any = {eventName: "Grid help dialog open"};
        this.biService.addEvent(event);     
        let dialogRef = this.dialog.open(CloseHoldingsPositionGridComponentDialog, {
          width: '800px',
          height: '300px'     
        });
    }

    SortGridByColumn(column:any)
    {     
       this.closeHoldingService.getPositions(this.calendarDate,this.indexMarkers ,this.currencyCode , column).then(data =>{                          
        this.HoldingsGridLoader =false;          
        if (Object.keys(data).length){
            this.currentIndex =data[0].indexMarker;                      
        }
        else{
            this.currentIndex ="";            
        }
       
        this.gridData = data;
        this.CustomTableHoldingsComponent.bind(this.dateDisplayService.getArrayDateDisplay(this.gridData,"asOfDate"),this.gridCustomColumns);   
     },onerror =>{
        this.HoldingsGridLoader =false;       
     }); 
    }

    onNotify(value:any) {          
       var pageModel = new CloseHoldingPageModel();
        pageModel.topBottomFlag =this.topBottomFlag ;
        pageModel.asofdate = value.end;  
        pageModel.currencyCode = this.currencyCode;           
        this.notifyPageModel.emit(pageModel);
        this.gridSetup();
    }
    onNotifyCurrency(currency:any){                     
        this.currencyCode =currency;            
        var pageModel = new CloseHoldingPageModel();
        pageModel.topBottomFlag =this.topBottomFlag ;
        pageModel.asofdate = this.calendarDate;  
         pageModel.currencyCode = this.currencyCode;          
        this.notifyPageModel.emit(pageModel);
        this.gridSetup(); 
        }
    

     singleSelect(value: any) {
        this.calendarDate = value.start;
        this.onNotify(value);
    }

    ExportXls(event):void{ 
        var event: any = {eventName: "Export Holdings grid", inputParameters: [{Name : "Exported IndexMarker" , Value: this.currentIndex}]};
        this.biService.addEvent(event);
        AWS.config.update({
            region: environment.region
        }); 
        var that = this;
        var visibleColumns = this.columnChooserService.getVisibleColumnsNames(this.gridCustomColumns); 
        this.s3Credentials = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('RestMessage'),this.passphrase).toString(CryptoJS.enc.Utf8));   
        this.closeHoldingService.getGridHoldingstData(this.calendarDate,this.indexMarkers,visibleColumns).then(dataUrl=>{
            var s3Url = dataUrl.url;
            AWS.config.update({
             region: environment.region,
             accessKeyId: this.s3Credentials.AccessKeyId,
             secretAccessKey: this.s3Credentials.SecretKey,
             sessionToken: this.s3Credentials.SessionToken
          });
        var s3 = new AWS.S3({
        });
          var urlParts = s3Url.split("/");

          var getParams = {
            Bucket: urlParts[urlParts.length - 3],
            Key: urlParts[urlParts.length - 2]+"/"+urlParts[urlParts.length - 1]
         }
         s3.getObject(getParams,function(err,data){
            if(err){
              console.log(err);
              return;
             }else{
                that.exportService.exportData(JSON.parse(data.Body.toString()),"HoldingsRawData");
             }
         });
     
    });
  }

}

@Component({
    selector: 'close-holdings-position-grid-dialog',
    templateUrl: './close-holdings-position-grid.component.widget.dialog.html'
})
export class CloseHoldingsPositionGridComponentDialog {
    performanceTextWidth:any;
    constructor(
    public dialogRef: MatDialogRef<CloseHoldingsPositionGridComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
       
     }
  
    onClose(): void {
        this.dialogRef.close();
  }
}