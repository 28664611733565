import { Injectable } from "@angular/core";
import { saveAs } from 'file-saver/FileSaver';
import * as moment from "moment";
import { DateService } from '../services/date.service';
import { DatePipe } from '@angular/common';

@Injectable()
export class ExportToExcelService{
  constructor(private dateService: DateService, public datepipe: DatePipe){        
    }
    

    exportData(rawJsonArray:any,filename:string):void{
        var dataToExport:string="";
        
        
             if(rawJsonArray.indexLevelData!=undefined) 
                    {
                       for(var index in  rawJsonArray )
                       {
                          var appendDataToExport= this.transformJsonArrayToTabularData(rawJsonArray[index]);
                          dataToExport +=index.substring(0,1).toUpperCase()+index.replace("Data","").substring(1)+"\n"+appendDataToExport +"\n";
                       }
                    }
                    else{
                     dataToExport = this.transformJsonArrayToTabularData(rawJsonArray);
                    
                   }
                if(null == dataToExport || "" == dataToExport){
                    return;
                }
       
        this.exportTabularData(dataToExport,filename);
    }

    exportRawCSVData(rawCSV:any,filename:string):void{
        if(null == rawCSV || "" == rawCSV){
            return;
        }
        this.exportTabularData(rawCSV,filename);
    }

    SaveJsonFile(data: string,filename:string):void{
        let blob = new Blob([data], { type: "text/json" });
        saveAs(blob, filename);
    }
    
    private exportTabularData(data: string,filename:string):void{
        let blob = new Blob([data], { type: "application/vnd.ms-excel;" });
        saveAs(blob, filename);
    }

  private transformJsonArrayToTabularData(rawJsonArray: any): string {
    if ("" == rawJsonArray || 0 >= rawJsonArray.length) {
      return null;
    }
    var fields: string[] = [];
    var dataToExport: string = "";
    for (var prop in rawJsonArray[0]) {
      fields.push(prop);
      dataToExport = dataToExport + prop + "\t";
    }
    dataToExport = dataToExport + "\n";
    for (var rowCount = 0; rowCount < rawJsonArray.length; rowCount++) {
      for (var columnCount = 0; columnCount < fields.length; columnCount++) {
        if ("ASOFDATE" == fields[columnCount].toUpperCase()) {
          var asofDateValue = rawJsonArray[rowCount][fields[columnCount]];
          var dateFormat = this.dateService.getDateFormat();
          if (dateFormat == 'UK') {
            dataToExport = dataToExport + moment(asofDateValue).format('DD/MM/YYYY');
          }
          else {
            dataToExport = dataToExport + moment(asofDateValue).format('MM/DD/YYYY');
          }
        } else {
          dataToExport = dataToExport + rawJsonArray[rowCount][fields[columnCount]];
        }
        dataToExport = dataToExport + "\t";
      }
      dataToExport = dataToExport + "\n";
    }
    return dataToExport;
  }
}
