import { Injectable } from '@angular/core';
// tslint:disable-next-line:import-blacklist

import { BehaviorSubject, Observable, of, from, Subject } from 'rxjs';
import { NewCoverageConfigForm } from '../models/coverageconfig';
import { NewVanguardConfigurationsForm } from '../models/coverageconfig';
import { map } from 'rxjs/operators';
import { Coverageconfig } from '../models/coverageconfig';
import { ResponseModel } from '../../models/landing-page-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { debug } from 'console';



@Injectable({
  providedIn: 'root'
})
export class CoverageConfigDataService {

  public isFormValid = true;
  constructor(private httpClient: HttpClient) { }

  private _listners = new Subject<any>();
  clientPublish(): Observable<any> {
    return this._listners.asObservable();
  }


  publishClient(client: string) {
    this._listners.next(client);
  }

  fetchReports$(pageIndex, pageSize): Observable<any> {
    //return this.reports$.asObservable();
    return from(this.GetCoverageConfigData(pageIndex, pageSize));
  }
  fetchReportsData$(pageIndex, pageSize, filter): Observable<any> {
    //return this.reports$.asObservable();
    return from(this.GetCoverageConfigListByFilter(pageIndex, pageSize, filter));
  }

  createCoverageConfig(coverageConfigForm: NewCoverageConfigForm): Observable<string> {


    //const report = this.fromReportForm(reportForm);
    this.saveNewCoverageConfig(coverageConfigForm).then((a) => {
      if (a.data != 0) {
        //this.publishClient("created coverageConfig data successfully!");
      }
      else {
        //this.publishClient(a.meta.message);
      }
    });
    return of(coverageConfigForm.parameterName);

  }

  updateCoverageConfig(coverageConfigForm: NewCoverageConfigForm): Observable<string> {
    this.saveCoverageConfig(coverageConfigForm).then((a) => {
      if (a.data != 0) {
        // this.publishClient("Updated coverageConfig data successfully!");
      }
      else {
        // this.publishClient(a.data.message);
      }
    });
    return of(coverageConfigForm.parameterName);
  }

  validateCoverageConfig(classificationAttributeId: any, classificationId: any, parameterName: string, classificationKeycolumn: string): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/ValidateCoverageConfig', JSON.stringify({ ClassificationAttributeId: classificationAttributeId, ClassificationId: classificationId, ParameterName: parameterName, ClassificationKeycolumn: classificationKeycolumn }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data
      }).catch(this.handleErr);
  }
  saveNewCoverageConfig(coverageConfigForm: NewCoverageConfigForm): any {
    this.validateCoverageConfig(coverageConfigForm.classificationAttributeId, coverageConfigForm.classificationId, coverageConfigForm.parameterName, coverageConfigForm.classificationKeycolumn).then((res) => {
      if (res) {
        alert('Coverage Config already exists!');
      }
      else {
        this.isFormValid = true;
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(
          environment.dataServiceRoot + 'ui/SITableConfigurations/AddCoverageConfig',
          JSON.stringify({
            ParameterName: coverageConfigForm.parameterName,
            ClassificationAttributeId: coverageConfigForm.classificationAttributeId,
            ClassificationId: coverageConfigForm.classificationId,
            ManualOverrideflag: coverageConfigForm.manualOverrideflag,
            PercentageCut: coverageConfigForm.percentageCut,
            DateToBeUsed: coverageConfigForm.dateToBeUsed,
            PercentageThreshold: coverageConfigForm.percentageThreshold,
            ClassificationKeycolumn: coverageConfigForm.classificationKeycolumn,
            ParameterId: coverageConfigForm.parameterId,
          }),

          { headers: headers }).toPromise()
          .then(response => {
            var result = response as ResponseModel;
            if (result.data != 0) {
              this.publishClient("Coverage config data created successfully!");
            }
            else {
              this.publishClient(result.meta.message);
            }

          }).catch(this.handleErr);
      }
    });
  }
  saveCoverageConfig(coverageConfigForm: NewCoverageConfigForm): any {
    this.isFormValid = true;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/UpdateCoverageConfig',
      JSON.stringify({
        ParameterName: coverageConfigForm.parameterName,
        ClassificationAttributeId: coverageConfigForm.classificationAttributeId,
        ClassificationId: coverageConfigForm.classificationId,
        ManualOverrideflag: coverageConfigForm.manualOverrideflag,
        PercentageCut: coverageConfigForm.percentageCut,
        DateToBeUsed: coverageConfigForm.dateToBeUsed,
        PercentageThreshold: coverageConfigForm.percentageThreshold,
        ClassificationKeycolumn: coverageConfigForm.classificationKeycolumn,
        ParameterId: coverageConfigForm.parameterId
      }),

      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        if (result.data != 0) {
          this.publishClient("Updated Coverage config data successfully!");
        }
        else {
          this.publishClient(result.meta.message);
        }
      }).catch(this.handleErr);

  }

  GetCoverageConfigData(pageIndex, pageSize): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/CoverageConfigList', JSON.stringify({ page: pageIndex, pageSize: pageSize }),
      { headers: headers });
  }
  GetCoverageConfigListByFilter(pageIndex, pageSize, filter): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/CoverageConfigListByFilter', JSON.stringify({ pageSize: pageSize, page: pageIndex, filter: filter }),
      { headers: headers });
  }
  GetClassificationIdList(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/ClassificationIdList', null,
      { headers: headers });
  }

  deleteCoverageConfigData(coverageConfigForm: any): any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DeleteCoverageConfig',
      JSON.stringify({
        ParameterName: coverageConfigForm.ParameterName,
        ClassificationAttributeId: coverageConfigForm.ClassificationAttributeId,
        ClassificationId: coverageConfigForm.ClassificationId,
        ClassificationKeycolumn: coverageConfigForm.ClassificationKeycolumn
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        this.publishClient("Coverage Config data has been deleted");
        return result;
      }).catch(this.handleErr);
  }
    downloadExcel(classificationId:any): Promise<any> {
      
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DownloadCoverageConfigExcel',
      JSON.stringify({
        ClassificationId: classificationId
      }),
      { headers: headers }).toPromise()
      .then(response => {
          var result = response;
          return result;
    })
        .catch (this.handleErr);
  }
  //VanguardConfiguration
  fetchVanguardConfigurationsReports$(pageIndex, pageSize): Observable<any> {
    //return this.reports$.asObservable();
    return from(this.GetVanguardConfigurationsData(pageIndex, pageSize));
  }
  fetchVanguardConfigurationsReportsData$(pageIndex, pageSize, filter): Observable<any> {
    //return this.reports$.asObservable();
    return from(this.GetVanguardConfigurationsListByFilter(pageIndex, pageSize, filter));
  }

  createVanguardConfigurations(vanguardConfigurationsForm: NewVanguardConfigurationsForm): Observable<string> {


    //const report = this.fromReportForm(reportForm);
    this.saveNewVanguardConfigurations(vanguardConfigurationsForm).then((a) => {
      if (a.data != 0) {
        //this.publishClient("created vanguardConfigurations data successfully!");
      }
      else {
        //this.publishClient(a.meta.message);
      }
    });
    return of(vanguardConfigurationsForm.indexcode);

  }

  updateVanguardConfigurations(vanguardConfigurationsForm: NewVanguardConfigurationsForm): Observable<string> {
    this.saveVanguardConfigurations(vanguardConfigurationsForm).then((a) => {
      if (a.data != 0) {
        // this.publishClient("Updated vanguardConfigurations data successfully!");
      }
      else {
        // this.publishClient(a.data.message);
      }
    });
    return of(vanguardConfigurationsForm.indexcode);
  }

  validateVanguardConfigurations(vanguardConfigurationsForm: any): Promise<string> {
   
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/ValidateVanguardConfigurations', JSON.stringify({ indexCode: vanguardConfigurationsForm.indexcode, clientName: vanguardConfigurationsForm.clientName }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data
      }).catch(this.handleErr);
  }
  saveNewVanguardConfigurations(vanguardConfigurationsForm: NewVanguardConfigurationsForm): any {
    this.validateVanguardConfigurations(vanguardConfigurationsForm).then((res) => {
      if (res) {
        alert('Vanguard Configurations already exists!');
      }
      else {
        this.isFormValid = true;
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(
          environment.dataServiceRoot + 'ui/SITableConfigurations/AddVanguardConfigurations',
          JSON.stringify({
            Indexcode: vanguardConfigurationsForm.indexcode,
            ParentIndexcode: vanguardConfigurationsForm.parentIndexcode,
            CurrencyCode: vanguardConfigurationsForm.currencyCode,
            ClimateMetric: vanguardConfigurationsForm.climateMetric,
            OutputRegex: vanguardConfigurationsForm.outputRegex,
            Excllstwgt: vanguardConfigurationsForm.excllstwgt,
            ClientName: vanguardConfigurationsForm.clientName
          }),

          { headers: headers }).toPromise()
          .then(response => {
            var result = response as ResponseModel;
            if (result.data != 0) {
              this.publishClient("Vanguard Configurations data created successfully!");
            }
            else {
              this.publishClient(result.meta.message);
            }

          }).catch(this.handleErr);
      }
    });
  }
  saveVanguardConfigurations(vanguardConfigurationsForm: NewVanguardConfigurationsForm): any {
    
    console.log("Update Vangaurd");
    this.isFormValid = true;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/UpdateVanguardConfigurations',
      JSON.stringify({
        Indexcode: vanguardConfigurationsForm.indexcode,
        ParentIndexcode: vanguardConfigurationsForm.parentIndexcode,
        CurrencyCode: vanguardConfigurationsForm.currencyCode,
        ClimateMetric: vanguardConfigurationsForm.climateMetric,
        OutputRegex: vanguardConfigurationsForm.outputRegex,
        Excllstwgt: vanguardConfigurationsForm.excllstwgt,
        ClientName: vanguardConfigurationsForm.clientName
      }),

      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        if (result.data != 0) {
          this.publishClient("Updated Vanguard Configurations data successfully!");
        }
        else {
          this.publishClient(result.meta.message);
        }
      }).catch(this.handleErr);

  }

  GetVanguardConfigurationsData(pageIndex, pageSize): Observable<any> {
 
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/VanguardConfigurationsList', JSON.stringify({ page: pageIndex, pageSize: pageSize }),
      { headers: headers });
  }
  GetVanguardConfigurationsListByFilter(pageIndex, pageSize, filter): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/VanguardConfigurationsListByFilter', JSON.stringify({ pageSize: pageSize, page: pageIndex, filter: filter }),
      { headers: headers });
  }
  GetindexList(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/VanguardConfigurationsIndexList', null,
      { headers: headers });
  }
  getCurrencies(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/GetCurrenciesList', null,
      { headers: headers });
  }
  deleteVanguardConfigurationsData(vanguardConfigurationsForm: any): any {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DeleteVanguardConfigurations',
      JSON.stringify({
        IndexCode: vanguardConfigurationsForm.IndexCode,
        ClientName: vanguardConfigurationsForm.ClientName
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        this.publishClient("Vanguard Configurations data has been deleted");
        return result;
      }).catch(this.handleErr);
  }
  downloadVanguardConfigurationsExcel(indexCode: any): Promise<any> {
    debugger;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DownloadVanguardConfigurationsExcel', JSON.stringify({
        IndexCode: indexCode
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        return result;
      })
      .catch(this.handleErr);
  }
  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}

