import { Component, OnInit } from '@angular/core';
import {ReportsService} from '../../services/reports.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {NewReportPopupComponent} from '../../components/new-report-popup/new-report-popup.component';
import {NewReportForm} from '../../models/report';

@Component({
  selector: 'app-climatereports-page',
  templateUrl: './climate-reports-page.component.html',
  styleUrls: ['./climate-reports-page.component.scss']
})
export class ClimateReportsPageComponent implements OnInit {

  baseDialogConfig: MatDialogConfig = {
    closeOnNavigation: true,
    panelClass: 'custom-popup-panel',
  };
  
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
  }
  editReport(report: NewReportForm) {
  }
  ngAfterViewInit(){
    let yourElem= <HTMLElement>document.querySelector('.fa-angle-double-left');
    if(yourElem!=null)
    {
    yourElem.click();
    }
  }
}
