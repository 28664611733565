import { Component, OnInit } from '@angular/core';
import { SedolAlertModel } from '../../models/sedol-alert-model';
import { SedolAlertService } from '../../services/sedol-alert.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sedol-search',
  templateUrl: './sedol-search.component.html',
  styleUrls: ['./sedol-search.component.css']
})
export class SedolSearchComponent implements OnInit {

  sedolData: SedolAlertModel[];
  sedolUser: SedolAlertModel[];
  deleteStatus: any;
  constructor(private services:SedolAlertService) { 
   
  }



  ngOnInit() {
    this.sedolData =[{method:"",attribute:"", frequency:"", count:"",notificationEmailAddress:"", id:""}];
    this.getSedolDetails();
  }

  initialize(){
    this.deleteStatus = "";
  }

  getSedolDetails(){
    this.services.sedolAlertDetails().then(result =>{
      
     this.sedolData=result;
     
    })
  }

  deleteSedol(element){
    this.services.DeleteSedolAlert({ID: element}).then(result =>{
      
      if(result == "1")
      {
        this.initialize();
         this.deleteStatus = "Record Deleted";
      }
      else{
        this.deleteStatus = "Not deleted";
      }
     this.getSedolDetails();
    })
  }



}
