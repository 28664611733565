import { Injectable } from '@angular/core';
// tslint:disable-next-line:import-blacklist

import {BehaviorSubject, Observable, of,from, Subject} from 'rxjs';
import {NewReportForm, Report, ReportData, ExportReportDetails, ExportReportDetailsColumns, ExportReportData} from '../models/report';
import {map} from 'rxjs/operators';
import { Portfolio } from '../models/portfolio';
import { Benchmark } from '../models/benchmark';
import { ResponseModel } from '../../models/landing-page-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


const data: Report[] = [
  {
    id: 0,
    name: 'REPORT_CORP',
    portfolio: 'Portfolio 1',
    portfolioId :2,
    benchmark: 'F4GAW',
    dateCreated: '07-01-2021',
    asOfDate: '07-01-2021',
    assetsClass :'Equity',
    template :'Corporate carbon footprint',
    metrics :'Carbon intensity of revenues',
    currency :'USD',
  }
];

@Injectable({
  providedIn: 'root'
})
export class ClimateDataPackService {
  
  private reports$ = new BehaviorSubject<Report[]>(data);

  constructor( private httpClient: HttpClient) { }
  
  private _listners= new Subject<any>(); 
  clientPublish():Observable<any>{
    return this._listners.asObservable();
  }


  publishClient(client: string) {
    this._listners.next(client);
  } 

  fetchReports$(): Observable<any> {
    //return this.reports$.asObservable();
    return from(this.GetReports());
  }

  GetReports(): Observable<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'client/api/climatedatapack/GetClimateDatapackReports',null,
      {headers: headers});
  }
}