import { Injectable } from '@angular/core';
import { iFilter, iIndexMarker, iRequest } from '../models/data-restore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { environment } from '../../environments/environment';
import { iResponseModel } from '../models/response.model';

@Injectable({
  providedIn: 'root'
})
export class DataRestoreService {

  constructor(private httpClient: HttpClient) { }

  GetIndexMarkers(search: string): Promise<iIndexMarker[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/DataRestore/GetIndexMarker', 
    JSON.stringify({Search: search}), { headers }).toPromise()
    .then((response: any) => { return response.data as iIndexMarker[]})
    .catch(this.handleError);
  }

  TransferRecords(tablename:string, filter: iFilter): Promise<iResponseModel> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let asOfDate: string = formatDate(filter.asOfDate, 'yyyy-MM-dd','en-US');

    return this.httpClient.post(environment.dataServiceRoot + 'ui/DataRestore/TransferRedshiftToAurora', 
    JSON.stringify({TableName: tablename, AsOfDate: asOfDate, IndexId: filter.indexId, RequestId: filter.requestId}), { headers }).toPromise()
    .then((response: any) => { return response as iResponseModel})
    .catch(this.handleError);
  }

  GetRequests(): Promise<iRequest[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/DataRestore/Requests', null, { headers }).toPromise()
    .then((response: any) => { return response.data as iRequest[]})
    .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
