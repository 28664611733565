import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewEsgExcludesPriorityForm } from '../../models/esgexcludes';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report } from '../../models/report';
import { formatDate, DatePipe } from '@angular/common';
import { EsgExcludesDataService } from '../../services/esgexcludes.service';
import { debug } from 'console';

@Component({
  selector: 'app-new-esgexcludesprioritydata-popup',
  templateUrl: './new-esgexcludesprioritydata-popup.component.html',
  styleUrls: ['./new-esgexcludesprioritydata-popup.component.scss']
})
export class NewEsgExcludesPriorityPopupComponent implements OnInit {

 form: FormGroup = this.fb.group({
    indexMarker: ['', Validators.required],
    excludeHeader: ['',Validators.required],
    priority: ['',[Validators.required,Validators.pattern("^[0-9]*$")]],
    excludeCategoryHeader: ['',Validators.required],
    excludeCategoryHeaderPriority:['',[Validators.pattern("^[0-9]*$")]]
  } as Record<keyof NewEsgExcludesPriorityForm, any[]>);

  updateReport: boolean = false;
  isreportForm = false;
  
  constructor(private fb: FormBuilder, private esgexcludesdataservice: EsgExcludesDataService, public dialogRef: MatDialogRef<NewEsgExcludesPriorityPopupComponent>, @Inject(MAT_DIALOG_DATA) public rowdata: any) {

    console.log(rowdata);

  }

  ngOnInit(): void {
    if (this.rowdata) {
      this.updateReport = true;
      this.form.patchValue({
        indexMarker: this.rowdata.IndexMarker,
        excludeHeader: this.rowdata.ExcludeHeader,
        priority: this.rowdata.Priority,
        excludeCategoryHeader: this.rowdata.ExcludeCategoryHeader,
        excludeCategoryHeaderPriority:this.rowdata.ExcludeCategoryHeaderPriority
        
      })   
    }
   
  }

  public onClickHandler(event: any): void {
 
  }

  onSubmit(): void {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      this.dialogRef.close(data);
    }
    if (this.form.invalid) {
      this.isreportForm = true;
      return;
    }
  }
  }
