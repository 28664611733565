import { Component, OnInit } from '@angular/core';
import { EsgconfigurationDataService } from '../../services/esgconfigurations.service';
import { EsgconfigurationfixedincomeDataService } from '../../services/esgconfigurationsfixedincome.service';
import { EsgExcludesDataService } from '../../services/esgexcludes.service';
import { NewEsgExcludesPopupComponent } from '../../components/new-esgexcludesdata-popup/new-esgexcludesdata-popup.component';
import { NewEsgExcludesForm } from '../../models/esgexcludes';
import { NewEsgExcludesPriorityPopupComponent } from '../../components/new-esgexcludesprioritydata-popup/new-esgexcludesprioritydata-popup.component';
import { NewEsgExcludesPriorityForm } from '../../models/esgexcludes';
import { NewEsgconfigurationPopupComponent } from '../../components/new-esgconfigurationdata-popup/new-esgconfigurationdata-popup.component';
import { NewEsgconfigurationFixedincomePopupComponent } from '../../components/new-esgconfigurationfixedincomedata-popup/new-esgconfigurationfixedincomedata-popup.component';
import { NewVanguardConfigurationPopupComponent } from '../../components/new-vanguardconfigurationdata-popup/new-vanguardconfigurationdata-popup.component';
import { NewEsgconfigurationForm } from '../../models/esgconfiguration';
import { NewEsgconfigurationfixedincomeForm } from '../../models/esgconfigurationfixedincome';
import { CoverageConfigDataService } from '../../services/coverageconfig.service';
import { NewCoverageConfigPopupComponent } from '../../components/new-coverageconfigdata-popup/new-coverageconfigdata-popup.component';
import { NewCoverageConfigForm, NewVanguardConfigurationsForm } from '../../models/coverageconfig';
import { SovereignConfigurationDataService } from '../../services/sovereignconfiguration.service';
import { NewSovereignconfigurationPopupComponent } from '../../components/new-sovereignconfigurationdata-popup/new-sovereignconfigurationdata-popup.component';
import { NewSovereignConfigurationForm } from '../../models/sovereignconfiguration';
import { NewSovereignconfigurationIndexPopupComponent } from '../../components/new-sovereignconfigurationindexdata-popup/new-sovereignconfigurationindexdata-popup.component';
import { NewSovereignConfigurationIndexForm } from '../../models/sovereignconfiguration';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DownloadService } from '../../services/download.service';
import { data } from 'jquery';
import { ExportToExcelService } from '../../../services/exportToExcel.service';
import { S3ManagerService } from '../../../services/s3-manager.service';
import * as CryptoJS from "crypto-js";
import { saveAs } from 'file-saver/FileSaver';
import { environment } from '../../../../environments/environment';
import { climatedatapackconfigurationDataService } from '../../services/climatedatapackconfigurations.service';
import { NewclimatedatapackconfigurationPopupComponent } from '../../components/new-climatedatapackconfigurationdata-popup/new-climatedatapackconfigurationdata-popup.component';
import { NewclimatedatapackconfigurationForm } from '../../models/climatedatapackconfiguration';
import { eupabdatesDataService } from '../../services/eupabdates.service';
import { NeweupabdatesPopupComponent } from '../../components/new-eupabdates-popup/new-eupabdates-popup.component';
import { NeweupabdatesForm } from '../../models/eupabdates';
import { eupabindexDataService } from '../../services/eupabindexconfigurations.service';
import { NewEUPABIndexconfigurationPopupComponent } from '../../components//new-eupabindexconfigurationdata-popup/new-eupabindexconfigurationdata-popup.component';
import { NeweupabindexForm } from '../../models/eupabindex';
import { gsxconfigurationsDataService } from '../../services/gsxconfigurations.service';
import { NewgsxconfigurationsPopupComponent } from '../../components/new-gsxconfigurations-popup/new-gsxconfigurations-popup.component';
import { NewgsxconfigurationsForm } from '../../models/gsxconfigurations';
import { paiconfigurationDataService } from '../../services/paiconfigurations.service';
import { NewPAIConfigurationPopupComponent } from '../../components/new-paiconfigurationdata-popup/new-paiconfigurationdata-popup.component';
import { PaiConfigurationDataGridComponent } from '../../components/paiconfiguration-data-grid/paiconfiguration-data-grid.component';
import { NewPAIConfigurationForm } from '../../models/paiconfiguration';


@Component({
  selector: 'si-tables-config-page',
  templateUrl: './si-tables-config-page.component.html',
  styleUrls: ['./si-tables-config-page.component.scss']
})
export class SITablesDataConfigComponent implements OnInit {
  indexCode: string = null;
  excludeIndexCode: string = null;
  classificationId: any = '0';
  isVisible: boolean = false;
  tableNames: string[];
  serchtext: any = '';
  selectedTable: string = '';
  public tableNamefilteredList: string[];
  dataSourceEsg$ = this.esgconfigurationDataService.fetchReports$(0, 5);
  dataSourceCoverage$ = this.coverageConfigDataService.fetchReports$(0, 5);
  dataSourceEsgExcludes$ = this.esgExcludesDataService.fetchEsgExcludesReports$(0, 5);
  dataSourceEsgExcludesPriority$ = this.esgExcludesDataService.fetchEsgExcludesPriorityReports$(0, 5);
  dataSourceSovereignConfiguration$ = this.sovereignConfigurationDataService.fetchReports$(0, 5);
  dataSourceSovereignConfigurationIndex$ = this.sovereignConfigurationDataService.fetchReportsSovereign$(0, 5);
  dataSourceVanguardConfiguration$ = this.coverageConfigDataService.fetchVanguardConfigurationsReports$(0, 5);
  climatedatapackdataSource$ = this.climatedatapackconfigurationDataService.fetchReports$(0, 5);
  gsxconfigurationsdataSource$ = this.gsxconfigurationsDataService.fetchReports$(0, 5);
  paidataSource$ = this.paiconfigurationDataService.fetchReports$(0, 5);
  dataSourceEsgfixedincome$ = this.esgconfigurationfixedincomeDataService.fetchReportsFixedIncome$(0, 5);
  dataSourceEUPABIndex$ = this.eupabindexDataService.fetchReports$(0, 5);
  passphrase: string = "EncryptionatRest";
  tableNameList: string[] = ['esgconfigurations', 'mvp_coverage_config', 'esgexcludes', 'esgexcludespriority', 'esgconfigurations_fixedincome', 'sovereign_configuration', 'sovereign_configuration_index', 'vanguardconfigurations', 'climatedatapackconfigurations', 'eupabdates', 'gsxconfigurations', 'pai_configurations','eupabcalculation_index'];
  baseDialogConfig: MatDialogConfig = {
    closeOnNavigation: true,
    panelClass: 'custom-popup-panel',
  };

    constructor(private esgconfigurationfixedincomeDataService: EsgconfigurationfixedincomeDataService,private esgconfigurationDataService: EsgconfigurationDataService, private coverageConfigDataService: CoverageConfigDataService, private esgExcludesDataService: EsgExcludesDataService, private sovereignConfigurationDataService: SovereignConfigurationDataService, private climatedatapackconfigurationDataService: climatedatapackconfigurationDataService, private eupabdatesDataService: eupabdatesDataService, private gsxconfigurationsDataService: gsxconfigurationsDataService, private paiconfigurationDataService: paiconfigurationDataService,private eupabindexDataService: eupabindexDataService, private dialog: MatDialog, private exportService: ExportToExcelService, private s3ManagerService: S3ManagerService) { }

  ngOnInit(): void {
    if (sessionStorage.getItem("UserRoles").indexOf(environment.siAdminGroup) != -1) {
      this.isVisible = true;
    }
    //enable add button for testing in dev
   //this.isVisible = true;
    //end
    this.GetTableConfigurations();
  }
  filterValue(event) {
    this.indexCode = event;
  }
  excludeIndexValue(event) {
    this.excludeIndexCode = event;
  }
  classificationIdfilterValue(event) {
    this.classificationId = event;
  }


  GetTableConfigurations() {
    this.esgconfigurationDataService.GetTableConfigurations().subscribe((res) => {
      this.tableNames = res.data;
      this.tableNamefilteredList = res.data;
    });
  }
  removefilter() {
    this.serchtext = '';
    this.tableNamefilteredList = this.tableNames;
  };
  filterTableList(val) {
    this.tableNamefilteredList = this.tableNames.filter((tablename) => tablename.includes(val == undefined ? val : val.toLowerCase()));
  }
  onTableNameChange(selectedTable) {
    console.log(selectedTable);
    if (selectedTable != undefined) {
      console.log("inside"+selectedTable);
      this.selectedTable = selectedTable;
      this.indexCode = '';
      this.excludeIndexCode = '';
      this.classificationId = '';
    }
    else {
      this.selectedTable = undefined;
    }

  }
  downloadEsgConfiguration(): void {
    this.esgconfigurationDataService.downloadExcel(this.indexCode).then((res) => {
      let data = res;
      alert("Click OK to download EsgConfigurationlist");
      if (data != undefined && data !== "") {
        // call download file method
        this.downloadFile(data);
        console.log(data);   // window.location.href = data;
        alert("Export  EsgConfiguration Report download is ready");
      } else if (data == "") {
        alert("No data found.");
      }

    });

  }
  downloadCoverageConfig(): void {
    
    this.coverageConfigDataService.downloadExcel(this.classificationId).then((res) => {
      let data = res;
      alert("Click OK to download CoverageConfiglist");
      if (data != undefined && data !== "") {
        // call download file method
        this.downloadFile(data);
        console.log(data);   // window.location.href = data;
        alert("Export  CoverageConfig Report download is ready");
      } else if (data == "") {
        alert("No data found.");
      }

    });

  }
  downloadEsgExcludes(): void {
    this.esgExcludesDataService.downloadEsgExcludesExcel(this.indexCode, this.excludeIndexCode).then((res) => {
      let data = res;
      alert("Click OK to download EsgExcludeslist");
      if (data != undefined && data !== "") {
        // call download file method
        this.downloadFile(data);
        console.log(data);   // window.location.href = data;
        alert("Export  EsgExcludes Report download is ready");
      } else if (data == "") {
        alert("No data found.");
      }

    });

  }
  downloadEsgExcludesPriority(): void {
    this.esgExcludesDataService.downloadEsgExcludesPriorityExcel(this.indexCode).then((res) => {
      let data = res;
      alert("Click OK to download EsgExcludesPriority");
      if (data != undefined && data !== "") {
        // call download file method
        this.downloadFile(data);
        console.log(data);   // window.location.href = data;
        alert("Export  EsgExcludesPriority Report download is ready");
      } else if (data == "") {
        alert("No data found.");
      }

    });

  }
  downloadEsgConfigurationFixedIncome(): void {
    this.esgconfigurationfixedincomeDataService.downloadFixedIncomeExcel(this.indexCode).then((res) => {
      let data = res;
      alert("Click OK to download EsgConfigurationFixedIncomelist");
      if (data != undefined && data !== "") {
        // call download file method
        this.downloadFile(data);
        console.log(data);   // window.location.href = data;
        alert("Export  EsgConfiguration Fixed Income Report download is ready");
      } else if (data == "") {
        alert("No data found.");
      }

    });

  }
  downloadSovereignConfiguration(): void {
    this.sovereignConfigurationDataService.downloadExcel(this.indexCode).then((res) => {
      let data = res;
      alert("Click OK to download SovereignConfigurationlist");
      if (data != undefined && data !== "") {
        // call download file method
        this.downloadFile(data);
        console.log(data);   // window.location.href = data;
        alert("Export SovereignConfiguration Report download is ready");
      } else if (data == "") {
        alert("No data found.");
      }

    });

  }
  downloadSovereignConfigurationIndex(): void {
    this.sovereignConfigurationDataService.downloadSovereignConfigurationExcel(this.indexCode).then((res) => {
      let data = res;
      alert("Click OK to download SovereignConfigurationIndexlist");
      if (data != undefined && data !== "") {
        // call download file method
        this.downloadFile(data);
        console.log(data);   // window.location.href = data;
        alert("Export SovereignConfigurationIndex Report download is ready");
      } else if (data == "") {
        alert("No data found.");
      }

    });

  }
  downloadVanguardConfiguration(): void {
    this.coverageConfigDataService.downloadVanguardConfigurationsExcel(this.indexCode).then((res) => {
      let data = res;
      alert("Click OK to download VanguardConfigurationlist");
      if (data != undefined && data !== "") {
        // call download file method
        this.downloadFile(data);
        console.log(data);   // window.location.href = data;
        alert("Export VanguardConfigurationlist Report download is ready");
      } else if (data == "") {
        alert("No data found.");
      }

    });

  }
  downloadclimatedatapackConfiguration(): void {
    this.climatedatapackconfigurationDataService.downloadExcel(this.indexCode).then((res) => {
      let data = res;
      alert("Click OK to download ClimatedatapackConfigurationslist");
      if (data != undefined && data !== "") {
        // call download file method
        this.downloadFile(data);
        console.log(data);   // window.location.href = data;
        alert("Export  ClimateDatapackConfigurations Report download is ready");
      } else if (data == "") {
        alert("No data found.");
      }

    });
  }
  downloadEupabdates(): void {
    this.eupabdatesDataService.downloadExcel(this.indexCode).then((res) => {
      let data = res;
      alert("Click OK to download EUPABDateslist");
      if (data != undefined && data !== "") {
        // call download file method
        this.downloadFile(data);
        console.log(data);   // window.location.href = data;
        alert("Export  EUPABDates Report download is ready");
      } else if (data == "") {
        alert("No data found.");
      }

    });

  }
  
  downloadEupabIndex(): void {
    this.eupabindexDataService.downloadExcel(this.indexCode).then((res) => {
      let data = res;
      alert("Click OK to download EUPABIndexlist");
      if (data != undefined && data !== "") {
        // call download file method
        this.downloadFile(data);
        console.log(data);   // window.location.href = data;
        alert("Export  EUPABIndex Report download is ready");
      } else if (data == "") {
        alert("No data found.");
      }

    });

  }
  downloadgsxconfigurations(): void {
    this.gsxconfigurationsDataService.downloadExcel(this.indexCode).then((res) => {
      let data = res;
      alert("Click OK to download GSXConfigurationslist");
      if (data != undefined && data !== "") {
        // call download file method
        this.downloadFile(data);
        console.log(data);   // window.location.href = data;
        alert("Export  GSXConfigurations Report download is ready");
      } else if (data == "") {
        alert("No data found.");
      }

    });

  }
  downloadFile(s3UrlData): void {
    console.log("FullS3 url: " + s3UrlData);
    var urlParts = s3UrlData.replace("//", "--").split("/");
    var bucketName = urlParts[1];
    var filePath = urlParts[urlParts.length - 1];
    var fileName = urlParts[urlParts.length - 1];

    console.log("BucketName url: " + bucketName);
    console.log("FilePath url: " + fileName);
    console.log("Session Message : " + sessionStorage.getItem('RestMessage'));
    this.s3ManagerService.getRawData(bucketName, filePath,
      JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('RestMessage'), this.passphrase).toString(CryptoJS.enc.Utf8))).then(result => {
        saveAs(new Blob([result.Body]), fileName);  //  {type: result.ContentType}
        console.log(sessionStorage.getItem('RestMessage'));
      }, onerror => {
        console.log(onerror);
      });
  }

  openDialogEsgConfiguration(): void {
    const dialogRef: MatDialogRef<NewEsgconfigurationPopupComponent, NewEsgconfigurationForm> = this.dialog.open(NewEsgconfigurationPopupComponent);
    dialogRef.afterClosed().subscribe((reportForm) => {
      if (reportForm) {
        var that = this;
        this.esgconfigurationDataService.creatEsgconfiguration(reportForm).subscribe((res) => {
          that.dataSourceEsg$ = this.esgconfigurationDataService.fetchReports$(0, 5);
        });
      }
    });
  }
  openDialogCoverageConfig(): void {
    const dialogRef: MatDialogRef<NewCoverageConfigPopupComponent, NewCoverageConfigForm> = this.dialog.open(NewCoverageConfigPopupComponent);
    dialogRef.afterClosed().subscribe((reportForm) => {
      if (reportForm) {
        var that = this;
        this.coverageConfigDataService.createCoverageConfig(reportForm).subscribe((res) => {
          that.dataSourceCoverage$ = this.coverageConfigDataService.fetchReports$(0, 5);
        });
      }
    });
  }
  openDialogEsgExcludes(): void {
    const dialogRef: MatDialogRef<NewEsgExcludesPopupComponent, NewEsgExcludesForm> = this.dialog.open(NewEsgExcludesPopupComponent);
    dialogRef.afterClosed().subscribe((reportForm) => {
      if (reportForm) {
        var that = this;
        this.esgExcludesDataService.creatEsgExcludes(reportForm).subscribe((res) => {
          that.dataSourceEsgExcludes$ = this.esgExcludesDataService.fetchEsgExcludesReports$(0, 5);
        });
      }
    });
  }
  openDialogEsgExcludesPriority(): void {
    const dialogRef: MatDialogRef<NewEsgExcludesPriorityPopupComponent, NewEsgExcludesPriorityForm> = this.dialog.open(NewEsgExcludesPriorityPopupComponent);
    dialogRef.afterClosed().subscribe((reportForm) => {
      if (reportForm) {
        var that = this;
        this.esgExcludesDataService.creatEsgExcludesPriority(reportForm).subscribe((res) => {
          that.dataSourceEsgExcludesPriority$ = this.esgExcludesDataService.fetchEsgExcludesPriorityReports$(0, 5);
        });
      }
    });
  }
  openDialogEsgConfigurationFixedIncome(): void {
    const dialogRef: MatDialogRef<NewEsgconfigurationFixedincomePopupComponent, NewEsgconfigurationfixedincomeForm> = this.dialog.open(NewEsgconfigurationFixedincomePopupComponent);
    dialogRef.afterClosed().subscribe((reportForm) => {
      if (reportForm) {
        var that = this;
        this.esgconfigurationfixedincomeDataService.creatEsgconfigurationFixedIncome(reportForm).subscribe((res) => {
          that.dataSourceEsgfixedincome$ = this.esgconfigurationfixedincomeDataService.fetchReportsFixedIncome$(0, 5);
        });
      }
    });
  }
  openDialogSovereignconfiguration(): void {
    const dialogRef: MatDialogRef<NewSovereignconfigurationPopupComponent, NewSovereignConfigurationForm> = this.dialog.open(NewSovereignconfigurationPopupComponent);
    dialogRef.afterClosed().subscribe((reportForm) => {
      if (reportForm) {
        var that = this;
        this.sovereignConfigurationDataService.creatSovereignConfiguration(reportForm).subscribe((res) => {
          that.dataSourceSovereignConfiguration$ = this.sovereignConfigurationDataService.fetchReports$(0, 5);
        });
      }
    });
  }
  openDialogSovereignconfigurationIndex(): void {
    const dialogRef: MatDialogRef<NewSovereignconfigurationIndexPopupComponent, NewSovereignConfigurationIndexForm> = this.dialog.open(NewSovereignconfigurationIndexPopupComponent);
    dialogRef.afterClosed().subscribe((reportForm) => {
      if (reportForm) {
        var that = this;
        this.sovereignConfigurationDataService.creatSovereignConfigurationIndex(reportForm).subscribe((res) => {
          that.dataSourceSovereignConfigurationIndex$ = this.sovereignConfigurationDataService.fetchReportsSovereign$(0, 5);
        });
      }
    });
  }
  openDialogVanguardConfiguration(): void {
    const dialogRef: MatDialogRef<NewVanguardConfigurationPopupComponent, NewVanguardConfigurationsForm> = this.dialog.open(NewVanguardConfigurationPopupComponent);
    dialogRef.afterClosed().subscribe((reportForm) => {
      if (reportForm) {
        var that = this;
        this.coverageConfigDataService.createVanguardConfigurations(reportForm).subscribe((res) => {
          that.dataSourceVanguardConfiguration$ = this.coverageConfigDataService.fetchVanguardConfigurationsReports$(0, 5);
        });
      }
    });
  }
  openDialogclimatedatapackConfiguration(): void {
    const dialogRef: MatDialogRef<NewclimatedatapackconfigurationPopupComponent, NewclimatedatapackconfigurationForm> = this.dialog.open(NewclimatedatapackconfigurationPopupComponent);
    dialogRef.afterClosed().subscribe((reportForm) => {
      if (reportForm) {
        var that = this;
        this.climatedatapackconfigurationDataService.creatclimatedatapackconfiguration(reportForm).subscribe((res) => {
          that.climatedatapackdataSource$ = this.climatedatapackconfigurationDataService.fetchReports$(0, 5);
        });
      }
    });
  }
  openDialogeupabdates(): void {
    const dialogRef: MatDialogRef<NeweupabdatesPopupComponent, NeweupabdatesForm> = this.dialog.open(NeweupabdatesPopupComponent);
    dialogRef.afterClosed().subscribe((reportForm) => {
      if (reportForm) {
        var that = this;
        this.eupabdatesDataService.createupabdates(reportForm).subscribe((res) => {
          that.dataSourceEsg$ = this.eupabdatesDataService.fetchReports$(0, 5);
        });
      }
    });
  }

  openDialogeupabIndex(): void {
    const dialogRef: MatDialogRef<NewEUPABIndexconfigurationPopupComponent, NeweupabindexForm> = this.dialog.open(NewEUPABIndexconfigurationPopupComponent);
    dialogRef.afterClosed().subscribe((reportForm) => {
      if (reportForm) {
        var that = this;
        this.eupabindexDataService.createupabindex(reportForm).subscribe((res) => {
          that.dataSourceEUPABIndex$ = this.eupabindexDataService.fetchReports$(0, 5);
        });
      }
    });
  }

  openDialoggsxconfigurations(): void {
    const dialogRef: MatDialogRef<NewgsxconfigurationsPopupComponent, NewgsxconfigurationsForm> = this.dialog.open(NewgsxconfigurationsPopupComponent);
    dialogRef.afterClosed().subscribe((reportForm) => {
      if (reportForm) {
        var that = this;
        this.gsxconfigurationsDataService.creatgsxconfigurations(reportForm).subscribe((res) => {
          that.gsxconfigurationsdataSource$ = this.gsxconfigurationsDataService.fetchReports$(0, 5);
        });
      }
    });
  }

  editclimatedatapckConfiguraion(report: NewclimatedatapackconfigurationForm) {
    const dialogRef: MatDialogRef<NewclimatedatapackconfigurationPopupComponent, NewclimatedatapackconfigurationForm> = this.dialog.open(NewclimatedatapackconfigurationPopupComponent, {
      ...this.baseDialogConfig,
      data: report,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var that = this;
        console.log(data);
        this.climatedatapackconfigurationDataService.updateclimatedatapackconfigurationIndex(data).subscribe(result => {
          that.climatedatapackdataSource$ = this.climatedatapackconfigurationDataService.fetchReports$(0, 5);
        });
        ;
      }
    });

  }

  editEsgConfiguraion(report: NewEsgconfigurationForm) {
    const dialogRef: MatDialogRef<NewEsgconfigurationPopupComponent, NewEsgconfigurationForm> = this.dialog.open(NewEsgconfigurationPopupComponent, {
      ...this.baseDialogConfig,
      data: report,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var that = this;
        console.log(data);
        this.esgconfigurationDataService.updateEsgconfigurationIndex(data).subscribe(result => {
          that.dataSourceEsg$ = this.esgconfigurationDataService.fetchReports$(0, 5);
        });
        ;
      }
    });

  }

  editCoverageConfig(report: NewCoverageConfigForm) {
    const dialogRef: MatDialogRef<NewCoverageConfigPopupComponent, NewCoverageConfigForm> = this.dialog.open(NewCoverageConfigPopupComponent, {
      ...this.baseDialogConfig,
      data: report,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var that = this;
        console.log(data);
        this.coverageConfigDataService.updateCoverageConfig(data).subscribe(result => {
          that.dataSourceCoverage$ = this.coverageConfigDataService.fetchReports$(0, 5);
        });
        ;
      }
    });

  }
  editEsgExcludes(report: NewEsgExcludesForm) {
    const dialogRef: MatDialogRef<NewEsgExcludesPopupComponent, NewEsgExcludesForm> = this.dialog.open(NewEsgExcludesPopupComponent, {
      ...this.baseDialogConfig,
      data: report,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var that = this;
        console.log(data);
        this.esgExcludesDataService.updateEsgExcludes(data).subscribe(result => {
          that.dataSourceEsgExcludes$ = this.esgExcludesDataService.fetchEsgExcludesReports$(0, 5);
        });
        ;
      }
    });

  }
  editEsgExcludesPriority(report: NewEsgExcludesPriorityForm) {
    const dialogRef: MatDialogRef<NewEsgExcludesPriorityPopupComponent, NewEsgExcludesPriorityForm> = this.dialog.open(NewEsgExcludesPriorityPopupComponent, {
      ...this.baseDialogConfig,
      data: report,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var that = this;
        console.log(data);
        this.esgExcludesDataService.updateEsgExcludesPriority(data).subscribe(result => {
          that.dataSourceEsgExcludesPriority$ = this.esgExcludesDataService.fetchEsgExcludesPriorityReports$(0, 5);
        });
        ;
      }
    });

  }
  editEsgConfiguraionFixedIncome(report: NewEsgconfigurationfixedincomeForm) {
    const dialogRef: MatDialogRef<NewEsgconfigurationFixedincomePopupComponent, NewEsgconfigurationfixedincomeForm> = this.dialog.open(NewEsgconfigurationFixedincomePopupComponent, {
      ...this.baseDialogConfig,
      data: report,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var that = this;
        console.log(data);
        this.esgconfigurationfixedincomeDataService.updateEsgconfigurationFixedIncome(data).subscribe(result => {
          that.dataSourceEsgfixedincome$ = this.esgconfigurationfixedincomeDataService.fetchReportsFixedIncome$(0, 5);
        });
        ;
      }
    });

  }
  editSovereignconfiguration(report: NewEsgconfigurationForm) {
    const dialogRef: MatDialogRef<NewSovereignconfigurationPopupComponent, NewSovereignConfigurationForm> = this.dialog.open(NewSovereignconfigurationPopupComponent, {
      ...this.baseDialogConfig,
      data: report,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var that = this;
        console.log(data);
        this.sovereignConfigurationDataService.updateSovereignConfiguration(data).subscribe(result => {
          that.dataSourceSovereignConfiguration$ = this.sovereignConfigurationDataService.fetchReports$(0, 5);
        });
        ;
      }
    });

  }
  editSovereignconfigurationIndex(report: NewSovereignConfigurationIndexForm) {
    const dialogRef: MatDialogRef<NewSovereignconfigurationIndexPopupComponent, NewSovereignConfigurationIndexForm> = this.dialog.open(NewSovereignconfigurationIndexPopupComponent, {
      ...this.baseDialogConfig,
      data: report,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var that = this;
        console.log(data);
        this.sovereignConfigurationDataService.updateSovereignConfigurationIndex(data).subscribe(result => {
          that.dataSourceSovereignConfigurationIndex$ = this.sovereignConfigurationDataService.fetchReportsSovereign$(0, 5);
        });
        ;
      }
    });

  }
  editVanguardConfiguration(report: NewVanguardConfigurationsForm) {
    const dialogRef: MatDialogRef<NewVanguardConfigurationPopupComponent, NewVanguardConfigurationsForm> = this.dialog.open(NewVanguardConfigurationPopupComponent, {
      ...this.baseDialogConfig,
      data: report,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var that = this;
        console.log(data);
        this.coverageConfigDataService.updateVanguardConfigurations(data).subscribe(result => {
          that.dataSourceVanguardConfiguration$ = this.coverageConfigDataService.fetchVanguardConfigurationsReports$(0, 5);
        });
        ;
      }
    });

  }
  editeupabdates(report: NeweupabdatesForm) {
    const dialogRef: MatDialogRef<NeweupabdatesPopupComponent, NeweupabdatesForm> = this.dialog.open(NeweupabdatesPopupComponent, {
      ...this.baseDialogConfig,
      data: report,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var that = this;
        console.log(data);
        this.eupabdatesDataService.updateeupabdatesIndex(data).subscribe(result => {
          that.dataSourceEsg$ = this.eupabdatesDataService.fetchReports$(0, 5);
        });
        ;
      }
    });

  }
  editeupabIndex(report: NeweupabindexForm) {
    const dialogRef: MatDialogRef<NewEUPABIndexconfigurationPopupComponent, NeweupabindexForm> = this.dialog.open(NewEUPABIndexconfigurationPopupComponent, {
      ...this.baseDialogConfig,
      data: report,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var that = this;
        console.log(data);
        this.eupabindexDataService.updateeupabindexIndex(data).subscribe(result => {
          that.dataSourceEUPABIndex$ = this.eupabindexDataService.fetchReports$(0, 5);
        });
        ;
      }
    });

  }
  editgsxconfigurations(report: NewgsxconfigurationsForm) {
    const dialogRef: MatDialogRef<NewgsxconfigurationsPopupComponent, NewgsxconfigurationsForm> = this.dialog.open(NewgsxconfigurationsPopupComponent, {
      ...this.baseDialogConfig,
      data: report,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var that = this;
        console.log(data);
        this.gsxconfigurationsDataService.updategsxconfigurationsIndex(data).subscribe(result => {
          that.gsxconfigurationsdataSource$ = this.gsxconfigurationsDataService.fetchReports$(0, 5);
        });
        ;
      }
    });

  }
  downloadPAIConfiguration(): void {
    this.paiconfigurationDataService.downloadExcel(this.indexCode).then((res) => {
      let data = res;
      alert("Click OK to download PAIConfiguration List");
      if (data != undefined && data !== "") {
        // call download file method
        this.downloadFile(data);
        console.log(data);   // window.location.href = data;
        alert("Export  PAIConfiguration Report download is ready");
      } else if (data == "") {
        alert("No data found.");
      }

    });
  }
  openDialogPaiConfiguration(): void {
    const dialogRef: MatDialogRef<NewPAIConfigurationPopupComponent, NewPAIConfigurationForm> = this.dialog.open(NewPAIConfigurationPopupComponent);
    dialogRef.afterClosed().subscribe((reportForm) => {
      if (reportForm) {
        var that = this;
        this.paiconfigurationDataService.creatpaiconfiguration(reportForm).subscribe((res) => {
          that.paidataSource$ = this.paiconfigurationDataService.fetchReports$(0, 5);
        });
      }
    });
  }
  editPaiConfiguration(report: NewPAIConfigurationForm) {
    const dialogRef: MatDialogRef<NewPAIConfigurationPopupComponent, NewPAIConfigurationForm> = this.dialog.open(NewPAIConfigurationPopupComponent, {
      ...this.baseDialogConfig,
      data: report,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        var that = this;
        console.log(data);
        this.paiconfigurationDataService.updatepaiconfigurationIndex(data).subscribe(result => {
          that.paidataSource$ = this.paiconfigurationDataService.fetchReports$(0, 5);
        });
        ;
      }
    });

  }
  ngAfterViewInit() {
    let yourElem = <HTMLElement>document.querySelector('.fa-angle-double-left');
    if (yourElem != null) {
      yourElem.click();
    }
  }
}

