import { Component, OnInit } from '@angular/core';
import { AdminReportService } from '../../services/adminreportservice';
import { WaveData } from '../../models/waves-data.model';
import { BiService } from '../../services/BiService.service';

@Component({
  selector: 'app-waves',
  templateUrl: './waves.component.html',
  styleUrls: ['./waves.component.css']
})
export class WavesComponent implements OnInit {
  today = new Date(Date.now());
  inputdate = '';
  waves: WaveData[] = [];

  constructor(private AdminReportService: AdminReportService, private biService: BiService) {
    this.today.setDate(this.today.getDate() - 1);
    this.inputdate = this.today.toISOString().split('T')[0];
  }

  GetWaves() {
    this.biService.addEvent2("Get Waves", [{ Name: "inputdate", Value: this.inputdate }]);
    this.AdminReportService.getWavesData(this.inputdate);
  }

  ngOnInit() {
    this.AdminReportService.getWavesData(this.inputdate);
    this.AdminReportService.wavesdataLoaded.subscribe(
      (data: WaveData[]) => {
        this.waves = data;
      });
  }
}
