import { Component , OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticateService } from '../../services/authenticate.service';
import { BehaviorSubject } from 'rxjs';
import { AppComponent } from '../../app.component';
import { environment } from '../../../environments/environment';
import { EnvSpecific } from '../../models/env-specific';
import {LZStringService} from 'ng-lz-string';
import { Crypt, RSA } from "hybrid-crypto-js";

@Component({
    selector: 'startup-page',
    templateUrl: './startup-page.component.html',
    styleUrls: ['./startup-page.component.css']
})
export class StartupPageComponent implements OnInit {    
    @Output() getLoginActiveStatusChange = new EventEmitter<boolean>();

    isCognito = false;
    isLoggedIn = false;
    
    publicKey:string = `-----BEGIN PUBLIC KEY-----
    MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAoFflTjsq9XsgTyq5G7oG
    QC/6fIfRvr00QXyqEQPCdYK+8gsSf+16ReH6qQ0VdIDIK98c0LCBvqmyAO4cFywh
    UMRMt8GZlA27PUBQmhK2c786cY6lH6c+G+W2HkB2g/TCoKsgnunWB7aILKcJg1dR
    p4ON8GvbcSPJNLKDNnK1Us+9EoEHAwvdsJoENYVf/kGdp//3TVV8ylO4Q+s/K3cf
    eQouWj775L6pTp1oa/6u4umWJ4MAQTkCTl993he8EPVCb/uv5mF/dM2iWLH/HgM+
    YYJlsod2ZO1BXX+GF1RsGw7Ng/AK0EvKsliL5VvDX03a66RAxRe1vu6Z5B4JrZFv
    bpqVHti/yfHltID9XBlkpZ047JJTuXWr1/9nx0cPX6GNyiWSpif0fS9iBbOh6Kdm
    J1hvl7blssAZvD8g7giRtnZPS8p1SFvioIETVLeDclOo9M9SDL7hcEqX4tLBYEaa
    koAx31SkOIMr0U22ejntkergdtlPy1AS2D6aU7SY5W3ipwTiMyZin7BTr5ETdlNT
    YqyIXDz0AabGf8nHt7SFiV5yPPkitgF0ySTj2/VBlubDGvMdISZW7tC5vzMvBaX1
    aN9SKSTBetjPWmMKGf32LY/UHH+OlsNzs2yUnPFZ3j3qXEqyTukmmKL0NvGsfmLz
    Lqwbi85O5VlLZe5dMzUsJrECAwEAAQ==
    -----END PUBLIC KEY-----`;
    constructor(private authService: AuthenticateService,
      private lz: LZStringService) {

    }
    ngOnInit(): void {        
      if(this.authService.getParameterByName('SAMLResponse','') != null){
        this.authService.adfsSignIn();
        this.isLoggedIn = true;
        this.isCognito = false; 
      }
    }     
    
    adfsLogin(){    
        this.authService.adfsSignIn();
    }
    
    cognitoLogin(){
     // localStorage.removeItem("samlResponse");
      localStorage.removeItem('ADFSAuthenticated');
        this.isCognito = true;        
    }    

    loginCallback(event):void{
        if(event) {         
          this.isLoggedIn = true;
          this.isCognito = false;
        }
        else{
          this.isCognito = false;
          this.isLoggedIn = false;
        }
      }
    
    encryptMessage(message:string):any{
        var crypt = new Crypt({  });
        var rsa = new RSA({});
        var encryptedMessage = crypt.encrypt(this.publicKey,message);
        return encryptedMessage;
    }
 }