import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ResponseModel } from '../models/landing-page-model';
import { TabDetails, NameDetails } from './../models/gerographical-charts';
import { Authorization } from 'aws-sdk/clients/mediapackage';
import { RoleDetail } from '../models/authorization';
import { MenuItemModel, PageAuthModel } from '../models/pageAuthModel';
import { UserGroupModel } from '../models/authorization';
import { resolveCname } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class PageAuthenticationService {

  constructor(private httpClient: HttpClient) { }

  menuItems(MenuItemsmodel): Promise<MenuItemModel[]> {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/pageauthorization/TabDetails',
      JSON.stringify(MenuItemsmodel),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as Array<any>;
      }).catch(this.handleErr);
  }
  saveFields(SaveMenuItemsmodel): Promise<any> {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/pageauthorization/RoleAuthorization',
      // JSON.stringify(SaveMenuItemsmodel),
      JSON.stringify({ RoleName: SaveMenuItemsmodel.RoleName, MenuItemModel: SaveMenuItemsmodel.MenuItemModel }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as Array<any>;
      }).catch(this.handleErr);
  }

  saveUserRoleMapping(SaveRoleModel): Promise<any> {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/userSave/SaveUserGroup',
      // JSON.stringify(SaveMenuItemsmodel),
      JSON.stringify({ RoleID: SaveRoleModel.RoleID, UserGroup: SaveRoleModel.UserGroup }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as Array<any>;
      }).catch(this.handleErr);





  }

  GetValues(RoleID): Promise<RoleDetail[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/pageauthorization/RoleDetails',
      JSON.stringify({ RoleID: RoleID }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as RoleDetail[];
      }).catch(this.handleErr);
  }

  getSelectedInfo(RoleID): Promise<UserGroupModel[]> {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/userSave/fetchData',
      JSON.stringify({ RoleID: RoleID }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as UserGroupModel[];
      }).catch(this.handleErr);
  }


  roleNames(): Promise<Authorization[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(environment.dataServiceRoot + 'ui/pageauthorization/RoleNames',
      null,
      { headers: headers })
      .toPromise()
      .then(response => {
        var result = response as ResponseModel;
        console.log(result);
        return result.data as Authorization[];

      })
      .catch(this.handleErr);


  }

  Delete(id): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/pageauthorization/DeletePageAuth',
      JSON.stringify(id),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as Array<any>;
      }).catch(this.handleErr);


  }








  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

}
