import { Injectable } from '@angular/core';
import { NewReportForm, Report, ReportData, ExportReportDetails, ExportReportDetailsColumns, ExportReportData } from '../models/report';
import { BehaviorSubject, Observable, of, from, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewEsgconfigurationForm } from '../models/esgconfiguration';
import { ResponseModel } from '../../models/landing-page-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { debug } from 'console';


@Injectable({
  providedIn: 'root'
})
export class EsgconfigurationDataService {

  public isFormValid = true;
  constructor(private httpClient: HttpClient) { }

  private _listners = new Subject<any>();
  clientPublish(): Observable<any> {
    return this._listners.asObservable();
  }


  publishClient(client: string) {
    this._listners.next(client);
  }

  fetchReports$(pageIndex, pageSize): Observable<any> {
    return from(this.GetEsgconfigurationData(pageIndex, pageSize));
  }
  fetchEsgconfigurationData$(pageIndex, pageSize,filter): Observable<any> {
    return from(this.GetEsgconfigurationDataByFilter(pageIndex, pageSize,filter));

  }
  
  creatEsgconfiguration(esgconfigurationForm: NewEsgconfigurationForm): Observable<string> {
    this.saveNewEsgconfiguration(esgconfigurationForm).then((a) => {
      if (a.data != 0) {
        //this.publishClient("created esgconfiguration data successfully!");
      }
      else {
        //this.publishClient(a.meta.message);
      }
    });
    return of(esgconfigurationForm.indexCode);

  }

  updateEsgconfigurationIndex(esgconfigurationForm: NewEsgconfigurationForm): Observable<string> {
    this.saveEsgconfiguration(esgconfigurationForm).then((a) => {
      if (a.data != 0) {
        // this.publishClient("Updated esgconfiguration data successfully!");
      }
      else {
        // this.publishClient(a.data.message);
      }
    });
    return of(esgconfigurationForm.indexCode);
  }

  validateEsgconfiguration(indexCode: string): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/ValidateEsgconfigurations', JSON.stringify({ indexCode: indexCode }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data
      }).catch(this.handleErr);
  }
 
  saveNewEsgconfiguration(esgconfigurationForm: NewEsgconfigurationForm): any {
    if (esgconfigurationForm.parentIndexCode !=undefined ? esgconfigurationForm.indexCode.toLowerCase() != esgconfigurationForm.parentIndexCode.toLowerCase() : true) {
      this.validateEsgconfiguration(esgconfigurationForm.indexCode).then((res) => {
        if (res) {
          alert('Esg configuration data already exists!');
        }
        else {   
          this.isFormValid = true;
          let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          return this.httpClient.post(
            environment.dataServiceRoot + 'ui/SITableConfigurations/AddEsgconfigurations',
            JSON.stringify({
              IndexCode: esgconfigurationForm.indexCode,
              IndexFamilyId: esgconfigurationForm.indexFamilyId,
              ParentIndexCode: esgconfigurationForm.parentIndexCode,
              DerivedIndex: esgconfigurationForm.derivedIndex,
              Currency: esgconfigurationForm.currency,
              Country: esgconfigurationForm.country,
              NicbIndustry: esgconfigurationForm.nicbIndustry              
            }),

            { headers: headers }).toPromise()
            .then(response => {
              var result = response as ResponseModel;
              if (result.data != 0) {
                this.publishClient("Esgconfiguration data created successfully!");
              }
              else {
                this.publishClient(result.meta.message);
              }
              
            }).catch(this.handleErr);
        }
      });
    }
    else {
      alert('Index code and Parent Index code must be different');
    }
  }

  saveEsgconfiguration(esgconfigurationForm: NewEsgconfigurationForm): any {

  if (esgconfigurationForm.parentIndexCode !=undefined ? esgconfigurationForm.indexCode.toLowerCase() != esgconfigurationForm.parentIndexCode.toLowerCase() : true) {
      this.isFormValid = true;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(
        environment.dataServiceRoot + 'ui/SITableConfigurations/UpdateEsgconfigurations',
        JSON.stringify({
          IndexCode: esgconfigurationForm.indexCode,
          IndexFamilyId: esgconfigurationForm.indexFamilyId,
          ParentIndexCode: esgconfigurationForm.parentIndexCode,
          DerivedIndex: esgconfigurationForm.derivedIndex,
          Currency: esgconfigurationForm.currency,
           Country: esgconfigurationForm.country,
          NicbIndustry: esgconfigurationForm.nicbIndustry            
        }),

        { headers: headers }).toPromise()
        .then(response => {
          var result = response as ResponseModel;
          if (result.data != 0) {
            this.publishClient("Updated Esgconfiguration data successfully!");
          }
          else {
            this.publishClient(result.meta.message);
          }
        }).catch(this.handleErr);
    } else {
      alert('Index code and Parent Index code must be different');
    }
  }


  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  GetEsgconfigurationData(pageIndex, pageSize): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EsgconfigurationList', JSON.stringify({ page: pageIndex, pageSize: pageSize }),
      { headers: headers });
  }

  GetEsgconfigurationDataByFilter(pageIndex, pageSize, filter): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EsgconfigurationListByFilter', JSON.stringify({ pageSize: pageSize, page: pageIndex, filter: filter }),
      { headers: headers });
  }
  GetindexList(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EsgconfigurationIndexList', null,
      { headers: headers });
  }

  
    GetTableConfigurations(): Observable<any> {
      
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/TableConfigurations', null,
      { headers: headers });
  }
  
 

 getCurrencies():Observable<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'ui/SITableConfigurations/GetCurrenciesList',null,
      {headers: headers});
  }
   getCountries():Observable<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'ui/SITableConfigurations/GetCountriesList',null,
      {headers: headers});
  }
  deleteEsgconfigurationData(esgconfigurationForm: any): any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DeleteEsgconfigurations',
      JSON.stringify({
        IndexCode: esgconfigurationForm.IndexCode
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        this.publishClient("Esgconfiguration data has been deleted");
        return result;
      }).catch(this.handleErr);
  }

  downloadExcel(indexCode:string): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DownloadExcel',
      JSON.stringify({
      IndexCode: indexCode
      }),
      { headers: headers }).toPromise()
      .then(response => {
          var result = response;
          return result;
    })
        .catch (this.handleErr);
  }
  

 

  }

