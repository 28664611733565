import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {map} from 'rxjs/operators';
import {BenchmarksService} from '../../services/benchmarks.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  fc = new FormControl('');
  @Output() update = new EventEmitter<string>();
  @Input() indexMarkers;
  sub = this.fc.valueChanges.pipe(
    map((date: Date) => date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : '')
  ).subscribe((value) => {
    this.onChange(value);
    this.update.emit(value);
  });
  onChange = (val: any) => {
    
  };
  onTouched = (val: any) => {};
  constructor( private benchmarksService: BenchmarksService,) {}

  writeValue(obj: string | any): void {
    this.fc.setValue(typeof obj === 'string' ? this.parseString(obj) : new Date(obj), {emitEvent: false});
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.fc.patchValue(new Date());
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line:no-unused-expression
    this.sub && this.sub.unsubscribe();
  }

  parseString(str: string): Date {
    const [year, month, day] = str.split('-').map(parseFloat);
    const date = new Date();
    date.setUTCFullYear(year);
    date.setUTCMonth(month - 1);
    date.setUTCDate(day);
    return date;
  }
}
