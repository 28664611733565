import { AfterViewInit, Component, ElementRef, OnInit, ViewChildren, QueryList, HostListener } from '@angular/core';
import { ChartOptions } from 'chart.js';
import { ReportsService } from '../../services/reports.service';
import { GoToPageDialogComponent } from '../go-to-page-popup/go-to-page-popup.component';
import {MatDialog} from '@angular/material/dialog';
import DOMPurify from 'dompurify';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image-improved';
import { Portfolio } from '../../models/portfolio';
import { Benchmark } from '../../models/benchmark';
import { ReportData } from '../../models/report';
import { formatDate, DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
// tslint:disable-next-line
console.log(window['DOMPurify'] = DOMPurify, html2canvas);
import jsPDF, { HTMLOptions } from 'jspdf';
import { IndexValues } from '../../../models/index-values';
import { timeout } from 'rxjs/operators';
import { Console } from 'console';


@Component({
  selector: 'app-indexto-index-report-print-page',
  templateUrl: './indexto-index-report-print-page.component.html',
  styleUrls: ['./indexto-index-report-print-page.component.scss']
})
export class IndextoIndexReportPrintPageComponent implements OnInit {

  @ViewChildren('page') pages: QueryList<ElementRef>;
  
  @HostListener('document:keydown.control.g', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent)
  {
      let dialogRef = this.dialog.open(GoToPageDialogComponent);

      dialogRef.afterClosed().subscribe(result => {
        if (result != '' && result != null && result > 0 && result <= this.pages.length) {
          this.pages.toArray()[result-1].nativeElement.scrollIntoView();
        }
      })
      event.preventDefault();
  
  }

    reportData$: ReportData = {
      portfolio: { id: 0, description: null, marketValue: null,isMarketValueFromTemplate: false, benchMark: null,benchMarkDest: null, name: null, assetClass: null, currency: null, asOfDate: null, sourceBenchmark:null },
      benchmark: { indexMarker: null, asOfDate: null, consCode: null, constituentName: null, countryCode: null, isoCode: null, marketCapCurrency: null, indexName: null, isin: null, weight: null, segmentOfAnalysis: null, assetClass: null },
      performanceData: [],
      performanceLabels: [],
      waciYears:[],
      overWeightData: [],
      overWeightLabels: [],
      countryBreakdownData: [],
      countryBreakdownLabels: [],
      regionalBreakdownData: [],
      regionalBreakdownLabels: [],
      //top10ByWeights: [],
      grTop10ByPfWeight: [],
      waciTop10ByPfWeight: [],
      waciTop10ByContribution: [],
      grTop10ByContribution: [],
      evicTop10ByPfWeight: [],
      evicTop10ByContribution: [],
      fossilfuelTop10ByContributionPfWeightOng: [],
      fossilfuelTop10ByContributionPfWeightCoal: [],
      sectorDetailsTable: [],
      carbonExposureData: [],
      carbonExposureLabels: [],
      greenRevenueData: [],
      greenRevenueLabels: [],
      fossilFuelCoalEmissionData: [],
      fossilFuelCoalEmissionLabels: [],
      fossilFuelOngEmissionData: [],
      fossilFuelOngEmissionLabels: [],
      greenRevenueSegmentData: [],
      fossilFuelSectorData: [],
      waciAttributionEffectData: [],
      waciAttributionEffectByScopeData: [],
      evicAttributionEffectData: [],
      evicAttributionEffectByScopeData: [],
      grPfCoverageData : [],
      grPfCoverageLabels : [],
      grBmCoverageData : [],
      grBmCoverageLabels : [],
      waciBmCoverageLabels :[],
      waciBmCoverageData:[],
      waciPfCoverageLabels:[],
      waciPfCoverageData:[],
      gricbSectorGapData : [],
      gricbSectorGapLabels : [],
      waciIndustryLabels :[],
      waciIndustryBenchmarkData: [],
      waciIndustryPortfolioData: [],
      waciCountryLabels :[],
      waciCountryBenchmarkData: [],
      waciCountryPortfolioData: [],
      totalMarketValue:0,
      portfolioValues:0,
      waciSectorCtbLabels : [],
      waciSectorCtbPortfolioData : [],
      waciSectorCtbBenchmarkData : [],
      evicSectorCtbLabels : [],
      evicSectorCtbPortfolioData : [],
      evicSectorCtbBenchmarkData : [],
      waciPortfolioHistoricData : [],
      waciBenchmarkHistoricData : [],
      waciHistoricYearLabels : [],
      wacikrRevenueExposureData : [],
      wacikrRevenueExposureLabel : [],
      wacikrSectorGapsData : [],
      wacikrSectorGapsLabel : [],
      evickrRevenueExposureData : [],
      evickrRevenueExposureLabel : [],
      evickrSectorGapsData : [],
      evickrSectorGapsLabel : [],
      evicaeEstimateLabel :[],
      evicaeEstimateData :[],
      waciHistoricCAGRData : [],
      fossilBmCoverageLabels :[],
      fossilBmCoverageData:[],
      fossilPfCoverageLabels:[],
      fossilPfCoverageData:[],
      historicBMCoverageData :[],
      historicBMNonCoverageData :[],
      historicPFCoverageData :[],
      historicPFNonCoverageData :[],
      tpimqBmCoverageLabels :[],
      tpimqBmCoverageData:[],
      tpimqPfCoverageLabels:[],
      tpimqPfCoverageData:[],
      tpimqTop10ByPfWeight:[],
      tpimqTop10ByContribution:[],
      tpimqScoreData: [],
      tpimqScoreLabels: [],
      tpimqScoreSectorGapsData: [],
      tpimqScoreSectorGapsLabels: [],
      ffTop10ByContributionData :[],
      fossilFuelSectorGapsData: [],
      fossilFuelSectorGapsLabels: [],
      evicaeSectorDetailsTable:[],
      evicaeHistoricBMNonCoverageData:[],
      evicaeHistoricBMCoverageData:[],
      evicaeHistoricPFCoverageData:[],
      evicaeHistoricPFNonCoverageData:[],
      evicaeBenchmarkHistoricData: [],
      evicaePortfolioHistoricData: [],
      evicaeHistoricYearLabels: [],
      evicaeHistoricCAGRData: [],
      evicaePfCoverageData: [],
      evicaePfCoverageLabels: [],
      evicaeBmCoverageData: [],
      evicaeBmCoverageLabels: [],
      crpiCoalBmCoverageData: [],
      crpiCoalBmCoverageLabels: [],
      crpiCoalPfCoverageData: [],
      crpiCoalPfCoverageLabels: [],
      crpiOilBmCoverageData: [],
      crpiOilBmCoverageLabels: [],
      crpiOilPfCoverageData: [],
      crpiOilPfCoverageLabels: [],
      pfAndBmPerformanceData: [],
      pfAndBmPerformanceLabels: [],
      metaDataData:[],
      metaDataLabels: [],
     };
    reportDate: string;
    PortfolioName: string;
    PortfolioId : number;
  
    DateofHolding: string;
    ReportChartLoader: boolean = true;
    ReportChartLoaderView: boolean = true;
    ReportChartBMLoaderView: boolean = true;
    reportObject: any;
    //top10ByWeightsTable: any;
    totaldata: any;
    totalData: any[];
    stageIndexDetails: any;
    waciExposureDetails: any;
    evicExposureDeatils: any;
    evicEstimateDetails:any;
    bmHistoricCoverage :any;
    pfHistoricCoverage : any;
    evicaeBMHistoricCoverage : any;
    evicaePFHistoricCoverage :any;
    colors11: string[] = [
      '#001EFF',
      '#7F7F7F',
      '#7CAFDD',
      '#B7B7B7',
      '#99A5FF',
      '#636363',
      '#264478',
      '#255E91',
      '#848484',
      '#698ED0',
      '#335AA1'
    ];
  
    colors3: string[] = ['#001EFF', '#7F7F7F', '#7F7F7F'];
  
    colorArray: string[] = ["#63be7b", "#81c77d", "#d3df82", "#f2e884", "#faea84", "#fcc47c", "#fdd07e", "#fbae78", "#fa9775", "#f8696b"];
    portfolioVsBenchmarkChartViewer: boolean = true;
    overweightChartViewer: boolean = true;
    carbonExposureScopeDataViewer: boolean = true;
    carbonExposureLineChartViewer: boolean = true;
    greenRevenueWeightsChartViewer: boolean = true;
    fossilfuelCoalEmissionChartViewer : boolean = true;
    fossilfuelOngEmissionChartViewer : boolean = true;
    tpimqScorePFBMChartViewer: boolean = true;
    greenRevenueSegmentChartViewer: boolean = true;
    waciAttributionEffectChartViewer: boolean = true;
    waciAttributionEffectByScopeChartViewer: boolean = true;
    evicAttributionEffectChartViewer: boolean = true;
    evicAttributionEffectByScopeChartViewer: boolean = true;
    fossilFuelSectorChartViewer: boolean = true;
    fossilFuelSectorGapViewer: boolean = true;
    reportDownloader: boolean = false; 
    waciCAGRChartViewer: boolean = true;
    waciTopPfWeightsAndContributions: string[] = ["Company", "IDX weight (%)", "GHG/Revenue (tCO2e/USDm)", "CTB to WACI WA (tCO2e/USDm)"]
    grTopPfWeightsAndContributions: string[] = ["Company", "IDX weight (%)", "GR share (%)", "Contribution to the GR WA"]
    evicTopPfWeightsAndContributions: string[] = ["Company", "IDX weight (%)", "GHG/EVIC (tCO2e/USD m)", "GHG/EVIC WA Contrib. (tCO2e/USDm)"]
    tpimqTopPfWeightsAndContributions: string[] =["Company","IDX weight (%)","TPI MQ Score (0-5)","Ctb to TPI MQ Score WA"]
    fossilTop10ByContribution: string[]= ["Company","IDX weight (%)","ICB Industry","ICB Subsector"]
    fossilfuelTop10ByContributionCoalandCoalandOng: string[]= ["Company","IDX weight (%)","Potential CO2/Rev. (tCO2/USDm)","Contribution to the WA (tCO2e/USDm)"]
    grTop10ByPfWeightsTable: any;
    grTop10ByContributionTable: any;
    evicTop10ByPfWeightsTable: any;
    evicTop10ByContributionTable: any;
    fossilfuelTop10ByContributionPfWeightOngTable: any;
    fossilfuelTop10ByContributionPfWeightCoalTable: any;
    waciTop10ByPfWeightsTable: any;
    waciTop10ByContributionTable:any;
    tpimqTop10ByPfWeightsTable: any;
    tpimqTop10ByContributionTable:any;
    fossilTop10ByContributionTable :any;
    historicDataCoverageHeaders: string[]=["Year","Covered","Non-Covered"]
    sectorDetailsTable: any;
    evicaeSectorDetailsTable: any;
    evicaesectorDetailsNames: string[]= ["Sector(ICB Industry)", "IDX weight (%)", "BM weight (%)", "IDX-BM (%)", "IDX GHG/EVIC (tCO2e/USD m)", "BM GHG/EVIC (tCO2e/USD m)", "IDX vs BM (sector % gap)", "IDX vs BM total (% gap)"]
    sectorDetailsNames: string[]= ["Sector(ICB Industry)", "IDX weight (%)", "BM weight (%)", "IDX-BM (%)", "IDX WACI (tCO2e/USDm)", "BM WACI (tCO2e/USDm)", "IDX vs BM (sector % gap)", "IDX vs BM total (% gap)"]
    labelData: string[] = ["IDX" ,"BM","IDX","BM"]
    evicaeSectorTotalData:any[];
    evicaeCAGRChartViewer: boolean = true;
    evicaePortfolioHistoricDataExist : boolean = true;
    evicaeBenchmarkHistoricDataExist: boolean = true;
    crpiOilPfData:any;
    crpiOilBmData:any;
    crpiCoalPfData:any;
    crpiCoalBmData:any;
    crpiPFCoverageChartViewer: boolean = true;
    crpiBMCoverageChartViewer: boolean = true;
    pfAndBmPerformanceViewer: boolean = true;
    pfBmPerformanceDetails:any;
    metaDataDetails: any;
    evicAeDataExist: boolean = true;
    evickrRevenueExposureDataExist: boolean = true;
    evickrSectorGapsDataExist: boolean = true;
    wacikrRevenueExposureDataExist : boolean = true; 
    wacikrSectorGapsDataExist : boolean = true; 
    waciSectorCtbPortfolioDataExist : boolean = true; 
    waciSectorCtbBenchmarkDataExist : boolean = true; 
    waciAttributionEffectDataExist : boolean = true;
    waciAttributionEffectByScopeDataExist : boolean = true;
  waciPortfolioHistoricDataExist : boolean = true; 
  waciBenchmarkHistoricDataExist   : boolean = true;
  evicSectorCtbPortfolioDataExist : boolean = true;
  evicSectorCtbBenchmarkDataExist : boolean = true;
  gricbSectorGapDataViewer: boolean = true;
  tpimqScoreDataViewer:boolean = true;
  tpimqScoreSectorGapsDataViewer:boolean = true;
    //Bar chart static data for Segment-comparision
    
  
  
  
    // Bar chart
    public barChartOptions: ChartOptions = {
      legend: false,
      showLines: false,
      responsive: true,
      maintainAspectRatio: false,
      devicePixelRatio: 3,
      // layout: {
      //   padding: {
      //     left: 100
      //   },
      // },
      // We use these empty structures as placeholders for dynamic theming.
      
      scales: {
        xAxes: [{
          gridLines: {
            drawBorder: false
          },
          ticks: {
            callback: function (value) {
              return value + "% ";
            },
          }
        }],
        yAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            lineHeight: 1,
            padding: -8,
          },
          barPercentage: 0.7,
          categoryPercentage: 0.55,
        }]
      },
      plugins: {
        labels: {
          render: () => {}
        }
      }
    };
    //console.log()
    constructor(private dialog:MatDialog, private reportService: ReportsService, private router: Router, private route: ActivatedRoute) { }
    ngOnInit(): void {
      var reportObject = JSON.parse(this.route.snapshot.paramMap.get('data'));
      console.log("reportObject");
      console.log(reportObject);
      this.reportDate = reportObject.reportDate;
      this.PortfolioName=reportObject.portfolioName;
      this.PortfolioId = reportObject.portfolioId;
      this.DateofHolding=reportObject.asOfDate;
      
      if(reportObject.template.includes("PrimeID"))
      {
        this.reportService.fetchReportCalculationsPrimeId$(reportObject.portfolioId, reportObject.benchMark, reportObject.asOfDate, reportObject.assetClass, reportObject.template).then(data => {
          if (data != undefined) {
            this.processResponse(reportObject , data);
          }
        });
      }else{
      this.reportService.fetchReportCalculations$(reportObject.portfolioId, reportObject.benchMark, reportObject.asOfDate, reportObject.assetClass, reportObject.template).then(data => {
        if (data != undefined) {
          this.processResponse(reportObject , data);
        }
      });
    }
    }

    processResponse(reportObject , data)
    {
  
      this.reportData$ = data;      
      //this.top10ByWeightsTable = data.top10ByWeights;
      this.grTop10ByContributionTable = data.grTop10ByContribution;
      this.grTop10ByPfWeightsTable = data.grTop10ByPfWeight;
      this.evicTop10ByContributionTable = data.evicTop10ByContribution;
      this.evicTop10ByPfWeightsTable = data.evicTop10ByPfWeight;
      this.fossilfuelTop10ByContributionPfWeightCoalTable = data.fossilfuelTop10ByContributionPfWeightCoal;
      this.fossilfuelTop10ByContributionPfWeightOngTable = data.fossilfuelTop10ByContributionPfWeightOng;
      this.waciTop10ByPfWeightsTable = data.waciTop10ByPfWeight;        
      this.waciTop10ByContributionTable = data.waciTop10ByContribution;
      this.tpimqTop10ByPfWeightsTable = data.tpimqTop10ByPfWeight;
      this.tpimqTop10ByContributionTable = data.tpimqTop10ByContribution;
      this.fossilTop10ByContributionTable = data.ffTop10ByContributionData;
      this.sectorDetailsTable = data.sectorDetailsTable;
      this.evicaeSectorDetailsTable = data.evicaeSectorDetailsTable;
      this.evicaeSectorTotalData = this.evicaeSectorDetailsTable[0];
      this.totalData = this.sectorDetailsTable[0];
       //Added by Rashmi card 414 slide 9 and 19
       if (data.waciAttributionEffectData.length != 0) {
        data.waciAttributionEffectData.forEach(item => {
          item.data =Number(item.data)*100;
        }); 
      }
      if (data.waciAttributionEffectByScopeData.length != 0) {
        data.waciAttributionEffectByScopeData.forEach(item => {
          item.data =Number(item.data)*100;
        }); 
      }
        if (data.evicAttributionEffectData.length != 0) {
        data.evicAttributionEffectData.forEach(item => {
          item.data =Number(item.data)*100;
        }); 
      }
        if (data.evicAttributionEffectByScopeData.length != 0) {
        data.evicAttributionEffectByScopeData.forEach(item => {
          item.data =Number(item.data)*100;
        }); 
      }

      //end

      // updating required values with 2 decimals
      
      this.grTop10ByContributionTable.forEach(item => {
        item.grPfCalculation =item.grPfCalculation==null?"0":Number(item.grPfCalculation)==0?"0.00":Number(item.grPfCalculation).toFixed(2);
        item.grPfWeight =item.grPfWeight==null?"0":Number(item.grPfWeight)==0?"0.00":Number(item.grPfWeight).toFixed(2);
        item.grShare = item.grShare==null?"0":Number(item.grShare)==0?"0.00":Number(item.grShare).toFixed(2);
      });
      this.grTop10ByPfWeightsTable.forEach(item => {
        item.grPfCalculation = item.grPfCalculation==null?"0":Number(item.grPfCalculation)==0?"0.00":Number(item.grPfCalculation).toFixed(2);
        item.grPfWeight =item.grPfWeight==null?"0":Number(item.grPfWeight)==0?"0.00":Number(item.grPfWeight).toFixed(2);
        item.grShare = item.grShare==null?"0":Number(item.grShare)==0?"0.00":Number(item.grShare).toFixed(2);
      });
      this.evicTop10ByContributionTable.forEach(item => {
        item.evicPfWeight = item.evicPfWeight==null?"0":Number(item.evicPfWeight)==0?"0.00":Number(item.evicPfWeight).toFixed(2);
        item.evicGhgRev = item.evicGhgRev==null?"0":Number(item.evicGhgRev)==0?"0.00":Number(item.evicGhgRev).toFixed(2);
        item.evicContribution = item.evicContribution==null?"0":Number(item.evicContribution)==0?"0.00":Number(item.evicContribution).toFixed(2);
      });
      this.evicTop10ByPfWeightsTable.forEach(item => {
        item.evicPfWeight =item.evicPfWeight==null?"0":Number(item.evicPfWeight)==0?"0.00":Number(item.evicPfWeight).toFixed(2);
        item.evicGhgRev = item.evicGhgRev==null?"0":Number(item.evicGhgRev)==0?"0.00":Number(item.evicGhgRev).toFixed(2);
        item.evicContribution = item.evicContribution==null?"0":Number(item.evicContribution)==0?"0.00":Number(item.evicContribution).toFixed(2);
      });
      this.fossilfuelTop10ByContributionPfWeightCoalTable.forEach(item => {
        item.crpiPfWeight =item.crpiPfWeight==null?"0":Number(item.crpiPfWeight)==0?"0.00": Number(item.crpiPfWeight).toFixed(2);
        item.crpiPotentialRev = item.crpiPotentialRev==null?"0":Number(item.crpiPotentialRev)==0?"0.00":Number(item.crpiPotentialRev).toFixed(2);
        item.crpiContribution = item.crpiContribution==null?"0":Number(item.crpiContribution)==0?"0.00":Number(item.crpiContribution).toFixed(2);
      });
      this.fossilfuelTop10ByContributionPfWeightOngTable.forEach(item => {
        item.crpiPfWeight = item.crpiPfWeight==null?"0":Number(item.crpiPfWeight)==0?"0.00": Number(item.crpiPfWeight).toFixed(2);
        item.crpiPotentialRev = item.crpiPotentialRev==null?"0":Number(item.crpiPotentialRev)==0?"0.00": Number(item.crpiPotentialRev).toFixed(2);
        item.crpiContribution =item.crpiContribution==null?"0":Number(item.crpiContribution)==0?"0.00": Number(item.crpiContribution).toFixed(2);
      });
      this.waciTop10ByPfWeightsTable.forEach(item => {
        item.pfWeightWACI =item.pfWeightWACI==null?"0":Number(item.pfWeightWACI)==0?"0.00":  Number(item.pfWeightWACI).toFixed(2);
        item.revenueWACI =item.revenueWACI==null?"0":Number(item.revenueWACI)==0?"0.00": Number(item.revenueWACI).toFixed(2);
        item.contributionWACI =item.contributionWACI==null?"0":Number(item.contributionWACI)==0?"0.00":  Number(item.contributionWACI).toFixed(2);
      });
      this.waciTop10ByContributionTable.forEach(item => {
        item.pfWeightWACI =item.pfWeightWACI==null?"0":Number(item.pfWeightWACI)==0?"0.00": Number(item.pfWeightWACI).toFixed(2);
        item.revenueWACI =item.revenueWACI==null?"0":Number(item.revenueWACI)==0?"0.00": Number(item.revenueWACI).toFixed(2);
        item.contributionWACI =item.contributionWACI==null?"0":Number(item.contributionWACI)==0?"0.00": Number(item.contributionWACI).toFixed(2);
      });
      this.tpimqTop10ByPfWeightsTable.forEach(item => {
        item.pfWeightTpimq = item.pfWeightTpimq==null?"0":Number(item.pfWeightTpimq)==0?"0.00":  Number(item.pfWeightTpimq).toFixed(2);
        item.tpimqContribution =item.tpimqContribution==null?"0":Number(item.tpimqContribution)==0?"0.00":  Number(item.tpimqContribution).toFixed(2);
        item.tpimqScore =item.tpimqScore==null?"0":Number(item.tpimqScore)==0?"0.00":  Number(item.tpimqScore).toFixed(2);
      });
      this.tpimqTop10ByContributionTable.forEach(item => {
        item.pfWeightTpimq = item.pfWeightTpimq==null?"0":Number(item.pfWeightTpimq)==0?"0.00":  Number(item.pfWeightTpimq).toFixed(2);
        item.tpimqContribution = item.tpimqContribution==null?"0":Number(item.tpimqContribution)==0?"0.00": Number(item.tpimqContribution).toFixed(2);
        item.tpimqScore =item.tpimqScore==null?"0":Number(item.tpimqScore)==0?"0.00": Number(item.tpimqScore).toFixed(2);
      });
      this.fossilTop10ByContributionTable.forEach(item => {
        item.fossilPFWeight =item.fossilPFWeight==null?"0":Number(item.fossilPFWeight)==0?"0.00":  Number(item.fossilPFWeight).toFixed(2);
      });
      
      this.bmHistoricCoverage= data.historicBMCoverageData.map((rec, index) => {
        return {
          label: rec.label,
          covered: data.historicBMCoverageData[index].data.toFixed(2),
          noncovered: data.historicBMNonCoverageData[index].data.toFixed(2)
        }
      });
      this.pfHistoricCoverage= data.historicPFCoverageData.map((rec, index) => {
        return {
          label: rec.label,
          covered: data.historicPFCoverageData[index].data.toFixed(2),
          noncovered: data.historicPFNonCoverageData[index].data.toFixed(2)
        }
      });
      this.evicaeBMHistoricCoverage= data.evicaeHistoricBMCoverageData.map((rec, index) => {
        return {
          label: rec.label,
          covered: data.evicaeHistoricBMCoverageData[index].data.toFixed(2),
          noncovered: data.evicaeHistoricBMNonCoverageData[index].data.toFixed(2)
        }
      });
      this.evicaePFHistoricCoverage= data.evicaeHistoricPFCoverageData.map((rec, index) => {
        return {
          label: rec.label,
          covered: data.evicaeHistoricPFCoverageData[index].data.toFixed(2),
          noncovered: data.evicaeHistoricPFNonCoverageData[index].data.toFixed(2)
        }
      });

     this.roundOffTwoDecimals(this.evicaeSectorDetailsTable);
     this.roundOffTwoDecimals(this.sectorDetailsTable);
     this.pfBmPerformanceDetails = data.pfAndBmPerformanceLabels.reduce((acc, k, i) => ({ ...acc, [k]:data.pfAndBmPerformanceData[i]}), {});
     console.log(this.pfBmPerformanceDetails);
  
    // console.log('fify');
     this.metaDataDetails = data.metaDataLabels.reduce((acc, k, i) => ({ ...acc, [k]: (data.metaDataData[i]) }), {});
    // console.log('dhanya');
     console.log(this.metaDataDetails);
      //this.totaldata = this.top10ByWeightsTable[0];
      this.stageIndexDetails = data.carbonExposureLabels.reduce((acc, k, i) => ({ ...acc, [k]: data.carbonExposureData[i] }), {});
      this.waciExposureDetails= data.wacikrRevenueExposureLabel.reduce((acc, k, i) => ({ ...acc, [k]: data.wacikrRevenueExposureData[i] }), {});
      if (data.wacikrRevenueExposureData.length != 0) {
        this.wacikrRevenueExposureDataExist = (data.wacikrRevenueExposureData.every(item => item === 0) == true) ? true : false;
      }

      this.evicExposureDeatils = data.evickrRevenueExposureLabel.reduce((acc, k , i) =>({...acc, [k]: data.evickrRevenueExposureData[i] }), {});
      if (data.evickrRevenueExposureData.length != 0) {
        this.evickrRevenueExposureDataExist = (data.evickrRevenueExposureData.every(item => item === 0) == true) ? true : false;
      }

      //this.evicEstimateDetails = data.evicaeEstimateLabel.reduce((acc, k , i) =>({...acc, [k]: data.evicaeEstimateData[i] }), {});
      this.crpiOilPfData = data.crpiOilPfCoverageLabels.reduce((acc, k, i) => ({ ...acc, [k]: data.crpiOilPfCoverageData[i] }), {});
      this.crpiOilBmData = data.crpiOilBmCoverageLabels.reduce((acc, k, i) => ({ ...acc, [k]: data.crpiOilBmCoverageData[i] }), {});
      this.crpiCoalPfData = data.crpiCoalPfCoverageLabels.reduce((acc, k, i) => ({ ...acc, [k]: data.crpiCoalPfCoverageData[i] }), {});
      this.crpiCoalBmData = data.crpiCoalBmCoverageLabels.reduce((acc, k, i) => ({ ...acc, [k]: data.crpiCoalBmCoverageData[i] }), {});
      this.crpiPFCoverageChartViewer = ((data.crpiOilPfCoverageData.every(item => item === 0) == true) ? true : false && (data.crpiCoalPfCoverageData.every(item => item === 0) == true) ? true : false);
      this.crpiBMCoverageChartViewer = ((data.crpiOilBmCoverageData.every(item => item === 0) == true) ? true : false && (data.crpiCoalBmCoverageData.every(item => item === 0) == true) ? true : false);
      this.evicEstimateDetails = data.evicaeEstimateLabel.reduce((acc, k , i) =>({...acc, [k]: data.evicaeEstimateData[i] }), {});
      if (data.evicaeEstimateData.length != 0) {
        this.evicAeDataExist = (data.evicaeEstimateData.every(item => item === 0) == true) ? true : false;
      } 
      console.log(this.stageIndexDetails)
      this.reportData$.portfolio.id = reportObject.portfolioId;
      this.reportData$.portfolio.name = reportObject.portfolioName;
      this.reportData$.portfolio.assetClass = reportObject.assetClass;
      this.reportData$.portfolio.currency = reportObject.currency;
      this.reportData$.portfolio.asOfDate = reportObject.asOfDate;
      this.reportData$.benchmark.indexMarker = reportObject.benchMark;
      this.ReportChartLoader = false;
      if (this.reportData$.performanceData.length != 0) {
        this.portfolioVsBenchmarkChartViewer = (this.reportData$.performanceData.every(item => item === 0) == true) ? true : false;
      }
      if (this.reportData$.overWeightData.length != 0) {
        this.overweightChartViewer = (this.reportData$.overWeightData.every(item => item === 0) == true) ? true : false;
      }
      if (this.reportData$.carbonExposureData.length != 0) {
        this.carbonExposureScopeDataViewer = ((this.stageIndexDetails.pfscope1 == 0 && this.stageIndexDetails.bmscope1 == 0 &&
          this.stageIndexDetails.pfscope2 == 0 && this.stageIndexDetails.bmscope2 == 0 &&
          this.stageIndexDetails.pfscope12 == 0 && this.stageIndexDetails.bmscope12 == 0) == true) ? true : false;
        this.carbonExposureLineChartViewer = ((this.stageIndexDetails.pfscope1 == 0 && this.stageIndexDetails.bmscope1 == 0 &&
          this.stageIndexDetails.pfscope2 == 0 && this.stageIndexDetails.bmscope2 == 0) == true) ? true : false;
      }
      if (this.reportData$.greenRevenueData.length != 0) {
        this.greenRevenueWeightsChartViewer = (this.reportData$.greenRevenueData.every(item => item === 0) == true) ? true : false;
      }
      if (this.reportData$.gricbSectorGapData.length != 0) {
        this.gricbSectorGapDataViewer = (this.reportData$.gricbSectorGapData.every(item => item === 0) == true) ? true : false;
      }
      if (this.reportData$.fossilFuelCoalEmissionData.length != 0) {
        this.fossilfuelCoalEmissionChartViewer = (this.reportData$.fossilFuelCoalEmissionData.every(item => item === 0) == true) ? true : false;
      }
      if (this.reportData$.fossilFuelOngEmissionData.length != 0) {
        this.fossilfuelOngEmissionChartViewer = (this.reportData$.fossilFuelOngEmissionData.every(item => item === 0) == true) ? true : false;
      }
      if (this.reportData$.tpimqScoreData.length != 0) {
        this.tpimqScorePFBMChartViewer = (this.reportData$.tpimqScoreData.every(item => item === 0) == true) ? true : false;
      }
      if (this.reportData$.greenRevenueSegmentData.length != 0) {
        this.greenRevenueSegmentChartViewer = (this.reportData$.greenRevenueSegmentData.every(item => item === 0) == true) ? true : false;
      }
      if (this.reportData$.fossilFuelSectorData.length != 0) {
        this.fossilFuelSectorChartViewer = (this.reportData$.fossilFuelSectorData.every(item => item.data === 0) == true) ? true : false;
      }
      if (this.reportData$.fossilFuelSectorGapsData.length != 0) {
        this.fossilFuelSectorGapViewer = (this.reportData$.fossilFuelSectorGapsData.every(item => item === 0) == true) ? true : false;
      }
      
      if (this.reportData$.waciAttributionEffectData.length != 0) {
        this.waciAttributionEffectChartViewer = (this.reportData$.waciAttributionEffectData.every(item => item === 0) == true) ? true : false;
      } 
      if (this.reportData$.waciAttributionEffectByScopeData.length != 0) {
        this.waciAttributionEffectByScopeChartViewer = (this.reportData$.waciAttributionEffectByScopeData.every(item => item === 0) == true) ? true : false;
      }
      if (this.reportData$.evicAttributionEffectData.length != 0) {
        this.evicAttributionEffectChartViewer = (this.reportData$.evicAttributionEffectData.every(item => item.data === 0) == true) ? true : false;
      } 
      if (this.reportData$.evicAttributionEffectByScopeData.length != 0) {
        this.evicAttributionEffectByScopeChartViewer = (this.reportData$.evicAttributionEffectByScopeData.every(item => item.data === 0) == true) ? true : false;
      }                              
      if (this.reportData$.waciHistoricCAGRData.length != 0) {
        this.waciCAGRChartViewer = (this.reportData$.waciHistoricCAGRData.every(item => item.data === 0) == true) ? true : false;
      }  
      if (this.reportData$.evicaeHistoricCAGRData.length != 0) {
        this.evicaeCAGRChartViewer = (this.reportData$.evicaeHistoricCAGRData.every(item => item.data === 0) == true) ? true : false;
      } 
      if (this.reportData$.evicaePortfolioHistoricData.length != 0) {
        this.evicaePortfolioHistoricDataExist  = (this.reportData$.evicaePortfolioHistoricData.every(item => item== 0) == true) ? true : false;
      } 
      if (this.reportData$.evicaeBenchmarkHistoricData.length != 0) {
        this.evicaeBenchmarkHistoricDataExist = (this.reportData$.evicaeBenchmarkHistoricData.every(item => item === 0) == true) ? true : false;
      } 

      if (this.reportData$.pfAndBmPerformanceData.length != 0) {
        this.pfAndBmPerformanceViewer = (this.reportData$.pfAndBmPerformanceData.every(item => item === 0) == true) ? true : false;
      } 
      console.log('dataexistflags')
 
      if (data.wacikrSectorGapsData.length != 0) {
        this.wacikrSectorGapsDataExist = (data.wacikrSectorGapsData.every(item => item === 0) == true) ? true : false;
      }
      if (data.waciSectorCtbPortfolioData.length != 0) {
        this.waciSectorCtbPortfolioDataExist = (data.waciSectorCtbPortfolioData.every(item => item === 0) == true) ? true : false;
      }
      if (data.waciSectorCtbBenchmarkData.length != 0) {
        this.waciSectorCtbBenchmarkDataExist = (data.waciSectorCtbBenchmarkData.every(item => item === 0) == true) ? true : false;
      }
      console.log(this.waciSectorCtbPortfolioDataExist);
      console.log(this.waciSectorCtbBenchmarkDataExist);
      if (data.waciAttributionEffectData.length != 0) {
        this.waciAttributionEffectDataExist = (data.waciAttributionEffectData.every(item => item.data === 0) == true) ? true : false;
      }
      if (data.waciAttributionEffectByScopeData.length != 0) {
        this.waciAttributionEffectByScopeDataExist = (data.waciAttributionEffectByScopeData.every(item => item.data === 0) == true) ? true : false;
      }
      
      
      console.log(this.wacikrSectorGapsDataExist);
      if (data.waciPortfolioHistoricData.length != 0) {
        this.waciPortfolioHistoricDataExist = (data.waciPortfolioHistoricData.every(item => item === 0) == true) ? true : false;
      }
      if (data.waciBenchmarkHistoricData.length != 0) {
        this.waciBenchmarkHistoricDataExist = (data.waciBenchmarkHistoricData.every(item => item === 0) == true) ? true : false;
      }
      if (data.evickrSectorGapsData.length != 0) {
        this.evickrSectorGapsDataExist = (data.evickrSectorGapsData.every(item => item === 0) == true) ? true : false;
      }
      if (data.evicSectorCtbPortfolioData.length != 0) {
        this.evicSectorCtbPortfolioDataExist = (data.evicSectorCtbPortfolioData.every(item => item === 0) == true) ? true : false;
      }
      if (data.evicSectorCtbBenchmarkData.length != 0) {
        this.evicSectorCtbBenchmarkDataExist = (data.evicSectorCtbBenchmarkData.every(item => item === 0) == true) ? true : false;
      }
     
      if (this.reportData$.grPfCoverageData.length != 0) {
        this.ReportChartLoaderView = (this.reportData$.grPfCoverageData.every(item => item === 0) == true) ? true : false;
      }
      if (this.reportData$.grBmCoverageData.length != 0) {
        this.ReportChartBMLoaderView = (this.reportData$.grBmCoverageData.every(item => item === 0) == true) ? true : false;
      }

      if (this.reportData$.tpimqScoreData.length != 0) {
        this.tpimqScoreDataViewer = (this.reportData$.tpimqScoreData.every(item => item === 0) == true) ? true : false;
      }
      if (this.reportData$.tpimqScoreSectorGapsData.length != 0) {
        this.tpimqScoreSectorGapsDataViewer = (this.reportData$.tpimqScoreSectorGapsData.every(item => item === 0) == true) ? true : false;
      }
      //console.log('dhanya')
      console.log('report-' + this.reportData$.portfolio.id + '.pdf');
      console.log(this.PortfolioId);
    }
  
    roundOffTwoDecimals(arr){
      arr.forEach(item => {
        item.pfWeight = Number(item.pfWeight).toFixed(2);
        item.bmWeight = Number(item.bmWeight).toFixed(2);
        item.pfBm =  Number(item.pfBm).toFixed(2);
        item.pfCarbonIntensityValue = Number(item.pfCarbonIntensityValue).toFixed(2);
        item.bmCarbonIntensityValue = Number(item.bmCarbonIntensityValue).toFixed(2);
        item.perfVsBm = Number(item.perfVsBm).toFixed(2);
        item.pFvsBMTotal = item.pFvsBMTotal.toFixed(2);
        item.pfweightTotal = item.pfweightTotal.toFixed(2);
        item.bmWeightTotal = item.bmWeightTotal.toFixed(2);
        item.pfBmTotal = Number(item.pfBmTotal).toFixed(2);
        item.pfCarbonIntensityTotalValue = item.pfCarbonIntensityTotalValue.toFixed(2);
        item.bmCarbonIntensityTotalValue = item.bmCarbonIntensityTotalValue.toFixed(2);
        item.perfVsBmTotal = Number(item.perfVsBmTotal).toFixed(2);
        item.ctbToPfBmGapTotal = item.ctbToPfBmGapTotal.toFixed(2);
      });
    }
  print(): void {
    
      let yourElem= <HTMLElement>document.querySelector('.fa-angle-double-left');
      if(yourElem!=null)
      {
      yourElem.click();
      }
  
      this.reportDownloader = true;
      
      var allPromises = [];
      var doc = new jsPDF("l", "pt", "a4", true)
      setTimeout(()=>{
      var height = doc.internal.pageSize.getHeight()
      var width = doc.internal.pageSize.getWidth()
      const pages = document.querySelectorAll(".download-report");
      let scale = 2;
      for (var i = 0; i < pages.length; i++) {
        allPromises.push(domtoimage.toJpeg((pages[i]),{
            width: pages[i].clientWidth * scale,
            height: pages[i].clientHeight * scale,
            style: { transform: 'scale('+scale+')', transformOrigin: 'top left'},
            bgcolor: "white"
        }))
      }
  
      Promise.all(allPromises).then(values => {          
         for (var i = 0; i < values.length; i++) {
          if (i == 0) {
            var imgData = values[i]
              // doc.addImage(imgData, "JPEG", 0, 0, width, height, '', 'FAST');
              doc.addImage(imgData, "JPEG", 0, 0, width, height, undefined, 'FAST');
   
          }
          else {
            imgData = values[i]
            doc.addPage([width, height])
            //doc.addImage(imgData, "JPEG", 0, 0, width, height), '', 'FAST';
            doc.addImage(imgData, "JPEG", 0, 0, width, height), undefined, 'FAST';
          }
         }
         this.reportDownloader = false;
         //doc.save('report-' + this.PortfolioId + '.pdf')
        if (this.reportData$.portfolio.sourceBenchmark != null)
          doc.save(this.reportData$.portfolio.benchMark + '-' + this.reportData$.portfolio.sourceBenchmark + '_Climate_Report_' + formatDate(this.reportData$.portfolio.asOfDate, 'yyyyMMdd', 'en-US') + '.pdf')
        else
          doc.save(this.reportData$.portfolio.benchMark + '_Climate_Report_' + formatDate(this.reportData$.portfolio.asOfDate, 'yyyyMMdd', 'en-US') + '.pdf')
       // console.log(values)
       
        this.reportDownloader = false;
        //doc.save('report-' + this.PortfolioId + '.pdf')

        //if (this.reportData$.portfolio.sourceBenchmark != null)
        //  doc.save(this.reportData$.portfolio.benchMark + '-' + this.reportData$.portfolio.sourceBenchmark + '_Climate_Report_' + formatDate(this.reportData$.portfolio.asOfDate, 'yyyyMMdd', 'en-US') + '.pdf')
        //else
        //  doc.save(this.reportData$.portfolio.benchMark + '_Climate_Report_' + formatDate(this.reportData$.portfolio.asOfDate, 'yyyyMMdd', 'en-US') + '.pdf')
       // console.log(values)
      }); 
    },100);              
    }
  
    return() {
      this.router.navigate(['reports',])
  
    }
    ngAfterViewInit(){
      let yourElem= <HTMLElement>document.querySelector('.fa-angle-double-left');
      if(yourElem!=null)
      {
      yourElem.click();
      }
    }
  }
