import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { Portfolio } from '../../models/portfolio';
import { BenchmarkDetailsColumns, detailsColumnsNameMap } from '../../models/benchmark';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PortfoliosService } from '../../services/portfolios.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

enum ColumnNames {
  chevron = 'chevron',
  name = 'name',
  description = 'description',
  marketValue = 'marketValue',
  benchMark = 'benchMark',
  assetClass = 'assetClass',
  currency = 'currency',
  asOfDate = 'asOfDate',
  controls = 'controls',
  isUploaded = 'isUploaded',
 sourceBenchmark = 'sourceBenchmark'
}

@Component({
  selector: 'app-portfolios-grid',
  templateUrl: './portfolios-grid.component.html',
  styleUrls: ['./portfolios-grid.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PortfoliosGridComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: any;
  @Output() edit = new EventEmitter<Portfolio>();
  pageSizeOptions: number[] = [5, 10, 15, 20, 25];
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 5;
  unsub: any;
  data: MatTableDataSource<Portfolio>;
  oldPageLimit: number;
  response: any;
  activePageDataChunk: any;
  pageLength: number;
  isLoading: boolean;
  @Input() loader : boolean ;
  columnsViewNameMap = new Map<ColumnNames, string>([
    [ColumnNames.name, 'Name'],
    [ColumnNames.description, 'Portfolio Description'],
    [ColumnNames.marketValue, 'Market Value'],
    [ColumnNames.benchMark, 'Benchmark'],
    [ColumnNames.assetClass, 'Asset Class'],
    [ColumnNames.currency, 'Currency'],
    [ColumnNames.asOfDate, 'As Of Date'],
    //[ColumnNames.isUploaded, 'Portfolio Type']
  ]);
  columnsToDisplay: ColumnNames[] = [
    ColumnNames.chevron,
    ColumnNames.name,
    ColumnNames.description,
    ColumnNames.marketValue,
    ColumnNames.benchMark,
    ColumnNames.assetClass,
    ColumnNames.currency,
    ColumnNames.asOfDate,
    //ColumnNames.isUploaded,
    ColumnNames.controls
  ];
  name = new FormControl();
  skipColumnsAutoRender = new Set<ColumnNames>([
    ColumnNames.chevron,
    //ColumnNames.isUploaded,
    ColumnNames.controls
  ]);
  fixColumnswidth100 = new Set<ColumnNames>([
    ColumnNames.assetClass,
    ColumnNames.currency,
    ColumnNames.asOfDate
  ]);
  fixColumnswidth120 = new Set<ColumnNames>([
    ColumnNames.marketValue,
    ColumnNames.benchMark
  ]);

  // TODO: refactor this in case it is correct
  expandedColumnsToDisplay: BenchmarkDetailsColumns[] = [
    ...Object.values(BenchmarkDetailsColumns),
  ];

  expandedColumnsToDisplayHeadingMap: Record<BenchmarkDetailsColumns, string> = detailsColumnsNameMap;
  expandedElement: Portfolio | null = null;

  filterForm: any;
  constructor(private router: Router, private portfoliosService: PortfoliosService, private fb: FormBuilder) {
 
    this.unsub = this.portfoliosService.clientPublish().subscribe((m: any) => {
      var result=m;
     
      this.portfoliosService.fetchPortfolios$(this.pageIndex, this.pageSize).subscribe((res) => {
        
        console.log("res");
        console.log(res);

        this.response = res;
        this.dataSource = this.response.data;
        if (this.dataSource.length % this.pageSize == 1 && (this.pageIndex == (Math.floor(this.dataSource.length / this.pageSize) - 1))) {
          this.pageIndex = this.pageIndex + 1;
        }
        let firstCut = this.pageIndex * this.pageSize;
        let secondCut = firstCut + this.pageSize;    
        if (result == "Invalid") {
          alert("Please Upload Valid details");
        }
        else if (result == "Invalid Asofdate") {
          alert("Please Upload Valid Asofdate of format 'mm/dd/yyyy");
        }
        else if(result == "Invalid Weight"){
          alert("Weight cannot be null. Please Upload Valid weight");
        }
        else if(result == "Invalid Sedol"){
          alert("Please provide either cusip or sedol if Isin is not given. sedol should be 7 in length and cusip 9 in length");
        }
        else if (result == "Updated") {
          alert("Updated successfully");
        }
        else if (result == "Deleted") {
          alert("Deleted successfully");
        }
        else if (result.includes("Row")) {
          alert(result);
        }
        else {
          alert("Portfolio created successfully");
        }
        this.loader = true ;
        this.activePageDataChunk = this.dataSource.slice(firstCut, secondCut);
        for(let item of this.activePageDataChunk){
          item.asOfDate = (item.asOfDate).substring(0,10)
        }
        this.dataSource.paginator = this.paginator;
        this.pageLength =  this.dataSource.length > 0? this.dataSource[0].totalCount: 0;//this.dataSource.length;
      });
    });
    this.data = new MatTableDataSource(this.dataSource);
    //  this.data.filterPredicate = this.createFilter();

  }
  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      return (
        data.name.toLowerCase().indexOf(searchTerms.name) !== -1 &&
        data.description
          .toString()
          .toLowerCase()
          .indexOf(searchTerms.id) !== -1 &&
        data.marketValue.toLowerCase().indexOf(searchTerms.colour) !== -1 &&
        data.benchmark.toLowerCase().indexOf(searchTerms.pet) !== -1
      );
    };
    return filterFunction;
  }
  ngOnInit(): void {

    this.filterForm = this.fb.group({
      name: [""],
      description: [""],
      marketValue: [""],
      benchMark: [""],
      assetClass: [""],
      currency: [""],
      asOfDate: [""],
      //isUploaded : [""],
    });
    this.filterForm.valueChanges.subscribe(value => {
      var filter1 =this.dataSource.filter(cp => cp.name.toLowerCase().indexOf(value.name.toLowerCase()) != -1 && cp.description.toLowerCase().indexOf(value.description.toLowerCase()) != -1 && cp.marketValue.toLowerCase().indexOf(value.marketValue.toLowerCase()) != -1
       &&cp.benchMark.toLowerCase().indexOf(value.benchMark.toLowerCase()) != -1 && cp.assetClass.toLowerCase().indexOf(value.assetClass.toLowerCase()) != -1 && cp.currency.toLowerCase().indexOf(value.currency.toLowerCase()) != -1 && cp.asOfDate.toLowerCase().indexOf(value.asOfDate.toLowerCase()) != -1 );
      this.activePageDataChunk = filter1.slice(0,this.pageSize);
      filter1.paginator = this.paginator;
      this.pageLength = filter1.length;
    });
    
    this.getPorfolios();
  }

  resolveValue(element)
  {
    if(element[ColumnNames.isUploaded] == true)
    return 'P';
    else
    return 'B';    
  }
  onPageChanged(e) {
   
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getPorfolios();   
  }

  getPorfolios()
  {
    this.isLoading = true;
    this.portfoliosService.fetchPortfolios$(this.pageIndex, this.pageSize).subscribe((res) => {
      console.log(res);
      this.response = res;
       this.dataSource = this.response.data;
       this.isLoading = false;
       this.activePageDataChunk = this.dataSource; //this.dataSource.slice(0, this.pageSize);
       for(let item of this.activePageDataChunk){
         item.asOfDate = (item.asOfDate).substring(0,10)
       }
       this.dataSource.paginator = this.paginator;
       this.pageLength = this.dataSource.length > 0? this.dataSource[0].totalCount: 0;
       console.log("this.dataSource");
       console.log(this.dataSource);
     });
  }

  // updateExpandedRow(element: Portfolio): void {
  //   if (element.children) {
  //     this.expandedElement = this.expandedElement === element ? null : element;
  //   }
  // }

  updateExpandedRow(element: Portfolio): void {
    this.expandedElement = this.expandedElement === element ? null : element;
    // this.updateExpandableElement.emit({
    //   AsOfDate: element.asOfDate,
    //   IndexMarker: element.indexMarker
    // });
  }

  trim(text: any): string | any {
    if (typeof text === 'string') {
      return text.length < 28 ? text : text.slice(0, 25).trim() + '...';
    }
    return text;
  }

  getColumnsViewName(column: ColumnNames): string {
    return this.columnsViewNameMap.get(column) || '';
  } id

  openPortfolio({ id }: Portfolio): void {
    this.router.navigate(['pbr', 'portfolios', id]);
  }

  openPortfolioEdit(portfolio) {
    this.edit.emit(portfolio);
  }

  getExpandedColumnValue(row: any, column: string, i: number): string {
    return row[i] || '';
  }

  ngOnDestroy() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
  }
}
