import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class UINotificationService {

    private subject = new Subject<any>();

    constructor() { }

    publish(uiNotificationsAlerts: any) {
        this.subject.next(uiNotificationsAlerts);
      }
    
      publishedUiNotifications(): Observable<any> {
        return this.subject.asObservable();
      }
}