import { Component, OnChanges,SimpleChanges,Input,OnInit,Inject,NgZone } from '@angular/core';
import { PerformanceService } from '../../services/performance.service'
import {PerformancePageModel} from '../../models/performance-page-model';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {MagellanColorService} from '../../services/magellancolor.service';
import * as FusionCharts from 'fusioncharts';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DateService} from '../../services/date.service';
import { HeatMapChart } from '../../models/performance-heatmap-chart';
import { IndexReturns } from '../../models/performance-index-returns';
import { BiService } from '../../services/BiService.service';
import {IDWChartExportService} from '../../services/idw-chart-export.service';

@Component({
  selector: 'performance-heat-chart',
  templateUrl: './performance-heat-chart.component.html',
})
export class PerformanceHeatChartComponent implements OnInit {
    asofDates: string;
    chartDatadidFail: boolean=false;
    heatChartDataSet : any;    
    HeatChartLoader:boolean =true; 
    popupDataSource : any; 
    chart:any;
        
    constructor(private dateService : DateService,private performanceService: PerformanceService,
         private exportService: ExportToExcelService,private publishIndexMarkerService:PublishIndexMarkerService,
         private indexMarkerService:IndexMarkerService ,private magellanColorService : MagellanColorService,
         public dialog: MatDialog, private biService: BiService,private zone:NgZone,
         private idwChartExportService: IDWChartExportService) { }
    fromDate : any;
    heatMapChartdidFail=false;
    toDate :any;
    indexMarkers : any;
    @Input() timePeriod : any;
    currencyCode : string;
    returnType : any;
    colors : any;
     
  openDialog(): void{    
    var event: any = {eventName: "Performance Quilt dialog open"};
    this.biService.addEvent(event);  
    this.popupDataSource.dataSource.chart.showlegend = "1";    
    let dialogRef = this.dialog.open(PerformanceHeatChartDialog, {
        width: '80%',
        height: '90%',
      data: this.popupDataSource
    });
  }

  openWidgetDialog(): void{       
    var event: any = {eventName: "Performance Quilt help dialog open"};
    this.biService.addEvent(event);  
    let dialogRef = this.dialog.open(PerformanceHeatChartWidgetDialog, {
      width: '800px'      
    });
  }

  chartInitialize(event){
    return (eve, arg) => { 
        this.zone.run(() => {
            this.chart =eve.sender;
        });  
        
    };
      
  }

  events = {
    initialized: this.chartInitialize(event)
}
    ngOnInit(){    
    this.indexMarkers = this.indexMarkerService.getIndexMarkers();
    this.timePeriod= this.dateService.getTimePeriod();
    this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
        this.HeatChartLoader=true; 
        this.indexMarkers=result;
        this.chartSetup(result);
    });

    this.dateService.timePeriodPublish().subscribe(timePeriod=>{
        this.HeatChartLoader=true;
        this.timePeriod =  timePeriod;       
       this.chartSetup(this.indexMarkers);
    });

    this.colors = this.magellanColorService.getCurrentColorSchema().split(",");   
    this.magellanColorService.colorsPublish().subscribe(color => { 
        this.colors = color.split(",");           
        for (var counter = 0; counter < this.colors.length; counter++) {
            if (counter < this.heatChartDataSet.colorrange.color.length) {
                this.heatChartDataSet.colorrange.color[counter].code = this.colors[counter].substring(1);
            }
        }
   });

   this.popupDataSource={     
    "dataSource": this.heatChartDataSet,
    "asOfDate": this.asofDates   
  }

   
  }  

  update(performancePageModel:PerformancePageModel){    
    this.HeatChartLoader=true; 
    this.returnType = performancePageModel.returntype;
    this.currencyCode = performancePageModel.currencyCode;
    this.fromDate =performancePageModel.selectedDates.start;
    this.toDate =performancePageModel.selectedDates.end;
    this.chartSetup(this.indexMarkers);
  } 
  
  
    private chartSetup(indexMarkers:any) {   
        this.performanceService.heatMapChartLoaded.observers=[];   
     this.performanceService.getMonthlyPerformanceChart(this.fromDate, this.toDate,indexMarkers ,this.timePeriod  ,this.currencyCode ,this.returnType);     
        this.performanceService.heatMapChartLoaded.subscribe(
            (data: HeatMapChart) => {
                if(null != data){
                    if(0< data.dataset.length){
                        this.bindHeatChart(data); 
                        this.popupDataSource={     
                            "dataSource": this.heatChartDataSet,
                            "asOfDate": this.toDate   
                          } 
                      }else{
                        this.heatChartDataSet = {};
                        this.HeatChartLoader =false;  
                      }
                }
                else{
                    this.heatChartDataSet = {};
                        this.HeatChartLoader =false; 
                }
                  
                     
            });
           this.performanceService.heatMapChartFailed.subscribe(
            (didFail: boolean) => this.heatMapChartdidFail = didFail
          ); 

  }

  bindHeatChart(data){
    this.heatChartDataSet = {                    
        "rows": data.rows,
        "columns": data.columns,
        "dataset": data.dataset,
        "colorrange": {
            "gradient": "0",                                
            "color": [
                {
                    "code": this.colors[0].substring(1,(this.colors[0].length)),
                    "label": this.indexMarkers[0]
                },
                {
                    "code": this.colors[1].substring(1,(this.colors[1].length)),
                    "label": this.indexMarkers[1]
                },
                {
                    "code": this.colors[2].substring(1,(this.colors[2].length)),
                    "label": this.indexMarkers[2]
                },
                {
                    "code": this.colors[3].substring(1,(this.colors[3].length)),
                    "label": this.indexMarkers[3]
                },
                {
                    "code": this.colors[4].substring(1,(this.colors[4].length)),
                    "label": this.indexMarkers[4]
                },
                {
                    "code": this.colors[5].substring(1,(this.colors[5].length)),
                    "label": this.indexMarkers[5]
                }    
            ]
        },
        "chart" : {
            "mapbycategory": "1",
            "bgcolor": "ffffff",
            "canvasbgcolor": "999999",
            "showborder": "0",
            "legendshadow": "0",
            "canvasborderalpha": "0",
            "legendborderalpha": "1",
            "showLabels": "0",
            "showLegend": "0",
            "baseFontColor": "#ffffff",
            "toolTipBgColor": "#666666",
            "divlinecolor": "#CCCCCC",
            "plotBorderColor": "#CCCCCC",
            "chartLeftMargin": "-10",
            "chartRightMargin": "-10",
            "chartTopMargin": "-5",
            "labelXPadding": "10",
            "placeXAxisLabelsOnTop": "1",
            "xAxisName": "Returns",
            "valueFontSize": "8",
            "baseFont": "sans-serif",
            "exportenabled": "1",
            "exportatclient": "0",
            "exportShowMenuItem": "0"
        },
        "annotations" : {
            "groups": [{
                "id": "rect",
                // "showBelow": "0",
                "items": [
                    {
                        "type": "rectangle",
                        "x": "$canvasStartX-0",
                        "y": "$canvasStartY-11",
                        "tox": "$canvasEndX+1",
                        "toy": "$canvasStartY",
                        "fillcolor": "#e6e6e6"
                    },
                    {
                        "type": "rectangle",
                        "x": "$canvasStartX-0",
                        "y": "$canvasEndY+11",
                        "tox": "$canvasEndX+1",
                        "toy": "$canvasEndY",
                        "fillcolor": "#e6e6e6"
                    }]
            },
            {
                "id": "text",
                "items": [
                    {
                        "type": "Text",
                        "color": "#000000",
                        "label": "Highest index returns",
                        "fontSize": "11",
                        "bold": "1",
                        "x": "$chartCenterX",
                        "y": "$canvasStartY-5",

                    },
                    {
                        "type": "Text",
                        "color": "#000000",
                        "label": "Lowest index returns",
                        "fontSize": "11",
                        "x": "$chartCenterX",
                        "y": "$canvasEndY+5",
                        "bold": "1",
                    }
                ]
            }
            ]
        }
    }
    this.HeatChartLoader=false;
  }
   ExportXls(event):void{
    var event: any = {eventName: "Export Peformance Quilt", inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode},{Name : "TimePeriod" , Value: this.timePeriod},{Name : "ReturnType" , Value: this.returnType}]};
    this.biService.addEvent(event);
          var inputData = {
            "exportType": "xls",
            "code": "performance-getMonthlyPerformanceChartData",
            "xlsFileName": "PerformanceQuiltRawData",
            "fromDate": this.fromDate,
            "toDate": this.toDate,
            "indexMarkers": this.indexMarkers,
            "timePeriod": this.timePeriod,
            "currencyCode": this.currencyCode,
            "returnType": this.returnType
          };
          this.idwChartExportService.export(inputData);   
   
  }

  ExportPNG(event):void{
    var event: any = {eventName: "Export Peformance Quilt Chart TO PNG",   inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode},{Name : "TimePeriod" , Value: this.timePeriod},{Name : "ReturnType" , Value: this.returnType}]};
    this.biService.addEvent(event);
    this.popupDataSource.dataSource.chart.showlegend = "1";  
    var inputData = {
        "exportType": "png",
        "legend": false,
        "dataSource": this.popupDataSource.dataSource,
        "chartid" : "performance QuiltExport",
        "type" : "heatmap",
        "targetDiv" : "exportPngDiv",
        "chartReference": this.chart,
        "pngFileName" : "PerformanceQuiltRawData",
        "chartTitle": "Performance Quilt",
        "chartDate": this.toDate
     };
     this.idwChartExportService.export(inputData);
     this.popupDataSource.dataSource.chart.showlegend = "0";  
  }

  
}


@Component({
      selector: 'performance-heat-chart-dialog',
  templateUrl: './performance-heat-chart.component.dialog.html'
})
export class PerformanceHeatChartDialog{
    performanceTextWidth:any;
    constructor(
    public dialogRef: MatDialogRef<PerformanceHeatChartDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
        this.performanceTextWidth = (100/data.dataSource.columns.column.length).toString()+"%";
     }

    onClose(): void {
        this.data.dataSource.chart.showlegend = "0"; 
        this.dialogRef.close();
  }
}


@Component({
    selector: 'performance-heat-chart-dialog',
templateUrl: './performance-heat-chart.component.widget.dialog.html'
})
export class PerformanceHeatChartWidgetDialog{
  performanceTextWidth:any;
  constructor(
  public dialogRef: MatDialogRef<PerformanceHeatChartWidgetDialog>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
     
   }

  onClose(): void {
      this.dialogRef.close();
}
}