import { Injectable }    from '@angular/core';
import {IDWFusionChartsPngExportService} from './idw-fusion-charts-png-export.service';

@Injectable()
export class IDWChartPngExportService {
    
    chartDrawingMatrix:any[] = [];
    dataInput:any;
    defaultLibrary:string = "fusionsCharts";
    constructor(private idwFusionChartsPngExportService: IDWFusionChartsPngExportService){
      this.chartDrawingMatrix["fusionsCharts"] = this.fusionsChartsDraw;    
    }

    exportImage(inputData:any){
        var exportMethod = this.chartDrawingMatrix[this.defaultLibrary];
        this.dataInput = inputData;
        exportMethod.apply(this);
    }

    private fusionsChartsDraw(){
        this.idwFusionChartsPngExportService.fusionsChartsDraw(this.dataInput);
    }
}