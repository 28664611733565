import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {MatTabsModule} from '@angular/material/tabs'; 
import {PbrRootComponent} from './containers/pbr-root/pbr-root.component';
import {LoginPageComponent} from './containers/login-page/login-page.component';
import {LoginComponent} from './components/login/login.component';
import {PortfoliosPageComponent} from './containers/portfolios-page/portfolios-page.component';
import {PortfoliosGridComponent} from './components/portfolios-grid/portfolios-grid.component';
import {NewPortfolioPopupComponent} from './components/new-portfolio-popup/new-portfolio-popup.component'; 
import {NewPortfolioBenchmarkPopupComponent} from './components/new-portfolio-benchmark-popup/new-portfolio-benchmark-popup.component'; 
import {LogoComponent} from './components/logo/logo.component';
import {PageComponent} from './components/page/page.component';
import {BenchmarksPageComponent} from './containers/benchmarks-page/benchmarks-page.component';
import {ReportsPageComponent} from './containers/reports-page/reports-page.component';
import {ClimateReportsPageComponent} from './containers/climate-reports-page/climate-reports-page.component';
import {MetaDataConfigComponent} from './containers/meta-data-config-page/meta-data-config-page.component';
import {SITablesDataConfigComponent} from './containers/si-tables-config-page/si-tables-config-page.component';
import {SIDataCalculationComponent} from './containers/si-datacalculation/si-datacalculation.component';
import {HistoryReportsPageComponent } from './containers/history-reports-page/history-reports-page.component';
import { BenchmarksGridComponent } from './components/benchmarks-grid/benchmarks-grid.component';
import {ReportsGridComponent} from './components/reports-grid/reports-grid.component';

import {SIDataCalculationGridComponent} from './components/sidatacalculation-data-grid/sidatacalculation-data-grid.component';
import {ClimateDataGridComponent} from './components/climate-data-grid/climate-data-grid.component';
import {CoverageconfigDataGridComponent} from './components/coverageconfig-data-grid/coverageconfig-data-grid.component';
import { EsgconfigurationDataGridComponent } from './components/esgconfiguration-data-grid/esgconfiguration-data-grid.component';
import { PaiConfigurationDataGridComponent } from './components/paiconfiguration-data-grid/paiconfiguration-data-grid.component';
import { VanguardConfigurationDataGridComponent } from './components/vanguardconfiguration-data-grid/vanguardconfiguration-data-grid.component';
import { SovereignConfigurationDataGridComponent } from './components/sovereignconfiguration-data-grid/sovereignconfiguration-data-grid.component';
import { SovereignConfigurationIndexDataGridComponent } from './components/sovereignconfigurationindex-data-grid/sovereignconfigurationindex-data-grid.component';
import { EsgconfigurationFixedIncomeDataGridComponent } from './components/esgconfigurationfixedincome-data-grid/esgconfigurationfixedincome-data-grid.component';
import {EsgExcludesDataGridComponent} from './components/esgexcludes-data-grid/esgexcludes-data-grid.component';
import { EsgExcludesPriorityDataGridComponent } from './components/esgexcludespriority-data-grid/esgexcludespriority-data-grid.component';
import { eupabdatesDataGridComponent } from './components/eupabdates-data-grid/eupabdates-data-grid.component';
import { EUPABindexconfigurationDataGridComponent } from './components/eupabindexconfiguration-data-grid/eupabindexconfiguration-data-grid.component';
import { gsxconfigurationsDataGridComponent } from './components/gsxconfigurations-data-grid/gsxconfigurations-data-grid.component';
import { climatedatapackconfigurationDataGridComponent } from './components/climatedatapackconfiguration-data-grid/climatedatapackconfiguration-data-grid.component';
import {MasterDataGridComponent} from './components/master-data-grid/master-data-grid.component';
import { HistoryDataGridComponent } from './components/history-data-grid/history-data-grid.component';
import { ExcelLikeGridComponent } from './components/excel-like-grid/excel-like-grid.component';
import {PortfolioDetailsPageComponent} from './containers/portfolio-details-page/portfolio-details-page.component';
import {AdditionalToolbarComponent} from './components/additional-toolbar/additional-toolbar.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCheckbox, MatCheckboxModule} from '@angular/material/checkbox';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {HttpClientModule} from '@angular/common/http';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {ReportPrintPageComponent} from './containers/report-print-page/report-print-page.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DatePickerComponent} from './components/date-picker/date-picker.component';
import {SelectIndexMarkerComponent} from './components/select-index-marker/select-index-marker.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import {ErrorHandlerComponent} from './components/error-handler/error-handler.component';
import { ReportPerformanceChartComponent } from './components/report-performance-chart/report-performance-chart.component';
import { ReportBreakdownChartComponent } from './components/report-breakdown-chart/report-breakdown-chart.component';
import { SelectBenchmarkComponent } from './components/select-benchmark/select-benchmark.component';
import { SelectBenchmarkPortfolioComponent } from './components/select-benchmark-portfolio/select-benchmark-portfolio.component';
import { NewReportPopupComponent } from './components/new-report-popup/new-report-popup.component';
import { NewMasterPopupComponent } from './components/new-masterdata-popup/new-masterdata-popup.component';
import { NewEsgconfigurationPopupComponent } from './components/new-esgconfigurationdata-popup/new-esgconfigurationdata-popup.component';
import { NewEsgconfigurationFixedincomePopupComponent } from './components/new-esgconfigurationfixedincomedata-popup/new-esgconfigurationfixedincomedata-popup.component';
import { NewEsgExcludesPopupComponent } from './components/new-esgexcludesdata-popup/new-esgexcludesdata-popup.component';
import { NewPAIConfigurationPopupComponent } from './components/new-paiconfigurationdata-popup/new-paiconfigurationdata-popup.component';
import { NewEsgExcludesPriorityPopupComponent } from './components/new-esgexcludesprioritydata-popup/new-esgexcludesprioritydata-popup.component';
import { NewVanguardConfigurationPopupComponent } from './components/new-vanguardconfigurationdata-popup/new-vanguardconfigurationdata-popup.component';
import { NewSovereignconfigurationPopupComponent } from './components/new-sovereignconfigurationdata-popup/new-sovereignconfigurationdata-popup.component';
import { NewSovereignconfigurationIndexPopupComponent } from './components/new-sovereignconfigurationindexdata-popup/new-sovereignconfigurationindexdata-popup.component';
import { NewCoverageConfigPopupComponent } from './components/new-coverageconfigdata-popup/new-coverageconfigdata-popup.component';
import { NewclimatedatapackconfigurationPopupComponent } from './components/new-climatedatapackconfigurationdata-popup/new-climatedatapackconfigurationdata-popup.component';
import { AsOfDatePickerComponent } from './components/as-of-date-picker/as-of-date-picker.component';
import { SelectPortfolioComponent } from './components/select-portfolio/select-portfolio.component';
import { PortfolioFormComponent } from './components/portfolio-form/portfolio-form.component';
import { PortfolioBenchmarkFormComponent } from './components/portfolio-benchmark-form/portfolio-benchmark-form.component';
import { EditPortfolioPopupComponent } from './components/edit-portfolio-popup/edit-portfolio-popup.component';
import { ExpandedRowComponent } from './components/expanded-row/expanded-row.component';
import { SalesExposureChartComponent,BarChartComponent,JointBarChartComponent, SalesExposureChartLineComponent, GroupedHorizontalBarChartComponent,AttributionEffectChartComponent,GroupedVerticalBarChartComponent,LineChartComponent,GroupedBarChartComponent,VerticalStackedBarChartComponent } from './components/sales-exposure-chart/sales-exposure-chart.component';
import { ReportTableComponent } from './containers/report-table/report-table.component';
import { ReportPerformanceTableComponent } from './containers/report-performance-table/report-performance-table.component';
import { ReportMetadataTableComponent } from './containers/report-metadata-table/report-metadata-table.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IndextoIndexReportPrintPageComponent } from './containers/indexto-index-report-print-page/indexto-index-report-print-page.component';
import { GoToPageDialogComponent } from './containers/go-to-page-popup/go-to-page-popup.component';
import { NeweupabdatesPopupComponent } from './components/new-eupabdates-popup/new-eupabdates-popup.component';
import { NewEUPABIndexconfigurationPopupComponent } from './components/new-eupabindexconfigurationdata-popup/new-eupabindexconfigurationdata-popup.component';
import { NewgsxconfigurationsPopupComponent } from './components/new-gsxconfigurations-popup/new-gsxconfigurations-popup.component';

const routes: Routes = [
  {
    path: '',
    component: PbrRootComponent,
    children: [
      { path: 'login', component: LoginPageComponent },
      {
        path: '',
        component: PageComponent,
        children: [
          {
            path: '',
            redirectTo: 'portfolios',
            pathMatch: 'full'
          }, {
            path: 'portfolios',
            children: [
              {
                path: '',
                component: PortfoliosPageComponent,
              }, {
                path: ':id',
                component: PortfolioDetailsPageComponent
              }
            ],
          }, {
            path: 'benchmarks',
            children: [
              {
                path: '',
                component: BenchmarksPageComponent,
              },
            ],
          }, {
            path: 'reports',
            children: [
              {
                path: '',
                component: ReportsPageComponent
              }, {
                path: 'print',
                component: ReportPrintPageComponent
              },
              {
                path: 'Indexprint',
                component: IndextoIndexReportPrintPageComponent
              }              
            ],
          },
          {
            path: 'climatereports',
            children: [
              {
                path: '',
                component: ClimateReportsPageComponent
              }
            ],
          },
          {
            path: 'metadataconfig',
            children: [
              {
                path: '',
                component: MetaDataConfigComponent
              }
            ],
          },      
          {
            path: 'historyreports',
            children: [
              {
                path: '',
                component: HistoryReportsPageComponent
              }
            ],
          },
          {
            path: 'sitablesconfig',
            children: [
              {
                path: '',
                component: SITablesDataConfigComponent
              }
            ],
          },
          {
            path: 'sidatacalculation',
            children: [
              {
                path: '',
                component: SIDataCalculationComponent
              }
            ],
          }, 
        ]
      }
    ]
  }, {
    path: 'print-report',
    component: ReportPrintPageComponent
  },{
    path: 'Index-print-report',
    component: IndextoIndexReportPrintPageComponent
  }
];

@NgModule({
  declarations: [
    PbrRootComponent,
    LoginPageComponent,
    LoginComponent,
    PortfoliosPageComponent,
    PortfoliosGridComponent,
    NewPortfolioPopupComponent,
    NewPortfolioBenchmarkPopupComponent,
    LogoComponent,
    PageComponent,
    BenchmarksPageComponent,
    ReportsPageComponent,
    ClimateReportsPageComponent,
    MetaDataConfigComponent,
    HistoryReportsPageComponent,
    BenchmarksGridComponent,
    ReportsGridComponent,
    ClimateDataGridComponent,
    MasterDataGridComponent,
    HistoryDataGridComponent,
    ExcelLikeGridComponent,
    PortfolioDetailsPageComponent,
    AdditionalToolbarComponent,
    ReportPrintPageComponent,
    DatePickerComponent,
    SelectIndexMarkerComponent,
    ErrorHandlerComponent,
    ReportPerformanceChartComponent,
    ReportBreakdownChartComponent,
    SelectBenchmarkComponent,
    SelectBenchmarkPortfolioComponent,
    NewReportPopupComponent,
    NewMasterPopupComponent,
    AsOfDatePickerComponent,
    SelectPortfolioComponent,
    PortfolioFormComponent,
    PortfolioBenchmarkFormComponent,
    EditPortfolioPopupComponent,
    ExpandedRowComponent,
    SalesExposureChartComponent,
    SalesExposureChartLineComponent,
    ReportTableComponent,
    BarChartComponent,
    JointBarChartComponent,
    GroupedHorizontalBarChartComponent,
    AttributionEffectChartComponent,
    GroupedVerticalBarChartComponent,
    LineChartComponent,
    GroupedBarChartComponent,
    VerticalStackedBarChartComponent,
    ReportPerformanceTableComponent,
    ReportMetadataTableComponent,
    IndextoIndexReportPrintPageComponent,
    GoToPageDialogComponent,
    SITablesDataConfigComponent,
    SIDataCalculationComponent,
    SIDataCalculationGridComponent,
    EsgconfigurationDataGridComponent,
    VanguardConfigurationDataGridComponent,
    SovereignConfigurationDataGridComponent,
    SovereignConfigurationIndexDataGridComponent,
    EsgconfigurationFixedIncomeDataGridComponent,
    EsgExcludesPriorityDataGridComponent,
    EsgExcludesDataGridComponent,
    eupabdatesDataGridComponent,
    EUPABindexconfigurationDataGridComponent,
    gsxconfigurationsDataGridComponent,
    PaiConfigurationDataGridComponent,
    CoverageconfigDataGridComponent,
    climatedatapackconfigurationDataGridComponent,
    NewEsgconfigurationPopupComponent,
    NewEsgconfigurationFixedincomePopupComponent,
    NewCoverageConfigPopupComponent,
    NewEsgExcludesPopupComponent,
    NewEsgExcludesPriorityPopupComponent,
    NewclimatedatapackconfigurationPopupComponent,
    NewSovereignconfigurationPopupComponent,
    NewSovereignconfigurationIndexPopupComponent,
    NewVanguardConfigurationPopupComponent,
    NeweupabdatesPopupComponent,
    NewEUPABIndexconfigurationPopupComponent,
    NewgsxconfigurationsPopupComponent,
    NewPAIConfigurationPopupComponent,

  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatButtonModule,
    MatCardModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatToolbarModule,
    ScrollingModule,
    HttpClientModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatExpansionModule,
     MatTabsModule,
     MatSelectFilterModule,
     MatTooltipModule
  ],
  entryComponents: [
    NewPortfolioPopupComponent,
    NewPortfolioBenchmarkPopupComponent,
    NewReportPopupComponent,
    EditPortfolioPopupComponent,
    GoToPageDialogComponent,
    SIDataCalculationComponent,
    SIDataCalculationGridComponent,
    NewMasterPopupComponent,
     NewEsgconfigurationPopupComponent,
     NewEsgconfigurationFixedincomePopupComponent,
    NewCoverageConfigPopupComponent,
  NewEsgExcludesPopupComponent,
    NewEsgExcludesPriorityPopupComponent,
    NewclimatedatapackconfigurationPopupComponent,
    NewSovereignconfigurationPopupComponent,
    NewSovereignconfigurationIndexPopupComponent,
    NewVanguardConfigurationPopupComponent,
    NeweupabdatesPopupComponent,
    NewEUPABIndexconfigurationPopupComponent,
    NewgsxconfigurationsPopupComponent, NewPAIConfigurationPopupComponent,]
})
export class PbrModule { }
