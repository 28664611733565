import { Component, OnInit,Input  } from '@angular/core';
import {DateService} from '../../services/date.service';
import {DataqualityserviceService} from '../../services/dataqualityservice.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-rgs-sector-data-quality',
    templateUrl: './rgs-sector-data-quality.component.html',
    styleUrls: ['./rgs-sector-data-quality.component.css']
  })
  export class RgsSectorDataQualityComponent implements OnInit {
        
    asOfDate : any;
    rgsSectorData:any;
    rgsIndexDetailsModel : any;
    rgsDataGridLoader:boolean =true;
    rgsRowExpandLoader:boolean[] =[]; 
    rgsPanelOpenState:Boolean = false;
    client : string ;
    
  constructor(
     private dataqualityservice:DataqualityserviceService,private dateService:DateService,
    private exportService:ExportToExcelService ,private route: ActivatedRoute) { }
    ngOnInit() {
      var that = this;
      this.asOfDate = this.dateService.getCalendarDate().end;
      this.dateService.calendarDatePublish().subscribe(result=>{
        that.asOfDate = moment(result.end).format('MM/DD/YYYY');
        that.rgsGridSetup();
       });
       var sub = this.route.queryParams.subscribe(params => {  
        this.client =params['Client'] ;
        if(this.client== undefined)
        {
          this.client= "ALL";
        }
        this.rgsGridSetup();
      });

    }

    rgsGridSetup(){

        var that = this;
        that.rgsRowExpandLoader = [];
              this.rgsDataGridLoader   = true;
              this.dataqualityservice.GetRgsSectorClassifictionDataQualities(this.asOfDate ,this.client).then(data=>{
                that.rgsSectorData=data;
              var index = 0;
              data.forEach(element => {
                that.rgsRowExpandLoader[index] = false;
                index++;
              });
              this.rgsDataGridLoader=false;
              },onerror =>{
              this.rgsDataGridLoader =false; 
              });
    }

    getRgsSearchDetails(element,index){
      if(this.rgsPanelOpenState == true){
        this.rgsRowExpandLoader[index] = true;
        this.dataqualityservice.GetRgsSectorClassificationDataQualityDetails(this.asOfDate,element.securityId,this.client).then(data=>{
        this.rgsIndexDetailsModel = data ;
        this.rgsRowExpandLoader[index] = false;
      },onerror =>{
        this.rgsRowExpandLoader[index] =false; 
    });
    }
    }

    ExportXls(element):void{
      this.dataqualityservice.GetRgsSectorClassificationDataQualityDetailsExportData(this.asOfDate,element.securityId,this.client).then(res => {
              this.exportService.exportData(res,"RGS Data");
              }).catch(err=> { 
              }); 
    }
    

}  