import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewclimatedatapackconfigurationForm } from '../../models/climatedatapackconfiguration';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report } from '../../models/report';
import { formatDate, DatePipe } from '@angular/common';
import { climatedatapackconfigurationDataService } from '../../services/climatedatapackconfigurations.service';
import { debug } from 'console';

@Component({
  selector: 'app-new-climatedatapackconfigurationdata-popup',
  templateUrl: './new-climatedatapackconfigurationdata-popup.component.html',
  styleUrls: ['./new-climatedatapackconfigurationdata-popup.component.scss']
})
export class NewclimatedatapackconfigurationPopupComponent implements OnInit {

 form: FormGroup = this.fb.group({
    indexCode: ['', Validators.required],
   parentIndexCode: [''],
    currency: [''],
   parentcurrency:['']
  } as Record<keyof NewclimatedatapackconfigurationForm, any[]>);

  updateReport: boolean = false;
  isreportForm = false;
  currencies: string[] ;
  public currenciesfilteredList : string[];
    parentcurrencies: string[] ;
  public parentcurrenciesfilteredList : string[];
  constructor(private fb: FormBuilder, private climatedatapackconfigurationdataservice: climatedatapackconfigurationDataService, public dialogRef: MatDialogRef<NewclimatedatapackconfigurationPopupComponent>, @Inject(MAT_DIALOG_DATA) public rowdata: any) {

    console.log(rowdata);

  }

  ngOnInit(): void {
    if (this.rowdata) {
      this.updateReport = true;
      this.form.patchValue({
        indexCode: this.rowdata.IndexCode,
        parentIndexCode: this.rowdata.ParentIndexCode,
        currency: this.rowdata.Currency,
        parentcurrency: this.rowdata.parentcurrency
      })   
    }
    this.climatedatapackconfigurationdataservice.getCurrencies().subscribe(data =>{
      this.currencies =data.data;
      this.currenciesfilteredList = data.data;
    });
    this.climatedatapackconfigurationdataservice.getCurrencies().subscribe(data => {
      this.parentcurrencies = data.data;
      this.parentcurrenciesfilteredList = data.data;
    }) ;
  }

  public onClickHandler(event: any): void {
 
  }

  onSubmit(): void {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      this.dialogRef.close(data);
    }
    if (this.form.invalid) {
      this.isreportForm = true;
      return;
    }
  } 

  }
