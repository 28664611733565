import { Component,OnInit } from '@angular/core';


@Component({
    templateUrl: './other-page.component.html'
})

export class OtherPageComponent implements OnInit {    

    ngOnInit(){}
}