import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewSovereignConfigurationIndexForm } from '../../models/sovereignconfiguration';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report } from '../../models/report';
import { formatDate, DatePipe } from '@angular/common';
import { SovereignConfigurationDataService } from '../../services/sovereignconfiguration.service';
import { debug } from 'console';

@Component({
  selector: 'app-new-sovereignconfigurationindexdata-popup',
  templateUrl: './new-sovereignconfigurationindexdata-popup.component.html',
  styleUrls: ['./new-sovereignconfigurationindexdata-popup.component.scss']
})
export class NewSovereignconfigurationIndexPopupComponent implements OnInit {

 form: FormGroup = this.fb.group({
    indexCode: ['', Validators.required],
   indexFamilyId: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
   indexName: ['', Validators.required],
 } as Record<keyof NewSovereignConfigurationIndexForm, any[]>);

  updateReport: boolean = false;
  isreportForm = false;

  constructor(private fb: FormBuilder, private sovereignConfigurationDataService: SovereignConfigurationDataService, public dialogRef: MatDialogRef<NewSovereignconfigurationIndexPopupComponent>, @Inject(MAT_DIALOG_DATA) public rowdata: any) {

    console.log(rowdata);

  }

  ngOnInit(): void {
    if (this.rowdata) {
      this.updateReport = true;
      this.form.patchValue({
        indexCode: this.rowdata.IndexCode,
        indexName: this.rowdata.IndexName,
        indexFamilyId: this.rowdata.IndexFamilyId
      })   
    }
  }

  public onClickHandler(event: any): void {
 
  }

  onSubmit(): void {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      this.dialogRef.close(data);
    }
    if (this.form.invalid) {
      this.isreportForm = true;
      return;
    }
  } 

  }
