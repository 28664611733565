import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewVanguardConfigurationsForm } from '../../models/coverageconfig';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report } from '../../models/report';
import { formatDate, DatePipe } from '@angular/common';
import { CoverageConfigDataService } from '../../services/coverageconfig.service';
import { debug } from 'console';

@Component({
  selector: 'app-new-vanguardconfigurationdata-popup',
  templateUrl: './new-vanguardconfigurationdata-popup.component.html',
  styleUrls: ['./new-vanguardconfigurationdata-popup.component.scss']
})
export class NewVanguardConfigurationPopupComponent implements OnInit {

  form: FormGroup = this.fb.group({
    indexcode: ['', Validators.required],
    clientName: ['', Validators.required],
    parentIndexcode: [''],
    climateMetric: [''],
    currencyCode: [''],
    excllstwgt: [''],
    outputRegex: ['', Validators.required]
  } as Record<keyof NewVanguardConfigurationsForm, any[]>);

  updateReport: boolean = false;
  isreportForm = false;
  isClimateMetric: boolean = false;
  isExcllstwgt: boolean = false;
  currencies: string[];
  public currenciesfilteredList: string[];

  constructor(private fb: FormBuilder, private coverageConfigDataService: CoverageConfigDataService, public dialogRef: MatDialogRef<NewVanguardConfigurationPopupComponent>, @Inject(MAT_DIALOG_DATA) public rowdata: any) {

    console.log(rowdata);

  }

  ngOnInit(): void {
    if (this.rowdata) {
      this.updateReport = true;
      this.form.patchValue({
        indexcode: this.rowdata.IndexCode,
        parentIndexcode: this.rowdata.ParentIndexCode,
        currencyCode: this.rowdata.CurrencyCode,
        climateMetric: this.rowdata.ClimateMetric,
        excllstwgt: this.rowdata.Excllstwgt,
        outputRegex: this.rowdata.OutputRegex,
        clientName: this.rowdata.ClientName
      });
      this.isClimateMetric = this.rowdata.ClimateMetric;
      this.isExcllstwgt = this.rowdata.Excllstwgt;
    }
    this.coverageConfigDataService.getCurrencies().subscribe(data => {
      this.currencies = data.data;
      this.currenciesfilteredList = data.data;
    });
    
   
  }
  public onClickHandlerExcllstwgt(event: any): void {
    this.isExcllstwgt = event.checked;
  }
  public onClickHandler(event: any): void {
    this.isClimateMetric = event.checked;
  }

  onSubmit(): void {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      data.climateMetric = this.isClimateMetric;
      data.excllstwgt = this.isExcllstwgt;
      this.dialogRef.close(data);
    }
    if (this.form.invalid) {
      this.isreportForm = true;
      return;
    }
  }

}
