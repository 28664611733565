import {Component, NgModule, OnInit,Output,EventEmitter, Input,ViewChild,ElementRef,Renderer2,AfterViewInit} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import { Daterangepicker,DaterangepickerConfig } from 'ng2-daterangepicker';
import { forkJoin } from "rxjs";
import * as moment from 'moment';
import { LandingService } from '../../services/landing.service';
import {DateService } from '../../services/date.service';
import { BiService } from '../../services/BiService.service';
import { BiEvent, Events, InputParameter } from '../../models/events';
import { DateRangePickerComponent } from '../date-range-picker/date-range-picker.component';

@Component({
  selector: 'custom-date-range-picker',
  templateUrl: './custom-date-range-picker.component.html'
})

export class CustomDateRangePickerComponent implements OnInit{
    constructor(private dateService : DateService,private landingService: LandingService,
        private myDaterangepickerOptions: DaterangepickerConfig, private biService: BiService,
        private renderer: Renderer2) { }  
    
    @Output() notifyDate: EventEmitter<any> = new EventEmitter<any>();
    dateFormat : any;
    TodayDate:any ;
    Yesterday:any ;
    OneMonth:any ;
    TwoMonths:any;
    StartMonth:any ;
    Quarter:any;
    ThreeMonths:any; 
    SixMonths:any; 
    OneYear:any ;
    TwoYears:any ;
    ThreeYears:any ;
    isClear= true;
    @Input()  mainInput = {
        start: this.TwoMonths,
        end: this.Yesterday,
        label:'Daily',
    }  
    isInputFocus:InputFocus = InputFocus.LostFocus; 
    dateRangeRegexPattern :string = "^([0-1][1-9]/[0-1][1-9]/[1-2][0-9][0-9][0-9] - [0-1][1-9]/[0-1][1-9]/[1-2][0-9][0-9][0-9])|([0-1][1-9]/[0-1][1-9]/[1-2][0-9][0-9][0-9]-[0-1][1-9]/[0-1][1-9]/[1-2][0-9][0-9][0-9])|([0-1][1-9]/[0-1][1-9]/[1-2][0-9][0-9][0-9]- [0-1][1-9]/[0-1][1-9]/[1-2][0-9][0-9][0-9])|([0-1][1-9]/[0-1][1-9]/[1-2][0-9][0-9][0-9] -[0-1][1-9]/[0-1][1-9]/[1-2][0-9][0-9][0-9])$"; 
    typedText: string = "";
    @ViewChild('picker',{static: false}) inputPicker: ElementRef;
    @ViewChild('pickerSubstitute',{static: false}) inputPickerSubstitute: ElementRef;
    @ViewChild('realDateTextBox',{static: false}) inputRealDateTextBox: ElementRef;
    
    ngOnInit(){
       var startDate = this.dateService.getStartDate();
       var endDate = this.dateService.getEndDate();
       console.log(startDate)
       if(startDate == null)
       {         
           this.mainInput.start = moment("04/01/2019").toDate();
           console.log(this.mainInput.start)
           this.landingService.getBusinessDate().then(date=>{
                this.mainInput.end= moment(date).toDate();          
                this.notifyDate.emit(this.mainInput); 
          });
       }
       else
       {
            this.mainInput.start = startDate;
            this.mainInput.end = endDate;
       }
            this.myDaterangepickerOptions.settings = {
             locale: { format: 'MM/DD/YYYY' },
             alwaysShowCalendars: false,
             singleDatePicker:false,
             autoApply: true,
             linkedCalendars: false,
             showDropdowns: true,    
             startDate: this.TwoMonths,
             endDate: this.TodayDate          
         };
        
         this.biService.GlobalInputs.StartDate = this.mainInput.start;
         this.biService.GlobalInputs.EndDate = this.mainInput.end;       
         this.dateFormat = this.dateService.getDateFormat();       

         this.dateService.dateFormatPublish().subscribe(dateFormat=>{
            this.dateFormat =  dateFormat;            
        });
         
    }

    ngAfterViewInit(){
       this.renderer.setStyle(this.inputPickerSubstitute.nativeElement,"display","none");
   }
   filter() {
    this.isClear = false;
  }
   OnKey(event:any){
       if(event.key === "Enter"){
        this.customDateRangeFocusOut();
       }
   }

    public selectedDate(value: any, dateInput: any) {
        this.dateService.setStartDateAndEndDate(value.start,value.end);
        this.biService.GlobalInputs.StartDate = value.start;
        this.biService.GlobalInputs.EndDate = value.end;
        dateInput.start = value.start;
        dateInput.end = value.end;
        var event: any = {eventName: "Date selection",page: "Common"};
        this.biService.addEvent(event);
        this.notifyDate.emit(value);
        }

        customDateRangeFocus(event){
            var reg = new RegExp(this.dateRangeRegexPattern);
            var dateRangeInputCheck = reg.test(this.typedText);
            if(dateRangeInputCheck){
                this.isInputFocus = InputFocus.Focus;
                this.getInputDisplay();
                var stringDates = this.typedText.split('-');
                var firstDate = moment(stringDates[0].trim());
                var secondDate = moment(stringDates[1].trim());
                console.log(firstDate)
                if(firstDate <= secondDate){
                    this.mainInput.start = firstDate;
                    this.mainInput.end = secondDate;
                    this.TwoMonths = firstDate;
                    this.TodayDate = secondDate;
                    this.dateService.setStartDateAndEndDate(firstDate,secondDate);
                    this.biService.GlobalInputs.StartDate = firstDate;
                    this.biService.GlobalInputs.EndDate = secondDate;
                    var event: any = {eventName: "Date selection",page: "Common"};
                    this.biService.addEvent(event);
                    this.notifyDate.emit(this.mainInput); 
                    this.renderer.setStyle(this.inputPickerSubstitute.nativeElement,"display","none");
                    this.renderer.setStyle(this.inputPicker.nativeElement,"display","");
                    this.inputRealDateTextBox.nativeElement.focus();
                }else{
                    this.isInputFocus = InputFocus.Focus;
                    this.getInputDisplay();
                }
            }else{
                this.isInputFocus = InputFocus.Focus;
                this.getInputDisplay();
            }
        }

        customDateRangeFocusOut(){
            var reg = new RegExp(this.dateRangeRegexPattern);
            var dateRangeInputCheck = reg.test(this.typedText);
            if(dateRangeInputCheck){
                this.isInputFocus = InputFocus.LostFocus;
                this.getInputDisplay();
                var stringDates = this.typedText.split('-');
                var firstDate = moment(stringDates[0].trim());
                var secondDate = moment(stringDates[1].trim());
                if(firstDate <= secondDate){
                    this.mainInput.start = firstDate;
                    this.mainInput.end = secondDate;
                    this.TwoMonths = firstDate;
                    this.TodayDate = secondDate;
                    this.dateService.setStartDateAndEndDate(firstDate,secondDate);
                    this.biService.GlobalInputs.StartDate = firstDate;
                    this.biService.GlobalInputs.EndDate = secondDate;
                    var event: any = {eventName: "Date selection",page: "Common"};
                    this.biService.addEvent(event);
                    this.notifyDate.emit(this.mainInput); 
                    this.renderer.setStyle(this.inputPickerSubstitute.nativeElement,"display","none");
                    this.renderer.setStyle(this.inputPicker.nativeElement,"display","");
                }else{
                    this.isInputFocus = InputFocus.FocusWrongData;
                    this.getInputDisplay();
                }
            }else{
                this.isInputFocus = InputFocus.FocusWrongData;
                this.getInputDisplay();
            }
            
        }

        datePickerReAssign(){
            this.renderer.setStyle(this.inputPickerSubstitute.nativeElement,"display","none");
            this.renderer.setStyle(this.inputPicker.nativeElement,"display","");
        }

        getInputDisplay(){
        if(this.isInputFocus == InputFocus.Focus){
            return "customDateRangeInputFocus";
        }
        if(this.isInputFocus == InputFocus.FocusWrongData){
            return "customDateRangeFocusWrongData";
        }
        if(this.isInputFocus == InputFocus.LostFocus){
            return "customDateRangeInputLostFocus"
        }
           return "";
        }
    } 
    
    export enum InputFocus{
        Focus,
        FocusWrongData,
        LostFocus
    }
