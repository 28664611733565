import { Injectable }    from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import { CloseTopBottomHolding } from '../models/close-holdings-top-bottom';
import {CloseSectorClassification} from '../models/close-holdings-sector-classification'
import {CloseHoldingsChartModel} from '../models/close-holdings-chart-model';
import {CloseCurrencyWeightHoldings} from '../models/close-holdings-currency-weights';

import {CloseHoldingsGridModel} from '../models/close-holdings-grid';
import {CloseHoldingsReturnsModel} from '../models/close-holdings-returns';

import {CountryWeightModel} from '../models/countryWeight.model';
import {CurrencyWeightModel} from '../models/currencyWeight.model';

import {S3UrlModel} from '../models/s3-url.model';

import {SanetizeStringService} from '../services/sanetize-string.service';


@Injectable() 
export class CloseHoldingService {

    private headers = new Headers({'Content-Type': 'application/json'});
    
    constructor( private httpClient: HttpClient,
    private sanetizeStringService: SanetizeStringService) { 
    }

    getTopBottomHoldings(asOfDate: any,indexMarkers : any ,currencyCode : any) : Promise<CloseTopBottomHolding> {
         
         let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/closeHoldings/gettopbottomholdings',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers,                
                CurrencyCode :currencyCode                
        }), {headers: headers})
        .toPromise()
        .then(response =>{
             return response as CloseTopBottomHolding
            })
        .catch(this.handleError);    
    }

    getBubbleDataset(asOfDate: any,indexMarkers : any,currencyCode : any) : Promise<CloseSectorClassification> {
        
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
       return this.httpClient.post(environment.dataServiceRoot + 'ui/closeHoldings/getbubbledataset',
           JSON.stringify({
               AsOfDate : asOfDate,
               IndexMarkers : indexMarkers,               
               CurrencyCode :currencyCode               
       }), {headers: headers})
       .toPromise()
       .then(response =>{
            return response as CloseSectorClassification
           })
       .catch(this.handleError);    
   }

   getCountryWeights(asOfDate: any,indexMarkers : any,currencyCode : any) : Promise<CloseHoldingsChartModel> {
    
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/closeHoldings/getcountryweights',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexMarkers : indexMarkers,               
                CurrencyCode :currencyCode
        }), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CloseHoldingsChartModel
            })
        .catch(this.handleError);    
    }

    getCurrencyWeights(asOfDate: any,indexMarkers : any,currencyCode : any) : Promise<CloseCurrencyWeightHoldings> {
        
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            return this.httpClient.post(environment.dataServiceRoot + 'ui/closeHoldings/getcurrencyweights',
                JSON.stringify({
                    AsOfDate : asOfDate,
                    IndexMarkers : indexMarkers,
                    CurrencyCode :currencyCode,
            }), {headers: headers})
            .toPromise()
            .then(response =>{
                    return response as CloseCurrencyWeightHoldings
                })
            .catch(this.handleError);    
        }
        
        

        getPositions(asOfDate: any,indexMarkers : any,currencyCode : any, sortBy: any) : Promise<CloseHoldingsGridModel> {
            
                let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
                return this.httpClient.post(environment.dataServiceRoot + 'ui/closeHoldings/getpositions',
                    JSON.stringify({
                        AsOfDate : asOfDate,
                        IndexMarkers : indexMarkers,                        
                        CurrencyCode :currencyCode  ,
                        SortBy: sortBy                      
                }), {headers: headers})
                .toPromise()
                .then(response =>{
                        return response as CloseHoldingsGridModel
                    })
                .catch(this.handleError);    
            }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    

        getTopBottomHoldingsData(asOfDate:any,indexMarkers:any,currencyCode:any,sortBy:any):Promise<CloseHoldingsReturnsModel[]>{
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            return this.httpClient.post(environment.dataServiceRoot + 'ui/closeHoldings/gettopbottomholdingsdata',
                JSON.stringify({
                    AsOfDate : asOfDate,
                    IndexMarkers : indexMarkers,
                    CurrencyCode:currencyCode,
                    SortBy: sortBy
                                
            }), {headers: headers})
            .toPromise()
            .then(response =>{
                    return response as CloseHoldingsReturnsModel[];
                })
            .catch(this.handleError);  
        }

        getCountryWeightData(asOfDate:any,indexMarkers:any):Promise<CountryWeightModel[]>{
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            return this.httpClient.post(environment.dataServiceRoot + 'ui/closeHoldings/getcountryweightsdata',
                JSON.stringify({
                    AsOfDate : asOfDate,
                    IndexMarkers : indexMarkers                      
            }), {headers: headers})
            .toPromise()
            .then(response =>{
                    return response as CountryWeightModel[]
                })
            .catch(this.handleError);  
        }

        getCurrencyWeightData(asOfDate:any,indexMarkers:any):Promise<CurrencyWeightModel[]>{
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            return this.httpClient.post(environment.dataServiceRoot + 'ui/closeHoldings/getcurrencyweightsdata',
                JSON.stringify({
                    AsOfDate : asOfDate,
                    IndexMarkers : indexMarkers                      
            }), {headers: headers})
            .toPromise()
            .then(response =>{
                    return response as CurrencyWeightModel[]
                })
            .catch(this.handleError);  
        }
        getGridHoldingstData(asOfDate:any,indexMarkers:any,selectedColumns:string[]):Promise<S3UrlModel>{
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            return this.httpClient.post(environment.dataServiceRoot + 'ui/closeHoldings/getgridholdlings',
                JSON.stringify({
                    AsOfDate : asOfDate,
                    IndexMarkers : indexMarkers,
                    SelectedColumns:selectedColumns                      
            }), {headers: headers})
            .toPromise()
            .then(response =>{
                    return response as S3UrlModel
                })
            .catch(this.handleError);  
        }

        getHoldingsTab(asOfDate:any,indexMarkers:any,currencyCode:any):Promise<any[]>{
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            return this.httpClient.post(environment.dataServiceRoot + 'ui/closeHoldings/GetHoldingsTab',
                JSON.stringify({
                    AsOfDate : asOfDate,
                    IndexMarkers : indexMarkers,
                    CurrencyCode:currencyCode                      
            }), {headers: headers})
            .toPromise()
            .then(response =>{
                    return response as any[]
                })
            .catch(this.handleError);  
        }
        
        queueRequest(startDate:any,endDate:any,timePeriod:any,indexMarkers:any):Promise<any>{
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            var userId =  sessionStorage.getItem("LoggedInUser");
            var sanetizedUser = this.sanetizeStringService.sanitizeStringLiteral(userId);

            return this.httpClient.post(environment.dataServiceRoot + 'ui/closeHoldings/queuerequest',
                JSON.stringify({
                    StartDate : startDate,
                    EndDate:endDate,
                    TimePeriod:timePeriod,
                    IndexMarkers : indexMarkers,
                    UserId:sanetizedUser                  
            }), {headers: headers})
            .toPromise()
            .then(response =>{
                    return response as any
                })
            .catch(this.handleError);  
        }
}