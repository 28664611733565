import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-additional-toolbar',
  templateUrl: './additional-toolbar.component.html',
  styleUrls: ['./additional-toolbar.component.scss']
})
export class AdditionalToolbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
