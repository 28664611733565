import { Component, OnInit, Input, OnChanges, SimpleChanges,Inject,Output,EventEmitter,NgZone } from '@angular/core';
import {WeightService} from '../../services/weight.service';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {BiService} from '../../services/BiService.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {MagellanColorService} from '../../services/magellancolor.service';
import { CloseHoldingsChartModel } from '../../models/close-holdings-chart-model';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import {DateService } from '../../services/date.service';

@Component({
    selector: 'weight-sector-history-weights-country-chart',
    templateUrl: './weight-sector-history-weights-country-chart.component.html'
})
export class WeightSectorHistoryWeightsCountryChartComponent implements OnInit {

    chartAttributes: any;
    popchartAttributes:any;
    sectorHistoryWeightsCountryDataSource: any;
    currentIndex:any;
    colors : any = "#7FBA00,#00B294,#FF8C00,#0072C6,#68217A,#00188F,#808080";
    chart:any;
    dateFormat: any;
    checkLoadFirstTime: boolean;
    dateFormatUK: boolean;
    dateFormatUSA: boolean;
    asofDates: any;
    colorsRange:string;
    startDate: any;
    indexMarkers: any; 
    treeMapObject: any;
    selectedSectorChart : string = "icb";
    currencyCode:any="USD";
    timePeriod:any;
    popupDataSource:any;
    targetPopupDataSource:any;
    events:any;
    SectorHistoryWeightsCountryChartLoader:boolean = true;
    @Output() chartCurentIndexMarker: EventEmitter<string> = new EventEmitter();

    constructor(private weightService: WeightService ,private indexMarkerService:IndexMarkerService,
         private exportService: ExportToExcelService,private publishIndexMarkerService:PublishIndexMarkerService,
        private dialog: MatDialog,private colorService : MagellanColorService,private biService:BiService,
        private zone:NgZone,private idwChartExportService: IDWChartExportService,private dateService : DateService) {
            this.chartAttributes = {
                "showLegend" :"0",
                "exportEnabled": "1",
                "exportShowMenuItem": "0",
                            "bgColor": "#ffffff",
                            "numberSuffix":"%",
                            "paletteColors": "",
                            "borderAlpha": "20",
                            "showCanvasBorder": "0",
                            "usePlotGradientColor": "0",
                            "plotBorderAlpha": "10",
                            "plotFillAlpha": "100",
                            "legendBorderAlpha": "0",
                            "legendShadow": "0",
                            "showValues": "0",
                            "showBorder": "0",
                            "showXAxisLine": "1",
                            "xAxisLineColor": "#999999",
                            "divlineColor": "#999999",
                            "divLineDashed": "1",
                            "showAlternateHGridColor": "0",
                            "drawAnchors":"1",
                            "anchorRadius":"5"         
            };
             
    }
    
   
    ngOnInit() {
        this.dateFormat = this.dateService.getDateFormat(); 
            this.dateService.dateFormatPublish().subscribe(dateFormat=>{
                this.SectorHistoryWeightsCountryChartLoader = true;
                this.dateFormat =  dateFormat;      
                this.changeDateFormat(this.treeMapObject);
            });

        this.indexMarkers = this.indexMarkerService.getIndexMarkers();
        this.colors = this.colorService.getCurrentColorSchema();
        this.colorService.colorsPublish().subscribe(color => {     
            this.colors = color; 
            this.chartAttributes.paletteColors=  this.colors;    
        });
        this.currentIndex = 0;  
        this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
            this.indexMarkers = result;
            this.SectorHistoryWeightsCountryChartLoader=true;
            this.chartSetup(this.treeMapObject);
        }); 
        this.events = {
            initialized: this.chartInitialize(event)
        }
    }

    chartInitialize(event){
        return (eve, arg) => { 
            this.zone.run(() => {
                this.chart =eve.sender;
            });  
            
        };
          
      }  
      private changeDateFormat(data: any) {
        if (this.dateFormat == "UK") {
            for (var i = 0; i < data.holdingscategories[0].category.length; i++) {
              data.holdingscategories[0].category[i].label = this.dateService.changeDateFormatUK(data.holdingscategories[0].category[i].label);
            }
        }
        else {
          if (this.dateFormat == "USA" && this.checkLoadFirstTime == false) {
            for (var i = 0; i < data.holdingscategories[0].category.length; i++) {
              data.holdingscategories[0].category[i].label = this.dateService.changeDateFormatUSA(data.holdingscategories[0].category[i].label);
            }                
          }         
        }
        this.checkLoadFirstTime = false;
      }

    carouselPrevious():void{
        var event: any = {eventName: "Sector Country Weight chart carousel previous", inputParameters: [{Name: "Previous IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() },{Name : "Relevent TimePeriod" , Value: this.timePeriod},{Name: "Relevent As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);
        if(0 == this.currentIndex){
           this.currentIndex = this.indexMarkers.length -1;
        }else{
            this.currentIndex = this.currentIndex - 1;
        }
        this.SectorHistoryWeightsCountryChartLoader = true;
        this.chartCurentIndexMarker.emit(this.indexMarkers[this.currentIndex]);
    }
    
    carouselNext():void{
        var event: any = {eventName: "Sector Country Weight chart carousel next", inputParameters: [{Name: "Next IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() },{Name : "Relevent TimePeriod" , Value: this.timePeriod},{Name: "Relevent As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);
       if((this.indexMarkers.length -1) == this.currentIndex){
            this.currentIndex = 0;
        }else{
            this.currentIndex = this.currentIndex + 1;
        }
        this.SectorHistoryWeightsCountryChartLoader = true;
        this.chartCurentIndexMarker.emit(this.indexMarkers[this.currentIndex]);
    }

    getCurrentIndex(){
        return this.indexMarkers[this.currentIndex];
    }

    update(closeHoldingsChartingModel: CloseHoldingsChartModel,startDate:any,endDate:any,timePeriod:any){
        this.startDate = startDate;
        this.asofDates = endDate;        
        this.timePeriod = timePeriod;
        this.chartSetup(closeHoldingsChartingModel);
    }

    onChange(selectedChart,currencyCode){
        this.currencyCode=currencyCode;
        this.selectedSectorChart = selectedChart;  
        this.chartSetup(this.treeMapObject);     
        
    }
    private chartSetup(closeHoldingsChartingModel: CloseHoldingsChartModel) {
        this.SectorHistoryWeightsCountryChartLoader = false;
         this.chartAttributes.caption= "FTSE "+this.indexMarkers[this.currentIndex]+"-Country"; 
         
         this.treeMapObject = closeHoldingsChartingModel; 
         this.checkLoadFirstTime=true; 
         this.changeDateFormat(this.treeMapObject);   
            
         if(0 == this.treeMapObject.holdingsDataset.length){
            this.sectorHistoryWeightsCountryDataSource = {};
         }  else{
            this.sectorHistoryWeightsCountryDataSource = {
                "chart": this.chartAttributes,
                "categories":this.treeMapObject.holdingscategories,
                "dataset":this.treeMapObject.holdingsDataset,
            }
         }    
             
         this.chartAttributes.paletteColors = this.colors; 
             this.popchartAttributes = {               
                 "showLegend" :"1",
                 "legendPosition":"RIGHT",
                 "exportEnabled": "1",
                 "exportShowMenuItem": "0",
                             "bgColor": "#ffffff",
                             "numberSuffix":"%",
                             "paletteColors": "",
                             "borderAlpha": "20",
                             "showCanvasBorder": "0",
                             "usePlotGradientColor": "0",
                             "plotBorderAlpha": "10",
                             "plotFillAlpha": "100",
                             "legendBorderAlpha": "0",
                             "legendShadow": "0",
                             "showValues": "0",
                             "showBorder": "0",
                             "showXAxisLine": "1",
                             "xAxisLineColor": "#999999",
                             "divlineColor": "#999999",
                             "divLineDashed": "1",
                             "showAlternateHGridColor": "0" ,
                             "drawAnchors":"1",
                             "anchorRadius":"5"   
             };       
             this.popchartAttributes.caption= "FTSE "+this.indexMarkers[this.currentIndex]+"-Country";
             if(0 == this.treeMapObject.holdingsDataset.length){
                this.popupDataSource = {};
             }  else{
                this.popupDataSource={
                    "chart": this.popchartAttributes,
                    "categories":this.treeMapObject.holdingscategories,
                    "dataset":this.treeMapObject.holdingsDataset,
                };
                this.targetPopupDataSource = {
                  "dataSource": this.popupDataSource,
                  "asOfDate": (this.dateFormat == "USA") ? this.dateService.changeDateFormat(this.asofDates) : this.dateService.changeDateFormatUK(this.asofDates)
                };
             }
            
    }
    loadingRefresh(){
        this.SectorHistoryWeightsCountryChartLoader = true;
        this.sectorHistoryWeightsCountryDataSource = {};
    }
    loadingStop(){
        this.SectorHistoryWeightsCountryChartLoader = false;
    }

    failedLoad(){
        this.sectorHistoryWeightsCountryDataSource = {};
        this.SectorHistoryWeightsCountryChartLoader = false;
    }

 ExportXls(event):void{
    var event: any = {eventName: "Weight Sector Country Weight Chart Exported", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name : "Exported TimePeriod" , Value: this.timePeriod},{Name: "Exported As Of Date", Value: this.asofDates.toString()}]};
    this.biService.addEvent(event);
        var inputData = {
            "exportType": "xls",
            "code": "weight-getCountryWeightsExportData",
            "xlsFileName": "countrySectorRawData",
            "asOfDate": this.asofDates,
            "indexMarkers": [this.indexMarkers[this.currentIndex]],
            "fromDate": this.startDate,
            "timePeriod": this.timePeriod
          };
          this.idwChartExportService.export(inputData);
      }

      ExportPNG(event):void{
        var event: any = {eventName: "Export Weight Sector Country Weight Chart TO PNG", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name : "Exported TimePeriod" , Value: this.timePeriod},{Name: "Exported As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);
        this.targetPopupDataSource.dataSource.chart.showlegend = "1";
        var inputData = {
            "exportType": "png",
            "legend": false,
            "dataSource": this.targetPopupDataSource.dataSource,
            "chartid" : "hisroryWeigtSectorExport",
            "type" : "stackedarea2d",
            "targetDiv" : "exportPngDiv",
            "chartReference": this.chart,
            "isIndexLegend": false,
            "pngFileName" : "countrySectorRawData",
            "chartTitle": "History Weights - Country",
            "chartDate": this.asofDates
         };
         this.idwChartExportService.export(inputData);
      }


    openDialog(): void{ 
        var event: any = {eventName: "help dialog open of Weight Sector Country Weight Chart", inputParameters: [{Name : "Expanded IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name : "Expanded TimePeriod" , Value: this.timePeriod},{Name: "Expanded As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);
        this.popchartAttributes.paletteColors = this.colors;
        this.targetPopupDataSource = {
            "dataSource": this.popupDataSource,
            "asOfDate": (this.dateFormat == "USA") ? this.dateService.changeDateFormat(this.asofDates) : this.dateService.changeDateFormatUK(this.asofDates)
          };
        this.targetPopupDataSource.dataSource.chart.showlegend = "1";
        let dialogRef = this.dialog.open(WeightSectorHistoryWeightsCountryChartComponentDialog, {
            width: '80%',
            height: '70%',
	    data: this.targetPopupDataSource
  });
}

}

@Component({
    selector: 'weight-sector-history-weights-country-chart-dialog',
	templateUrl: './weight-sector-history-weights-country-chart.component.dialog.html'
})
export class WeightSectorHistoryWeightsCountryChartComponentDialog{

    constructor(
    public dialogRef: MatDialogRef<WeightSectorHistoryWeightsCountryChartComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    onClose(): void {
        this.dialogRef.close();
  }
   
}