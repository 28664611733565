import { Injectable} from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as AWS from 'aws-sdk';
import * as AWSCognito from 'amazon-cognito-identity-js';

@Injectable()
export class S3ManagerService{

    constructor(private http: Http,private httpClient: HttpClient){}
    tmpCredential:any = null;
    
    deleteData(bucketName:string,key:string,tempCredential:any):Promise<any>{
        return new Promise(resolve=>{
            AWS.config.update({
                region: 'eu-west-1',
                accessKeyId: tempCredential.AccessKeyId,
                secretAccessKey: tempCredential.SecretKey,
                sessionToken: tempCredential.SessionToken
             });
           var s3 = new AWS.S3({
           });
           var delParams = {
            Bucket: bucketName,
            Key: key,
            Body: ""
         }
         s3.putObject(delParams,function(err,data){
             if(err){
                 console.log(err);
             }
             else{
                 resolve(data);
             }
         });
        });
    }
    getRawData(bucketName:string,key:string,tempCredential:any):Promise<any>{
        return new Promise(resolve=>{
            AWS.config.update({
                region: 'eu-west-1',
                accessKeyId: tempCredential.AccessKeyId,
                secretAccessKey: tempCredential.SecretKey,
                sessionToken: tempCredential.SessionToken
             });
           var s3 = new AWS.S3({
           });
           var getParams = {
            Bucket: bucketName,
            Key: key,
            ResponseContentType: "arraybuffer"
         }
         s3.getObject(getParams,function(err,data){
             if(err){
                 console.log(err);
             }
             else{
                 resolve(data);
             }
         });
        });
       
    }


    getS3Data(bucketName:string,key:string,versionId: any,tempCredential:any):Promise<any>{
        return new Promise(resolve=>{
            AWS.config.update({
                region: 'eu-west-1',
                accessKeyId: tempCredential.AccessKeyId,
                secretAccessKey: tempCredential.SecretKey,
                sessionToken: tempCredential.SessionToken
             });
           var s3 = new AWS.S3({
           });
           var getParams = {
            Bucket: bucketName,
            Key: key,
            VersionId: versionId

         }
         s3.getObject(getParams,function(err,data){
             if(err){
                 console.log(err);
             }
             else{
                 resolve(data);
             }
         });
        });
       
    }
    getFileListForSpecificFolderInBucket(bucketName:string, folder:string,delimiter:string,tempCredential:any):Promise<any[]>{
        return new Promise(resolve=>{
            AWS.config.update({
                region: 'eu-west-1',
                accessKeyId: tempCredential.AccessKeyId,
                secretAccessKey: tempCredential.SecretKey,
                sessionToken: tempCredential.SessionToken
             });
           var s3 = new AWS.S3({
           });
           var getParams = {
            Bucket: bucketName,
            Prefix: folder,
            Delimiter:delimiter
         } 
         s3.listObjectsV2(getParams,function(err,data){
             if(err){
               console.log(err);
             }else{
                resolve(data.Contents);
             }
         });
        });
        
    }
    

//     var params = {Bucket: bucketname , Key: keyfile , Expires: 3600 , ResponseContentDisposition :  `attachment; filename="filename.ext"` };
// var url = s3.getSignedUrl('getObject', params);
 

  testS3SignedUrl(bucketName:string,key:string,tempCredential:any):Promise<any>{
    return new Promise(resolve=>{
        AWS.config.update({
            region: 'eu-west-1',
            accessKeyId: tempCredential.AccessKeyId,
            secretAccessKey: tempCredential.SecretKey,
            sessionToken: tempCredential.SessionToken
         });
       var s3 = new AWS.S3({
       });
       var getParams = {
        Bucket: bucketName,
        Key: key,
        Expires: 3600 
     }     
    try {
        const url = new Promise((resolve, reject) => {
          s3.getSignedUrl('getObject', getParams, function (err, url) {
            if (err) {
                console.log(err);
            }
            resolve(url); 
          })
        })
    
        return url
      } catch (err) {
        throw err;
      }
 
    });
   
}

  
    getS3SignedUrl(bucketName:string,key:string,tempCredential:any):Promise<any>{
        return new Promise(resolve=>{
            AWS.config.update({
                region: 'eu-west-1',
                accessKeyId: tempCredential.AccessKeyId,
                secretAccessKey: tempCredential.SecretKey,
                sessionToken: tempCredential.SessionToken
             });
           var s3 = new AWS.S3({
           });
           var getParams = {
            Bucket: bucketName,
            Key: key,
            Expires: 3600 ,
         }
         s3.getObject(getParams,function(err,data){
             if(err){
                 console.log(err);
             }
             else{
                 resolve(data);
             }
         });
        });
       
    }
}