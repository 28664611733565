import { Component, OnInit, ViewChild } from '@angular/core';
import { iFilter, iIndexMarker, iControl, iTable, iRequest, Status } from '../../models/data-restore';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataRestoreService } from '../../services/data-restore.service';
import { iResponseModel } from '../../models/response.model';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';

@Component({
  selector: 'app-data-restore',
  templateUrl: './data-restore.component.html',
  styleUrls: ['./data-restore.component.scss']
})
export class DataRestoreComponent implements OnInit {
  tables: iTable[] = [];
  selectedTable: number = 0;
  controls: iControl[] = [];
  filter: iFilter;
  selectTableLabel: string = 'Select table';
  selectAsOfDateLabel: string = 'Select date';
  todayDate: Date = new Date();
  public imSelectMultiCtrl: FormControl = new FormControl();
  public filteredIndexMarkers: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public indexMarkerMultiFilterCtrl: FormControl = new FormControl();
  public asOfDate = new FormControl();
  indexMarkerModel: iIndexMarker[] = [];
  protected _onDestroy = new Subject<void>();
  loaderIndexMarker: boolean = false;
  loaderTransfer: boolean = false;
  loaderRequest: boolean = false;
  btnDisabled: boolean = true;
  @ViewChild(MatDatepicker) picker: MatDatepicker<Moment>;
  requests: iRequest[] = [];

  constructor(private dataRestoreService: DataRestoreService) { }

  ngOnInit(): void {
    this.ResetControls();
    this.dataRestoreService.GetRequests().then((response: iRequest[]) => { this.requests = this.UpdateFlag(response); })
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  tableSelect(event: any, row: any) {
    if (event.isUserInput) {
      this.filter.tableId = row.id;
      this.selectedTable = row.id;
      if (this.filter.tableId == 0) {
        this.controls[0].placeholder = 'Select table';
        this.controls[1].isVisible = this.controls[2].isVisible = false;
        this.btnDisabled = true;
        this.ResetControls();
      } else {
        this.controls[0].placeholder = 'Selected table';
        this.loadTable(row.id, true);
        this.btnDisabled = false;
      }
    }
  }

  loadTable(id: number, isLoadTable: boolean) {
    this.setControlVisibility(id, isLoadTable);    
    this.loaderIndexMarker = true;
    this.imSelectMultiCtrl = new FormControl();
    this.dataRestoreService.GetIndexMarkers('').then(res => {
      this.indexMarkerModel = res;
      this.filteredIndexMarkers.next(res.slice());
      this.indexMarkerMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filtertypeIndexMarker();
        });
      this.loaderIndexMarker = false;
      if (res.length <= 0)
        alert(`No records found for AsOfDate ${this.filter.asOfDate.toLocaleDateString()}.`)
    });
  }
  setControlVisibility(id: number, isLoadTable: boolean) {
    this.controls[1].isVisible = this.controls[2].isVisible = false;
    switch (id) {
      case this.tables[1].id:
      case this.tables[2].id:
        {
          this.controls[1].isVisible = this.controls[2].isVisible = true;
          if (isLoadTable)
          {
            this.controls[1].placeholder = 'Select date';
            this.filter.asOfDate = null;
            this.filter.indexId = '';
          }
          else 
          {
            this.filter.indexId = '';
          }          
          break;
        }
      default:
        break;
    }
    //this.controls[1].isVisible = this.controls[2].isVisible = id == 1 ? true : false;
  }

  dateSelected(asOfdate: Date) {
    if (asOfdate != null) {
      this.controls[1].placeholder = 'Selected date';
    }
  }

  filtertypeIndexMarker() {
    let search = this.indexMarkerMultiFilterCtrl.value;
    if (!search) {
      this.filteredIndexMarkers.next(this.indexMarkerModel.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.loaderIndexMarker = true;
    this.dataRestoreService.GetIndexMarkers(search).then(res => {
      this.indexMarkerModel = res;
      this.filteredIndexMarkers.next(res.slice());
      this.loaderIndexMarker = false;
    });
  }

  Transfer() {
    if (this.Validate()) {
      const tablename = this.tables.find(x => x.id == this.filter.tableId).name;
      this.loaderTransfer = true;
      this.dataRestoreService.TransferRecords(tablename, this.filter)
        .then((response: iResponseModel) => {
          this.loaderTransfer = false;
          if (response.uiNotification != null && response.uiNotification.length > 0)
            alert(response.uiNotification.join("\n"));
          else alert(`records from ${tablename} sent for processing.`);

          this.requests = this.UpdateFlag(response.data);
        });
    }
  }

  Reset() {
    this.ResetControls();
    this.btnDisabled = true;
  }

  weekendsDatesFilter = (d: Date): boolean => {
    const day = d.getDay();
    /* Prevent Saturday and Sunday for select. */
    return day !== 0 && day !== 6;
  }

  Validate() {
    let errors: string[] = [];
    if (this.filter.asOfDate == undefined)
      errors.push('Please select AsOfDate');
    if (this.filter.indexId == '')
      errors.push('Please select Index marker');

    if (errors.length <= 0) { return true; }
    else { alert(errors.join('\n')); return false; }
  }

  SelectedIndexMarker(value: any) {
    this.filter.indexId = value;
    this.controls[2].placeholder = 'selected index marker';
  }

  openedChange(isOpened: boolean) {
    if (!isOpened && this.indexMarkerModel.length <= 0) {
      this.loadTable(1, false);
    }
    if (isOpened) {
      this.filter.indexId = '';
      this.controls[2].placeholder = 'select index marker';
    }
  }
  UpdateFlag(response: iRequest[]): iRequest[] {
    response.forEach(row => {
      row.displayRefresh = (
        row.status.toLowerCase() == Status.Started
        || row.status.toLowerCase() == Status.InProgress
        || row.status.toLowerCase() == Status.ValidatingRequest) ? true : false;
      row.displayTransfer = (row.status.toLowerCase() == 'failed') ? true : false;
    });
    return response;
  }

  refresh() {
    this.loaderRequest = true;
    this.dataRestoreService.GetRequests().then((response: iRequest[]) => {
      this.requests = this.UpdateFlag(response);
      this.loaderRequest = false;
    })
  }

  TransferAgain(row: iRequest) {
    this.filter.requestId = row.id;
    this.filter.tableId = this.tables.find(x => x.name == row.tableName).id;
    this.filter.asOfDate = row.asOfDate;
    this.filter.indexId = row.indexMarker;
    this.Transfer();
    this.ResetControls();
  }

  ResetControls() {
    this.tables = [];
    this.tables = [
      { id: 0, name: '--select--' },
      { id: 1, name: 'Consolidated.HoldingsClose' },
      { id: 2, name: 'Consolidated.IndexValueClose' }
    ];
    this.selectedTable = this.tables[0].id;
    this.controls = [
      { label: 'Table', placeholder: 'select table', isVisible: true },
      { label: 'As Of Date', placeholder: 'select date', isVisible: false },
      { label: 'Index Marker', placeholder: 'select index marker', isVisible: false },
    ]
    this.filter = { tableId: 0, asOfDate: null, indexId: '', requestId: 0 };

    //Reset Control
    this.imSelectMultiCtrl = new FormControl();
    this.filteredIndexMarkers = new ReplaySubject<any[]>(1);
    this.indexMarkerMultiFilterCtrl = new FormControl();
    this.indexMarkerModel = [];
    this.asOfDate = null;
  }
}
