import { Component, OnInit,Input,EventEmitter,Output,Inject } from '@angular/core';
import { CloseHoldingService } from '../../services/close-holdings.service';
import {CloseHoldingPageModel} from '../../models/close-holding-page-model';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { BiService } from '../../services/BiService.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
    selector: 'close-ranked-holdings-grid',
    templateUrl: './close-holdings-ranked-holdings-grid.component.html',
    styleUrls:['close-holdings-ranked-holdings-grid.component.css']
})
export class CloseRankedHoldingsGridComponent implements OnInit {
    @Output() notifyPageModel : EventEmitter<CloseHoldingPageModel> = new EventEmitter<CloseHoldingPageModel>();
    asofDates : any;
    indexMarkers : any;
    currencyCode : string;
    chartAttributes: any;
    topHoldings: boolean;
    bottomHoldings: boolean;
    topholdingsDataset: any;
    bottomholdingsDataset: any;
    currentIndex : any;
    currentIndexName : any;

    constructor(private publishIndexMarkerService:PublishIndexMarkerService, private closeHoldingService: CloseHoldingService,
        private indexMarkerService:IndexMarkerService, private exportService: ExportToExcelService,private biService: BiService,public dialog: MatDialog) {  this.topHoldings = true; }


    toggleRankedHoldings(topRankedHoldings: boolean) {
        var event: any = {eventName: "toggle TopHoldings Clicked"};
        this.biService.addEvent(event);
        this.topHoldings = topRankedHoldings;
        this.bottomHoldings = !topRankedHoldings;
        var pageModel = new CloseHoldingPageModel();
        pageModel.asofdate = this.asofDates;
        pageModel.topBottomFlag = topRankedHoldings;
         pageModel.currencyCode = this.currencyCode ;
        this.notifyPageModel.emit(pageModel);
    }

    ngOnInit(){
        this.currentIndex = 0; 
        this.indexMarkers = this.indexMarkerService.getIndexMarkers();
        this.publishIndexMarkerService.indexMarkerPublish().subscribe(res=>{
        this.indexMarkers = res;        
        this.chartSetup();
    });
   }

   openWidgetDialog():void{
    var event: any = {eventName: "TopBottom Grid help dialog open"};
    this.biService.addEvent(event);
    let dialogRef = this.dialog.open(CloseRankedHoldingsGridComponentDialog, {
        width: '800px' , 
        data: this.indexMarkers     
      });
  }

    update(closeHoldingPageModel:CloseHoldingPageModel){
        this.asofDates = closeHoldingPageModel.asofdate;
        this.currencyCode =closeHoldingPageModel.currencyCode;
        this.chartSetup();
    }

    private chartSetup() {
        var oneIndexMarkers:string[] = [];     
        if(this.indexMarkers[this.currentIndex]==undefined)
        {
             this.currentIndex=0;
        }   
        oneIndexMarkers.push(this.indexMarkers[this.currentIndex]);
        this.currentIndexName = this.indexMarkers[this.currentIndex];
        this.closeHoldingService.getTopBottomHoldings(this.asofDates,oneIndexMarkers ,this.currencyCode ).then(data => {
            if(undefined!= data && undefined!=data.topHoldings && undefined!= data.bottomHoldings){
                this.topholdingsDataset = data.topHoldings[0].data;
                this.bottomholdingsDataset = data.bottomHoldings[0].data;
            }else{
                this.topholdingsDataset = [];
                this.bottomholdingsDataset = [];
            }
        
        });
    }

    carouselPrevious():void{
        var event: any = {eventName: "TopBottom Grid Previous Carousel", inputParameters: [{Name: "Previous IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() }]};
        this.biService.addEvent(event);
        if(0 == this.currentIndex){
           this.currentIndex = this.indexMarkers.length -1;
        }else{
            this.currentIndex = this.currentIndex - 1;
        }
        this.chartSetup();
    }

    carouselNext():void{        
        var event: any = {eventName: "TopBottom Grid next Carousel", inputParameters: [{Name: "Next IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() }]};
        this.biService.addEvent(event);
       if((this.indexMarkers.length -1) == this.currentIndex){
            this.currentIndex = 0;
        }else{
            this.currentIndex = this.currentIndex + 1;
        }
        this.chartSetup();
    }

    ExportXls(event):void{
        var event: any = {eventName: "Export Top Holdings Grid", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()}
        ,{Name : "CurrencyCode" , Value: this.currencyCode}]};
        this.biService.addEvent(event);
        var oneIndexMarkers:string[] = [];
        oneIndexMarkers.push(this.indexMarkers[this.currentIndex]);
        this.closeHoldingService.getTopBottomHoldings(this.asofDates,oneIndexMarkers,this.currencyCode ).then(data => {
               if(true == this.topHoldings){
                   if(null!= data && undefined!= data){
                    this.exportService.exportData(data.topHoldings[0].data,"topHoldings"+oneIndexMarkers);
                   }                  
               }else{
                   if(null!= data && undefined!= data){
            this.exportService.exportData(data.bottomHoldings[0].data,"bottomHoldings"+oneIndexMarkers);
                  }                   
               }
            });
    }
}


@Component({   
    templateUrl: './close-holdings-ranked-holdings-grid.component.widget.dialog.html'
  })
  export class CloseRankedHoldingsGridComponentDialog{
  
  @Input() indexes: string;
  constructor(
  public dialogRef: MatDialogRef<CloseRankedHoldingsGridComponentDialog>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
    this.indexes="";
     data.forEach(element => {
         if( this.indexes.length<3)
         {
            this.indexes=element;
         }else{
        this.indexes += ", "+ element;
         }
     });   
   }
  
  onClose(): void {
      this.dialogRef.close();
  }
  }