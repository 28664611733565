
import {catchError, debounceTime, distinctUntilChanged, tap, switchMap, merge} from 'rxjs/operators';
import { Component, OnInit} from '@angular/core';
import { SearchService } from '../../services/search.service'
import {Observable, of} from 'rxjs';







import { SearchResultModel } from '..//../models/search-model'; 
import { myfilter } from '..//../models/search-model'; 
import { Category } from '..//../models/search-model'; 
import { IndexMarkerService } from '../../services/indexmarker.service';
import { PublishIndexMarkerService } from '../../services/publish-index-marker.service';
import { SearchRequest, indexAttribute } from '../..//models/search-model';
import { BiService } from '../../services/BiService.service';
import { BiEvent, Events, InputParameter } from '../../models/events';
import { SearchIndexMarkerPipe} from '../../filter.pipe';
import { PagerService } from '../../services/pager.service';
@Component(
    {
        selector: 'app-search',
        templateUrl: './search-component.html'
    }
)
export class SearchComponent implements  OnInit
{
    model: any;
    indexMarkerModel: any ="";
    indexNameModel: any ="";
    productModel: any ="";
    geographyModel: any ="";
    productFamilyModel: any ="";
    segmentModel:any="";
    styleModel:any="";
    sizeModel:any="";
    searchFilter: myfilter;
    searching = false;
    searchFailed = false;
    indexArray:any;
    hideSearchingWhenUnsubscribed = new Observable(() => () => this.searching = false);
    returnResult: SearchResultModel;
    indexDetails: any[];
    defaultIndexDetails:any[];
    chosenIndexes : any[] = new Array();
    indexmarker : any[] = new Array();
    chosen : any[] = new Array();
    categories :any;    
    attributes : Category[];
    pager: any = {};
    filesPaged: any[] =[];
    page:any = 1;
    pageSize :any = 15;
    totalEntries :any = 0;
    isSearchIndexMarker:boolean = false;
    constructor(private searchService: SearchService,
         private indexMarkerService:IndexMarkerService, 
         private publishIndexMarkerService: PublishIndexMarkerService, private biService: BiService,private pagerService: PagerService) 
    {
        this.attributes =[
            {name:"ProductCode",color: "ProductCode",style:"", selected: false},
            {name:"ProductFamily",color: "ProductFamily",style:"", selected: false},
            {name:"Classification",color: "Classification",style:"", selected: false},
            {name:"ICB",color: "ICB",style:"", selected: false},
            {name:"RGS",color: "RGS",style:"", selected: false},
            {name:"NARIET",color: "NARIET",style:"", selected: false},
            {name:"Nomura",color: "Nomura",style:"", selected: false},
            {name:"Segment",color: "Segment",style:"", selected: false},
            {name:"Geography",color: "Geography",style:"", selected: false},
            {name:"Region",color: "Region",style:"", selected: false},
            {name:"Country",color: "Country",style:"", selected: false},
            {name:"CapSize",color: "CapSize",style:"", selected: false},
            {name:"SizeMarker",color: "SizeMarker",style:"", selected: false},
            {name:"Style",color: "Style",style:"", selected: false}
    ];
      /*  this.publishIndexMarkerService.indexMarkerPublish().subscribe(result => {
            this.chosenIndexes = result;
        });*/
    }

    ngOnInit(){
        this.chosenIndexes = this.indexMarkerService.getIndexMarkers();
        this.indexDetails = [];
        this.bindAdvancedSearhData();
        
        this.publishIndexMarkerService.indexMarkerPublish().subscribe(result => {
            this.chosenIndexes =[];
            [].push.apply(this.chosenIndexes, result);
           this.indexDetails =[];
           this.defaultIndexDetails =[];
           this.bindAdvancedSearhData();
           this.performSearch();
        });
        this.publishIndexMarkerService.indexMarkerNamesPublish().subscribe(result=>{ 
            this.indexArray =result
          });
        this.indexArray=this.indexMarkerService.getIndexMarkersNames();
      }

      bindAdvancedSearhData(){
        this.searchFilter = {
            Indexes : this.chosenIndexes
        }
        this.isSearchIndexMarker = true;
        var searchRequest: SearchRequest = {
            indexMarker: this.indexMarkerModel,
            indexName: this.indexNameModel,
            product: this.productModel,
            productFamily: this.productFamilyModel,
            region: this.geographyModel,
            segment:this.segmentModel,
            style:this.styleModel,
            size:this.sizeModel,
            selectedField:"",selectedSearchText:""
         
      };
        this.searchService.searchAll(this.page,this.pageSize,searchRequest)
        .then(result=> {
            this.indexDetails = result["indexAttributes"];
            this.totalEntries=result["totalResults"];
            this.setPage(this.page)
        })
        
    }

    search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() =>
       this.searching = true),
      switchMap(term =>
        this.searchService.searchAndGetDistinctValues(this.getSearchRequest(term)).pipe(
          tap(() => {
                  this.searchFailed = false;
              }),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }),)),
      tap(() => 
      {
          this.searching = false;
    }),
      merge(()=>
      {
          this.hideSearchingWhenUnsubscribed;
      }
     ),);

     getSearchRequest(term: any)
     {
            var searchRequest: SearchRequest = {
            indexMarker: this.model == "IndexMarker" ? "" :this.indexMarkerModel,
            indexName: this.model == "IndexName" ? "": this.indexNameModel,
            product: this.model == "ProducCodet" ? "" :this.productModel,
            productFamily: this.model == "ProductFamily" ? "": this.productFamilyModel,
            region: this.model == "Region" ? "" : this.geographyModel,
            segment:this.model=="Segment"?"":this.segmentModel,
            style:this.model=="Style"?"":this.styleModel,
            size:this.model=="Size"?"":this.sizeModel,
            selectedField:this.model,
            selectedSearchText:term
         };
         var event: any = {eventName: "adv. Distinct Search", inputParameters: [
            {Name: "indexMarker", Value: searchRequest.indexMarker },
            {Name: "indexName", Value: searchRequest.indexMarker },
            {Name: "product", Value: searchRequest.indexMarker },
            {Name: "productFamily", Value: searchRequest.indexMarker },
            {Name: "selectedField", Value: this.model },
            {Name: "selectedSearchText", Value: term }
        ]};
        this.biService.addEvent(event);
        this.setPage(this.page);
        return searchRequest;
     }

     performSearch()
     {
        const that = this;
        if(this.indexMarkerModel != "" || this.indexNameModel !=""  || this.productModel !=""  || this.productFamilyModel !=""  || this.geographyModel !="")
        {
        var searchRequest: SearchRequest = {
            indexMarker: this.indexMarkerModel,
            indexName: this.indexNameModel,
            product: this.productModel,
            productFamily: this.productFamilyModel,
            region: this.geographyModel,
            segment:this.segmentModel,
            style:this.styleModel,
            size:this.sizeModel,
            selectedField:"",selectedSearchText:""
           
         };

        var event: any = {eventName: "adv.Search", inputParameters: [
            {Name: "indexMarker", Value: this.indexNameModel },
            {Name: "indexName", Value: this.indexNameModel },
            {Name: "productCode", Value: this.productModel },
            {Name: "productFamily", Value: this.productFamilyModel },
            {Name: "region", Value: this.geographyModel }
        ]};
        this.biService.addEvent(event);
        this.searchService.searchAll(this.page,this.pageSize,searchRequest)
        .then(result=> {
            this.indexDetails = result["indexAttributes"];
            this.totalEntries=result["totalResults"];
            this.setPage(this.page)
        });
    }
    }
    onindexMarkerFilterKey(event:any){
        this.page = 1;
       var searchRequest: SearchRequest = {
        indexMarker: this.indexMarkerModel,
        indexName: this.indexNameModel,
        product: this.productModel,
        productFamily: this.productFamilyModel,
        region: this.geographyModel,
        segment:this.segmentModel,
        style:this.styleModel,
        size:this.sizeModel,
        selectedField:"",selectedSearchText:""
        
     };
     this.page = 1;
     this.searchService.searchAll(this.page,this.pageSize,searchRequest).then(data=>{
          this.indexDetails = data["indexAttributes"];
           this.totalEntries = data["totalResults"];
           this.setPage(this.page);
       });
    
    }
    onindexNameFilterKey(event:any){
        this.page = 1;
        var searchRequest: SearchRequest = {
            indexMarker: this.indexMarkerModel,
            indexName: this.indexNameModel,
            product: this.productModel,
            productFamily: this.productFamilyModel,
            region: this.geographyModel,
            segment:this.segmentModel,
            style:this.styleModel,
            size:this.sizeModel,
            selectedField:"",selectedSearchText:""
         
      };
      this.page = 1;
      this.searchService.searchAll(this.page,this.pageSize,searchRequest).then(data=>{
           this.indexDetails = data["indexAttributes"];
            this.totalEntries = data["totalResults"];
            this.setPage(this.page);
        });
     
     }
     onproductCodeFilterKey(event:any){
        this.page = 1;
        var searchRequest: SearchRequest = {
            indexMarker: this.indexMarkerModel,
            indexName: this.indexNameModel,
            product: this.productModel,
            productFamily: this.productFamilyModel,
            region: this.geographyModel,
            segment:this.segmentModel,
            style:this.styleModel,
            size:this.sizeModel,
            selectedField:"",selectedSearchText:""
         
      };
      this.page = 1;
      this.searchService.searchAll(this.page,this.pageSize,searchRequest).then(data=>{
           this.indexDetails = data["indexAttributes"];
          
           this.totalEntries = data["totalResults"];
           this.setPage(this.page);
        });
     
     }
     onproductFamilyFilterKey(event:any){
        this.page = 1;
        var searchRequest: SearchRequest = {
            indexMarker: this.indexMarkerModel,
            indexName: this.indexNameModel,
            product: this.productModel,
            productFamily: this.productFamilyModel,
            region: this.geographyModel,
            segment:this.segmentModel,
            style:this.styleModel,
            size:this.sizeModel,
            selectedField:"",selectedSearchText:""
         
      };
      this.page = 1;
      this.searchService.searchAll(this.page,this.pageSize,searchRequest).then(data=>{
           this.indexDetails = data["indexAttributes"];
          
           this.totalEntries = data["totalResults"];
           this.setPage(this.page);
        });
     
     }
     onregionFilterKey(event:any){
        this.page = 1;
        var searchRequest: SearchRequest = {
            indexMarker: this.indexMarkerModel,
            indexName: this.indexNameModel,
            product: this.productModel,
            productFamily: this.productFamilyModel,
            region: this.geographyModel,
            segment:this.segmentModel,
            style:this.styleModel,
            size:this.sizeModel,
            selectedField:"",selectedSearchText:""
         
      };
      this.page = 1;
      this.searchService.searchAll(this.page,this.pageSize,searchRequest).then(data=>{
           this.indexDetails = data["indexAttributes"];
          
           this.totalEntries = data["totalResults"];
           this.setPage(this.page);
        });
     
     }
     onsegmentFilterKey(event:any){
        this.page = 1;
        var searchRequest: SearchRequest = {
            indexMarker: this.indexMarkerModel,
            indexName: this.indexNameModel,
            product: this.productModel,
            productFamily: this.productFamilyModel,
            region: this.geographyModel,
            segment:this.segmentModel,
            style:this.styleModel,
            size:this.sizeModel,
            selectedField:"",selectedSearchText:""
         
      };
      this.page = 1;
      this.searchService.searchAll(this.page,this.pageSize,searchRequest).then(data=>{
           this.indexDetails = data["indexAttributes"];
          
           this.totalEntries = data["totalResults"];
           this.setPage(this.page);
        });
     
     }
     onstyleFilterKey(event:any){
        this.page = 1;
        var searchRequest: SearchRequest = {
            indexMarker: this.indexMarkerModel,
            indexName: this.indexNameModel,
            product: this.productModel,
            productFamily: this.productFamilyModel,
            region: this.geographyModel,
            segment:this.segmentModel,
            style:this.styleModel,
            size:this.sizeModel,
            selectedField:"",selectedSearchText:""
         
      };
      this.page = 1;
      this.searchService.searchAll(this.page,this.pageSize,searchRequest).then(data=>{
           this.indexDetails = data["indexAttributes"];
          
           this.totalEntries = data["totalResults"];
           this.setPage(this.page);
        });
     
     }
     onsizeFilterKey(event:any){
        this.page = 1;
        var searchRequest: SearchRequest = {
            indexMarker: this.indexMarkerModel,
            indexName: this.indexNameModel,
            product: this.productModel,
            productFamily: this.productFamilyModel,
            region: this.geographyModel,
            segment:this.segmentModel,
            style:this.styleModel,
            size:this.sizeModel,
            selectedField:"",selectedSearchText:""
         
      };
      this.page = 1;
      this.searchService.searchAll(this.page,this.pageSize,searchRequest).then(data=>{
           this.indexDetails = data["indexAttributes"];
          
           this.totalEntries = data["totalResults"];
           this.setPage(this.page);
        });
     
     }
     setPage(page: number) {    
        this.pager = this.pagerService.getPager(this.totalEntries, page);
        
      }
      fetchPage(page: number){
        this.page = page;
       
        var searchRequest: SearchRequest = {
            indexMarker: this.indexMarkerModel,
            indexName: this.indexNameModel,
            product: this.productModel,
            productFamily: this.productFamilyModel,
            region: this.geographyModel,
            segment:this.segmentModel,
            style:this.styleModel,
            size:this.sizeModel,
            selectedField:"",selectedSearchText:""
         
      };
      this.searchService.searchAll(this.page,this.pageSize,searchRequest).then(data=>{
        this.indexDetails = data["indexAttributes"];
        this.filesPaged = this.indexDetails;
        this.totalEntries = data["totalResults"];
        this.setPage(this.page);
     });
  
       
      }
    setCurrentSearch(model: any){
        this.model = model;
     }
    
     selectIndex(indexMarker:any, indexname :any ,selected:any)
     {
         selected == true ? this.addIndex(indexMarker,indexname) : this.deleteIndex(indexMarker)
     }

     addIndex(selectedIndex: any,indexname : any)
     {         
        if(this.chosenIndexes.length != 6){
            if(!this.chosenIndexes.includes(selectedIndex))
            {
                this.chosenIndexes.push(selectedIndex);
                this.indexmarker.push({selectedIndex,indexname});
            }
        }
     }

     deleteIndex(indexMarker:any)
     {
        this.chosen = this.indexMarkerService.getIndexMarkers();
        if(this.chosenIndexes.length >1 && !this.chosen.includes(indexMarker) ){
        var index = this.chosenIndexes.findIndex(index =>index == indexMarker);
        if(index != -1)
        {
        this.chosenIndexes.splice(index, 1);
        this.indexmarker.splice(this.indexmarker.findIndex(i => i == indexMarker),1);     
     }
    }
    }
     onSelect(): void {
        var event: any = {eventName: "Add/Delete Search Tags from adv. Search"};
           this.biService.addEvent(event);
           this.indexMarkerService.publish(this.chosenIndexes);
           for(var i in this.indexmarker)
           {
               if( this.indexmarker[i].indexname=="")
               {
                this.indexmarker[i].indexname="null";
               }
            this.indexArray.push({index:this.indexmarker[i].selectedIndex,indexName:this.indexmarker[i].indexname});
           }
            this.indexMarkerService.publishIndexNames(this.indexArray);
            this.indexmarker=[];
        }

     clearSearch()
     {
        this.model = "";
        this.indexMarkerModel= "";
        this.indexNameModel= "";
        this.productModel= "";
        this.geographyModel= "";
        this.productFamilyModel= "";
        this.indexDetails = [];
        
        [].push.apply(this.indexDetails,this.defaultIndexDetails);
     }
     selectItem(){}
    applyStyle(attribute: any)
    { 
       var index = this.attributes.findIndex(x => x.name == attribute);  
       if(this.attributes[index].style == ""){
        this.attributes[index].style = "attributeStyle attributeStyle-close";
        this.attributes[index].color = ""
        }
        else
        {
        this.attributes[index].style = "";
        this.attributes[index].color = this.attributes[index].name;
        }
    }
 }
