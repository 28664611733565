import {Component,Input,Output,EventEmitter,ViewChild} from '@angular/core';
import { ColumnChooserService } from '../../services/column-chooser.service';
import {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'custom-column-chooser',
    templateUrl: './custom-column-chooser.component.html'
})
export class CustomColumnChooserComponent{

    customColumns:any[];
    removable : true;
    check:string;
    @Input() dataKey:string;
    @Input() startDate:any;
    @Input() endDate:any;
    @Input() indexMarkers: any[];
    @Output() columnSelection: EventEmitter<any[]> = new EventEmitter();
    @ViewChild(NgbDropdown,{static: false})
    private dropdown: NgbDropdown;
    constructor(private columnChooserService: ColumnChooserService){
        
            }

            Init(event){
               this.customColumns = [];
               this.columnChooserService.getColumnsDescription(this.dataKey,this.startDate,this.endDate,this.indexMarkers).then(columns=>{
                    this.customColumns = columns;
                     this.bindTargetColumns(this.customColumns);
                   });
            }
            bindTargetColumns(targetColumns:any[]){
                this.customColumns = targetColumns;
            }
            SelectColumns(){
                this.columnSelection.emit(this.customColumns);
                this.columnChooserService.updateColumnsDescription(this.dataKey,this.customColumns,this.startDate,this.endDate);
                 this.dropdown.close();
            }
            onClose(){
                this.dropdown.close();
            }
            CheckAll(){
               
                if(this.customColumns[0].columnVisibility==true)
                {
                    this.check="true";
                        this.customColumns.forEach(element => {
                     element.columnVisibility=true;
                      });
                }
                else 
                {
                    var checklength=1;
                    this.customColumns.forEach(element => {
                        if(element.columnVisibility==true)  {checklength=checklength+1;}            
                         });
                   if(checklength==this.customColumns.length) { this.check="true";}
                    if(this.check=="true")
                    {
                        this.columnChooserService.getDefaultColumnsDescription(this.dataKey,this.endDate,this.indexMarkers).then(columns=>{
                            this.customColumns = columns;
                            this.customColumns[0].columnVisibility=false;
                             this.bindTargetColumns(this.customColumns);
                           });
                        this.check="false";
                    }
                  
                }
                
               
            }
}