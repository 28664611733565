import { Component, HostListener,ViewChild } from '@angular/core';
import { AuthenticateService } from './services/authenticate.service';
import {DateService} from './services/date.service';
import {BiService} from './services/BiService.service';
import {BiHeartBeatServiceService} from './services/bi-heart-beat-service.service';
import {TopNavComponent} from './components/top-nav/top-nav.component';
import { stringify } from 'querystring';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent{
  @ViewChild(TopNavComponent,{static: false}) topNavComponent;
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if(this.biservice.arePendingEvents())
    {
      var pendingEvents = this.biservice.getEvents();
      localStorage.setItem('PendingsEvents',JSON.stringify(pendingEvents));
    }
  }
  @HostListener('window:unload',['$event'])
  unloadHandler($event:any){
    
    if(this.biservice.arePendingEvents()&& null == localStorage.getItem('PendingsEvents'))
    {
      var pendingEvents = this.biservice.getEvents();
      localStorage.setItem('PendingsEvents',JSON.stringify(pendingEvents));
    }
  }
  environment: string;
  dateFormat: string;
  didFail = false;
  isLoading = true;
  isDateAvailable = false;
  userId: string;
  isCognito = false;
  isloggedIn = false;
  logoutParam:string = "?Logout=true";
  
  constructor(private dateService:DateService, private authService: AuthenticateService,private biservice: BiService, private biHeartBeatServiceService:BiHeartBeatServiceService) {
    if(environment.environmentName =="dev"  ) {
      if(sessionStorage.getItem('CognitoAuthenticated') != null && sessionStorage.getItem('LoginDetails') != null ) {
        this.isloggedIn = true;
        var loginObject = sessionStorage.getItem('LoginDetails');
        var loginDetails =  JSON.parse(loginObject);
        this.userId =  loginDetails["userId"];  
        this.authService.refreshSignIn(loginDetails["userId"]);            
        }
        var apiToken = null;
        if(sessionStorage.getItem('apiToken') != null && sessionStorage.getItem('apiToken') != "null")
        {
          apiToken = sessionStorage.getItem('apiToken');
        }
        if(apiToken == null)
        {
          this.authService.cognitoSignIn(environment.cognitoUserId,environment.cognitoUserPassword);
          this.isloggedIn = true;
          this.isLoading = false;
          this.userId =  this.authService.userId.value;  
        } 
   }
    else {   
      this.authService.adfsSignIn(); 
       
    if(window.location.href.indexOf(this.logoutParam) > -1){
      sessionStorage.removeItem('LoginDetails');
      localStorage.removeItem('ADFSAuthenticated');
      localStorage.removeItem("samlResponse");
      localStorage.removeItem('cognitoid');
      localStorage.removeItem('isADFSAuthenticated');
      localStorage.removeItem('activelogin');
      sessionStorage.removeItem('RestMessage');
      sessionStorage.removeItem('LoginDetails');
      localStorage.removeItem('samlResponse');
      window.location.href = window.location.href.substr(0,window.location.href.indexOf(this.logoutParam));
    }
    this.userId ="Not Signed in";        
    
     
    if(sessionStorage.getItem('CognitoAuthenticated') != null && sessionStorage.getItem('LoginDetails') != null ) {
      this.isloggedIn = true;
      var loginObject = sessionStorage.getItem('LoginDetails');
      var loginDetails =  JSON.parse(loginObject);
      this.userId =  loginDetails["userId"];  
      this.authService.refreshSignIn(loginDetails["userId"]);            
    }

    if(localStorage.getItem('ADFSAuthenticated') != null) {     
      var samlResponse = null;
      //var expiredSamlResponseJsonString = localStorage.getItem("samlResponse");
      var expiredSamlResponseJsonString = null;
      console.log("expiredSamlResponseJsonString: "+expiredSamlResponseJsonString);
      var expiredSamlResponseJson = (null!= expiredSamlResponseJsonString && undefined!= expiredSamlResponseJsonString)?
      JSON.parse(expiredSamlResponseJsonString):null;
      console.log("app component deserialized");
      var currentTime = new Date().getTime();
      console.log("currentTime: "+currentTime);
      console.log("expiredSamlResponseJson: "+expiredSamlResponseJson);
      if(expiredSamlResponseJson!=null && expiredSamlResponseJson!=undefined &&  expiredSamlResponseJson["samlResponse"] != null
        && expiredSamlResponseJson["samlResponse"]!= undefined && expiredSamlResponseJson["samlResponse"] != "null"
    && currentTime<expiredSamlResponseJson["expiry"])
    {
      samlResponse =  expiredSamlResponseJson["samlResponse"];
    }
      else {
        samlResponse = this.authService.getParameterByName('SAMLResponse','');
        var expiredSamlResponse = {
          samlResponse: samlResponse,
          expiry: new Date().getTime() + 300000
        };       
        //localStorage.setItem("samlResponse",JSON.stringify(expiredSamlResponse));
      }
      
      if(samlResponse != null )
      {
        this.authService.adfsSignIn();
        this.isloggedIn = true;
        this.userId =  this.authService.userId.value;  
      }
      else {
        localStorage.removeItem('ADFSAuthenticated');
        localStorage.removeItem('samlResponse');        
      }
     
    }
  }   

      
  }
  ngOnInit(){
    
      this.authService.authIsLoading.subscribe(
        (isLoading: boolean) =>this.isLoading = isLoading    
      );
      this.authService.authDidFail.subscribe(
        (didFail: boolean) =>this.didFail = didFail      
      );    
      this.authService.userId.subscribe(
        (value: string) =>this.userId = value    
      );  
    this.dateService.getCalendarDate() == null ? this.isDateAvailable : this.isDateAvailable= true;
  }

  logoutCallback(event):void {
    this.isloggedIn = false;
    this.isLoading = true;    
  }
  removeSelection(event):void {
    this.topNavComponent.removeAllSelection();
  }


  closeAllPopup(): void { }

  onNotifyDate(value:any){           
    this.dateService.setCalendarDate(value);
    this.isDateAvailable= true;
  }

  onNotifyTimePeriod(value:any){ 
   this.dateService.setTimePeriod(value);    
  }

  onNotifyCollapse(value:any){
    this.topNavComponent.setCollapseMenu();
  }
}
