
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthenticateService } from './authenticate.service';
import { BiService } from './BiService.service';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpEventType,
  HttpErrorResponse
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import {UINotificationService} from './ui-notification.service';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class CoreHttpInterceptor implements HttpInterceptor {
  passphrase: string = "EncryptionatRest";
  constructor( private authService:AuthenticateService, 
    private biservice: BiService,private uiNotificationService:UINotificationService) {
  } 
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var signedHeaders = this.authService.getSignedHeaders(request.url, request.method, request.body);
    if(request.url.includes('/ui/'))
    {
      var events = this.biservice.getEvents();
      if(events != null)
      {
        signedHeaders['IDWUIEvents'] = JSON.stringify(events);
        this.biservice.clearEvents();
      }
   }
   request = request.clone(           
     { setHeaders:  signedHeaders}
    );
    
    return next.handle(request).pipe(tap(
      (event) =>{
        if (event instanceof HttpResponse) {
          if(null != event.body && null!= event.body.uiNotification && undefined!= event.body.uiNotification){
            this.uiNotificationService.publish(event.body.uiNotification);
            console.log(event.body.uiNotification);
            console.log(event)
          }
          return event;
      }
      },(err) =>{
        console.log("Error Status: "+err.status);
        console.log(err);
        if(err.status === 0){
          //possibly cors related.
          console.log("unknown error");
        }
        else if (err.status === 403){
          var credentials  =  JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('RestMessage'),this.passphrase).toString(CryptoJS.enc.Utf8));   
          // var cognitoLoginUser = sessionStorage.getItem('LoggedInUser');
          // var cognitoPWD = sessionStorage.getItem('PWD');
          var isADFSAuthenticated = localStorage.getItem('ADFSAuthenticated');
          console.log("Core Http ADFSAuthenticated:" +isADFSAuthenticated);
          var currentPage = window.location.href ;   
          if(isADFSAuthenticated == 'true'){
            this.cleanADFS();
            var  samlResponse = this.authService.getParameterByName('SAMLResponse','');
            if(samlResponse != null){
              this.authService.getSamlCredentials(samlResponse);
            }else{
               localStorage.removeItem('samlResponse');
               this.authService.adfsSignIn();
            }
            
          }else{
           // this.authService.cognitoSignIn(cognitoLoginUser,cognitoPWD,this.urlReload,currentPage);
           this.authService.adfsSignIn();
          }
        }
        return observableThrowError(err);
      }));
  }
  cleanADFS(){
    localStorage.removeItem('cognitoid');
    localStorage.removeItem('isADFSAuthenticated');
    localStorage.removeItem('activelogin');
    sessionStorage.removeItem('RestMessage');
    sessionStorage.removeItem('LoginDetails');
    localStorage.removeItem('samlResponse');
  }

  urlReload(currentPage){
    window.location.assign(currentPage);
  }
}