import { Injectable }    from '@angular/core';

import * as FusionCharts from 'fusioncharts';
import { saveAs } from 'file-saver/FileSaver';
import html2canvas from 'html2canvas';
import canvg from 'canvg';
import * as moment from 'moment';
import { window } from 'rxjs/operators';
import { Buffer } from 'buffer';
@Injectable()
export class IDWFusionChartsPngExportService {

    fusionsChartsDraw(inputData:any){
        this.exportToPNG(inputData);
      }

      
      private exportToPNG(inputData:any){
         var chartReference = inputData["chartReference"]; 
         var copy = chartReference.clone({
             chartid: inputData["chartid"],
             type: inputData["type"],
             dataSource: inputData["dataSource"]
         });
         if(inputData["legend"] == true){
             copy.dataSource.chart["showLegend"] = "1";
         }
         copy.render(inputData["targetDiv"],undefined,function(){            
            var svgString = copy.getSVGString();
            var canvas = document.createElement('canvas');              
            canvg(canvas,svgString);
            var imageData = canvas.toDataURL("image/png");
            var newImage = new Image();
            newImage.src = imageData;
            var customizedDiv = document.createElement('div');
            if(inputData["chartTitle"]!= null && inputData["chartTitle"]!= undefined){
               var titleParagraph = document.createElement('h3');
               titleParagraph.style.fontFamily="Helvetica,Arial,sans-serif";
               titleParagraph.innerHTML = inputData["chartTitle"];
               var titleSpace = document.createElement('hr');
               customizedDiv.appendChild(titleParagraph);
               customizedDiv.appendChild(titleSpace);
            }
            if(inputData["chartDate"]!= null && inputData["chartDate"] != undefined){
                var dateParagraph = document.createElement('h5');
                dateParagraph.style.fontFamily="Helvetica,Arial,sans-serif";
                dateParagraph.innerHTML = moment(inputData['chartDate']).format("MM/DD/YYYY");
                var dateSpace = document.createElement('hr');
                customizedDiv.appendChild(dateParagraph);
                customizedDiv.appendChild(dateSpace);
            }
            
            customizedDiv.appendChild(newImage);            
            if(inputData["isIndexLegend"] == true){
                for(var counter =0; counter < inputData["indexLegendNames"].length;counter++){
                    var legendDiv = document.createElement('div');
                    legendDiv.style.fontFamily="Helvetica,Arial,sans-serif";
                    legendDiv.className = inputData["indexLegendClassNames"][0];
                    var legendColor = document.createElement('div');
                    legendColor.className = inputData["indexLegendClassNames"][1];
                    legendColor.style.backgroundColor = inputData["indexLegendColors"][counter];
                    var legendLabel = document.createElement('span');
                    legendLabel.className = inputData["indexLegendClassNames"][2];
                    legendLabel.innerHTML = inputData["indexLegendNames"][counter].indexName;
                    legendColor.appendChild(legendLabel);
                    legendDiv.appendChild(legendColor);
                    customizedDiv.appendChild(legendDiv);
                }
            }
            var drawingDiv = <HTMLDivElement> document.getElementById(inputData["targetDiv"]);
            drawingDiv.innerHTML = "";
            if(inputData["isIndexLegend"] !=null && inputData["isIndexLegend"] != undefined){
              customizedDiv.style.height = "600px";
            }
            var imageUrl = canvas.toDataURL("image/png");
                        var base64 = imageUrl.split("base64,")[1];
                        var bufferArr = Buffer.from(base64,'base64');
                        var blob = new Blob([bufferArr], { type: 'image/png' });
                        saveAs(blob,inputData["pngFileName"]);
            // document.body.appendChild(customizedDiv);
            // html2canvas(customizedDiv).then(canvas => {
            //     document.body.removeChild(customizedDiv);
            //     console.log(navigator.msSaveBlob);
            //         if(navigator.msSaveBlob){
            //             var msBlob = canvas.msToBlob();
            //             console.log(msBlob);
            //                 navigator.msSaveBlob(msBlob, inputData["pngFileName"]);
                        
            //         }else{
            //             console.log("Hiii");
            //             var imageUrl = canvas.toDataURL("image/png");
            //             var base64 = imageUrl.split("base64,")[1];
            //             var bufferArr = Buffer.from(base64,'base64');
            //             var blob = new Blob([bufferArr], { type: 'image/png' });
            //             saveAs(blob,inputData["pngFileName"]);
            //         }

                
                
              
            // }).catch(function(err){
            //     console.log("The error is:"+err);
            //     document.body.removeChild(customizedDiv);
            // });
         }); 
      }
}