import { Component, EventEmitter, Input, OnInit, Inject,Output, ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {SelectionModel} from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { ConnectContactLens } from 'aws-sdk';
import { ExportReportData, Report, ReportData } from '../../models/report';
import { EsgExcludesDataService } from '../../services/esgexcludes.service';
import { DownloadService } from '../../services/download.service';
import {S3ManagerService} from '../../../services/s3-manager.service';
import { saveAs } from 'file-saver/FileSaver';
import { ArrayDataSource } from '@angular/cdk/collections';
import * as CryptoJS from "crypto-js";
import * as AWS from 'aws-sdk';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';
import { EsgExcludes } from '../../models/esgexcludes';
import { environment } from '../../../../environments/environment';

enum ColumnNames {
  indexMarker='IndexMarker',
  excludeIndexMarker = 'ExcludeIndexMarker',
  excludeProductCode = 'ExcludeProductCode',
  excludeIcbSubsectorCode = 'ExcludeIcbSubsectorCode',
  ModifiedUser = 'ModifiedUser',
  ModifiedTime = 'ModifiedTime',
  controls = 'controls'
}

@Component({
  selector: 'esgexcludes-data-grid',
  templateUrl: './esgexcludes-data-grid.component.html',
  styleUrls: ['./esgexcludes-data-grid.component.scss']
})
export class EsgExcludesDataGridComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() edit = new EventEmitter<Report>();
  @Output() indexCodeEvent = new EventEmitter<string>();
  @Output() excludeIndexCodeEvent = new EventEmitter<string>();
  dataSource: any;
  isVisible :boolean = false ;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25];
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 5;
  pageCount: number = 0;
  pager: any = {};
  unsub: any;
  esgexcludes: EsgExcludes[] = [];
  data: MatTableDataSource<any>;
  oldPageLimit: number;
  response: any;
  selectedIndex: any;
  selectedValue: any;
  selectedExcludeIndexValue:any;
  activePageDataChunk: any;
  pageLength: number;
  filterForm: any;
  serchtext: any = '';
  excludeSerchText: any = '';
  isupdate :boolean = false ;
  exportresultArray: ExportReportData[] = [];
  selectedreports: number[] = [];
  passphrase: string = "EncryptionatRest";
  isLoading: boolean = false;
  indexMarkers: string[];
  public indexMarkerfilteredList: string[];
  excludeIndexMarkers: string[];
   public excludeIndexMarkerfilteredList: string[];
  displayColumnNameMap = new Map<ColumnNames, string>([
    [ColumnNames.indexMarker,'Index Marker'],
    [ColumnNames.excludeIndexMarker, 'Exclude Index Marker'],
    [ColumnNames.excludeProductCode, 'Exclude Product Code'],
    [ColumnNames.excludeIcbSubsectorCode, 'Exclude Icb Subsector Code'],  
    [ColumnNames.ModifiedUser, 'Modified User'],
    [ColumnNames.ModifiedTime, 'Modified date']
  ]);
  displayedColumns: ColumnNames[] = [
    ColumnNames.indexMarker,
    ColumnNames.excludeIndexMarker,
    ColumnNames.excludeProductCode,
    ColumnNames.excludeIcbSubsectorCode,
    ColumnNames.ModifiedUser,
    ColumnNames.ModifiedTime,
    ColumnNames.controls
  ];
  skipColumnsAutoRender = new Set<ColumnNames>([
    ColumnNames.controls
  ]);
  constructor(private router: Router, private esgexcludesDataService: EsgExcludesDataService, private downloadService: DownloadService, private fb: FormBuilder, private s3ManagerService:S3ManagerService) {
    this.unsub = this.esgexcludesDataService.clientPublish().subscribe((m: any) => {
      alert(m);
      this.getEsgExcludesData();
   
    });
  }

  ngOnInit(): void {
    this.filterForm = this.fb.group({
     indexMarker:  [""],
     excludeIndexMarker:  [""],
     excludeProductCode:  [""],
     excludeIcbSubsectorCode:  [""],
      ModifiedUser: [""],
      ModifiedTime: [""]
 

    });
    this.filterForm.valueChanges.subscribe(value => {
      console.log(this.dataSource);
    });
    if (sessionStorage.getItem("UserRoles").indexOf(environment.siAdminGroup) != -1) {
            this.isVisible=true;
        }
    //enable update/delete button for testing in dev
      //   this.isVisible=true;
     //end
     this.GetindexList();
     this.GetExcludeindexMarkerListList(null);
    this.getEsgExcludesData();
   
  }


  getColumnsViewName(column: ColumnNames): string {
    return this.displayColumnNameMap.get(column) || '';
  }
  

  trim(text: any): string | any {
    if (typeof text === 'string') {
      return text.length < 28 ? text : text.slice(0, 25).trim() + '...';
    }
    return text;
  }
  GetindexList() {
    this.esgexcludesDataService.GetEsgExcludesIndexList().subscribe((res) => {
      this.indexMarkers = res.data;
      this.indexMarkerfilteredList = res.data;
    });
  }
    GetExcludeindexMarkerListList(indexMarker) {
    this.esgexcludesDataService.GetExcludeindexMarkerListList(indexMarker).subscribe((res) => {
      this.excludeIndexMarkers = res.data;
      this.excludeIndexMarkerfilteredList = res.data;
    });
  }
  getEsgExcludesData()
  {
    if (this.selectedValue != undefined || this.selectedExcludeIndexValue != undefined) {
      this.getEsgExcludesDataByfilter(this.selectedValue,this.selectedExcludeIndexValue);     
    }
    else {
      this.isLoading = true;
      this.esgexcludesDataService.fetchEsgExcludesReports$(this.pageIndex, this.pageSize).subscribe((res) => {
        this.response = res;
        this.dataSource = this.response.Data;

        console.log(this.dataSource);
        this.isLoading = false;
        this.activePageDataChunk = this.dataSource;//.slice(0, this.pageSize);
        for (let item of this.activePageDataChunk) {
          item.ModifiedTime = (item.ModifiedTime).substring(0, 10);
        }
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].TotalCount : 0;

      });
    }
  }
  getEsgExcludesDataByfilter(filter,excludeIndexMarker) {
    this.isLoading = true;
      this.esgexcludesDataService.fetchEsgExcludesData$(this.pageIndex, this.pageSize, filter,excludeIndexMarker).subscribe((res) => {
        this.response = res;
        this.dataSource = this.response.Data;

        console.log("getEsgExcludesData");
        console.log(this.dataSource);
        this.isLoading = false;
        this.activePageDataChunk = this.dataSource;//.slice(0, this.pageSize);
        for (let item of this.activePageDataChunk) {
          item.ModifiedTime = (item.ModifiedTime).substring(0, 10);
        }
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].TotalCount : 0;
      

    });
  }
  onIndexChange(selectedIndex) {
    if (selectedIndex != undefined) {
      this.pageIndex=0;
      this.selectedValue = selectedIndex;
       this.selectedExcludeIndexValue = undefined;
      this.GetExcludeindexMarkerListList(selectedIndex);
      this.getEsgExcludesDataByfilter(selectedIndex,this.selectedExcludeIndexValue);
      this.indexCodeEvent.emit(selectedIndex);
       this.excludeIndexCodeEvent.emit(null);
    }
    else {
      this.selectedValue = undefined;
      this.GetExcludeindexMarkerListList(null);
      this.getEsgExcludesData();
      this.selectedExcludeIndexValue = undefined;
      this.indexCodeEvent.emit(null);
      this.excludeIndexCodeEvent.emit(null);
      this.serchtext = '';
      this.excludeSerchText = '';
      this.indexMarkerfilteredList = this.indexMarkers;
    }

  }
  filterIndexList(val) {
    this.indexMarkerfilteredList = this.indexMarkers.filter((indexCode) => indexCode.includes(val == undefined ? val : val.toUpperCase()));
  }
  filterExcludeIndexList(val) {
    this.excludeIndexMarkerfilteredList = this.excludeIndexMarkers.filter((indexCode) => indexCode.includes(val == undefined ? val : val.toUpperCase()));
  }
  onExcludeIndexChange(selectedIndex) {
    if (selectedIndex != undefined) {
      this.pageIndex=0;
      this.selectedExcludeIndexValue = selectedIndex;
      this.getEsgExcludesDataByfilter(this.selectedValue,selectedIndex);
      this.excludeIndexCodeEvent.emit(selectedIndex);
    }
    else {
      this.selectedExcludeIndexValue = undefined;
      this.getEsgExcludesData();
      this.excludeIndexCodeEvent.emit(null);
      this.excludeSerchText = '';
      this.excludeIndexMarkerfilteredList = this.excludeIndexMarkers;
    }

  }
  openEditMetric(metric: any) {
    this.isupdate = true ;
    this.edit.emit(metric);
  }

 
  onPageChanged(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getEsgExcludesData();
  }
  ngOnDestroy() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
  }
  delete(esgExcludes : any) {
    debugger;
    if (confirm("Are you sure to delete EsgExcludes Data?")) {
      this.esgexcludesDataService.deleteEsgExcludesData(esgExcludes);     
    }
  }
}
