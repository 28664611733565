import {Component, OnDestroy, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-pbr-root',
  templateUrl: './pbr-root.component.html',
  styleUrls: ['./pbr-root.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PbrRootComponent implements OnInit, OnDestroy {

  ngOnInit(): void {
  }

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(window.document.body, 'pbr-testing-open');
  }

  ngOnDestroy() {
    this.renderer.removeClass(window.document.body, 'pbr-testing-open');
  }

}
