import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PublishIndexMarkerService } from '../services/publish-index-marker.service';
import { log } from 'util';
import { indexNamesModel } from '../models/indexNames.model';
import { ResponseModel } from '../models/landing-page-model';
import { Observable } from 'rxjs';
import { iResponseModel } from '../models/response.model';
@Injectable()
export class IndexMarkerService {
  defaultIndexes = ["GEISAC", "ACDXUS", "ACE", "ACEUR", "AWORLDS", "RU3000"];
  defaultProductCodes = ["gliv", "a2iv", "RUSS", "lcav_icb", "RGS"];
  ischeck: any = false;
  defaultIndexeNames = [
    { index: "GEISAC", indexName: "FTSE GLOBAL ALL CAP INDEX", productCode: "gliv" },
    { index: "ACDXUS", indexName: "FTSE DEVELOPED ALL CAP EX US INDEX", productCode: "gliv" },
    { index: "ACE", indexName: "FTSE SECONDARY EMERGING ALL CAP INDEX", productCode: "gliv" },
    { index: "ACEUR", indexName: "FTSE EUROPE ALL CAP INDEX", productCode: "gliv" },
    { index: "AWORLDS", indexName: "FTSE ALL-WORLD INDEX", productCode: "a2iv" },
    { index: "RU3000", indexName: "Russell 3000", productCode: "RUSS" },];
  constructor(private publishIndexMarkerService: PublishIndexMarkerService,
    private httpClient: HttpClient) { }

  publish(indexMarkers: any) {
    this.setIndexMarkers(indexMarkers);
    this.publishIndexMarkerService.publish(indexMarkers);
  }
  getIndexMarkers() {
    if (!JSON.parse(localStorage.getItem('IndexMarkers')))
      this.setIndexMarkers(this.defaultIndexes);
    return JSON.parse(localStorage.getItem('IndexMarkers'));

  }

  setIndexMarkers(indexMarkers: any): void {
    localStorage.setItem('IndexMarkers', JSON.stringify(indexMarkers));
  }

  publishIndexNames(indexMarkersNames: any) {
    this.setIndexMarkersNames(indexMarkersNames);
    this.publishIndexMarkerService.publishIndexNames(indexMarkersNames);
  }
  getIndexMarkersNames() {
    if (!JSON.parse(localStorage.getItem('IndexMarkersNames')))
      this.setIndexMarkersNames(this.defaultIndexeNames);
    return JSON.parse(localStorage.getItem('IndexMarkersNames'));

  }
  setIndexMarkersNames(indexMarkersNames: any): void {
    localStorage.setItem('IndexMarkersNames', JSON.stringify(indexMarkersNames));
  }

  getRussellIndexes(indexMarkers: any): Promise<any[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/getRussellIndexes',
      JSON.stringify({
        IndexMarkers: indexMarkers
      }), { headers: headers })
      .toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as any[];
      })
      .catch(this.handleError);
  }

  getIndexes(search: any, pageIndex: number, pageSize: number) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (search!='')
      search.indexId = search.indexId == '' ? 0 : search.indexId;
    return this.httpClient.post(environment.dataServiceRoot + 'ui/Index/Get',
      JSON.stringify({
        indexId: isNaN(search.indexId) ? 0 : search.indexId,
        indexMarker: search.indexMarker,
        indexName: search.indexName,
        indexMarkerReportingName: search.indexReportingName,
        source: search.source,
        indexFamily: search.indexFamily,
        pageIndex: pageIndex,
        pageSize: pageSize
      }), { headers: headers })
      .toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as any;
      })
      .catch(this.handleError);
  }

  updateIndex(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/Index/Update',
      JSON.stringify({
        indexId: data.indexId,
        indexMarker: data.indexMarker,
        indexName: data.indexName,
        indexMarkerReportingName: data.indexReportingName,
        source: data.source,
        indexFamily: data.indexFamily,
      }), { headers: headers })
      .toPromise()
      .then(response => {
        return response as iResponseModel;
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}