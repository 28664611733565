import { Component, OnInit,OnChanges, SimpleChanges } from '@angular/core';
import { UserGroupModel } from '../../models/authorization';
import { AuthorizationService } from '../../services/authorization.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-usergroup',
  templateUrl: './usergroup.component.html',
  styleUrls: ['./usergroup.component.css'] 
})
export class UserGroupComponent implements OnInit,OnChanges {
  usergroupData: UserGroupModel
  selectedUserType: any;
  commitStatus:any;
  constructor(private services:AuthorizationService, private route: ActivatedRoute) 
  { 
  }
  ngOnInit() {
  //this.usergroupData.userType = ["Select","ADFS","Cognito"];
      this.initilize()
      var sub = this.route.params.subscribe(params => {
        this.usergroupData.userType = params['userType'];
        this.selectedUserType =params['userType'];
        this.usergroupData.userGroupName = params['userGroupName'];
        this.usergroupData.id = params['id'];
        if(params['id'] == undefined) {
          this.usergroupData.userType = "Select";
          this.selectedUserType = "Select";
      
        }
     
      }); 

}
initilize()
{
  this.selectedUserType = "Select"
  this.usergroupData ={id:0,userGroupName:"",userType:this.selectedUserType};
   this.commitStatus ="";
}
ngOnChanges(changes: SimpleChanges){}
onUserTypeChange()
{
  this.usergroupData.userType = this.selectedUserType;
 //this.usergroupData.id = 0;
}
saveChanges()
{
  if(this.usergroupData.userGroupName == undefined || this.usergroupData.userGroupName =='')
  {
  this.commitStatus = "Please enter the User Group Name";
  return;
  }
  else if(this.usergroupData.userType == '')
  {
    this.commitStatus = "Please select the User Type";
    return;
  }
  else
  { 
    if(this.usergroupData.id== undefined)
    {
      this.usergroupData.id =0;
    }
  this.services.saveUserGroup(this.usergroupData).then(result => {
    console.log("Save User Group data:" + result);
    if(result == "1")
    {
      //this.commitStatus = "Saved successfully"
      alert("User Group: " + this.usergroupData.userGroupName +" "+'Saved successfully');
      window.location.href = "/usergroup-search"
     
    } 
    else
    {
      this.commitStatus = "Error while saving"
    }
 });
}
}
}


 