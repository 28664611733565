import { Injectable } from '@angular/core';
import { NewReportForm, Report, ReportData, ExportReportDetails, ExportReportDetailsColumns, ExportReportData } from '../models/report';
import { BehaviorSubject, Observable, of, from, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewgsxconfigurationsForm } from '../models/gsxconfigurations';
import { ResponseModel } from '../../models/landing-page-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { debug } from 'console';


@Injectable({
  providedIn: 'root'
})
export class gsxconfigurationsDataService {

  public isFormValid = true;
  constructor(private httpClient: HttpClient) { }

  private _listners = new Subject<any>();
  clientPublish(): Observable<any> {
    return this._listners.asObservable();
  }


  publishClient(client: string) {
    this._listners.next(client);
  }

  fetchReports$(pageIndex, pageSize): Observable<any> {
    return from(this.GetgsxconfigurationsData(pageIndex, pageSize));
  }
  fetchgsxconfigurationsData$(pageIndex, pageSize,filter): Observable<any> {
    return from(this.GetgsxconfigurationsDataByFilter(pageIndex, pageSize,filter));

  }
  
  creatgsxconfigurations(gsxconfigurationsForm: NewgsxconfigurationsForm): Observable<string> {
    this.saveNewgsxconfigurations(gsxconfigurationsForm).then((a) => {
      if (a.data != 0) {
        //this.publishClient("created gsxconfigurations data successfully!");
      }
      else {
        //this.publishClient(a.meta.message);
      }
    });
    return of(gsxconfigurationsForm.indexCode);

  }

  updategsxconfigurationsIndex(gsxconfigurationsForm: NewgsxconfigurationsForm): Observable<string> {
    this.savegsxconfigurations(gsxconfigurationsForm).then((a) => {
      if (a.data != 0) {
        // this.publishClient("Updated gsxconfigurations data successfully!");
      }
      else {
        // this.publishClient(a.data.message);
      }
    });
    return of(gsxconfigurationsForm.indexCode);
  }
  getCurrencies(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/GetCurrenciesList', null,
      { headers: headers });
  }
  validategsxconfigurations(indexCode: string): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/ValidateGSXConfigurations', JSON.stringify({ indexCode: indexCode }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data
      }).catch(this.handleErr);
  }
 
  saveNewgsxconfigurations(gsxconfigurationsForm: NewgsxconfigurationsForm): any {
    if (gsxconfigurationsForm.parentIndexCode != undefined ? gsxconfigurationsForm.indexCode.toLowerCase() != gsxconfigurationsForm.parentIndexCode.toLowerCase() : true) {
      this.validategsxconfigurations(gsxconfigurationsForm.indexCode).then((res) => {
        if (res) {
          alert('GSXConfigurations data already exists!');
        }
        else {   
          this.isFormValid = true;
          let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          return this.httpClient.post(
            environment.dataServiceRoot + 'ui/SITableConfigurations/AddGSXConfigurations',
            JSON.stringify({
              IndexCode: gsxconfigurationsForm.indexCode,
              ParentIndexCode: gsxconfigurationsForm.parentIndexCode,
              Currency: gsxconfigurationsForm.Currency,
              Variant: gsxconfigurationsForm.Variant,
              Fromparent: gsxconfigurationsForm.Fromparent,
              Calculatepct: gsxconfigurationsForm.Calculatepct
            }),

            { headers: headers }).toPromise()
            .then(response => {
              var result = response as ResponseModel;
              if (result.data != 0) {
                this.publishClient("GSXConfigurations data created successfully!");
              }
              else {
                this.publishClient(result.meta.message);
              }
              
            }).catch(this.handleErr);
        }
      });
    }
    else {
      alert('Index code and Parent Index code must be different');
    }
  }

  savegsxconfigurations(gsxconfigurationsForm: NewgsxconfigurationsForm): any {

    if (gsxconfigurationsForm.parentIndexCode != undefined ? gsxconfigurationsForm.indexCode.toLowerCase() != gsxconfigurationsForm.parentIndexCode.toLowerCase() : true) {
      this.isFormValid = true;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(
        environment.dataServiceRoot + 'ui/SITableConfigurations/UpdateGSXConfigurations',
        JSON.stringify({
          IndexCode: gsxconfigurationsForm.indexCode,
          ParentIndexCode: gsxconfigurationsForm.parentIndexCode,
          Currency: gsxconfigurationsForm.Currency,
          Variant: gsxconfigurationsForm.Variant,
          Fromparent: gsxconfigurationsForm.Fromparent,
          Calculatepct: gsxconfigurationsForm.Calculatepct        
        }),

        { headers: headers }).toPromise()
        .then(response => {
          var result = response as ResponseModel;
          if (result.data != 0) {
            this.publishClient("Updated GSXConfigurations data successfully!");
          }
          else {
            this.publishClient(result.meta.message);
          }
        }).catch(this.handleErr);
    } else {
      alert('Index code and Parent Index code must be different');
    }
  }

  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }



  GetgsxconfigurationsData(pageIndex, pageSize): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/GSXConfigurationsList', JSON.stringify({ page: pageIndex, pageSize: pageSize }),
      { headers: headers });
  }
   
  GetgsxconfigurationsDataByFilter(pageIndex, pageSize, filter): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/GSXConfigurationsListByFilter', JSON.stringify({ pageSize: pageSize, page: pageIndex, filter: filter }),
      { headers: headers });
  }
  GetindexList(): Observable<any> {
   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/GSXConfigurationsIndexList',null,
     { headers: headers });
  }


  deletegsxconfigurationsData(gsxconfigurationsForm: any): any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DeleteGSXConfigurations',
      JSON.stringify({
        IndexCode: gsxconfigurationsForm.IndexCode
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        this.publishClient("GSXConfigurations data has been deleted");
        return result;
      }).catch(this.handleErr);
  }

  downloadExcel(IndexCode: string): Promise<any> {
    debugger;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DownloadGSXConfigurationsExcel',
      JSON.stringify({
        IndexCode: IndexCode
      }),
      { headers: headers }).toPromise()
      .then(response => {
          var result = response;
          return result;
    })
        .catch (this.handleErr);
  }

  }

