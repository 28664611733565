import { Injectable } from '@angular/core';
import { NewReportForm, Report, ReportData, ExportReportDetails, ExportReportDetailsColumns, ExportReportData } from '../models/report';
import { BehaviorSubject, Observable, of, from, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewEsgExcludesForm } from '../models/esgexcludes';
import { NewEsgExcludesPriorityForm } from '../models/esgexcludes';
import { ResponseModel } from '../../models/landing-page-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { debug } from 'console';


@Injectable({
  providedIn: 'root'
})
export class EsgExcludesDataService {

  public isFormValid = true;
  constructor(private httpClient: HttpClient) { }

  private _listners = new Subject<any>();
  clientPublish(): Observable<any> {
    return this._listners.asObservable();
  }


  publishClient(client: string) {
    this._listners.next(client);
  }

  fetchEsgExcludesReports$(pageIndex, pageSize): Observable<any> {
    return from(this.GetEsgExcludesData(pageIndex, pageSize));
  }
  fetchEsgExcludesData$(pageIndex, pageSize,filter,excludeIndexMarker): Observable<any> {
    return from(this.GetEsgExcludesDataByFilter(pageIndex, pageSize,filter,excludeIndexMarker));

  }
  
  creatEsgExcludes(esgExcludesForm: NewEsgExcludesForm): Observable<string> {
    this.saveNewEsgExcludes(esgExcludesForm).then((a) => {
      if (a.data != 0) {
        //this.publishClient("created esgExcludes data successfully!");
      }
      else {
        //this.publishClient(a.meta.message);
      }
    });
    return of(esgExcludesForm.indexMarker);

  }

  updateEsgExcludes(esgExcludesForm: NewEsgExcludesForm): Observable<string> {
    this.saveEsgExcludes(esgExcludesForm).then((a) => {
      if (a.data != 0) {
        // this.publishClient("Updated esgExcludes data successfully!");
      }
      else {
        // this.publishClient(a.data.message);
      }
    });
    return of(esgExcludesForm.indexMarker);
  }

  validateEsgExcludes(indexMarker: string,excludeIndexMarker: string): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/ValidateEsgExcludes', JSON.stringify({ IndexMarker: indexMarker,ExcludeIndexMarker:excludeIndexMarker }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data
      }).catch(this.handleErr);
  }
 
  saveNewEsgExcludes(esgExcludesForm: NewEsgExcludesForm): any { 
      this.validateEsgExcludes(esgExcludesForm.indexMarker,esgExcludesForm.excludeIndexMarker).then((res) => {
        if (res) {
          alert('Esg Excludes data already exists!');
        }
        else {   
          this.isFormValid = true;
          let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          return this.httpClient.post(
            environment.dataServiceRoot + 'ui/SITableConfigurations/AddEsgExcludes',
            JSON.stringify({
              IndexMarker: esgExcludesForm.indexMarker,
              ExcludeIndexMarker: esgExcludesForm.excludeIndexMarker,
              ExcludeProductCode: esgExcludesForm.excludeProductCode,
              ExcludeIcbSubsectorCode: esgExcludesForm.excludeIcbSubsectorCode                        
            }),

            { headers: headers }).toPromise()
            .then(response => {
              var result = response as ResponseModel;
              if (result.data != 0) {
                this.publishClient("EsgExcludes data created successfully!");
              }
              else {
                this.publishClient(result.meta.message);
              }
              
            }).catch(this.handleErr);
        }
      });
  }

  saveEsgExcludes(esgExcludesForm: NewEsgExcludesForm): any {
      this.isFormValid = true;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(
        environment.dataServiceRoot + 'ui/SITableConfigurations/UpdateEsgExcludes',
        JSON.stringify({
          IndexMarker: esgExcludesForm.indexMarker,
              ExcludeIndexMarker: esgExcludesForm.excludeIndexMarker,
              ExcludeProductCode: esgExcludesForm.excludeProductCode,
              ExcludeIcbSubsectorCode: esgExcludesForm.excludeIcbSubsectorCode           
        }),

        { headers: headers }).toPromise()
        .then(response => {
          var result = response as ResponseModel;
          if (result.data != 0) {
            this.publishClient("Updated EsgExcludes data successfully!");
          }
          else {
            this.publishClient(result.meta.message);
          }
        }).catch(this.handleErr);
  }

  



  GetEsgExcludesData(pageIndex, pageSize): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EsgExcludesList', JSON.stringify({ page: pageIndex, pageSize: pageSize }),
      { headers: headers });
  }

  GetEsgExcludesDataByFilter(pageIndex, pageSize, filter,excludeIndexMarker): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EsgExcludesListByFilter', JSON.stringify({ pageSize: pageSize, page: pageIndex, filter: filter ,excludeindexMarker:excludeIndexMarker}),
      { headers: headers });
  }
  GetEsgExcludesIndexList(): Observable<any> {
   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EsgExcludesIndexList',null,
     { headers: headers });
  }
 GetExcludeindexMarkerListList(filter:any): Observable<any> {
   debugger;
   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/ExcludeindexMarkerList',JSON.stringify({filter:filter}),
     { headers: headers });
  }
  
  deleteEsgExcludesData(esgExcludesForm: any): any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DeleteEsgExcludes',
      JSON.stringify({
        IndexMarker: esgExcludesForm.IndexMarker,
        ExcludeIndexMarker:esgExcludesForm.ExcludeIndexMarker
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        this.publishClient("EsgExcludes data has been deleted");
        return result;
      }).catch(this.handleErr);
  }

  downloadEsgExcludesExcel(indexMarker:string,excludeIndexMarker): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DownloadEsgExcludesExcel',
      JSON.stringify({
      filter: indexMarker,
      excludeIndexMarker:excludeIndexMarker
      }),
      { headers: headers }).toPromise()
      .then(response => {
          var result = response;
          return result;
    })
        .catch (this.handleErr);
  }
  //esgexclude priority

   fetchEsgExcludesPriorityReports$(pageIndex, pageSize): Observable<any> {
    return from(this.GetEsgExcludesPriorityData(pageIndex, pageSize));
  }
  fetchEsgExcludesPriorityData$(pageIndex, pageSize,filter): Observable<any> {
    return from(this.GetEsgExcludesPriorityDataByFilter(pageIndex, pageSize,filter));

  }

   creatEsgExcludesPriority(esgExcludesPriorityForm: NewEsgExcludesPriorityForm): Observable<string> {
    this.saveNewEsgExcludesPriority(esgExcludesPriorityForm).then((a) => {
      if (a.data != 0) {
        //this.publishClient("created esgExcludesPriority data successfully!");
      }
      else {
        //this.publishClient(a.meta.message);
      }
    });
    return of(esgExcludesPriorityForm.indexMarker);

  }

  updateEsgExcludesPriority(esgExcludesPriorityForm: NewEsgExcludesPriorityForm): Observable<string> {
    this.saveEsgExcludesPriority(esgExcludesPriorityForm).then((a) => {
      if (a.data != 0) {
        // this.publishClient("Updated esgExcludesPriority data successfully!");
      }
      else {
        // this.publishClient(a.data.message);
      }
    });
    return of(esgExcludesPriorityForm.indexMarker);
  }

  validateEsgExcludesPriority(indexMarker: string): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/ValidateEsgExcludesPriority', JSON.stringify({ indexMarker: indexMarker }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data
      }).catch(this.handleErr);
  }
 
  saveNewEsgExcludesPriority(esgExcludesPriorityForm: NewEsgExcludesPriorityForm): any { 
      this.validateEsgExcludesPriority(esgExcludesPriorityForm.indexMarker).then((res) => {
        if (res) {
          alert('Esg Excludes Priority data already exists!');
        }
        else {   
          this.isFormValid = true;
          let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          return this.httpClient.post(
            environment.dataServiceRoot + 'ui/SITableConfigurations/AddEsgExcludesPriority',
            JSON.stringify({
              IndexMarker: esgExcludesPriorityForm.indexMarker,
              ExcludeHeader: esgExcludesPriorityForm.excludeHeader,
              Priority: esgExcludesPriorityForm.priority,
              ExcludeCategoryHeader: esgExcludesPriorityForm.excludeCategoryHeader,
              ExcludeCategoryHeaderPriority:esgExcludesPriorityForm.excludeCategoryHeaderPriority                       
            }),

            { headers: headers }).toPromise()
            .then(response => {
              var result = response as ResponseModel;
              if (result.data != 0) {
                this.publishClient("EsgExcludesPriority data created successfully!");
              }
              else {
                this.publishClient(result.meta.message);
              }
              
            }).catch(this.handleErr);
        }
      });
  }

  saveEsgExcludesPriority(esgExcludesPriorityForm: NewEsgExcludesPriorityForm): any {
      this.isFormValid = true;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(
        environment.dataServiceRoot + 'ui/SITableConfigurations/UpdateEsgExcludesPriority',
        JSON.stringify({
          IndexMarker: esgExcludesPriorityForm.indexMarker,
              ExcludeHeader: esgExcludesPriorityForm.excludeHeader,
              Priority: esgExcludesPriorityForm.priority,
              ExcludeCategoryHeader: esgExcludesPriorityForm.excludeCategoryHeader,
              ExcludeCategoryHeaderPriority:esgExcludesPriorityForm.excludeCategoryHeaderPriority
        }),

        { headers: headers }).toPromise()
        .then(response => {
          var result = response as ResponseModel;
          if (result.data != 0) {
            this.publishClient("Updated EsgExcludesPriority data successfully!");
          }
          else {
            this.publishClient(result.meta.message);
          }
        }).catch(this.handleErr);
  }

  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }



  GetEsgExcludesPriorityData(pageIndex, pageSize): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EsgExcludesPriorityList', JSON.stringify({ page: pageIndex, pageSize: pageSize }),
      { headers: headers });
  }

  GetEsgExcludesPriorityDataByFilter(pageIndex, pageSize, filter): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EsgExcludesPriorityListByFilter', JSON.stringify({ pageSize: pageSize, page: pageIndex, filter: filter }),
      { headers: headers });
  }
  GetEsgExcludesPriorityIndexList(): Observable<any> {
   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EsgExcludesPriorityIndexList',null,
     { headers: headers });
  }

  deleteEsgExcludesPriorityData(esgExcludesPriorityForm: any): any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DeleteEsgExcludesPriority',
      JSON.stringify({
        IndexMarker: esgExcludesPriorityForm.IndexMarker
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        this.publishClient("EsgExcludesPriority data has been deleted");
        return result;
      }).catch(this.handleErr);
  }
  
  downloadEsgExcludesPriorityExcel(indexMarker:string): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DownloadEsgExcludesPriorityExcel',
      JSON.stringify({
      IndexMarker: indexMarker
      }),
      { headers: headers }).toPromise()
      .then(response => {
          var result = response;
          return result;
    })
        .catch (this.handleErr);
  }
  }

