import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {LicenceBandModel} from '../../models/LicenceBand-model';
import{LicenceBandsService} from '../../services/licence-bands.service';

@Component({
  selector: 'app-licence-bands',
  templateUrl: './licence-bands.component.html',
  styleUrls: ['./licence-bands.component.css']
})
export class LicenceBandsComponent implements OnInit {
  

  LicenceBandModel:LicenceBandModel;
  list : LicenceBandModel[];
  commitStatus : any;
  constructor( private service1 :LicenceBandsService, private route: ActivatedRoute) { }

  ngOnInit() {
     
    this.LicenceBandModel={
      id:0,
    licenceBand:"",
    sedolRange:"",
    description:"",
    }
    
 
   this.service1.getLicenceInfo().then(result =>{
     this.list = result;
 
     var sub = this.route.params.subscribe(params => {
       
      this.LicenceBandModel.licenceBand = params['band'];
      this.LicenceBandModel.sedolRange = params['count'];
       this.LicenceBandModel.description = params['description'];
       this.LicenceBandModel.id = params['id'];
      
 
     }); 
   })

  }
saveChanges(){
this.service1.LicenceBand(this.LicenceBandModel).then( result =>{
if(result == "1")
{
  
  alert('License Band saved succesfully');
  window.location.href = "/licence-search-bands"
 
}
else
{
  this.commitStatus = "Error while saving"
} 
    });
  }
 

}
