import {Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-as-of-date-picker',
  templateUrl: './as-of-date-picker.component.html',
  styleUrls: ['./as-of-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsOfDatePickerComponent),
      multi: true
    }
  ]
})
export class AsOfDatePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

  fc = new FormControl('');
  @Output() update = new EventEmitter<string>();
  sub = this.fc.valueChanges.pipe(
    map((date: Date) => date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : '')
  ).subscribe((value) => {
    this.onChange(value);
    this.update.emit(value);
  });
  onChange = (val: any) => {};
  onTouched = (val: any) => {};
  constructor() {}

  writeValue(obj: any): void {
    this.fc.setValue(obj, {emitEvent: false});
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    let dte = new Date();
    dte.setDate(dte.getDate() - 1);
    this.fc.patchValue(dte);
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line:no-unused-expression
    this.sub && this.sub.unsubscribe();
  }

}
