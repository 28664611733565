
import {map} from 'rxjs/operators';
import { Injectable }    from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import {ResponseModel} from '../models/landing-page-model';
import {LandingPageModel} from '../models/landing-page-model';
import {IndexReturns} from '../models/performance-index-returns';
import { CloseTopBottomHolding } from '../models/close-holdings-top-bottom';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class LandingService {

    indexCalculatorDataByValuesLoaded = new Subject<IndexReturns>();
    indexCalculatorDataByValuesIsLoading = new BehaviorSubject<boolean>(false);
    indexCalculatorDataByValuesFailed = new Subject<boolean>();

    
    indexCalculatorDataByValuesDataLoaded = new Subject<IndexReturns>();
    indexCalculatorDataByValuesDataIsLoading = new BehaviorSubject<boolean>(false);
    indexCalculatorDataByValuesDataFailed = new Subject<boolean>();
  private headers = new Headers({'Content-Type': 'application/json'});
  
  constructor( private httpClient: HttpClient) { 
  }

  getGrowthofUnit(fromDate: any,toDate:any,indexMarkers : any,currencyCode : any,timePeriod : any,returnType : any) : Promise<LandingPageModel> {
       
       let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
       sessionStorage.setItem('apiURL',environment.swaggerApiUrl);          
      return this.httpClient.post(environment.dataServiceRoot + 'ui/landing/getgrowthofunit',
          JSON.stringify({
              FromDate : fromDate,
              ToDate : toDate,
              IndexMarkers : indexMarkers,              
              CurrencyCode :currencyCode,
              TimePeriod : timePeriod,
              ReturnType : returnType
      }), {headers: headers})
      .toPromise()
      .then(response =>{
        var result = response as ResponseModel;
        return result.data as LandingPageModel;
          })
      .catch(this.handleError);    
  }

  getGrowthofUnitDataToExport(fromDate: any,toDate:any,indexMarkers : any,currencyCode : any,timePeriod : any,returnType : any) : Promise<IndexReturns[]> {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         

   return this.httpClient.post(environment.dataServiceRoot + 'ui/landing/getgrowthofunitdata',
       JSON.stringify({
           FromDate : fromDate,
           ToDate : toDate,
           IndexMarkers : indexMarkers,              
           CurrencyCode :currencyCode,
           TimePeriod : timePeriod,
           ReturnType : returnType
   }), {headers: headers})
   .toPromise()
   .then(response =>{
      var result = response as ResponseModel;
        return result.data as IndexReturns[];
       })
   .catch(this.handleError);    
}
  getspikeChartValues(toDate:any,fromDate:any,indexMarkers : any,currencyCode : any,returnType:any) : Promise<Array<LandingPageModel>> {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
   return this.httpClient.post(environment.dataServiceRoot + 'ui/landing/spikechartvalues',
       JSON.stringify({  
           ToDate:toDate,
           FromDate:fromDate,         
           IndexMarkers : indexMarkers,              
           CurrencyCode :currencyCode,
           ReturnType:returnType
   }), {headers: headers})
   .toPromise()
   .then(response =>{
        var result = response as ResponseModel;
        return result.data as Array<LandingPageModel>
       })
   .catch(this.handleError);    
}

getgetIndexReturns(asOfDate : any,indexMarkers : any,toDate:any,fromDate:any,currencyCode:any,returnType:any)  : Promise<any> {
    
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         

 return this.httpClient.post(environment.dataServiceRoot + 'ui/performance/getindexreturns',
     JSON.stringify({           
         IndexMarkers : indexMarkers,
         AsOfDate : asOfDate,
         ToDate:toDate,
         FromDate:fromDate,
         CurrencyCode:currencyCode,
         ReturnType:returnType
 }), {headers: headers}).toPromise()
 .then(response =>{
    var result = response as ResponseModel;
      return result.data as any;
     })
 .catch(this.handleError); 
}


getIndexReturns(asOfDate : any,indexMarkers : any,toDate:any,fromDate:any,currencyCode:any,returnType:any)  {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
    this.indexCalculatorDataByValuesLoaded.next(null);
    this.indexCalculatorDataByValuesFailed.next(false);
    this.indexCalculatorDataByValuesIsLoading.next(true);
   return this.httpClient.post(environment.dataServiceRoot + 'ui/performance/getindexreturns',
       JSON.stringify({           
           IndexMarkers : indexMarkers,
           AsOfDate : asOfDate,
           ToDate:toDate,
           FromDate:fromDate,
           CurrencyCode:currencyCode,
           ReturnType:returnType
   }), {headers: headers}).pipe(
   map(
    (response: Response) => 
    response as any
  ))
  .subscribe(
    (data) => {      
      var result = data as ResponseModel;
      var indexCalculatorDataByValues = result.data as IndexReturns;
      this.indexCalculatorDataByValuesLoaded.next(indexCalculatorDataByValues);                  
    },
    (error) => {
      console.log(error);
      this.indexCalculatorDataByValuesIsLoading.next(true);
      this.indexCalculatorDataByValuesLoaded.next(null);
    });         
}

getIndexReturnsToExport(indexMarkers : any,asOfDate:any,toDate:any,fromDate:any,
    currencyCode:any,returnType:any,selectedColumns:string[])  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
    this.indexCalculatorDataByValuesDataLoaded.next(null);
    this.indexCalculatorDataByValuesDataFailed.next(false);
    this.indexCalculatorDataByValuesDataIsLoading.next(true);
       return this.httpClient.post(environment.dataServiceRoot + 'ui/performance/getindexreturnstoexport',
           JSON.stringify({           
               IndexMarkers : indexMarkers,
               AsOfDate: asOfDate,               
               ToDate: toDate,
               FromDate:fromDate,
               CurrencyCode:currencyCode,
               ReturnType: returnType,
               SelectedColumns : selectedColumns
       }), {headers: headers}).pipe(
       map(
        (response: Response) => 
        response as any
      ))
      .subscribe(
        (data) => {      
          var result = data as ResponseModel;
          var indexCalculatorDataByValues = result.data as IndexReturns;
          this.indexCalculatorDataByValuesDataLoaded.next(indexCalculatorDataByValues);                  
        },
        (error) => {
          console.log(error);
          this.indexCalculatorDataByValuesDataIsLoading.next(true);
          this.indexCalculatorDataByValuesDataLoaded.next(null);
        });
}

getTopBottomHoldings(asofDates: any,indexMarkers : any ,currencyCode : any) : Promise<CloseTopBottomHolding> {
         
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   return this.httpClient.post(environment.dataServiceRoot + 'ui/landing/GetTopFiveHoldings',
       JSON.stringify({          
           IndexMarkers : indexMarkers,                
           CurrencyCode :currencyCode,
           AsOfDate :asofDates                
   }), {headers: headers})
   .toPromise()
   .then(response =>{
        var result = response as ResponseModel;
        return result.data as CloseTopBottomHolding;
       })
   .catch(this.handleError);    
}

getBusinessDate() : Promise<any> {
         
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   return this.httpClient.post(environment.dataServiceRoot + 'ui/landing/getbusinessdate',null, {headers: headers})
   .toPromise()
   .then(response =>{
        var result = response as ResponseModel;
        return result.data as any;
       })
   .catch(this.handleError);    
}

getTargetBusinessDate(sourceDate:any) : Promise<any> {
    
let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
return this.httpClient.post(environment.dataServiceRoot + 'ui/landing/gettargetbusinessdate',JSON.stringify({          
    AsOfDate:sourceDate               
}), {headers: headers})
.toPromise()
.then(response =>{
    var result = response as ResponseModel;
    return result.data as any;
  })
.catch(this.handleError);    
}


getCurrencies(indexMarkers : any ,endDate: any,dataSourceName:any) : Promise<any[]> {
         
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   return this.httpClient.post(environment.dataServiceRoot + 'ui/landing/getcurrencies',
       JSON.stringify({          
           IndexMarkers : indexMarkers,                          
           AsOfDate :endDate,
           DataSourceName: dataSourceName                  
   }), {headers: headers})
   .toPromise()
   .then(response =>{
        var result = response as ResponseModel;      
        return result.data;

       })
   .catch(this.handleError);    
}

updateQueueStatus(req:any): Promise<number> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/landing/updatequeuestatus',
        JSON.stringify({
            CurrencyCode: req,

        }), { headers: headers })
        .toPromise()
        .then(response => {
            return response;
        })
        .catch(this.handleError);
}

 getCognitoUserGroups(isExternal:any):Promise<any[]>{
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    var userName = JSON.parse(sessionStorage.getItem('LoggedInUser'));
    var userPoolId = environment.cognitoUserPoolId;
    return this.httpClient.post(environment.dataServiceRoot + 'ui/landing/GetCognitoUserGroups',
        JSON.stringify({
            UserPoolId: userPoolId,
            UserId:userName,
            IsExternal:isExternal

        }), { headers: headers })
        .toPromise()
        .then(response => {
            return response;
        })
        .catch(this.handleError);
 }


  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); 
    return Promise.reject(error.message || error);
}

}
