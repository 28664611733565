import { Component, EventEmitter, Input, OnInit, Inject,Output, ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {SelectionModel} from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { ConnectContactLens } from 'aws-sdk';
import { ExportReportData, Report, ReportData } from '../../models/report';
import { ReportsService } from '../../services/reports.service';
import { DownloadService } from '../../services/download.service';
import {S3ManagerService} from '../../../services/s3-manager.service';
import { saveAs } from 'file-saver/FileSaver';
import { ArrayDataSource } from '@angular/cdk/collections';
import * as CryptoJS from "crypto-js";
import * as AWS from 'aws-sdk';
import { formatDate,DatePipe } from '@angular/common';
 import {MdbDropdownDirective} from 'mdb-angular-ui-kit/dropdown';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';

enum ColumnNames {
  name = 'name',
  portfolioName = 'portfolioName',
  benchMark = 'benchMark',
  reportDate = 'reportDate',
  portfolioType= 'portfolioType',
  controls1 = 'controls1',
  controls = 'controls',
  select = 'select',
  template= 'template',

}
@Component({
  selector: 'app-reports-grid',
  templateUrl: './reports-grid.component.html',
  styleUrls: ['./reports-grid.component.scss']
})
export class ReportsGridComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() edit = new EventEmitter<Report>();
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  pageSizeOptions: number[] = [5, 10, 15, 20, 25];
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 5;
  unsub: any;
  data: MatTableDataSource<any>;
  oldPageLimit: number;
  response: any;
  activePageDataChunk: any;
  pageLength: number;
  filterForm: any;
  isupdate :boolean = false ;
  exportresultArray: ExportReportData[] = [];
  selectedreports: number[] = [];
  passphrase: string = "EncryptionatRest";
  isDownloadClick: boolean = false;
  isLoading: boolean = false;

  displayColumnNameMap = new Map<ColumnNames, string>([
    [ColumnNames.name, 'Name'],
    [ColumnNames.portfolioName, 'Portfolio'],
    [ColumnNames.benchMark, 'Benchmark'],
    [ColumnNames.reportDate, 'Date Created'],
    [ColumnNames.portfolioType, 'Portfolio Type'],
    [ColumnNames.template, 'Report Type']
  ]);
  displayedColumns: ColumnNames[] = [
    ColumnNames.select,
    ColumnNames.name,
    ColumnNames.portfolioName,
    ColumnNames.benchMark,
    ColumnNames.reportDate,
    ColumnNames.portfolioType,
    ColumnNames.template,
    ColumnNames.controls
   
  ];
  skipColumnsAutoRender = new Set<ColumnNames>([
    ColumnNames.select,
    ColumnNames.controls,
  ]);
  constructor(private router: Router, private reportsService: ReportsService, private downloadService: DownloadService, private fb: FormBuilder, private s3ManagerService:S3ManagerService) {
    this.unsub = this.reportsService.clientPublish().subscribe((m: any) => {
      this.reportsService.fetchReports$(this.pageIndex, this.pageSize).subscribe(res => {
        this.response = res;
        this.dataSource = this.response.data;
        if (this.dataSource.length % this.pageSize == 1 && (this.pageIndex == (Math.floor(this.dataSource.length / this.pageSize) - 1))) {
          this.pageIndex = this.pageIndex + 1;
        }
        let firstCut = this.pageIndex * this.pageSize;
        let secondCut = firstCut + this.pageSize;
        if(m == "create Report"){
          alert("Report created successfully");
        }
        else if(m == "Edit Report"){
          alert("Report updated successfully");
        }
        else{
          alert(m);
        }        
        this.activePageDataChunk = this.dataSource.slice(firstCut, secondCut);
        for(let item of this.activePageDataChunk){
          item.reportDate = (item.reportDate).substring(0,10)
        }
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length;
      });
    });
  }

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      name: [""],
      portfolioName: [""],
      benchMark: [""],
      reportDate: [""],
      portfolioType : [""],
      template : [""]
    });
    this.filterForm.valueChanges.subscribe(value => {
      console.log(this.dataSource);
      var filter1 = this.dataSource.filter(cp => cp.name.toLowerCase().indexOf(value.name.toLowerCase()) != -1 && cp.portfolioName.toLowerCase().indexOf(value.portfolioName.toLowerCase()) != -1
        && cp.benchMark.toLowerCase().indexOf(value.benchMark.toLowerCase()) != -1 && cp.reportDate.toLowerCase().indexOf(value.reportDate.toLowerCase()) != -1
        && cp.portfolioType.toLowerCase().indexOf(value.portfolioType.toLowerCase()) != -1 
        && cp.template.toLowerCase().indexOf(value.template.toLowerCase()) != -1);
      this.activePageDataChunk = filter1.slice(0, this.pageSize);
      filter1.paginator = this.paginator;
      this.pageLength = filter1.length;
    });
     this.getReports();
  }

  getReports()
  {
    this.isLoading = true;
    this.reportsService.fetchReports$(this.pageIndex, this.pageSize).subscribe((res) => {
      this.response = res;
      this.dataSource = this.response.data;
      this.isLoading = false;
      this.activePageDataChunk = this.dataSource;//.slice(0, this.pageSize);
      for(let item of this.activePageDataChunk){
        item.reportDate = (item.reportDate).substring(0,10)
      }
      this.dataSource.paginator = this.paginator;
      this.pageLength =  this.dataSource.length > 0? this.dataSource[0].totalCount: 0;
    });
  }

  getColumnsViewName(column: ColumnNames): string {
    return this.displayColumnNameMap.get(column) || '';
  }
  openReportEdit(report: any) {
    this.isupdate = true ;
    this.edit.emit(report);
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.activePageDataChunk.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.activePageDataChunk.forEach(row => this.selection.select(row));
  }

  trim(text: any): string | any {
    if (typeof text === 'string') {
      return text.length < 28 ? text : text.slice(0, 25).trim() + '...';
    }
    return text;
  }
  openReport(id:any) {
    this.router.navigate(['/', 'pbr', 'print-report']);
  }

  viewReport(report : any) {
    console.log(report);
    var data;
    this.reportsService.viewReport(report).then((res) => {  
       data = res;
      console.log(data);
      console.log(typeof(data));      
    if(data != true)
    {
      alert("Report is not ready to show !");
      return;
    }
    else{      
      console.log(report);
      if (report.portfolioType=='Portfolio Analysis')
      {      
      this.router.navigate(['/', 'pbr', 'print-report',{data: JSON.stringify(report)}]);
      }
      else if (report.portfolioType=='Benchmark Analysis')
      {
        this.router.navigate(['/', 'pbr', 'Index-print-report',{data: JSON.stringify(report)}]);
       }
    }
    });
    
}  

exportBtnToggle(reportId : any){ 
  const el = <HTMLElement>document.querySelector(`#ddl_${reportId}`);
    if (el.classList.contains('hidden')) 
    {
      el.classList.remove('hidden');
    } else {
      el.classList.add('hidden');
    }
  }
  close(reportId : any){
    const el = <HTMLElement>document.querySelector(`#ddl_${reportId}`);
    if (!el.classList.contains('hidden')) 
    {
      el.classList.add('hidden');
    } 
  }
  
downloadReport(report : any, fileType : any) {
  console.log(report);
  var data;
  this.isDownloadClick = true;
  this.reportsService.viewReport(report).then((res) => {  
     data = res;
    console.log(data);
    console.log(typeof(data));
    if(data != true)
    {
      alert("Report is not ready to show !");
      this.isDownloadClick = false;
      return;
    }
    else{      
      console.log(report);  
      if(fileType == 'xls') {
        alert("downloading is in progress for xlsx format");
        this.reportsService.exportReportData$(report.id, report.portfolioId, report.benchMark, report.asOfDate, report.portfolioType,  report.template).then(data => {
          if (data != undefined && data !== "") {     
            // call download file method
            this.downloadFile(data);
            console.log(data);   // window.location.href = data;
            alert("Export Climate Report download is ready");  
          } else if (data == "") {
            alert("No data found.");
          }
          this.isDownloadClick = false;
      }).catch(err =>{
        console.log(err);
        //alert("Failed to download the file: API timeout error.");
        alert("Failed to download the file. Please try after 1 minute");
        this.isDownloadClick = false;
      }); 
    }
    if(fileType == 'csv'){
      alert("downloading is in progress for csv format");
      this.reportsService.exportReportDataForCSV$(report.portfolioId, report.benchMark, report.asOfDate, report.portfolioType, report.template).then(result => {
        if (result != undefined) {     
          console.log(result);     
           var dataReport = this.reportsService.reportToDetails(result); 
           this.reportsService.ReportConfigMetaData$().then(response => {
            if(response != undefined) {
              console.log(response);     
              this.downloadService.download(dataReport,  'ClimateReport_' + report.portfolioId + '_'  + formatDate( new Date(), 'yyyyMMdd', 'en-US'), 'ClimateReport', report.asOfDate, response, report.portfolioType);
             alert("Export Climate Report download is ready"); 
            }
            });
        } 
        else {
          alert("No data found.");
        }
        this.isDownloadClick = false;
    }).catch(err =>{
      console.log(err);
      alert("Failed to download the file: API timeout error.");
      this.isDownloadClick = false;
    }); 
    }
    return;
    }

    
  });  
 }   
 
downloadFile(s3UrlData):void{
  console.log("FullS3 url: "+ s3UrlData);
  var urlParts = s3UrlData.replace("//", "--").split("/"); 
  var bucketName = urlParts[1];
  var filePath =  urlParts[urlParts.length - 1];
  var fileName = urlParts[urlParts.length - 1];
  
  console.log("BucketName url: "+ bucketName);
  console.log("FilePath url: "+ fileName);
  console.log("Session Message : "+ sessionStorage.getItem('RestMessage')); 
  this.s3ManagerService.getRawData(bucketName,filePath,
    JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('RestMessage'),this.passphrase).toString(CryptoJS.enc.Utf8))).then(result=>{ 
    saveAs(new Blob([result.Body]),fileName);  //  {type: result.ContentType}
    console.log(sessionStorage.getItem('RestMessage'));
},onerror =>{
    console.log(onerror);  
 }); 
}

delete(report : any) {
  if(confirm("Are you sure to delete portfolio report?")){
  this.reportsService.deleteReport(report).then(()=>{window.location.reload();}); 
  }
  // this.reportsService.deleteReport(report).suscribe(()=>this.reportsService.GetReports()); 
// this.reportsService.GetReports();
// this.router.navigate(['reports']); 
}

deleteselected(){
  this.selectedreports = [];
   for(let item of this.selection.selected){
    this.selectedreports.push(item.id);
   }
  
  if(this.selectedreports.length > 0 && confirm("Are you sure to delete selected portfolio report(s)?")){
    this.reportsService.deleteReports(this.selectedreports).then(()=>{
      window.location.reload();
    }); 
    }

}

  onPageChanged(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    /*let firstCut = e.pageIndex * e.pageSize;
    let secondCut = firstCut + e.pageSize;
    this.activePageDataChunk = this.dataSource.slice(firstCut, secondCut);
    for(let item of this.activePageDataChunk){
      item.reportDate = (item.reportDate).substring(0,10)
    }*/
    this.getReports();
    this.selection.clear();
  }


  ngOnDestroy() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
  }
}
