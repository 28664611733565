import { Component, OnInit, Input, NgZone, Output, EventEmitter } from '@angular/core';
import * as FusionCharts from 'fusioncharts';

@Component({
  selector: 'idw-chart',
  templateUrl: './idw-chart.component.html',
  styleUrls: ['./idw-chart.component.css']
})
export class IdwChartComponent implements OnInit {

  @Input() id: string;
  @Input() events: any;
  @Input() datasource: any;
  @Input() type: string;
@Input() selectedIndex:any;
  @Output() carouselPreviousEmit: EventEmitter<string>  = new EventEmitter();
  @Output() carouselNextEmit: EventEmitter<string>  = new EventEmitter();
  constructor(private zone: NgZone) { }
  @Input() isCarousel: boolean = true;


  ngOnInit() {
   
      
  }
  
  carouselPrevious(){
    this.carouselPreviousEmit.emit(' ');
  }
  carouselNext() {
    this.carouselNextEmit.emit(' ');
  }
}
