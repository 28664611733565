import { Injectable } from '@angular/core';
import { NewReportForm, Report, ReportData, ExportReportDetails, ExportReportDetailsColumns, ExportReportData } from '../models/report';
import { BehaviorSubject, Observable, of, from, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NeweupabdatesForm } from '../models/eupabdates';
import { ResponseModel } from '../../models/landing-page-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { debug } from 'console';


@Injectable({
  providedIn: 'root'
})
export class eupabdatesDataService {

  public isFormValid = true;
  constructor(private httpClient: HttpClient) { }

  private _listners = new Subject<any>();
  clientPublish(): Observable<any> {
    return this._listners.asObservable();
  }


  publishClient(client: string) {
    this._listners.next(client);
  }

  fetchReports$(pageIndex, pageSize): Observable<any> {
    return from(this.GeteupabdatesData(pageIndex, pageSize));
  }
  fetcheupabdatesData$(pageIndex, pageSize,filter): Observable<any> {
    return from(this.GeteupabdatesDataByFilter(pageIndex, pageSize,filter));

  }
  
  createupabdates(eupabdatesForm: NeweupabdatesForm): Observable<string> {
    this.saveNeweupabdates(eupabdatesForm).then((a) => {
      if (a.data != 0) {
        //this.publishClient("created eupabdates data successfully!");
      }
      else {
        //this.publishClient(a.meta.message);
      }
    });
    return of(eupabdatesForm.indexCode);

  }

  updateeupabdatesIndex(eupabdatesForm: NeweupabdatesForm): Observable<string> {
    this.saveeupabdates(eupabdatesForm).then((a) => {
      if (a.data != 0) {
        // this.publishClient("Updated eupabdates data successfully!");
      }
      else {
        // this.publishClient(a.data.message);
      }
    });
    return of(eupabdatesForm.indexCode);
  }

  validateeupabdates(indexCode: string, dateOfData: string): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/ValidateEUPABDates', JSON.stringify({ indexCode: indexCode, dateOfData: dateOfData, }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data
      }).catch(this.handleErr);
  }
 
  saveNeweupabdates(eupabdatesForm: NeweupabdatesForm): any {
    if (eupabdatesForm.parentIndexCode != undefined ? eupabdatesForm.indexCode.toLowerCase() != eupabdatesForm.parentIndexCode.toLowerCase() : true) {
      this.validateeupabdates(eupabdatesForm.indexCode,eupabdatesForm.DateOfData).then((res) => {
        if (res) {
          alert('This EUPAB Date already exists for this index!');
        }
        else {   
          this.isFormValid = true;
          let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          return this.httpClient.post(
            environment.dataServiceRoot + 'ui/SITableConfigurations/AddEUPABDates',
            JSON.stringify({
              IndexCode: eupabdatesForm.indexCode,
              ParentIndexCode: eupabdatesForm.parentIndexCode,
              YearOfTheDate: eupabdatesForm.YearOfTheDate,
              DateOfData: eupabdatesForm.DateOfData,
              IndexFamilyID: eupabdatesForm.indexFamilyID
            }),

            { headers: headers }).toPromise()
            .then(response => {
              var result = response as ResponseModel;
              if (result.data != 0) {
                this.publishClient("EUPAB date added successfully!");
              }
              else {
                this.publishClient(result.meta.message);
              }
              
            }).catch(this.handleErr);
        }
      });
    }
    else {
      alert('Index code and Parent Index code must be different');
    }
  }

  saveeupabdates(eupabdatesForm: NeweupabdatesForm): any {

    if (eupabdatesForm.parentIndexCode != undefined ? eupabdatesForm.indexCode.toLowerCase() != eupabdatesForm.parentIndexCode.toLowerCase() : true) {
      this.isFormValid = true;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(
        environment.dataServiceRoot + 'ui/SITableConfigurations/UpdateEUPABDates',
        JSON.stringify({
          IndexCode: eupabdatesForm.indexCode,
          ParentIndexCode: eupabdatesForm.parentIndexCode,
          YearOfTheDate: eupabdatesForm.YearOfTheDate,
          DateOfData: eupabdatesForm.DateOfData,
          IndexFamilyID: eupabdatesForm.indexFamilyID          
        }),

        { headers: headers }).toPromise()
        .then(response => {
          var result = response as ResponseModel;
          if (result.data != 0) {
            this.publishClient("Updated eupabdates data successfully!");
          }
          else {
            this.publishClient(result.meta.message);
          }
        }).catch(this.handleErr);
    } else {
      alert('Index code and Parent Index code must be different');
    }
  }

  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }



  GeteupabdatesData(pageIndex, pageSize): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EUPABDatesList', JSON.stringify({ page: pageIndex, pageSize: pageSize }),
      { headers: headers });
  }
   
  GeteupabdatesDataByFilter(pageIndex, pageSize, filter): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EUPABDatesListByFilter', JSON.stringify({ pageSize: pageSize, page: pageIndex, filter: filter }),
      { headers: headers });
  }
  GetindexList(): Observable<any> {
   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EUPABDatesIndexList',null,
     { headers: headers });
  }


  deleteeupabdatesData(eupabdatesForm: any): any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DeleteEUPABDates',
      JSON.stringify({
        IndexCode: eupabdatesForm.IndexCode, 
        DateOfData: eupabdatesForm.DateOfData
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        this.publishClient("EUPAB Date has been deleted");
        return result;
      }).catch(this.handleErr);
  }

  downloadExcel(IndexCode: string): Promise<any> {
    debugger;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DownloadEUPABDatesExcel',
      JSON.stringify({
        IndexCode: IndexCode
      }),
      { headers: headers }).toPromise()
      .then(response => {
          var result = response;
          return result;
    })
        .catch (this.handleErr);
  }

  }

