import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PortfolioForm, Portfolio, portfolioToPortfolioForm} from '../../models/portfolio';
// tslint:disable-next-line:import-blacklist
import {BehaviorSubject} from 'rxjs';
import { PortfoliosService } from '../../services/portfolios.service';
import {DownloadService} from '../../services/download.service';
import {BenchmarksService} from '../../services/benchmarks.service';


@Component({
  selector: 'app-portfolio-benchmark-form',
  templateUrl: './portfolio-benchmark-form.component.html',
  styleUrls: ['./portfolio-benchmark-form.component.scss']
})
export class PortfolioBenchmarkFormComponent implements OnInit, OnDestroy {
  portfolioForm: FormGroup = this.fb.group({
    id : [0],
    name: ['', Validators.required],
    nameBasedOnfileName: [''],
    description: [''],
    selectBenchmark: ['', Validators.required],
    selectDestBenchmark: ['', Validators.required],
    assetClass: ['',  Validators.required],
    file: [''],
    marketValue :[''],
    asOfDate: ['', Validators.required],
    currency: ['', Validators.required],
    isMarketValueFromTemplate: [false]
  } as Record<keyof PortfolioForm, any>);
  isUpdateForm = false;
  fileNames: string;
  indexMarkers:any;
  isportfolioForm = false;
  _protfolio : any;
  public  benchmark1:any;
  public  benchmark2:any;
  public  asofDate:string;
  @Input() set data (portfolio: Portfolio) {
    this.isUpdateForm = true;
    const form = portfolioToPortfolioForm(portfolio);
    Object.keys(this.portfolioForm.controls).forEach((key) => {
      if (key in form) {
        this.portfolioForm.controls[key].patchValue(form[key]);
      }

        if(this.isUpdateForm)
        {
        this.portfolioForm.controls['selectDestBenchmark'].setValue('-- select a benchmark --');
        }
    });
  }
  @Output() submitbenchmarkForm = new EventEmitter<PortfolioForm>();

  currencies: string[] ;
  currenciesfilteredList : string[];
  fileLoading$ = new BehaviorSubject<boolean>(false);
  constructor(private portfolioService : PortfoliosService,private downloadService: DownloadService,
    private fb: FormBuilder,private benchmarksService: BenchmarksService, private portfoliosService: PortfoliosService
  ) { }
  @ViewChild('fileInput', {static: true}) fileInput: ElementRef | undefined;

  ngOnInit(): void {
    this.portfolioService.getCurrencies().subscribe(data =>{
      this.currencies =data.data;
      this.currenciesfilteredList =data.data;
    })
  }

  
  onClick(): void {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.click();
    this.fileLoading$.next(true);
    setTimeout(() => this.fileLoading$.next(false), 1000);
  }

  onSubmit(): void {
    if (this.portfolioForm.invalid) {
      this.isportfolioForm = true;
      return;
    }   
    this.portfoliosService.ValidateportfolioName$(this.portfolioForm.controls['name'].value).then((res) => {
      console.log(res);
      this._protfolio = res;
      if(this._protfolio != undefined && this._protfolio.length > 0)
      {
        if(!this.isUpdateForm)
        {
          var protfolio = this._protfolio.find(x=> x.name == this.portfolioForm.controls['name'].value)
          if(protfolio == undefined)
          {
            this.submitbenchmarkForm.emit(this.portfolioForm.getRawValue());
          }
          else
          {
              alert('Portfolio name is already exist. Please provide an unique name.');
          }
        }
      }
      else{
        if(this.isUpdateForm)
        {
          if(this.portfolioForm.controls['selectDestBenchmark'].value=='-- select a benchmark --')
          {
              alert('Please select Benchmark');             
             
            }
          else
          {
            this.submitbenchmarkForm.emit(this.portfolioForm.getRawValue());
          }
        }
        else
        {
          this.submitbenchmarkForm.emit(this.portfolioForm.getRawValue());
        }        
      }
    })
  }

  public GetData1(data: any):void { 
     this.benchmark1 = data; 
   
}
public GetData2(data: any):void { 
   this.benchmark2 = data; 
 
}

getBenchmark(date: string, assetClass: string){
  this.benchmarksService.GetBenchmark(date, assetClass).then(data=>{
    if (data != undefined) {
      this.indexMarkers = data;
    }
    }); 
}

dateChange(date: string ) { 
  this.getBenchmark(date,this.portfolioForm.controls['assetClass'].value);  
}

assetClassChange(assetClass: string ) { 
  this.getBenchmark(this.portfolioForm.controls['asOfDate'].value, assetClass);
}

 

  fileProgress(event){
    var result=[];
    var marketValueSum =0;
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      const reader = new FileReader();
reader.onload = (event: Event) => {
        console.log(reader.result);
     };
      this.fileNames = file.name;
      this.portfolioForm.controls['file'].setValue(file);
      this.downloadService.readFileData$<Portfolio>(file).subscribe((data) => {
        result = data.data;
        for (var i = 0; i < result.length; i++) {
          result[i].PortfolioValue = (result[i].PortfolioValue == "") ? 0 : parseFloat(result[i].PortfolioValue);
          marketValueSum = marketValueSum + result[i].PortfolioValue;
        }
        console.log("Total Sum is " + marketValueSum)
        if (marketValueSum != 0) {
          this.portfolioForm.controls['marketValue'].setValue(marketValueSum);
          document.getElementById('marketValue').setAttribute("readonly", 'true');
          this.portfolioForm.controls['isMarketValueFromTemplate'].setValue(true);
        }
        else {
          document.getElementById('marketValue').removeAttribute("readonly");
          this.portfolioForm.controls['marketValue'].reset();
          this.portfolioForm.controls['isMarketValueFromTemplate'].setValue(false);
        }
      });  
    }

  }
  ngOnDestroy() {}
}
