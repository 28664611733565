
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { BehaviorSubject ,  Subject } from 'rxjs'

import { WaveData } from '../models/waves-data.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import { JsonValue } from 'aws-sdk/clients/glue';
import { HttpParams } from '@angular/common/http';
import { ResponseModel } from '../models/landing-page-model';
import { ReportData } from '../models/report-data.model';
import { MissingIndex } from '../models/missing-index.model';
import { LoggingData } from '../models/logging-data.model';
import { KPIData } from '../models/kpi-data.model';
import { FileLoadStatus } from '../models/fileload-status.model';

@Injectable()
export class AdminReportService {

  reportData: ReportData;
  kpiTestLoaded = new Subject<any[]>();
  kpiTestLoadStatusFailed = new Subject<boolean>();

  reportdataLoaded = new Subject<ReportData[]>();
  reportdataIsLoading = new BehaviorSubject<boolean>(false);
  reportdataLoadFailed = new Subject<boolean>();

  missingIndexLoaded = new Subject<MissingIndex[]>();
  missingIndexIsLoading = new BehaviorSubject<boolean>(false);
  missingIndexFailed = new Subject<boolean>();

  loggingData: LoggingData;
  loggingdataIsLoading = new BehaviorSubject<boolean>(false);
  loggingdataLoaded = new Subject<LoggingData[]>();
  loggingdataLoadFailed = new Subject<boolean>();

  kpiData: KPIData;
  kpidataIsLoading = new BehaviorSubject<boolean>(false);
  kpidataLoaded = new Subject<KPIData[]>();
  kpidataLoadFailed = new Subject<boolean>();

  wavesDate: WaveData[];
  wavesdataIsLoading = new BehaviorSubject<boolean>(false);
  wavesdataLoaded = new Subject<any[]>();
  wavesdataLoadFailed = new Subject<boolean>();

  fileLoadStatus: FileLoadStatus;
  fileLoadStatusLoaded = new Subject<FileLoadStatus[]>();
  fileLoadStatusIsLoading = new BehaviorSubject<boolean>(false);
  fileLoadStatusFailed = new Subject<boolean>();

  constructor( private httpClient: HttpClient) { }

  getReportData(inputdate: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.reportdataLoaded.next(null);
    this.reportdataLoadFailed.next(false);
    this.reportdataIsLoading.next(true);
    this.httpClient.post(environment.dataServiceRoot + 'ui/AdminReport/GetAPIReport', JSON.stringify({
      AsOfDate: inputdate
    }), { headers: headers }).pipe(
      map(
      (response: Response) =>
        response as any
      ))
      .subscribe(
      (data) => {
        var result = data as ResponseModel;
        var reports = result.data as any[];
        this.reportdataLoaded.next(reports);
      },
      (error) => {
        console.log(error);
        this.reportdataLoadFailed.next(true);
        this.reportdataLoaded.next(null);
      });
  }

  getMissingIndex(inputdate: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.missingIndexLoaded.next(null);
    this.missingIndexFailed.next(false);
    this.missingIndexIsLoading.next(true);
    this.httpClient.post(environment.dataServiceRoot + 'ui/AdminReport/GetMissingIndexReport', JSON.stringify({
      AsOfDate: inputdate
    }), { headers: headers }).pipe(
      map(
      (response: Response) =>
        response as any
      ))
      .subscribe(
      (data) => {
        var result = data as ResponseModel;
        var missingIndexReports = result.data as any[];
        this.missingIndexLoaded.next(missingIndexReports);
      },
      (error) => {
        console.log(error);
        this.missingIndexIsLoading.next(true);
        this.missingIndexLoaded.next(null);
      });
  }

  getKPIData(inputdate: string, methodEnd: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.kpidataLoaded.next(null);
    this.kpidataLoadFailed.next(false);
    this.kpidataIsLoading.next(true);
    this.httpClient.post(environment.dataServiceRoot + 'ui/AdminReport/GetKPILogOfUser', JSON.stringify({
      AsOfDate: inputdate,
      MethodNameEndsWith: methodEnd
    }), { headers: headers }).pipe(
      map(
      (response: Response) =>
        response as any
      ))
      .subscribe(
      (data) => {
        var result = data as ResponseModel;
        var reports = result.data as any;
        this.kpidataLoaded.next(reports);
      },
      (error) => {
        console.log(error);
        this.kpidataLoadFailed.next(true);
        this.kpidataLoaded.next(null);
      });
  }

  getWavesData(inputdate: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.wavesdataIsLoading.next(null);
    this.wavesdataLoadFailed.next(false);
    this.wavesdataIsLoading.next(true);

    this.httpClient.post(environment.dataServiceRoot + 'ui/AdminReport/GetWavesReport', JSON.stringify({
      AsOfDate: inputdate
    }), { headers: headers }).pipe(
      map(
      (response: Response) =>
        response as any
      ))
      .subscribe(
      (data) => {
        var result = data as ResponseModel;
        var reports = result.data as any[];
        this.wavesdataLoaded.next(reports);
      },
      (error) => {
        console.log(error);
        this.wavesdataLoadFailed.next(true);
        this.wavesdataLoaded.next(null);
      });
  }

  getFileLoadStatus(inputdate: string) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.fileLoadStatusLoaded.next(null);
    this.fileLoadStatusFailed.next(false);
    this.fileLoadStatusIsLoading.next(true);

    this.httpClient.post(environment.dataServiceRoot + 'ui/AdminReport/GetFileLoadStatus', JSON.stringify({
      AsOfDate: inputdate
    }), { headers: headers }).pipe(
      map(
      (response: Response) =>
        response as any
      ))
      .subscribe(
      (data) => {
        var result = data as ResponseModel;
        var reports = result.data as any[];
        this.fileLoadStatusLoaded.next(reports);
      },
      (error) => {
        console.log(error);
        this.fileLoadStatusFailed.next(true);
        this.fileLoadStatusLoaded.next(null);
      });
  }

  getKPITst(inputDate:string, indexMarkers:any){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.httpClient.post(environment.dataServiceRoot + 'api/idw/constituentlistclose', JSON.stringify({
      AsOfDate: inputDate,
      IndexMarkers: indexMarkers
    }), { headers: headers }).pipe(
      map(
      (response: Response) =>
        response as any
      ))
      .subscribe(
      (data) => {
        var result = data as ResponseModel;
        this.kpiTestLoaded.next(result.data);
      },
      (error) => {
        console.log(error);
        this.kpidataLoadFailed.next(true);
        this.kpiTestLoaded.next(null);
      });
  }


}
