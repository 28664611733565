import {GridData} from './common';
import {BenchmarkDetailsColumns} from './benchmark';

export const DEFAULT_COLUMNS = [
  ...Object.values(BenchmarkDetailsColumns),
];

export interface Portfolio {
  id: number;
  name: string;
  description: string;
  marketValue: string;
  benchMark: string;
  benchMarkDest: string;
  assetClass: string;
  currency: string;
  asOfDate: string;
  children?: PortfolioDetails;
  isMarketValueFromTemplate: boolean;
  sourceBenchmark : string;
}

export const newPortfolio = ({
                        id= 0,
                        name= '',
                        description= '',
                        marketValue= '',
                        benchMark= '',
                        benchMarkDest= '',
                        assetClass= '',
                        currency= '',
                        asOfDate= '',
                        children,
                        isMarketValueFromTemplate = false,
                        sourceBenchmark = ''
                      }: Partial<Portfolio> = {}): Portfolio => {
  const portfolio = {
    id: id,
    name: name,
    description,
    marketValue,
    benchMark,
    benchMarkDest,
    assetClass,
    currency,
    asOfDate,
    children,
    isMarketValueFromTemplate,
    sourceBenchmark
  };
  if (children) {
    portfolio.children.model = portfolio;
  }
  return portfolio;
};


export interface PortfolioDetails extends GridData<Portfolio> {}


export interface PortfolioForm {
  id: number;
  name: string;
  nameBasedOnfileName: string;
  description: string;
  selectBenchmark: string;
  selectDestBenchmark: string;
  assetClass: string;
  file: File;
  asOfDate: string;
  currency: string;
  marketValue: string;
  isMarketValueFromTemplate:boolean;
  sourceBenchmark : string;
}

export interface PortfolioDetailsMockData {
  [key: string]: PortfolioDetails;
}

export const portfolioToFormMapper: Record<keyof Portfolio, keyof PortfolioForm> = {
  id: 'id',
  currency: 'currency',
  asOfDate: 'asOfDate',
  benchMark: 'selectBenchmark',
  benchMarkDest: 'selectDestBenchmark',
  assetClass: 'assetClass',
  name: 'name',
  description: 'description',
  marketValue: 'marketValue',
  isMarketValueFromTemplate:'isMarketValueFromTemplate',
  sourceBenchmark : 'sourceBenchmark',
  // TODO: Need to be refactoring based on API Interface
  children: null
};

export const formToPortfolioMapper = Object.keys(portfolioToFormMapper).reduce((acc, portfolioKey) => {
  if (portfolioToFormMapper[portfolioKey] === null) {
    return acc;
  }
  acc[portfolioToFormMapper[portfolioKey]] = portfolioKey;
  return acc;
}, {});

export function portfolioToPortfolioForm(portfolio: Portfolio): PortfolioForm {
  return Object.keys(portfolioToFormMapper).reduce((acc, portfolioField) => {
    // TODO: Need to be refactoring based on API Interface
    if (portfolioToFormMapper[portfolioField] === null) {
      return acc;
    }
    acc[portfolioToFormMapper[portfolioField]] = portfolio[portfolioField];
    return acc;
  }, {} as PortfolioForm);
}

export function portfolioFormToPortfolio(form: PortfolioForm): Portfolio {
  const portfolio = newPortfolio();
  Object.keys(formToPortfolioMapper).forEach((formKey) => {
    portfolio[formToPortfolioMapper[formKey]] = form[formKey];
  });
  return portfolio;
}
