import { Component, OnInit, Input, OnChanges, SimpleChanges,Inject,Output,EventEmitter,NgZone } from '@angular/core';
import {WeightService} from '../../services/weight.service';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {BiService} from '../../services/BiService.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MagellanColorService} from '../../services/magellancolor.service';
import { CloseHoldingsChartModel } from '../../models/close-holdings-chart-model';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import {DateService } from '../../services/date.service';

@Component({
    selector: 'weight-sector-supersector-chart',
    templateUrl: './weight-sector-history-weights-supersector-chart.component.html'
})

export class WeightSectorHistorySuperSectorComponent implements OnInit {

    chartAttributes: any;
    colorsRange:string;
    WeightsSectorSuperSectorDataSource: any;
    currentIndex:any;
    colors : any = "#7FBA00,#00B294,#FF8C00,#0072C6,#68217A,#00188F,#808080";
    popupData: any;
    popupChartAttributes: any;
    WeightsSectorSuperSectorChartLoader:boolean = true;
    popupDataSource:any;
    chart:any;
    asofDates: any;
    startDate:any;
    timePeriod:any;
    indexMarkers: any ;
    treeMapObject: any;
    selectedSectorChart : string = "icb";
    currencyCode:any="USD";
    dateFormat: any;
    checkLoadFirstTime: boolean;
    dateFormatUK: boolean;
    dateFormatUSA: boolean;
    @Output() chartCurentIndexMarker: EventEmitter<string> = new EventEmitter();

    constructor(private weightService: WeightService ,private indexMarkerService:IndexMarkerService, private exportService: ExportToExcelService,private publishIndexMarkerService:PublishIndexMarkerService,
        private dialog: MatDialog,private colorService : MagellanColorService,private biService:BiService,
        private zone:NgZone,private idwChartExportService: IDWChartExportService,private dateService : DateService) {       
            this.chartAttributes = {                           
                "bgColor": "#ffffff",
                "exportEnabled": "1",
                "exportShowMenuItem": "0",
                "numberSuffix":"%",
                "paletteColors": "",
                "borderAlpha": "20",               
                "showCanvasBorder": "0",
                "usePlotGradientColor": "0",
                "plotBorderAlpha": "10",                
                "legendBorderAlpha": "0",
                "legendShadow": "0",
                "showValues": "0",
                "plotFillAlpha": "100",
                "showBorder": "0",
                "showXAxisLine": "1",
                "xAxisLineColor": "#999999",
                "divlineColor": "#999999",
                "divLineDashed": "1",
                "showAlternateHGridColor": "0",
                "showLegend":"0",
                "drawAnchors":"1",
                "anchorRadius":"5"
            };  
            this.popupChartAttributes={
                "bgColor": "#ffffff",                
                "numberSuffix":"%",
                "paletteColors": this.colors,
                "borderAlpha": "20",
                "showCanvasBorder": "0",
                "usePlotGradientColor": "0",
                "plotBorderAlpha": "10",              
                "legendBorderAlpha": "0",
                "legendShadow": "0",
                "showValues": "0",
                "showBorder": "0",
                "plotFillAlpha": "100",
                "showXAxisLine": "1",
                "xAxisLineColor": "#999999",
                "divlineColor": "#999999",
                "divLineDashed": "1",
                "showAlternateHGridColor": "0",
                "showLegend":"1",
                "legendPosition":"RIGHT" ,
                "drawAnchors":"1",
                "anchorRadius":"5"               
            };

        }
        
       
        ngOnInit() {
            this.dateFormat = this.dateService.getDateFormat(); 
            this.dateService.dateFormatPublish().subscribe(dateFormat=>{
                this.dateFormat =  dateFormat;      
                this.changeDateFormat(this.treeMapObject);
                                    
            });
            this.indexMarkers = this.indexMarkerService.getIndexMarkers();
            this.currentIndex = 0; 

            this.colors = this.colorService.getCurrentColorSchema();
            this.colorService.colorsPublish().subscribe(color => {     
                this.colors =  color;
                this.chartAttributes.paletteColors=  this.colors;    
            }); 
            this.chartAttributes.paletteColors=  this.colors; 
            this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
                this.indexMarkers = result;
                this.chartSetup(this.selectedSectorChart,this.treeMapObject);
            });                

        }
    
        chartInitialize(event){
            return (eve, arg) => { 
                this.zone.run(() => {
                    this.chart =eve.sender;
                });  
                
            };
              
          }
        
          events = {
            initialized: this.chartInitialize(event)
        }
        private changeDateFormat(data: any) {
            if (this.dateFormat == "UK") {                       
                for (var i = 0; i < data.icbChartData.holdingscategories[0].category.length; i++) {
                  data.icbChartData.holdingscategories[0].category[i].label = this.dateService.changeDateFormatUK(data.icbChartData.holdingscategories[0].category[i].label);
                } 
            }
            else {
              if (this.dateFormat == "USA" && this.checkLoadFirstTime == false) {
                for (var i = 0; i < data.icbChartData.holdingscategories[0].category.length; i++) {
                  data.icbChartData.holdingscategories[0].category[i].label = this.dateService.changeDateFormatUSA(data.icbChartData.holdingscategories[0].category[i].label);
                }                
              }              
            }
            this.checkLoadFirstTime = false;
          }

        carouselPrevious():void{
            var event: any = {eventName: "Sector SuperSector Weight chart carousel previous", inputParameters: [{Name: "Previous IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() },{Name : "Relevent TimePeriod" , Value: this.timePeriod},{Name: "Relevent As Of Date", Value: this.asofDates.toString()}]};
            this.biService.addEvent(event);
            if(0 == this.currentIndex){
               this.currentIndex = this.indexMarkers.length -1;
            }else{
                this.currentIndex = this.currentIndex - 1;
            }
            this.WeightsSectorSuperSectorChartLoader = true;
            this.chartCurentIndexMarker.emit(this.indexMarkers[this.currentIndex]);
        }
        
        carouselNext():void{
            var event: any = {eventName: "Sector SuperSector Weight chart carousel next", inputParameters: [{Name: "Next IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() },{Name : "Relevent TimePeriod" , Value: this.timePeriod},{Name: "Relevent As Of Date", Value: this.asofDates.toString()}]};
            this.biService.addEvent(event);
           if((this.indexMarkers.length -1) == this.currentIndex){
                this.currentIndex = 0;
            }else{
                this.currentIndex = this.currentIndex + 1;
            }
            this.WeightsSectorSuperSectorChartLoader = true;
            this.chartCurentIndexMarker.emit(this.indexMarkers[this.currentIndex]);
        }
    
        getCurrentIndex(){
            return this.indexMarkers[this.currentIndex];
        }

        update(closeHoldingsChartModel: CloseHoldingsChartModel,startDate:any,endDate:any,timePeriod:any){  
            if(null!= closeHoldingsChartModel && undefined!= closeHoldingsChartModel){
                 
            this.startDate = startDate;
            this.asofDates = endDate;       
            this.timePeriod = timePeriod;
            this.chartAttributes.paletteColors=  this.colors; 
            this.chartSetup(this.selectedSectorChart,closeHoldingsChartModel);
            }else{
                this.WeightsSectorSuperSectorChartLoader = false;
            }
        }
    
        onChange(selectedChart,currencyCode){
            this.currencyCode=currencyCode;
            this.selectedSectorChart = selectedChart; 
            this.chartAttributes.paletteColors=  this.colors;  
            if(this.treeMapObject != null && this.treeMapObject!=undefined){
                this.chartSetup(this.selectedSectorChart,this.treeMapObject);
            }else{
                this.WeightsSectorSuperSectorChartLoader = false;
            }
                        
        }
        private chartSetup(selectedChart:string,closeHoldingsChartModel: CloseHoldingsChartModel) {
            this.WeightsSectorSuperSectorChartLoader = false;         
            this.chartAttributes.caption= "FTSE "+this.indexMarkers[this.currentIndex]+"- Supersector";        
                this.treeMapObject = closeHoldingsChartModel; 
                this.checkLoadFirstTime=true; 
                this.changeDateFormat(this.treeMapObject);   

                if(selectedChart == "icb" && 0 == this.treeMapObject.icbChartData.holdingsDataset.length){
                    this.WeightsSectorSuperSectorDataSource = {};
                   }else if (selectedChart == "rgs" && 0 == this.treeMapObject.rgsChartData.holdingsDataset.length){
                    this.WeightsSectorSuperSectorDataSource= {};
                   }
                   else{
                    this.WeightsSectorSuperSectorDataSource = {
                        "chart": this.chartAttributes,
                        "categories":this.treeMapObject.icbChartData.holdingscategories,
                        "dataset": (this.selectedSectorChart == "icb")?this.treeMapObject.icbChartData.holdingsDataset:this.treeMapObject.rgsDataset,                 
                        
                    }  ;
                   }              
                         
        }
            
        loadingRefresh(){
            this.WeightsSectorSuperSectorChartLoader = true;
            this.WeightsSectorSuperSectorDataSource = {};
        }
        loadingStop(){
            this.WeightsSectorSuperSectorChartLoader = false;
        }
        failedLoad(){
            this.WeightsSectorSuperSectorDataSource = {};
            this.WeightsSectorSuperSectorChartLoader = false;
        }
        
     ExportXls(event):void{
        var event: any = {eventName: "Weight Sector SuperSector Weight Chart Exported", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name : "Exported TimePeriod" , Value: this.timePeriod},,{Name: "Exported As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);
        var inputData = {
            "exportType": "xls",
            "code": "weight-getSuperSectorWeightData",
            "xlsFileName": "SuperSectorRawData",
            "asOfDate": this.asofDates,
            "indexMarkers": [this.indexMarkers[this.currentIndex]],
            "returnType": this.selectedSectorChart,
            "currencyCode": this.currencyCode,
            "fromDate": this.startDate,
            "timePeriod": this.timePeriod
          };
          this.idwChartExportService.export(inputData);
          }

        

          openDialog(): void{ 
            var event: any = {eventName: "help dialog open of Weight Sector SuperSector Weight Chart", inputParameters: [{Name : "Expanded IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name : "Expanded TimePeriod" , Value: this.timePeriod},{Name: "Expanded As Of Date", Value: this.asofDates.toString()}]};
            this.biService.addEvent(event);                       
            this.popupChartAttributes.caption= "FTSE "+this.indexMarkers[this.currentIndex]+"- Supersector"; 
            this.popupData= {
                "chart": this.popupChartAttributes,
                "categories":this.WeightsSectorSuperSectorDataSource.categories,
                "dataset": this.WeightsSectorSuperSectorDataSource.dataset               
            }   
            
            this.popupData.chart.showlegend = "1";
            this.popupDataSource = {
                "dataSource":this.popupData,
                "asOfDate": (this.dateFormat == "USA") ? this.dateService.changeDateFormat(this.asofDates) : this.dateService.changeDateFormatUK(this.asofDates)
           };
            this.popupChartAttributes.caption= "FTSE "+this.indexMarkers[this.currentIndex]+"- Supersector"; 
            let dialogRef = this.dialog.open(WeightSectorHistorySuperSectorComponentDialog, {
                width: '80%',
                height: '70%',
            data: this.popupDataSource
      });
    }

    ExportPNG(event):void{        
        var event: any = {eventName: "Export Weight Sector SuperSector Weight Chart TO PNG", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()},{Name : "Exported TimePeriod" , Value: this.timePeriod},,{Name: "Exported As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);    
        this.WeightsSectorSuperSectorDataSource.chart.showLegend ="1";
        this.WeightsSectorSuperSectorDataSource.chart.legendPosition ="RIGHT";
                var inputData = {
            "exportType": "png",
            "legend": false,
            "dataSource": this.WeightsSectorSuperSectorDataSource,
            "chartid" : "WeightsSectorSuperSectorChartExport",
            "type" : "stackedarea2d",
            "targetDiv" : "exportPngDiv",
            "isIndexLegend": false,
            "chartReference": this.chart,
            "pngFileName" : "SuperSectorRawData",
            "chartTitle": "History Weights - SuperSector",
            "chartDate": this.asofDates
         };
         this.idwChartExportService.export(inputData);
         this.WeightsSectorSuperSectorDataSource.chart.showlegend = "0";            
      }
   
                         
    }
    
    
@Component({
    selector: 'weight-sector-history-weights-supersector-chart-dialog',
	templateUrl: './weight-sector-history-weights-supersector-chart.component.dialog.html'
})
export class WeightSectorHistorySuperSectorComponentDialog{

    constructor(
    public dialogRef: MatDialogRef<WeightSectorHistorySuperSectorComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    onClose(): void {
        this.dialogRef.close();
  }
}