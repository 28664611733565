import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { ChartsDetails,NameDetails, CountryDetails, EventDetails} from './../models/gerographical-charts';
import { environment } from '../../environments/environment';
import { ResponseModel } from '../models/landing-page-model';
import { Observable } from 'rxjs';
import { Name } from 'aws-sdk/clients/efs';
// import { debugger } from 'fusioncharts';
// import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';

// const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
// const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})


export class GeographicalBarChartsService {
  // name=[];
  private headers = new Headers({'Content-Type': 'application/json'});
  httpClient: any;
  constructor(private http:HttpClient) { } 

  getMapData(asofdate: any,SelectAPIName:any):Promise<ChartsDetails[]>{
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
return this.http.post(environment.dataServiceRoot + 'ui/logs/GetTelemetryValuesByContinent',
JSON.stringify({            
    AsOfDate: asofdate,
    
    APIName: SelectAPIName  
    // APIName: "GetSedolDetails"                
  }), 
  {headers: headers})
 .toPromise()
 .then(response =>{ 
   var result = response as ResponseModel;
     return result.data as ChartsDetails[];
     })
 .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }


  getTelemetryLogsToExport(telemetryIndexMarkerFilter:any, AsOfDate: any, APIName: any, endDate: any):Promise<any>{
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
    
       return this.http.post(environment.dataServiceRoot + 'ui/logs/GetExportFileValue',
           JSON.stringify({     
             AsOfDate: AsOfDate,
             APIName : APIName,
             EndDate: endDate,
             telemetryCountryResponse: {
              CreateDate: telemetryIndexMarkerFilter.CreateDate,
              UserID: telemetryIndexMarkerFilter.UserID,
              EventID : telemetryIndexMarkerFilter.EventID, 
              IPAddress: telemetryIndexMarkerFilter.IPAddress,
              Country: telemetryIndexMarkerFilter.Country,
              City: telemetryIndexMarkerFilter.City,
              ContinentName:telemetryIndexMarkerFilter.ContinentName,
              RegionName:telemetryIndexMarkerFilter.RegionName
              
            
             }
       }), {headers: headers})
       .toPromise() 
       .then(response =>{
            return response as any
           })
       .catch(this.handleError);
}



//dropdown method 
  dropDownValue():Promise<NameDetails[]>{
    // debugger;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.dataServiceRoot +'ui/logs/GetApiNames',null, {headers: headers})
     .toPromise()
     .then(response =>{ 
      // return response as NameDetails[];
       var result = response as ResponseModel;
         return result.data as NameDetails[];
         })
     .catch(this.handleErr);
  }

  getMapDataByCountry(todaydate: any, enddate: any,apiName:any):Promise<CountryDetails[]>{
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
return this.http.post(environment.dataServiceRoot + 'ui/logs/GetTelemetryValuesByCountry',
JSON.stringify({          
  StartDate: todaydate,
  
    EndDate: enddate,
               
    APIName: apiName
                  
  }), 
  {headers: headers})
 .toPromise()
 .then(response =>{ 
   var result = response as ResponseModel;
     return result.data as CountryDetails[];
     })
 .catch(this.handleError);
  }


  getDataByEventParameter(eventID: any):Promise<EventDetails[]>{
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
return this.http.post(environment.dataServiceRoot + 'ui/logs/GetTelemetryEventParameters',
JSON.stringify({            
    eventID: eventID              
  }), 
  {headers: headers})
 .toPromise()
 .then(response =>{ 
   var result = response as ResponseModel;
     return result.data as EventDetails[];
     })
 .catch(this.handleError);
  }

  TelemetryIndexMaker(page:any,pageSize:any,telemetryIndexMarkerFilter:any,todaydate: any, enddate: any,apiName:any):Promise<any>{
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
    
          return this.http.post(environment.dataServiceRoot + 'ui/logs/getTelemetryPageValues', 
          JSON.stringify({
             Page: page,
             PageSize: pageSize,
             telemetryCountryInput:{
               StartDate:todaydate,
               EndDate:enddate,
               APIName:apiName

             },

             telemetryCountryResponse: {
              CreateDate: telemetryIndexMarkerFilter.CreateDate,
              UserID: telemetryIndexMarkerFilter.UserID,
              EventID : telemetryIndexMarkerFilter.EventID, 
              IPAddress: telemetryIndexMarkerFilter.IPAddress,
              Country: telemetryIndexMarkerFilter.Country,
              City: telemetryIndexMarkerFilter.City,
              ContinentName:telemetryIndexMarkerFilter.ContinentName,
              RegionName:telemetryIndexMarkerFilter.RegionName
              
            
             }
         
    }),         {headers: headers})
                .toPromise()
                .then(response =>{
                     return response as any
                    })
                .catch(this.handleError);
}

  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  // dropDownValue():Observable<any>{
  //   return this.http.get('ui/logs/GetApiNames');
  //     }
}
 