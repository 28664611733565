import { Component, OnInit, EventEmitter,Output,ViewChild,AfterViewInit, Inject } from '@angular/core';
import {CustomTableComponent} from '../custom-table/custom-table.component';
import { LandingService } from '../../services/landing.service';
import {FilterPipe, FilterReturnTypePipe} from '../../filter.pipe';
import { DecimalPipe } from '@angular/common';
import {ColumnChooserService} from '../../services/column-chooser.service';
import {CustomColumnsModel} from '../../models/custom-columns-model';
import {PerformancePageModel} from '../../models/performance-page-model';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import * as moment from 'moment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {DateService } from '../../services/date.service';
import {DateDisplayService} from '../../services/date.display.service';
import { BiService } from '../../services/BiService.service';
import{CurrencyService}from '../../services/currency.service';

@Component({
    selector: 'performance-grid',
    templateUrl: './performance-grid.component.html' 
})

export class PerformanceGridComponent implements OnInit {   
    
    performGriddidFail: boolean=false;
    @Output() notifyPageModel: EventEmitter<PerformancePageModel> = new EventEmitter<PerformancePageModel>();
    @ViewChild(CustomTableComponent,{static: false}) customTableComponent; 
    datatable: any; 
    dataTableRawData: any;
    gridCustomColumns: any[];
    gridData:any;         
    indexMarkers : any;   
    pageModel : PerformancePageModel = {currencyCode:"USD",returntype:"P",selectedDates:{start:moment().subtract(2,'months').toDate(),end:moment().subtract(1,'days').toDate()}};     
    supportedFilters : any[] = [{ "key": "Hedged", "value": "H" }, { "key": "Net", "value": "N" },{ "key": "Total", "value": "T" },{ "key": "Price", "value": "P" } ];
    PerformanceGridLoader:boolean = true;
   
    constructor(private dateDisplayService: DateDisplayService,private dateService : DateService,
        private landingService: LandingService, private exportService: ExportToExcelService,
        private publishIndexMarkerService:PublishIndexMarkerService,private indexMarkerService:IndexMarkerService,
        private columnChooserService:ColumnChooserService,public dialog: MatDialog,private biService: BiService,
        private currencyService:CurrencyService) { }

    ngOnInit() {            
      this.indexMarkers = this.indexMarkerService.getIndexMarkers();
      this.pageModel.currencyCode=this.currencyService.getCurrencyCode(); 
      this.currencyService.currencyCodePublish().subscribe(currency=>{        
        this.onNotifyCurrency(currency);
        // this.PerformanceGridLoader=false;
      });       
      this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
        this.PerformanceGridLoader=true;
        this.indexMarkers=result;
        this.gridSetup(result);
      });
      
      this.onNotify(this.dateService.getCalendarDate());
      this.dateService.calendarDatePublish().subscribe(result=>{ 
          this.onNotify(result);
      });

    }
   
      
    PopupInfo(): void{         
        var event: any = {eventName: "Performance grid help dialog open"};
        this.biService.addEvent(event);   
        let dialogRef = this.dialog.open(PerformanceGridComponentDialog, {
          width: '800px'            
        });
      }

    refreshGrid(customColumns:CustomColumnsModel[]){
        var event: any = {eventName: "Column chooser clicked", inputParameters: [{Name : "ColumnName", Value :JSON.stringify(customColumns)}]};
        this.biService.addEvent(event);   
        this.gridCustomColumns = customColumns;
        this.customTableComponent.bind(this.gridData,this.gridCustomColumns); 
    }

    gridSetup(indexMarkers:any) {  
        this.PerformanceGridLoader = true;   
        this.columnChooserService.getPerformanceCustomColumns(this.pageModel.selectedDates.start,this.pageModel.selectedDates.end).then(customs=>{
            this.gridCustomColumns = customs;
          });      
          this.landingService.indexCalculatorDataByValuesLoaded.observers=[];
     this.landingService.getgetIndexReturns(this.pageModel.selectedDates.end,indexMarkers,this.pageModel.selectedDates.end
        ,this.pageModel.selectedDates.start,this.pageModel.currencyCode ,this.pageModel.returntype).then(data => {      
            if(null != data){
                this.dataTableRawData = data;       
                this.gridData = this.flattenData(data); 
                this.customTableComponent.bind(this.dateDisplayService.getArrayDateDisplay(this.gridData,"asOfDate"),this.gridCustomColumns);
                this.PerformanceGridLoader=false;
            }else {
                this.PerformanceGridLoader=false;
            }
        });
    //  this.landingService.indexCalculatorDataByValuesLoaded.subscribe(
    //     (data: any) => {
    //         if(null != data){
    //             this.dataTableRawData = data;       
    //             this.gridData = this.flattenData(data); 
    //             this.customTableComponent.bind(this.dateDisplayService.getArrayDateDisplay(this.gridData,"asOfDate"),this.gridCustomColumns);
    //             this.PerformanceGridLoader=false;
    //         }else {
    //             this.PerformanceGridLoader=false;
    //         }
                      
    //     });
    //    this.landingService.indexCalculatorDataByValuesFailed.subscribe(
    //     (didFail: boolean) => this.performGriddidFail = didFail
    //   ); 
             
      
    }    
    PerformanceCustomColumnsSelction(event):void{
        this.refreshGrid(event);
      }   

    applyFilter(filter : string)
    {
        this.pageModel.returntype = filter;
        this.gridSetup(this.indexMarkers);
        this.notifyPageModel.emit(this.pageModel);
    }

    getFilterCSSClass(filter : string){
        return (filter == this.pageModel.returntype) ? "active" : "currencyLinks";
    }

    
    private filterData(data:any,currencyFilter:string,returnTypeFilter:string):any{
        var currencyFilterPipe = new FilterPipe();
        var currencyFiltred =  currencyFilterPipe.transform(data,this.pageModel.currencyCode);
        var returnTypeFilterPipe = new FilterReturnTypePipe();
        return returnTypeFilterPipe.transform(currencyFiltred,this.pageModel.returntype);
    }

    private flattenData(data:any[]){
        var flattenedData:any[] = [];
        for(var counter = 0; counter<data.length; counter++){
           var row = {};
           var returnsWithPeriodicData = data[counter];
           for(var prop in returnsWithPeriodicData.dataSource){
               row[prop] = returnsWithPeriodicData.dataSource[prop];
           }
           if(null!= returnsWithPeriodicData.yearlyData && undefined!= returnsWithPeriodicData.yearlyData){
            for(var yearlyCounter = 0; yearlyCounter<returnsWithPeriodicData.yearlyData.length; yearlyCounter++){
                row[returnsWithPeriodicData.yearlyData[yearlyCounter].name] = returnsWithPeriodicData.yearlyData[yearlyCounter].value;
            }
           }
           if(null!= returnsWithPeriodicData.quarterlyData && undefined!= returnsWithPeriodicData.quarterlyData){
            for(var quarterlyCounter = 0; quarterlyCounter<returnsWithPeriodicData.quarterlyData.length; quarterlyCounter++){
                row[returnsWithPeriodicData.quarterlyData[quarterlyCounter].name] = returnsWithPeriodicData.quarterlyData[quarterlyCounter].value;
               }
           }    
           if(null!= returnsWithPeriodicData.monthlyData && undefined!= returnsWithPeriodicData.monthlyData){
            for(var monthlyCounter = 0; monthlyCounter<returnsWithPeriodicData.monthlyData.length; monthlyCounter++){
                row[returnsWithPeriodicData.monthlyData[monthlyCounter].name] = returnsWithPeriodicData.monthlyData[monthlyCounter].value;
               }
           }       
           
           flattenedData.push(row);
        }
        return flattenedData;
    }
    Exportxls():void{
        var event: any = {eventName: "Export Performance Grid", inputParameters: [{Name : "CurrencyCode" , Value: this.pageModel.currencyCode},{Name : "ReturnType" , Value: this.pageModel.returntype}]};
        this.biService.addEvent(event);
        this.landingService.indexCalculatorDataByValuesDataLoaded.observers=[];
        var visibleColumns = this.columnChooserService.getVisibleColumnsNames(this.gridCustomColumns); 
        this.landingService.getIndexReturnsToExport(this.indexMarkers,this.pageModel.selectedDates.end
            ,this.pageModel.selectedDates.end,this.pageModel.selectedDates.start,  this.pageModel.currencyCode, 
            this.pageModel.returntype,visibleColumns);
        this.landingService.indexCalculatorDataByValuesDataLoaded.subscribe(
            (data: any) => {
                this.exportService.exportData(data,"PerformanceGridData");          
            });
           this.landingService.indexCalculatorDataByValuesDataFailed.subscribe(
            (didFail: boolean) => this.performGriddidFail = didFail
          ); 
           
           
       
    }

    onNotify(value:any){           
        this.pageModel.selectedDates.start =this.dateService.changeDateFormat(value.start);
        this.pageModel.selectedDates.end =this.dateService.changeDateFormat(value.end);
        this.PerformanceGridLoader=true;
        this.notifyPageModel.emit(this.pageModel); 
        this.gridSetup(this.indexMarkers);              
      }
      onNotifyCurrency(currency:any){           
        this.pageModel.currencyCode =currency;
        this.PerformanceGridLoader=true;        
        this.datatable = this.filterData(this.dataTableRawData ,currency,this.pageModel.returntype);
        this.gridSetup(this.indexMarkers); 
      
        this.notifyPageModel.emit(this.pageModel);                
      }
}

@Component({
    selector: 'performance-grid-dialog',
templateUrl: './performance-grid.component.widget.dialog.html'
})
export class PerformanceGridComponentDialog{
  performanceTextWidth:any;
  constructor(
  public dialogRef: MatDialogRef<PerformanceGridComponentDialog>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
     
   }

  onClose(): void {
      this.dialogRef.close();
}
}