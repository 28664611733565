import { Component, OnInit,Inject,Input } from '@angular/core';
import { DdsService } from '../../services/dds.service';
import {DDSFile}  from '../../models/ddstracker.model'
import { FilterDDSPipe } from '../../filter.pipe';
import {S3ManagerService} from '../../services/s3-manager.service';
import { saveAs } from 'file-saver/FileSaver';
import {DateService} from '../../services/date.service';
import { PagerService } from '../../services/pager.service';
import { Observable, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_DIALOG_DATA,MatDialog,MatDialogRef} from '@angular/material/dialog';
import { BiService } from '../../services/BiService.service';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import { environment } from '../../../environments/environment';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import * as CryptoJS from "crypto-js";
import * as AWS from 'aws-sdk';

@Component({
  selector: 'app-ddstracker',
  templateUrl: './ddstracker.component.html',
  styleUrls: ['./ddstracker.component.css']
})
export class DdstrackerComponent implements OnInit {
  ddsFiles: DDSFile[] =[];
  ddsCompleteFileList:DDSFile[]= [];
  ddsTruckerLoader:boolean=false;
  feedLoadGroupFilter : any;
  fileNameFilter : any;
  lastModifiedDateFilter : any;
  s3BucketPathFilter: any;
  versionNumberFilter: any;
  ddsPathFilter:any;
  ddsPath: any;
  startDate : any;
  endDate: any;
  pager: any = {};
  ddsFilesPaged: DDSFile[] =[];
  isReissued:any; 
  passphrase: string = "EncryptionatRest"; 
  pageSize:any = 10;
  inventoryCount:any = 0;
  pageNumber:any = 1;
  filters: any[] = [];
  filterSubscription$: Subscription;
  filterTerm$ = new Subject<string>();

  @Input() visiblecolumn: boolean = true;
constructor(private dateService : DateService, private ddsService: DdsService,
  private s3ManagerService:S3ManagerService, private pagerService: PagerService,
  private dialog: MatDialog,private biService: BiService,
  private idwChartExportService: IDWChartExportService,private exportService: ExportToExcelService) { this.isReissued="NA"}
  ngOnInit() {
    this.startDate =  this.dateService.changeDateFormat(this.dateService.getCalendarDate().start);
      this.endDate =   this.dateService.changeDateFormat(this.dateService.getCalendarDate().end);
      this.setPage(this.pageNumber,true);
    this.dateService.calendarDatePublish().subscribe(dateRange=>{
      this.startDate =  this.dateService.changeDateFormat(dateRange.start);
      this.endDate =   this.dateService.changeDateFormat(dateRange.end);
      this.setPage(this.pageNumber,true);
  });

  this.filterSubscription$ =this.filterTerm$.pipe(
    debounceTime(400),    
    distinctUntilChanged(),
    switchMap((term) => {
      this.fileNameFilter = term;
      var filters = this.getFiltersArray();
      return this.onPageByyFilter(this.pageNumber,true, filters);
    })
  ).subscribe(res => {
    this.setDataPageByFilter(res,this.pageNumber);
  });

 }

 onItemChange(value){   
  this.isReissued = value;
  this.setPage(1,true);

  }


 setPage(page: number,isFilter: boolean = false) {    
  var t = this;
  t.ddsTruckerLoader = true;
  t.pageNumber = page;
    t.ddsFiles = [];
        t.ddsCompleteFileList =[];
        t.ddsFilesPaged=[];
    this.ddsService.getDDSInventory(this.startDate,this.endDate,this.isReissued,this.pageNumber,this.pageSize).then(function (result){           
      if(result.inventoryList.length >0){
      for (let item of result.inventoryList) {            
        t.ddsCompleteFileList.push(item);  
      }  
    t.ddsFiles = t.ddsCompleteFileList;
    t.inventoryCount = result.pageCount;
    t.pageNumber = page;
    t.pager = t.pagerService.getPager(t.inventoryCount, t.pageNumber);    
    t.ddsFilesPaged = t.ddsFiles;
   
      }
      else
      {
        t.ddsFiles = [];
        t.ddsCompleteFileList =[];
        t.ddsFilesPaged=[];
        t.inventoryCount = 0;
    t.pageNumber = 1;
    t.pager = t.pagerService.getPager(t.inventoryCount, t.pageNumber);    
    t.ddsFilesPaged = t.ddsFiles;
      }
      t.ddsTruckerLoader = false;
  }).catch(err =>{
    console.log(err);
    t.ddsTruckerLoader = false;
  }); 
      
}

setPageByFilter(page: number,isFilter: boolean = false, filters:any) {    
  var t = this;
  t.ddsTruckerLoader = true;
    t.ddsFiles = [];
        t.ddsCompleteFileList =[];
        t.ddsFilesPaged=[];
        t.pageNumber = page;
    this.ddsService.getDDSInventoryByFilter(this.startDate,this.endDate,this.isReissued,this.pageNumber,this.pageSize,filters).then(function (result){           
      if(result.inventoryList.length >0){
      for (let item of result.inventoryList) {            
        t.ddsCompleteFileList.push(item);  
      }  
    t.ddsFiles = t.ddsCompleteFileList;
    t.inventoryCount = result.pageCount;
    t.pageNumber = page;
    t.pager = t.pagerService.getPager(t.inventoryCount, t.pageNumber);    
    t.ddsFilesPaged = t.ddsFiles;
   
      }
      else
      {
        t.ddsFiles = [];
        t.ddsCompleteFileList =[];
        t.ddsFilesPaged=[];
        t.inventoryCount = 0;
    t.pageNumber = 1;
    t.pager = t.pagerService.getPager(t.inventoryCount, t.pageNumber);    
    t.ddsFilesPaged = t.ddsFiles;
      }
      t.ddsTruckerLoader = false;
  }).catch(err =>{
    console.log(err);
    t.ddsTruckerLoader = false;
  }); 
      
}

getFiltersArray(){
  var filters = {};
  if(null != this.feedLoadGroupFilter && undefined != this.feedLoadGroupFilter
  && "" != this.feedLoadGroupFilter){
    filters["feedloadgroup"] = this.feedLoadGroupFilter;
  }
  if(null != this.fileNameFilter && undefined != this.fileNameFilter 
      && "" != this.fileNameFilter){
    filters["filename"] = this.fileNameFilter;
  }
  if(null != this.lastModifiedDateFilter  && undefined != this.lastModifiedDateFilter 
  && "" != this.lastModifiedDateFilter){
       filters["lastmodifieddate"] = this.lastModifiedDateFilter;
  }
  if(null != this.s3BucketPathFilter && undefined != this.s3BucketPathFilter
  && "" != this.s3BucketPathFilter){
    filters["s3bucketpath"] = this.s3BucketPathFilter;
  }
  if(null != this.versionNumberFilter && undefined != this.versionNumberFilter
  && "" != this.versionNumberFilter){
    filters["versionnumber"] = this.versionNumberFilter;
  }
  if(null != this.ddsPathFilter && undefined != this.ddsPathFilter
  && "" != this.ddsPathFilter){
    filters["ddspath"] = this.ddsPathFilter;
  }
  return filters;
}
onfeedLoadGroupFilterKey(event: any) { 
  if(event.target.value.length <=1 && this.pageNumber>1){
    this.pageNumber = 1;
  }
  this.feedLoadGroupFilter = event.target.value;
  var filters = this.getFiltersArray();
  this.setPageByFilter(this.pageNumber,true,filters);
}

onPageByyFilter(page: number,isFilter: boolean = false, filters:any){
  return this.ddsService.getDDSInventoryByFilter(this.startDate,this.endDate,this.isReissued,this.pageNumber,this.pageSize,filters)
}
setDataPageByFilter(result,page){
  this.ddsTruckerLoader = true;
  this.ddsFiles = [];
  this.ddsCompleteFileList =[];
  this.ddsFilesPaged=[];
  this.pageNumber = page;
  if(result.inventoryList.length >0){
    for (let item of result.inventoryList) {            
      this.ddsCompleteFileList.push(item);  
    }  
    this.ddsFiles = this.ddsCompleteFileList;
    this.inventoryCount = result.pageCount;
    this.pageNumber = page;
    this.pager = this.pagerService.getPager(this.inventoryCount, this.pageNumber);    
    this.ddsFilesPaged = this.ddsFiles; 
    }
    else
    {
      this.ddsFiles = [];
      this.ddsCompleteFileList =[];
      this.ddsFilesPaged=[];
      this.inventoryCount = 0;
      this.pageNumber = 1;
      this.pager = this.pagerService.getPager(this.inventoryCount, this.pageNumber);    
      this.ddsFilesPaged = this.ddsFiles;
    }
    this.ddsTruckerLoader = false;
}
onfileNameFilterKey(event:any){
  if(event.target.value.length <=1 && this.pageNumber>1){
    this.pageNumber = 1;
  }
  this.fileNameFilter = event.target.value;
  var filters = this.getFiltersArray();
  
  
}
onlastModifiedDateFilterKey(event: any) { 
  if(event.target.value.length <=1 && this.pageNumber>1){
    this.pageNumber = 1;
  }
  this.lastModifiedDateFilter = event.target.value;
  var filters = this.getFiltersArray();
  this.setPageByFilter(this.pageNumber,true,filters);
}
ons3BucketPathFilterKey(event: any) { 
  if(event.target.value.length <=1 && this.pageNumber>1){
    this.pageNumber = 1;
  }
  this.s3BucketPathFilter = event.target.value;
  var filters = this.getFiltersArray();
  this.setPageByFilter(this.pageNumber,true,filters);
}

onversionNumberFilterKey(event: any) {
  if(event.target.value.length <=1 && this.pageNumber>1){
    this.pageNumber = 1;
  } 
  this.versionNumberFilter = event.target.value;
  var filters = this.getFiltersArray();
  this.setPageByFilter(this.pageNumber,true,filters);
}

onddsPathFilterrKey(event: any) { 
  if(event.target.value.length <=1 && this.pageNumber>1){
    this.pageNumber = 1;
  }
  this.ddsPathFilter = event.target.value;
  var filters = this.getFiltersArray();
  this.setPageByFilter(this.pageNumber,true,filters);
}

 Download(bucketPath,filename,versionId):void{
  var urlParts = bucketPath.split("/");
  var bucketName = urlParts[0];
  var filePath = "";
  if(urlParts.length > 1){
       for(var counter = 1; counter < urlParts.length; counter++){
         filePath+= urlParts[counter]+"/" ;
       }
  }
  filePath+=filename;


  this.s3ManagerService.getS3Data(bucketName,filePath,versionId,JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('RestMessage'),this.passphrase).toString(CryptoJS.enc.Utf8))).then(result=>{
       saveAs(new Blob([result.Body.toString()], {type: 'appilication/csv;charset=utf-8;'}),filename);
    
},onerror =>{
    console.log(onerror);  
 });

 
}
public SelectFileToCompare(dds:DDSFile){
  var similarFiles = [];
  this.ddsService.getDDSInventoryVersion(dds.fileName).then(function (result){
    for (let item of result) {            
      similarFiles.push(item);        
    }  
  });
  // var fileVersions = this.ddsFilesPaged.filter(fv=> fv.ddsPath == dds.ddsPath &&
  // fv.fileName == dds.fileName );
 
  //  fileVersions.forEach(file=>{
  //    var fileToCompare = {
  //     fileName : file.fileName,
  //     s3BucketPath: file.s3BucketPath,
  //     versionNumber: file.versionNumber,
  //     versionId: file.versionId,
  //     isSelected: false
  //    };
  //    similarFiles.push(fileToCompare);
  //  })
  
  let dialogRef = this.dialog.open(DdstrackerVersionComparerComponent, {
    width: '1200px',
    data: similarFiles     
});

}

Exportxls():void{
  var t = this;
  var event: any = {eventName: "Export DDSInventory Data", inputParameters: [{Name : "startDate" , Value: this.startDate},{Name : "endDate" , Value: this.endDate},{Name : "isReissued" , Value: this.isReissued}]};
   this.biService.addEvent(event);  
   
   AWS.config.update({
    region: environment.region
}); 
var that = this;
var s3Credentials = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('RestMessage'),this.passphrase).toString(CryptoJS.enc.Utf8));   
this.ddsService.getDDSInventoryData(this.startDate,this.endDate,this.isReissued).then(dataUrl=>{
    var s3Url = dataUrl;
    AWS.config.update({
     region: environment.region,
     accessKeyId: s3Credentials.AccessKeyId,
     secretAccessKey: s3Credentials.SecretKey,
     sessionToken: s3Credentials.SessionToken
  });
var s3 = new AWS.S3({
});
  var urlParts = s3Url.split("/");

  var getParams = {
    Bucket: urlParts[urlParts.length - 3],
    Key: urlParts[urlParts.length - 2]+"/"+urlParts[urlParts.length - 1]
 }
 s3.getObject(getParams,function(err,data){
    if(err){
      console.log(err);
      return;
     }else{
        that.exportService.exportData(JSON.parse(data.Body.toString()),"DDSInventoryData");
     }
 });

}); 
     
}

// ExportXls(event):void{
//   var event: any = {eventName: "Export DDSInventory Data", inputParameters: [{Name : "startDate" , Value: this.startDate},{Name : "endDate" , Value: this.endDate},{Name : "isReissued" , Value: this.isReissued}]};
//   this.biService.addEvent(event);
//         var inputData = {
//           "exportType": "xls",
//           "code": "dds-getDDSInventoryData",
//           "xlsFileName": "DDSInventoryData",
//           "startDate": this.startDate,
//           "endDate": this.endDate,
//           "isReissued": this.isReissued
//         };
//         this.idwChartExportService.export(inputData); 
//   }
}

@Component({
  selector: 'ddstracker-version-comparer',
  templateUrl: './ddstracker-version-comparer.component.html'
})
export class DdstrackerVersionComparerComponent {
  constructor(private ddsService: DdsService,private s3ManagerService:S3ManagerService
    ,public dialogRef: MatDialogRef<DdstrackerVersionComparerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){
          this.versionsAccepted =  data;
          if (sessionStorage.getItem("UserRoles") != environment.role) {
            this.columnVisible =false;
          }
  }
  versionsAccepted: any[]= [];
  comparisonResult:string = "";
  validationMessage:string = "";
  isDownload: Boolean = false;
  columnVisible:boolean =true;
  passphrase: string = "EncryptionatRest";
  public checkForDifferences(){
   var selectedVersions = [];
   this.versionsAccepted.forEach(element =>{
       if(element.isSelected == true){
         selectedVersions.push(element);
       }
   });
   if(selectedVersions.length!= 2){
     this.validationMessage = "Exactly two files need to be selected";
   }
   else if(selectedVersions.length == 2 && (selectedVersions[0]["versionId"] == undefined ||
   selectedVersions[0]["versionId"] == "" || selectedVersions[1]["versionId"] == undefined ||
   selectedVersions[0]["versionId"] == "")){
    this.validationMessage = "One Selected File or more has no versionid, please select a valid file"; 
   }else{
     this.validationMessage = "";
    this.ddsService.getFilesDifference(selectedVersions[0]["s3BucketPath"],selectedVersions[0]["fileName"],selectedVersions[0]["versionId"],
    selectedVersions[1]["s3BucketPath"],selectedVersions[1]["fileName"],selectedVersions[1]["versionId"]).then(res=>{
     this.comparisonResult = res.data;
     if(res.data != "No Difference Between files"){
       this.isDownload = true;
     }else{
       this.isDownload = false;
     }
 }).catch(err=>{
   console.log(err);
   this.validationMessage = "Some errors happend while validating the files";
 })
   }
  
  }

 

  onClose(): void {
    this.dialogRef.close();
}

DownloadDelta():void{
  this.Download(this.comparisonResult);
}
Download(filedestination):void{
  var urlParts = filedestination.split("/");
  var bucketName = urlParts[0];
  var filePath = "";
  var fileName = urlParts[urlParts.length - 1];
  for(var counter = 1 ;counter< urlParts.length;counter++){
    filePath+= urlParts[counter];
    if(counter < (urlParts.length - 1))
    {
      filePath+="/";
    }
  }
  this.s3ManagerService.getRawData(bucketName,filePath,JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('RestMessage'),this.passphrase).toString(CryptoJS.enc.Utf8))).then(result=>{
       saveAs(new Blob([result.Body.toString()]),fileName);
    
},onerror =>{
    console.log(onerror);  
 });
}

DownloadFile(bucketPath,filename,versionId):void{
  var urlParts = bucketPath.split("/");
  var bucketName = urlParts[0];
  var filePath = "";
  if(urlParts.length > 1){
       for(var counter = 1; counter < urlParts.length; counter++){
         filePath+= urlParts[counter]+"/" ;
       }
  }
  filePath+=filename;


  this.s3ManagerService.getS3Data(bucketName,filePath,versionId,JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('RestMessage'),this.passphrase).toString(CryptoJS.enc.Utf8))).then(result=>{
       saveAs(new Blob([result.Body.toString()], {type: 'appilication/csv;charset=utf-8;'}),filename);
    
},onerror =>{
    console.log(onerror);  
 }); 
}



}


