import { Component, ElementRef, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { ChartOptions } from 'chart.js';
import Chart from 'chart.js';
import 'chartjs-plugin-labels'

@Component({
  selector: 'app-report-breakdown-chart',
  templateUrl: './report-breakdown-chart.component.html',
  styleUrls: ['./report-breakdown-chart.component.scss']
})
export class ReportBreakdownChartComponent implements OnInit, OnChanges {

  @Input() data: number[];
  @Input() labels: string[];
  @Input() colorsInput: string[] = [];
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement> | undefined;

  constructor() { }
  private pieChartOptions: ChartOptions = {
    responsive: true,
    aspectRatio: 1,
    devicePixelRatio: 3,
    plugins: {
      labels: {
        render: 'percentage',
        fontSize: 12,
        fontColor: 'black',
        fontStyle: 'normal',
        fontFamily: "Arial",
        position: 'outside',
        outsidePadding: 6,
        textMargin: 7
      }
    }
  };

  ngOnInit(): void {
    this.chartSetup();
  }

  ngOnChanges(changes: any) {

    console.log(this.data);
    console.log(changes);

    this.chartSetup();
  }

  private chartSetup() {
    Chart.plugins.register({
      beforeDraw: function (chartInstance) {
        const ctx = chartInstance.chart.ctx;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
      }
    });
    const optionsPie = {
      type: 'pie',      
      data: {
        labels: this.labels,
        datasets: [{
          data: this.data,
          backgroundColor: this.colorsInput,
          borderColor: ["#FFFFFF","#FFFFFF"],
            borderWidth: 1          
        }]
      },
      options: {
        ...this.pieChartOptions,
        legend: { display: false },
      }
    };
    // tslint:disable-next-line:no-unused-expression
    new Chart(this.canvas.nativeElement, optionsPie);
  }
}
