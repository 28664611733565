import {Injectable, OnDestroy} from '@angular/core';
// tslint:disable-next-line:import-blacklist
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {BenchmarkParams} from '../models/benchmark';

type GeneralError = any;

export interface ExpandedElement<T = GeneralError> {
  data?: T;
  error?: GeneralError;
  id?: BenchmarkParams;
}

@Injectable({
  providedIn: 'root'
})
export class ExpendableDataService<T> implements OnDestroy {
  private id$: BehaviorSubject<BenchmarkParams | null> = new BehaviorSubject<BenchmarkParams | null>(null);
  private data$ = new BehaviorSubject<ExpandedElement<T>>({});
  private fetch: (arg: BenchmarkParams) => Observable<T>;
  private subscription;
  fetchData(id: BenchmarkParams): void {
    this.id$.next(id);
  }

  getData$(): Observable<ExpandedElement<T>> {
    return combineLatest([
      this.data$.asObservable(),
      this.id$
    ]).pipe(
      map(([data, id]) => ({
        ...data,
        id,
        data: data.id === id ? data.data : null,
        error: data.id === id ? data.error : null
      }))
    );
  }

  init(fetch: (arg: BenchmarkParams) => Observable<T>) {
    this.fetch = fetch;

    this.subscription = this.id$.pipe(
      filter((id) => id !== null),
      switchMap(id => this.fetch(id)),
    ).subscribe(
      data => this.updateState(data as any),
      error => this.createErrorState(error),
    );
  }

  private updateState(data: T): void {
    this.data$.next({
      id: this.id$.value,
      data,
    });
  }

  private createErrorState(error: void): void {
    this.data$.next({
      error,
      id: this.id$.value,
    });
  }

  ngOnDestroy() {
    // tslint:disable-next-line:no-unused-expression
    this.subscription && this.subscription.unsubscribe && this.subscription.unsubscribe();
  }
}
