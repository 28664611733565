import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewMetricForm } from '../../models/metric';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report } from '../../models/report';
import { formatDate, DatePipe } from '@angular/common';
import { MasterDataService } from '../../services/masterdatapack.service';
import { debug } from 'console';
import { NgClass } from '@angular/common';

@Component({  
  selector: 'app-new-masterdata-popup',
  templateUrl: './new-masterdata-popup.component.html',
  styleUrls: ['./new-masterdata-popup.component.scss']
})
export class NewMasterPopupComponent implements OnInit {

  form: FormGroup = this.fb.group({
    indexCode: ['', Validators.required],
    index: [''],
    indexFamilyId: [''],
    indexFamily: [''],
    parentIndexCode: ['', Validators.required],
    parentIndex: [''],
    assetClass: ['', Validators.required],
    comments: [''],
    urgentFlag: [false],
  } as Record<keyof NewMetricForm, any[]>);

  assetClassSelection: string;
  indexName: string;
  parentIndexName: string;
  indexFamilyName: string;
  updateReport: boolean = false;
  isreportForm = false;
  isreportFormFamily:boolean =  false;
  parentData: any;
  isUrgent: boolean = false;
  isFamilyReadOnly: boolean = false;
  isIndexReadOnly: boolean = false;
  isParentIndexReadOnly: boolean = false;

  constructor(private fb: FormBuilder, private masterdataservice: MasterDataService, public dialogRef: MatDialogRef<NewMasterPopupComponent>, @Inject(MAT_DIALOG_DATA) public rowdata: any) {

    console.log(rowdata);

  }

  ngOnInit(): void {
    if (this.rowdata) {
      this.isreportFormFamily = false;
      this.updateReport = true;
      this.form.patchValue({
        indexCode: this.rowdata.IndexCode,
        index: this.rowdata.Index,
        indexFamilyId: this.rowdata.IndexFamilyId,
        indexFamily: this.rowdata.IndexFamily,
        parentIndexCode: this.rowdata.ParentIndexCode,
        parentIndex: this.rowdata.ParentIndex,
        assetClass: this.rowdata.AssetClass,
        comments: this.rowdata.Comments
      })
      this.isUrgent = this.rowdata.UrgentFlag;
      this.indexName = this.rowdata.Index;
      this.parentIndexName = this.rowdata.ParentIndex;
      this.indexFamilyName = this.rowdata.IndexFamily;
      this.isFamilyReadOnly = this.rowdata.IndexFamily == undefined ? false : true;
      this.isIndexReadOnly = this.rowdata.Index == undefined ? false : true;
      this.isParentIndexReadOnly = this.rowdata.ParentIndex == undefined ? false : true;
    }

  }

  public onClickHandler(event: any): void {
    this.isUrgent = event.checked;
  }
  assetClassChange(assetClass: string) {
    debugger;
    this.assetClassSelection = assetClass;   
    if(this.assetClassSelection == "Equity")
      {
        this.isreportFormFamily = true;
        this.form.controls['indexFamilyId'].setValidators([Validators.required]);         
        this.form.controls['indexFamilyId'].enable();   
        this.form.controls['indexFamily'].enable();
        this.form.controls['indexFamilyId'].updateValueAndValidity();   
                 
      }    
      else
      {
        this.isreportFormFamily = false;      
        this.form.controls['indexFamilyId'].clearValidators();
        this.form.controls['indexFamilyId'].disable();
        this.form.controls['indexFamily'].disable();
        this.form.controls['indexFamilyId'].updateValueAndValidity(); 
           

      }      
   }

  onSubmit(): void {
      if(this.assetClassSelection == "Equity")
      {
        this.isreportFormFamily = true;    
        if (this.form.valid && this.isreportFormFamily) {
          const data = this.form.getRawValue();
          data.urgentFlag = this.isUrgent;    
          this.dialogRef.close(data);    
        }        
      }
      else
      {
        this.isreportFormFamily = false;
        if (this.form.valid && !this.isreportFormFamily) {
          const data = this.form.getRawValue();
          data.urgentFlag = this.isUrgent;    
          this.dialogRef.close(data); 
        }
      }
    

    if (this.form.invalid  || this.form.controls['indexFamilyId'].invalid ) {
           this.isreportForm = true;          
      debugger; 
     
      return;
    }
  }
  getIndexName(event: any) {
    if(event.target.value !='')
    {
    this.masterdataservice.validateSIMetricIndex(event.target.value).then(data => {
      if (data != undefined) {
        this.isIndexReadOnly = true;
        this.indexName = data;
      }
      else {
        this.indexName = '';
        this.isIndexReadOnly = false;
      }
    });
    }
  }
  isValidParentIndexCode(event: any) {
    if(event.target.value !='')
    {
      this.masterdataservice.validateSIMetricIndex(event.target.value).then(data => {
        const rawData = this.form.getRawValue();
        if (rawData.indexCode.toLowerCase() == event.target.value.toLowerCase())
        {
          alert('Index code and Parent Index code must be different');
          this.parentIndexName = '';
          event.target.value = '';
          return;
        }
      });
      }
  }
  getParentIndexName(event: any) {
    if(event.target.value !='')
    {
      this.masterdataservice.validateSIMetricIndex(event.target.value).then(data => {
        if (data != undefined) {        
          this.parentIndexName = data;
          this.isParentIndexReadOnly = true;
        }
        else {
          this.parentIndexName = '';
          this.isParentIndexReadOnly = false;
        }
      });
      }
  }
  getIndexFamilyName(event: any) {
    if(event.target.value !='')
    {
    this.masterdataservice.validateSIMetricsIndexFamily(event.target.value).then(data => {
      if (data != undefined) {
        this.isFamilyReadOnly = true;
        this.indexFamilyName = data;
      }
      else {
        this.indexFamilyName = '';
        this.isFamilyReadOnly = false;
      }
    });
    }
  }
}
