import { Injectable } from '@angular/core';
import {GeneralError} from '../models/common';
import {MatSnackBarConfig} from '@angular/material/snack-bar/snack-bar-config';
import {ErrorNotificationComponent} from '../components/error-notification/error-notification.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private matSnackBar: MatSnackBar) { }

  showError (error: GeneralError) {
    const config: MatSnackBarConfig = {
      data: error,
      duration: 10000,
      panelClass: 'pbr-snack-container'
    };
    this.matSnackBar.openFromComponent(ErrorNotificationComponent, config);
  }
}
