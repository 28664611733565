import { Component, OnInit, Input, Inject, NgZone, ViewChild,AfterViewInit } from '@angular/core';
import { ValuesService } from '../../services/values.service';
import { PublishIndexMarkerService } from '../../services/publish-index-marker.service';
import { ValuePageModel } from '../../models/value-page-model';
import { IndexMarkerService } from '../../services/indexmarker.service';
import { ExportToExcelService } from '../../services/exportToExcel.service';
import { MagellanColorService } from '../../services/magellancolor.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateService } from '../../services/date.service';
import { BiService } from '../../services/BiService.service';
import { IDWChartExportService } from '../../services/idw-chart-export.service';
import { ChartTypeComponent } from '../chart-type/chart-type.component';

@Component({
  selector: 'values-market-cap',
  templateUrl: './values-market-cap.component.html'
})
export class ValuesMarketCapComponent implements OnInit,AfterViewInit{

  valuesMarketcapChartID: string = "valuesMarketcapChartID";
  asofDates: any;
  indexMarkers: any;
  marketCapChart: any;
  @Input() currencyCode: string;
  chartAttributes: any;
  dataSource: any;
  marketCapData : any;
  MarketCapChartLoader:boolean=true; 
  indexValuesMSChart : any;
  colors : any;
  popupDataSource:any;
  chart:any;
 
  @ViewChild(ChartTypeComponent,{static: false}) chartTypeComponent;
  defaultActiveChart: string = "bar2d";

  datasetType = "simple";
  iscarousel: boolean = false;
  constructor(private valuesService: ValuesService,private dateService: DateService, private exportService: ExportToExcelService
    ,private publishIndexMarkerService:PublishIndexMarkerService,private indexMarkerService:IndexMarkerService,
     private magellanColorService : MagellanColorService,public dialog: MatDialog,private biService: BiService,
     private zone:NgZone,private idwChartExportService: IDWChartExportService) { }

  openDialog(): void{ 
    var event: any = {eventName: "Market Cap dialog open"};
    this.biService.addEvent(event);
      let dialogRef = this.dialog.open(ValuesMarketCapComponentDialog, {
        width: '80%',
        height: '85%',
          data: this.popupDataSource
        });
      }

    


  openWidgetDialog(): void {
    var event: any = { eventName: "MarketCap help dialog open" };
    this.biService.addEvent(event);
    let dialogRef = this.dialog.open(ValuesMarketCapComponentWidgetDialog, {
      width: '800px',
      data: this.indexMarkers
    });
  }

  chartInitialize(event) {
    return (eve, arg) => {
      this.zone.run(() => {
        this.chart = eve.sender;
      });

    };

  }

  events = {
    initialized: this.chartInitialize(event)
  }

  ngOnInit() {  
    this.colors = this.magellanColorService.getCurrentColorSchema();
    this.chartAttributes = {
      "exportEnabled": "1",
      "exportShowMenuItem": "0",
      "paletteColors": this.colors,
      "bgColor": "#ffffff",
      "showBorder": "0",
      "showCanvasBorder": "0",
      "usePlotGradientColor": "0",
      "showalternatehgridcolor": "0",
      "showplotborder": "0",
      "plotBorderAlpha": "10",
      "showValue": "0",
      "showValues": "0",
      "axisLineAlpha": "25",
      "divLineAlpha": "10",
      "alignCaptionWithCanvas": "0",
      "showAlternateVGridColor": "0",
      "captionFontSize": "14",
      "subcaptionFontSize": "14",
      "subcaptionFontBold": "0",
      "toolTipColor": "#ffffff",
      "toolTipBorderThickness": "0",
      "toolTipBgColor": "#000000",
      "toolTipBgAlpha": "80",
      "toolTipBorderRadius": "2",
      "toolTipPadding": "5",
      "chartRightMargin": "3",
      "showLegend": "1",
      "legendPosition": "right",
      "legendBorderColor": "#ffffff",
      "legendBorderThickness": "0",
      "legendBorderAlpha": "0",
      "legendShadow": "0",
      "chartLeftMargin": "0",
      "chartTopMargin": "5",
      "formatNumberScale": "0"
    };
    this.magellanColorService.colorsPublish().subscribe(color => {
      this.colors = color;
      this.chartAttributes.paletteColors = this.colors;
    });
   
  
    this.indexMarkers = this.indexMarkerService.getIndexMarkers();
    this.asofDates = this.dateService.getTransactionDate();
    
  }

  ngAfterViewInit(){
    this.chartTypeComponent.defaultChartType=this.defaultActiveChart;
    this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
      this.MarketCapChartLoader=true;
      this.indexMarkers=result;
      this.chartSetup(result);
    });
  }

  onChartTypeChange(chart: string) {
    this.defaultActiveChart = chart;
    if (chart == 'column2d' || chart == 'bar2d' || chart == 'line') {
      this.chartAttributes.chartLeftMargin = "0";
      this.chartAttributes.chartRightMargin = "3";
      this.chartAttributes.chartTopMargin = "5";
      this.chartAttributes.use3DLighting = "0";
      this.chartAttributes.showLabels = 1;
    }
    else {
        this.chartAttributes.showLabels = 0;
        this.chartAttributes.chartLeftMargin = "-20";
        this.chartAttributes.chartRightMargin = "-10";
        this.chartAttributes.chartTopMargin = "-15";
        this.chartAttributes.use3DLighting = "0";

    }
    this.chartSetup(this.indexMarkers);
  }

  update(valuePageMode: ValuePageModel) {
    this.MarketCapChartLoader = true;
    this.currencyCode = valuePageMode.currencyCode;
    this.asofDates = valuePageMode.selectedDates.end;
    this.chartSetup(this.indexMarkers);
  }

  private chartSetup(indexMarkers: any) {
    this.valuesService.getIndexValuesMSChart(this.asofDates, indexMarkers, this.currencyCode).then(data => {
     // this.marketCapChart = this.reallignWithIndexMarkers(data);
      if (0 < data.data.dataset.length) {
        this.marketCapData = data;
        this.bindMarketCap(data.data);
        
      }
      else {
        this.dataSource = {};
      }
    }, onerror => {
      this.MarketCapChartLoader = false;
    });

   

  }

  bindMarketCap(data: any) {
    this.dataSource = {
      "chart": this.chartAttributes,
      "categories": data.categories,
      "data": data.dataset[0].data,
      "chartType": this.defaultActiveChart
    }
    this.popupDataSource = {
      "dataSource": this.dataSource,
      "asOfDate": this.asofDates
   } ; 
    this.MarketCapChartLoader = false;
  }

  private reallignWithIndexMarkers(data){
      var associativeCategories = [];
      var associativeMultidatasets = [];
      for(var category of data["data"]["categories"][0]["category"]){
        associativeCategories[category.label] = category;
      }
      for(var dataset of data["data"].dataset[0]["data"]){
        associativeMultidatasets[dataset.label] = dataset;
      }
      data["data"]["categories"][0]["category"] = [];
      data["data"].dataset[0]["data"] = [];
      for(var indexmarker of this.indexMarkers){
        data["data"]["categories"][0]["category"].push(associativeCategories[indexmarker]);
        data["data"].dataset[0]["data"].push(associativeMultidatasets[indexmarker]);
      }
      return data;
  }
  ExportXls(event): void {
    var event: any = { eventName: "Export Values Market Cap", inputParameters: [{ Name: "CurrencyCode", Value: this.currencyCode }] };
    this.biService.addEvent(event);
    var inputData = {
      "exportType": "xls",
      "code": "value-getIndexValuesMSChartData",
      "xlsFileName": "MarketCapRawData",
      "asOfDate": this.asofDates,
      "indexMarkers": this.indexMarkers,
      "currencyCode": this.currencyCode
    };
    this.idwChartExportService.export(inputData);
  }

  ExportPNG(event): void {
    var event: any = { eventName: "Export Values Market Cap Chart TO PNG", inputParameters: [{ Name: "CurrencyCode", Value: this.currencyCode }] };
    this.biService.addEvent(event);
    var inputData = {
      "exportType": "png",
      "legend": false,
      "dataSource": this.popupDataSource.dataSource,
      "chartid": "indexValuesMSChart",
      "type": this.defaultActiveChart,
      "targetDiv": "exportPngDiv",
      "isIndexLegend": false,
      "chartReference": this.chart,
      "pngFileName": "MarketCapRawData",
      "chartTitle": "Market Cap",
      "chartDate": this.asofDates
    };
    this.idwChartExportService.export(inputData);
  }
}


@Component({
  selector: 'values-market-cap-dialog',
  templateUrl: './values-market-cap.component.dialog.html'
})
export class ValuesMarketCapComponentDialog {

  constructor(
    public dialogRef: MatDialogRef<ValuesMarketCapComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    onClose(): void {
        this.dialogRef.close();
  }
}


@Component({
  templateUrl: './values-market-cap.component.widget.dialog.html'
})
export class ValuesMarketCapComponentWidgetDialog {

  @Input() indexes: string;
  constructor(
    public dialogRef: MatDialogRef<ValuesMarketCapComponentWidgetDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.indexes = "";
    data.forEach(element => {
      if (this.indexes.length < 3) {
        this.indexes = element;
      } else {
        this.indexes += ", " + element;
      }
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}