
import {map} from 'rxjs/operators';
import { Injectable} from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import { ReviewProducts } from '../models/review-products.model';
import { of , BehaviorSubject ,  Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ResponseModel} from '../models/landing-page-model';

import { ReviewSchedule } from '../models/review-schedule.model';
import {ReviewScheduleDropdowns} from '../models/review-schedule-dropdowns.model'

@Injectable()
export class ReviewsService
{

    constructor(private httpClient: HttpClient){}

    getReviewProducts() : Promise<ReviewProducts[]> {   
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.httpClient.post(environment.dataServiceRoot + 'ui/reviews/getreviewproducts',
        null, {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any 
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var products = result.data as any[];                    
                    return products
                  })
              .catch(this.handleError);        
    }

    private handleError(error: any): Promise<any>
    {
        console.error('error occured',error);
        return Promise.reject(error.message || error);
    }

    getReviewSchedule(): Promise<ReviewSchedule[]> {   
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reviews/getreviewschedules',
        null, {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var schedules = result.data as any[];                    
                    return schedules
                  })
              .catch(this.handleError);      
    }


    calculateDate(date: string): Promise<any> {   
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reviews/calculateDate',
        JSON.stringify({Date: date}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as any;
                    var schedules = result.data as any;                    
                    return schedules
                  })
              .catch(this.handleError);      
    }

    //getReviewScheduleDropdown(): Promise<ReviewScheduleDropdowns[]> { 
        getReviewScheduleDropdown(): Promise<ReviewScheduleDropdowns>{   
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reviews/GetReviewScheduleDropdown',
        null, {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var schedules = result.data as any[];                    
                    return schedules
                  })
              .catch(this.handleError);      
    }


}