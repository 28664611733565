import { Component, OnInit } from '@angular/core';
import { AdminReportService } from '../../services/adminreportservice';
import { ReportData } from '../../models/report-data.model';
import { MissingIndex } from '../../models//missing-index.model';
import { BiService } from '../../services/BiService.service';
import { BiEvent } from '../../models/events'
@Component({
  selector: 'app-apireport',
  templateUrl: './apireport.component.html',
  styleUrls: ['./apireport.component.css']
})
export class APIReportComponent implements OnInit {
  reportData: ReportData[] = [];
  didFail: any;
  missingIndex: MissingIndex[] = [];
  reportdidFail = false;
  missingIndexdidFail = false;
  ConstituentListProforma = false;
  ConstituentPositionsProforma = false;
  ConstituentListOpen = false;
  ConstituentPositionsOpen = false;
  ConstituentListClose = false;
  ConstituentPositionsClose = false;
  IndexMasterOpen = false;
  IndexMasterClose = false;
  IndexLevelOpen = false;
  IndexLevelClose = false;
  ExchangeRates = false;
  AsOfDateClose = '';
  AsOfDateOpen = '';
  ConstituentListProformaCount = '0';
  ConstituentPositionsProformaCount = '0';
  ConstituentsListOpenCount = '0';
  ConstituentsListClosedCount = '0';
  ConstituentsPositionsOpenCount = '0';
  ConstituentsPositionsClosedCount = '0';
  IndexMasterOpenCount = '0';
  IndexMasterClosedCount = '0';
  IndexLevelOpenCount = '0';
  IndexLevelClosedCount = '0';
  ExchangeRatesCount = '0';
  today = new Date(Date.now());
  inputdate = '';

  constructor(private AdminReportService: AdminReportService, private biService: BiService) { }

  ngOnInit() {
    this.today.setDate(this.today.getDate() - 1);
    this.inputdate = this.today.toISOString().split('T')[0];
    this.AdminReportService.getReportData(this.inputdate);
    this.AdminReportService.reportdataLoaded.subscribe(
      (data: ReportData[]) => {
        this.reportData = data;
        this.fillData();
      }
    );
    this.AdminReportService.reportdataLoadFailed.subscribe(
      (didFail: boolean) => this.reportdidFail = didFail
    );

    this.AdminReportService.getMissingIndex(this.inputdate);

    this.AdminReportService.missingIndexLoaded.subscribe(
      (data: MissingIndex[]) => {
        this.missingIndex = data;
        this.fillData();
      });
    this.AdminReportService.missingIndexFailed.subscribe(
      (didFail: boolean) => this.missingIndexdidFail = didFail
    );
  }

  getResults() {
    this.biService.addEvent2("ApiReport GetData", [{ Name: "inputdate", Value: this.inputdate }]);
    this.AdminReportService.getReportData(this.inputdate);
    this.AdminReportService.getMissingIndex(this.inputdate);
  }

  fillData(): void {

    this.AsOfDateClose = this.inputdate;
    this.AsOfDateOpen = this.inputdate;

    if (this.reportData !== null && this.reportData.length !== 0) {
      this.reportdidFail = false;
      this.ConstituentListProforma = false;
      this.ConstituentPositionsProforma = false;
      this.ConstituentListOpen = false;
      this.ConstituentPositionsOpen = false;
      this.ConstituentListClose = false;
      this.ConstituentPositionsClose = false;
      this.IndexMasterOpen = false;
      this.IndexMasterClose = false;
      this.IndexLevelOpen = false;
      this.IndexLevelClose = false;
      this.ExchangeRates = false;
      this.ConstituentListProformaCount = '0';
      this.ConstituentPositionsProformaCount = '0';
      this.ConstituentsListOpenCount = '0';
      this.ConstituentsListClosedCount = '0';
      this.ConstituentsPositionsOpenCount = '0';
      this.ConstituentsPositionsClosedCount = '0';
      this.IndexMasterOpenCount = '0';
      this.IndexMasterClosedCount = '0';
      this.IndexLevelOpenCount = '0';
      this.IndexLevelClosedCount = '0';
      this.ExchangeRatesCount = '0';

      for (var i = 0; i < this.reportData.length; i++) {
        switch (this.reportData[i].method) {
          case "IndexLevelClosed": {
            this.IndexLevelClose = true;
            this.IndexLevelClosedCount = this.reportData[i].count;
            break;
          }
          case "ConstituentPositionClosed": {
            this.ConstituentPositionsClose = true;
            this.ConstituentsPositionsClosedCount = this.reportData[i].count;
            break;
          }
          case "ConstituentPositionOpen": {
            this.ConstituentPositionsOpen = true;
            this.ConstituentsPositionsOpenCount = this.reportData[i].count;
            break;
          }
          case "ConstituentListOpen": {
            this.ConstituentListOpen = true;
            this.ConstituentsListOpenCount = this.reportData[i].count;
            break;
          }
          case "IndexMasterOpen": {
            this.IndexMasterOpen = true;
            this.IndexMasterOpenCount = this.reportData[i].count;
            break;
          }
          case "ConstituentListClosed": {
            this.ConstituentListClose = true;
            this.ConstituentsListClosedCount = this.reportData[i].count;
            break;
          }
          case "IndexMasterClosed": {
            this.IndexMasterClose = true;
            this.IndexMasterClosedCount = this.reportData[i].count;
            break;
          }
          case "ExchangeRates": {
            this.ExchangeRates = true;
            this.ExchangeRatesCount = this.reportData[i].count;
            break;
          }
          case "IndexLevelOpen": {
            this.IndexLevelOpen = true;
            this.IndexLevelOpenCount = this.reportData[i].count;
            break;
          }
          case "ConstituentPositionProforma": {
            this.ConstituentPositionsProforma = true;
            this.ConstituentPositionsProformaCount = this.reportData[i].count;
            break;
          }
          case "ConstituentListProforma": {
            this.ConstituentListProforma = true;
            this.ConstituentListProformaCount = this.reportData[i].count;
            break;
          }
        }
      }
    }
    else {
      this.ConstituentListProforma = false;
      this.ConstituentPositionsProforma = false;
      this.ConstituentListOpen = false;
      this.ConstituentPositionsOpen = false;
      this.ConstituentListClose = false;
      this.ConstituentPositionsClose = false;
      this.IndexMasterOpen = false;
      this.IndexMasterClose = false;
      this.IndexLevelOpen = false;
      this.IndexLevelClose = false;
      this.ExchangeRates = false;
      this.ConstituentListProformaCount = '0';
      this.ConstituentPositionsProformaCount = '0';
      this.ConstituentsListOpenCount = '0';
      this.ConstituentsListClosedCount = '0';
      this.ConstituentsPositionsOpenCount = '0';
      this.ConstituentsPositionsClosedCount = '0';
      this.IndexMasterOpenCount = '0';
      this.IndexMasterClosedCount = '0';
      this.IndexLevelOpenCount = '0';
      this.IndexLevelClosedCount = '0';
      this.ExchangeRatesCount = '0';
    }
  }
}