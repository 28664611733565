import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewgsxconfigurationsForm } from '../../models/gsxconfigurations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report } from '../../models/report';
import { formatDate, DatePipe } from '@angular/common';
import { gsxconfigurationsDataService } from '../../services/gsxconfigurations.service';
import { debug } from 'console';

@Component({
  selector: 'app-new-gsxconfigurations-popup',
  templateUrl: './new-gsxconfigurations-popup.component.html',
  styleUrls: ['./new-gsxconfigurations-popup.component.scss']
})
export class NewgsxconfigurationsPopupComponent implements OnInit {

 form: FormGroup = this.fb.group({
   indexCode: ['', Validators.required],
    parentIndexCode: [''],
   Currency: [''],
   Variant: [''],
   Fromparent: [''],
   Calculatepct: ['']
 } as Record<keyof NewgsxconfigurationsForm, any[]>);
  currencies: string[];
  public currenciesfilteredList: string[];
  updateReport: boolean = false;
  isreportForm = false;
  Fromparent: boolean = false;
  Calculatepct: boolean = false;
  constructor(private fb: FormBuilder, private gsxconfigurationsDataService: gsxconfigurationsDataService, public dialogRef: MatDialogRef<NewgsxconfigurationsPopupComponent>, @Inject(MAT_DIALOG_DATA) public rowdata: any) {

    console.log(rowdata);

  }

  ngOnInit(): void {
    if (this.rowdata) {
      this.updateReport = true;
      this.form.patchValue({
        indexCode: this.rowdata.IndexCode,
        parentIndexCode: this.rowdata.ParentIndexCode,
        Currency: this.rowdata.Currency,
        Variant: this.rowdata.Variant,
        Fromparent : this.rowdata.Fromparent,
        Calculatepct : this.rowdata.Calculatepct
      })
    }
   
    this.gsxconfigurationsDataService.getCurrencies().subscribe(data => {
      this.currencies = data.data;
      this.currenciesfilteredList = data.data;
    });
    this.Fromparent = this.rowdata.Fromparent;
    this.Calculatepct = this.rowdata.Calculatepct;

   
   
    //debugger;
    //if (this.rowdata.Fromparent == null) { this.Fromparent = false } else { this.Fromparent = this.rowdata.Fromparent; };
    //if (this.rowdata.Calculatepct == null) { } { this.Calculatepct = this.rowdata.Calculatepct; };
  }

  public onClickHandlerFromparent(event: any): void {

    this.Fromparent = event.checked;
  
 
  }

  public onClickHandlerCalculatepct(event: any): void {

    this.Calculatepct = event.checked;


  }

  onSubmit(): void {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      data.Fromparent = this.Fromparent;
      data.Calculatepct = this.Calculatepct;  
      this.dialogRef.close(data);
    }
    if (this.form.invalid) {
      this.isreportForm = true;
      return;
    }
  } 

  }
