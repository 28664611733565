import { Component, OnInit ,Input, Inject, NgZone, ViewChild, AfterViewInit } from '@angular/core';
import { ValuesService } from '../../services/values.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { ValuePageModel } from '../../models/value-page-model';
import {MagellanColorService} from '../../services/magellancolor.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DateService} from '../../services/date.service';
import { BiService } from '../../services/BiService.service';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import {ChartTypeComponent} from '../chart-type/chart-type.component';
import{Chart} from 'chart.js';
import { saveAs } from 'file-saver/FileSaver';
@Component({
  selector: 'values-constituent-count',
  templateUrl: './values-constituent-count.component.html'
})
export class ValuesConstituentCountComponent implements OnInit,AfterViewInit {
  
  valuesConstituteChartID:string="valuesConstituteChartID";
  ConstituentChart:any;
  asofDates : any;
  indexMarkers : any;
  @Input() currencyCode : string;  
  chartAttributes: any;  
  dataSource: any; 
  constituentCountData : any;
  ConstituentChartLoader:boolean=true;
  colors : any;
  constituentChart:any;
  popupDataSource:any;
  chart:any;
  @ViewChild(ChartTypeComponent,{static: false}) chartTypeComponent;   
    defaultActiveChart: string  = "column2d";
    datasetType = "simple";
    iscarousel: boolean= false;

  constructor(private valuesService: ValuesService,private dateService: DateService, private exportService: ExportToExcelService
    ,private indexMarkerService:IndexMarkerService ,private publishIndexMarkerService:PublishIndexMarkerService,
     private magellanColorService : MagellanColorService,private dialog: MatDialog,private biService: BiService,
     private zone:NgZone,private idwChartExportService: IDWChartExportService) { }
  
     ngAfterViewInit(){
      this.chartTypeComponent.defaultChartType=this.defaultActiveChart;
      this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{
        this.ConstituentChartLoader=true;
        this.indexMarkers=result;
        this.chartSetup(result);
       });
     }
  openDialog(): void{ 
    var event: any = {eventName: "Constituent Count dialog open"};
    this.biService.addEvent(event);
    let dialogRef = this.dialog.open(ValuesConstituentCountComponentDialog, {
      width: '80%',
      height: '85%',
	  data: this.popupDataSource
    });
  }

  openWidgetDialog():void{
    var event: any = {eventName: "Constituent count help dialog open"};
    this.biService.addEvent(event);
    let dialogRef = this.dialog.open(ValuesConstituentCountComponentWidgetDialog, {
        width: '800px' , 
        data: this.indexMarkers     
      });
  }

 
  chartInitialize(event){
    return (eve, arg) => { 
        this.zone.run(() => {
            this.chart =eve.sender;
        });  
        
    };
      
  }

  events = {
    initialized: this.chartInitialize(event)
}
  ngOnInit() {
    this.colors = this.magellanColorService.getCurrentColorSchema();
    this.indexMarkers = this.indexMarkerService.getIndexMarkers();
    this.chartAttributes = {
      "paletteColors": this.colors,
      "bgColor": "#ffffff",
      "showBorder": "0", 
      "showCanvasBorder": "0",
      "usePlotGradientColor": "0", 
      "showalternatehgridcolor": "0", 
      "showplotborder": "0",
      "plotBorderAlpha": "10", 
      "showValue": "0", 
      "showValues": "0", 
      "axisLineAlpha": "25", 
      "divLineAlpha": "10", 
      "alignCaptionWithCanvas": "0",  
      "showAlternateVGridColor": "0",  
      "captionFontSize": "14",
      "subcaptionFontSize": "14", 
      "subcaptionFontBold": "0", 
      "toolTipColor": "#ffffff", 
      "toolTipBorderThickness": "0", 
      "toolTipBgColor": "#000000",   
      "toolTipBgAlpha": "80",      
      "toolTipBorderRadius": "2", 
      "toolTipPadding": "5",  
      "chartRightMargin": "3",  
      "exportEnabled": "1",    
      "exportShowMenuItem": "0", 
      "showLegend": "1",
      "legendPosition": "right",
      "legendBorderColor": "#ffffff",
      "legendBorderThickness": "0",
      "legendBorderAlpha": "0",
      "legendShadow": "0",
      "chartLeftMargin": "0",
      "chartTopMargin": "5",
      "formatNumberScale":"0",
    };
    this.colors = this.magellanColorService.getCurrentColorSchema();
    this.magellanColorService.colorsPublish().subscribe(color => { 
      this.colors= color;   
      // this.ConstituentChart.data.datasets[0].backgroundColor= this.colors;
      // this.ConstituentChart.update();    
    });
    
  
    this.indexMarkers = this.indexMarkerService.getIndexMarkers();
    this.asofDates= this.dateService.getTransactionDate();
    

  }
	
  update(valuePageMode:ValuePageModel){ 
    this.ConstituentChartLoader=true;
    this.currencyCode = valuePageMode.currencyCode;
    this.asofDates = valuePageMode.selectedDates.end;
    this.chartSetup(this.indexMarkers);
  }

  private chartSetup(indexMarkers:any) {
   
    this.valuesService.getIndexValuesConstituentCountMSChart(this.asofDates,indexMarkers ,this.currencyCode).then(data => {      
     //this.constituentChart= this.reallignWithIndexMarkers(data);
      if(0 <  data.data.dataset.length){
      this.constituentCountData = data;
      this.bindConstituentCount(data.data);
    //  this.ConstituentChart.update();
      }
      else{
        this.dataSource = {};
      //  this.ConstituentChart.data.datasets[0].data = [];
      //  this.ConstituentChart.update();
      }
       
    },onerror =>{
        this.ConstituentChartLoader =false;       
     });
  }

  bindConstituentCount(data: any){
    this.dataSource = {
      "chart": this.chartAttributes,
      "categories": data.categories,
      "data": data.dataset[0].data,
      "chartType" : this.defaultActiveChart   
    }     
    this.popupDataSource = {
      "dataSource": this.dataSource,
      "asOfDate": this.asofDates
   } ;
//   console.log(this.defaultActiveChart)
//   var i;
//   var value: any[] = new Array();
//   var label: any[] = new Array();
//   for(i=0;i<data.dataset[0].data.length;i++)
//   {
//     value[i]=data.dataset[0].data[i].value ;
//     label[i]=data.dataset[0].data[i].label;
//   }
 
//   this.ConstituentChart = new Chart('ConstituentChart', {
//     type: this.defaultActiveChart,
//     data: {
//         labels: label,
//         datasets: [{
//             label: '',
//             barThickness: 6,
//             barPercentage: 1,
//             data: value,
//             backgroundColor: this.colors,
//             borderColor: this.colors,
//             borderWidth: 0,
//         }]
//     },
//     options: {
//         legend: {
//             display: false
//         },
//         tooltips: {      
//           callbacks: {
//             label: function(tooltipItem, data) {
//                 var value = (data.datasets[0].data[tooltipItem.index] /1000);
//                 return value.toFixed(2)+ 'K';
//             }
//       }
//       },
//         maintainAspectRatio: false,
//         scales: {
//             xAxes: [{
//                 gridLines: {
//                   display: false, // must be false since we're going to draw our own 'gridlines'!
//                   lineWidth: 5, // can still set the width.
//                 },
//                 ticks: {
//                     autoSkip: false,
//                     maxRotation: 0,
//                     minRotation: 0,
//                     fontSize: 8,
//                     fontColor: '#000000',
//                     fontFamily: 'Roboto',
//                 }
//               }],
//             yAxes: [{
//                 angleLines: {
//                     display: false
//                   },
//                 ticks: {
//                     beginAtZero: true,
//                     maxTicksLimit: 10,
//                     callback: function(value, index, values) {
//                         return  value/1000  + 'k';
//                     },
//                     fontSize: 9,
//                     fontColor: '#000000',
//                     fontFamily: 'Roboto',
                    
//                 }
//             }]
//         }
//     },
//     plugins: [{
//       afterDraw: function(chart) {
//         if (chart.data.datasets[0].data.length == 0) {
//           // No data is present
//           var ctx = chart.chart.ctx;
//           var width = chart.chart.width;
//           var height = chart.chart.height
//           chart.clear();
//           ctx.save();
//           ctx.textAlign = 'center';
//           ctx.textBaseline = 'middle';
//           ctx.font = "20px Roboto 'Helvetica Nueue'";
//         ctx.fillText('No data to display', width / 2, height / 2);
//            ctx.restore();
//       }
//       }
// }]
// }); 

    this.ConstituentChartLoader=false;    
  }
  onChartTypeChange(chart: string) {                
    this.defaultActiveChart = chart;  
    if (chart == 'column2d' || chart == 'bar2d' || chart == 'line') {
      this.chartAttributes.chartLeftMargin = "0";
      this.chartAttributes.chartRightMargin = "3";
      this.chartAttributes.chartTopMargin = "5";
      this.chartAttributes.use3DLighting = "0";
      this.chartAttributes.showLabels = 1;
    }
    else {
        this.chartAttributes.showLabels = 0;
        this.chartAttributes.chartLeftMargin = "-20";
        this.chartAttributes.chartRightMargin = "-10";
        this.chartAttributes.chartTopMargin = "-15";
        this.chartAttributes.use3DLighting = "0";

    }
    this.chartSetup(this.indexMarkers);
}  

private reallignWithIndexMarkers(data){
  var associativeCategories = [];
  var associativeMultidatasets = [];
  for(var category of data["data"]["categories"][0]["category"]){
    associativeCategories[category.label] = category;
  }
  for(var dataset of data["data"].dataset[0]["data"]){
    associativeMultidatasets[dataset.label] = dataset;
  }
  data["data"]["categories"][0]["category"] = [];
  data["data"].dataset[0]["data"] = [];
  for(var indexmarker of this.indexMarkers){
    data["data"]["categories"][0]["category"].push(associativeCategories[indexmarker]);
    data["data"].dataset[0]["data"].push(associativeMultidatasets[indexmarker]);
  }
  return data;
}
  ExportXls(event):void{
    var event: any = {eventName: "Export Values Constituent Count", inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode}]};
    this.biService.addEvent(event);

     var inputData = {
      "exportType": "xls",
      "code": "value-getIndexValuesConstituentCountMSChartData",
      "xlsFileName": "ConstituentCountRawData",
      "asOfDate": this.asofDates,
      "indexMarkers": this.indexMarkers,
      "currencyCode": this.currencyCode
    };
    this.idwChartExportService.export(inputData);
  }

  ExportPNG(event):void{
    var event: any = {eventName: "Export Values Constituent Count Chart TO PNG",  inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode}]};
    this.biService.addEvent(event);
    var inputData = {
      "exportType": "png",
      "legend": false,
      "dataSource": this.popupDataSource.dataSource,
      "chartid" : "indexValuesColumnChartExport",
      "type" : this.defaultActiveChart,
      "targetDiv" : "exportPngDiv",
      "chartReference": this.chart,
      "isIndexLegend": false,
      "pngFileName" : "ConstituentCountRawData",
      "chartTitle": "Constituent Count",
      "chartDate": this.asofDates
   };
   this.idwChartExportService.export(inputData);
  }

}



@Component({
    selector: 'values-constituent-count-dialog',
	templateUrl: './values-constituent-count.component.dialog.html'
})
export class ValuesConstituentCountComponentDialog{

    constructor(
    public dialogRef: MatDialogRef<ValuesConstituentCountComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    onClose(): void {
        this.dialogRef.close();
  }
}


@Component({   
  templateUrl: './values-constituent-count.component.widget.dialog.html'
})
export class ValuesConstituentCountComponentWidgetDialog{

@Input() indexes: string;
constructor(
public dialogRef: MatDialogRef<ValuesConstituentCountComponentWidgetDialog>,
@Inject(MAT_DIALOG_DATA) public data: any) {
  this.indexes="";
   data.forEach(element => {
       if( this.indexes.length<3)
       {
          this.indexes=element;
       }else{
      this.indexes += ", "+ element;
       }
   });   
 }

onClose(): void {
    this.dialogRef.close();
}
}