import { Component, OnInit } from '@angular/core';
import {GeographicalInformationService} from './../../services/geographical-information.service';
import { LogDetails } from '../../models/log-details';
import * as moment from 'moment';
import {DateService} from '../../services/date.service';



 
@Component({
  selector: 'app-geographical-information',
  templateUrl: './geographical-information.component.html',
  styleUrls: ['./geographical-information.component.css']
})
export class GeographicalInformationComponent implements OnInit {
  asOfDate : any;
  constructor(private service:GeographicalInformationService,private dateService:DateService) { 
  }

  latitude='';
  longitude='';
  ip:LogDetails[]= [];
  ApiInfo:LogDetails[]= [];
  zoom:number=1;
  
  clickedMarker(label: string, index: number){

    console.log(`clicked the marker: ${label || index}`)
  }

  ngOnInit() {
    var that = this;
    this.dateService.calendarDatePublish().subscribe(result=>{ 
      that.asOfDate = moment(result.end).format('MM/DD/YYYY');
      that.getLocationData();
  });

    this.asOfDate = this.dateService.getCalendarDate().end;
    this.getLocationData();  

  }

  getLocationData() {
    this.service.getLocation(this.asOfDate).then((result) => {            
      for (let item of result) {                
        this.ApiInfo.push(item);
       
        // console.log('testing',item);
 
      }
    }); 
  }






}  
