import { Component, forwardRef, OnChanges, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BenchmarksService } from '../../services/benchmarks.service';
import { ReportsService } from '../../services/reports.service';

@Component({
  selector: 'app-select-portfolio',
  templateUrl: './select-portfolio.component.html',
  styleUrls: ['./select-portfolio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectPortfolioComponent),
      multi: true
    }
  ]
})

export class SelectPortfolioComponent implements OnInit, ControlValueAccessor, OnDestroy {
  fc = new FormControl('');
  benchmark: any;
  asofdate: any;
  currency: any;
  @Output() Seldata = new EventEmitter<any>();
  // TODO: Replace benchmarks with portfolios
  portfolios$: any;
  portfoliosfilteredList: any;

  sub = this.fc.valueChanges.subscribe((value) => {
    const valObj = this.portfolios$.find(item => item.id === value);
    this.onChange(valObj);
    this.onPortfolioChange(valObj);
  });
  onChange = (val: any) => { };

  onTouched = (val: any) => { };
  constructor(private reportService: ReportsService,) { }

  ngOnInit(): void {
    this.reportService.getPortfolio().subscribe(data => {
      this.portfolios$ = data.data;
      this.portfoliosfilteredList = data.data;
    });
  }

  writeValue(obj: any): void {
    this.fc.setValue(obj, { emitEvent: false });
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    // tslint:disable-next-line:no-unused-expression
    this.sub && this.sub.unsubscribe();
  }
  /*change(selectedValue)
  {
      console.log("hi");
      console.log(selectedValue);
  }*/
  onPortfolioChange(selectedValue)
  {
    //console.log("hello");
      console.log(selectedValue.id);
      console.log(selectedValue.name);
      const data  = this.reportService.getPortfoliobyId(selectedValue.id).then
      (data => {console.log(data);console.log(data.currency);
        console.log(data.benchMark);console.log(data.asOfDate);
         this.Seldata.emit(data);
    });
  }

}
