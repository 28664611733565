import { Component, OnInit, OnChanges,SimpleChanges } from '@angular/core';
import { CompanyModel } from '../../models/entitlement.model';
import { APIFieldsModel,EntitlementModel } from '../../models/entitlement.model';
import { EntitlementService } from '../../services/entitlement.service';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'rxjs/operators';
import { isNull } from 'util';
import { param, isEmptyObject } from 'jquery';
 
@Component({
  selector: 'app-api-configuration',
  templateUrl: './api-configuration.component.html',
  styleUrls: ['./api-configuration.component.css']
})

export class ApiConfigurationComponent implements OnInit {
  company:any ={};
  companies: CompanyModel[];
  selectedApi: any;
  selectedCompany: any;
  apis: any[] = new Array();
  apiFields: APIFieldsModel[];
  inputApiFields : APIFieldsModel[];
  outputApiFields : APIFieldsModel[];
  dataTypes: any[] = new Array();
  entitlement: EntitlementModel;
  commitStatus: any;
  input : APIFieldsModel;
  private sub: any;
  defaultCompany: string = "";
  AllOutputSelection: boolean = false;
  disabled: boolean =false;
  errorColor:string = "red";
  normalDisplay:string = "";
  messageStylDisplay:string = "";
  count : number = 0;
  
  constructor(private entitlementService: EntitlementService,private route: ActivatedRoute) { }
  ngOnInit() {
   this.dataTypes.push("EnumeratedStringParameter");
    this.dataTypes.push("AnyStringParameter");
    this.dataTypes.push("AnyDateParameter");
    this.dataTypes.push("AnyEnumerableStringParameter");
    this.dataTypes.push("LessThanDateParameter");
    this.dataTypes.push("GreaterThanDateParameter");
    this.entitlement = {
      companyId:"",
      companyName:"", 
      apiName:"", 
      subscriptionEndDate:"",
      subscriptionStartDate:"",
      isActive:false,
      inputParameters :[],
      outputParameters: [] 
    } ;
    this.entitlementService.getCompanies().then(result =>
      {
        
        this.companies = result;
        if(this.entitlement.companyId != undefined && this.entitlement.companyId != ""){
          this.company = result.filter(cp=> cp.companyId == this.entitlement.companyId);
          this.defaultCompany = this.entitlement.companyId;
          this.getInputOutputParameters(this.entitlement.companyId,this.entitlement.apiName);
        }
        

      })
      this.entitlementService.getAPIS().then(result =>
        {
          this.apis = result;
        });
    var sub = this.route.params.subscribe(params => {
      if(isEmptyObject(params) )
      {  
        this.disabled = false
      }
      else{
        this.disabled = true
      }
    
      this.entitlement.companyName = params['companyName'];
      this.entitlement.apiName = params['apiName'];
      this.entitlement.companyId=params['companyId'];
      this.apis = params['apiName'];
      this.entitlement.subscriptionStartDate= params['subscriptionStartDate'];
      this.entitlement.subscriptionEndDate= params['subscriptionEndDate'];
      this.entitlement.isActive= params['isActive'];
      this.AllOutputSelection = (localStorage.getItem(this.entitlement.companyId + " "+this.entitlement.apiName+
    "Selection")=="true")?true:false;
     // this.getDefaultValues(this.entitlement.apiName);
    });

  }

  selectOutputParams(selectionOption){
    for(var counter =0; counter< this.entitlement.outputParameters.length;counter++){
      this.entitlement.outputParameters[counter].selected = this.AllOutputSelection;
    }
    localStorage.setItem(this.entitlement.companyId + " "+ this.entitlement.apiName+"Selection",
  this.AllOutputSelection.toString());

  }
    getInputOutputParameters(companyId,apiName){
      this.entitlement.outputParameters = [];
      if(localStorage.getItem(this.entitlement.companyId+" "+apiName)!= null &&
          localStorage.getItem(this.entitlement.companyId+" "+apiName) != undefined){
            this.entitlementService.getEntitement(companyId,apiName).then(ent => {
              this.entitlement.inputParameters = ent[0].inputParameters;
            });    
            var arrayOrder = JSON.parse(localStorage.getItem(this.entitlement.companyId+" "+apiName));
                   var outputParametersToDisplayLocalStorage = [];
                   for(var counter =0; counter< arrayOrder.length;counter++){
                     var selectApiFieldModelLocalStorage = new APIFieldsModel();
                     selectApiFieldModelLocalStorage.apiName = apiName;
                     selectApiFieldModelLocalStorage.fieldName =  arrayOrder[counter].fieldName;
                     selectApiFieldModelLocalStorage.selected = arrayOrder[counter].selected;
                     outputParametersToDisplayLocalStorage.push(selectApiFieldModelLocalStorage);
                   }
                   this.entitlement.outputParameters = outputParametersToDisplayLocalStorage;
          }else{
            this.entitlementService.getAPIFields(apiName).then(apiFields=> {
              this.entitlementService.getEntitement(companyId,apiName).then(ent => {
                this.entitlement.inputParameters = ent[0].inputParameters;
                var selectedOutputParameters = ent[0].outputParameters;
                var completeListOfOutPutsParameters = apiFields.filter(ap => ap.isOutputField == true);
                var outputParamsToDisplay = [];
                for(var counter = 0;counter < completeListOfOutPutsParameters.length;counter++){
                  var selectedParam = selectedOutputParameters.filter(sp => sp.fieldName == 
                    completeListOfOutPutsParameters[counter].fieldName);
                  if(selectedParam != null && selectedParam != undefined && selectedParam.length > 0){
                    var selectApiFieldModel = new APIFieldsModel();
                    selectApiFieldModel.apiName = apiName;
                    selectApiFieldModel.fieldName = selectedParam[0]["fieldName"];
                    selectApiFieldModel.selected = selectedParam[0]["selected"];
                    outputParamsToDisplay.push(selectApiFieldModel);
                  }else{
                    outputParamsToDisplay.push(completeListOfOutPutsParameters[counter]);
                  }
                }
                this.entitlement.outputParameters = outputParamsToDisplay;         
              })
            });            
          }
    }
    onCompanyChange(selectedCompany)
    {
      if(selectedCompany == "Select")
      {
        this.entitlement.companyName= undefined;
      }else{
      var selectedCompanyBasedOnId = this.companies.filter(cm => cm.companyId == selectedCompany);
      this.entitlement.companyName=selectedCompanyBasedOnId[0].companyName;
      this.entitlement.companyId= selectedCompanyBasedOnId[0].companyId ;
      this.defaultCompany = selectedCompany;
      }
    }

    getDefaultValues(selectedAPI)
    {
      this.entitlementService.getAPIFields(selectedAPI).then(result =>
        {
          this.apiFields = result;
          this.entitlement.inputParameters =  this.apiFields.filter(x => x.isInputField == true) ;   
          this.entitlement.outputParameters =  this.apiFields.filter(x => x.isOutputField == true) ;  
          this.entitlementService.getEntitement(this.entitlement.companyId,this.entitlement.apiName).then(result =>
            {
              this.entitlement.inputParameters = result[0].inputParameters;
              this.entitlement.subscriptionStartDate = result[0].subscriptionStartDate.substring(0,10);
              this.entitlement.subscriptionEndDate = result[0].subscriptionEndDate.substring(0,10);
              this.entitlement.isActive = result[0].isActive;
              for(var i=0; i< this.entitlement.outputParameters.length; i++)
              {
                 for(var j=0; j< result[0].outputParameters.length; j++)
                {
                  if(this.entitlement.outputParameters[i].fieldName == result[0].outputParameters[j].fieldName)
                  {
                    this.entitlement.outputParameters[i].selected = true;
                  }
                }
              }
            })
        })
    }
   
    onAPIChange(selectedAPI)
    {
      if(selectedAPI == "Select")
      {
        this.entitlement.apiName= "Select";
      }
      this.entitlementService.getAPIFields(selectedAPI).then(result =>
        {
          this.apiFields = result;
          this.entitlement.inputParameters =  this.apiFields.filter(x => x.isInputField == true) ;   
          this.entitlement.outputParameters =  this.apiFields.filter(x => x.isOutputField == true) ;  
        })     
    }
    UpMove(param : APIFieldsModel){
          var paramIndex = this.entitlement.outputParameters.findIndex(op => op.fieldName == param.fieldName);
          if(0 == paramIndex){
            return;
          }
          var arrayCopy = JSON.parse(JSON.stringify(this.entitlement.outputParameters));
          arrayCopy.splice(paramIndex,1);
          arrayCopy.splice(paramIndex - 1,0,param);
          this.entitlement.outputParameters = [];
          this.entitlement.outputParameters = arrayCopy;
    }

    DownMove(param : APIFieldsModel){
      var paramIndex = this.entitlement.outputParameters.findIndex(op => op.fieldName == param.fieldName);
      if((this.entitlement.outputParameters.length - 1) == paramIndex){
        return;
      }
      var arrayCopy = JSON.parse(JSON.stringify(this.entitlement.outputParameters));
      arrayCopy.splice(paramIndex,1);
      arrayCopy.splice(paramIndex + 1,0,param);
      this.entitlement.outputParameters = [];
      this.entitlement.outputParameters = arrayCopy;
    }
    saveChanges()
    {
      this.commitStatus ="";
      var i ,j;
      this.messageStylDisplay = this.normalDisplay;
      this.commitStatus = "";
      i = (this.entitlement.inputParameters.length)-1;
      if(this.entitlement.companyName==undefined)
          {
          this.commitStatus = "Please Select Company Name";
          }
          else if(this.entitlement.apiName==undefined ||this.entitlement.apiName=="Select" )
          {
            this.commitStatus = "Please Select API Name";
          }
          else{
      for(var counter =0; counter< this.entitlement.inputParameters.length;counter++){
        if(this.entitlement.inputParameters[counter].dataType== null||this.entitlement.inputParameters[counter].dataType== 'Select')
       {
        this.messageStylDisplay = this.errorColor;
        this.commitStatus = "Enter required (*) fields";
        break ;
       }
       else if(this.entitlement.inputParameters[counter].dataType != null ){
        if(this.entitlement.inputParameters[counter].value == null)
        {
          this.entitlement.inputParameters[counter].value = "";
        }
        if(this.entitlement.inputParameters[counter].dataType == 'AnyEnumerableStringParameter'){
          var stringParametersArray = this.entitlement.inputParameters[counter].value.split(',');
          for(var stringParamCounter =0; stringParamCounter< stringParametersArray.length;stringParamCounter++){
            var alphaNumericRegex = new RegExp("^[A-Z a-z 0-9]+$");
            var stringParameterCheck = alphaNumericRegex.test(stringParametersArray[stringParamCounter]);
            if(stringParametersArray[stringParamCounter] == "" || stringParameterCheck){
              j = counter;
              console.log(j);        
            }else{
              this.messageStylDisplay = this.errorColor;
              this.commitStatus = "Error detected in that part of the string "+stringParametersArray[stringParamCounter];
               break;
            }
          }
        }
        
        
      }
       else{  j= counter; 
        console.log(j);}
      }
    }
      if(i == j){
      this.entitlementService.saveEntitlement(this.entitlement).then(result => {      
        if(result >0)
        {
         this.commitStatus = "Saved successfully";
         localStorage.setItem(this.entitlement.companyId+ " "+ this.entitlement.apiName,
          JSON.stringify(this.entitlement.outputParameters));
          this.count =this.count +1;
          if(this.count==1){
          alert("API "+ this.entitlement.apiName  +" "+'Saved Successfully');
          window.location.href = "/company-api-search"
          }
        }
        else
        {
            this.commitStatus="Enter Valid Subscription Start/End Date"
        }    
      });
    }
    }


}
