import { Component, OnInit, Input } from '@angular/core';
import { AdminReportService } from '../../../services/adminreportservice';
import {KPIData} from '../../../models/kpi-data.model';


@Component({
    selector: 'app-kpi',
    templateUrl: './kpi.component.html'
  })
  export class KPIComponent implements OnInit {

    @Input() inputDate;
    kpiData: KPIData[] = [];
    kpidialFail = false;
    kpiActionsDictionary = [];
    indexMasterCloseIndexMarkers = [];
    IndexLevelCloseIndexMarkers=[];
    ConstituentPositionsCloseIndexMarkers = [];
    ConstituentListCloseIndexMarkers = [];
    IndexMasterOpenIndexMarkers = [];
    IndexLevelOpenIndexMarkers = [];
    ConstituentPositionsOpenIndexMarkers = [];
    ConstituentListOpenIndexMarkers = [];
    ConstituentPositionsProformaIndexMarkers = [];
    ConstituentPositionsHistoricalIndexMarkers = [];
    IndexLevelHistoricalIndexMarkers = [];

    DisplayObject = {"IndexMasterClose":false,"IndexLevelClose":false,"ConstituentPositionsClose":false,"ConstituentListClose":false,"IndexMasterOpen":false,
    "IndexLevelOpen":false,"ConstituentPositionsOpen":false,"ConstituentListOpen":false,"ConstituentListProforma":false,"ConstituentPositionsProforma":false,
    "ExchangeRates":false,"ConstituentListHistorical":false,"ConstituentPositionsHistorical":false,"IndexLevelHistorical":false};
  
    StartTimeObject = {"IndexMasterClose":'',"IndexLevelClose":'',"ConstituentPositionsClose":'',"ConstituentListClose":'',"IndexMasterOpen":'',
    "IndexLevelOpen":'',"ConstituentPositionsOpen":'',"ConstituentListOpen":'',"ConstituentListProforma":'',"ConstituentPositionsProforma":'',
    "ExchangeRates":'',"ConstituentListHistorical":'',"ConstituentPositionsHistorical":'',"IndexLevelHistorical":''};
  
    SuccessErrorCodeObject = {"IndexMasterClose":'',"IndexLevelClose":'',"ConstituentPositionsClose":'',"ConstituentListClose":'',"IndexMasterOpen":'',
    "IndexLevelOpen":'',"ConstituentPositionsOpen":'',"ConstituentListOpen":'',"ConstituentListProforma":'',"ConstituentPositionsProforma":'',
    "ExchangeRates":'',"ConstituentListHistorical":'',"ConstituentPositionsHistorical":'',"IndexLevelHistorical":''};
  
    SuccessErrorMessageObject = {"IndexMasterClose":'',"IndexLevelClose":'',"ConstituentPositionsClose":'',"ConstituentListClose":'',"IndexMasterOpen":'',
    "IndexLevelOpen":'',"ConstituentPositionsOpen":'',"ConstituentListOpen":'',"ConstituentListProforma":'',"ConstituentPositionsProforma":'',
    "ExchangeRates":'',"ConstituentListHistorical":'',"ConstituentPositionsHistorical":'',"IndexLevelHistorical":''};
  
    BindIndexObject = {"IndexMasterClose":'',"IndexLevelClose":'',"ConstituentPositionsClose":'',"ConstituentListClose":'',
    "IndexMasterOpen":'',"IndexLevelOpen":'',"ConstituentPositionsOpen":'',"ConstituentListOpen":'',"ConstituentPositionsProforma":'',
    "ConstituentPositionsHistorical":'',"IndexLevelHistorical":''};
  
    ThroughputObject = {"IndexMasterClose":'',"IndexLevelClose":'',"ConstituentPositionsClose":'',"ConstituentListClose":'',"IndexMasterOpen":'',
    "IndexLevelOpen":'',"ConstituentPositionsOpen":'',"ConstituentListOpen":'',"ConstituentListProforma":'',"ConstituentPositionsProforma":'',
    "ExchangeRates":'',"ConstituentListHistorical":'',"ConstituentPositionsHistorical":'',"IndexLevelHistorical":''};
  
    AVGBytesObject = {"IndexMasterClose":'',"IndexLevelClose":'',"ConstituentPositionsClose":'',"ConstituentListClose":'',"IndexMasterOpen":'',
    "IndexLevelOpen":'',"ConstituentPositionsOpen":'',"ConstituentListOpen":'',"ConstituentListProforma":'',"ConstituentPositionsProforma":'',
    "ExchangeRates":'',"ConstituentListHistorical":'',"ConstituentPositionsHistorical":'',"IndexLevelHistorical":''};
  
    ThreadNameObject = {"IndexMasterClose":'',"IndexLevelClose":'',"ConstituentPositionsClose":'',"ConstituentListClose":'',"IndexMasterOpen":'',
    "IndexLevelOpen":'',"ConstituentPositionsOpen":'',"ConstituentListOpen":'',"ConstituentListProforma":'',"ConstituentPositionsProforma":'',
    "ExchangeRates":'',"ConstituentListHistorical":'',"ConstituentPositionsHistorical":'',"IndexLevelHistorical":''};
  
    LatencyObject = {"IndexMasterClose":'',"IndexLevelClose":'',"ConstituentPositionsClose":'',"ConstituentListClose":'',"IndexMasterOpen":'',
    "IndexLevelOpen":'',"ConstituentPositionsOpen":'',"ConstituentListOpen":'',"ConstituentListProforma":'',"ConstituentPositionsProforma":'',
    "ExchangeRates":'',"ConstituentListHistorical":'',"ConstituentPositionsHistorical":'',"IndexLevelHistorical":''};
  
    ConnectionTimeObject = {"IndexMasterClose":'',"IndexLevelClose":'',"ConstituentPositionsClose":'',"ConstituentListClose":'',"IndexMasterOpen":'',
    "IndexLevelOpen":'',"ConstituentPositionsOpen":'',"ConstituentListOpen":'',"ConstituentListProforma":'',"ConstituentPositionsProforma":'',
    "ExchangeRates":'',"ConstituentListHistorical":'',"ConstituentPositionsHistorical":'',"IndexLevelHistorical":''};
  
    UsernameObject = {"IndexMasterClose":'',"IndexLevelClose":'',"ConstituentPositionsClose":'',"ConstituentListClose":'',"IndexMasterOpen":'',
    "IndexLevelOpen":'',"ConstituentPositionsOpen":'',"ConstituentListOpen":'',"ConstituentListProforma":'',"ConstituentPositionsProforma":'',
    "ExchangeRates":'',"ConstituentListHistorical":'',"ConstituentPositionsHistorical":'',"IndexLevelHistorical":''};

    constructor(private AdminReportService: AdminReportService) {
        this.kpiActionsDictionary["IndexLevelClose"] = this.indexLevelCloseKPI;
        this.kpiActionsDictionary["ConstituentPositionClose"] = this.constituentPositionsCloseKPI;
        this.kpiActionsDictionary["ConstituentPositionOpen"] = this.constituentPositionsOpenKPI;
        this.kpiActionsDictionary["ConstituentListOpen"] = this.constituentListOpenKPI;
        this.kpiActionsDictionary["IndexMasterOpen"] = this.indexMasterOpenKPI;
        this.kpiActionsDictionary["ConstituentListClose"] = this.constituentListCloseKPI;
        this.kpiActionsDictionary["IndexMasterClose"] = this.indexMasterCloseKPI;
        this.kpiActionsDictionary["ExchangeRates"] = this.exchangeRatesKPI;
        this.kpiActionsDictionary["IndexLevelOpen"] = this.indexLevelOpenKPI;
        this.kpiActionsDictionary["ConstituentPositionProforma"] = this.constituentPositionsProformaKPI;
        this.kpiActionsDictionary["ConstituentListProforma"] = this.constituentListProformaKPI;
        this.kpiActionsDictionary["ConstituentListHistory"] = this.constituentListHistoryKPI;
        this.kpiActionsDictionary["ConstituentPositionHistory"] = this.constituentPositionsHistoryKPI;
        this.kpiActionsDictionary["IndexLevelHistory"] = this.indexLevelHistoryKPI;
    }

    ngOnInit() {
        this.AdminReportService.getKPIData(this.inputDate, "");
        this.AdminReportService.kpidataLoaded.subscribe((data: KPIData[]) => {
         this.kpiData = data;
          this.fillKPIData();
        });
        this.AdminReportService.kpidataLoadFailed.subscribe(
          (didFail: boolean) => this.kpidialFail = didFail
        );
    }
    
    updateKPI() {
    
      this.AdminReportService.getKPIData(this.inputDate,"");
      this.AdminReportService.kpidataLoaded.subscribe((data: KPIData[]) => {
        this.kpiData = data;
        this.fillKPIData();
      });
    }
    fillKPIData(): void {
      if (this.kpiData !== null && this.kpiData.length !== 0) {
        this.initAllExceptDisplay(this);
       
        for (var i = 0; i < this.kpiData.length; i++) {
          var methodName = this.kpiData[i].apiMethodName.split(" ")[0];
          if(methodName in this.kpiActionsDictionary){
            this.kpiActionsDictionary[methodName](this.kpiData[i],this);
          }
          
        }
        if(undefined!= this.BindIndexObject['IndexMasterClose']){
          this.indexMasterCloseIndexMarkers = this.BindIndexObject['IndexMasterClose'].split('\n');
          this.indexMasterCloseIndexMarkers.pop();
        }
       
        if(undefined!= this.BindIndexObject['IndexLevelClose']){
          this.IndexLevelCloseIndexMarkers = this.BindIndexObject['IndexLevelClose'].split('\n');
        this.IndexLevelCloseIndexMarkers.pop();
        }
        
        if(undefined!= this.BindIndexObject['ConstituentPositionClose']){
          this.ConstituentPositionsCloseIndexMarkers = this.BindIndexObject['ConstituentPositionClose'].split('\n');
        this.ConstituentPositionsCloseIndexMarkers.pop();
        }
        
        if(undefined!= this.BindIndexObject['ConstituentListClose']){
          this.ConstituentListCloseIndexMarkers = this.BindIndexObject['ConstituentListClose'].split('\n');
          this.ConstituentListCloseIndexMarkers.pop();
        }
       
        if(undefined!= this.BindIndexObject['IndexMasterOpen']){
          this.IndexMasterOpenIndexMarkers = this.BindIndexObject['IndexMasterOpen'].split('\n');
        this.IndexMasterOpenIndexMarkers.pop();
        }
        
        if(undefined!= this.BindIndexObject['IndexLevelOpen']){
          this.IndexLevelOpenIndexMarkers = this.BindIndexObject['IndexLevelOpen'].split('\n');
          this.IndexLevelOpenIndexMarkers.pop();
        }
       
        if(undefined!= this.BindIndexObject['ConstituentPositionOpen']){
          this.ConstituentPositionsOpenIndexMarkers = this.BindIndexObject['ConstituentPositionOpen'].split('\n');
          this.ConstituentPositionsOpenIndexMarkers.pop();
        }
       
        if(undefined!= this.BindIndexObject['ConstituentListOpen']){
          this.ConstituentListOpenIndexMarkers = this.BindIndexObject['ConstituentListOpen'].split('\n');
          this.ConstituentListOpenIndexMarkers.pop();
        }
       
        if(undefined!= this.BindIndexObject['ConstituentPositionProforma']){
          this.ConstituentPositionsProformaIndexMarkers = this.BindIndexObject['ConstituentPositionProforma'].split('\n');
        this.ConstituentPositionsProformaIndexMarkers.pop();
        }
        
        if(undefined!= this.BindIndexObject['ConstituentPositionHistory']){
          this.ConstituentPositionsHistoricalIndexMarkers = this.BindIndexObject['ConstituentPositionHistory'].split('\n');
          this.ConstituentPositionsHistoricalIndexMarkers.pop();
        }
       
        if(undefined!= this.BindIndexObject['IndexLevelHistory']){
          this.IndexLevelHistoricalIndexMarkers = this.BindIndexObject['IndexLevelHistory'].split('\n');
          this.IndexLevelHistoricalIndexMarkers.pop();
        }
       

      } else {
        this.initDisplay(this);
        this.initAllExceptDisplay(this);
      }
    }

      indexLevelCloseKPI(kpiData:KPIData,currentObject){
        currentObject.DisplayObject["IndexLevelClose"] = true;
        currentObject.BindIndexObject["IndexLevelClose"] = currentObject.BindIndexObject["IndexLevelClose"] + kpiData.indexMarker + "\n";
        currentObject.StartTimeObject["IndexLevelClose"] = currentObject.StartTimeObject["IndexLevelClose"]   + kpiData.callStartTime+ "\n";
        currentObject.SuccessErrorCodeObject["IndexLevelClose"] = currentObject.SuccessErrorCodeObject["IndexLevelClose"] + kpiData.successErrorCode + "\n";
        currentObject.SuccessErrorMessageObject["IndexLevelClose"] = currentObject.SuccessErrorMessageObject["IndexLevelClose"]  + kpiData.successErrorMessage + "\n";
        currentObject.ConnectionTimeObject["IndexLevelClose"] = currentObject.ConnectionTimeObject["IndexLevelClose"] + kpiData.connectionTime + "\n";
        currentObject.UsernameObject["IndexLevelClose"] = currentObject.UsernameObject["IndexLevelClose"] + kpiData.userId + "\n";
      }
    
      constituentPositionsCloseKPI(kpiData:KPIData,currentObject){
        currentObject.DisplayObject["ConstituentPositionsClose"] = true;
        currentObject.BindIndexObject["ConstituentPositionsClose"] = currentObject.BindIndexObject["ConstituentPositionsClose"] + kpiData.indexMarker + "\n";
        currentObject.StartTimeObject["ConstituentPositionsClose"] = currentObject.StartTimeObject["ConstituentPositionsClose"]  + kpiData.callStartTime+ "\n";
        currentObject.SuccessErrorCodeObject["ConstituentPositionsClose"] = currentObject.SuccessErrorCodeObject["ConstituentPositionsClose"] + kpiData.successErrorCode + "\n";
        currentObject.SuccessErrorMessageObject["ConstituentPositionsClose"] = currentObject.SuccessErrorMessageObject["ConstituentPositionsClose"] + kpiData.successErrorMessage + "\n";
       currentObject.ConnectionTimeObject["ConstituentPositionsClose"] =  currentObject.ConnectionTimeObject["ConstituentPositionsClose"] + kpiData.connectionTime + "\n";
        currentObject.UsernameObject["ConstituentPositionsClose"] = currentObject.UsernameObject["ConstituentPositionsClose"] + kpiData.userId + "\n";
      }
    
      constituentPositionsOpenKPI(kpiData:KPIData,currentObject){
        currentObject.DisplayObject["ConstituentPositionsOpen"] = true;
        currentObject.BindIndexObject["ConstituentPositionsOpen"] =  currentObject.BindIndexObject["ConstituentPositionsOpen"] + kpiData.indexMarker + "\n";
        currentObject.StartTimeObject["ConstituentPositionsOpen"] = currentObject.StartTimeObject["ConstituentPositionsOpen"]  + kpiData.callStartTime+ "\n";
        currentObject.SuccessErrorCodeObject["ConstituentPositionsOpen"] =  currentObject.SuccessErrorCodeObject["ConstituentPositionsOpen"] + kpiData.successErrorCode + "\n";
        currentObject.SuccessErrorMessageObject["ConstituentPositionsOpen"] = currentObject.SuccessErrorMessageObject["ConstituentPositionsOpen"] + kpiData.successErrorMessage + "\n";
        currentObject.ConnectionTimeObject["ConstituentPositionsOpen"] = currentObject.ConnectionTimeObject["ConstituentPositionsOpen"] + kpiData.connectionTime + "\n";
        currentObject.UsernameObject["ConstituentPositionsOpen"] = currentObject.UsernameObject["ConstituentPositionsOpen"] + kpiData.userId + "\n";
      }
    
      constituentListOpenKPI(kpiData:KPIData,currentObject){
        currentObject.DisplayObject["ConstituentListOpen"] = true;
        currentObject.BindIndexObject["ConstituentListOpen"] = currentObject.BindIndexObject["ConstituentListOpen"] + kpiData.indexMarker + "\n";
        currentObject.StartTimeObject["ConstituentListOpen"] = currentObject.StartTimeObject["ConstituentListOpen"]  + kpiData.callStartTime+ "\n";
        currentObject.SuccessErrorCodeObject["ConstituentListOpen"] = currentObject.SuccessErrorCodeObject["ConstituentListOpen"] + kpiData.successErrorCode + "\n";
        currentObject.SuccessErrorMessageObject["ConstituentListOpen"] = currentObject.SuccessErrorMessageObject["ConstituentListOpen"] + kpiData.successErrorMessage + "\n";
        currentObject.ConnectionTimeObject["ConstituentListOpen"] = currentObject.ConnectionTimeObject["ConstituentListOpen"] + kpiData.connectionTime + "\n";
        currentObject.UsernameObject["ConstituentListOpen"] = currentObject.UsernameObject["ConstituentListOpen"] + kpiData.userId + "\n";
      }
    
      indexMasterOpenKPI(kpiData:KPIData,currentObject){
        currentObject.DisplayObject["IndexMasterOpen"] = true;
        currentObject.BindIndexObject["IndexMasterOpen"] = currentObject.BindIndexObject["IndexMasterOpen"] + kpiData.indexMarker + "\n";
        currentObject.StartTimeObject["IndexMasterOpen"] = currentObject.StartTimeObject["IndexMasterOpen"]  + kpiData.callStartTime+ "\n";
        currentObject.SuccessErrorCodeObject["IndexMasterOpen"] = currentObject.SuccessErrorCodeObject["IndexMasterOpen"] + kpiData.successErrorCode + "\n";
        currentObject.SuccessErrorMessageObject["IndexMasterOpen"] = currentObject.SuccessErrorMessageObject["IndexMasterOpen"] + kpiData.successErrorMessage + "\n";
        currentObject.ConnectionTimeObject["IndexMasterOpen"] = currentObject.ConnectionTimeObject["IndexMasterOpen"] + kpiData.connectionTime + "\n";
        currentObject.UsernameObject["IndexMasterOpen"] = currentObject.UsernameObject["IndexMasterOpen"] + kpiData.userId + "\n";
      }
    
      constituentListCloseKPI(kpiData:KPIData,currentObject){
        currentObject.DisplayObject["ConstituentListClose"] = true;
        currentObject.BindIndexObject["ConstituentListClose"] = currentObject.BindIndexObject["ConstituentListClose"] + kpiData.indexMarker + "\n";
        currentObject.StartTimeObject["ConstituentListClose"] = currentObject.StartTimeObject["ConstituentListClose"]  + kpiData.callStartTime+ "\n";
        currentObject.SuccessErrorCodeObject["ConstituentListClose"] = currentObject.SuccessErrorCodeObject["ConstituentListClose"] + kpiData.successErrorCode + "\n";
        currentObject.SuccessErrorMessageObject["ConstituentListClose"] = currentObject.SuccessErrorMessageObject["ConstituentListClose"] + kpiData.successErrorMessage + "\n";
       currentObject.ConnectionTimeObject["ConstituentListClose"] = currentObject.ConnectionTimeObject["ConstituentListClose"] + kpiData.connectionTime + "\n";
        currentObject.UsernameObject["ConstituentListClose"] = currentObject.UsernameObject["ConstituentListClose"] + kpiData.userId + "\n";
      }
    
      indexMasterCloseKPI(kpiData:KPIData,currentObject){
        currentObject.DisplayObject["IndexMasterClose"] = true;
        currentObject.BindIndexObject["IndexMasterClose"] = currentObject.BindIndexObject["IndexMasterClose"] + kpiData.indexMarker + "\n";
        currentObject.StartTimeObject["IndexMasterClose"] = currentObject.StartTimeObject["IndexMasterClose"]  + kpiData.callStartTime+ "\n";
        currentObject.SuccessErrorCodeObject["IndexMasterClose"] = currentObject.SuccessErrorCodeObject["IndexMasterClose"] + kpiData.successErrorCode + "\n";
        currentObject.SuccessErrorMessageObject["IndexMasterClose"] = currentObject.SuccessErrorMessageObject["IndexMasterClose"] + kpiData.successErrorMessage + "\n";
        currentObject.ConnectionTimeObject["IndexMasterClose"] = currentObject.ConnectionTimeObject["IndexMasterClose"] + kpiData.connectionTime + "\n";
        currentObject.UsernameObject["IndexMasterClose"] = currentObject.UsernameObject["IndexMasterClose"] + kpiData.userId + "\n";
      }
    
      exchangeRatesKPI(kpiData:KPIData,currentObject){
        currentObject.DisplayObject["ExchangeRates"] = true;
        currentObject.StartTimeObject["ExchangeRates"] = currentObject.StartTimeObject["ExchangeRates"]  + kpiData.callStartTime+ "\n";
        currentObject.SuccessErrorCodeObject["ExchangeRates"] = currentObject.SuccessErrorCodeObject["ExchangeRates"] + kpiData.successErrorCode + "\n";
        currentObject.SuccessErrorMessageObject["ExchangeRates"] = currentObject.SuccessErrorMessageObject["ExchangeRates"] + kpiData.successErrorMessage + "\n";
        currentObject.ConnectionTimeObject["ExchangeRates"] = currentObject.ConnectionTimeObject["ExchangeRates"] + kpiData.connectionTime + "\n";
        currentObject.UsernameObject["ExchangeRates"] = currentObject.UsernameObject["ExchangeRates"] + kpiData.userId + "\n";
      }
    
      indexLevelOpenKPI(kpiData:KPIData,currentObject){
        currentObject.DisplayObject["IndexLevelOpen"] = true;
        currentObject.BindIndexObject["IndexLevelOpen"] = currentObject.BindIndexObject["IndexLevelOpen"] + kpiData.indexMarker + "\n";
        currentObject.StartTimeObject["IndexLevelOpen"] = currentObject.StartTimeObject["IndexLevelOpen"]  + kpiData.callStartTime+ "\n";
        currentObject.SuccessErrorCodeObject["IndexLevelOpen"] = currentObject.SuccessErrorCodeObject["IndexLevelOpen"] + kpiData.successErrorCode + "\n";
        currentObject.SuccessErrorMessageObject["IndexLevelOpen"] = currentObject.SuccessErrorMessageObject["IndexLevelOpen"] + kpiData.successErrorMessage + "\n";
       currentObject.ConnectionTimeObject["IndexLevelOpen"] = currentObject.ConnectionTimeObject["IndexLevelOpen"] + kpiData.connectionTime + "\n";
        currentObject.UsernameObject["IndexLevelOpen"] = currentObject.UsernameObject["IndexLevelOpen"] + kpiData.userId + "\n";
      }
    
      constituentPositionsProformaKPI(kpiData:KPIData,currentObject){
        currentObject.DisplayObject["ConstituentPositionsProforma"] = true;
        currentObject.BindIndexObject["ConstituentPositionsProforma"] = currentObject.BindIndexObject["ConstituentPositionsProforma"] + kpiData.indexMarker + "\n";
        currentObject.StartTimeObject["ConstituentPositionsProforma"] = currentObject.StartTimeObject["ConstituentPositionsProforma"]  + kpiData.callStartTime+ "\n";
        currentObject.SuccessErrorCodeObject["ConstituentPositionsProforma"] = currentObject.SuccessErrorCodeObject["ConstituentPositionsProforma"] + kpiData.successErrorCode + "\n";
        currentObject.SuccessErrorMessageObject["ConstituentPositionsProforma"] = currentObject.SuccessErrorMessageObject["ConstituentPositionsProforma"] + kpiData.successErrorMessage + "\n";
       currentObject.ConnectionTimeObject["ConstituentPositionsProforma"] = currentObject.ConnectionTimeObject["ConstituentPositionsProforma"] + kpiData.connectionTime + "\n";
        currentObject.UsernameObject["ConstituentPositionsProforma"] = currentObject.UsernameObject["ConstituentPositionsProforma"] + kpiData.userId + "\n";
      }
    
      constituentListProformaKPI(kpiData:KPIData,currentObject){
        currentObject.DisplayObject["ConstituentListProforma"] = true;
        currentObject.StartTimeObject["ConstituentListProforma"] = currentObject.StartTimeObject["ConstituentListProforma"]  + kpiData.callStartTime+ "\n";
        currentObject.SuccessErrorCodeObject["ConstituentListProforma"] = currentObject.SuccessErrorCodeObject["ConstituentListProforma"] + kpiData.successErrorCode + "\n";
        currentObject.SuccessErrorMessageObject["ConstituentListProforma"] = currentObject.SuccessErrorMessageObject["ConstituentListProforma"] + kpiData.successErrorMessage + "\n";
        currentObject.ConnectionTimeObject["ConstituentListProforma"] = currentObject.ConnectionTimeObject["ConstituentListProforma"] + kpiData.connectionTime + "\n";
        currentObject.UsernameObject["ConstituentListProforma"] = currentObject.UsernameObject["ConstituentListProforma"] + kpiData.userId + "\n";
      }
    
      constituentListHistoryKPI(kpiData:KPIData,currentObject){
        currentObject.DisplayObject["ConstituentListHistorical"] = true;
        currentObject.StartTimeObject["ConstituentListHistorical"] = currentObject.StartTimeObject["ConstituentListHistorical"]  + kpiData.callStartTime+ "\n";
        currentObject.SuccessErrorCodeObject["ConstituentListHistorical"] = currentObject.SuccessErrorCodeObject["ConstituentListHistorical"] + kpiData.successErrorCode + "\n";
        currentObject.SuccessErrorMessageObject["ConstituentListHistorical"] = currentObject.SuccessErrorMessageObject["ConstituentListHistorical"] + kpiData.successErrorMessage + "\n";
        currentObject.ConnectionTimeObject["ConstituentListHistorical"] = currentObject.ConnectionTimeObject["ConstituentListHistorical"] + kpiData.connectionTime + "\n";
        currentObject.UsernameObject["ConstituentListHistorical"] = currentObject.UsernameObject["ConstituentListHistorical"] + kpiData.userId + "\n";
      }
    
      constituentPositionsHistoryKPI(kpiData:KPIData,currentObject){
        currentObject.DisplayObject["ConstituentPositionsHistorical"] = true;
        currentObject.BindIndexObject["ConstituentPositionsHistorical"] =  currentObject.BindIndexObject["ConstituentPositionsHistorical"] + kpiData.indexMarker + "\n";
        currentObject.StartTimeObject["ConstituentPositionsHistorical"] = currentObject.StartTimeObject["ConstituentPositionsHistorical"]  + kpiData.callStartTime+ "\n";
        currentObject.SuccessErrorCodeObject["ConstituentPositionsHistorical"] = currentObject.SuccessErrorCodeObject["ConstituentPositionsHistorical"] + kpiData.successErrorCode + "\n";
        currentObject.SuccessErrorMessageObject["ConstituentPositionsHistorical"] = currentObject.SuccessErrorMessageObject["ConstituentPositionsHistorical"] + kpiData.successErrorMessage + "\n";
        currentObject.ConnectionTimeObject["ConstituentPositionsHistorical"] = currentObject.ConnectionTimeObject["ConstituentPositionsHistorical"] + kpiData.connectionTime + "\n";
        currentObject.UsernameObject["ConstituentPositionsHistorical"] = currentObject.UsernameObject["ConstituentPositionsHistorical"] + kpiData.userId + "\n";
      }
    
      indexLevelHistoryKPI(kpiData:KPIData,currentObject){
        currentObject.DisplayObject["IndexLevelHistorical"] = true;
        currentObject.BindIndexObject["IndexLevelHistorical"] = currentObject.BindIndexObject["IndexLevelHistorical"] + kpiData.indexMarker + "\n";
        currentObject.StartTimeObject["IndexLevelHistorical"] = currentObject.StartTimeObject["IndexLevelHistorical"]  + kpiData.callStartTime+ "\n";
        currentObject.SuccessErrorCodeObject["IndexLevelHistorical"] = currentObject.SuccessErrorCodeObject["IndexLevelHistorical"] + kpiData.successErrorCode + "\n";
        currentObject.SuccessErrorMessageObject["IndexLevelHistorical"] = currentObject.SuccessErrorMessageObject["IndexLevelHistorical"] + kpiData.successErrorMessage + "\n";
       currentObject.ConnectionTimeObject["IndexLevelHistorical"] = currentObject.ConnectionTimeObject["IndexLevelHistorical"] + kpiData.connectionTime + "\n";
        currentObject.UsernameObject["IndexLevelHistorical"] = currentObject.UsernameObject["IndexLevelHistorical"] + kpiData.userId + "\n";
      }
   
      initDisplay(currentObject){
        for (var property in currentObject.DisplayObject){
          currentObject.DisplayObject[property] = false;
        }
      }

      initAllExceptDisplay(currentObject){
        for(var startTimeProperty in currentObject.StartTimeObject){
          currentObject.StartTimeObject[startTimeProperty] = '';
        }
      
        for(var successErrorCodePropery in currentObject.SuccessErrorCodeObject){
          currentObject.SuccessErrorCodeObject[successErrorCodePropery] = '';
        }
        
        for(var successErrorMessageProperty in currentObject.SuccessErrorMessageObject){
          currentObject.SuccessErrorMessageObject[successErrorMessageProperty] = '';
        }
        
        for(var bindIndexProperty in currentObject.BindIndexObject){
          currentObject.BindIndexObject[bindIndexProperty] = '';
        }
       
       
        
        for(var cnnectiontimeProperty in currentObject.ConnectionTimeObject){
          currentObject.ConnectionTimeObject[cnnectiontimeProperty] = '';
        }
        
        for(var usernameProperty in currentObject.UsernameObject){
          currentObject.UsernameObject[usernameProperty] = '';
        }
      }
      
  }