import { Component,OnInit,ViewChild,AfterViewInit,EventEmitter,Output, Inject, Input } from '@angular/core';
import {CustomTableComponent} from '../custom-table/custom-table.component';
import { LandingService } from '../../services/landing.service';
import {FilterPipe, FilterReturnTypePipe} from '../../filter.pipe';

import {ColumnChooserService} from '../../services/column-chooser.service';
import {CustomColumnsModel} from '../../models/custom-columns-model';
import { IndexReturns } from '../../models/performance-index-returns';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import * as moment from 'moment';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DateService} from '../../services/date.service';
import {DateDisplayService} from '../../services/date.display.service';
import{CurrencyService}from '../../services/currency.service';

@Component({
    selector: 'factors-return-time-period-grid',
    templateUrl: './factors-return-time-period-grid.component.html'
})
export class FactorsReturnTimePeriodGridComponent {
    constructor(private dateDisplayService: DateDisplayService,private landingService: LandingService,private dateService:DateService,private publishIndexMarkerService:PublishIndexMarkerService,
        private indexMarkerService:IndexMarkerService,private columnChooserService:ColumnChooserService,
        private exportService: ExportToExcelService,public dialog: MatDialog,private currencyService:CurrencyService) { }
    @ViewChild(CustomTableComponent,{static: false}) customTableComponent; 
   
    defaultActiveCurrency: string = "USD";
    defaultActiveFilter: string = "T";       
    ReturnTimePeriodGridLoader:boolean =true;
    indexMarkers : any;     
    currencyCode : string;
    dataTableRawData : any;
    datatable : any;
    gridCustomColumns: any[];
    gridData:any;
    returnType : any;
    asOfDate :any;
    startDate:any;
    
    ngOnInit() {              
        this.asOfDate = this.dateService.getCalendarDate().end.substring(0,10);
        this.startDate = this.dateService.getCalendarDate().start.substring(0,10);
        this.currencyCode = this.currencyService.getCurrencyCode(); 
        this.returnType = 'T'; 
        this.indexMarkers = this.indexMarkerService.getIndexMarkers(); 
        this.gridSetup( this.asOfDate,this.indexMarkers);  
        this.dateService.calendarDatePublish().subscribe(dateRange=>{
            this.asOfDate = this.dateService.changeDateFormat(dateRange.end);
            this.startDate = this.dateService.changeDateFormat(dateRange.start);
            this.gridSetup( this.asOfDate,this.indexMarkers);
          });  
          this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
            this.indexMarkers=result;
            this.gridSetup( this.asOfDate,this.indexMarkers);
        });
        this.currencyService.currencyCodePublish().subscribe(currency=>{        
            this.currencyCode=currency;
            this.gridSetup( this.asOfDate,this.indexMarkers);
          });
             
    } 
    gridSetup( asOfDate,indexMarkers:any) { 
        this.ReturnTimePeriodGridLoader = true;
         
             this.gridCustomColumns = [
                {columnName: "Select All", columnVisibility: false, columnCssClass: "text-left", uiDisplayValue: "Select All", filter: "",hasFilter : true },
                {columnName: "indexName", columnVisibility: true, columnCssClass: "text-left", uiDisplayValue: "Index Name", filter: "",hasFilter : true},
                {columnName: "OneMonthReturn", columnVisibility: true, columnCssClass: "text-left", uiDisplayValue: "1M (%)", filter : "number:'.1-2'",hasFilter : true },
                {columnName: "threeMonthReturn", columnVisibility: true, columnCssClass: "text-left", uiDisplayValue: "3M (%)", filter : "number:'.1-2'",hasFilter :true },
                {columnName: "sixMonthReturn", columnVisibility: true, columnCssClass: "text-left", uiDisplayValue: "6M (%)", filter : "number:'.1-2'",hasFilter: true },
                {columnName: "qtdReturn", columnVisibility: true, columnCssClass: "text-right", uiDisplayValue: "QTD (%)", filter: "number:'.1-2'",hasFilter: true },
                {columnName: "ytdReturn", columnVisibility: true, columnCssClass: "text-right", uiDisplayValue: "YTD (%)", filter: "number:'.1-2'",hasFilter: true },
                {columnName: "1y", columnVisibility: true, columnCssClass: "text-right", uiDisplayValue: "1Y (% p.a.)", filter: "number:'.1-2'",hasFilter: true },
                {columnName: "3y", columnVisibility: true, columnCssClass: "text-right", uiDisplayValue: "3Y (% p.a.)", filter: "number:'.1-2'",hasFilter: true },
                {columnName: "5y", columnVisibility: true, columnCssClass: "text-right", uiDisplayValue: "5Y (% p.a.)", filter: "number:'.1-2'",hasFilter: true },
                {columnName: "10y", columnVisibility: true, columnCssClass: "text-right", uiDisplayValue: "10Y (% p.a.)", filter: "number:'.1-2'",hasFilter: true },
                 {columnName: "TwentyFiveYearAnnualizedReturn", columnVisibility: false, columnCssClass: "text-right", uiDisplayValue: "25", filter: "number:'.1-2'", hasFilter: true},
                ];
                             
                this.landingService.getIndexReturns(this.asOfDate,this.indexMarkers,this.asOfDate,this.startDate,
                    this.currencyCode,this.returnType); 
                    this.landingService.indexCalculatorDataByValuesLoaded.subscribe(res=> {
                        this.gridData = this.flattenData(res);
                        this.customTableComponent.bind(this.dateDisplayService.getArrayDateDisplay(this.gridData,"asofdate"),this.gridCustomColumns);       
                     this.ReturnTimePeriodGridLoader =false;
                    });
     }  
   
     private flattenData(data:any){
        var flattenedData:any[] = [];
        for(var counter = 0; counter<data.length; counter++){
           var row = {};
           var returnsWithPeriodicData = data[counter];
           for(var prop in returnsWithPeriodicData.dataSource){
               row[prop] = returnsWithPeriodicData.dataSource[prop];
           }
           if(null!= returnsWithPeriodicData.yearlyData && undefined!= returnsWithPeriodicData.yearlyData){
            for(var yearlyCounter = 0; yearlyCounter<returnsWithPeriodicData.yearlyData.length; yearlyCounter++){
                row[returnsWithPeriodicData.yearlyData[yearlyCounter].name] = returnsWithPeriodicData.yearlyData[yearlyCounter].value;
            }
           }
           if(null!= returnsWithPeriodicData.quarterlyData && undefined!= returnsWithPeriodicData.quarterlyData){
            for(var quarterlyCounter = 0; quarterlyCounter<returnsWithPeriodicData.quarterlyData.length; quarterlyCounter++){
                row[returnsWithPeriodicData.quarterlyData[quarterlyCounter].name] = returnsWithPeriodicData.quarterlyData[quarterlyCounter].value;
               }
           }    
           if(null!= returnsWithPeriodicData.monthlyData && undefined!= returnsWithPeriodicData.monthlyData){
            for(var monthlyCounter = 0; monthlyCounter<returnsWithPeriodicData.monthlyData.length; monthlyCounter++){
                row[returnsWithPeriodicData.monthlyData[monthlyCounter].name] = returnsWithPeriodicData.monthlyData[monthlyCounter].value;
               }
           }       
           
           flattenedData.push(row);
        }
        return flattenedData;
    }
    
    PopupInfo(): void{         
        let dialogRef = this.dialog.open(FactorsReturnTimePeriodGridComponentDialog, {
          width: '800px',
          data: this.indexMarkers                  
        });
      }

      Exportxls():void{
        this.landingService.getIndexReturns(this.asOfDate,this.indexMarkers,this.asOfDate,this.startDate,
            this.currencyCode,this.returnType); 
            this.landingService.indexCalculatorDataByValuesLoaded.subscribe(res=> {
                this.exportService.exportData(this.flattenData(res),"Factors Returns Time Period GridData");
                this.ReturnTimePeriodGridLoader =false;
            });
      }
}


@Component({
    selector: 'factors-return-time-period-grid.component.widget.dialog',
    templateUrl: './factors-return-time-period-grid.component.widget.dialog.html'
})
export class FactorsReturnTimePeriodGridComponentDialog {
    @Input() indexes: string;
    constructor(
    public dialogRef: MatDialogRef<FactorsReturnTimePeriodGridComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.indexes="";
       data.forEach(element => {
           if( this.indexes.length<3)
           {
              this.indexes=element;
           }else{
          this.indexes += ", "+ element;
           }
       });   
     }
  
  onClose(): void {
      this.dialogRef.close();
}
}