import { Component, OnInit } from '@angular/core';
import { PublishIndexMarkerService } from '../services/publish-index-marker.service';
import { BiService } from '../services/BiService.service';
import { ExportToExcelService } from '../services/exportToExcel.service';
import { DataqualityserviceService } from '../services/dataqualityservice.service';
import { DateService } from '../services/date.service';
import { IndexMarkerService } from '../services/indexmarker.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-country-data-quality-component',
  templateUrl: './country-data-quality-component.component.html',
  styleUrls: ['./country-data-quality-component.component.css']
})
export class CountryDataQualityComponentComponent implements OnInit {
  asOfDate : any;
  indexDetailsModel : any;
  indexMarkers: any[]= [];
  countrydata:any;
  DataGridLoader:boolean =true;
  RowExpandLoader:boolean[] =[]; 
  panelOpenState = false;
  client :string;
  constructor( private exportService:ExportToExcelService, private dataqualityservice:DataqualityserviceService,private dateService:DateService
    ,private route: ActivatedRoute) { }

  ngOnInit() {
    this.asOfDate = this.dateService.getCalendarDate().end; 
    var that = this;
    this.dateService.calendarDatePublish().subscribe(result=>{ 
      that.asOfDate = moment(result.end).format('MM/DD/YYYY');
      that.gridSetup();
  });
  var sub = this.route.queryParams.subscribe(params => {  
    this.client =params['Client'] ;
    if(this.client== undefined)
    {
      this.client= "ALL";
    }
    this.gridSetup();
  });
  }

  getCountryDetails(element,index){
    if(this.panelOpenState == true){
      this.RowExpandLoader[index] = true;
      this.dataqualityservice.getCountryDifferenceDetails(element.securityId ,this.asOfDate , this.client).then(data=>{
      this.indexDetailsModel = data["data"] ;
      this.RowExpandLoader[index] = false;
    },onerror =>{
      this.RowExpandLoader[index] =false; 
  });
  }
  }

  gridSetup(){
    var that = this;
    that.RowExpandLoader = [];
          this.DataGridLoader   = true;
          this.dataqualityservice.getCountryDifference(this.asOfDate , this.client).then(data=>{
          this.countrydata=data;
          var index = 0;
          data.forEach(element => {
            that.RowExpandLoader[index] = false;
            index++;
          });
          this.DataGridLoader=false;
          },onerror =>{
          this.DataGridLoader =false; 
          });
    }

    ExportXls(element):void{
      this.dataqualityservice.getCountryDifferenceDetailsExport(this.asOfDate,element.securityId , this.client).then(res => {
              this.exportService.exportData(res["data"],"Country data");
              }).catch(err=> { 
              }); 
    }

}
