import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DataqualityserviceService } from '../../services/dataqualityservice.service';
import { DateService } from '../../services/date.service';

@Component({
  selector: 'app-summary-data-quality-component',
  templateUrl: './summary-data-quality-component.component.html',
  styleUrls: ['./summary-data-quality-component.component.css']
})
export class SummaryDataQualityComponentComponent implements OnInit {
  asOfDate : any;
  client :string;
  summarydata:any;
  constructor(  private dataqualityservice:DataqualityserviceService,private dateService:DateService
    ,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.asOfDate = this.dateService.getCalendarDate().end; 
    var that = this;
    this.dateService.calendarDatePublish().subscribe(result=>{ 
      that.asOfDate = moment(result.end).format('MM/DD/YYYY');
      that.gridSetup();
  });
  var sub = this.route.params.subscribe(params => {   
    this.client =params['Client']    ;
    this.gridSetup();
  }); 
  }
  gridSetup(){
    this.dataqualityservice.getSummaryData(this.asOfDate).then(data=>{
      this.summarydata = data;
    });
  }

}
