import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {DateService} from '../../services/date.service';
import {ReferenceService} from '../../services/reference.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatTable,MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {ReportFilter,SearchSecurity} from '../../models/report-filter.model';
import { PagerService } from '../../services/pager.service';
import * as moment from 'moment';

@Component({
  selector: 'referencedata-table',
  templateUrl: './referencedata-table.component.html',
  styleUrls: ['./referencedata-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ReferencedataTableComponent implements AfterViewInit, OnInit {
  endDate : any;
  constructor(private dateService:DateService,private referenceService:ReferenceService,
    private pagerService: PagerService){}
  
  dataSource:  MatTableDataSource<SearchSecurity>;
  searchModel : any;
  indexDetailsModel : any;
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['securityIdentifier', 'securityName'];
  pager: any = {};
  filesPaged: any[] =[];
  page:any = 1;
  pageSize :any = 15;
  totalEntries :any = 0;
  productIndexMarkerFilter : any = {};
  securitySearchFilter: any = {};
  isProductIndexMarker:boolean = false;
  isSecuritySearch:boolean = false;
  asOfDate :any;
  securityIdentifierFilter : any;
  securityNameFilter : any;
  securityIdFilter : any; 
  productCodeFilter : any;;
  indexNameFilter:any;
  indexMarkerFilter:any;
  securityExapnsionFilter:any={};
  panelOpenState = false;
searchResult : any;
referenceSecurityDetailsLoader:boolean[] =[]; 
  ngOnInit() {
    this.endDate =this.dateService.getCalendarDate().end;
  //   this.referenceService.getSeuritySearchwithoutpaging(this.endDate).then(data=>{  
  //     this.searchModel =data;
  //     this.searchResult = data;
  //   console.log(data);    
  //   const rows = [];
  //   this.searchModel.forEach(element => rows.push(element, { detailRow: true, element }));
  //   rows.forEach(function (ele, index) {
  //     ele.srNo = index;
  //     ele.show = false;
  //   });
  //   this.dataSource =  new MatTableDataSource(rows);
  //   console.log(this.dataSource);  
  // });
  this.securitySearchFilter = {
    
    SecurityIdentifier: this.securityIdentifierFilter,
    
   SecurityName: this.securityNameFilter,
    
   SecurityId: this.securityIdFilter,

   ProductCode : this.productCodeFilter
    
    };
    
this.securityExapnsionFilter={
  IndexName: this.indexNameFilter,
  IndexMarker: this.indexMarkerFilter,
  SecurityId: this.securityIdFilter,
  ProductCode: this.productCodeFilter
}

  this.referenceService.getsecuritySearch(this.page,this.pageSize,this.securitySearchFilter,this.endDate).then(data=>{
    this.searchResult = data["securitySearches"];
    this.referenceSecurityDetailsLoader = [];
    this.searchResult.forEach(element => {
      this.referenceSecurityDetailsLoader[element.securityId] = false;
    });
   this.totalEntries = data["totalResults"];
   
   this.setPage(this.page);
   
   });
   this.dateService.calendarDatePublish().subscribe(result=>{ 
    this.endDate = moment(result.end).format('MM/DD/YYYY');
    this.referenceService.getsecuritySearch(this.page,this.pageSize,this.securitySearchFilter,this.endDate).then(data=>{
      this.searchResult = data["securitySearches"];
     this.totalEntries = data["totalResults"];
     this.setPage(this.page);
     });
});
  }

 
    
   

  getSeuritySearchDetails(element){
    console.log(element);
    if(this.panelOpenState == true){
    this.referenceSecurityDetailsLoader[element.securityId] = true;
    this.referenceService.getSeuritySearchDetails(element.securityId,this.securityExapnsionFilter,this.endDate).then(data=>{
      this.indexDetailsModel = data["indexDetails"];
      console.log(data);
      element.indexDetailsModel = data["indexDetails"];
     // this.dataSource = data;
     console.log(element.indexDetailsModel);
       this.referenceSecurityDetailsLoader[element.securityId] = false;
    }).catch(err=>{
      this.referenceSecurityDetailsLoader[element.securityId] = false;
    });
  }

    const foundElement = this.dataSource.data.find(elem => elem !== undefined && elem.srNo === element.srNo)
        const index = this.dataSource.data.indexOf(foundElement);
        this.dataSource.data[index].show = !this.dataSource.data[index].show;
  }

  ngAfterViewInit() {

    
  }
  onsecurityIdentifierFilterFilterKey(event:any){
    
    this.page = 1;
    this.securitySearchFilter = {
      SecurityIdentifier: this.securityIdentifierFilter,
      SecurityName: this.securityNameFilter,
      SecurityId: this.securityIdFilter
    };
    this.page = 1;
    this.referenceService.getsecuritySearch(this.page,this.pageSize,this.securitySearchFilter,this.endDate).then(data=>{
      this.searchResult = data["securitySearches"];
        this.totalEntries = data["totalResults"];
        this.setPage(this.page);
        
      });

      this.referenceService.getSeuritySearchwithoutpaging(this.endDate).then(data=>{
      this.dataSource = data;
      });
}
onsecurityNameFilterFilterKey(event:any){
  
  this.page = 1;
  this.securitySearchFilter = {
    SecurityIdentifier: this.securityIdentifierFilter,
    SecurityName: this.securityNameFilter,
    SecurityId: this.securityIdFilter
  };
  this.page = 1;
  this.referenceService.getsecuritySearch(this.page,this.pageSize,this.securitySearchFilter,this.endDate).then(data=>{
    this.searchResult = data["securitySearches"];
      this.totalEntries = data["totalResults"];
      this.setPage(this.page);
      
    });
}
onsecurityIdFilterFilterKey(event:any){
  
  this.page = 1;
  this.securitySearchFilter = {
    SecurityIdentifier: this.securityIdentifierFilter,
    SecurityName: this.securityNameFilter,
    SecurityId: this.securityIdFilter
  };
  this.page = 1;
  console.log(this.securitySearchFilter)
  this.referenceService.getsecuritySearch(this.page,this.pageSize,this.securitySearchFilter,this.endDate).then(data=>{
    this.searchResult = data["securitySearches"];
      this.totalEntries = data["totalResults"];
      this.setPage(this.page);
      
    });
}
onindexNameFilterFilterKey(event:any,element){
  
 
  this.securityExapnsionFilter={
    IndexName: this.indexNameFilter,
    IndexMarker: this.indexMarkerFilter,
    SecurityId: this.securityIdFilter,
    ProductCode: this.productCodeFilter
  }
  
  this.referenceService.getSeuritySearchDetails(element.securityId,this.securityExapnsionFilter,this.endDate).then(data=>{
    this.indexDetailsModel = data["indexDetails"];
    console.log(data);
    element.indexDetailsModel = data["indexDetails"];
      
    });
}
onindexMarkerFilterFilterKey(event:any,element){
  
 
  this.securityExapnsionFilter={
    IndexName: this.indexNameFilter,
    IndexMarker: this.indexMarkerFilter,
    SecurityId: this.securityIdFilter,
    ProductCode: this.productCodeFilter
  }
  
  this.referenceService.getSeuritySearchDetails(element.securityId,this.securityExapnsionFilter,this.endDate).then(data=>{
    this.indexDetailsModel = data["indexDetails"];
    console.log(data);
    element.indexDetailsModel = data["indexDetails"];
      
    });
  }
  onpProductFilterFilterKey(event:any,element){
  
 
      this.securityExapnsionFilter={
        IndexName: this.indexNameFilter,
        IndexMarker: this.indexMarkerFilter,
        SecurityId: this.securityIdFilter,
        ProductCode: this.productCodeFilter
      }
      
      this.referenceService.getSeuritySearchDetails(element.securityId,this.securityExapnsionFilter,this.endDate).then(data=>{
        this.indexDetailsModel = data["indexDetails"] ;
        console.log(data);
        element.indexDetailsModel = data["indexDetails"];
          
        });
}

  setPage(page: number) {    
    this.pager = this.pagerService.getPager(this.totalEntries, page);      
  }
  fetchPage(page: number){
    this.page = page;
    
  
    this.securitySearchFilter = {
      SecurityIdentifier: this.securityIdentifierFilter,
      SecurityName: this.securityNameFilter,
      SecurityId: this.securityIdFilter,
      ProductCode: this.productCodeFilter
    };
  
    this.referenceService.getsecuritySearch(this.page,this.pageSize,this.securitySearchFilter,this.endDate).then(data=>{
       
      this.searchResult = data["securitySearches"];
      this.filesPaged = this.searchResult;
        this.totalEntries = data["totalResults"];
        this.setPage(this.page);
        
      });
  
      this.referenceService.getSeuritySearchwithoutpaging(this.endDate).then(data=>{
        this.dataSource = data;
      });
  
  }
  

}
