import {map} from 'rxjs/operators';
import { Injectable} from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import { DDSFile } from '../models/ddstracker.model';
import { of , BehaviorSubject ,  Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ResponseModel} from '../models/landing-page-model';

@Injectable()
export class DataqualityserviceService {

  constructor(private httpClient: HttpClient ) { }

  GetIcbSectorClassifictionDataQualities(asofdate: any,client:any) : Promise<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetIcbSectorClassifictionDataQualities',
    JSON.stringify({
        AsOfDate : asofdate,
        Client :client

}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var ddsFiles = result.data as any[];                    
                    return ddsFiles
                  })
              .catch(this.handleError);  

  }

  GetIcbSectorClassificationDataQualityDetails(asofdate: any, securityId:any ,client:any ):Promise<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetIcbSectorClassificationDataQualityDetails',
    JSON.stringify({
        AsOfDate : asofdate,
        SecurityId: securityId,
        Client :client

}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var ddsFiles = result.data as any[];                    
                    return ddsFiles
                  })
              .catch(this.handleError);  

  }

  GetIcbSectorClassificationDataQualityDetailsExportData(asofdate: any, securityId:any ,client:any):Promise<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetIcbDataQualityExportData',
    JSON.stringify({
        AsOfDate : asofdate,
        SecurityId: securityId,
        Client :client

}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var ddsFiles = result.data as any[];                    
                    return ddsFiles
                  })
              .catch(this.handleError);  

  }


  GetNicbSectorClassifictionDataQualities(asofdate: any,client:any) : Promise<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetNicbSectorClassifictionDataQualities',
    JSON.stringify({
        AsOfDate : asofdate,
        Client :client

}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var ddsFiles = result.data as any[];                    
                    return ddsFiles
                  })
              .catch(this.handleError);  

  }

  GetNicbSectorClassificationDataQualityDetails(asofdate: any, securityId:any ,client:any):Promise<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetNicbSectorClassificationDataQualityDetails',
    JSON.stringify({
        AsOfDate : asofdate,
        SecurityId: securityId,
        Client:client

}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var ddsFiles = result.data as any[];                    
                    return ddsFiles
                  })
              .catch(this.handleError);  

  }

  GetNicbSectorClassificationDataQualityDetailsExportData(asofdate: any, securityId:any ,client:any):Promise<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetNicbDataQualityExportData',
    JSON.stringify({
        AsOfDate : asofdate,
        SecurityId: securityId,
          Client :client  
}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var ddsFiles = result.data as any[];                    
                    return ddsFiles
                  })
              .catch(this.handleError);  

  }



  GetRgsSectorClassifictionDataQualities(asofdate: any,client:any) : Promise<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetRgsSectorClassifictionDataQualities',
    JSON.stringify({
        AsOfDate : asofdate,
        Client :client

}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var ddsFiles = result.data as any[];                    
                    return ddsFiles
                  })
              .catch(this.handleError);  

  }

  GetRgsSectorClassificationDataQualityDetails(asofdate: any, securityId:any,client:any):Promise<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetRgsSectorClassificationDataQualityDetails',
    JSON.stringify({
        AsOfDate : asofdate,
        SecurityId: securityId,
        Client :client

}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var ddsFiles = result.data as any[];                    
                    return ddsFiles
                  })
              .catch(this.handleError);  

  }

  GetRgsSectorClassificationDataQualityDetailsExportData(asofdate: any, securityId:any,client:any):Promise<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetRgsDataQualityExportData',
    JSON.stringify({
        AsOfDate : asofdate,
        SecurityId: securityId,
        Client :client
        
}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var ddsFiles = result.data as any[];                    
                    return ddsFiles
                  })
              .catch(this.handleError);  

  }


  getPriceData(asofdate: any, client:any) : Promise<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/getdataqualitypricedifference',
    JSON.stringify({
        AsOfDate : asofdate,
        Client : client
}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var ddsFiles = result.data as any[];                    
                    return ddsFiles
                  })
              .catch(this.handleError);  

  }

  getDataQualityPriceDifferenceDetails(securityId:any,asofdate: any ,client:any):Promise<any>{
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
    
          return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/getDataQualityPriceDifferenceDetails', 
          JSON.stringify({
            SecurityID: securityId,
            AsOfDate : asofdate,
            Client :client
               }),         {headers: headers})
                .toPromise()
                .then(response =>{
                     return response as any
                    })
    
                    .catch(this.handleError);
}

getOpenPriceDifference(asofdate: any ,client:any) : Promise<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/getopenpricedifference',
    JSON.stringify({
        AsOfDate : asofdate,
        Client :client
     
}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var ddsFiles = result.data as any[];                    
                    return ddsFiles
                  })
              .catch(this.handleError);  

  }

  getOpenPriceDifferenceDetails(securityId:any,asofdate: any,client:any):Promise<any>{
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
    
          return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/getopenpricedifferencedetails', 
          JSON.stringify({
            SecurityID: securityId,
            AsOfDate : asofdate,
            Client :client
               }),         {headers: headers})
                .toPromise()
                .then(response =>{
                     return response as any
                    })
    
                    .catch(this.handleError);
}

GetClosePriceDifferenceDetailsExport(asofdate: any ,securityId:any ,client :any):Promise<any>{
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
  
        return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetClosePriceDifferenceDetailsExport', 
        JSON.stringify({
          SecurityID: securityId,
          AsOfDate : asofdate,
          Client :client
             }),         {headers: headers})
              .toPromise()
              .then(response =>{
                   return response as any
                  })
  
                  .catch(this.handleError);
} 

GetOpenPriceDifferenceDetailsExport(asofdate: any ,securityId:any ,client:any):Promise<any>{
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
  
        return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetOpenPriceDifferenceDetailsExport', 
        JSON.stringify({
          SecurityID: securityId,
          AsOfDate : asofdate,
          Client :client
             }),         {headers: headers})
              .toPromise()
              .then(response =>{
                   return response as any
                  })
  
                  .catch(this.handleError);
} 

getCountryDifference(asofdate: any ,client:any) : Promise<any>
{
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetCountryDifference',
  JSON.stringify({
      AsOfDate : asofdate,
      Client :client
}), {headers: headers}).pipe(
      map(
          (response: Response) => 
          response as any
          ))
          .toPromise()
            .then(response =>{
                  var result = response as ResponseModel;
                  var ddsFiles = result.data as any[];                    
                  return ddsFiles
                })
            .catch(this.handleError);  

}

getCountryDifferenceDetails(securityId:any,asofdate: any ,client:any):Promise<any>{
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
  return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetCountryDifferenceDetails', 
  JSON.stringify({
          SecurityID: securityId,
          AsOfDate : asofdate,
          Client : client
             }),         {headers: headers})
              .toPromise()
              .then(response =>{
                   return response as any
                  })
  
                  .catch(this.handleError);
}

getCountryDifferenceDetailsExport(asofdate: any ,securityId:any, client:any):Promise<any>{
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
  return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetCountryDifferenceDetailsExport', 
  JSON.stringify({
      SecurityID: securityId,
      AsOfDate : asofdate,
      Client :client
      }),    {headers: headers})
      .toPromise()
      .then(response =>{
      return response as any
      })
       .catch(this.handleError);
} 
getSharesData(asofdate: any,client:any) : Promise<any>
{
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetDataQualitySharesOutstanding',
  JSON.stringify({
      AsOfDate : asofdate,
      Client : client
}), {headers: headers}).pipe(
      map(
          (response: Response) => 
          response as any
          ))
          .toPromise()
            .then(response =>{
                  var result = response as ResponseModel;
                  var ddsFiles = result.data as any[];                    
                  return ddsFiles
                })
            .catch(this.handleError);  

}
getSharesDataDetails(securityId:any,asofdate: any,client:any):Promise<any>{
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
  
        return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/getdataqualitysharesoutstandingdetails', 
        JSON.stringify({
          SecurityID: securityId,
          AsOfDate : asofdate,
          Client :client
             }),         {headers: headers})
              .toPromise()
              .then(response =>{
                   return response as any
                  })
  
                  .catch(this.handleError);
}
getSharesDataDetailsExport(asofdate: any ,securityId:any ,client:any):Promise<any>{
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
  
        return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/getdataqualitysharesoutstandingdetailsexport', 
        JSON.stringify({
          SecurityID: securityId,
          AsOfDate : asofdate,
          Client:client
             }),         {headers: headers})
              .toPromise()
              .then(response =>{
                   return response as any
                  })
  
                  .catch(this.handleError);
}
getMarketValueData(asofdate: any,client:any) : Promise<any>
{
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetDataQualityMarketValue',
  JSON.stringify({
      AsOfDate : asofdate,
      Client : client
}), {headers: headers}).pipe(
      map(
          (response: Response) => 
          response as any
          ))
          .toPromise()
            .then(response =>{
                  var result = response as ResponseModel;
                  var ddsFiles = result.data as any[];                    
                  return ddsFiles
                })
            .catch(this.handleError);  

}
getMarketValueDataDetails(securityId:any,asofdate: any,client:any):Promise<any>{
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
  
        return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/getdataqualitymarketvaluedetails', 
        JSON.stringify({
          SecurityID: securityId,
          AsOfDate : asofdate,
          Client:client
             }),         {headers: headers})
              .toPromise()
              .then(response =>{
                   return response as any
                  })
  
                  .catch(this.handleError);
}
getMarketValueDetailsExport(asofdate: any ,securityId:any,client:any):Promise<any>{
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
  
        return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/getdataqualitymarketvaluedetailsexport', 
        JSON.stringify({
          SecurityID: securityId,
          AsOfDate : asofdate,
          Client: client
             }),         {headers: headers})
              .toPromise()
              .then(response =>{
                   return response as any
                  })
  
                  .catch(this.handleError);
}
getSummaryData(asofdate: any) : Promise<any>
{
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/GetSummaryData',
  JSON.stringify({
      AsOfDate : asofdate
}), {headers: headers}).pipe(
      map(
          (response: Response) => 
          response as any
          ))
          .toPromise()
            .then(response =>{
                  var result = response as ResponseModel;
                  var ddsFiles = result.data as any[];                    
                  return ddsFiles
                })
            .catch(this.handleError);  

}

getClient() : Promise<any[]> {
         
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
 return this.httpClient.post(environment.dataServiceRoot + 'ui/dataquality/getclient', null, {headers: headers})
 .toPromise()
 .then(response =>{
  var result = response as ResponseModel;      
  return result.data;
     })
 .catch(this.handleError);    
}
  private handleError(error: any): Promise<any>
  {
      console.error('error occured',error);
      return Promise.reject(error.message || error);
  }  
}
