import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {BenchmarksService} from '../../services/benchmarks.service';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-select-benchmark',
  templateUrl: './select-benchmark.component.html',
  styleUrls: ['./select-benchmark.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectBenchmarkComponent),
      multi: true
    }
  ]
})
export class SelectBenchmarkComponent implements OnInit, ControlValueAccessor, OnDestroy {
  fc = new FormControl('');
  indexMarkers$ = this.benchmarksService.getAvailableIndexMarkers$();  
  sub = this.fc.valueChanges.subscribe((value) => {
    this.onChange(value);
  });
  onChange = (val: any) => {};
  onTouched = (val: any) => {};
  constructor(private benchmarksService: BenchmarksService) {}

  ngOnInit(): void {   
  }

  writeValue(obj: any): void {
    this.fc.setValue(obj, {emitEvent: false});
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    // tslint:disable-next-line:no-unused-expression
    this.sub && this.sub.unsubscribe();
  }
}
