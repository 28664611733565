import { Component, OnInit } from '@angular/core';
import { MenuItem } from '../../models/authorization';
import { AuthorizationService } from '../../services/authorization.service';

@Component({
  selector: 'app-menu-items',
  templateUrl: './menu-items-search.component.html',
  styleUrls: ['./menu-items-search.component.css']
})
export class MenuItemsSearchComponent implements OnInit {

  menuItemData: MenuItem[];
  commitStatus : any;
  constructor(private services:AuthorizationService) { }

  ngOnInit() {
    this.menuItemData = [{id:"",menuitem:"",routerlink:"",isClicked:""}];
    this.GetMenuDetails();
  }

GetMenuDetails(){
  this.services.getMenuItemInfo().then(data =>
    {
     this.menuItemData=data; 
     console.log('MenuItem Data', this.menuItemData); 
    }) 
}

  deleteMenuItem(element)
  {
    if(confirm("Are you sure to delete menuitem ?")){
          this.services.deleteMenuItem(element).then(res =>{
       this.commitStatus = "Deleted successfully";
       this.GetMenuDetails()
    });
   

  }
  }

}
