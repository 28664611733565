import {Component,ViewChild,Input} from '@angular/core';
import {CustomTableComponent} from '../custom-table/custom-table.component';
import {ReportAnalyseMethodGenerator} from '../../services/report-analyse-method-generator.service';
import {IndexMarkerService} from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {ReportFilter} from '../../models/report-filter.model';
import {DateDisplayService} from '../../services/date.display.service';
import { ɵAnimationGroupPlayer } from '@angular/animations';

@Component({
    selector: 'report-analyse-grid',
    templateUrl: './report-analyse-grid.component.html'
  })
export class ReportAnalyseGridComponent{

    @Input() visible: boolean = true;
    value:any='.2-2';
    TrackingReportGridTitle: boolean=false;
 
    visibleColums: any;
       weightChangesColumns: any[];
       weightChanges: any;
       indexLevelsColumns: any[];
       indexLevels: any;
       divindendChangesColumns: any[];
       dividendChanges: any; 
  
    reportFilter:ReportFilter;
    gridCustomColumns: any[];
    gridData:any; 
    excelNameMatrix:string[] = [];

    PerformanceAnalyseGridLoader:boolean=false;
    constructor(private reportAnalyseMethodGenerator: ReportAnalyseMethodGenerator,private indexMarkerService:IndexMarkerService,
    private exportToExcelService: ExportToExcelService, private dateDisplayService:DateDisplayService) { 
        this.excelNameMatrix["getDrawdownExcessReturnsAnalysis"] = "PerformanceAnalysis";
        this.excelNameMatrix["getRiskReturnList"] = "RiskReturn";
        this.excelNameMatrix["getDistinctProductFamilies"] = "ProductFamilies";
        this.excelNameMatrix["getTrackingReport"] = "FiveDaysTrackingReport";
        this.excelNameMatrix["getDailyTrackingReport"] = "OneDayTrackingReport";
    }
        @ViewChild(CustomTableComponent,{static: false}) customTableComponent; 
        getCurrentCustomColumns():any[]{
          return this.gridCustomColumns;
        }        
        
        gridSetup(reportFilter:ReportFilter):void{
            this.PerformanceAnalyseGridLoader = true;
            this.gridData = [];
            console.log(reportFilter);
            this.gridCustomColumns = [];
            
            var customColumnService = this.reportAnalyseMethodGenerator.getServiceInstance(reportFilter.ReportCustColumnsCode);
            var customColumnMethod = this.reportAnalyseMethodGenerator.getMethodsGeneratorParams(reportFilter.ReportCustColumnsCode);
            var customColumnsParams =  customColumnMethod(reportFilter,this.indexMarkerService.getIndexMarkers());                     
            customColumnService[this.reportAnalyseMethodGenerator.getMethodName(reportFilter.ReportCustColumnsCode)].apply(customColumnService,customColumnsParams).then(customs=>{
                this.gridCustomColumns = customs;
                var targetService = this.reportAnalyseMethodGenerator.getServiceInstance(reportFilter.ReportCode);
                var paramsGeneratorMethod = this.reportAnalyseMethodGenerator.getMethodsGeneratorParams(reportFilter.ReportCode);
                var targetParams = paramsGeneratorMethod(reportFilter,this.indexMarkerService.getIndexMarkers());
                targetService[this.reportAnalyseMethodGenerator.getMethodName(reportFilter.ReportCode)].apply(targetService,targetParams).then(data=>{
                   
                this.gridData = (reportFilter.ReportCode == 'report-getDrawdownExcessReturnsAnalysis')?this.drawDownCustomGrid(data,customs):data;
                 
                   if(this.gridData.data.indexLevelData!=undefined)
                   {
                        this.indexLevels= this.dateDisplayService.getArrayDateDisplay(this.gridData.data.indexLevelData,"asofdate");
                        this.indexLevelsColumns=this.changeColumnNames(customs.indexLevelsColumns);
                        this.weightChanges= this.dateDisplayService.getArrayDateDisplay(this.gridData.data.weightChangesData,"asofdate");
                        this.weightChangesColumns=this.changeColumnNames(customs.weightChangesColumns);
                        this.dividendChanges= this.dateDisplayService.getArrayDateDisplay(this.gridData.data.dividendChangesData,"asofdate");
                        this.divindendChangesColumns=this.changeColumnNames(customs.divindendChangesColumns);
                        this.TrackingReportGridTitle=true;
                   }
                   else{
                    this.TrackingReportGridTitle=false;
                    this.weightChanges=[];
                    this.weightChangesColumns=[];
                    this.dividendChanges=[];
                    this.divindendChangesColumns=[];
                    if(reportFilter.ReportCode=="report-getDrawdownExcessReturnsAnalysis"){
                        this.value=".2-4"
                    }
                      else{
                         this.value=".2-2"
                      }
                    this.customTableComponent.bind(this.dateDisplayService.getArrayDateDisplay(this.gridData.data,"asOfDate"),customs.data,this.value);
                   }
 
                this.PerformanceAnalyseGridLoader = false;
            },onerror =>{
                this.disableGrids();     
             })
              },onerror =>{
                   this.disableGrids();
             });
        }

        exportData(reportFilter:ReportFilter):void{
            var targetService = this.reportAnalyseMethodGenerator.getServiceInstance(reportFilter.ReportCode);
            var paramsGeneratorMethod = this.reportAnalyseMethodGenerator.getMethodsGeneratorParams(reportFilter.ReportCode);
            var targetParams = paramsGeneratorMethod(reportFilter,this.indexMarkerService.getIndexMarkers());
            targetService[this.reportAnalyseMethodGenerator.getMethodName(reportFilter.ReportCode)].apply(targetService,targetParams).then(result=>{
                this.exportToExcelService.exportData(result.data,
                    this.excelNameMatrix[this.reportAnalyseMethodGenerator.getMethodName(reportFilter.ReportCode)]);
            },onerror =>{
                console.log("error while Trying to export the data")       
             })
        }
        disableGrids()
        {
            this.indexLevels=[];
            this.indexLevelsColumns=[];
            this.TrackingReportGridTitle=false;
            this.weightChanges=[];
            this.weightChangesColumns=[];
            this.dividendChanges=[];
            this.divindendChangesColumns=[];
            this.PerformanceAnalyseGridLoader =false;   

        }
        changeColumnNames(customColumns:any[]):any[]{
             this.visibleColums=[];
             for(let elem of customColumns){  
                  if(elem.columnVisibility){
                      if(elem.columnName!="Select All")
                      {
                      var visibleElem =  {UIDisplayValue: elem.uiDisplayValue,ColumnCssClass:elem.columnCssClass,
                        ColumnName:elem.columnName.substring(0,1).toLowerCase()+elem.columnName.substring(1),
                     HasFilter: elem.hasFilter, Filter:elem.filter};
                      this.visibleColums.push(visibleElem);
                    }
                  }
         
              }
         
              return this.visibleColums;
         
          }

          drawDownCustomGrid(data:any,customs:any):any{
            var ret = {"data": {}};
            var mappingdata: any = [];
            data.data.forEach((val,key)=> {
                var dataelement:any = {};
                dataelement["asOfDate"] =val["asOfDate"];
                dataelement[customs.data[1].columnName.substring(0,1).toLowerCase()+customs.data[1].columnName.substring(1)] = val["firstIndexValue"];
                dataelement[customs.data[2].columnName.substring(0,1).toLowerCase()+customs.data[2].columnName.substring(1)] = val["secondIndexValue"];
                dataelement[customs.data[3].columnName.substring(0,1).toLowerCase()+customs.data[3].columnName.substring(1)] = val["firstIndexDailyReturn"];
                dataelement[customs.data[4].columnName.substring(0,1).toLowerCase()+customs.data[4].columnName.substring(1)] = val["secondIndexDailyReturn"];
                dataelement[customs.data[5].columnName.substring(0,1).toLowerCase()+customs.data[5].columnName.substring(1)] = val["firstIndexDrawdown"];
                dataelement[customs.data[6].columnName.substring(0,1).toLowerCase()+customs.data[6].columnName.substring(1)] = val["secondIndexDrawdown"];
                dataelement["excessReturn"] = val["excessReturn"];
                mappingdata.push(dataelement);
            });
            ret["data"] = mappingdata;
            return ret;
          }
           
          

}