import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
//#endregion
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs'; 
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatRippleModule } from '@angular/material/core';
//#region [custom module import statements]
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './modules/app-routing.module';
import { ChartsModule } from 'ng2-charts';
import {ScrollingModule} from '@angular/cdk/scrolling';
import * as FusionCharts from 'fusioncharts';
import { FusionChartsModule } from 'angular-fusioncharts';
import { Daterangepicker } from 'ng2-daterangepicker';
FusionCharts.options["creditLabel"]=false;
//#endregion
let Charts = require('fusioncharts/fusioncharts.charts');
let PowerCharts = require("fusioncharts/fusioncharts.powercharts");
let TreeMapCharts = require("fusioncharts/fusioncharts.treemap");
let zoomLineCharts = require("fusioncharts/fusioncharts.zoomline");
export function FusionChartsProvider() {
  // Resolve charts dependency
  Charts(FusionCharts);
  PowerCharts(FusionCharts);
  TreeMapCharts(FusionCharts);
  zoomLineCharts(FusionCharts);
  return FusionCharts;
}
import {MatNativeDateModule} from '@angular/material/core';
import { LZStringModule, LZStringService } from 'ng-lz-string';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

//#region [component import statements]
import { AppComponent } from './app.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { GrowthOfUnitComponent  } from './components/landing-growth-of-unit/growth-of-unit.component';
import { GrowthOfUnitComponentDialog  } from './components/landing-growth-of-unit/growth-of-unit.component';
import { CloseHoldingsPageComponent } from './components/close-holdings-page/close-holdings-page.component';
import { CloseTopBottomHoldingsChartComponent,CloseTopBottomHoldingsChartComponentDialog,CloseTopBottomHoldingsChartComponentWidgetDialog } from './components/close-holdings-top-bottom-holdings-chart/close-holdings-top-bottom-holdings-chart.component';
import { CloseTopLevelSectorChartComponent,CloseTopLevelSectorChartComponentDialog} from './components/close-holdings-top-level-sector-chart/close-holdings-top-level-sector-chart.component';
import { CloseCountryWeightsChartComponent,CloseCountryWeightsChartComponentDialog,CloseCountryWeightsChartComponentWidgetDialog } from './components/close-holdings-country-weights-chart/close-holdings-country-weights-chart.component';
import { CloseCurrencyWeightsChartComponent,CloseCurrencyWeightsChartComponentDialog,CloseCurrencyWeightsChartComponentWidgetDialog } from './components/close-holdings-currency-weights-chart/close-holdings-currency-weights-chart.component';
import { OpenHoldingsPageComponent } from './components/open-holdings-page/open-holdings-page.component';
import { OpenTopBottomHoldingsChartComponent,OpenTopBottomHoldingsChartComponentDialog,OpenTopBottomHoldingsChartComponentWidgetDialog } from './components/open-holdings-top-bottom-holdings-chart/open-holdings-top-bottom-holdings-chart.component';
import { OpenCountryWeightsChartComponent,OpenCountryWeightsChartComponentDialog,OpenCountryWeightsChartComponentWidgetDialog } from './components/open-holdings-country-weights-chart/open-holdings-country-weights-chart.component';
import { OpenCurrencyWeightsChartComponent,OpenCurrencyWeightsChartComponentDialog,OpenCurrencyWeightsChartComponentWidgetDialog } from './components/open-holdings-currency-weights-chart/open-holdings-currency-weights-chart.component';
import { ChartToolbarComponent } from './components/chart-toolbar/chart-toolbar.component';
import { ChartToolbarGrowthofUnitComponent }  from './components/chart-toolbar-growthofunit/chart-toolbar-growthofunit.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { CloseTopHoldingsOptionsComponent } from './components/close-holdings-top-holdings-options/close-holdings-top-holdings-options.component';
import { OpenTopHoldingsOptionsComponent } from './components/open-holdings-top-holdings-options/open-holdings-top-holdings-options.component';
import { ChartOptionsComponent } from './components/chart-options/chart-options.component';
import { CloseRankedHoldingsGridComponent,CloseRankedHoldingsGridComponentDialog } from './components/close-holdings-ranked-holdings-grid/close-holdings-ranked-holdings-grid.component';
import { CloseHoldingsPositionGridComponent } from './components/close-holdings-position-grid/close-holdings-position-grid.component';
import { CloseHoldingsPositionGridComponentDialog } from './components/close-holdings-position-grid/close-holdings-position-grid.component';
import { CloseHoldingsQueueComponent } from './components/close-holdings-queue/close-holdings-queue.component';
import { OpenRankedHoldingsGridComponent,OpenRankedHoldingsGridComponentDialog } from './components/open-holdings-ranked-holdings-grid/open-holdings-ranked-holdings-grid.component';
import { OpenHoldingsPositionGridComponent } from './components/open-holdings-position-grid/open-holdings-position-grid.component';
import { OpenHoldingsPositionGridComponentDialog } from './components/open-holdings-position-grid/open-holdings-position-grid.component';
import { OpenHoldingsQueueComponent } from './components/open-holdings-queue/open-holdings-queue.component';
import { UserProfileToolbarComponent } from './components/user-profile-toolbar/user-profile-toolbar.component';
import { HomeToolbarComponent } from './components/home-toolbar/home-toolbar.component';
import { LoginPageComponent }   from './components/login-page/login-page.component';
import { LandingPageComponent }   from './components/landing-page/landing-page.component';
import { LandingPageChartsComponent } from './components/landing-page-charts/landing-page-charts.component';
import { MarketCapReturnsChartComponent, MarketCapReturnsChartComponentDialog } from './components/landing-market-cap-returns-chart/market-cap-returns-chart.component';
import { LandingRiskReturnComparisonComponent,LandingRiskReturnComparisonComponentDialog } from './components/landing-risk-return-comparison/landing-risk-return-comparison.component';
import { LandingSectorContributionChartComponent} from './components/landing-sector-contribution-chart/landing-sector-contribution-chart.component';
import { LandingTopNavigationChartComponent} from './components/landing-top-navigation-chart/landing-top-navigation-chart.component';
import { LandingGridComponent, LandingGridComponentDialog} from './components/landing-grid/landing-gird.component';
import { PerformancePageComponent } from './components/performance-page/performance-page.component';
import { PerformanceHeatChartComponent,PerformanceHeatChartDialog, PerformanceHeatChartWidgetDialog } from './components/performance-heat-chart/performance-heat-chart.component';
import { PerformanceTimeSeriesComponent,PerformanceTimeSeriesComponentDialog,PerformanceTimeSeriesComponentWidgetDialog } from './components/performance-time-series-chart/performance-time-series-chart.component';
import { PerformanceReturnsComponent,PerformanceReturnsComponentDialog,PerformanceReturnsComponentWidgetDialog } from './components/performance-returns-chart/performance-returns-chart.component';
import {PerformanceQueueComponent} from './components/performance-queue/performance-queue.component';
import { PerformanceCalendarReturnsComponent,PerformanceCalendarReturnsComponentDialog, PerformanceCalendarReturnsComponentWidgetDialog } from './components/performance-calendar-return-chart/performance-calendar-return-chart.component';
import { PerformanceGridComponent, PerformanceGridComponentDialog } from './components/performance-grid/performance-grid.component';
import { ValuesPageComponent} from './components/values-page/values-page.component';
import { ValuesGrowthofUnitTotalComponent,ValuesGrowthofUnitTotalComponentDialog,ValuesGrowthofUnitTotalComponentWidgetDialog} from './components/values-growth-of-unit-total/values-growth-of-unit-total.component';
import { ValuesGrowthofUnitPriceComponent,ValuesGrowthofUnitPriceComponentDialog,ValuesGrowthofUnitPriceComponentWidgetDialog} from './components/values-growth-of-unit-price/values-growth-of-unit-price.component';
import { ValuesConstituentCountComponent, ValuesConstituentCountComponentDialog ,ValuesConstituentCountComponentWidgetDialog} from './components/values-constituent-count/values-constituent-count.component';
import { ValuesMarketCapComponent,ValuesMarketCapComponentDialog,ValuesMarketCapComponentWidgetDialog}  from './components/values-market-cap/values-market-cap.component';
import {ValuesQueueComponent} from './components/values-queue/values-queue.component';
import { ValuesGridComponent} from './components/values-grid/values-grid.component';
import { ValuesGridComponentWidgetDialog} from './components/values-grid/values-grid.component';
import { SearchComponent} from './components/search/search-component';
import { CustomColumnChooserComponent} from './components/custom-column-chooser/custom-column-chooser.component';
import { CustomTableComponent} from './components/custom-table/custom-table.component';
import { CustomTableHoldingsComponent } from './components/custom-table-holdings/custom-table-holdings.component';
import { ReportFileDownloadComponent} from './components/report-file-download/report-file-download.component';
import {ReportS3FileDownloadComponent} from './components/report-s3-file-download/report-s3-file-download.component';
import {ReportIndexCharacteristicsComponent} from './components/report-index-characteristics/report-index-characteristics.component';
import { LandingTopBottomGridComponent,LandingTopBottomGridComponentDialog} from './components/landing-top-bottom-grid/landing-top-bottom-grid.component';
import { WeightPageComponent } from './components/weight-page/weight-page.component';
import { ReportingPageComponent} from './components/reporting-page/reporting-page.component';
import { ReportAnalyseGridComponent} from './components/report-analyse-grid/report-analyse-grid.component';
import { ReferencePageComponent} from './components/reference-page/reference-page.component';
import { WeightSectorWeightsTreeComponent} from './components/weight-sector-weights-tree/weight-sector-weights-tree.component';
import { StartupPageComponent }   from './components/startup-page/startup-page.component';
import { EntitlementtestComponent} from './components/entitlementtest/entitlementtest.component';
import { CompanyComponent } from './components/company/company.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ApiConfigurationComponent } from './components/api-configuration/api-configuration.component';
import { CompanyApiSearchComponent } from './components/company-api-search/company-api-search.component';
import { CompanySearchComponent } from './components/company-search/company-search.component';
import { ApiKeysComponent } from './components/api-keys/api-keys.component';
import { ApiKeySearchComponent } from './components/apikey-search/apikey-search.component';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { WavesComponent } from './components/waves/waves.component';
import { APIReportComponent } from './components/apireport/apireport.component'
import { FileloadstatusComponent } from './components/fileloadstatus/fileloadstatus.component'
import {LAMComponent} from './components/lam/lam.component';
import {KPIComponent} from './components/lam/kpi/kpi.component';
import {FactorsPageComponent} from './components/factors-page/factors-page.component';
import {FactorsDrawdownChartComponent,FactorsDrawdownChartComponentDialog,FactorsDrawdownChartComponentWidgetDialog} from './components/factors-drawdown-chart/factors-drawdown-chart.component';
import {FactorsCalendarYearReturnChartComponent,FactorsCalendarYearReturnChartComponentDialog,FactorsCalendarYearReturnChartComponentWidgetDialog} from './components/factors-calendar-year-return-chart/factors-calendar-year-return-chart.component';
import {FactorsCalendarYearReturnGridComponent,FactorsCalendarYearReturnGridComponentDialog} from './components/factors-calendar-year-return-grid/factors-calendar-year-return-grid.component';
import {FactorsRiskReturnGridComponent,FactorsRiskReturnGridComponentDialog} from './components/factors-risk-return-grid/factors-risk-return-grid.component';
import {FactorsReturnTimePeriodGridComponent,FactorsReturnTimePeriodGridComponentDialog} from './components/factors-return-time-period-grid/factors-return-time-period-grid.component';
import {FactorsPerformanceRatiosGridComponent,FactorsPerformanceRatiosGridComponentDialog} from './components/factors-performance-ratios-grid/factors-performance-ratios-grid.component';
import {FactorsCumulativeTotalReturnChartComponent,FactorsCumulitiveTotalReturnChartComponentDialog,FactorsCumulitiveTotalReturnChartComponentWidgetDialog} from './components/factors-cumulative-total-return-chart/factors-cumulative-total-return-chart.component';
import { WeightSectorHistoryWeightsCountryChartComponent,WeightSectorHistoryWeightsCountryChartComponentDialog } from './components/weight-sector-history-weights-country-chart/weight-sector-history-weights-country-chart.component';
import { WeightSectorHistoryWeightsSectorChartComponent,WeightSectorHistoryWeightsSectorChartComponentDialog } from './components/weight-sector-history-weights-sector-chart/weight-sector-history-weights-sector-chart.component';
import { WeightSectorHistoryWeightsSubSectorChartComponent,WeightSectorHistoryWeightsSubSectorChartComponentDialog } from './components/weight-sector-history-weights-subsector-chart/weight-sector-history-weights-subsector-chart.component';
import { WeightSectorHistoryIndustryComponent, WeightSectorHistoryIndustryComponentDialog } from './components/weight-sector-history-weights-industry-chart/weight-sector-history-weights-industry-chart.component';
import { WeightSectorHistorySuperSectorComponent, WeightSectorHistorySuperSectorComponentDialog } from './components/weight-sector-history-weights-supersector-chart/weight-sector-history-weights-supersector-chart.component';
import {OtherPageComponent} from './components/other-page/other-page.component';
import {PerformanceIndicatorsReportComponent} from './components/performance-indicators-report/performance-indicators-report.component';
import {GlossaryPageComponent} from './components/glossary-page/glossary-page.component';
import {IndexOfInterestComponent} from './components/index-of-interest/index-of-interest.component';
import {FundamentalsReportComponent, FundComponentDialog} from './components/fundamentals-report/fundamentals-report.component';
import { PriceDataQualityComponent } from './components/price-data-quality/price-data-quality.component';
import {SectorDataQualityComponent} from './components/sector-data-quality/sector-data-quality.component';
import {NicbSectorDataQualityComponent} from './components/nicb-sector-data-quality/nicb-sector-data-quality.component';
import {RgsSectorDataQualityComponent} from './components/rgs-sector-data-quality/rgs-sector-data-quality.component';
import {ClientIndexMappingFormComponent } from './components/client-index-mapping-form/client-index-mapping-form.component';
import {NewClientIndexMappingPopupComponent} from './components/new-client-index-mapping-popup/new-client-index-mapping-popup.component';

//#region [service import statements]
// import { EnvironmentSpecificModule, EnvironmentSpecificService } from './services/environmentSpecific.Service';
import { GrowthOfUnitService } from './services/growth-of-unit.service';
import { AuthenticateService } from './services/authenticate.service';
import { ExportToExcelService} from './services/exportToExcel.service';
import {SanetizeStringService} from './services/sanetize-string.service';
import { SearchService } from './services/search.service';
import { ColumnChooserService} from './services/column-chooser.service';
import { S3ManagerService} from './services/s3-manager.service';
//#endregion
import { CloseHoldingService } from './services/close-holdings.service';
import { OpenHoldingService } from './services/open-holdings.service';
import { WeightService} from './services/weight.service';
import { PerformanceService } from './services/performance.service';
import { CoreHttpInterceptor } from './services/CoreHttpInterceptor';
import { FilterPipe ,FilterHoldingsCurrencyTypePipe,FilterReturnTypePipe, FilterReviewProductsPipe, FilterTelemetryProducts, FilterEvents, FilterReviewSchedulePipe,UKDateFormatPipe,USDateFormatPipe,FilterDDSPipe,ProductIndexMarkerPipe,IndexMarkerPropertiesPipe,SearchIndexMarkerPipe} from './filter.pipe';

import { ValuesService} from './services/values.service';
import { LandingService} from './services/landing.service';
import { IndexMarkerService} from './services/indexmarker.service'
import { PublishIndexMarkerService} from './services/publish-index-marker.service';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import {CustomDateRangePickerComponent} from './components/custom-date-range-picker/custom-date-range-picker.component';
import { MagellanColorService} from './services/magellancolor.service';
import { ReportService} from './services/report.service';
import { ReportAnalyseMethodGenerator} from './services/report-analyse-method-generator.service';
import {DateService} from './services/date.service';
import {CurrencyService} from './services/currency.service';
import {DateDisplayService} from './services/date.display.service';
import {EntitlementService } from './services/entitlement.service';
import {AdminReportService} from './services/adminreportservice';
import {ReferenceService} from './services/reference.service';
import {ReviewsService} from './services/reviews.service';
import {BiService} from './services/BiService.service';
import {BiHeartBeatServiceService} from './services/bi-heart-beat-service.service';
import{IDWChartExportService} from './services/idw-chart-export.service';
import {IDWChartXlsExportService} from './services/idw-chart-xls-export.service';
import {IDWChartPngExportService} from './services/idw-chart-png-export.service';
import {IDWFusionChartsPngExportService} from './services/idw-fusion-charts-png-export.service';

import { ReviewsComponent } from './components/reviews/reviews.component';
import { ReviewProductsComponent } from './components/review-products/review-products.component';
import { ReviewScheduleComponent } from './components/review-schedule/review-schedule.component';
import { IdwChartComponent } from './components/idw-chart/idw-chart.component';
import { ChartTypeComponent } from './components/chart-type/chart-type.component';
import { DdstrackerComponent,DdstrackerVersionComparerComponent } from './components/ddstracker/ddstracker.component';
import { DdsService } from './services/dds.service';
import { PagerService } from './services/pager.service';
import {UserTabChangeService} from './services/user-tab-change.service';
import {UINotificationService} from './services/ui-notification.service';
import {PerformanceIndicatorsReportService} from './services/performance-indicators-report.service';
import {FactorsService} from './services/factors.service';
import { AuthorizationService } from './services/authorization.service'

import {ReferencedataTableComponent} from './components/referencedata-table/referencedata-table.component';
import { DataqualityserviceService } from './services/dataqualityservice.service';
import { OpenpriceDataQualtityComponent } from './components/openprice-data-qualtity/openprice-data-qualtity.component';
import { CountryDataQualityComponentComponent } from './country-data-quality-component/country-data-quality-component.component';
import { SharesDataQualityComponent } from './components/shares-data-quality/shares-data-quality.component';


//agm import by vicky
import {AgmCoreModule} from '@agm/core';
import { GeographicalInformationComponent } from './components/geographical-information/geographical-information.component';
import {GeographicalInformationService} from './services/geographical-information.service';
import { TelemetryLogs } from './components/telemetry-logs/telemetry-logs.component';
import { SedolAlertComponent } from './components/sedol-alert/sedol-alert.component';
import { SedolSearchComponent } from './components/sedol-search/sedol-search.component';

import { MarketValueDataqualityComponent } from './components/market-value-dataquality/market-value-dataquality.component';

import { AlternateDataDeliveryComponent } from './components/alternate-data-delivery/alternate-data-delivery.component';
import { SummaryDataQualityComponentComponent } from './components/summary-data-quality-component/summary-data-quality-component.component';

import { UserGroupSearchComponent } from './components/usergroup-search/usergroup-search.component';
import { UserGroupComponent } from './components/usergroup/usergroup.component';
import { RolePageMappingComponent } from './components/role-page-mapping/role-page-mapping.component';
import { RolePageMappingSearchComponent } from './components/role-page-mapping-search/role-page-mapping-search.component';
import { UserRoleMappingComponent } from './components/user-role-mapping/user-role-mapping.component';
import { MenuItemsSearchComponent } from './components/menu-items-search/menu-items-search.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import {ErrorNotificationComponent} from './pbr/components/error-notification/error-notification.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import {LicenceBandsComponent} from './components/licence-bands/licence-bands.component';
import {LicenceBandsSearchComponent } from './components/licence-bands-search/licence-bands-search.component';
import { DataQualityClientDropdownComponent } from './components/data-quality-client-dropdown/data-quality-client-dropdown.component';
import { PbrModule } from './pbr/pbr.module';
import {DatePipe} from '@angular/common';
import { MatSelectFilterModule } from 'mat-select-filter';
import { ExpandedRowComponent } from './components/common/expanded-row/expanded-row.component';
import { ExcelLikeGridComponent } from './components/common/excel-like-grid/excel-like-grid.component';
import { ClientIndexMappingGridComponent } from './components/client-index-mapping-grid/client-index-mapping-grid.component';
import { ClientIndexMappingDetailComponent } from './components/client-index-mapping-detail/client-index-mapping-detail.component';
import { AdditionalToolbarComponent } from './components/common/additional-toolbar/additional-toolbar.component';
import { ErrorHandlerComponent } from './components/common/error-handler/error-handler.component';
import { IndexComponent } from './components/index/index.component';
import { IndexEditComponent } from './components/index-edit/index-edit.component';
import { DataRestoreComponent } from './components/data-restore/data-restore.component';

// import {SelectIndexMarkerComponent} from './pbr/components/select-index-marker/select-index-marker.component';
// import { AsOfDatePickerComponent } from './pbr/components/as-of-date-picker/as-of-date-picker.component';
// import { ExpandedRowComponent } from './pbr/components/expanded-row/expanded-row.component';
// import {BenchmarksPageComponent} from './pbr/containers/benchmarks-page/benchmarks-page.component';
// import {BenchmarksGridComponent} from './pbr/components/benchmarks-grid/benchmarks-grid.component';

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatSelectModule,
    MatTabsModule,
    MatTooltipModule,
  ],
  entryComponents: [
    PerformanceHeatChartComponent
    ,PerformanceHeatChartDialog
    ,PerformanceHeatChartWidgetDialog
    ,PerformanceReturnsComponent
    ,PerformanceReturnsComponentDialog
    ,PerformanceReturnsComponentWidgetDialog
    ,PerformanceTimeSeriesComponent
    ,PerformanceTimeSeriesComponentDialog
    ,PerformanceTimeSeriesComponentWidgetDialog
    ,PerformanceCalendarReturnsComponent
    ,PerformanceCalendarReturnsComponentDialog
    ,PerformanceCalendarReturnsComponentWidgetDialog
    ,PerformanceGridComponentDialog
    ,LandingGridComponent
    ,ValuesGridComponentWidgetDialog
    ,GrowthOfUnitComponentDialog
    ,LandingTopBottomGridComponentDialog
    ,LandingGridComponentDialog
    ,LandingRiskReturnComparisonComponentDialog
    ,CloseHoldingsPositionGridComponentDialog
    ,MarketCapReturnsChartComponentDialog
    ,CloseRankedHoldingsGridComponentDialog
    ,ValuesMarketCapComponent
    ,ValuesMarketCapComponentDialog
    ,ValuesMarketCapComponentWidgetDialog
    ,ValuesConstituentCountComponent
    ,ValuesConstituentCountComponentDialog
    ,ValuesConstituentCountComponentWidgetDialog
    ,ValuesGrowthofUnitPriceComponent
    ,ValuesGrowthofUnitPriceComponentDialog
    ,ValuesGrowthofUnitPriceComponentWidgetDialog
    ,ValuesGrowthofUnitTotalComponent
    ,ValuesGrowthofUnitTotalComponentDialog
    ,ValuesGrowthofUnitTotalComponentWidgetDialog
    ,CloseTopBottomHoldingsChartComponent
    ,CloseTopBottomHoldingsChartComponentDialog
    ,CloseTopBottomHoldingsChartComponentWidgetDialog
    ,CloseTopLevelSectorChartComponent
    ,CloseTopLevelSectorChartComponentDialog
    ,CloseCountryWeightsChartComponent
    ,CloseCountryWeightsChartComponentDialog
    ,FundComponentDialog
    ,CloseCountryWeightsChartComponentWidgetDialog
    ,CloseCurrencyWeightsChartComponent
    ,CloseCurrencyWeightsChartComponentDialog
    ,CloseCurrencyWeightsChartComponentWidgetDialog
	,OpenTopBottomHoldingsChartComponent
    ,OpenTopBottomHoldingsChartComponentDialog
    ,OpenTopBottomHoldingsChartComponentWidgetDialog
    ,OpenHoldingsPositionGridComponentDialog
    ,OpenCountryWeightsChartComponent
    ,OpenCountryWeightsChartComponentDialog
    ,OpenCountryWeightsChartComponentWidgetDialog
    ,OpenCurrencyWeightsChartComponent
    ,OpenCurrencyWeightsChartComponentDialog
    ,OpenCurrencyWeightsChartComponentWidgetDialog
    ,OpenRankedHoldingsGridComponentDialog
    ,FactorsPageComponent
    ,GlossaryPageComponent
    ,FactorsDrawdownChartComponent
    ,FactorsDrawdownChartComponentDialog
    ,FactorsDrawdownChartComponentWidgetDialog
    ,FactorsCalendarYearReturnChartComponent
    ,FactorsCalendarYearReturnChartComponentDialog
    ,FactorsCalendarYearReturnChartComponentWidgetDialog
    ,FactorsCalendarYearReturnGridComponent
    ,FactorsCalendarYearReturnGridComponentDialog
    ,FactorsRiskReturnGridComponent,
    FactorsRiskReturnGridComponentDialog,
    FactorsReturnTimePeriodGridComponent,
    FactorsReturnTimePeriodGridComponentDialog,
    FactorsPerformanceRatiosGridComponent,
    FactorsPerformanceRatiosGridComponentDialog,
    FactorsCumulativeTotalReturnChartComponent,
    FactorsCumulitiveTotalReturnChartComponentDialog,
    FactorsCumulitiveTotalReturnChartComponentWidgetDialog,
    WeightSectorHistoryWeightsCountryChartComponent,
    WeightSectorHistoryWeightsCountryChartComponentDialog,
    WeightSectorHistoryWeightsSectorChartComponent,
    WeightSectorHistoryWeightsSectorChartComponentDialog,
    WeightSectorHistoryWeightsSubSectorChartComponent,
    WeightSectorHistoryWeightsSubSectorChartComponentDialog,
    WeightSectorHistoryIndustryComponent,
    WeightSectorHistoryIndustryComponentDialog,
    WeightSectorHistorySuperSectorComponent,
    WeightSectorHistorySuperSectorComponentDialog,
    PerformanceIndicatorsReportComponent,
    DdstrackerVersionComparerComponent,
    ErrorNotificationComponent,
    NewClientIndexMappingPopupComponent,
    IndexEditComponent
    
  ],
  declarations: [
    AppComponent,
    TopNavComponent,
    CustomColumnChooserComponent,
    CustomTableComponent,
    CustomTableHoldingsComponent,
    GrowthOfUnitComponent,
    GrowthOfUnitComponentDialog,
    LandingTopBottomGridComponentDialog,
    LandingRiskReturnComparisonComponentDialog,
    MarketCapReturnsChartComponentDialog,
    CloseHoldingsPageComponent,
    CloseTopBottomHoldingsChartComponent,
    CloseTopBottomHoldingsChartComponentWidgetDialog,
    CloseTopLevelSectorChartComponent,
    CloseCountryWeightsChartComponent,
    CloseCurrencyWeightsChartComponent,
    CloseRankedHoldingsGridComponentDialog,
	  OpenHoldingsPageComponent,
    OpenTopBottomHoldingsChartComponent,
    OpenCountryWeightsChartComponent,
    OpenCurrencyWeightsChartComponent,
    ChartToolbarComponent,
    ChartToolbarGrowthofUnitComponent,
    ProgressSpinnerComponent,
    CloseTopHoldingsOptionsComponent,
	OpenTopHoldingsOptionsComponent,
    ChartOptionsComponent,
    CloseRankedHoldingsGridComponent,
    CloseHoldingsPositionGridComponent,
    CloseHoldingsPositionGridComponentDialog,
    CloseHoldingsQueueComponent,
  OpenRankedHoldingsGridComponent,
  OpenRankedHoldingsGridComponentDialog,
    OpenHoldingsPositionGridComponent,
    OpenHoldingsPositionGridComponentDialog,
    OpenHoldingsQueueComponent,
    UserProfileToolbarComponent,
    HomeToolbarComponent,
    StartupPageComponent,
    LoginPageComponent,
    LandingPageComponent,
    LandingPageChartsComponent,
    MarketCapReturnsChartComponent,
    LandingRiskReturnComparisonComponent,
    LandingSectorContributionChartComponent,
    LandingTopNavigationChartComponent,
    LandingGridComponent,
    LandingGridComponentDialog,
    PerformanceGridComponentDialog,
    PerformancePageComponent,
    PerformanceHeatChartComponent,
    PerformanceTimeSeriesComponent,
    PerformanceReturnsComponent,
    PerformanceCalendarReturnsComponent,
    PerformanceGridComponent,
    FilterPipe,
    FilterHoldingsCurrencyTypePipe,
    FilterReturnTypePipe,
    FilterReviewProductsPipe,
    FilterTelemetryProducts,
    FilterEvents,
    FilterReviewSchedulePipe,
    UKDateFormatPipe,
    USDateFormatPipe,
    FilterDDSPipe,
    ProductIndexMarkerPipe,
    SearchIndexMarkerPipe,
    IndexMarkerPropertiesPipe,
    ValuesPageComponent,
    ValuesGrowthofUnitTotalComponent,
    ValuesGrowthofUnitPriceComponent,
    ValuesConstituentCountComponent,
    ValuesMarketCapComponent,
    ValuesGridComponent,
    ValuesGridComponentWidgetDialog,
    DateRangePickerComponent,
    SearchComponent,
    DatePickerComponent,
    CustomDateRangePickerComponent,
    PerformanceHeatChartDialog,
    PerformanceHeatChartWidgetDialog,
    PerformanceReturnsComponentDialog,
    PerformanceReturnsComponentWidgetDialog,
    PerformanceTimeSeriesComponentDialog,
    PerformanceTimeSeriesComponentWidgetDialog,
    PerformanceCalendarReturnsComponentDialog,
    PerformanceCalendarReturnsComponentWidgetDialog,
    ValuesMarketCapComponentDialog,
    ValuesMarketCapComponentWidgetDialog,
    ValuesConstituentCountComponentDialog,
    ValuesConstituentCountComponentWidgetDialog,
    ValuesGrowthofUnitPriceComponentDialog,
    ValuesGrowthofUnitPriceComponentWidgetDialog,
    ValuesGrowthofUnitTotalComponentDialog,
    ValuesGrowthofUnitTotalComponentWidgetDialog,
    CloseTopLevelSectorChartComponentDialog,
    CloseTopBottomHoldingsChartComponentDialog,
    CloseTopBottomHoldingsChartComponentWidgetDialog,
    CloseCountryWeightsChartComponentDialog,
    FundComponentDialog,
    CloseCountryWeightsChartComponentWidgetDialog,
    CloseCurrencyWeightsChartComponentDialog,
    CloseCurrencyWeightsChartComponentWidgetDialog,
    OpenTopBottomHoldingsChartComponentDialog,
    OpenTopBottomHoldingsChartComponentWidgetDialog,
    OpenCountryWeightsChartComponentDialog,
    OpenCountryWeightsChartComponentWidgetDialog,
    OpenCurrencyWeightsChartComponentDialog,
    OpenCurrencyWeightsChartComponentWidgetDialog,
    LandingTopBottomGridComponent,
    WeightPageComponent,
    ReferencePageComponent,
    WeightSectorWeightsTreeComponent,
    ReportFileDownloadComponent,
    ReportingPageComponent,
    ReportAnalyseGridComponent,
    ReportS3FileDownloadComponent,
    ReportIndexCharacteristicsComponent,
    PerformanceQueueComponent,
    ValuesQueueComponent,
    CompanyComponent,
    UserProfileComponent,
    ApiConfigurationComponent,
    CompanyApiSearchComponent,
    CompanySearchComponent,
    ApiKeysComponent,
    ApiKeySearchComponent,
    UserSearchComponent,
    EntitlementtestComponent,
    WavesComponent,
    APIReportComponent,
    LAMComponent,
    KPIComponent,
    FileloadstatusComponent,
    FactorsPageComponent,
    GlossaryPageComponent,
    FactorsDrawdownChartComponent
    ,FactorsDrawdownChartComponentDialog
    ,FactorsDrawdownChartComponentWidgetDialog
    ,FactorsCalendarYearReturnChartComponent
    ,FactorsCalendarYearReturnChartComponentDialog
    ,FactorsCalendarYearReturnChartComponentWidgetDialog
    ,FactorsCalendarYearReturnGridComponent
    ,FactorsCalendarYearReturnGridComponentDialog
    ,FactorsRiskReturnGridComponent,
    FactorsRiskReturnGridComponentDialog,
    FactorsReturnTimePeriodGridComponent,
    FactorsReturnTimePeriodGridComponentDialog,
    FactorsPerformanceRatiosGridComponent,
    FactorsPerformanceRatiosGridComponentDialog,
    FactorsCumulativeTotalReturnChartComponent,
    FactorsCumulitiveTotalReturnChartComponentDialog,
    FactorsCumulitiveTotalReturnChartComponentWidgetDialog,
    ReviewsComponent,
    ReviewProductsComponent,
    ReviewScheduleComponent,
    WeightSectorHistoryWeightsCountryChartComponent,
    WeightSectorHistoryWeightsCountryChartComponentDialog,
    WeightSectorHistoryWeightsSectorChartComponent,
    WeightSectorHistoryWeightsSectorChartComponentDialog,
    WeightSectorHistoryWeightsSubSectorChartComponent,
    WeightSectorHistoryWeightsSubSectorChartComponentDialog,
    WeightSectorHistoryIndustryComponent,
    WeightSectorHistoryIndustryComponentDialog,
    WeightSectorHistorySuperSectorComponent,
    WeightSectorHistorySuperSectorComponentDialog,
    OtherPageComponent,
    IdwChartComponent,
    ChartTypeComponent,
    DdstrackerComponent,
    DdstrackerVersionComparerComponent,
    PerformanceIndicatorsReportComponent,
    IndexOfInterestComponent,
    FundamentalsReportComponent,
    ReferencedataTableComponent,
    PriceDataQualityComponent,
    SectorDataQualityComponent,
    NicbSectorDataQualityComponent,
    RgsSectorDataQualityComponent,
    OpenpriceDataQualtityComponent,
    OpenpriceDataQualtityComponent,
    CountryDataQualityComponentComponent,
    SharesDataQualityComponent,
    GeographicalInformationComponent,
    TelemetryLogs,
    SedolAlertComponent,
    SedolSearchComponent,
    RolePageMappingComponent,
    RolePageMappingSearchComponent,
    MarketValueDataqualityComponent,
    AlternateDataDeliveryComponent,
    SummaryDataQualityComponentComponent,
    UserGroupSearchComponent,
    UserGroupComponent,
    UserRoleMappingComponent,
    MenuItemsSearchComponent,
    MenuItemComponent,
    SideNavComponent,
    LicenceBandsComponent,
    LicenceBandsSearchComponent,
    DataQualityClientDropdownComponent,
    //#endregion
    ErrorNotificationComponent,
    ExpandedRowComponent,
    ExcelLikeGridComponent,
    ClientIndexMappingGridComponent,
    ClientIndexMappingDetailComponent,
    ClientIndexMappingFormComponent,
    NewClientIndexMappingPopupComponent,
    AdditionalToolbarComponent,
    ErrorHandlerComponent,
    IndexComponent,
    IndexEditComponent,
    DataRestoreComponent,
    // BenchmarksPageComponent,
    // BenchmarksGridComponent,
    // SelectIndexMarkerComponent,
    // AsOfDatePickerComponent,
    // ExpandedRowComponent,
  ],
  imports: [
    AppRoutingModule,
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    // ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    FusionChartsModule,
    FusionChartsModule.forRoot(FusionChartsProvider),
    HttpModule,
    ScrollingModule,
    HttpClientModule,
    ChartsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatListModule,
    MatChipsModule,  
    MatIconModule,  
    MatSidenavModule,
    MatDatepickerModule, 
    MatNativeDateModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatTooltipModule,
    Daterangepicker,
    BrowserAnimationsModule,
    DragDropModule,
    ClipboardModule,
    LZStringModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatButtonModule,
    MatRadioModule,
    MatSnackBarModule,
    MatCardModule,
    MatProgressBarModule,
    PbrModule,
    MatTabsModule,
    MatSelectFilterModule,
    NgxMatSelectSearchModule,
    MatDialogModule,    
    AgmCoreModule.forRoot({
      apiKey:'AIzaSyDxQnv5zuXyuzuYRX7ZfWGrCGu6YoqfRE4'
    })
  ],
  providers: [DatePipe,
    GrowthOfUnitService,
    ExportToExcelService,
    SanetizeStringService,
    ColumnChooserService,
    IDWFusionChartsPngExportService,
    IDWChartPngExportService,
    IDWChartXlsExportService,
    IDWChartExportService,
    S3ManagerService,
    IndexMarkerService,{
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
   // EnvironmentSpecificService,
    //EnvironmentSpecificModule.init(),
    AuthenticateService,
    BiService,
    CloseHoldingService, {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
	OpenHoldingService, {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    PerformanceService,{
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    ValuesService,{
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    LandingService,{
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    BiHeartBeatServiceService,{
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    SearchService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },

    EntitlementService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    AuthorizationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    AdminReportService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },

    ReportService,{
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },

    ReportAnalyseMethodGenerator,{
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    WeightService,{
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    ReviewsService,{
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
     DdsService,{
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    PerformanceIndicatorsReportService,{
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    MagellanColorService,
    PublishIndexMarkerService,
    DateService,
    CurrencyService,
    DateDisplayService,
    ReferenceService,{
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    FactorsService,{
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    DataqualityserviceService,{
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    PagerService,
    UserTabChangeService,
    UINotificationService,
    LZStringService,
    GeographicalInformationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


