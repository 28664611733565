import {Component,ViewChild,OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {S3ManagerService} from '../../services/s3-manager.service';
import { saveAs } from 'file-saver/FileSaver';

import {ReportFileDownloadModel} from '../../models/report-file-download.model';
import { LandingService } from '../../services/landing.service';
import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'report-s3-file-download',
    templateUrl: './report-s3-file-download.component.html'
})
export class ReportS3FileDownloadComponent implements OnInit{
    private s3Credentials:any;
    private bucketDelimiter = "/";
    private dataAsString = "";
    private dataToDownload: Blob;
    ReportS3FileDownloadLoader:boolean = true;
    request:any;
    passphrase: string = "EncryptionatRest";
    constructor(
        private route: ActivatedRoute,
        private s3ManagerService:S3ManagerService,
    private landingService:LandingService) {}

    ngOnInit(){
      this.route.queryParams.subscribe(req=>{
          this.request = JSON.parse(req.request);
          this.PrepareDownload(this.request);
       });
    }

    PrepareDownload(req:any):void{
        this.ReportS3FileDownloadLoader = true;
            this.s3Credentials = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('RestMessage'),this.passphrase).toString(CryptoJS.enc.Utf8));
            var urlParts = req.link.split(this.bucketDelimiter);
            this.s3ManagerService.getRawData(urlParts[urlParts.length - 3],urlParts[urlParts.length - 2]+
                this.bucketDelimiter+urlParts[urlParts.length - 1],this.s3Credentials).then(result=>{
                    console.log(result);
                    this.dataAsString = result.Body.toString();
                    this.ReportS3FileDownloadLoader = false;  
                
            },onerror =>{
                this.ReportS3FileDownloadLoader = false;
                console.log(onerror);  
             });
    }

    Download():void{
        this.landingService.updateQueueStatus(this.request.requestId);
        saveAs(new Blob([this.dataAsString], {type: 'appilication/csv;charset=utf-8;'}),"RawData");
    }
}