import { Component , OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticateService } from '../../services/authenticate.service';
import { BehaviorSubject } from 'rxjs';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {    
    @Output() getLoginActiveStatusChange = new EventEmitter<boolean>();

    username = '';
    psw = '';
    constructor(private authService: AuthenticateService) {

    }
    ngOnInit(): void {        
       
    }     
    
    cognitoLogin(){
        this.getLoginActiveStatusChange.emit(true);
        this.authService.cognitoSignIn(this.username,this.psw);    
    }

    cancelLogin(){        
        this.getLoginActiveStatusChange.emit(false);
    }    
 }