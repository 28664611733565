import { Component, OnInit } from '@angular/core';
import { LicenseBand } from '../../models/authorization';
import { AuthorizationService } from '../../services/authorization.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-licence-bands-search',
  templateUrl: './licence-bands-search.component.html',
  styleUrls: ['./licence-bands-search.component.css']
})
export class LicenceBandsSearchComponent implements OnInit {

  licenseBand: LicenseBand[];
  commitStatus: any;
  viewOnlyAccess : Boolean = true;
  constructor(private services:AuthorizationService) { }

  ngOnInit() {
    if (sessionStorage.getItem("UserRoles") == environment.role 
    || sessionStorage.getItem("UserRoles").indexOf(environment.adminGroup) !== -1
    || sessionStorage.getItem("UserRoles").indexOf(environment.clientServiceOpsGroup) !== -1)
{
  this.viewOnlyAccess = false;
}
  this.licenseBand = [{id:"", description:"", licenseBand:"", sedolCount:""}];
  this.GetLicenseDetails();
  }

  GetLicenseDetails(){
    this.services.getLicenseInfo().then(data =>
      {
       this.licenseBand=data; 
       console.log('License band data for .......', this.licenseBand); 
      }) 
  }

  deleteLicense(element){
    if(confirm("Are you sure to delete ?")){
          this.services.deleteLicense(element).then(res =>{
       this.commitStatus = "Deleted successfully";
       this.GetLicenseDetails()
    });
   

  }
  }


}
