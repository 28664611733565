import { Component, EventEmitter, Input, OnInit, Inject,Output, ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {SelectionModel} from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { ConnectContactLens } from 'aws-sdk';
import { ExportReportData, Report, ReportData } from '../../models/report';
import { eupabindexDataService } from '../../services/eupabindexconfigurations.service';
import { DownloadService } from '../../services/download.service';
import {S3ManagerService} from '../../../services/s3-manager.service';
import { saveAs } from 'file-saver/FileSaver';
import { ArrayDataSource } from '@angular/cdk/collections';
import * as CryptoJS from "crypto-js";
import * as AWS from 'aws-sdk';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';
import { eupabindex } from '../../models/eupabindex';
import { environment } from '../../../../environments/environment';

enum ColumnNames {
  AsofDate='AsofDate',
  IndexCode = 'IndexCode',
  Yoy_Decarbonisation_Trajectory = 'Yoy_Decarbonisation_Trajectory',
  Excess_S_Decarbonisation = 'Excess_S_Decarbonisation',
  Active_Share_Level ="Active_Share_Level",
  Carbon_Reduction_Target_Alignment = 'Carbon_Reduction_Target_Alignment',
  Emission_Intensity = 'Emission_Intensity',
  ModifiedUser = 'ModifiedUser',
  ModifiedTime = 'ModifiedTime',
  controls = 'controls'
}

@Component({
  selector: 'eupabindexconfiguration-data-grid',
  templateUrl: './eupabindexconfiguration-data-grid.component.html',
  styleUrls: ['./eupabindexconfiguration-data-grid.component.scss']
})
export class EUPABindexconfigurationDataGridComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() edit = new EventEmitter<Report>();
  @Output() indexCodeEvent = new EventEmitter<string>();
  dataSource: any;
  isVisible :boolean = false ;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25];
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 5;
  pageCount: number = 0;
  pager: any = {};
  unsub: any;
  eupabindex: eupabindex[] = [];
  data: MatTableDataSource<any>;
  oldPageLimit: number;
  response: any;
  selectedIndex: any;
  selectedValue: any;
  activePageDataChunk: any;
  pageLength: number;
  filterForm: any;
  serchtext: any='';
  isupdate :boolean = false ;
  exportresultArray: ExportReportData[] = [];
  selectedreports: number[] = [];
  passphrase: string = "EncryptionatRest";
  isLoading: boolean = false;
  indexcodes: string[];
  public indexcodefilteredList: string[];
  displayColumnNameMap = new Map<ColumnNames, string>([
    [ColumnNames.AsofDate,'AsofDate'],
    [ColumnNames.IndexCode, 'Index Code'],
    [ColumnNames.Yoy_Decarbonisation_Trajectory, 'Yoy\n Decarbonisation\n Trajectory'],
    [ColumnNames.Excess_S_Decarbonisation, 'Excess\n S\n Decarbonisation'],
    [ColumnNames.Active_Share_Level, 'Active\n Share\n Level'],
    [ColumnNames.Carbon_Reduction_Target_Alignment, 'Carbon\n Reduction\n Target\n Alignment'],
    [ColumnNames.Emission_Intensity, 'Emission\n Intensity'],   
    [ColumnNames.ModifiedUser, 'Modified User'],
    [ColumnNames.ModifiedTime, 'Modified date']
  ]);
  displayedColumns: ColumnNames[] = [
    ColumnNames.AsofDate,
    ColumnNames.IndexCode,
    ColumnNames.Yoy_Decarbonisation_Trajectory,
    ColumnNames.Excess_S_Decarbonisation,
    ColumnNames.Active_Share_Level,
    ColumnNames.Carbon_Reduction_Target_Alignment,
    ColumnNames.Emission_Intensity,
    ColumnNames.ModifiedUser,
    ColumnNames.ModifiedTime,
    ColumnNames.controls
  ];
  skipColumnsAutoRender = new Set<ColumnNames>([
    ColumnNames.controls
  ]);
  constructor(private router: Router, private eupabindexDataService: eupabindexDataService, private downloadService: DownloadService, private fb: FormBuilder, private s3ManagerService:S3ManagerService) {
    this.unsub = this.eupabindexDataService.clientPublish().subscribe((m: any) => {
      alert(m);
      this.getEUPABfigurationData();
   
    });
  }

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      AsofDate:  [""],
      IndexCode :  [""],
      Yoy_Decarbonisation_Trajectory:  [""],
      Excess_S_Decarbonisation:  [""],
      Active_Share_Level:  [""],
      Carbon_Reduction_Target_Alignment: [""],
      Emission_Intensity :[""],
      ModifiedUser: [""],
      ModifiedTime: [""]
 

    });
    this.filterForm.valueChanges.subscribe(value => {
      console.log(this.dataSource);
    });
    if (sessionStorage.getItem("UserRoles").indexOf(environment.siAdminGroup) != -1) {
            this.isVisible=true;
        }
    //enable update/delete button for testing in dev
       // this.isVisible=true;
     //end
     this.GetindexList();
    this.getEUPABfigurationData();
   
  }


  getColumnsViewName(column: ColumnNames): string {
    return this.displayColumnNameMap.get(column) || '';
  }
  

  trim(text: any): string | any {
    if (typeof text === 'string') {
      return text.length < 28 ? text : text.slice(0, 25).trim() + '...';
    }
    return text;
  }
  GetindexList() {
    this.eupabindexDataService.GetindexList().subscribe((res) => {
      this.indexcodes = res.data;
      this.indexcodefilteredList = res.data;
    });
  }
  getEUPABfigurationData()
  {
    if (this.selectedValue != undefined) {
      this.getEUPABconfigurationDataByfilter(this.selectedValue);     
    }
    else {
      this.isLoading = true;
      this.eupabindexDataService.fetchReports$(this.pageIndex, this.pageSize).subscribe((res) => {
        this.response = res;
        this.dataSource = this.response.Data;

        console.log(this.dataSource);
        this.isLoading = false;
        this.activePageDataChunk = this.dataSource;//.slice(0, this.pageSize);
        for (let item of this.activePageDataChunk) {
          item.ModifiedTime = (item.ModifiedTime).substring(0, 10);
          if (item.AsofDate != undefined)
            item.AsofDate= (item.AsofDate).substring(0, 10);
        }
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].TotalCount : 0;

      });
    }
  }
  getEUPABconfigurationDataByfilter(filter) {
    this.isLoading = true;
    this.eupabindexDataService.fetcheupabindexData$(this.pageIndex, this.pageSize, filter).subscribe((res) => {
        this.response = res;
        this.dataSource = this.response.Data;

        console.log("getEUPABconfigurationData");
        console.log(this.dataSource);
        this.isLoading = false;
        this.activePageDataChunk = this.dataSource;//.slice(0, this.pageSize);
        for (let item of this.activePageDataChunk) {
          item.ModifiedTime = (item.ModifiedTime).substring(0, 10);
          if (item.AsofDate != undefined)
            item.AsofDate= (item.AsofDate).substring(0, 10);
        }
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].TotalCount : 0;
      

    });
  }
  onIndexChange(selectedIndex) {
    if (selectedIndex != undefined) {
      this.selectedValue = selectedIndex;
      this.getEUPABconfigurationDataByfilter(selectedIndex);
      this.indexCodeEvent.emit(selectedIndex);
    }
    else {
      this.selectedValue = undefined;
      this.getEUPABfigurationData();
      this.indexCodeEvent.emit(null);
      this.serchtext = '';
      this.indexcodefilteredList = this.indexcodes;
    }

  }
  filterIndexList(val) {
    this.indexcodefilteredList = this.indexcodes.filter((indexCode) => indexCode.includes(val == undefined ? val : val.toUpperCase()));
  }
  openEditMetric(metric: any) {
    this.isupdate = true ;
    this.edit.emit(metric);
  }

 
  onPageChanged(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getEUPABfigurationData();
  }
  ngOnDestroy() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
  }
  delete(data : any) {
    if (confirm("Are you sure you want to delete EUPAB metric data?")) {
      this.eupabindexDataService.deleteeupabindexData(data);     
    }
  }
}
