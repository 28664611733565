import { Component, OnInit,SimpleChanges, OnChanges } from '@angular/core';
import { CompanyModel, EntitlementModel } from '../../models/entitlement.model';
import { APIFieldsModel } from '../../models/entitlement.model';
import { EntitlementService } from '../../services/entitlement.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-company-api-search',
  templateUrl: './company-api-search.component.html',
  styleUrls: ['./company-api-search.component.css']
})

export class CompanyApiSearchComponent implements OnInit,OnChanges {
  companies: CompanyModel[];
  apis: any[] = new Array();
  companyApis: EntitlementModel[];
  filteredcompanyApis: EntitlementModel[];
  selectedCompany: any;
  selectedApi: any;
  selectedCompanyId : any ;
  viewOnlyAccess : Boolean = true;
  constructor(private entitlementService: EntitlementService) { }
  ngOnInit() {
    if (sessionStorage.getItem("UserRoles") == environment.role 
    || sessionStorage.getItem("UserRoles").indexOf(environment.adminGroup) !== -1
    || sessionStorage.getItem("UserRoles").indexOf(environment.clientServiceOpsGroup) !== -1)
{
  this.viewOnlyAccess = false;
}
    this.entitlementService.getCompanies().then(result =>
      {
        this.companies = result;
      })
      this.entitlementService.getAPIS().then(result =>
        {
          this.apis = result;
     })
        this.entitlementService.getCompanyAPIS().then(result =>
          {
            this.companyApis = result;
            for(var i=0; i< this.companyApis.length; i++)
            {
              this.companyApis[i].subscriptionStartDate = this.companyApis[i].subscriptionStartDate.substring(0,10);
              this.companyApis[i].subscriptionEndDate = this.companyApis[i].subscriptionEndDate.substring(0,10);
            }
            this.filteredcompanyApis = this.companyApis;
          })
  }

  deleteEntitlement(companyId, apiName){
    var requestEntitlement = {
        CompanyId: companyId,
        ApiName: apiName
    };
   let values = confirm('Do you want to Delete ?')
      if(values==true){
    this.entitlementService.deleteEntitlement(requestEntitlement).then(res =>{
       
    });
    this.filteredcompanyApis = this.filteredcompanyApis.filter(fca => fca.companyId != companyId || fca.apiName != apiName); 
  }
  }
  ngOnChanges(changes:SimpleChanges){}

  onCompanyChange(selectedCompany)
  {
    var selectedCompanyId;
    for(var i=0; i< this.companyApis.length ;i++)
    {
     if(this.companyApis[i].companyName == selectedCompany)
     { selectedCompanyId =this.companyApis[i].companyId }
    }
    this.selectedCompanyId = selectedCompanyId;
    if(this.selectedApi == undefined)
    {
    this.filteredcompanyApis =  this.companyApis.filter(x => x.companyId == selectedCompanyId) ;  
    }else{
      this.filteredcompanyApis =  this.companyApis.filter(x => x.companyId == selectedCompanyId && x.apiName == this.selectedApi) ;
    }
  }

  onAPIChange(selectedAPI)
  {
    this.selectedApi=selectedAPI;
    if(this.selectedCompany == undefined)
    {
    this.filteredcompanyApis =  this.companyApis.filter(x => x.apiName == selectedAPI) ;  
    }else{
    this.filteredcompanyApis =  this.companyApis.filter(x => x.apiName == selectedAPI && x.companyId == this.selectedCompanyId) ;   
    }
  }

  adddNewMapping()
  {
    var test =this.selectedCompany;
  }
}
