import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NeweupabindexForm } from '../../models/eupabindex';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report } from '../../models/report';
import { formatDate, DatePipe } from '@angular/common';
import { eupabindexDataService } from '../../services/eupabindexconfigurations.service';
import { debug } from 'console';

@Component({
  selector: 'app-new-eupabindexconfigurationdata-popup',
  templateUrl: './new-eupabindexconfigurationdata-popup.component.html',
  styleUrls: ['./new-eupabindexconfigurationdata-popup.component.scss']
})
export class NewEUPABIndexconfigurationPopupComponent implements OnInit {

 form: FormGroup = this.fb.group({
    AsofDate: ['', Validators.required],
    IndexCode: ['', Validators.required],
   Yoy_Decarbonisation_Trajectory: [''],
    Excess_S_Decarbonisation: [''],
   Active_Share_Level:[''],
   Carbon_Reduction_Target_Alignment:[''],
   Emission_Intensity:['']
  } as Record<keyof NeweupabindexForm, any[]>);

  updateReport: boolean = false;
  isreportForm = false;
  constructor(private fb: FormBuilder, private eupabindexDataService: eupabindexDataService, public dialogRef: MatDialogRef<NewEUPABIndexconfigurationPopupComponent>, @Inject(MAT_DIALOG_DATA) public rowdata: any) {

    console.log(rowdata);

  }

  ngOnInit(): void {
    if (this.rowdata) {
      this.updateReport = true;
      this.form.patchValue({
        AsofDate: this.rowdata.AsofDate,
        IndexCode: this.rowdata.IndexCode,
        Yoy_Decarbonisation_Trajectory: this.rowdata.Yoy_Decarbonisation_Trajectory,
        Excess_S_Decarbonisation: this.rowdata.Excess_S_Decarbonisation,
        Active_Share_Level: this.rowdata.Active_Share_Level,
        Carbon_Reduction_Target_Alignment: this.rowdata.Carbon_Reduction_Target_Alignment,
        Emission_Intensity: this.rowdata.Emission_Intensity
      })   
    }    
  }

  public onClickHandler(event: any): void {
 
  }

  onSubmit(): void {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      this.dialogRef.close(data);
    }
    if (this.form.invalid) {
      this.isreportForm = true;
      return;
    }
  } 

  }
