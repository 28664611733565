import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import { JsonValue } from 'aws-sdk/clients/glue';
import { HttpParams } from '@angular/common/http';
import {ResponseModel} from '../models/landing-page-model';
import {LicenseBand, MenuItem, UserGroupModel} from '../models/authorization';
import { AnyARecord } from 'dns';


@Injectable()
export class AuthorizationService {
    constructor(
     private httpClient: HttpClient) 
  {
    
  }
  getUserGroupInfo():Promise<UserGroupModel[]>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/pageauthorization/GetUserGroupInfo', null ,{headers: headers}).toPromise().
    then(response => {
       var result = response as ResponseModel;
       return result.data as UserGroupModel;
      })
    .catch(this.handleError)
  }

  getMenuItemInfo():Promise<MenuItem[]>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(environment.dataServiceRoot + 'ui/menuitems/GetMenuItemDetails', null, {headers: headers}).toPromise().

    then(response => {
       var result = response as ResponseModel;
       return result.data as MenuItem;
      })
    .catch(this.handleError)
  }

  getLicenseInfo():Promise<LicenseBand[]>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/LicenceSave/LicenseDetails',null, {headers: headers}).toPromise().

    then(response => {
       var result = response as ResponseModel;
       return result.data as LicenseBand;
      })
    .catch(this.handleError)
  }



  saveMenuItem(menuItemData):Promise<any>
  {
    var inputData = JSON.stringify(menuItemData);
    console.log('service call value', inputData);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/menuitems/SaveMenuItems',
      inputData,
      {headers: headers}).toPromise()
       .then(response => {
        var result = response as ResponseModel;
         return result.data as Array<any>;
       }).catch(this.handleError);
  }



  saveUserGroup(usergroupData):Promise<any>
  {
    var inputData = JSON.stringify(usergroupData);
    console.log(inputData);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/pageauthorization/SaveUserGroup',
      inputData,
      {headers: headers}).toPromise()
       .then(response => {
        var result = response as ResponseModel;
         return result.data as Array<any>;
       }).catch(this.handleError);
  }

  deleteUserGroup(usergroupData)
  {
    var inputData = JSON.stringify(usergroupData);
    console.log(inputData);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/pageauthorization/DeleteUserGroup',
      inputData,
      {headers: headers}).toPromise()
       .then(response => {
        var result = response as ResponseModel;
         return result.data as Array<any>;
       }).catch(this.handleError);
  }

  deleteMenuItem(menuid){
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post(environment.dataServiceRoot + 'ui/menuitems/DeleteUserGroup',
      JSON.stringify({MenuItemID : menuid}),
      {headers: headers}).toPromise()
       .then(response => {
        var result = response as ResponseModel;
         return result.data as Array<any>;
       }).catch(this.handleError);
  }

  deleteLicense(ID){
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post(environment.dataServiceRoot + 'ui/LicenceSave/DeleteLicense',
      JSON.stringify({ID : ID}),
      {headers: headers}).toPromise()
       .then(response => {
        var result = response as ResponseModel;
         return result.data as Array<any>;
       }).catch(this.handleError);
  }

  private handleError(error: any): Promise<any>
  {
    return Promise.reject(error.message || error);
  }
}
