
import {catchError, debounceTime, distinctUntilChanged, tap, switchMap, merge} from 'rxjs/operators';
import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable ,  of } from 'rxjs';
import { SearchResultModel } from '..//../models/search-model';
import { SearchSet } from '..//../models/search-model';
import { myfilter } from '..//../models/search-model';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap'
import { internalEmailValidator } from '../../customValidators/internalEmailValidator';
import { SearchService } from '../../services/search.service'
import { ExportToExcelService } from '../../services/exportToExcel.service';
import { CloseHoldingService } from '../../services/close-holdings.service';
import * as moment from 'moment';
import { DateService } from '../../services/date.service'
import { IndexMarkerService } from '../../services/indexmarker.service';

@Component({
    selector: 'close-holdings-queue',
    templateUrl: './close-holdings-queue.component.html',
    styleUrls :['close-holdings-queue.component.css']
})

export class CloseHoldingsQueueComponent implements OnInit {

    @ViewChild(NgbDropdown,{static: false})
    private dropdown: NgbDropdown;
    queueModel: any;
    searching = false;    
    firstValueIndexName:any;
    firstValue:string 
    searchFailed = false;
    supportedPeriod: any[] = [{ "key": "D", "value": "DAILY", "maxPeriod": "370" }, { "key": "M", "value": "MONTHLY", "maxPeriod": "5500" }, { "key": "Q", "value": "QUARTERLY", "maxPeriod": "14640" }, { "key": "Y", "value": "YEARLY", "maxPeriod": "unlimited" }];
    defaultActivePeriod: string = "D";
    hideSearchingWhenUnsubscribed = new Observable(() => () => this.searching = false);
    indexes = [];
    selectable = true;
    removable = true;
    dateRange = {
        start: new Date(),
        end: new Date(),
        label: "Daily"
    };
    diffDays = 60;
    holdingsQueueForm: FormGroup;
    commitStatus: any;
    showMessage = false;
    constructor(private searchService: SearchService, private fb: FormBuilder, private closeHoldingService: CloseHoldingService, private dateService: DateService, private indexMarkerService: IndexMarkerService) {
        this.createForm();
    }

    ngOnInit() {
        var releventDate = new Date();
        this.showMessage=false;
        this.dateRange.end = new Date(releventDate.setDate(releventDate.getDate() - 1));
        this.dateRange.start = new Date(releventDate.setDate(releventDate.getDate() - 60));
        this.indexMarkerService.getIndexMarkers().forEach(value => {
            this.indexes.push(value);
        });
    }
    createForm() {
        this.holdingsQueueForm = this.fb.group({
            indexMarkers: [null, []]
        });
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(term =>
                this.searchService.search(term).pipe(
                    tap((response) => {
                        this.searchFailed = false;
                    }),
                    catchError(() => {
                        this.searchFailed = true;
                        return of([]);
                    }),)),
            tap(() => {
                this.searching = false;
            }),
            merge(
                this.hideSearchingWhenUnsubscribed
        ),);

    selectItem(event: any) {
        if (this.indexes.length < 6) {
            var indexMarker = event.item.split('-');
            if (!this.indexes.includes(indexMarker[0])) {
                this.indexes.push(indexMarker[0]);
            }
        }
    }
    populateData() {
        this.indexes=[];
        this.indexMarkerService.getIndexMarkers().forEach(value => {
            this.indexes.push(value);
        });
        this.dateRange.start = new Date(this.dateService.getCalendarDate().start);
        this.dateRange.end = new Date(this.dateService.getCalendarDate().end);
        this.dateRange.label = this.dateService.getTimePeriod();
        this.defaultActivePeriod = this.supportedPeriod[this.supportedPeriod.findIndex(img => img.value == this.dateService.getTimePeriod())].key;
    }
    deleteQueueIndex(value: string): void {
        if(value != " ")
        {
            if(this.indexes.length == 0){  this.indexes.push(this.firstValue); }
            else{  this.firstValue = this.indexes[0]; }                   
        }
        var removeIndex=null;
        var arr=value.split("×");
        var i=0;
        for (let indexName of this.indexes)
        {               
            if (indexName==arr[0])
            {
                 removeIndex=i;
                break;
            }
                i++;
        }
        if(removeIndex!=null)
        {
            this.indexes.splice(removeIndex, 1);
        }
    
        if(value != " ")
        {
            if(this.indexes.length == 0)
            {
                this.indexes.push(this.firstValueIndexName);
            }
            else
            {    
                this.firstValueIndexName = this.indexes[0];
            }                                
        }
    }

    onNotify(value: any) {
        this.showMessage=false;
        this.dateRange.start = value.start;
        this.dateRange.end = value.end;
        this.dateRange.label = value.label;
        var diff = new Date(this.dateRange.end).getTime() - new Date(this.dateRange.start).getTime();
        this.diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        for (var counter = this.supportedPeriod.length - 1; counter >= 0; counter--) {
            this.setDefaultActivePeriod(this.supportedPeriod[counter]);
            this.getPeriodCSSClass(this.supportedPeriod[counter]);
        }
    }

    sendHoldingQueue(): void {
        this.showMessage = true;
        var currentDate = moment();
        var theEndDate = moment(this.dateRange.end);
        var theStartDate = moment(this.dateRange.start);
        if (this.holdingsQueueForm.valid && this.indexes.length > 0 && this.indexes.length <= 6
            && this.dateRange.start <= this.dateRange.end && theEndDate < currentDate) {
            this.closeHoldingService.queueRequest(theStartDate.toDate(), theEndDate.toDate(), this.defaultActivePeriod, this.indexes).then(res=>{
                if(res!=null || res==0){
                    this.commitStatus = "Request has been submitted successfully";
                   }
                   else   
                   {
                   this.commitStatus = "Request has not been submitted successfully";
                   }
               });
        }

    }
    onClose(){
        this.dropdown.close();
        this.showMessage=false;
    }

    getPeriodCSSClass(period: any) {
        if (period.maxPeriod == "unlimited") {
            return (period.key == this.defaultActivePeriod) ? "active" : "currencyLinks";
        }
        return (this.diffDays > period.maxPeriod) ? "disabledPeriodLink" :
            (period.key == this.defaultActivePeriod) ? "active" : "currencyLinks";
    }

    filterPeriod(period: string) {
        this.defaultActivePeriod = period;

    }

    setDefaultActivePeriod(period: any) {
        if ("unlimited" == period.maxPeriod || this.diffDays <= period.maxPeriod) {
            this.defaultActivePeriod = period.key;
        }
    }
}