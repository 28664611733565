import { Injectable }    from '@angular/core';
import { Headers, Http } from '@angular/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';



import { GrowthOfUnitRecord } from '../models/growth-of-unit-record';

@Injectable()
export class GrowthOfUnitService {
    
    private headers = new Headers({'Content-Type': 'application/json'});
    private url: string;

    constructor( private http: Http) { 
    }

    getData(): Promise<GrowthOfUnitRecord[]>{
        
        return this.http.get(environment.dataServiceRoot + 'api/gou')
            .toPromise()
            .then(response =>{
                 return response.json() as GrowthOfUnitRecord[]
                })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
      }
    }