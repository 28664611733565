import { Component, OnInit ,Input, Inject,OnChanges,SimpleChanges} from '@angular/core';
import {WeightService} from '../../services/weight.service';
import {CloseHoldingPageModel} from '../../models/close-holding-page-model';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {MagellanColorService} from '../../services/magellancolor.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {BiService} from '../../services/BiService.service';

@Component({
  selector: 'weight-sector-weights-tree',
  templateUrl: './weight-sector-weights-tree.component.html'
})
export class WeightSectorWeightsTreeComponent implements OnChanges {
    @Input() asofDates: any;
    @Input() indexMarker: any;
    @Input() selectedSectorChart: any;
    @Input() currencyCode: any;
    @Input() data:any;
    WeightsSectorTreeLoader:boolean = true;
    indexMarkers: any;
    sectorWeightList: any;
    colors : any;    
    nodes : any;    

    constructor(private weightService: WeightService ,private indexMarkerService:IndexMarkerService,
         private publishIndexMarkerService:PublishIndexMarkerService, private exportService: ExportToExcelService,
        private magellanColorService : MagellanColorService,private biService:BiService) { }

    ngOnInit() {   
        this.WeightsSectorTreeLoader = true; 
        this.selectedSectorChart  = "icb";
        this.currencyCode="USD";
        this.colors = this.magellanColorService.getCurrentColorSchema().split(",");
        this.magellanColorService.colorsPublish().subscribe(color => { 
            this.colors = color.split(",");                       
        });              
    }

    ngOnChanges(changes:SimpleChanges){        
        this.chartSetup();
      }

    private chartSetup() { 
        this.WeightsSectorTreeLoader = false; 
        if(null!= this.data && undefined!=this.data){
            this.sectorWeightList = this.data;
            this.nodes = (this.selectedSectorChart == "icb")?this.sectorWeightList.icbSectorWeight:this.sectorWeightList.rgsSectorWeight;
        }                                                                             
           
       
    }

    loadingRefresh(){
        this.WeightsSectorTreeLoader = true;
        this.nodes = {};
    }
    loadingStop(){
        this.WeightsSectorTreeLoader = false;
    }
    failedLoad(){
        this.nodes = {};
        this.WeightsSectorTreeLoader = false;
    }
    ExportXls(event):void{  
        var event: any = {eventName: "Weight Sector Weight Tree Chart Exported", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarker},{Name: "Exported As Of Date", Value: this.asofDates.toString()}]};
        this.biService.addEvent(event);      
            // this.weightService.getTopLevelSectorData(this.asofDates,[this.indexMarker],this.selectedSectorChart,this.currencyCode).then(data=>{
            //     this.exportService.exportData(data,"topLevelSectorRawData");
            // });
          }

    onChange(selectedChart){
    this.selectedSectorChart = selectedChart;      
    this.nodes = (this.selectedSectorChart == "icb")?this.sectorWeightList.icbSectorWeight:this.sectorWeightList.rgsSectorWeight ;       
}
    
}

