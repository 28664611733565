import { Component, OnInit , EventEmitter,Output,ViewChild,AfterViewInit, Inject} from '@angular/core';
import {CustomTableComponent} from '../custom-table/custom-table.component';
import { ValuesService } from '../../services/values.service';
import {FilterPipe} from '../../filter.pipe';

import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import {ColumnChooserService} from '../../services/column-chooser.service';
import {CustomColumnsModel} from '../../models/custom-columns-model';
import {ValuePageModel} from '../../models/value-page-model';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import * as moment from 'moment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DateService } from '../../services/date.service';
import {DateDisplayService} from '../../services/date.display.service';
import { BiService } from '../../services/BiService.service';
import{CurrencyService}from '../../services/currency.service';

@Component({
  selector: 'values-grid',
  templateUrl: './values-grid.component.html'
})
export class ValuesGridComponent implements OnInit {
 
  @Output() notifyPageModel: EventEmitter<ValuePageModel> = new EventEmitter<ValuePageModel>();
  @ViewChild(CustomTableComponent,{static: false}) customTableComponent; 
  indexMarkers : any;  
  valuesGrid:any;
  valuesColumn:any;
  dataTableRawData: any;  
  gridCustomColumns: any[];
  gridData:any; 
  timePeriod : any;
  selectedDates:any;
  dtvaluesGridData: any; 
  ValueGridLoader:boolean =true;  
  
    dateFormat: any;
  pageModel : ValuePageModel = {currencyCode:"USD",timePeriod:"DAILY",selectedDates:{start:moment().subtract(2,'months').toDate(),end:moment().subtract(1,'days').toDate()}};
  asOfDate :any ;
  
  constructor(private dateDisplayService: DateDisplayService,private dateService : DateService,private valuesService: ValuesService , 
    private exportService: ExportToExcelService,private publishIndexMarkerService:PublishIndexMarkerService 
    ,private indexMarkerService:IndexMarkerService,private columnChooserService:ColumnChooserService,public dialog: MatDialog,private biService: BiService,private currencyService:CurrencyService) { }

  ngOnInit(){
    this.dateFormat = this.dateService.getDateFormat(); 
this.dateService.dateFormatPublish().subscribe(dateFormat=>{
    this.dateFormat =  dateFormat;                          
});         
    this.asOfDate = this.dateService.getCalendarDate().end.substring(0,10);
    this.indexMarkers = this.indexMarkerService.getIndexMarkers();
    this.pageModel.currencyCode=this.currencyService.getCurrencyCode(); 

    this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{
      this.ValueGridLoader=true;
      this.indexMarkers=result;
      this.chartSetup(result);
    });
    this.currencyService.currencyCodePublish().subscribe(currency=>{ 
      this.pageModel.currencyCode=currency;       
      this.onNotifyCurrency(currency);
    });

    this.pageModel.selectedDates = this.dateService.getCalendarDate();
    this.onNotify(this.pageModel);
    this.dateService.calendarDatePublish().subscribe(result=>{ 
      this.pageModel.selectedDates.start = this.dateService.changeDateFormat(result.start);
      this.pageModel.selectedDates.end = this.dateService.changeDateFormat(result.end);
        this.onNotify(this.pageModel);
    });

    this.dateService.timePeriodPublish().subscribe(timePeriod=>{     
      this.pageModel.timePeriod =timePeriod;
      this.notifyPageModel.emit(this.pageModel);
  });
  }


  ngAfterViewInit(){
    this.chartSetup(this.indexMarkers);
}

  refreshGrid(customColumns:CustomColumnsModel[]){
    var event: any = {eventName: "Column chooser clicked", inputParameters: [{Name : "ColumnName", Value :JSON.stringify(customColumns)}]};
    this.biService.addEvent(event);        
    this.gridCustomColumns = customColumns;
    this.customTableComponent.bind(this.gridData,this.gridCustomColumns); 
} 


PopupInfo(): void{         
  var event: any = {eventName: "Grid help dialog open"};
  this.biService.addEvent(event);
  let dialogRef = this.dialog.open(ValuesGridComponentWidgetDialog, {
    width: '800px',
    height: '300px'     
  });
}

private chartSetup(indexMarkers:any) {

  this.ValueGridLoader   = true;
  this.columnChooserService.getValuesCustomColumns().then(customs=>{ 
    this.gridCustomColumns = customs;
  }); 

  this.valuesService.getValues(this.asOfDate,indexMarkers ,this.pageModel.currencyCode,this.pageModel.timePeriod).then(data => { 
    this.dataTableRawData = data; 
    this.valuesGrid= data; 
    this.gridData =  this.filterData(this.valuesGrid.data,this.pageModel.currencyCode);    
    this.customTableComponent.bind(this.dateDisplayService.getArrayDateDisplay(this.gridData,"asOfDate"),this.gridCustomColumns);
    this.ValueGridLoader=false;
  },onerror =>{
        this.ValueGridLoader =false;       
     });
  
}
onNotify(value:any){                
  this.pageModel.selectedDates =value.selectedDates;
  this.pageModel.timePeriod =value.timePeriod;
  this.notifyPageModel.emit(this.pageModel);
  this.chartSetup(this.indexMarkers); 
}

onNotifyCurrency(currency:any){                
  this.pageModel.currencyCode =currency;     
  this.gridData =  this.filterData(this.dataTableRawData.data,this.pageModel.currencyCode);
  this.customTableComponent.bind(this.gridData,this.gridCustomColumns);
  this.notifyPageModel.emit(this.pageModel);
  }

  update(valuePageMode:ValuePageModel){ 
    this.pageModel.currencyCode = valuePageMode.currencyCode;
    this.pageModel.selectedDates = valuePageMode.selectedDates;
    this.pageModel.timePeriod =valuePageMode.timePeriod;
    this.asOfDate = valuePageMode.selectedDates.end;
    this.chartSetup(this.indexMarkers);
  }

  Exportxls():void{
    var event: any = {eventName: "Export Values Grid", inputParameters: [{Name : "CurrencyCode" , Value: this.pageModel.currencyCode}]};
    this.biService.addEvent(event);
     var visibleColumns = this.columnChooserService.getVisibleColumnsNames(this.gridCustomColumns); 
    this.valuesService.getValuesToExport(this.asOfDate, this.indexMarkers ,this.pageModel.currencyCode,this.pageModel.timePeriod,visibleColumns).then(result => {  
      this.valuesGrid= result;
      this.exportService.exportData(this.valuesGrid.data,"ValuesGridData");
    });
  } 

  ValuesCustomColumnsSelction(event):void{
    this.refreshGrid(event);
  }

  filterPeriod(period: string) {     
    this.pageModel.timePeriod = period; 
    this.notifyPageModel.emit(this.pageModel);
  }

  private filterData(data:any,currencyFilter:string):any{    
    if(undefined == data || 0== data.length){
      return;
    }
    var currencyFilterPipe = new FilterPipe(); 
    var dataToReturn = [];
    for(let item of data){
      if(item.currencycode == currencyFilter){
        dataToReturn.push(item);
      }
    }
    return dataToReturn;
  }
}


@Component({
  selector: 'values-grid-widget',
  templateUrl: './values-grid.component.widget.dialog.html'
})
export class ValuesGridComponentWidgetDialog {
  performanceTextWidth:any;
  constructor(
  public dialogRef: MatDialogRef<ValuesGridComponentWidgetDialog>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
     
   }

  onClose(): void {
      this.dialogRef.close();
}
}