import { Component, Input,Output,EventEmitter } from '@angular/core';
import {ExportToExcelService} from '../../services/exportToExcel.service';

@Component({
    selector: 'open-top-holdings-options',
    templateUrl: './open-holdings-top-holdings-options.component.html'
})
export class OpenTopHoldingsOptionsComponent {
    @Input() title: string;
    @Output() exportElement: EventEmitter<string> = new EventEmitter();
    @Output() holdingsSelectedColumns: EventEmitter<any[]> = new EventEmitter();

    @Output() widgetElement: EventEmitter<string> = new EventEmitter();
    ExportXls():void{
        this.exportElement.emit(' ');
    }

    PopupWidget ():void{
        this.widgetElement.emit(' ');
    }

    HoldingsCustomColumnsSelction(event):void{
      this.holdingsSelectedColumns.emit(event);
    }
}