
import {map} from 'rxjs/operators';
import { Injectable }    from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';

@Injectable()
export class PerformanceIndicatorsReportService{
    constructor( private httpClient: HttpClient) { 
    }

    getPerformanceIndicators(asOfDate:any,targetUser:any,selectedWave:any):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/AdminReport/getPerformanceIndicatorsReport',
            JSON.stringify({
                AsOfDate : asOfDate,
                UserId : targetUser,
                WaveNumber:selectedWave              
        }), {headers: headers})
        .toPromise()
        .then(response =>{
             return response ;
            })
        .catch(this.handleError);
    }

    getPerformanceIndicatorsEmptyColumns(asOfDate:any,indexmarker:any,apiMethodName:any):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/AdminReport/getPerformanceIncatorReportEmptyColumns',
            JSON.stringify({
                AsOfDate : asOfDate,
                IndexIdentifier : indexmarker,
                ApiMethodName:apiMethodName             
        }), {headers: headers})
        .toPromise()
        .then(response =>{
             return response ;
            })
        .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}