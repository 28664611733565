import { Injectable } from '@angular/core';
// tslint:disable-next-line:import-blacklist
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {FetchService} from './fetch.service';
import {map} from 'rxjs/operators';
import {Benchmark, BenchmarkDetails, BenchmarkParams, BenchmarkDetailsColumns,  BenchmarkInfo} from '../models/benchmark';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResponseModel } from '../../models/landing-page-model';

export interface IndexMarkerData {
  type: string;
  name: string;
  index: string;
}

function getHash({indexMarker}: Benchmark) {
  return indexMarker;
}

class BenchmarkSet {
  set = new Set<string>();

  getHash(benchmark: Benchmark): string {
    return getHash(benchmark);
  }

  has(benchmark: Benchmark): boolean {
    return this.set.has(this.getHash(benchmark));
  }

  add(benchmark: Benchmark): this {
    this.set.add(this.getHash(benchmark));
    return this;
  }
}

@Injectable({
  providedIn: 'root'
})
export class BenchmarksService {

  getAvailableIndexMarkers$(): Observable<IndexMarkerData[]> {
    return of(MARKERS.sort((a,b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
  }

  GetBenchmark(asofDate, assetClass): any
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return  this.httpClient.post( 
      environment.dataServiceRoot + 'client/api/mvp/GetMVPBenchmark',   JSON.stringify({ asofDate :asofDate, assetClass: assetClass}),
      {headers: headers}).toPromise()
      .then(response => {
        var result = response as ResponseModel  
        return result.data as any;
      }).catch(this.handleErr);
      

  }
  GetBenchmarkindexmarker(asofDate,indexmarker):any
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return  this.httpClient.post( 
      environment.dataServiceRoot + 'client/api/mvp/GetMVPBenchmarkIndex',   JSON.stringify({ asofDate :asofDate, BenchMark: indexmarker}),
      {headers: headers}).toPromise()
      .then(response => {
        var result = response as ResponseModel  
        return result.data as any;
      }).catch(this.handleErr);
  }
  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  constructor(private fetchService: FetchService,  private httpClient: HttpClient,) { }

  // fetchBenchmarksGroups$(params: BenchmarkParams[] | BenchmarkParams): Observable<Benchmark[]> {
  //   const setOfIndexMarkers = new BenchmarkSet();
  //   return this.fetchBenchmarks$(params).pipe(
  //     map(data => data.filter((benchmark) => setOfIndexMarkers.has(benchmark) ? false : setOfIndexMarkers.add(benchmark))),
  //   );
  // }
  private _date= new Subject<any>(); 
  dateOrIndexMarkerPublish():Observable<any>{
    return this._date.asObservable();
  }

  publishDateOrIndexMarker(client: any) {
    this._date.next(client);
  } 

  private getHash(benchmark: Benchmark): string {
    return getHash(benchmark);
  }

  hasSameMarkers(benchmark1: Benchmark, benchmark2: Benchmark): boolean {
    return this.getHash(benchmark1) === this.getHash(benchmark2);
  }

  // fetchBenchmarks$(params: BenchmarkParams[] | BenchmarkParams): Observable<Benchmark[]> {
  //   params = Array.isArray(params) ? params : [params];
  //   return forkJoin([
  //     this.getAvailableIndexMarkers$(),
  //     this.fetchService.post<Benchmark[]>('client/api/mvp/MVPConstituents', params)
  //   ]).pipe(
  //     map(([markers, {data}]) => {
  //       const indexToTypeMap = markers.reduce((acc, marker) => {
  //         acc[marker.index] = marker.type;
  //         return acc;
  //       }, {});
  //       // TODO: add correct types !!!!!!!!!
  //       return data.map(item => {
  //         item.assetClass = indexToTypeMap[item.indexMarker];
  //         return item;
  //       });
  //     }),
  //   );
  // }

  fetchBenchmarkData$(asofdate , indexmarkers , pageIndex , pageSize ,filters ): Observable<BenchmarkInfo> {
    return forkJoin([
      this.getAvailableIndexMarkers$(),
      this.fetchService.post<BenchmarkInfo>('client/api/mvp/MVPConstituents', { AsOfDate : asofdate ,IndexMarkers :indexmarkers , PageIndex :pageIndex , PageSize :pageSize ,FilterModel :filters})
    ]).pipe(
      map(([markers, {data}]) => {
        const indexToTypeMap = markers.reduce((acc, marker) => {
          acc[marker.index] = marker.type;
          return acc;
        }, {});
        // TODO: add correct types !!!!!!!!!
        // data.benchmarkData.map(item => {
        //   item.assetClass = indexToTypeMap[item.indexMarker];
        //   return item;
        // });
        return data ;
      }),
    );
  }
  onBenchmarkPageChange$(asofdate , indexmarkers , pageIndex , pageSize ,filters): Observable<Benchmark[]> {
    return forkJoin([
      this.getAvailableIndexMarkers$(),
      this.fetchService.post<Benchmark[]>('client/api/mvp/BenchmarkPageChange', { AsOfDate : asofdate ,IndexMarkers :indexmarkers, PageIndex :pageIndex , PageSize :pageSize ,FilterModel :filters})
    ]).pipe(
      map(([markers, {data}]) => {
        const indexToTypeMap = markers.reduce((acc, marker) => {
          acc[marker.index] = marker.type;
          return acc;
        }, {});
        // TODO: add correct types !!!!!!!!!
        
        // return data.map(item => {
        //   item.assetClass = indexToTypeMap[item.indexMarker];
        //   return item;
        // }); 
        return data;
      }),
    );
  }

  fetchBenchmarkdetails$(asofdate , indexmarker): Observable<Benchmark[]> {
    return forkJoin([
      this.getAvailableIndexMarkers$(),
      this.fetchService.post<Benchmark[]>('client/api/mvp/BenchmarkPageDetails', { AsOfDate : asofdate ,IndexMarker :indexmarker})
    ]).pipe(
      map(([markers, {data}]) => {
        const indexToTypeMap = markers.reduce((acc, marker) => {
          acc[marker.index] = marker.type;
          return acc;
        }, {});
        // TODO: add correct types !!!!!!!!!
        // return data.map(item => {
        //   item.assetClass = indexToTypeMap[item.indexMarker];
        //   return item;
        // });
        return data;
      }),
    );
  }

  benchmarksToDetails(benchmarks: Benchmark[] = [], benchmark: Benchmark = {} as Benchmark): BenchmarkDetails {
    return benchmarks.reduce((acc, item) => {
      acc.data.push(acc.columns.map(column => item[column]));
      return acc;
    }, <BenchmarkDetails>{
      model: benchmark,
      columns: [...Object.keys(BenchmarkDetailsColumns)],
      data: []
    });
  }
  

  // fetchBenchmarkByIndexMarker$(param: BenchmarkParams = {} as BenchmarkParams): Observable<BenchmarkDetails> {
  //   return this.fetchBenchmarks$({IndexMarker: param.IndexMarker, AsOfDate: param.AsOfDate}).pipe(
  //     map(benchmarks => this.benchmarksToDetails(benchmarks)),
  //   );
  // }
}


const MARKERS = [
  {
    name: 'FTSE Blossom Japan Index',
    index: 'FBLSMJPN',
    type: 'Equity'
  },
  { name: 'FTSE4Good All World Index', index: 'F4GAW', type: 'Equity' },
  { name: 'FTSE4Good Developed Index', index: '4GGL', type: 'Equity' },
  {
    name: 'FTSE4Good Emerging Markets Index',
    index: 'F4GEM',
    type: 'Equity'
  },
  {
    name: 'FTSE Environmental Opportunities 100 Index',
    index: 'EO100',
    type: 'Equity'
  },
  {
    name: 'FTSE Environmental Opportunities All-Share Index',
    index: 'EOAS',
    type: 'Equity'
  },
  {
    name: 'FTSE Environmental Technology 100 Index',
    index: 'ET100',
    type: 'Equity'
  },
  {
    name: 'FTSE All-World Green Revenues Index',
    index: 'FAWDGRI',
    type: 'Equity'
  },
  {
    name: 'FTSE All-Share Green Revenues Index',
    index: 'FASXGRI',
    type: 'Equity'
  },
  {
    name: 'FTSE Developed Green Revenues Index',
    index: 'FDEVGRI',
    type: 'Equity'
  },
  {
    name: 'FTSE Emerging Green Revenues Index',
    index: 'FTEMGRI',
    type: 'Equity'
  },
  {
    name: 'Russell 1000 Green Revenues Index',
    index: 'FRU1GRI',
    type: 'Equity'
  },
  {
    name: 'Russell 2000 Green Revenues Index',
    index: 'FRU2GRI',
    type: 'Equity'
  },
  {
    name: 'Russell 3000 Green Revenues Index',
    index: 'FRU3GRI',
    type: 'Equity'
  },
  {
    name: 'FTSE All-World ex Fossil Fuels Index',
    index: 'AWXFF',
    type: 'Equity'
  },
  {
    name: 'FTSE Developed ex Fossil Fuels Index',
    index: 'DXFF',
    type: 'Equity'
  },
  {
    name: 'FTSE Emerging ex Fossil Fuels Index',
    index: 'EXFF',
    type: 'Equity'
  },
  {
    name: 'FTSE North America ex Fossil Fuels Index',
    index: 'DNAXFF',
    type: 'Equity'
  },
  {
    name: 'FTSE All-World ex Coal Index',
    index: 'FAWXC',
    type: 'Equity'
  },
  {
    name: 'FTSE Developed ex Coal Index',
    index: 'FAWDXC',
    type: 'Equity'
  },
  {
    name: 'FTSE Emerging ex Coal Index',
    index: 'FEXC',
    type: 'Equity'
  },
  {
    name: 'FTSE North America ex Coal Index',
    index: 'FNAXC',
    type: 'Equity'
  },
  {
    name: 'FTSE All-World Climate Comprehensive Balance Factor Index',
    index: 'AWCBCF',
    type: 'Equity'
  },
  {
    name: 'FTSE All-World ex CW Climate Balanced Factor Index',
    index: 'AWXWCBF',
    type: 'Equity'
  },
  {
    name: 'FTSE All-World Climate Balanced Comprehensive Factor Index',
    index: 'AWXWCBMF',
    type: 'Equity'
  },
  {
    name: 'FTSE All-World ex CW Climate Index',
    index: 'AWXWCS',
    type: 'Equity'
  },
  {
    name: 'FTSE All-World ex CW ex UN Controversies Climate Index',
    index: 'AWXWNCS',
    type: 'Equity'
  },
  { name: 'Russell 1000 Climate Index', index: 'R1CS', type: 'Equity' },
  {
    name: 'FTSE Developed Climate Index',
    index: 'AWDC',
    type: 'Equity'
  },
  {
    name: 'FTSE Developed ex US Climate Index',
    index: 'AWDXUSC',
    type: 'Equity'
  },
  {
    name: 'FTSE Developed ex Korea TPI Climate Transition Index',
    index: 'FDXKTPI',
    type: 'Equity'
  },
  {
    name: 'FTSE All World TPI ex FF ex Tobacco ex Controversies TPI Climate Transition Index',
    index: 'FAWTPIN',
    type: 'Equity'
  },
  {
    name: 'FTSE All World TPI Climate Transition ex FF  Index',
    index: 'FAWTPIF',
    type: 'Equity'
  },
  { name: 'FTSE Developed ESG Index', index: 'ESGDE', type: 'Equity' },
  { name: 'FTSE Emerging ESG Index', index: 'ESGEM', type: 'Equity' },
  { name: 'Russell 1000 ESG Index', index: 'ESGR1', type: 'Equity' },
  { name: 'FTSE All-Share ESG Index', index: 'ESGASX', type: 'Equity' },
  {
    name: 'FTSE All-Share Women on Boards Leadership Index',
    index: 'ASXWOBSL',
    type: 'Equity'
  },
  {
    name: 'Russell 1000 Women on Boards Leadership Index',
    index: 'RU1WOBSL',
    type: 'Equity'
  },
  {
    name: 'FTSE EPRA Nareit Developed Green Index',
    index: 'FENGRE',
    type: 'Equity'
  },
  {
    name: 'FTSE/JSE Responsible Investment Index',
    index: 'J113',
    type: 'Equity'
  },
  {
    name: 'FTSE/JSE Responsible Investment Top 30 Index',
    index: 'J110',
    type: 'Equity'
  },
  {
    name: 'FTSE Developed ESG Low Carbon Select Index',
    index: 'ELCAWD',
    type: 'Equity'
  },
  {
    name: 'FTSE Emerging ESG Low Carbon Select Index',
    index: 'ELCAWE',
    type: 'Equity'
  },
  { name: 'FTSE Japan', index: 'WIJPN', type: 'Equity' },
  { name: 'FTSE All World Index', index: 'AWORLDS', type: 'Equity' },
  { name: 'FTSE Developed Index', index: 'AWD', type: 'Equity' },
  { name: 'FTSE Emerging Index', index: 'AWALLE', type: 'Equity' },
  { name: 'FTSE UK All-Share', index: 'ASX', type: 'Equity' },
  { name: 'FTSE US', index: 'WIUSA', type: 'Equity' },
  { name: 'FTSE Global All Cap Index', index: 'GEISAC', type: 'Equity' },
  { name: 'FTSE ASIA PACIFIC ALL CAP INDEX', index: 'ACAPS', type: 'Equity' },
  { name: 'FTSE Europe Index', index: 'AWEURS', type: 'Equity' },
  { name: 'Russell 1000', index: 'FTRU1', type: 'Equity' },
  { name: 'Russell 3000', index: 'FTRU3', type: 'Equity' },
  { name: 'FTSE Developed ex North America Index', index: 'AWDXNA', type: 'Equity' },
  { name: 'FTSE North America', index: 'AWNAMERS', type: 'Equity' },
  { name: 'FTSE Developed ex US', index: 'AWDXUS', type: 'Equity' },
  {
    name: 'FTSE Developed ex Korea Index',
    index: 'AWDXKOR',
    type: 'Equity'
  },
  {
    name: 'FTSE All-Share ex Investment Trusts',
    index: 'ASXXM',
    type: 'Equity'
  },
  { name: 'FTSE EPRA Nareit Developed', index: 'ENGL', type: 'Equity' },
  {
    name: 'FTSE/JSE Shareholder Weighted All-Share',
    index: 'J403',
    type: 'Equity'
  },
  {
    name: 'FTSE/JSE Shareholder Weighted Top 40',
    index: 'J400X',
    type: 'Equity'
  },
  {
    name: 'FTSE Climate Risk-Adjusted World Government Bond Index (WGBI)',
    index: 'CLMTWGBI',
    type: 'Sovereign'
  },
  {
    name: 'FTSE Climate Risk-Adjusted European Monetary Union Government Bond Index (EGBI)',
    index: 'CLMTEGBI',
    type: 'Sovereign'
  },
  {
    name: 'FTSE World Government Bond',
    index: 'WGBI',
    type: 'Sovereign'
  },
  {
    name: 'FTSE EMU Government Bond',
    index: 'EGBI',
    type: 'Sovereign'
  },
  {
    name: 'FTSE Asian Government Bond',
    index: 'AGBI',
    type: 'Sovereign'
  },
  {
    name: 'FTSE World Broad Investment-Grade Bond Index',
    index: 'WORLDBIG',
    type: 'Multi-Sector'
  },
  {
    name: 'FTSE Emerging Markets Broad Bond Index',
    index: 'EMUSDBBI',
    type: 'Multi-Sector'
  },
  {
    name: 'FTSE Chinese (Onshore CNY) Green Bond Index',
    index: 'CNYGRBI',
    type: 'Sovereign'
  },
  {
    name: 'FTSE EO RENEWABLE AND ALTERNATIVE ENERGY INDEX',
    index: 'EORE',
    type: 'Sovereign'
  },
  {
    name: 'FTSE China Green Revenues Net Tax Index',
    index: 'FCHNGRIN',
    type: 'Sovereign'
  },
  // {
  //   name: '',
  //   index: 'FGCACN',
  //   type: 'Sovereign'
  // },
  // {
  //   name: '',
  //   index: 'FGCDXAU',
  //   type: 'Sovereign'
  // },
  // {
  //   name: '',
  //   index: 'FGCDEACN',
  //   type: 'Sovereign'
  // },
  // {
  //   name: '',
  //   index: 'FGCACXUS',
  //   type: 'Sovereign'
  // },
  // {
  //   name: '',
  //   index: 'FGCACXUR',
  //   type: 'Sovereign'
  // },
  // {
  //   name: '',
  //   index: 'FGCACU',
  //   type: 'Sovereign'
  // },
  // {
  //   name: '',
  //   index: 'FGCEACN',
  //   type: 'Sovereign'
  // },
  // {
  //   name: '',
  //   index: 'FGCUSAC',
  //   type: 'Sovereign'
  // },
  // {
  //   name: '',
  //   index: 'FGCUSACN',
  //   type: 'Equity'
  // }
];
