
import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NewPortfolioPopupComponent} from '../../components/new-portfolio-popup/new-portfolio-popup.component';
import {PortfoliosService} from '../../services/portfolios.service';
import {catchError, filter, switchMap} from 'rxjs/operators';
// tslint:disable-next-line:import-blacklist
import {BehaviorSubject, combineLatest, of} from 'rxjs';
import {Router} from '@angular/router';
import {PortfolioForm, Portfolio} from '../../models/portfolio';
import {throwError} from 'rxjs/internal/observable/throwError';
import {EditPortfolioPopupComponent} from '../../components/edit-portfolio-popup/edit-portfolio-popup.component';
import {MatDialogConfig} from '@angular/material/dialog/dialog-config';

@Component({
  selector: 'app-portfolios-page',
  templateUrl: './portfolios-page.component.html',
  styleUrls: ['./portfolios-page.component.scss']
})
export class PortfoliosPageComponent implements OnInit {
  retry$ = new BehaviorSubject(null);
  error$ = new BehaviorSubject<any>(null);
  dataSource : any;
  response : any;
  loader : Boolean = true ;
  // dataSource$ = combineLatest([
  //   this.retry$
  // ]).pipe(
  //   switchMap(() => {
  //     this.error$.next(null);
  //     var response : any;
     
  //     return response;
  //   }),
  //   catchError((error) => {
  //     this.error$.next(error);
  //     return throwError(error);
  //   })
  // );

  baseDialogConfig: MatDialogConfig = {
    closeOnNavigation: true,
    panelClass: 'custom-popup-panel',
    height:"700px"
  };

  constructor(
    public dialog: MatDialog,
    private portfoliosService: PortfoliosService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    // this.portfoliosService.fetchPortfolios$().subscribe((res) => {     
    //   console.log(res);  
    //   this.response = res;
    //    this.dataSource = this.response.data;
    // });
  }

  openDialog(): void {
    const dialogRef: MatDialogRef<NewPortfolioPopupComponent, PortfolioForm> = this.dialog.open(NewPortfolioPopupComponent, {
      ...this.baseDialogConfig
    });
    
    dialogRef.afterClosed().pipe(
      filter(data => !!data),
      switchMap((data) => data ? this.portfoliosService.createPortfolio$(data) : of(data)),
    ).subscribe((id) => {
      this.loader = false ;
      //alert("Portfolio created successfully");
    });
  }

  retry() {
    this.retry$.next(new Date());
  }

  editPortfolio(portfolio: Portfolio) {
    const dialogRef: MatDialogRef<EditPortfolioPopupComponent, PortfolioForm> = this.dialog.open(EditPortfolioPopupComponent, {
      ...this.baseDialogConfig,
      data: portfolio,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.portfoliosService.updatePortfolio(data).then(result => {  
          this.portfoliosService.publishClient("Updated");
        });
        
       // window.location.reload();
      }
    });
  }
}
