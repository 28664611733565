import { AfterContentInit, Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Action, iDisplayNewRow, indexMappingDetailsColumnsNameMap } from '../../../models/client-index-mapping';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-excel-like-grid',
  templateUrl: './excel-like-grid.component.html',
  styleUrls: ['./excel-like-grid.component.scss']
})
export class ExcelLikeGridComponent implements OnInit, OnChanges {
  updateObject: any[][] = [];
  inputData: any[] = [];
  filterText: string;
  viewOnlyAccess : Boolean = true;
  private _dataSource: any[] | null = null;
  @Output() update = new EventEmitter<any>();
  @Output() newRecord = new EventEmitter<any>();
  @Output() cancelRecord = new EventEmitter();
  @Output() duplicateRecord = new EventEmitter();
  @Output() selectRecord = new EventEmitter<string>();
  @Output() deleteRecord = new EventEmitter<number>();
  @Output() searchRecord = new EventEmitter<string>();
  @Input() columnsNameMap: { [key: string]: string } = { ...indexMappingDetailsColumnsNameMap };
  @Input() columns: any[] | null = null;
  @Input() displayNewRow: iDisplayNewRow;
  @Input() isDeleteAll: boolean = false;
  @Input() clearFilter: boolean = false;
  @Input() set dataSource(val: any[] | null) {
    this._dataSource = val;
    console.log(this._dataSource);
    if (this.updateObject.length <= 0)
      this.updateObject = [];
  }

  get dataSource(): any[] | null {
    return this._dataSource;
  }

  constructor() { }

  ngOnInit(): void {
    if (sessionStorage.getItem("UserRoles") == environment.role 
    || sessionStorage.getItem("UserRoles").indexOf(environment.adminGroup) !== -1
    || sessionStorage.getItem("UserRoles").indexOf(environment.clientServiceOpsGroup) !== -1)
{
  this.viewOnlyAccess = false;
}
  }

  ngOnChanges(): void {
    switch (this.displayNewRow.action) {
      case Action.new: {
        if (this.displayNewRow.displayInput)
          this.clearInput();
        break;
      }
      case Action.duplicate: {
        break;
      }
      case Action.edit: {
        if (!this.displayNewRow.displayInput)
          this.clearInput();
        break;
      }
      case Action.cancel: {
        this.clearInput();
        break;
      }
    }

    if (this.clearFilter)
      this.filterText = '';
  }

  clearInput() {
    this.inputData[0] = this.inputData[1] = this.inputData[2] = '';
    this.inputData[3] = true;
  }

  updateInput($event: Event, rowIndex: number, columnIndex: number): void {
    const { value } = ($event.target as HTMLInputElement);
    if (!this.updateObject[rowIndex]) {
      this.updateObject[rowIndex] = [];
    }
    this.updateObject[rowIndex][columnIndex] = value;
    this.update.emit(this.updateObject);
  }
  updateCheckboxInput(value: boolean, rowIndex: number, columnIndex: number): void {
    if (!this.updateObject[rowIndex]) {
      this.updateObject[rowIndex] = [];
    }
    this.updateObject[rowIndex][columnIndex] = value;
    this.update.emit(this.updateObject);
  }

  getColumnValue(rowIndex, columnIndex): string {
    const updateValue = this.updateObject[rowIndex] && this.updateObject[rowIndex][columnIndex];
    const initialValue = this.dataSource[rowIndex][columnIndex] || '';
    return updateValue == null ? initialValue : updateValue;
  }

  getColumnName(column: string) {
    return this.columnsNameMap[column] == null ? column : this.columnsNameMap[column];
  }

  addRecord() {
    this.newRecord.emit(this.inputData);
  }
  cancelEdit() {
    this.cancelRecord.emit();
  }

  duplicate(row) {
    this.inputData[0] = row.indexMarker;
    this.inputData[1] = row.indexFamily;
    this.inputData[2] = row.currency
    this.inputData[3] = row.isActive;
    this.duplicateRecord.emit();
  }

  cbDeleteAll(isChecked) {
    this.dataSource.forEach(item => { item.isChecked = isChecked })
    this.selectRecord.emit(`{"isChecked":${isChecked},"configId":0}`);
  }
  cbDelete(isChecked, row) {
    this.selectRecord.emit(`{"isChecked":${isChecked},"configId":${row.configId}}`);
  }

  delete(row) {
    this.deleteRecord.emit(row.configId);
  }

  filter() {
    this.searchRecord.emit(this.filterText);
  }

}
