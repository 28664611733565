import {Component, Inject, NgModule, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ClientIndexMappingForm } from '../../models/client-index-mapping-details'; 

@Component({
  selector: 'app-new-client-index-mapping-popup',
  templateUrl: './new-client-index-mapping-popup.component.html',
  styleUrls: ['./new-client-index-mapping-popup.component.scss']  
})

 


export class NewClientIndexMappingPopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NewClientIndexMappingPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data : ClientIndexMappingForm ) {
    
  }
indexValue : ClientIndexMappingForm;
  ngOnInit(): void { 
    if(this.data != null)
    {
      this.indexValue = this.data;
    //alert(this.data.reportType);
    }
   }
  
  myTabFocusChange(index: number){
    console.log('Selected index: ' + index);
  }

  onSubmit(data: ClientIndexMappingForm): void {
    this.dialogRef.close(data);
  }

    MyCtrl($scope) {
    $scope.value= 'foo';
    
    $scope.newValue = function(value) {
       console.log(value);
    }
}
}
