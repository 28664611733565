import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TopNavComponent } from '../components/top-nav/top-nav.component';
import { LoginPageComponent } from '../components/login-page/login-page.component';
import { LandingPageComponent } from '../components/landing-page/landing-page.component';
import { CloseHoldingsPageComponent } from '../components/close-holdings-page/close-holdings-page.component';
import { OpenHoldingsPageComponent } from '../components/open-holdings-page/open-holdings-page.component';
import { PerformancePageComponent } from '../components/performance-page/performance-page.component';
import { ValuesPageComponent } from '../components/values-page/values-page.component';
import { SearchComponent } from '../components/search/search-component';
import { WeightPageComponent } from '../components/weight-page/weight-page.component';
import { ReportingPageComponent } from '../components/reporting-page/reporting-page.component';
import { ReferencePageComponent } from '../components/reference-page/reference-page.component';
import { ReportFileDownloadComponent } from '../components/report-file-download/report-file-download.component';
import { EntitlementtestComponent } from '../components/entitlementtest/entitlementtest.component';
import { APIReportComponent } from '../components/apireport/apireport.component';
import { FileloadstatusComponent } from '../components/fileloadstatus/fileloadstatus.component';
import { LAMComponent } from '../components/lam/lam.component';
import { WavesComponent } from '../components/waves/waves.component';
import { CompanyComponent } from '../components/company/company.component';
import { UserProfileComponent } from '../components/user-profile/user-profile.component';
import { ApiConfigurationComponent } from '../components/api-configuration/api-configuration.component';
import { environment } from '../../environments/environment';
import { CompanyApiSearchComponent } from '../components/company-api-search/company-api-search.component';
import { CompanySearchComponent } from '../components/company-search/company-search.component';
import { ApiKeysComponent } from '../components/api-keys/api-keys.component';
import { ApiKeySearchComponent } from '../components/apikey-search/apikey-search.component';
import { UserSearchComponent } from '../components/user-search/user-search.component';
import { StartupPageComponent } from '../components/startup-page/startup-page.component';
import { ReportS3FileDownloadComponent } from '../components/report-s3-file-download/report-s3-file-download.component';
import { FactorsPageComponent } from '../components/factors-page/factors-page.component';
import { ReviewsComponent } from '../components/reviews/reviews.component';
import { ReviewProductsComponent } from '../components/review-products/review-products.component';
import { ReviewScheduleComponent } from '../components/review-schedule/review-schedule.component';
import { OtherPageComponent } from '../components/other-page/other-page.component';
import { DdstrackerComponent } from '../components/ddstracker/ddstracker.component';
import { PerformanceIndicatorsReportComponent } from '../components/performance-indicators-report/performance-indicators-report.component';
import { IndexOfInterestComponent } from '../components/index-of-interest/index-of-interest.component';
import { GeographicalInformationComponent } from './../components/geographical-information/geographical-information.component';
import { PriceDataQualityComponent } from '../components/price-data-quality/price-data-quality.component';
import { SectorDataQualityComponent } from '../components/sector-data-quality/sector-data-quality.component';
import { NicbSectorDataQualityComponent } from '../components/nicb-sector-data-quality/nicb-sector-data-quality.component';
import { RgsSectorDataQualityComponent } from '../components/rgs-sector-data-quality/rgs-sector-data-quality.component';
import { OpenpriceDataQualtityComponent } from '../components/openprice-data-qualtity/openprice-data-qualtity.component';
import { CountryDataQualityComponentComponent } from '../country-data-quality-component/country-data-quality-component.component';
import { SharesDataQualityComponent } from '../components/shares-data-quality/shares-data-quality.component';
import { MarketValueDataqualityComponent } from '../components/market-value-dataquality/market-value-dataquality.component';
import { SedolAlertComponent } from '../components/sedol-alert/sedol-alert.component';

import { TelemetryLogs } from '../components/telemetry-logs/telemetry-logs.component'
import { SedolSearchComponent } from '../components/sedol-search/sedol-search.component';
import { RolePageMappingComponent } from '../components/role-page-mapping/role-page-mapping.component';
import { RolePageMappingSearchComponent } from '../components/role-page-mapping-search/role-page-mapping-search.component';
import { AlternateDataDeliveryComponent } from '../components/alternate-data-delivery/alternate-data-delivery.component';
import { SummaryDataQualityComponentComponent } from '../components/summary-data-quality-component/summary-data-quality-component.component';
import { UserGroupSearchComponent } from '../components/usergroup-search/usergroup-search.component';
import { UserGroupComponent } from '../components/usergroup/usergroup.component';
import { UserRoleMappingComponent } from '../components/user-role-mapping/user-role-mapping.component';
import { MenuItemsSearchComponent } from '../components/menu-items-search/menu-items-search.component';
import { MenuItemComponent } from '../components/menu-item/menu-item.component';
import { LicenceBandsComponent } from '../components/licence-bands/licence-bands.component';
import { LicenceBandsSearchComponent } from '../components/licence-bands-search/licence-bands-search.component';
import { BenchmarksPageComponent } from '../pbr/containers/benchmarks-page/benchmarks-page.component';
import { PortfoliosPageComponent } from '../pbr/containers/portfolios-page/portfolios-page.component';
import { PortfolioDetailsPageComponent } from '../pbr/containers/portfolio-details-page/portfolio-details-page.component';
import { ReportsPageComponent } from '../pbr/containers/reports-page/reports-page.component';
import { ClimateReportsPageComponent } from '../pbr/containers/climate-reports-page/climate-reports-page.component';
import { MetaDataConfigComponent } from '../pbr/containers/meta-data-config-page/meta-data-config-page.component';
import {SITablesDataConfigComponent} from '../pbr/containers/si-tables-config-page/si-tables-config-page.component';
import { ReportPrintPageComponent } from '../pbr/containers/report-print-page/report-print-page.component';
import { ClientIndexMappingGridComponent } from '../components/client-index-mapping-grid/client-index-mapping-grid.component';
import { ClientIndexMappingDetailComponent } from '../components/client-index-mapping-detail/client-index-mapping-detail.component';
import { ClientIndexMappingFormComponent } from '../components/client-index-mapping-form/client-index-mapping-form.component';
import { NewClientIndexMappingPopupComponent } from '../components/new-client-index-mapping-popup/new-client-index-mapping-popup.component';
import { IndexComponent } from '../components/index/index.component';
import { DataRestoreComponent } from '../components/data-restore/data-restore.component';
import { HistoryReportsPageComponent } from '../pbr/containers/history-reports-page/history-reports-page.component';
import { SIDataCalculationComponent } from '../pbr/containers/si-datacalculation/si-datacalculation.component';


const routes: Routes = [
  { path: '', component: LandingPageComponent, pathMatch: 'full' },
  { path: 'startup', component: StartupPageComponent, pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent, pathMatch: 'full' },
  { path: 'closeHoldings', component: CloseHoldingsPageComponent, pathMatch: 'full' },
  { path: 'openHoldings', component: OpenHoldingsPageComponent, pathMatch: 'full' },
  { path: 'performance', component: PerformancePageComponent, pathMatch: 'full' },
  { path: 'values', component: ValuesPageComponent, pathMatch: 'full' },
  { path: 'search', component: SearchComponent, pathMatch: 'full' },
  { path: 'weights', component: WeightPageComponent, pathMatch: 'full' },
  { path: 'reference', component: ReferencePageComponent, pathMatch: 'full' },
  { path: 'report-file-download', component: ReportFileDownloadComponent, pathMatch: 'full' },
  { path: 'report-s3-file-download', component: ReportS3FileDownloadComponent, pathMatch: 'full' },
  { path: 'factors', component: FactorsPageComponent, pathMatch: 'full' },
  { path: 'reporting', component: ReportingPageComponent, pathMatch: 'full' },
  { path: 'glossary', component: OtherPageComponent, pathMatch: 'full' },
  { path: 'other', component: OtherPageComponent, pathMatch: 'full' },
  { path: 'entitlementtest', component: EntitlementtestComponent, pathMatch: 'full' },
  { path: 'waves', component: WavesComponent, pathMatch: 'full' },
  { path: 'apireport', component: APIReportComponent, pathMatch: 'full' },
  { path: 'fileloadstatus', component: FileloadstatusComponent, pathMatch: 'full' },
  { path: 'lam', component: LAMComponent, pathMatch: 'full' },
  { path: 'company', component: CompanyComponent, pathMatch: 'full' },
  { path: 'user-Profile', component: UserProfileComponent, pathMatch: 'full' },
  { path: 'apiConfiguration', component: ApiConfigurationComponent, pathMatch: 'full' },
  { path: 'company-api-search', component: CompanyApiSearchComponent, pathMatch: 'full' },
  { path: 'index-of-interest', component: IndexOfInterestComponent, pathMatch: 'full' },
  { path: 'company-search', component: CompanySearchComponent, pathMatch: 'full' },
  { path: 'api-keys', component: ApiKeysComponent, pathMatch: 'full' },
  { path: 'apikey-search', component: ApiKeySearchComponent, pathMatch: 'full' },
  { path: 'user-search', component: UserSearchComponent, pathMatch: 'full' },
  { path: 'reviews', component: ReviewsComponent, pathMatch: 'full' },
  { path: 'reviewproducts', component: ReviewProductsComponent, pathMatch: 'full' },
  { path: 'reviewschedules', component: ReviewScheduleComponent, pathMatch: 'full' },
  { path: 'ddstracker', component: DdstrackerComponent, pathMatch: 'full' },
  { path: 'performance-indicators-report', component: PerformanceIndicatorsReportComponent, pathMatch: 'full' },
  { path: 'price-data-quality', component: PriceDataQualityComponent, pathMatch: 'full' },
  { path: 'sector-data-quality', component: SectorDataQualityComponent, pathMatch: 'full' },
  { path: 'nicb-sector-data-quality', component: NicbSectorDataQualityComponent, pathMatch: 'full' },
  { path: 'rgs-sector-data-quality', component: RgsSectorDataQualityComponent, pathMatch: 'full' },
  { path: 'openprice-data-quality', component: OpenpriceDataQualtityComponent, pathMatch: 'full' },
  { path: 'country-data-quality', component: CountryDataQualityComponentComponent, pathMatch: 'full' },
  { path: 'shares-data-quality', component: SharesDataQualityComponent, pathMatch: 'full' },
  { path: 'geographical-information', component: GeographicalInformationComponent, pathMatch: 'full' },
  { path: 'SedolAlert', component: SedolAlertComponent, pathMatch: 'full' },
  { path: 'sedol-search', component: SedolSearchComponent, pathMatch: 'full' },
  { path: 'market-value-dataquality', component: MarketValueDataqualityComponent, pathMatch: 'full' },
  { path: 'alternate-data-delivery', component: AlternateDataDeliveryComponent, pathMatch: 'full' },
  { path: 'summary-data-quality', component: SummaryDataQualityComponentComponent, pathMatch: 'full' },

  { path: 'telemetry-logs', component: TelemetryLogs, pathMatch: 'full' },
  { path: 'usergroup-search', component: UserGroupSearchComponent, pathMatch: 'full' },
  { path: 'usergroup', component: UserGroupComponent, pathMatch: 'full' },
  { path: 'role-page-mapping-search', component: RolePageMappingSearchComponent, pathMatch: 'full' },
  { path: 'role-page-mapping', component: RolePageMappingComponent, pathMatch: 'full' },
  { path: 'user-role-mapping', component: UserRoleMappingComponent, pathMatch: 'full' },
  { path: 'menu-items', component: MenuItemsSearchComponent, pathMatch: 'full' },
  { path: 'menu-items-edit', component: MenuItemComponent, pathMatch: 'full' },
  { path: 'licence-bands', component: LicenceBandsComponent, pathMatch: 'full' },
  { path: 'licence-search-bands', component: LicenceBandsSearchComponent, pathMatch: 'full' },
  { path: 'benchmarks', component: BenchmarksPageComponent, pathMatch: 'full' },
  { path: 'metadataconfig', component: MetaDataConfigComponent, pathMatch: 'full' },
  { path: 'sitablesconfig', component: SITablesDataConfigComponent, pathMatch: 'full' },
  { path: 'sidatacalculation', component: SIDataCalculationComponent, pathMatch: 'full' },
  { path: 'pbr', component: PortfoliosPageComponent, pathMatch: 'full' },
  { path: 'pbr/portfolios/:id', component: PortfolioDetailsPageComponent, pathMatch: 'full' },
  { path: 'pbr', loadChildren: () => import('../pbr/pbr.module').then(m => m.PbrModule) },
  { path: 'climatereports', component: ClimateReportsPageComponent, pathMatch: 'full' },
  { path: 'historyreports', component: HistoryReportsPageComponent, pathMatch: 'full' },
  { path: 'reports', component: ReportsPageComponent, pathMatch: 'full' },
  { path: 'reports/print', component: ReportPrintPageComponent, pathMatch: 'full' },
  { path: 'client-index-mapping', component: ClientIndexMappingGridComponent, pathMatch: 'full' },
  { path: 'client-index-mapping/details', component: ClientIndexMappingDetailComponent, pathMatch: 'full' },
  { path: 'index-master', component: IndexComponent, pathMatch: 'full' },
  { path: 'data-restore', component: DataRestoreComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
