import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { EntitlementService } from '../../services/entitlement.service';

import { CompanyModel, ReportTypeModel } from '../../models/entitlement.model';
import { ClientIndex, ClientIndexMappingForm } from '../../models/client-index-mapping-details';
import { MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';


@Component({
  selector: 'app-client-index-mapping-form',
  templateUrl: './client-index-mapping-form.component.html',
  styleUrls: ['./client-index-mapping-form.component.scss']
})

export class ClientIndexMappingFormComponent implements OnInit {
  clientIndexMappingForm: FormGroup = this.fb.group({
    id: [0],
    company: ['', Validators.required],
    companyId: [0, Validators.required],
    reportType: ['', Validators.required],
    reportTypeId: [0, Validators.required],
    reportGroup: [''],
    file: ['']
  } as Record<keyof ClientIndexMappingForm, any>);
  companies: CompanyModel[]=[];
  reportTypes: ReportTypeModel[]=[];
  fileNames: string;
  fileLoading$ = new BehaviorSubject<boolean>(false);
  isClientIndexForm = false;
  isUpdateForm = false;
  count: number = 0;
  companyToolTip: string = 'Please select Company';
  reportTypeToolTip: string = 'Please select Report type';

  @Input() set data(clientIndex: ClientIndexMappingForm) {
    if (clientIndex != null) {
      this.isUpdateForm = true;
      this.clientIndexMappingForm.controls['id'].patchValue(clientIndex['id']);
      this.clientIndexMappingForm.controls['company'].patchValue(clientIndex['companyId']);
      this.clientIndexMappingForm.controls['reportType'].patchValue(clientIndex['reportTypeId']);
    }


  }
  @Output() submitForm = new EventEmitter<ClientIndexMappingForm>();
  @ViewChild('allSelected') private allSelected: MatOption;


  constructor(private route: ActivatedRoute,
    private entitlementService: EntitlementService, private fb: FormBuilder) { }

  ngOnInit() {
    this.entitlementService.getCompanies().then(result => {
      this.companies = result;
    });

    this.entitlementService.getReportType().then(result => {
      this.reportTypes = result;
    });
  }

  onClick(): void {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.click();
    this.fileLoading$.next(true);
    setTimeout(() => this.fileLoading$.next(false), 1000);
  }
  fileProgress(event) {
    var result = [];
    var marketValueSum = 0;
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event: Event) => {
        console.log(reader.result);
      };
      this.fileNames = file.name;
      this.clientIndexMappingForm.controls['file'].setValue(file);
    }

  }

  onSubmit(): void {
    if (this.clientIndexMappingForm.invalid) {
      alert("Invalid Form");
      this.isClientIndexForm = true;
      return;
    }
    if (this.clientIndexMappingForm.controls['file'].value == '' && !this.isUpdateForm) {
      alert("Invalid File");
      this.isClientIndexForm = true;
      return;
    }

    this.submitForm.emit(this.clientIndexMappingForm.getRawValue());
  }

  public selectedValue(event: MatSelectChange) {
    this.clientIndexMappingForm.controls['reportGroup'].patchValue(event.source.triggerValue);
    this.companyToolTip = event.source.triggerValue;
  }


  rtSelectAll() {
    if (this.allSelected.selected) {
      this.clientIndexMappingForm.controls.reportType
        .patchValue([...this.reportTypes.map(item => item.reportTypeId), 0]);
    } else {
      this.clientIndexMappingForm.controls.reportType.patchValue([]);
    }
    this.reportTypeToolTip = this.GetToolTip(this.clientIndexMappingForm.controls.reportType.value, this.reportTypes);
  }


  rtSelect(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
    }
    if (this.clientIndexMappingForm.controls.reportType.value.length == this.reportTypes.length)
      this.allSelected.select();

      this.reportTypeToolTip = this.GetToolTip(this.clientIndexMappingForm.controls.reportType.value, this.reportTypes);
  }

  GetToolTip(selected:any, data:any): string {
    let tooltip = '';
    selected.filter(x => x !== 0).forEach(id => {
      tooltip += data.find(x => x.reportTypeId == id).reportType + ', ';
    }); 
    tooltip = tooltip.slice(0,-2);
    if (tooltip.length <= 0)
      tooltip = 'Please select Report type';
    return tooltip;
  }

}
