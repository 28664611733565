import {Component, NgModule, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {PortfolioForm} from '../../models/portfolio';  

@Component({
  selector: 'app-new-portfolio-popup',
  templateUrl: './new-portfolio-popup.component.html',
  styleUrls: ['./new-portfolio-popup.component.scss']  
})

 


export class NewPortfolioPopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NewPortfolioPopupComponent> ) {
    
  }

  ngOnInit(): void {  }
  
  myTabFocusChange(index: number){
    console.log('Selected index: ' + index);
  }

  onSubmit(data: PortfolioForm): void {
    this.dialogRef.close(data);
  }

    MyCtrl($scope) {
    $scope.value= 'foo';
    
    $scope.newValue = function(value) {
       console.log(value);
    }
}
}
