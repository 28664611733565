import { Component, OnInit } from '@angular/core';
import { FilterPipe } from '../../filter.pipe';
import { AdminReportService } from '../../services/adminreportservice';
import { FileLoadStatus } from '../../models/fileload-status.model';
import { BiService } from '../../services/BiService.service';

@Component({
  selector: 'app-fileloadstatus',
  templateUrl: './fileloadstatus.component.html',
  styleUrls: ['./fileloadstatus.component.css'],
})
export class FileloadstatusComponent implements OnInit {
  
  didFail: any;
  fileLoadStatus: FileLoadStatus[] = [];  
  fileLoadStatusdidFail = false;  

 
  today = new Date(Date.now());
  inputdate = '';
  
  constructor(private AdminReportService: AdminReportService, private biService: BiService) {
    this.today.setDate(this.today.getDate());
    this.inputdate= this.today.toISOString().split('T')[0];    
  }

  ngOnInit() {    
    this.AdminReportService.getFileLoadStatus(this.inputdate);
    this.AdminReportService.fileLoadStatusLoaded.subscribe(
      (data:FileLoadStatus[]) => {      
       this.fileLoadStatus = data;           
        this.fillData();
      });
     this.AdminReportService.fileLoadStatusFailed.subscribe(
      (didFail: boolean) => this.fileLoadStatusdidFail = didFail
    ); 

  }

  onGetResults() {    
    this.biService.addEvent2("FileLoadStatusGet", [{Name:"inputdate", Value:this.inputdate}]);    

    this.AdminReportService.getFileLoadStatus(this.inputdate);
    }

  fillData():void {
    console.log(this.inputdate);
  }

  getColor = function(fileStatus){    
    if(fileStatus.loadstatus == 'Loaded')
      return 'green'
    else if(fileStatus.loadstatus == 'Loading')
      return 'orange';
    else 
      return 'red';
  }
}