import {Component, Input, Output, EventEmitter} from '@angular/core';
import {DateService } from '../../services/date.service';

@Component({
    selector: 'custom-table-holdings',
    templateUrl: './custom-table-holdings.component.html',
    styleUrls:['custom-table-holdings.component.css']
})
export class CustomTableHoldingsComponent{
    customColumns:any[];
    @Input() data:any[];
    dateFormat: any;
    sortedArray:any;
    sortedColumn:any;
    isAscending:boolean=true;
    @Output() notifySorting : EventEmitter<string>= new EventEmitter<string>();
    @Input() visibleColums:any[]
    constructor(private dateService : DateService){        
     }
     ngOnInit(){      
        this.dateFormat = this.dateService.getDateFormat(); 
        this.dateService.dateFormatPublish().subscribe(dateFormat=>{
            this.dateFormat =  dateFormat;                          
        });     
     }

     columntoSort(columnname:any)
     {
        this.sortedColumn=columnname;
        if(this.isAscending)
        {       
           this.isAscending=false;           
           this.notifySorting.emit(columnname +" ASC");
        }
        else{          
               this.isAscending=true;
               this.notifySorting.emit(columnname +" DESC");
        }       
        this.bind( this.sortedArray, this.customColumns)   ;
     }
     
    bind(targetData:any, targetColumns:any[]){
      this.customColumns = targetColumns;
      this.visibleColums = []; 
     
      for(let elem of this.customColumns){  
          if(elem.columnVisibility){
              if(elem.columnName!="Select All")
              {
              var visibleElem =  {UIDisplayValue: elem.uiDisplayValue,ColumnCssClass:elem.columnCssClass,
                ColumnName:elem.columnName.substring(0,1).toLowerCase()+elem.columnName.substring(1),
             HasFilter: elem.hasFilter, Filter:elem.filter};
              this.visibleColums.push(visibleElem);
            }
          }
      }
      this.data = targetData;    
    }

}