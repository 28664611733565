import { Component, OnInit } from '@angular/core';
import { UserGroupModel } from '../../models/authorization';
import { AuthorizationService } from '../../services/authorization.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-usergroup-search',
  templateUrl: './usergroup-search.component.html',
  styleUrls: ['./usergroup-search.component.css'] 
})
export class UserGroupSearchComponent implements OnInit {

  usergroupData: UserGroupModel[];
  commitStatus:any;
  constructor(private services:AuthorizationService) { 
   
  } 
  ngOnInit() {
    this.usergroupData =[{id:"",userGroupName:"",userType:""}];
    this.services.getUserGroupInfo().then(data =>
     {
      this.usergroupData=data; 
      console.log("User Group: "+ this.usergroupData); 
     }) 
      }
      deleteUserGroup(usergroupData:UserGroupModel)
      {
        this.services.deleteUserGroup(usergroupData).then(result =>{
          if(result == "1")
          {
            alert("User Group: "+ usergroupData.userGroupName +" "+'Deleted successfully');
              this.services.getUserGroupInfo().then(data =>
              {
              this.usergroupData=data; 
              console.log(this.usergroupData); 
              })       
          }
          else
          {
            this.commitStatus = "Error while deleting User Group:" + usergroupData.userGroupName 
          }
      });
     
      }
}
