import { Portfolio } from "./portfolio";
import { Benchmark } from "./benchmark";
import {GridData} from './common';
import { DateTime } from "aws-sdk/clients/devicefarm";

export interface Report {
  id: number;
  name: string;
  portfolio: string;
  portfolioId : number;
  benchmark: string;
  dateCreated: string;
  asOfDate: string;
  assetsClass: string;
  template: string;
  metrics: string;
  currency : string;
}

export interface NewReportForm {
  id: number;
  name: string;
  asOfDate: string;
  portfolioIndexMarker: {id: number ,name: string};
  benchmarkIndexMarker: string;
  date: string;
  assetsClass: string;
  template: string;
  metrics: string;
  currency : string;
}

export const reportToFormMapper: Record<keyof Report, keyof NewReportForm> = {
  id: 'id',
  name: 'name',
  portfolio: 'portfolioIndexMarker',
  portfolioId : 'portfolioIndexMarker',
  benchmark: 'benchmarkIndexMarker',
  dateCreated: 'date',
  asOfDate: 'asOfDate',
  assetsClass: 'assetsClass',
  template: 'template',
  metrics: 'metrics',
  currency : 'currency'
}
export interface ReportData {    
  portfolio: Portfolio;
  benchmark: Benchmark;
  //top10ByWeights: any[];
  grTop10ByPfWeight:any[];
  waciTop10ByPfWeight:any[];
  waciTop10ByContribution:any[];
  grTop10ByContribution:any[];
  evicTop10ByPfWeight:any[];
  evicTop10ByContribution:any[];
  fossilfuelTop10ByContributionPfWeightOng:any[];
  fossilfuelTop10ByContributionPfWeightCoal:any[];
  sectorDetailsTable:any[];
  performanceData: number[];
  performanceLabels: string[];
  waciYears: string[];
  overWeightData: number[];
  overWeightLabels: string[];
  countryBreakdownData: number[];
  countryBreakdownLabels: string[];
  regionalBreakdownData: number[];
  regionalBreakdownLabels: string[];
  carbonExposureData: number[];
  carbonExposureLabels: string[];
  greenRevenueData: number[];
  greenRevenueLabels : string[];
  fossilFuelCoalEmissionData: number[];
  fossilFuelCoalEmissionLabels : string[];
  fossilFuelOngEmissionData: number[];
  fossilFuelOngEmissionLabels : string[];
  greenRevenueSegmentData: any[];
  fossilFuelSectorData: any[];
  waciAttributionEffectData : any[];
  waciAttributionEffectByScopeData : any[];
  evicAttributionEffectData : any[];
  evicAttributionEffectByScopeData : any[];
  grPfCoverageData : number[];
  grPfCoverageLabels : string[];
  grBmCoverageData : number[];
  grBmCoverageLabels : string[];
  waciBmCoverageLabels :string[];
  waciPfCoverageLabels:string[];
  waciPfCoverageData:number[];
  waciBmCoverageData:number[];
  gricbSectorGapData : number[];
  gricbSectorGapLabels : string[];
  waciIndustryLabels :string[];
  waciIndustryBenchmarkData: number[];
  waciIndustryPortfolioData: number[];
  waciCountryLabels :string[];
  waciCountryBenchmarkData: number[];
  waciCountryPortfolioData: number[];
  totalMarketValue : number;
  portfolioValues: number;
  waciSectorCtbLabels : string[];
  waciSectorCtbPortfolioData : number[];
  waciSectorCtbBenchmarkData : number[];
  evicSectorCtbLabels :string[];
  evicSectorCtbPortfolioData :number[];
  evicSectorCtbBenchmarkData :number[];
  waciPortfolioHistoricData : number[];
  waciBenchmarkHistoricData : number[];
  waciHistoricYearLabels : string[];
  wacikrRevenueExposureData : number[];
  wacikrRevenueExposureLabel : string[];
  wacikrSectorGapsData : number[];
  wacikrSectorGapsLabel : string[];
  evickrRevenueExposureData : number[];
  evickrRevenueExposureLabel : string[];
  evicaeEstimateLabel :string[],
  evicaeEstimateData :number[],
  evickrSectorGapsData : number[];
  evickrSectorGapsLabel : string[];
  historicBMCoverageData :any[];
  historicBMNonCoverageData :any[];
  historicPFCoverageData :any[];
  historicPFNonCoverageData :any[];
  waciHistoricCAGRData :any;
  tpimqBmCoverageLabels :string[];
  tpimqPfCoverageLabels:string[];
  tpimqPfCoverageData:number[];
  tpimqBmCoverageData:number[];
  tpimqTop10ByPfWeight:any[];
  tpimqTop10ByContribution:any[];
  fossilBmCoverageLabels :string[];
  fossilBmCoverageData:number[];
  fossilPfCoverageLabels:string[];
  fossilPfCoverageData:number[];
  ffTop10ByContributionData:any[];
  tpimqScoreData: number[];
  tpimqScoreLabels : string[];
  tpimqScoreSectorGapsData : number[];
  tpimqScoreSectorGapsLabels : string[];
  fossilFuelSectorGapsData: number[];
  fossilFuelSectorGapsLabels: string[];
  evicaeSectorDetailsTable:any[];
  evicaeHistoricBMNonCoverageData:any[];
  evicaeHistoricBMCoverageData:any[];
  evicaeHistoricPFCoverageData:any[];
  evicaeHistoricPFNonCoverageData:any[];
  evicaeBenchmarkHistoricData: number[];
  evicaePortfolioHistoricData: number[];
  evicaeHistoricYearLabels: string[];
  evicaeHistoricCAGRData: any;
  evicaePfCoverageData: number[];
  evicaePfCoverageLabels: string[];
  evicaeBmCoverageData: number[];
  evicaeBmCoverageLabels: string[];
  crpiCoalBmCoverageData: number[];
  crpiCoalBmCoverageLabels: string[];
  crpiCoalPfCoverageData: number[];
  crpiCoalPfCoverageLabels:string[];
  crpiOilBmCoverageData: number[];
  crpiOilBmCoverageLabels: string[];
  crpiOilPfCoverageData: number[];
  crpiOilPfCoverageLabels: string[];
  pfAndBmPerformanceData: number[];
  pfAndBmPerformanceLabels: string[];
  metaDataData:string[];
  metaDataLabels:string[];
}
export function PortfolioReportForm(report: Report): NewReportForm {
  return Object.keys(reportToFormMapper).reduce((acc, reportField) => {
    // TODO: Need to be refactoring based on API Interface
    if (reportToFormMapper[reportField] === null) {
      return acc;
    }
    acc[reportToFormMapper[reportField]] = report[reportField];
    return acc;
  }, {} as NewReportForm);
}



export interface ExportReportData {    
  portfolioId: string;
  sedol: string;
  cusip: string;
  isin: string;
  consCode: string;
  name: string;
  countryCode: string;
  icbIndustry: string;
  icbIndustryCode: string; 
  pfWeight: number;
  bmWeight: number;
  pfMktValue: number;   

  //#region  Waci
  scope1and2PerRevenueRatio_tCO2ePerUSDm_Scope1GHG: string;
  scope1and2PerRevenueRatio_tCO2ePerUSDm_Scope2GHG: string;
  scope1and2PerRevenueRatio_tCO2ePerUSDm_Revenue: string;
  revenueRatio_Scope1and2PerRevenueRatio: string;
  scope1and2PerRevenueRatio_tCO2ePerUSDm_PFRatedWeight: string;
  scope1and2PerRevenueRatio_tCO2ePerUSDm_PFContributionToWA: string;
  scope1and2PerRevenueRatio_tCO2ePerUSDm_BMRatedWeight: string;
  scope1and2PerRevenueRatio_tCO2ePerUSDm_BMContributionToWA: string; 
  //#endregion

  // //#region MCaeRatio  
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_Scope1GHG: string;
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_Scope2GHG: string;
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_MarketCap: string;
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_Scope1and2PerMktCapRatio: string;
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_PFRatedWeight: string;
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_PFContributionToWA: string;
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_BMRatedWeight: string;
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_BMContributionToWA: string;  
  //#endregion

  //#region EVicRatio  
  scope1and2PerEVICRatio_tCO2ePerUSDm_Scope1GHG: string;
  scope1and2PerEVICRatio_tCO2ePerUSDm_Scope2GHG: string;
  scope1and2PerEVICRatio_tCO2ePerUSDm_EVIC: string;
  evicRatio_Scope1and2PerEVICRatio: string;
  scope1and2PerEVICRatio_tCO2ePerUSDm_PFRatedWeight: string;
  scope1and2PerEVICRatio_tCO2ePerUSDm_PFContributionToWA: string;
  scope1and2PerEVICRatio_tCO2ePerUSDm_BMRatedWeight: string;
  scope1and2PerEVICRatio_tCO2ePerUSDm_BMContributionToWA: string; 
  //#endregion

  //#region GreenRev 
  grPerRev_GRPerRevenueTier123: string;
  grPerRev_GRPerRevenueTier12: string;
  grPerRev_GRPerRevenueEUTaxonomy: string;
  grPerRev_PFRatedWeight: string; 
  grPerRev_PFContributionToWA: string;
  grPerRev_BMRatedWeight_Pct: string;
  grPerRev_BMContributionToWA: string; 
  //#endregion

  //#region FossilFuel 
  expToFFICBSubsectors_ICBSubsector: string;
  expToFFICBSubsectors_ICBSubsectorCode: string;
  expToFFICBSubsectors_PFRatedWeight: string;
  expToFFICBSubsectors_BMRatedWeight: string;
  //#endregion

  //#region FossilFuel Coal & OilandGas 
  ffrpcO2Coal1P: string;
  ffrpcO2Coal1PPerRevenueRatio: string;
  ffrpcO2Coal1P_BMContributionToWA: string;
  ffrpcO2Coal1P_BMRatedWeight: string;
  ffrpcO2Coal1P_PFContributionToWA: string;
  ffrpcO2Coal1P_PFRatedWeight: string;
  ffrpcO2Coal1P_Revenue: string;

  ffrpcO2OilandGas2Por1P: string;
  ffrpcO2OilandGas2Por1P_Revenue: string;
  ffrpcO2OilandGas2Por1PPerRevenueRatio: string;
  ffrpcO2OilandGas2Por1P_PFRatedWeight: string;
  ffrpcO2OilandGas2Por1P_PFContributionToWA: string;
  ffrpcO2OilandGas2Por1P_BMRatedWeight: string;
  ffrpcO2OilandGas2Por1P_BMContributionToWA: string;   
  //#endregion

  //#region TPIMQ 
  tpimqScore_0To5Score_TPIMQScore: string;
  tpimqScore_0To5Score_PFRatedWeight: string;
  tpimqScore_0To5Score_PFContributionToWA: string;
  tpimqScore_0To5Score_BMRatedWeight_Pct: string;
  tpimqScore_0To5Score_BMContributionToWA: string;  
  //#endregion

}

export interface ExportReportDetails extends GridData<ExportReportData> {}

export enum ExportReportDetailsColumns {
  sedol = 'sedol',
  cusip = 'cusip',
  isin = 'isin',
  consCode = 'consCode',
  name = 'name',
  countryCode = 'countryCode',  
  icbIndustry = 'icbIndustry',
  icbIndustryCode = 'icbIndustryCode', 
  pfWeight = 'pfWeight',
  bmWeight = 'bmWeight',
  pfMktValue = 'pfMktValue',   

  scope1and2PerRevenueRatio_tCO2ePerUSDm_Scope1GHG = 'scope1and2PerRevenueRatio_tCO2ePerUSDm_Scope1GHG',
  scope1and2PerRevenueRatio_tCO2ePerUSDm_Scope2GHG = 'scope1and2PerRevenueRatio_tCO2ePerUSDm_Scope2GHG',
  scope1and2PerRevenueRatio_tCO2ePerUSDm_Revenue = 'scope1and2PerRevenueRatio_tCO2ePerUSDm_Revenue',
  revenueRatio_Scope1and2PerRevenueRatio = 'revenueRatio_Scope1and2PerRevenueRatio',
  scope1and2PerRevenueRatio_tCO2ePerUSDm_PFRatedWeight = 'scope1and2PerRevenueRatio_tCO2ePerUSDm_PFRatedWeight',
  scope1and2PerRevenueRatio_tCO2ePerUSDm_PFContributionToWA = 'scope1and2PerRevenueRatio_tCO2ePerUSDm_PFContributionToWA',
  scope1and2PerRevenueRatio_tCO2ePerUSDm_BMRatedWeight = 'scope1and2PerRevenueRatio_tCO2ePerUSDm_BMRatedWeight',
  scope1and2PerRevenueRatio_tCO2ePerUSDm_BMContributionToWA = 'scope1and2PerRevenueRatio_tCO2ePerUSDm_BMContributionToWA',
  //#endregion

  // MCae ratio 
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_Scope1GHG = 'scope1and2PerMktCapRatio_tCO2ePerUSDm_Scope1GHG',
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_Scope2GHG = 'scope1and2PerMktCapRatio_tCO2ePerUSDm_Scope2GHG',
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_MarketCap = 'scope1and2PerMktCapRatio_tCO2ePerUSDm_MarketCap',
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_Scope1and2PerMktCapRatio = 'scope1and2PerMktCapRatio_tCO2ePerUSDm_Scope1and2PerMktCapRatio',
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_PFRatedWeight = 'scope1and2PerMktCapRatio_tCO2ePerUSDm_PFRatedWeight',
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_PFContributionToWA = 'scope1and2PerMktCapRatio_tCO2ePerUSDm_PFContributionToWA',
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_BMRatedWeight = 'scope1and2PerMktCapRatio_tCO2ePerUSDm_BMRatedWeight',
  // scope1and2PerMktCapRatio_tCO2ePerUSDm_BMContributionToWA = 'scope1and2PerMktCapRatio_tCO2ePerUSDm_BMContributionToWA',
    
  //#region EVicRatio 
  scope1and2PerEVICRatio_tCO2ePerUSDm_Scope1GHG = 'scope1and2PerEVICRatio_tCO2ePerUSDm_Scope1GHG',
  scope1and2PerEVICRatio_tCO2ePerUSDm_Scope2GHG = 'scope1and2PerEVICRatio_tCO2ePerUSDm_Scope2GHG',
  scope1and2PerEVICRatio_tCO2ePerUSDm_EVIC = 'scope1and2PerEVICRatio_tCO2ePerUSDm_EVIC',
  evicRatio_Scope1and2PerEVICRatio = 'evicRatio_Scope1and2PerEVICRatio',
  scope1and2PerEVICRatio_tCO2ePerUSDm_PFRatedWeight = 'scope1and2PerEVICRatio_tCO2ePerUSDm_PFRatedWeight',
  scope1and2PerEVICRatio_tCO2ePerUSDm_PFContributionToWA = 'scope1and2PerEVICRatio_tCO2ePerUSDm_PFContributionToWA',
  scope1and2PerEVICRatio_tCO2ePerUSDm_BMRatedWeight = 'scope1and2PerEVICRatio_tCO2ePerUSDm_BMRatedWeight',
  scope1and2PerEVICRatio_tCO2ePerUSDm_BMContributionToWA = 'scope1and2PerEVICRatio_tCO2ePerUSDm_BMContributionToWA',
  //#endregion

  //#region GreenRev  
  grPerRev_GRPerRevenueTier123 = 'grPerRev_GRPerRevenueTier123',
  grPerRev_GRPerRevenueTier12 = 'grPerRev_GRPerRevenueTier12',
  grPerRev_GRPerRevenueEUTaxonomy = 'grPerRev_GRPerRevenueEUTaxonomy',
  grPerRev_PFRatedWeight = 'grPerRev_PFRatedWeight', 
  grPerRev_PFContributionToWA = 'grPerRev_PFContributionToWA',
  grPerRev_BMRatedWeight_Pct = 'grPerRev_BMRatedWeight_Pct',
  grPerRev_BMContributionToWA = 'grPerRev_BMContributionToWA',
  //#endregion

  //#region FossilFuel 
  expToFFICBSubsectors_ICBSubsector = 'expToFFICBSubsectors_ICBSubsector',
  expToFFICBSubsectors_ICBSubsectorCode = 'expToFFICBSubsectors_ICBSubsectorCode',
  expToFFICBSubsectors_PFRatedWeight = 'expToFFICBSubsectors_PFRatedWeight',
  expToFFICBSubsectors_BMRatedWeight = 'expToFFICBSubsectors_BMRatedWeight',
  //#endregion

  //#region FossilFuelOilandGas 
  ffrpcO2Coal1P  = 'ffrpcO2Coal1P',
  ffrpcO2Coal1P_Revenue  = 'ffrpcO2Coal1P_Revenue', 
  ffrpcO2Coal1PPerRevenueRatio  = 'ffrpcO2Coal1PPerRevenueRatio',
  ffrpcO2Coal1P_PFRatedWeight  = 'ffrpcO2Coal1P_PFRatedWeight',
  ffrpcO2Coal1P_PFContributionToWA  = 'ffrpcO2Coal1P_PFContributionToWA',
  ffrpcO2Coal1P_BMRatedWeight  = 'ffrpcO2Coal1P_BMRatedWeight',
  ffrpcO2Coal1P_BMContributionToWA  = 'ffrpcO2Coal1P_BMContributionToWA',

  ffrpcO2OilandGas2Por1P = 'ffrpcO2OilandGas2Por1P',
  ffrpcO2OilandGas2Por1P_Revenue = 'ffrpcO2OilandGas2Por1P_Revenue',
  ffrpcO2OilandGas2Por1PPerRevenueRatio = 'ffrpcO2OilandGas2Por1PPerRevenueRatio',
  ffrpcO2OilandGas2Por1P_PFRatedWeight = 'ffrpcO2OilandGas2Por1P_PFRatedWeight',
  ffrpcO2OilandGas2Por1P_PFContributionToWA = 'ffrpcO2OilandGas2Por1P_PFContributionToWA',
  ffrpcO2OilandGas2Por1P_BMRatedWeight = 'ffrpcO2OilandGas2Por1P_BMRatedWeight',
  ffrpcO2OilandGas2Por1P_BMContributionToWA = 'ffrpcO2OilandGas2Por1P_BMContributionToWA',
  //#endregion

  //#region TPIMQ  
  tpimqScore_0To5Score_TPIMQScore = 'tpimqScore_0To5Score_TPIMQScore',
  tpimqScore_0To5Score_PFRatedWeight = 'tpimqScore_0To5Score_PFRatedWeight',
  tpimqScore_0To5Score_PFContributionToWA = 'tpimqScore_0To5Score_PFContributionToWA',
  tpimqScore_0To5Score_BMRatedWeight_Pct = 'tpimqScore_0To5Score_BMRatedWeight_Pct',
  tpimqScore_0To5Score_BMContributionToWA = 'tpimqScore_0To5Score_BMContributionToWA' 
}

export interface ExportPortfolioConfigData
{
      datasetDate: string;
      classificationid: string;
      classificationattributeid: string;
      classificationKeyColumn :string;
      datasetDate_Year: string; 
} 


