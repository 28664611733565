import { Component,ViewChild,OnInit } from '@angular/core';
import {DateService} from '../../services/date.service';
import {ReferenceService} from '../../services/reference.service';
import {CustomTableComponent} from '../custom-table/custom-table.component';
import {ColumnChooserService} from '../../services/column-chooser.service';
import {CustomColumnsModel} from '../../models/custom-columns-model';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {ReportAnalyseGridComponent} from '../report-analyse-grid/report-analyse-grid.component';
import {ReportFilter,SearchSecurity} from '../../models/report-filter.model';
import { BiService } from '../../services/BiService.service';
import { ProductIndexMarkerPipe,IndexMarkerPropertiesPipe } from '../../filter.pipe';
import { PagerService } from '../../services/pager.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
  import {MatSelect} from '@angular/material/select';
  import {MatTableDataSource} from '@angular/material/table';
  import {MatPaginator} from '@angular/material/paginator';
  import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';
  import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'reference-page',
    templateUrl: './reference-page.component.html'
})
export class ReferencePageComponent implements OnInit {    
    
gridCustomColumns: any[];
@ViewChild(CustomTableComponent,{static: false}) customTableComponent; 
public data = {};

indexpropertydata:any;
gridData:any;   
indexMarkers : any;   
endDate : any;
reportFilter:ReportFilter;
displayIndexProperties: boolean = false;
displayProductFamilies: boolean = false;
displaySecuritySearch : boolean = false;
targetDataKey: string = "";
referenceTabLoader : boolean=false;
canDisplayToolbar: boolean = false;

filePrefixFilter : any;
fileDescriptionFilter : any;
fileTypeFilter : any;
folderPathFilter: any;
indexMarkerFilter: any;

securityIdentifierFilter : any;
securityNameFilter : any;
securityIdFilter : any;

indexMarkerPropertiesFilter:any;
indexNameFilter:any;
assetClassCodeFilter:any;
assetClassFilter:any;
geographyCodeFilter:any;
geographyFilter:any;
styleFilter:any;
styleCodeFilter:any;
sizeCodeFilter:any;

pager: any = {};
filesPaged: any[] =[];
page:any = 1;
pageSize :any = 15;
totalEntries :any = 0;
productIndexMarkerFilter : any = {};
securitySearchFilter: any = {};
isProductIndexMarker:boolean = false;
isSecuritySearch:boolean = false;
asOfDate :any;

dataSource: MatTableDataSource<SearchSecurity>;
displayedColumns_New :  ['SecurityIdentifier', 'SecurityName'];

@ViewChild(ReportAnalyseGridComponent,{static: false}) reportAnalyseGridComponent;

constructor(private exportService: ExportToExcelService,private dateService:DateService,private referenceService:ReferenceService
  ,private columnChooserService: ColumnChooserService,private publishIndexMarkerService:PublishIndexMarkerService
  ,private indexMarkerService:IndexMarkerService,private biService: BiService, private pagerService: PagerService,private route: ActivatedRoute)
  {
    
  }

ngOnInit(){
  this.reportFilter = new ReportFilter();
  this.indexMarkers = this.indexMarkerService.getIndexMarkers(); 
  this.endDate =this.dateService.getCalendarDate().end;
  this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{
    this.indexMarkers = result;
    this.gridSetup(result);
  });     
  this.referenceService.getIndexMarker$.subscribe((data) => {
    this.indexMarkerFilter = data;
    console.log(this.indexMarkerFilter)
    this.getProductIndexMarker() 
});
 
}
    
gridSetup(indexMarkers) { 
  
  this.referenceTabLoader = true;
  this.displayIndexProperties = true; 
  this.isProductIndexMarker = false;
  this.isSecuritySearch = false;
  this.displayProductFamilies = false; 
  this.displaySecuritySearch=false;
  this.canDisplayToolbar = true;
  this.targetDataKey = "Index Properties";       
  this.indexMarkers = indexMarkers 

    
      this.referenceService.getIndexPropertiesList(this.indexMarkers,this.endDate).then(data=>{
        this.gridData = data;
        this.indexpropertydata=data;
        this.referenceTabLoader = false;
      });     
  
  }

refreshGrid(customColumns:CustomColumnsModel[]){
  var event: any = {eventName: "Column Chooser Click", inputParameters: [{Name : "ColumnName", Value :JSON.stringify(customColumns)}]};
  this.biService.addEvent(event);    
  this.gridCustomColumns = customColumns;
}

getDistinctsProductFamilies(){
  
  var event: any = {eventName: "Clicked Product Families"};
  this.biService.addEvent(event);
  this.isProductIndexMarker = false;
  this.isSecuritySearch = false;
  this.referenceTabLoader = true;
  this.canDisplayToolbar = true;
  this.displayIndexProperties = false; 
  this.displayProductFamilies = true;
  this.displaySecuritySearch=false;
  this.targetDataKey = "Product Families";  
  this.columnChooserService.getReferenceProductFamiliesCustomColumns().then(customs=>{
    this.gridCustomColumns = customs;          
  });   

  this.referenceService.getDistinctProductFamilies().then(data=>{
      this.gridData = data;
      this.referenceTabLoader = false;
    });
  
}

getDDSDownload(){

this.displayIndexProperties = false; 
this.displayProductFamilies = false;
this.displaySecuritySearch = false;
this.referenceTabLoader = false;
this.canDisplayToolbar = false;
}

getProductIndexMarker(){
  
  var event: any = {eventName: "Clicked Product IndexMarker"};
  this.biService.addEvent(event);
  this.isProductIndexMarker = true;
  this.isSecuritySearch = false;
  this.referenceTabLoader = true;
  this.displayIndexProperties = false; 
  this.canDisplayToolbar = true;
  this.displayProductFamilies = true;
  this.displaySecuritySearch = false;
  this.targetDataKey = "Product Index Marker";  
  
  this.productIndexMarkerFilter = {
    Prefix: this.filePrefixFilter,
    FileDescription: this.fileDescriptionFilter,
    FileType : this.fileTypeFilter,
    FolderPath: this.folderPathFilter,
    ProductIndexMarker: this.indexMarkerFilter
  };
  this.referenceService.getProductIndexMarker(this.page,this.pageSize,this.productIndexMarkerFilter).then(data=>{
      this.gridData = data["productIndexes"];
      console.log(this.gridData)
      this.totalEntries = data["totalResults"];
      this.setPage(this.page);
      this.referenceTabLoader = false;
    });
}


securitySearchUpdate(){
  
  var event: any = {eventName: "Clicked Security Search"};
  this.biService.addEvent(event);
  this.isProductIndexMarker = false;
  this.isSecuritySearch = true;
  this.referenceTabLoader = true;
  this.displayIndexProperties = false; 
  this.canDisplayToolbar = true;
  this.displaySecuritySearch = true;
  this.displayProductFamilies=false;
  this.targetDataKey = "Security Search";  
 

  this.securitySearchFilter = {
    SecurityIdentifier: this.securityIdentifierFilter,
    SecurityName: this.securityNameFilter,
    SecurityId: this.securityIdFilter
  };
  
}
  

Exportxls():void{
  var event: any = {eventName: "Export Reference Grid ", inputParameters: [{Name : "TargetDataKey" , Value: this.targetDataKey}]};
  this.biService.addEvent(event);
  if("Index Properties" == this.targetDataKey){    
    var visibleColumns = this.columnChooserService.getVisibleColumnsNames(this.gridCustomColumns); 
    this.referenceService.getIndexPropertiesGridToExport(this.indexMarkers,this.endDate,visibleColumns).then(data=>{
       this.exportService.exportData(data,"IndexPropertiesGridData");
    });
  }  
  else if("Product Families" == this.targetDataKey){
    this.referenceService.getDistinctProductFamilies().then(data=>{
      this.exportService.exportData(data,"ProductFamilies");
   });
  }
  else if("Product Index Marker" == this.targetDataKey){
    this.referenceService.getProductIndexMarkerData().then(data=>{
      this.exportService.exportData(data,"ProductIndexMarker");
   });
  }
  
}

IndexCustomColumnsSelction(event):void{      
  this.refreshGrid(event);
}

onfilePrefixFilterKey(event:any){
    this.referenceTabLoader = true;
    this.page = 1;
    this.productIndexMarkerFilter = {
      Prefix: this.filePrefixFilter,
      FileDescription: this.fileDescriptionFilter,
      FileType : this.fileTypeFilter,
      FolderPath: this.folderPathFilter,
      ProductIndexMarker: this.indexMarkerFilter
    };
    this.page = 1;
    this.referenceService.getProductIndexMarker(this.page,this.pageSize,this.productIndexMarkerFilter).then(data=>{
      this.gridData = data["productIndexes"];
      this.totalEntries = data["totalResults"];
      this.setPage(this.page);
      this.referenceTabLoader = false;
    });
 
}
onfileDescriptionFilterFilterKey(event:any){
    this.referenceTabLoader = true;
    this.page = 1;
    this.productIndexMarkerFilter = {
      Prefix: this.filePrefixFilter,
      FileDescription: this.fileDescriptionFilter,
      FileType : this.fileTypeFilter,
      FolderPath: this.folderPathFilter,
      ProductIndexMarker: this.indexMarkerFilter
    };
    this.page = 1;
    this.referenceService.getProductIndexMarker(this.page,this.pageSize,this.productIndexMarkerFilter).then(data=>{
      this.gridData = data["productIndexes"];
      this.totalEntries = data["totalResults"];
      this.setPage(this.page);
      this.referenceTabLoader = false;
    });
}
  
onfileTypeFilterFilterKey(event:any){
    this.referenceTabLoader = true;
    this.page = 1;
    this.productIndexMarkerFilter = {
      Prefix: this.filePrefixFilter,
      FileDescription: this.fileDescriptionFilter,
      FileType : this.fileTypeFilter,
      FolderPath: this.folderPathFilter,
      ProductIndexMarker: this.indexMarkerFilter
    };
    this.page = 1;
    this.referenceService.getProductIndexMarker(this.page,this.pageSize,this.productIndexMarkerFilter).then(data=>{
      this.gridData = data["productIndexes"];
      this.totalEntries = data["totalResults"];
      this.setPage(this.page);
      this.referenceTabLoader = false;
    });
}
onfolderPathFilterFilterKey(event:any){
    this.referenceTabLoader = true;
    this.page = 1;
    this.productIndexMarkerFilter = {
      Prefix: this.filePrefixFilter,
      FileDescription: this.fileDescriptionFilter,
      FileType : this.fileTypeFilter,
      FolderPath: this.folderPathFilter,
      ProductIndexMarker: this.indexMarkerFilter
    };
    this.page = 1;
    this.referenceService.getProductIndexMarker(this.page,this.pageSize,this.productIndexMarkerFilter).then(data=>{
      this.gridData = data["productIndexes"];
      this.totalEntries = data["totalResults"];
      this.setPage(this.page);
      this.referenceTabLoader = false;
    });
}
onindexMarkerFilterFilterKey(event:any){
    this.referenceTabLoader = true;
    this.page = 1;
   
    this.productIndexMarkerFilter = {
      Prefix: this.filePrefixFilter,
      FileDescription: this.fileDescriptionFilter,
      FileType : this.fileTypeFilter,
      FolderPath: this.folderPathFilter,
      ProductIndexMarker: this.indexMarkerFilter
      
    };
    this.page = 1;
    this.referenceService.getProductIndexMarker(this.page,this.pageSize,this.productIndexMarkerFilter).then(data=>{
      this.gridData = data["productIndexes"];
      this.totalEntries = data["totalResults"];
      this.setPage(this.page);
      this.referenceTabLoader = false;
    });
}
onindexMarkerPropertiesFilterKey(event:any){
  var indexMarkerPropertiesPipe = new IndexMarkerPropertiesPipe();
 var data = indexMarkerPropertiesPipe.transform(this.indexpropertydata,this.indexMarkerPropertiesFilter,this.indexNameFilter,
   this.assetClassCodeFilter,this.assetClassFilter,this.geographyCodeFilter,this.geographyFilter,
  this.styleFilter,this.styleCodeFilter,this.sizeCodeFilter);
  this.gridData = data;
}
onindexNameFilterFilterKey(event:any){
  var indexMarkerPropertiesPipe = new IndexMarkerPropertiesPipe();
  var data = indexMarkerPropertiesPipe.transform(this.indexpropertydata,this.indexMarkerPropertiesFilter,this.indexNameFilter,
    this.assetClassCodeFilter,this.assetClassFilter,this.geographyCodeFilter,this.geographyFilter,
   this.styleFilter,this.styleCodeFilter,this.sizeCodeFilter);
   this.gridData = data;
}
onassetClassCodeFilterKey(event:any){
  var indexMarkerPropertiesPipe = new IndexMarkerPropertiesPipe();
  var data = indexMarkerPropertiesPipe.transform(this.indexpropertydata,this.indexMarkerPropertiesFilter,this.indexNameFilter,
    this.assetClassCodeFilter,this.assetClassFilter,this.geographyCodeFilter,this.geographyFilter,
   this.styleFilter,this.styleCodeFilter,this.sizeCodeFilter);
   this.gridData = data;
}
onassetClassFilterKey(event:any){
  var indexMarkerPropertiesPipe = new IndexMarkerPropertiesPipe();
  var data = indexMarkerPropertiesPipe.transform(this.indexpropertydata,this.indexMarkerPropertiesFilter,this.indexNameFilter,
    this.assetClassCodeFilter,this.assetClassFilter,this.geographyCodeFilter,this.geographyFilter,
   this.styleFilter,this.styleCodeFilter,this.sizeCodeFilter);
   this.gridData = data;
}
ongeographyCodeFilterKey(event:any){
  var indexMarkerPropertiesPipe = new IndexMarkerPropertiesPipe();
  var data = indexMarkerPropertiesPipe.transform(this.indexpropertydata,this.indexMarkerPropertiesFilter,this.indexNameFilter,
    this.assetClassCodeFilter,this.assetClassFilter,this.geographyCodeFilter,this.geographyFilter,
   this.styleFilter,this.styleCodeFilter,this.sizeCodeFilter);
   this.gridData = data;
}
ongeographyFilterKey(event:any){
  var indexMarkerPropertiesPipe = new IndexMarkerPropertiesPipe();
  var data = indexMarkerPropertiesPipe.transform(this.indexpropertydata,this.indexMarkerPropertiesFilter,this.indexNameFilter,
    this.assetClassCodeFilter,this.assetClassFilter,this.geographyCodeFilter,this.geographyFilter,
   this.styleFilter,this.styleCodeFilter,this.sizeCodeFilter);
   this.gridData = data;
}
onstyleFilterKey(event:any){
  var indexMarkerPropertiesPipe = new IndexMarkerPropertiesPipe();
 var data = indexMarkerPropertiesPipe.transform(this.indexpropertydata,this.indexMarkerPropertiesFilter,this.indexNameFilter,
   this.assetClassCodeFilter,this.assetClassFilter,this.geographyCodeFilter,this.geographyFilter,
  this.styleFilter,this.styleCodeFilter,this.sizeCodeFilter);
  this.gridData = data;
}
onstyleCodeFilterrKey(event:any){
  var indexMarkerPropertiesPipe = new IndexMarkerPropertiesPipe();
  var data = indexMarkerPropertiesPipe.transform(this.indexpropertydata,this.indexMarkerPropertiesFilter,this.indexNameFilter,
    this.assetClassCodeFilter,this.assetClassFilter,this.geographyCodeFilter,this.geographyFilter,
   this.styleFilter,this.styleCodeFilter,this.sizeCodeFilter);
   this.gridData = data;
}
onsizeCodeFilterKey(event:any){
  var indexMarkerPropertiesPipe = new IndexMarkerPropertiesPipe();
 var data = indexMarkerPropertiesPipe.transform(this.indexpropertydata,this.indexMarkerPropertiesFilter,this.indexNameFilter,
   this.assetClassCodeFilter,this.assetClassFilter,this.geographyCodeFilter,this.geographyFilter,
  this.styleFilter,this.styleCodeFilter,this.sizeCodeFilter);
  this.gridData = data;
}

onsecurityIdentifierFilterFilterKey(event:any){
    this.referenceTabLoader = true;
    this.page = 1;
    this.securitySearchFilter = {
      SecurityIdentifier: this.securityIdentifierFilter,
      SecurityName: this.securityNameFilter,
      SecurityId: this.securityIdFilter
    };
    this.page = 1;
    this.referenceService.getsecuritySearch(this.page,this.pageSize,this.securitySearchFilter,this.endDate).then(data=>{
      this.gridData = data["securitySearches"];
        this.totalEntries = data["totalResults"];
        this.setPage(this.page);
        this.referenceTabLoader = false;
      });

      this.referenceService.getSeuritySearchwithoutpaging(this.endDate).then(data=>{
      this.dataSource = data;
      });
}
onsecurityNameFilterFilterKey(event:any){
  this.referenceTabLoader = true;
  this.page = 1;
  this.securitySearchFilter = {
    SecurityIdentifier: this.securityIdentifierFilter,
    SecurityName: this.securityNameFilter,
    SecurityId: this.securityIdFilter
  };
  this.page = 1;
  this.referenceService.getsecuritySearch(this.page,this.pageSize,this.securitySearchFilter,this.endDate).then(data=>{
    this.gridData = data["securitySearches"];
      this.totalEntries = data["totalResults"];
      this.setPage(this.page);
      this.referenceTabLoader = false;
    });
}
onsecurityIdFilterFilterKey(event:any){
  this.referenceTabLoader = true;
  this.page = 1;
  this.securitySearchFilter = {
    SecurityIdentifier: this.securityIdentifierFilter,
    SecurityName: this.securityNameFilter,
    SecurityId: this.securityIdFilter
  };
  this.page = 1;
  console.log(this.securitySearchFilter)
  this.referenceService.getsecuritySearch(this.page,this.pageSize,this.securitySearchFilter,this.endDate).then(data=>{
    this.gridData = data["securitySearches"];
      this.totalEntries = data["totalResults"];
      this.setPage(this.page);
      this.referenceTabLoader = false;
    });
}
setPage(page: number) {    
  this.pager = this.pagerService.getPager(this.totalEntries, page);      
}
fetchPage(page: number){
  this.page = page;
  this.referenceTabLoader = true;
  
  if(this.isProductIndexMarker==true){
  this.productIndexMarkerFilter = {
    Prefix: this.filePrefixFilter,
    FileDescription: this.fileDescriptionFilter,
    FileType : this.fileTypeFilter, 
    FolderPath: this.folderPathFilter,
    ProductIndexMarker: this.indexMarkerFilter
  };
  this.referenceService.getProductIndexMarker(this.page,this.pageSize,this.productIndexMarkerFilter).then(data=>{
    this.gridData = data["productIndexes"];
    this.filesPaged = this.gridData;
    this.totalEntries = data["totalResults"];
    this.setPage(this.page);
    this.referenceTabLoader = false;
  });
}
if(this.isSecuritySearch==true){
  this.securitySearchFilter = {
    SecurityIdentifier: this.securityIdentifierFilter,
    SecurityName: this.securityNameFilter,
    SecurityId: this.securityIdFilter
  };

  this.referenceService.getsecuritySearch(this.page,this.pageSize,this.securitySearchFilter,this.endDate).then(data=>{
     
    this.gridData = data["securitySearches"];
    this.filesPaged = this.gridData;
      this.totalEntries = data["totalResults"];
      this.setPage(this.page);
      this.referenceTabLoader = false;
    });

    this.referenceService.getSeuritySearchwithoutpaging(this.endDate).then(data=>{
      this.dataSource = data;
    });
}
}
 }