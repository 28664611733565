import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { CompanyModel } from '../../models/entitlement.model';
import { EntitlementService } from '../../services/entitlement.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Nimble } from 'aws-sdk';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})

export class CompanyComponent implements OnInit, OnChanges {
  company: CompanyModel;
  commitStatus: any;
  companies: CompanyModel[];
  pattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  constructor(private entitlementService: EntitlementService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.initilize();
    var sub = this.route.params.subscribe(params => {
      this.company.companyId = params['companyId'] == undefined ? "" : params['companyId'];
      this.company.companyName = params['companyName'] == undefined ? "" : params['companyName'];
      this.company.isActive = params['isActive'] == undefined ? false : JSON.parse(params['isActive']);
      this.company.address = params['address'] == undefined ? "" : params['address'];
      this.company.emailAddress = params['emailAddress'] == undefined ? "" : params['emailAddress'];
      this.company.parentCompany = params['parentCompany'] == undefined ? "" : params['parentCompany'];
      this.company.phoneNumber = params['phoneNumber'] == undefined ? null : params['phoneNumber'];

    });
  }

  initilize() {
    this.company = {
      companyName: "", companyId: "", parentCompany: "", companyValue: "", emailAddress: "", address: "", isActive: false, phoneNumber: ""
    };
    this.commitStatus = "";
  }
  ngOnChanges(changes: SimpleChanges) { }

  onlyNumber(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  saveChanges() {
    
    this.entitlementService.getCompanies().then(result => {
      this.companies = result;
      if (this.company.companyName.trim() == "") {
        this.commitStatus = "Please enter Company Name";
      }
      else if (this.company.companyId == '' && this.companies.find(x => x.companyName == this.company.companyName) != null) {
        this.commitStatus = "Company name already exist"
      }
      else if (this.company.emailAddress != this.company.emailAddress.match(this.pattern)) {
  
        this.commitStatus = "Please enter valid Email";
      }
      else if (this.company.phoneNumber == null) {
        this.commitStatus = "Please enter Phone Number ";
      }
      else {
        this.entitlementService.saveCompany(this.company).then(result => {
          console.log(this.company);
          if (result == "1") {
            //  this.initilize();
            this.commitStatus = "Saved successfully";
            window.location.href = "/company-search"
          }
          else {
            this.commitStatus = "Not saved"
          }
        });
      }
    });
  }
}
