import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { PageAuthenticationService } from '../../services/page-authentication.service';
import{PageAuthModel,MenuItemModel, Model} from './../../models/pageAuthModel';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { isEmptyObject } from 'jquery';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-page-authentication',
  templateUrl: './role-page-mapping.component.html',
  styleUrls: ['./role-page-mapping.component.css']
})
export class RolePageMappingComponent implements OnInit {
 

  //  dataArray:PageAuthModel;
  // MenuItemModel
  addRow;
  PageAuthModel=new PageAuthModel();
  Model:any;
  pageAuth:PageAuthModel;
  addData: Model[] = [];
 dataArray=[];
  menuItems:MenuItemModel;
  getAuthValues: any;
  disabled: boolean =false;
  GetMenuItems;
  saveData;
  that: { [x: number]: any; isParent: any; ParentMenuItem: any; DisplayOrder: any; };
  commitStatus:any;
  constructor(private services:PageAuthenticationService, private fb:FormBuilder, private route: ActivatedRoute) {
   }
 
  ngOnInit() {
     this.getMenutItem();   

     this.addData =  [{
      MenuItem :"",
      isParent:"",
      ParentMenuItem:"",
      DisplayOrder:0,
      tid:0
     }]

     this.addRow= this.pageAuth={
      RoleName: "",
      RoleID: "",
      MenuItemModel : []         
     }

     var sub = this.route.params.subscribe(params => {
      if(isEmptyObject(params) )
      {  
        this.disabled = false
      } 
      else{
        this.disabled = true
      }

      this.pageAuth.RoleID = params['id'];
      this.pageAuth.RoleName = params['rolename']
      if(params['id'] != undefined) {
        this.getAttributeNames(this.pageAuth.RoleID);
    
      }
     
    });


  }
  getMenutItem(){
    this.services.menuItems(this.menuItems).then(data =>{
     this.GetMenuItems=data; 
     console.log(this.GetMenuItems); 
    })  
   }

   getAttributeNames(element){
    this.services.GetValues(element).then(data =>{
      var that = this;
      this.getAuthValues=data;
     console.log('authvalues', this.getAuthValues);
    
    this.getAuthValues.forEach(function(b){
      
      that.pageAuth.
         MenuItemModel.push({
          MenuItem : b.menuItem,
          isParent:b.isParent,
          ParentMenuItem: b.parentMenuItem,
          DisplayOrder:b.displayOrder,
          tid:b.id
         });        
 
     })
    
    })

  }

 onAddRow() {
   this.addRow = new Model()
  this.pageAuth.MenuItemModel.push(this.addRow);
}

onCancel(){
  
  window.location.href = "/role-page-mapping-search"
}
 
onRemoveRow(index){
  this.pageAuth.MenuItemModel.splice(index,1);
}



onSubmit(){
  this.commitStatus = undefined
if(this.pageAuth.RoleName == undefined || this.pageAuth.RoleName == '' )
{
  this.commitStatus = "Please enter the role name";
  return;
} else if (this.pageAuth.MenuItemModel != undefined) {
  var that = this;



  this.pageAuth.MenuItemModel.forEach(function(b){
    
  //  if(b.MenuItem == b.ParentMenuItem){
  //    that.commitStatus = "MenuItem and ParentMenuItem cannot be same";
  //  }

    if(b.isParent){
      if(b.MenuItem == null || b.DisplayOrder == null){
      that.commitStatus = "Please enter all the fields";
      }
      return;
    }
    else{
      if(b.MenuItem == null || b.ParentMenuItem == null || b.DisplayOrder == null){
        that.commitStatus = "Please enter all the fields";
      }
    }

  });


      if(that.pageAuth.MenuItemModel.length > 1) {
      var k = that.pageAuth.MenuItemModel.length -1 ;
  // for(var i =0; i<= k; i++){
  // for(var j=1; j<=k; j++){
  //        if(i != j){
  //          if(that.pageAuth.MenuItemModel[i].MenuItem == that.pageAuth.MenuItemModel[j].MenuItem){
  //            that.commitStatus = "Menu Item cannot be same";
  //            return;
  //          }
  //        } }
  // } 
}

}
else {
  this.commitStatus = undefined;
}

if(this.commitStatus == undefined)
{
    console.log('new code',this.pageAuth);  
  this.services.saveFields(this.pageAuth).then(results =>{
    console.log('result', results);
    if(results == "1")
    {
      this.commitStatus = "Saved Sucessfully"

      window.location.href = "/role-page-mapping-search"
    }
    else
    {
      this.commitStatus = "Error while saving"
    }
  } );
}
} 
}
