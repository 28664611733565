import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { IndexMarkerService } from '../../services/indexmarker.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { IndexEditComponent } from '../index-edit/index-edit.component';
import { iIndexMaster } from '../../models/index-master';
import { filter, switchMap } from 'rxjs/operators';
import { iResponseModel } from '../../models/response.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  retry$ = new BehaviorSubject(null);
  error$ = new BehaviorSubject<any>(null);
  dataSource: any;
  activePageDataChunk: any;
  filterForm: any;
  loader: Boolean = true;
  isLoading: boolean;
viewOnlyAccess : Boolean = true;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25];
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 5;
  pageLength: number;
  pageOffset: number;

  columnsToDisplay: ColumnNames[] = [
    ColumnNames.indexid,
    ColumnNames.indexmarker,
    ColumnNames.indexname,
    ColumnNames.indexreportingname,
    ColumnNames.source,
    ColumnNames.indexfamily,
    ColumnNames.controls
  ];
  columnsViewNameMap = new Map<ColumnNames, string>([
    [ColumnNames.indexid, 'Index Id'],
    [ColumnNames.indexmarker, 'Index Marker'],
    [ColumnNames.indexname, 'Index Name'],
    [ColumnNames.indexreportingname, 'Index Marker Reporting Name'],
    [ColumnNames.source, 'Source'],
    [ColumnNames.indexfamily, 'Index Family'],
  ]);
  skipColumnsAutoRender = new Set<ColumnNames>([
    ColumnNames.controls
  ]);
  baseDialogConfig: MatDialogConfig = {
    closeOnNavigation: true,
    height: "auto",
    minHeight: "calc(100vh-90px)"
  };

  constructor(private service: IndexMarkerService, private fb: FormBuilder, public dialog: MatDialog) {
    this.isLoading = true;
    service.getIndexes('', this.pageIndex, this.pageSize).then(res => {
      this.dataSource = res;

      if (this.dataSource.length % this.pageSize == 1 && (this.pageIndex == (Math.floor(this.dataSource.length / this.pageSize) - 1))) {
        this.pageIndex = this.pageIndex + 1;
      }
      let firstCut = this.pageIndex * this.pageSize;
      let secondCut = firstCut + this.pageSize;

      this.loader = true;
      this.activePageDataChunk = this.dataSource.slice(firstCut, secondCut);
      this.dataSource.paginator = this.paginator;
      this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].totalCount : 0;
      this.isLoading = false;
    })
  }

  ngOnInit(): void {
    if (sessionStorage.getItem("UserRoles") == environment.role 
    || sessionStorage.getItem("UserRoles").indexOf(environment.adminGroup) !== -1
    || sessionStorage.getItem("UserRoles").indexOf(environment.clientServiceOpsGroup) !== -1)
{
  this.viewOnlyAccess = false;
}
    this.filterForm = this.fb.group({
      indexId: [""],
      indexMarker: [""],
      indexName: [""],
      indexReportingName: [""],
      source: [""],
      indexFamily: [""]
    });

    this.filterForm.valueChanges.subscribe(value => {
      this.pageIndex = 0;
      this.pageSize = 5;
      this.service.getIndexes(value, this.pageIndex, this.pageSize).then(res => {
        this.dataSource = res;

        if (this.dataSource.length % this.pageSize == 1 && (this.pageIndex == (Math.floor(this.dataSource.length / this.pageSize) - 1))) {
          this.pageIndex = this.pageIndex + 1;
        }
        let firstCut = this.pageIndex * this.pageSize;
        let secondCut = firstCut + this.pageSize;

        this.loader = true;
        this.activePageDataChunk = this.dataSource.slice(firstCut, secondCut);
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].totalCount : 0;
        this.isLoading = false;
      })
    });
  }


  onPageChanged(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.GetIndexes();
  }

  GetIndexes() {
    this.isLoading = true;
    this.service.getIndexes(this.filterForm.value, this.pageIndex, this.pageSize).then((res) => {
      this.dataSource = res;
      this.UpdatePagination();
    });
  }

  UpdatePagination() {
    this.isLoading = false;
    this.pageOffset = this.pageIndex * this.pageSize;
    this.activePageDataChunk = this.dataSource;
    this.dataSource.paginator = this.paginator;
    this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].totalCount : 0;
  }

  getColumnsViewName(column: ColumnNames): string {
    return this.columnsViewNameMap.get(column) || '';
  }
  openMappingDetail(data: any): void {
    console.log("OpenDialog data: "+ data);
    const dialogRef: MatDialogRef<IndexEditComponent, iIndexMaster> = this.dialog.open(IndexEditComponent, {
      ...this.baseDialogConfig,
      data: data,
      height: "auto",
      minHeight: "calc(100vh-90px)"
    });

    dialogRef.afterClosed().pipe(
      filter(data => !!data),
      switchMap((data) => data ? this.service.updateIndex(data) : of(data)),
    ).subscribe((model: iResponseModel) => {
      alert(model.uiNotification.join('\n'));
      this.pageIndex = 0;
      this.GetIndexes();
    });
  }

  retry() {
    this.retry$.next(new Date());
  }

}

enum ColumnNames {
  indexid = 'indexId',
  indexmarker = 'indexMarker',
  indexname = 'indexName',
  indexreportingname = 'indexReportingName',
  source = 'source',
  indexfamily = 'indexFamily',
  controls = 'controls',
}