import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Portfolio, PortfolioForm} from '../../models/portfolio';

@Component({
  selector: 'app-edit-portfolio-popup',
  templateUrl: './edit-portfolio-popup.component.html',
  styleUrls: ['./edit-portfolio-popup.component.scss']
})
export class EditPortfolioPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EditPortfolioPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: Portfolio) {}

  ngOnInit(): void {}

  onSubmit(data: PortfolioForm): void {
    this.dialogRef.close(data);
  }
}
