import { Component, OnInit } from '@angular/core';
import { ReviewsService } from '../../services/reviews.service';
import { ReviewProducts } from '../../models/review-products.model';

@Component({
  selector: 'app-review-products',
  templateUrl: './review-products.component.html',
  styleUrls: ['./review-products.component.css']
})
export class ReviewProductsComponent implements OnInit {

  didFail: any;
  reviewProducts: ReviewProducts[] = [];  
  reviewProductsdidFail = false;  
  today = new Date(Date.now());
  frequencyDate = '';
  descriptionFilter: any;
  productCodeFilter: any;
  reviewProductDateFilter: any;
  calendarDateFilter: any;
  webMenuFilter: any;

  constructor(private reviewsService: ReviewsService) { }

  ngOnInit() {   
    this.today.setDate(this.today.getDate());
    this.frequencyDate= this.today.toISOString().split('T')[0];
    var t= this;
    this.reviewsService.getReviewProducts().then(function (result) {            
      for (let item of result) {            
        t.reviewProducts.push(item);
      }
    });       
  }
}
