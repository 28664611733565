import { Component, Inject, Input, OnInit } from '@angular/core';
import { iIndexMaster } from '../../models/index-master';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-index-edit',
  templateUrl: './index-edit.component.html',
  styleUrls: ['./index-edit.component.scss']
})
export class IndexEditComponent implements OnInit {
  indexEditForm: FormGroup = this.fb.group({
    indexId: [0],
    indexMarker: ['', Validators.required],
    indexName: [''],
    indexReportingName: [''],
    source: [''],
    indexFamily: [''],
  } as Record<keyof iIndexMaster, any>);

  constructor(public dialogRef: MatDialogRef<IndexEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data : iIndexMaster, private fb: FormBuilder) {
      this.indexEditForm.controls['indexId'].patchValue(data.indexId);
      this.indexEditForm.controls['indexMarker'].patchValue(data.indexMarker);
      this.indexEditForm.controls['indexName'].patchValue(data.indexName);
      this.indexEditForm.controls['indexReportingName'].patchValue(data.indexReportingName);
      this.indexEditForm.controls['source'].patchValue(data.source);
      this.indexEditForm.controls['indexFamily'].patchValue(data.indexFamily);
     }

  ngOnInit(): void {
  }

  onSubmit(): void{
    if (this.indexEditForm.invalid) {
      alert("Invalid details!!");
      return;
    }
    this.dialogRef.close(this.indexEditForm.getRawValue());
  }

}
