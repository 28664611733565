import { Component, EventEmitter, Input, OnInit, Inject, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { ConnectContactLens } from 'aws-sdk';
import { ExportReportData, Report, ReportData } from '../../models/report';
import { HistoryDataService } from '../../services/historydata.service';
import { DownloadService } from '../../services/download.service';
import { S3ManagerService } from '../../../services/s3-manager.service';
import { saveAs } from 'file-saver/FileSaver';
import { ArrayDataSource } from '@angular/cdk/collections';
import * as CryptoJS from "crypto-js";
import * as AWS from 'aws-sdk';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';
import { bool } from 'aws-sdk/clients/redshiftdata';
import { environment } from '../../../../environments/environment';


enum ColumnNames {
  asofDate = 'asofDate',
  indexMarker = 'indexMarker',
  siMetricCalculationStatus = 'siMetricCalculationStatus',
  historyReportGenerationStatus = 'historyReportGenerationStatus',
  createDate = 'createDate',
  modifiedDate ='modifiedDate',
  controls = 'controls',
}

@Component({
  selector: 'history-data-grid',
  templateUrl: './history-data-grid.component.html',
  styleUrls: ['./history-data-grid.component.scss']
})
export class HistoryDataGridComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() edit = new EventEmitter<Report>();
  dataSource: any;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25];
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 15;
  unsub: any;
  data: MatTableDataSource<any>;
  oldPageLimit: number;
  response: any;
  activePageDataChunk: any;
  pageLength: number;
  filterForm: any;
  isupdate :boolean = false ;
  exportresultArray: ExportReportData[] = [];
  selectedreports: number[] = [];
  passphrase: string = "EncryptionatRest";
  displayColumnNameMap = new Map<ColumnNames, string>([
    [ColumnNames.asofDate, 'AsOfDate'],
    [ColumnNames.indexMarker, 'Index Marker'],
    [ColumnNames.siMetricCalculationStatus, 'SI Matric Calculated'],
    [ColumnNames.historyReportGenerationStatus, 'Report Generated'],
    [ColumnNames.createDate, 'Create Date'],
    [ColumnNames.modifiedDate, 'Modified Date']
  ]);
  displayedColumns: ColumnNames[] = [
    ColumnNames.asofDate,
    ColumnNames.indexMarker,
    ColumnNames.siMetricCalculationStatus,
    ColumnNames.historyReportGenerationStatus,
    ColumnNames.createDate,
    ColumnNames.modifiedDate,
    ColumnNames.controls
  ];
  skipColumnsAutoRender = new Set<ColumnNames>([
    ColumnNames.controls
  ]);
  constructor(private router: Router, private historyDataService: HistoryDataService, private downloadService: DownloadService, private fb: FormBuilder, private s3ManagerService:S3ManagerService) {
    this.unsub = this.historyDataService.clientPublish().subscribe((m: any) => {
      this.historyDataService.fetchReports$().subscribe(res => {
        this.response = res;
        this.dataSource = this.response.data;
        if (this.dataSource.length % this.pageSize == 1 && (this.pageIndex == (Math.floor(this.dataSource.length / this.pageSize) - 1))) {
          this.pageIndex = this.pageIndex + 1;
        }
        let firstCut = this.pageIndex * this.pageSize;
        let secondCut = firstCut + this.pageSize;   
        this.activePageDataChunk = this.dataSource.slice(firstCut, secondCut);
        for(let item of this.activePageDataChunk){
          item.createDate = (item.createDate).substring(0, 10),
            item.modifiedDate = (item.modifiedDate).substring(0, 10),
            item.modifiedDate = (item.modifiedDate).substring(0, 10),
            item.asofDate = (item.asofDate).substring(0, 10),
            item.historyReportGenerationStatus = item.historyReportGenerationStatus == 0 ? false : true,
            item.siMetricCalculationStatus = item.siMetricCalculationStatus == 0 ? false : true
        }
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length;
      });
    });
  }

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      asofDate: [""],
      indexMarker: [""],
      siMetricCalculationStatus: [""],
      historyReportGenerationStatus: [""],
      createDate: [""],
      modifiedDate:[""]
    });
    this.filterForm.valueChanges.subscribe(value => {
      console.log(this.dataSource);
      var filter1 = this.dataSource.filter(cp => cp.indexMarker.toLowerCase().indexOf(value.indexMarker.toLowerCase()) != -1 && cp.siMetricCalculationStatus.toLowerCase().indexOf(value.siMetricCalculationStatus.toLowerCase()) != -1
        && cp.historyReportGenerationStatus.toLowerCase().indexOf(value.historyReportGenerationStatus.toLowerCase()) != -1 
        //&& cp.asOfDate.toLowerCase().indexOf(value.asOfDate.toLowerCase()) != -1
        //&& cp.createDate.toLowerCase().indexOf(value.createDate.toLowerCase()) != -1
        //&& cp.modifiedDate.toLowerCase().indexOf(value.modifiedDate.toLowerCase()) != -1
      );
      this.activePageDataChunk = filter1.slice(0, this.pageSize);
      filter1.paginator = this.paginator;
      this.pageLength = filter1.length;
    });
    this.historyDataService.fetchReports$().subscribe((res) => {
      this.response = res;
      this.dataSource = this.response.data;

      if (this.dataSource.length > this.pageSize) {
        this.activePageDataChunk = this.dataSource.slice(0, this.pageSize);
      }
      else {
        this.activePageDataChunk = this.dataSource;
      }
      for(let item of this.activePageDataChunk){
        item.createDate = (item.createDate).substring(0, 10),
          item.modifiedDate = (item.modifiedDate).substring(0, 10),
          item.asofDate = (item.asofDate).substring(0, 10),
          item.historyReportGenerationStatus = item.historyReportGenerationStatus == 0 ? false : true,
          item.siMetricCalculationStatus = item.siMetricCalculationStatus == 0 ? false : true
          
      }
      this.dataSource.paginator = this.paginator;
      this.pageLength = this.dataSource.length;
    });
  }

  getColumnsViewName(column: ColumnNames): string {
    return this.displayColumnNameMap.get(column) || '';
  }
  

  trim(text: any): string | any {
    if (typeof text === 'string') {
      return text.length < 28 ? text : text.slice(0, 25).trim() + '...';
    }
    return text;
  }
 
  onPageChanged(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    let firstCut = e.pageIndex * e.pageSize;
    let secondCut = firstCut + e.pageSize;
    this.activePageDataChunk = this.dataSource.slice(firstCut, secondCut);
    for(let item of this.activePageDataChunk){
      item.createDate = (item.createDate).substring(0, 10),
        item.modifiedDate = (item.modifiedDate).substring(0, 10),
        item.asofDate = (item.asofDate).substring(0, 10),
        item.historyReportGenerationStatus = item.historyReportGenerationStatus == 0 ? false : true,
        item.siMetricCalculationStatus = item.siMetricCalculationStatus == 0 ? false : true
        
    }
  }
  downloadReport(report: any, fileType: any) {
    console.log(report);
    if (report.historyReportGenerationStatus && report.siMetricCalculationStatus) {
      alert("downloading is in progress for xlsx format");
      debugger;
      var date = report.modifiedDate.replaceAll('-', '');
      var fileName = "SIMetricsHistoryReport_" + report.indexMarker + "_" + date + ".xlsx";
      // call download file method
      this.downloadFile(fileName);
    }
    else {
      alert("Report is not ready to show !");
    }
             
        }
        

  downloadFile(fileName): void {
    debugger;
    var bucketName = environment.deltaBucket;
    var filePath = fileName;

    console.log("BucketName url: " + bucketName);
    console.log("FilePath url: " + fileName);
    console.log("Session Message : " + sessionStorage.getItem('RestMessage'));
    this.s3ManagerService.getRawData(bucketName, filePath,
      JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('RestMessage'), this.passphrase).toString(CryptoJS.enc.Utf8))).then(result => {
        saveAs(new Blob([result.Body]), fileName);  //  {type: result.ContentType}
        console.log(sessionStorage.getItem('RestMessage'));
      }, onerror => {
        console.log(onerror);
      });
  }
  ngOnDestroy() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
  }
}
