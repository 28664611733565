import {Component, forwardRef, OnDestroy, OnInit,EventEmitter,Output, Input} from '@angular/core';
import {BenchmarksService} from '../../services/benchmarks.service';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import { ReportsService } from '../../services/reports.service';
import { Console } from 'console';

@Component({
  selector: 'app-select-benchmark-portfolio',
  templateUrl: './select-benchmark-portfolio.component.html',
  styleUrls: ['./select-benchmark-portfolio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectBenchmarkPortfolioComponent),
      multi: true
    }
  ]
})
export class SelectBenchmarkPortfolioComponent implements OnInit, ControlValueAccessor, OnDestroy {
  public filteredindexMarkers;
  fc = new FormControl('');
 _indexMarkers$;
  @Output() Seldata = new EventEmitter<any>();
  @Input()
   set indexMarkers(data:any){ 
    if(data)
    {
      this._indexMarkers$=data;
      this.filteredindexMarkers = data;
    }
   }
   get indexMarkers(){
     
return this._indexMarkers$;
   }
  
  sub = this.fc.valueChanges.subscribe((value) => {
   
    this.onChange(value);
    this.onPortfolioChange(value);
  });
  onChange = (val: any) => {};
  onTouched = (val: any) => {};
  constructor(private benchmarksService: BenchmarksService,) {
   
  }
  
  ngOnInit(): void {   
    
  }
  

  writeValue(obj: any): void {
    this.fc.setValue(obj, {emitEvent: false});
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    // tslint:disable-next-line:no-unused-expression
    this.sub && this.sub.unsubscribe();
  }

  onPortfolioChange(selectedValue)
  {
         
         this.Seldata.emit(selectedValue);
    
  }
}
