import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { OpenTopBottomHoldingsChartComponent } from '../open-holdings-top-bottom-holdings-chart/open-holdings-top-bottom-holdings-chart.component';
import { OpenCountryWeightsChartComponent } from '../open-holdings-country-weights-chart/open-holdings-country-weights-chart.component';
import { OpenCurrencyWeightsChartComponent } from '../open-holdings-currency-weights-chart/open-holdings-currency-weights-chart.component';
import { OpenRankedHoldingsGridComponent } from '../open-holdings-ranked-holdings-grid/open-holdings-ranked-holdings-grid.component';
import { OpenHoldingPageModel } from '../../models/open-holding-page-model';
import { OpenHoldingsPositionGridComponent } from '../open-holdings-position-grid/open-holdings-position-grid.component';
import * as moment from 'moment';
import { DateService } from '../../services/date.service';
@Component({
  selector: 'open-holdings-page',
  templateUrl: './open-holdings-page.component.html'
})

export class OpenHoldingsPageComponent implements OnInit,AfterViewInit {

  openHoldingPageModel: OpenHoldingPageModel;

  @ViewChild(OpenTopBottomHoldingsChartComponent,{static: false}) topBottomHoldingsChartComponent;
  @ViewChild(OpenCountryWeightsChartComponent,{static: false}) countryWeightsChartComponent;
  @ViewChild(OpenCurrencyWeightsChartComponent,{static: false}) currencyWeightsChartComponent;
  @ViewChild(OpenRankedHoldingsGridComponent,{static: false}) rankedHoldingsGridComponent;
  @ViewChild(OpenHoldingsPositionGridComponent,{static: false}) holdingsPositionGridComponent;

  show: boolean = true;
  iconClass: any = 'icon-minus noTextDecoration';
  cssClass: any = 'showRow';
  defaultDateRange:any;
  constructor(private dateService: DateService) { }
  ngOnInit() {
    this.defaultDateRange = this.dateService.getCalendarDate();
    this.dateService.calendarDatePublish().subscribe(dateRange => {
       this.defaultDateRange = dateRange;
       this.openHoldingPageModel.asofdate = this.dateService.changeDateFormat(this.defaultDateRange.end);
       this.topBottomHoldingsChartComponent.update(this.openHoldingPageModel);
       this.countryWeightsChartComponent.update(this.openHoldingPageModel);
       this.currencyWeightsChartComponent.update(this.openHoldingPageModel);
       this.rankedHoldingsGridComponent.update(this.openHoldingPageModel);
       this.holdingsPositionGridComponent.update(this.openHoldingPageModel);
    });
  }

  ngAfterViewInit(){
    this.openHoldingPageModel.asofdate = this.dateService.changeDateFormat(this.defaultDateRange.end);
    this.openHoldingPageModel.topBottomFlag = true;
    this.topBottomHoldingsChartComponent.update(this.openHoldingPageModel);
    this.countryWeightsChartComponent.update(this.openHoldingPageModel);
    this.currencyWeightsChartComponent.update(this.openHoldingPageModel);
    this.rankedHoldingsGridComponent.update(this.openHoldingPageModel);
    this.holdingsPositionGridComponent.update(this.openHoldingPageModel);
  }
  onNotify(openHoldingPageModel: OpenHoldingPageModel): void {
    this.openHoldingPageModel = openHoldingPageModel;
    if(undefined != this.topBottomHoldingsChartComponent && undefined != this.countryWeightsChartComponent
    && undefined != this.currencyWeightsChartComponent && undefined != this.rankedHoldingsGridComponent
    && undefined != this.holdingsPositionGridComponent){
      this.topBottomHoldingsChartComponent.update(this.openHoldingPageModel);
      this.countryWeightsChartComponent.update(this.openHoldingPageModel);
      this.currencyWeightsChartComponent.update(this.openHoldingPageModel);
      this.rankedHoldingsGridComponent.update(this.openHoldingPageModel);
      this.holdingsPositionGridComponent.update(this.openHoldingPageModel);
    }
    

  }

  ExportXls(event): void {
    this.holdingsPositionGridComponent.ExportXls();
  }
  SelectHoldingsColums(event): void {
    this.holdingsPositionGridComponent.refreshGrid(event);

  }

  toggle() {
    this.show = !this.show;
    if (this.show) {
      this.iconClass = "icon-minus noTextDecoration ";
      this.cssClass = "showRow";
    }
    else {
      this.iconClass = "icon-plus noTextDecoration ";
      this.cssClass = "hideRow";
    }
  }
}