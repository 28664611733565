import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-error-handler',
  templateUrl: './error-handler.component.html',
  styleUrls: ['./error-handler.component.css']
})
export class ErrorHandlerComponent implements OnInit {
  @Input() error: any;
  @Output() retry = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }

  get name() {
    return (this.error && this.error.name) || 'Empty name';
  }

  get message() {
    return (this.error && this.error.message) || 'message is empty';
  }

}
