import { Component, OnInit,Input  } from '@angular/core';
import {DateService} from '../../services/date.service';
import {DataqualityserviceService} from '../../services/dataqualityservice.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-nicb-sector-data-quality',
    templateUrl: './nicb-sector-data-quality.component.html',
    styleUrls: ['./nicb-sector-data-quality.component.css']
  })
  export class NicbSectorDataQualityComponent implements OnInit {
        
    asOfDate : any;
    client : string ;
    nicbSectordata:any;
    nicbIndexDetailsModel : any;
    nicbDataGridLoader:boolean =true;
    nicbRowExpandLoader:boolean[] =[]; 
    nicbPanelOpenState:Boolean = false;

  constructor(
     private dataqualityservice:DataqualityserviceService,private dateService:DateService,
    private exportService:ExportToExcelService ,private route: ActivatedRoute) { }
    ngOnInit() {
      var that = this;
      this.asOfDate = this.dateService.getCalendarDate().end;
      this.dateService.calendarDatePublish().subscribe(result=>{
        that.asOfDate = moment(result.end).format('MM/DD/YYYY');
        that.nicbGridSetup();
       });
       var sub = this.route.queryParams.subscribe(params => {  
        this.client =params['Client'] ;
        if(this.client== undefined)
        {
          this.client= "ALL";
        }
        this.nicbGridSetup();
      });

    }

    nicbGridSetup(){

      var that = this;
      that.nicbRowExpandLoader = [];
            this.nicbDataGridLoader   = true;
            this.dataqualityservice.GetNicbSectorClassifictionDataQualities(this.asOfDate ,this.client).then(data=>{
              that.nicbSectordata=data;
            var index = 0;
            data.forEach(element => {
              that.nicbRowExpandLoader[index] = false;
              index++;
            });
            this.nicbDataGridLoader=false;
            },onerror =>{
            this.nicbDataGridLoader =false; 
            });
  }

  getNicbSearchDetails(element,index){
    if(this.nicbPanelOpenState == true){
      this.nicbRowExpandLoader[index] = true;
      this.dataqualityservice.GetNicbSectorClassificationDataQualityDetails(this.asOfDate,element.securityId ,this.client).then(data=>{
      this.nicbIndexDetailsModel = data ;
      this.nicbRowExpandLoader[index] = false;
    },onerror =>{
      this.nicbRowExpandLoader[index] =false; 
  });
  }
  }
  
  ExportXls(element):void{
    this.dataqualityservice.GetNicbSectorClassificationDataQualityDetailsExportData(this.asOfDate,element.securityId ,this.client).then(res => {
            this.exportService.exportData(res,"NICB Data");
            }).catch(err=> { 
            }); 
  }
  


}  