import { Component, OnInit, ViewChild } from '@angular/core';
import { PageAuthenticationService } from '../../services/page-authentication.service';

import { MatSelectionList } from '@angular/material/list';
import { AuthorizationService } from '../../services/authorization.service';
import { UserGroupModel } from '../../models/authorization';
import { Group, UserRoleModel } from '../../models/pageAuthModel';

@Component({
  selector: 'app-user-group',
  templateUrl: './user-role-mapping.component.html',
  styleUrls: ['./user-role-mapping.component.css']
})
export class UserRoleMappingComponent implements OnInit {
  getRoleItemsValue:any;
 
   commitStatus:any;
   userModel: UserRoleModel;
  group: Group;
  selectedOptions: string[] = [];
  selectedOptionforRight: string[] = [];
 disableMe1 : boolean = true;
 disableMe2: boolean = true;
 disableMe3: boolean = true;
 disableMe4: boolean = true;
 selectedgroupData: UserGroupModel[] = [];
  usergroupData: UserGroupModel[];
  allocatedgroupData: UserGroupModel[];

  @ViewChild('allSelected', {static: true}) 
  private allSelected: MatSelectionList; 

  
  @ViewChild('allSelectedRight', {static: true}) 
  private allSelectedRight: MatSelectionList;
 

  constructor(private services:PageAuthenticationService, private service:AuthorizationService) { 
  }

  ngOnInit()  {
    
    this.GetRoles();
   // this.usergroupData =[{id:"",userGroupName:"",userType:""}];
  //  this.selectedgroupData = [{id:"",userGroupName:"",userType:""}];
    this.userModel={
      RoleID: "",
      UserGroup: []
    }
   this.GetUserGroup();
 

  }

selectLeft(checkAll){
  if(checkAll){
    this.disableMe1 = false;
  }
  else{
    this.disableMe1 = true;
  }
}

selectRight(checkAll){
  if(checkAll){
    this.disableMe2 = false;
  }
  else{
    this.disableMe2 = true;
  }
}


  selectAll(checkAll) {
    if (checkAll) {
   //   this.usergroupData.push({checkAll})
        this.allSelected.selectAll();
        this.disableMe3 = false;
    }
    else{
      this.allSelected.deselectAll();
      this.disableMe3 = true;
    }
  }

  selectAllRight(checkAll){
    if(checkAll){
      this.allSelectedRight.selectAll();
      this.disableMe4 = false;
    }
    else{
      this.allSelectedRight.deselectAll();
      this.disableMe4 = true;
    }
  }

saveLeft(element){
  var that = this;

  element.forEach(function(b){  
    const indx =  that.usergroupData.findIndex(x=>x.userGroupName === b.userGroupName); 
    
     that.usergroupData.splice(indx,1); 

     that.selectedgroupData.push({
      userGroupName: b.userGroupName,
      id: b.id,
      userType: b.userType
     })
  })

} 

saveChanges(){
  var that = this;
  
  that.selectedgroupData.forEach(function(b){
    that.userModel.UserGroup.push({
      UserGroupID: b.id
    })
  })

  console.log('service model', that.userModel)
  that.services.saveUserRoleMapping(this.userModel).then(results =>{
    if(results == "1")
    {
      this.commitStatus = "Saved Sucessfully"

      window.location.href = "/role-page-mapping-search"
    }
    else
    {
      this.commitStatus = "Error while saving"
    }
  })

}

saveRight(element){
  var that = this;

  element.forEach(function(b){
    const indx =  that.selectedgroupData.findIndex(x=>x.userGroupName === b.userGroupName); 

    that.usergroupData.push({
      userGroupName: b.userGroupName,
      id: b.id,
      userType: b.userType
    });

    
   that.selectedgroupData.splice(indx,1); 

  })
}

onNgModelChange(event){
  console.log('on ng model change', event);
}


  GetRoles(){
    this.services.roleNames().then(data =>{
      this.getRoleItemsValue=data;
     
          })
  }

GetUserGroup(){
  this.service.getUserGroupInfo().then(data =>
    {
     this.usergroupData=data; 
    }) 
}

GetSelectedGroup(){
  this.GetUserGroup();
  this.services.getSelectedInfo(this.userModel.RoleID).then(data =>
    {
      this.selectedgroupData=data;
   
      if(this.selectedgroupData.length)
      { 
    this.selectedgroupData.forEach((e1)=>
      this.usergroupData.forEach((e2) => {
             if(e1.id == e2.id){
    
              const indx =  this.usergroupData.findIndex(x=>x.id === e2.id); 
              this.usergroupData.splice(indx,1); 
             }
        }));
      }
    })

}



}