import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ExpandedElement } from '../../services/expendable-data.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Benchmark, BenchmarkParams, ColumnNames, BenchmarkDetailsColumns, detailsColumnsNameMap } from '../../models/benchmark';
import { BenchmarksService } from '../../services/benchmarks.service';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { DownloadService } from '../../services/download.service';
import { FormControl,FormBuilder } from '@angular/forms';


type UpdateElementEvent = BenchmarkParams;

// enum ColumnNames {
//   chevron = 'chevron',
//   name = 'name',
//   description = 'description',
//   marketValue = 'marketValue',
//   benchMark = 'benchMark',
//   assetClass = 'assetClass',
//   currency = 'currency',
//   asOfDate = 'asOfDate',
//   controls = 'controls'
// }

@Component({
  selector: 'app-benchmarks-grid',
  templateUrl: './benchmarks-grid.component.html',
  styleUrls: ['./benchmarks-grid.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BenchmarksGridComponent implements OnInit, AfterViewInit {
  private _dataSource = null;
  // itemsPerPage = 50;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25];
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 5;
  activePageDataChunk: any;
  pageLength: number;
  matDataSource: MatTableDataSource<Benchmark> = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @Input() set dataSource(data: Benchmark[] | null) {
  //   this._dataSource = data;
  //   if (data) {
  //     this.matDataSource.data = data;
  //   }
  // }

  // get dataSource(): Benchmark[] {
  //   return this._dataSource;
  // }
  // @Input() expandedElementData: ExpandedElement<Benchmark[]> | null = null;
  // @Output() export = new EventEmitter<Benchmark>();
  // @Output() updateExpandableElement = new EventEmitter<UpdateElementEvent>();
  expandedElement = null;
  BenchMarkDetails: any;
  @Input() date;
  @Input() marker: any[];
  columnsViewNameMap = new Map<ColumnNames, string>([
    [ColumnNames.IndexMarker, 'Index Marker'],
    [ColumnNames.IndexName, 'Index Description'],
    [ColumnNames.AssetClass, 'Asset Class'],
  ]);
  columnsToDisplay: ColumnNames[] = [
    ColumnNames.chevron,
    ColumnNames.IndexMarker,
    ColumnNames.IndexName,
    ColumnNames.AssetClass,
    ColumnNames.controls
  ];
  
  skipColumnsAutoRender = new Set<ColumnNames>([
    ColumnNames.chevron,
    ColumnNames.controls
  ]);

  expandedColumns: BenchmarkDetailsColumns[] = [
    ...Object.values(BenchmarkDetailsColumns),
  ];
  error$ = new BehaviorSubject<Error | null>(null);
  reload$ = new BehaviorSubject(null);
  
  expandedColumnNamesMap: Record<BenchmarkDetailsColumns, string> = detailsColumnsNameMap;
  unsub: any;
  filterForm: any;
  formData: any;

  constructor(private benchmarksService: BenchmarksService, private downloadService: DownloadService,private fb: FormBuilder) {
  }

  ngOnInit(): void {
        this.filterForm = this.fb.group({
          indexMarker: [""],
          indexName: [""],
          assetClass : [""]
        });
         this.filterForm.valueChanges.subscribe(value => {
         this.formData= value;
         //this.activePageDataChunk = null ;
        if (this.unsub) {
          this.unsub.unsubscribe();
        }
        this.unsub=this.benchmarksService.fetchBenchmarkData$(this.date, this.marker,0, this.pageSize, this.formData).subscribe(data => {
          this.pageIndex = 0;
          this.activePageDataChunk = data.benchmarkData;
          this.pageLength = data.totalEntries; 
        });
      });
  }

  ngAfterViewInit() {
    this.matDataSource.paginator = this.paginator;
  }
  ngOnChanges(changes: SimpleChanges) {
     this.activePageDataChunk = null ;
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
    if (this.marker != undefined) {
      this.unsub =this.benchmarksService.fetchBenchmarkData$(this.date, this.marker,0, this.pageSize, this.formData).subscribe(data => {
        this.pageIndex =0;
        this.activePageDataChunk = data.benchmarkData;
        this.pageLength = data.totalEntries;
      });
    }
  }

  updateExpandedRow(element: Benchmark): void {
    this.expandedElement = this.expandedElement === element ? null : element;
    this.BenchMarkDetails = null;
    if(this.expandedElement != null){
    this.benchmarksService.fetchBenchmarkdetails$(this.expandedElement.asOfDate, this.expandedElement.indexMarker).subscribe(data => {
      this.BenchMarkDetails = data
    });}
  }

 
  trim(text: any): string | any {
    if (typeof text === 'string') {
      return text.length < 28 ? text : text.slice(0, 25).trim() + '...';
    }
    return text;
  }
  ngOnDestroy() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
  }

  getColumnsViewName(column: ColumnNames): string {
    return this.columnsViewNameMap.get(column) || '';
  }

  async onExport(benchmark: Benchmark) {
    const bms = await this.benchmarksService.fetchBenchmarkdetails$(
      benchmark.asOfDate,
      benchmark.indexMarker
    ).toPromise();
    const data = this.benchmarksService.benchmarksToDetails(bms);
    this.downloadService.download(data, benchmark.indexMarker);
  }
  onPageChanged(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.activePageDataChunk = null ;
    this.benchmarksService.onBenchmarkPageChange$(this.date, this.marker, e.pageIndex, e.pageSize, this.formData).subscribe(data => {
      this.activePageDataChunk = data;
    });
  }
}
