import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResponseModel } from '../models/landing-page-model';
import { NameDetails} from './../models/gerographical-charts';
import { SedolAlertModel } from '../models/sedol-alert-model';
import { ChartsDetails } from '../models/gerographical-charts';
import { AttributeModel } from '../models/attribute-model';


@Injectable({
  providedIn: 'root' 
})
export class SedolAlertService {
  constructor( private httpClient: HttpClient) {}
  dropDownValue():Promise<NameDetails[]>{
    return this.httpClient.get(environment.dataServiceRoot +'ui/logs/GetApiNames')
     .toPromise() 
     .then(response =>{ 
       var result = response as ResponseModel;
         return result.data as NameDetails[];
         })
     .catch(this.handleErr);
  }
  SedolAlert(sedolalertmodel): Promise<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'ui/alert/SaveSedolAlert',
      JSON.stringify(sedolalertmodel),
      {headers: headers}).toPromise()
       .then(response => {
        var result = response as ResponseModel;
         return result.data as Array<any>;
       }).catch(this.handleErr);
  }

  sedolAlertDetails():Promise<SedolAlertModel[]>{
    // debugger;
    return this.httpClient.get(environment.dataServiceRoot +'ui/alert/SedolAlertDetails')
     .toPromise()
     .then(response =>{ 
     
       var result = response as ResponseModel;
         return result.data as SedolAlertModel[];
         })
     .catch(this.handleErr);
  }

   DeleteSedolAlert(id):Promise<any>
   {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post(environment.dataServiceRoot + 'ui/alert/DeleteSedolAlert',
      JSON.stringify(id),
      {headers: headers}).toPromise()
       .then(response => {
        var result = response as ResponseModel;
         return result.data as Array<any>;
       }).catch(this.handleErr);


   }

  GetAttributes(method): Promise<AttributeModel[]> 
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'ui/alert/GetAttributes',
      JSON.stringify(method),
      {headers: headers}).toPromise()
       .then(response => {
        var result = response as ResponseModel;
         return result.data as AttributeModel[];
       }).catch(this.handleErr);
  }


  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  }

