import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import {Observable} from 'rxjs';
import { environment } from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs/internal/observable/throwError';
import {ErrorService} from './error.service';

interface Response<T> {
  data: T;
}

@Injectable({
  providedIn: 'root'
})
export class FetchService {

  constructor(
    private http: HttpClient,
    
    private errorService: ErrorService,
  ) { }

  post<T>(url: string, body: any): Observable<Response<T>> {
    return this.http.post<Response<T>>(
      environment.dataServiceRoot + url,
      JSON.stringify(body),
    ).pipe(
      catchError((error) => {
        this.errorService.showError(error);
        return throwError(error);
      })
    );
  }
}
