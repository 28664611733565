import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResponseModel } from '../models/landing-page-model';
import {LicenceBandModel} from '../models/LicenceBand-model';

@Injectable({
  providedIn: 'root'
})
export class LicenceBandsService {

  constructor( private httpClient: HttpClient) { }

  LicenceBand(LicenceBandModel): Promise<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'ui/LicenceSave/LicenceBand',
      JSON.stringify({id: LicenceBandModel.id,
      description: LicenceBandModel.description,
    sedolRange: LicenceBandModel.sedolRange,
  licenceBand: LicenceBandModel.licenceBand}),
      {headers: headers}).toPromise()
       .then(response => {
        var result = response as ResponseModel;
         return result.data as Array<any>;
       }).catch(this.handleErr);
  }

  getLicenceInfo():Promise<LicenceBandModel[]>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/LicenceSave/LicenseDetails',null, {headers: headers}).toPromise().

    then(response => {
       var result = response as ResponseModel;
       return result.data as LicenceBandModel;
      })
    .catch(this.handleErr)
  }


  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}

