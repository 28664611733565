import { Component, OnInit } from '@angular/core';
import { PublishIndexMarkerService } from '../../services/publish-index-marker.service';
import { ExportToExcelService } from '../../services/exportToExcel.service';
import { DataqualityserviceService } from '../../services/dataqualityservice.service';
import { BiService } from '../../services/BiService.service';
import { DateService } from '../../services/date.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import { CurrencyService } from '../../services/currency.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-openprice-data-qualtity',
  templateUrl: './openprice-data-qualtity.component.html',
  styleUrls: ['./openprice-data-qualtity.component.css']
})
export class OpenpriceDataQualtityComponent implements OnInit {

  asOfDate : any;
  indexDetailsModel : any;
  indexMarkers: any[]= [];    
  currencyCode:any;
  pricedata:any;
  DataGridLoader:boolean =true;
  RowExpandLoader:boolean[] =[]; 
  panelOpenState = false;
  client : string ;
  constructor(private publishIndexMarkerService:PublishIndexMarkerService,private indexMarkerService:IndexMarkerService,
    private exportService:ExportToExcelService, private biService:BiService, private currencyService:CurrencyService,
     private route: ActivatedRoute,private dataqualityservice:DataqualityserviceService,private dateService:DateService ) { }

  ngOnInit() {
          this.indexMarkers =  this.indexMarkerService.getIndexMarkers();
          this.currencyCode = this.currencyService.getCurrencyCode();
          this.asOfDate = this.dateService.getCalendarDate().end;
          
          var that = this;
          this.dateService.calendarDatePublish().subscribe(result=>{   
            that.asOfDate = moment(result.end).format('MM/DD/YYYY');
            that.gridSetup();
        });
        var sub = this.route.queryParams.subscribe(params => {  
          this.client =params['Client'] ;
          if(this.client== undefined)
          {
            this.client= "ALL";
          }
          this.gridSetup();
        });
                
  }
  getOpenPriceSearchDetails(element,index){
    if(this.panelOpenState == true){
      this.RowExpandLoader[index] = true;
      this.dataqualityservice.getOpenPriceDifferenceDetails(element.securityId ,this.asOfDate ,this.client).then(data=>{
      this.indexDetailsModel = data["data"] ;
      this.RowExpandLoader[index] = false;
    },onerror =>{
      this.RowExpandLoader[index] =false; 
  });
  }
  }
  gridSetup(){
    var that = this;
    that.RowExpandLoader = [];
          this.DataGridLoader   = true;
          this.dataqualityservice.getOpenPriceDifference(this.asOfDate ,this.client).then(data=>{
          this.pricedata=data;
          var index = 0;
          data.forEach(element => {
            that.RowExpandLoader[index] = false;
            index++;
          });
          this.DataGridLoader=false;
          },onerror =>{
          this.DataGridLoader =false; 
          });
    }
    ExportXls(element):void{
      this.dataqualityservice.GetOpenPriceDifferenceDetailsExport(this.asOfDate,element.securityId,this.client).then(res => {

              this.exportService.exportData(res["data"],"Open price data");
                
              }).catch(err=> {
                 
              }); 
    }

}
