import { Component, EventEmitter, Input, OnInit, Inject,Output, ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {SelectionModel} from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { ConnectContactLens } from 'aws-sdk';
import { ExportReportData, Report, ReportData } from '../../models/report';
import { ClimateDataPackService } from '../../services/climatedatapack.service';
import { DownloadService } from '../../services/download.service';
import {S3ManagerService} from '../../../services/s3-manager.service';
import { saveAs } from 'file-saver/FileSaver';
import { ArrayDataSource } from '@angular/cdk/collections';
import * as CryptoJS from "crypto-js";
import * as AWS from 'aws-sdk';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';

enum ColumnNames {
  asOfDate = 'asOfDate',
  indexMarker = 'indexMarker',
  parentIndexMarker = 'parentIndexMarker',
  currency = 'currency',
  controls = 'controls',
}

@Component({
  selector: 'climate-data-grid',
  templateUrl: './climate-data-grid.component.html',
  styleUrls: ['./climate-data-grid.component.scss']
})
export class ClimateDataGridComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() edit = new EventEmitter<Report>();
  dataSource: any;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25];
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 5;
  unsub: any;
  data: MatTableDataSource<any>;
  oldPageLimit: number;
  response: any;
  activePageDataChunk: any;
  pageLength: number;
  filterForm: any;
  isupdate :boolean = false ;
  exportresultArray: ExportReportData[] = [];
  selectedreports: number[] = [];
  passphrase: string = "EncryptionatRest";
  displayColumnNameMap = new Map<ColumnNames, string>([
    //[ColumnNames.asOfDate, 'AsOfDate'],
    [ColumnNames.indexMarker, 'Index Marker'],
    [ColumnNames.parentIndexMarker, 'Parent Index Marker'],
    [ColumnNames.currency, 'Currency']
  ]);
  displayedColumns: ColumnNames[] = [
    //ColumnNames.asOfDate,
    ColumnNames.indexMarker,
    ColumnNames.parentIndexMarker,
    ColumnNames.currency,
    ColumnNames.controls
  ];
  skipColumnsAutoRender = new Set<ColumnNames>([
    ColumnNames.controls
  ]);
  constructor(private router: Router, private climateDataPackService: ClimateDataPackService, private downloadService: DownloadService, private fb: FormBuilder, private s3ManagerService:S3ManagerService) {
    this.unsub = this.climateDataPackService.clientPublish().subscribe((m: any) => {
      this.climateDataPackService.fetchReports$().subscribe(res => {
        this.response = res;
        this.dataSource = this.response.data;
        if (this.dataSource.length % this.pageSize == 1 && (this.pageIndex == (Math.floor(this.dataSource.length / this.pageSize) - 1))) {
          this.pageIndex = this.pageIndex + 1;
        }
        let firstCut = this.pageIndex * this.pageSize;
        let secondCut = firstCut + this.pageSize;
              
        this.activePageDataChunk = this.dataSource.slice(firstCut, secondCut);
        /*for(let item of this.activePageDataChunk){
          item.asOfDate = (item.asOfDate).substring(0,10)
        }*/
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length;
      });
    });
  }

  ngOnInit(): void {
    this.filterForm = this.fb.group({
     // asOfDate: [""],
      indexMarker: [""],
      parentIndexMarker: [""],
      currency: [""]
    });
    this.filterForm.valueChanges.subscribe(value => {
      console.log(this.dataSource);
      var filter1 = this.dataSource.filter(cp => cp.indexMarker.toLowerCase().indexOf(value.indexMarker.toLowerCase()) != -1 && cp.parentIndexMarker.toLowerCase().indexOf(value.parentIndexMarker.toLowerCase()) != -1
        && cp.currency.toLowerCase().indexOf(value.currency.toLowerCase()) != -1 
        //&& cp.asOfDate.toLowerCase().indexOf(value.asOfDate.toLowerCase()) != -1
        );
      this.activePageDataChunk = filter1.slice(0, this.pageSize);
      filter1.paginator = this.paginator;
      this.pageLength = filter1.length;
    });
    this.climateDataPackService.fetchReports$().subscribe((res) => {
      this.response = res;
      this.dataSource = this.response.data;
      this.activePageDataChunk = this.dataSource.slice(0, this.pageSize);
      /*for(let item of this.activePageDataChunk){
        item.asOfDate = (item.asOfDate).substring(0,10)
      }*/
      this.dataSource.paginator = this.paginator;
      this.pageLength = this.dataSource.length;
    });
  }

  getColumnsViewName(column: ColumnNames): string {
    return this.displayColumnNameMap.get(column) || '';
  }
  

  trim(text: any): string | any {
    if (typeof text === 'string') {
      return text.length < 28 ? text : text.slice(0, 25).trim() + '...';
    }
    return text;
  }
 
  onPageChanged(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    let firstCut = e.pageIndex * e.pageSize;
    let secondCut = firstCut + e.pageSize;
    this.activePageDataChunk = this.dataSource.slice(firstCut, secondCut);
    /*for(let item of this.activePageDataChunk){
      item.asOfDate = (item.asOfDate).substring(0,10)
    }*/
  }
  ngOnDestroy() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
  }
}
