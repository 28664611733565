import { Component, OnInit,Input,ViewChild,Inject,NgZone, AfterViewInit  } from '@angular/core';
import { CloseHoldingService } from '../../services/close-holdings.service';
import {CloseHoldingPageModel} from '../../models/close-holding-page-model';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {MagellanColorService} from '../../services/magellancolor.service';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { BiService } from '../../services/BiService.service';
import{CurrencyService}from '../../services/currency.service';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import {ChartTypeComponent} from '../chart-type/chart-type.component';


@Component({
    selector: 'close-country-weights-chart',
    templateUrl: './close-holdings-country-weights-chart.component.html'
})
export class CloseCountryWeightsChartComponent implements OnInit,AfterViewInit {
    @ViewChild(ChartTypeComponent,{static: false}) chartTypeComponent  ;
    chartAttributes: any;
    chartCategories: any;
    dataSource: any;
    CountryWeightChartLoader:boolean=true;
    currentIndex:any;
    toollbarTitle:any;
    popupDataSource:any;
    defaultActiveChart : any = "stackedcolumn2d";
    datasetType = "multiple";
    selectedIndex:any;
    colors : any;
    chart:any;
    constructor(private closeHoldingService: CloseHoldingService,private indexMarkerService:IndexMarkerService, private exportService: ExportToExcelService
        ,private publishIndexMarkerService:PublishIndexMarkerService, private magellanColorService : MagellanColorService
    ,private dialog: MatDialog,private biService: BiService,private zone:NgZone,private currencyService:CurrencyService,
    private idwChartExportService: IDWChartExportService) { }
    @Input() asofDates : any;
    indexMarkers : any;  
    currencyCode : string;

    ngOnInit(){    
        this.indexMarkers = this.indexMarkerService.getIndexMarkers();
        this.currencyCode =this.currencyService.getCurrencyCode();
        this.currentIndex = 0; 
        this.toollbarTitle = "Country Weights"; 
        this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
            this.indexMarkers = result;
            this.CountryWeightChartLoader=true;
            this.chartSetup();
        });
        this.colors = this.magellanColorService.getCurrentColorSchema();
        this.magellanColorService.colorsPublish().subscribe(color => { 
          this.colors=color;
            this.chartAttributes.paletteColors = this.colors;  
          });
         
         
    }
    ngAfterViewInit(){
        this.chartTypeComponent.defaultChartType=this.defaultActiveChart;
    }
    chartInitialize(){
        return (eve) => { 
            this.zone.run(() => {
                this.chart =eve.sender;
            });  
            
        };
          
      }
    
      events = {
        initialized: this.chartInitialize()
    }

    carouselPrevious():void{
        var event: any = {eventName: "Country Weight Previous Carousel", inputParameters: [{Name: "Previous IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() }]};
        this.biService.addEvent(event);
        this.CountryWeightChartLoader=true;
        if(0 == this.currentIndex){
           this.currentIndex = this.indexMarkers.length -1;
        }else{
            this.currentIndex = this.currentIndex - 1;
        }
        this.colors = this.magellanColorService.getCurrentColorSchema();
        this.chartSetup();
    }
    
    carouselNext():void{
        var event: any = {eventName: "Country Weight next Carousel ", inputParameters: [{Name: "Next IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() }]};
        this.biService.addEvent(event);
        this.CountryWeightChartLoader=true;
       if((this.indexMarkers.length -1) == this.currentIndex){
            this.currentIndex = 0;
        }else{
            this.currentIndex = this.currentIndex + 1;
        }
        this.colors = this.magellanColorService.getCurrentColorSchema();
        this.chartSetup();
    }


    update(closeHoldingPageModel:CloseHoldingPageModel){
        this.CountryWeightChartLoader=true;
        this.asofDates = closeHoldingPageModel.asofdate;  
        this.currencyCode=closeHoldingPageModel.currencyCode;      
        this.chartSetup();
    }
    
    private chartSetup() {
        var oneIndexMarkers:string[] = [];
        if(this.indexMarkers[this.currentIndex]==undefined)
        {
             this.currentIndex=0;
        }
        oneIndexMarkers.push(this.indexMarkers[this.currentIndex]);
        this.selectedIndex= this.indexMarkers[this.currentIndex];
        this.toollbarTitle = "Country Weights" ;
        this.closeHoldingService.getCountryWeights(this.asofDates,oneIndexMarkers ,this.currencyCode ).then(data => {
            this.chartAttributes ={
                "paletteColors": this.colors,
                "bgcolor": "#ffffff",
                "plotgradientcolor": "",
                "showAlternateHGridColor": "0",
                "showAlternateVGridColor": "0",
                "showplotborder": "0",
                "divlinecolor": "CCCCCC",
                "showvalues": "0",
                "showcanvasborder": "0",
                "labeldisplay": "STAGGER",
                "slantlabels": "1",
                "canvasborderalpha": "0",
                "showlegend": "0",
                "legendshadow": "0",
                "legendborderalpha": "0",
                "showborder": "0",
                "chartRightMargin": "-10",
                "exportEnabled": "1",
                "exportShowMenuItem": "0",
                "yAxisMaxValue":"0.1"
                
            }
            if(null!= data && null != data.holdingsDataset && null != data.holdingsDataset.data
            && 0< data.holdingsDataset.data.length){
                this.dataSource = {
                    "categories": data.holdingscategories,
                    "dataset": data.holdingsDataset,
                    "chart" :  this.chartAttributes
                  }
                
                  this.popupDataSource={
                      "title":this.toollbarTitle+" - "+ this.selectedIndex,
                      "asOfDate": this.asofDates,
                      "type": this.defaultActiveChart,
                      "dataSource": this.dataSource                    
                    }
            }else{
                this.dataSource = {};
            }
            
            this.CountryWeightChartLoader=false;
            
          },onerror =>{
        this.CountryWeightChartLoader =false;       
     });
        
    }

    ExportXls(event):void{
        var event: any = {eventName: "Close Holdings Export Country Weight", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()}]};
        this.biService.addEvent(event);
        var inputData = {
          "exportType": "xls",
          "code": "closeHoldings-getCloseHoldingsCountryWeightData",
          "xlsFileName": "CloseCountryWeightsRawData",
          "asOfDate": this.asofDates,
          "indexMarkers": [this.indexMarkers[this.currentIndex]]
        };
        this.idwChartExportService.export(inputData);
    }

    ExportPNG(event):void{
        var event: any = {eventName: "Close Holdings Export Country Weight TO PNG", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()}]};
        this.biService.addEvent(event);
        this.popupDataSource.dataSource.chart.showlegend = "1";
        var inputData = {
           "exportType": "png",
           "legend": false,
           "dataSource": this.popupDataSource.dataSource,
           "chartid" : "countryWeightChartExport",
           "type" : this.defaultActiveChart,
           "targetDiv" : "exportPngDiv",
           "chartReference": this.chart,
           "pngFileName" : "CloseHoldingsCountriesWeight",
           "chartTitle": this.toollbarTitle+" - " +this.selectedIndex,
           "isIndexLegend": false,
           "chartDate": this.asofDates
        };
        this.idwChartExportService.export(inputData);
      }

      openWidgetDialog():void{
        var event: any = {eventName: "Country weight help dialog open"};
        this.biService.addEvent(event);
        let dialogRef = this.dialog.open(CloseCountryWeightsChartComponentWidgetDialog, {
            width: '800px' , 
            data: this.indexMarkers     
          });
      }

    openDialog(): void{ 
        var event: any = {eventName: "Country Weight dialog open"};
        this.biService.addEvent(event);
        this.popupDataSource.dataSource.chart.showlegend = "1"; 
        let dialogRef = this.dialog.open(CloseCountryWeightsChartComponentDialog, {
            width: '80%',
            height: '85%',
	    data: this.popupDataSource
        });
    }

    onChartTypeChange(chart: string) {                
        this.defaultActiveChart = chart;    
        this.chartSetup();
    }    
}

@Component({
    selector: 'close-holdings-country-weights-chart-dialog',
	templateUrl: './close-holdings-country-weights-chart.component.dialog.html'
})
export class CloseCountryWeightsChartComponentDialog{

    constructor(
    public dialogRef: MatDialogRef<CloseCountryWeightsChartComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    onClose(): void {
        this.data.dataSource.chart.showlegend = "0";
        this.dialogRef.close();
  }
}


@Component({   
    templateUrl: './close-holdings-country-weights-chart.component.widget.dialog.html'
  })
  export class CloseCountryWeightsChartComponentWidgetDialog{
  
  @Input() indexes: string;
  constructor(
  public dialogRef: MatDialogRef<CloseCountryWeightsChartComponentWidgetDialog>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
    this.indexes="";
     data.forEach(element => {
         if( this.indexes.length<3)
         {
            this.indexes=element;
         }else{
        this.indexes += ", "+ element;
         }
     });   
   }
  
  onClose(): void {
      this.dialogRef.close();
  }
  }
