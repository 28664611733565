import { Component, ViewChild,OnInit,AfterViewInit } from '@angular/core';
import {ValuePageModel} from '../../models/value-page-model';
import {ValuesGrowthofUnitTotalComponent} from '../../components/values-growth-of-unit-total/values-growth-of-unit-total.component';
import {ValuesGrowthofUnitPriceComponent} from '../../components/values-growth-of-unit-price/values-growth-of-unit-price.component';
import {ValuesConstituentCountComponent} from '../../components/values-constituent-count/values-constituent-count.component';
import {ValuesMarketCapComponent} from '../../components/values-market-cap/values-market-cap.component';
import {ValuesGridComponent} from '../../components/values-grid/values-grid.component';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {DateService} from '../../services/date.service';


@Component({
  selector: 'values-page',
  templateUrl: './values-page.component.html',
  styleUrls: ['./values-page.component.css']
})

export class ValuesPageComponent implements OnInit,AfterViewInit{ 


  valuePageModel: ValuePageModel;

  @ViewChild(ValuesGrowthofUnitTotalComponent,{static: false}) valuesGrowthofUnitTotalComponent;
  @ViewChild(ValuesGrowthofUnitPriceComponent,{static: false}) valuesGrowthofUnitPriceComponent;
  @ViewChild(ValuesConstituentCountComponent,{static: false}) valuesConstituentCountComponent;
  @ViewChild(ValuesMarketCapComponent,{static: false}) valuesMarketCapComponent;
  @ViewChild(ValuesGridComponent,{static: false}) valuesGridComponent;
  show:boolean = true;
  iconClass:any = 'icon-minus noTextDecoration';
  cssClass:any = 'showRow'; 
  defaultDateRange:any;
  constructor(private dateService: DateService){}

  ngOnInit(){
    this.defaultDateRange = this.dateService.getCalendarDate();
    this.dateService.calendarDatePublish().subscribe(dateRange=>{
      this.defaultDateRange = dateRange;
    });
  }
  ngAfterViewInit(){
   
    this.valuePageModel.selectedDates.start = this.dateService.changeDateFormat(this.defaultDateRange.start);
      this.valuePageModel.selectedDates.end = this.dateService.changeDateFormat(this.defaultDateRange.end);
      this.valuePageModel.timePeriod=this.dateService.getTimePeriod();
      this.valuesGrowthofUnitTotalComponent.update(this.valuePageModel );
      this.valuesGrowthofUnitPriceComponent.update(this.valuePageModel );
      this.valuesConstituentCountComponent.update(this.valuePageModel );
      this.valuesMarketCapComponent.update(this.valuePageModel);
  }

  onNotify(valuePageModel: ValuePageModel):void{    
    this.valuePageModel = valuePageModel;
    this.valuePageModel.timePeriod=this.dateService.getTimePeriod();
    if(undefined != this.valuesGrowthofUnitTotalComponent && undefined != this.valuesGrowthofUnitPriceComponent
    && undefined != this.valuesConstituentCountComponent && undefined != this.valuesMarketCapComponent
    && undefined != this.valuesGridComponent){
      this.valuesGrowthofUnitTotalComponent.update(this.valuePageModel );
      this.valuesGrowthofUnitPriceComponent.update(this.valuePageModel );
      this.valuesConstituentCountComponent.update(this.valuePageModel );
      this.valuesMarketCapComponent.update(this.valuePageModel);
      this.valuesGridComponent.update(this.valuePageModel);
    }
    
    
  }
  toggle() 
  {
    this.show = !this.show;
    if(this.show)  
    {
      this.iconClass = "icon-minus noTextDecoration ";
      this.cssClass="showRow";        
    }
    else
    {
       this.iconClass = "icon-plus noTextDecoration ";
      this.cssClass="hideRow";      
    }       
  }
  
}