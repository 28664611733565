export class ReportFileDownloadModel{
    date:any;
    requestId: any;
    requestType:any;
    loginId:any
    link:any;
    startDate:any;
    endDate:any;
    indexMarkers:any;
    timePeriod:any;
    isDownloaded:any;
    enabler:any;
}