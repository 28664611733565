import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewEsgExcludesForm } from '../../models/esgexcludes';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report } from '../../models/report';
import { formatDate, DatePipe } from '@angular/common';
import { EsgExcludesDataService } from '../../services/esgexcludes.service';
import { debug } from 'console';

@Component({
  selector: 'app-new-esgexcludesdata-popup',
  templateUrl: './new-esgexcludesdata-popup.component.html',
  styleUrls: ['./new-esgexcludesdata-popup.component.scss']
})
export class NewEsgExcludesPopupComponent implements OnInit {

 form: FormGroup = this.fb.group({
    indexMarker: ['', Validators.required],
    excludeIndexMarker: ['',Validators.required],
    excludeProductCode: [''],
    excludeIcbSubsectorCode: ['']
  } as Record<keyof NewEsgExcludesForm, any[]>);

  updateReport: boolean = false;
  isreportForm = false;
  
  constructor(private fb: FormBuilder, private esgexcludesdataservice: EsgExcludesDataService, public dialogRef: MatDialogRef<NewEsgExcludesPopupComponent>, @Inject(MAT_DIALOG_DATA) public rowdata: any) {

    console.log(rowdata);

  }

  ngOnInit(): void {
    if (this.rowdata) {
      this.updateReport = true;
      this.form.patchValue({
        indexMarker: this.rowdata.IndexMarker,
        excludeIndexMarker: this.rowdata.ExcludeIndexMarker,
        excludeProductCode: this.rowdata.ExcludeProductCode,
        excludeIcbSubsectorCode: this.rowdata.ExcludeIcbSubsectorCode
        
      })   
    }
   
  }

  public onClickHandler(event: any): void {
 
  }

  onSubmit(): void {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      this.dialogRef.close(data);
    }
    if (this.form.invalid) {
      this.isreportForm = true;
      return;
    }
  }
  }
