export class PageAuthModel
{
     RoleID:any;
     RoleName:any;
     MenuItemModel : Model[];
    
}
export class MenuItemModel{
menuItemID:any;
tabName:any;
}

export class Model
{
    tid:any; 
    MenuItem :any;
    isParent:any;
    ParentMenuItem:any;
    DisplayOrder:any;
}

export class UserRoleModel
{
    RoleID: any;
    UserGroup: Group[];
}

export class Group{
    UserGroupID: any;
}