import { Component, OnInit,Inject,NgZone, ViewChild, AfterViewInit } from '@angular/core';
import { PerformanceService } from '../../services/performance.service';
import {PerformancePageModel} from '../../models/performance-page-model';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {MagellanColorService} from '../../services/magellancolor.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DateService } from '../../services/date.service';
import { IndexReturns } from '../../models/performance-index-returns';
import { ChartModel } from '../../models/performance-chart-model';
import { MultiSeries } from '../../models/performance-multi-series';
import { BiService } from '../../services/BiService.service';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import {ChartTypeComponent} from '../chart-type/chart-type.component';
import { AnyRecord } from 'dns';
import{Chart} from 'chart.js';
import { Buffer } from 'buffer';
import { saveAs } from 'file-saver/FileSaver';

@Component({
  selector: 'performance-returns-chart',
  templateUrl: './performance-returns-chart.component.html'
})

export class PerformanceReturnsComponent implements OnInit,AfterViewInit {   
  
  performanceRetrunChartID: string;
    returnChartdidFail: boolean=false;
    chartDatadidFail: boolean=false;
    performance2DMultiSeriesChartDataset: any;   
    RetursChartLoader:boolean =true;
    PerformanceRetrunChart:any;
    chart:any;
    constructor(private dateService: DateService,private performanceService: PerformanceService,
       private exportService: ExportToExcelService,private publishIndexMarkerService:PublishIndexMarkerService,
       private indexMarkerService:IndexMarkerService,private magellanColorService : MagellanColorService,
       public dialog: MatDialog,private biService: BiService,private zone:NgZone,
       private idwChartExportService: IDWChartExportService) { }
    asofDates : any;
    indexMarkers : any;
    timePeriod : any= "DAILY";
    currencyCode : string;
    returnType : any;
    colors : any;
    chartAttributes: any; 
    popupDataSource:any;
    @ViewChild(ChartTypeComponent,{static: false}) chartTypeComponent;   
    defaultActiveChart: string  = "msColumn2D";
    datasetType = "multiple";
    iscarousel: boolean= false;

  openDialog(): void{
      var event: any = {eventName: "Performance Returns dialog open"};
      this.biService.addEvent(event);     
      this.PerformanceRetrunChart.options.legend.display = true; 
      let dialogRef = this.dialog.open(PerformanceReturnsComponentDialog, {
        width: '80%',
        height: '90%',
        data: {date :this.asofDates , dataset :this.PerformanceRetrunChart}
      });
    }

    
  openWidgetDialog(): void{
    var event: any = {eventName: "Performance Returns help dialog open"};
    this.biService.addEvent(event);          
    let dialogRef = this.dialog.open(PerformanceReturnsComponentWidgetDialog, {
      width: '800px'//,
      //data: this.performance2DMultiSeriesChartDataset      
    });
  }

  chartInitialize(event){
    return (eve, arg) => { 
        this.zone.run(() => {
            this.chart =eve.sender;
        });  
        
    };
      
  }

  events = {
    initialized: this.chartInitialize(event)
}
  ngOnInit(){    
    this.performanceRetrunChartID="performanceRetrunChartID";
    this.asofDates= this.dateService.getTransactionDate();
      

      this.colors = this.magellanColorService.getCurrentColorSchema().split(',');
     
    this.magellanColorService.colorsPublish().subscribe(color => { 
      this.colors= color.split(',');
      // this.chartAttributes.paletteColors = this.colors;  
      // this.popupDataSource={     
      //   "dataSource": this.performance2DMultiSeriesChartDataset,
      //   "legend": this.indexMarkerService.getIndexMarkersNames(), 
      //   "asOfDate": this.asofDates, 
      //   "colors":this.colors,
      //   "chartType"    : this.defaultActiveChart
      // }
  })
   }

   ngAfterViewInit(){
    this.chartTypeComponent.defaultChartType=this.defaultActiveChart;
    this.indexMarkers = this.indexMarkerService.getIndexMarkers();    
            this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
          this.RetursChartLoader=true;
          this.indexMarkers=result;
          this.chartSetup(result);
      });
   }

 
 update(performancePageModel:PerformancePageModel){
    this.returnType = performancePageModel.returntype;
    this.currencyCode = performancePageModel.currencyCode;
    this.asofDates = performancePageModel.selectedDates.end;
    this.chartSetup(this.indexMarkers);   
  }
  
  private chartSetup(indexMarkers:AnyRecord) {
    this.performanceRetrunChartID="performanceRetrunChartID";
    this.performanceService.multiSeriesChartLoaded.observers=[];
    this.performanceService.getPerformance2DMultiSeriesChart(this.asofDates,indexMarkers ,this.timePeriod  ,this.currencyCode ,this.returnType);         
     
    this.performanceService.multiSeriesChartLoaded.subscribe(
        (data:MultiSeries) => {
          if(null != data){
            if(0< data.dataSets.length){
              this.bindReturnsChart(data);
            }else{
              //this.performance2DMultiSeriesChartDataset = {};
              this.PerformanceRetrunChart.data.datasets.length = 0 ;
               this.PerformanceRetrunChart.update();
              this.RetursChartLoader =false;  
            }
          }
          else{
            this.performance2DMultiSeriesChartDataset = {};
              this.RetursChartLoader =false; 
          }
               
        });
       this.performanceService.multiSeriesChartFailed.subscribe(
        (didFail: boolean) => this.returnChartdidFail = didFail
      ); 

     
     
  }

  bindReturnsChart(data){
    var dataChart :any[]=[];
    var value : any[][] =[];
    for(var i=0;i<data.dataSets.length;i++)
    {
      value[i] = []
      for(var j=0 ;j<data.dataSets[i].data.length;j++){
        value[i][j]=data.dataSets[i].data[j].value ;
      }
      dataChart.push({label:data.dataSets[i].seriesName, fill:'-1', data :value[i] ,borderColor:this.colors[i],backgroundColor:this.colors[i],borderWidth:'1'});
    }
    if(this.defaultActiveChart=="stackedarea2d"){
      if (this.PerformanceRetrunChart) this.PerformanceRetrunChart.destroy();
      console.log(this.PerformanceRetrunChart.config.type);
      for(var k=0;k<dataChart.length ;k++)
      {
        dataChart[k].fill='-1';
      }
      this.PerformanceRetrunChart = new Chart('performanceRetrunChartID', {
        type: 'line',
        data: {
            labels:  ["Daily", "Weekly", "MTD", "QTD", "YTD", "1M", "3M", "6M"],
            datasets: dataChart
        },
        options: {
          responsive: true,
          elements: {
            line: {
                tension: 0
            }
        },
          legend: {
              display: false    
             },
          maintainAspectRatio: false,
          scales: {
              xAxes: [{
                  ticks: {
                      autoSkip: false,
                      maxRotation: 0,
                      minRotation: 0,
                      fontSize: 8,
                      fontColor: '#000000',
                      fontFamily: 'Roboto',
                  }
              }],
              yAxes: [{
                  
                  ticks: {
                      beginAtZero: true,
                      maxTicksLimit: 10,
                      callback: function (value, index, values) {
                          return value;
                      },
                      fontSize: 9,
                      fontColor: '#000000',
                      fontFamily: 'Roboto',
  
                  }
              }]
          }
      },
      plugins: [{
        afterDraw: function(chart) {
          if (chart.data.datasets.length == 0) {
            // No data is present
            var ctx = chart.chart.ctx;
            var width = chart.chart.width;
            var height = chart.chart.height
            chart.clear();
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = ' rgb(102, 102, 102)';
            ctx.font = "24px Roboto 'Helvetica Nueue'";
          ctx.fillText('No data to display', width / 2, height / 2);
             ctx.restore();
        }
        }
  }]
      });
      this.PerformanceRetrunChart.update();

    }else 
    if(this.defaultActiveChart=="stackedcolumn2d" || this.defaultActiveChart=="stackedcolumn2dline"){
      if (this.PerformanceRetrunChart) this.PerformanceRetrunChart.destroy();
     
      this.PerformanceRetrunChart = new Chart('performanceRetrunChartID', {
        type: 'bar',
        data: {
            labels:  ["Daily", "Weekly", "MTD", "QTD", "YTD", "1M", "3M", "6M"],
            datasets: dataChart
        },
        options: {
          responsive:true,
          legend: {
              display: false     },
          maintainAspectRatio: false,
          scales: {
              xAxes: [{
                stacked :true,
                  ticks: {
                      autoSkip: false,
                      maxRotation: 0,
                      minRotation: 0,
                      fontSize: 8,
                      fontColor: '#000000',
                      fontFamily: 'Roboto',
                  }
              }],
              yAxes: [{
                stacked :true,
                  ticks: {
                      beginAtZero: true,
                      maxTicksLimit: 10,
                      callback: function (value, index, values) {
                          return value;
                      },
                      fontSize: 9,
                      fontColor: '#000000',
                      fontFamily: 'Roboto',
  
                  }
              }]
          }
      },  plugins: [{
        afterDraw: function(chart) {
          if (chart.data.datasets.length == 0) {
            console.log("null");
            var ctx = chart.chart.ctx;
            var width = chart.chart.width;
            var height = chart.chart.height
            chart.clear();
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = ' rgb(102, 102, 102)';
            ctx.font = "bold 24px Roboto 'Helvetica Nueue'";
          ctx.fillText('No data to display', width / 2, height / 2);
             ctx.restore();
        }
        }
  }]
    });
      this.PerformanceRetrunChart.update();
    }else 
    if(this.defaultActiveChart=="msline"){
      if (this.PerformanceRetrunChart) this.PerformanceRetrunChart.destroy();
      
      for(var k=0;k<dataChart.length ;k++)
      {
        dataChart[k].fill='false';
      }
      this.PerformanceRetrunChart = new Chart('performanceRetrunChartID', {
        type: 'line',
        data: {
            labels:  ["Daily", "Weekly", "MTD", "QTD", "YTD", "1M", "3M", "6M"],
            datasets: dataChart
        },
        options: {
          responsive:true,
          elements: {
            line: {
                tension: 0
            }
        },
          legend: {
              display: false   
              },
          maintainAspectRatio: false,
          scales: {
              xAxes: [{
                  ticks: {
                      autoSkip: false,
                      maxRotation: 0,
                      minRotation: 0,
                      fontSize: 8,
                      fontColor: '#000000',
                      fontFamily: 'Roboto',
                  }
              }],
              yAxes: [{
                  
                  ticks: {
                      beginAtZero: true,
                      maxTicksLimit: 10,
                      callback: function (value, index, values) {
                          return value;
                      },
                      fontSize: 9,
                      fontColor: '#000000',
                      fontFamily: 'Roboto',
  
                  }
              }]
          }
      },
      plugins: [{
        afterDraw: function(chart) {
          if (chart.data.datasets.length == 0) {
            // No data is present
            var ctx = chart.chart.ctx;
            var width = chart.chart.width;
            var height = chart.chart.height
            chart.clear();
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = ' rgb(102, 102, 102)';
            ctx.font = "24px Roboto 'Helvetica Nueue'";
          ctx.fillText('No data to display', width / 2, height / 2);
             ctx.restore();
        }
        }}]
      });
      this.PerformanceRetrunChart.update();
    }
    else
    if(this.defaultActiveChart=="stackedbar2d"){
      if (this.PerformanceRetrunChart) this.PerformanceRetrunChart.destroy();
      this.PerformanceRetrunChart = new Chart('performanceRetrunChartID', {
        type: 'horizontalBar',
        data: {
            labels:  ["Daily", "Weekly", "MTD", "QTD", "YTD", "1M", "3M", "6M"],
            datasets: dataChart
        },
        options: {
          responsive:true,
          legend: {
              display: false     
            },
          maintainAspectRatio: false,
          scales: {
              xAxes: [{
                stacked :true,
                  ticks: {
                      autoSkip: false,
                      maxRotation: 0,
                      minRotation: 0,
                      fontSize: 8,
                      fontColor: '#000000',
                      fontFamily: 'Roboto',
                  }
              }],
              yAxes: [{
                stacked :true,
                  ticks: {
                      beginAtZero: true,
                      maxTicksLimit: 10,
                      callback: function (value, index, values) {
                          return value;
                      },
                      fontSize: 9,
                      fontColor: '#000000',
                      fontFamily: 'Roboto',
  
                  }
              }]
          }
      },
      plugins: [{
        afterDraw: function(chart) {
          if (chart.data.datasets.length == 0) {
            // No data is present
            var ctx = chart.chart.ctx;
            var width = chart.chart.width;
            var height = chart.chart.height
            chart.clear();
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = ' rgb(102, 102, 102)';
            ctx.font = "24px Roboto 'Helvetica Nueue'";
          ctx.fillText('No data to display', width / 2, height / 2);
             ctx.restore();
        }
        }
  }]
      });
      this.PerformanceRetrunChart.update();

    }
    else{
    if (this.PerformanceRetrunChart) this.PerformanceRetrunChart.destroy();
    
    this.PerformanceRetrunChart = new Chart('performanceRetrunChartID', {
      type: 'bar',
      data: {
          labels:  ["Daily", "Weekly", "MTD", "QTD", "YTD", "1M", "3M", "6M"],
          datasets: dataChart
      },
      options: {
        responsive:true,
        elements: {
          line: {
              tension: 0
          }
      },
        legend: {
            display: false     },
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                    fontSize: 8,
                    fontColor: '#000000',
                    fontFamily: 'Roboto',
                }
            }],
            yAxes: [{          
                ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 10,
                    callback: function (value, index, values) {
                        return value;
                    },
                    fontSize: 9,
                    fontColor: '#000000',
                    fontFamily: 'Roboto',
                }
            }]
        }
    },
      plugins: [{
        afterDraw: function(chart) {
          if (chart.data.datasets.length == 0) {
            // No data is present
            var ctx = chart.chart.ctx;
            var width = chart.chart.width;
            var height = chart.chart.height
            chart.clear();
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = ' rgb(102, 102, 102)';
            ctx.font = "24px Roboto 'Helvetica Nueue'";
          ctx.fillText('No data to display', width / 2, height / 2);
             ctx.restore();
        }
        }
  }]
  }); 
}
    // this.chartAttributes = {
    //   "paletteColors":  this.colors,
    //   "baseFontColor": "#000000",
    //   "baseFont": "sans-serif",
    //   "showBorder": "0",
    //   "bgColor": "#ffffff",
    //   "showShadow": "0",
    //   "canvasBgColor": "#ffffff",
    //   "canvasBorderAlpha": "0",
    //   "divlineAlpha": "100",
    //   "divlineColor": "#999999",
    //   "usePlotGradientColor": "0",
    //   "showplotborder": "0",
    //   "valueFontColor": "#ffffff",
    //   "showValues": "0",
    //   "showHoverEffect": "1",
    //   "showAlternateHGridColor": "0",
    //   "chartRightMargin": "-10",
    //   "divLineColor": "#d1d4d7",
    //   "showYAxisLine": "1",
    //   "showXAxisLine": "1",
    //   "xAxisLineColor": "#c0c0c0",
    //   "yAxisLineColor": "#c0c0c0",
    //   "showLegend": "0",
    //   "showYAxisValues": "1",
    //   "exportenabled": "1",
    //   "exportatclient": "0",
    //   "exportShowMenuItem": "0",
    //   "showAlternateVGridColor": "0",
    //   "labelDisplay": "None",
    //   "theme": "fint",
    //   "bgAlpha": "0",   
    //   "showCanvasBorder": "0",
    //   "divlineThickness": "1",
    //   "yAxisMinValue": "85",
    //   "anchoralpha": "100",
    //   "anchorgbalpha": "50",
    //   "linethickness": "1",
    //   "showvalues": "0",
    //   "formatnumberscale": "0",
    //   "anchorradius": "2",
    //   "divlinecolor": "#c0c0c0",
    //   "divlinealpha": "30",
    //   "divlineisdashed": "0",
    //   "bgcolor": "#FFFFFF",
    //   "showalternatehgridcolor": "0",
    //   "labelpadding": "10",
    //   "canvasborderthickness": "1",
    //   "legendshadow": "0",
    //   "showborder": "0"   
    //   }  
      
    // this.performance2DMultiSeriesChartDataset = {                   
    //   "dataset": data.dataSets,
    //   "categories":[{
    //     "category": [
    //         { "label": "Daily" }
    //         , { "Vline": "1", "Color": "#c0c0c0" }
    //         , { "label": "Weekly" }
    //         , { "Vline": "1", "Color": "#c0c0c0" }
    //         , { "label": "MTD" }
    //         , { "Vline": "1", "Color": "#c0c0c0" }
    //         , { "label": "QTD" }
    //         , { "Vline": "1", "Color": "#c0c0c0" }
    //         , { "label": "YTD" }
    //         , { "Vline": "1", "Color": "#c0c0c0" }
    //         , { "label": "1M" }
    //         , { "Vline": "1", "Color": "#c0c0c0" }
    //         , { "label": "3M" }
    //         , { "Vline": "1", "Color": "#c0c0c0" }
    //         , { "label": "6M" }
    //     ]
    // }] ,
    // "chart": this.chartAttributes               
    // } 
    this.RetursChartLoader=false;

    // this.popupDataSource={     
    //   "dataSource": this.performance2DMultiSeriesChartDataset,
    //   "asOfDate": this.asofDates,
    //   "legend": this.indexMarkerService.getIndexMarkersNames(), 
    //   "colors":this.colors.split(',') ,
    //   "chartType"    : this.defaultActiveChart     
    // }
     
  }
  onChartTypeChange(chart: string) {                
    this.defaultActiveChart = chart;       
    this.chartSetup(this.indexMarkers);        
}  


  ExportXls(event):void{
    var event: any = {eventName: "Export Peformance Returns", inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode},{Name : "ReturnType" , Value: 

this.returnType}]};
    this.biService.addEvent(event);
      var inputData = {
        "exportType": "xls",
        "code": "performance-getPerformance2DMultiSeriesChartData",
        "xlsFileName": "PerformanceReturnsRawData",
        "asOfDate": this.asofDates,
        "indexMarkers": this.indexMarkers,
        "timePeriod": this.timePeriod,
        "currencyCode": this.currencyCode,
        "returnType": this.returnType
      };
      this.idwChartExportService.export(inputData);
   
  }


  ExportPNG(event):void{
    var event: any = {eventName: "Export Peformance Returns Chart TO PNG",  inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode},{Name : "ReturnType" , 

Value: this.returnType}]};
    this.biService.addEvent(event);
  //   this.popupDataSource.dataSource.chart.showlegend = "1";
  //   var legendClassNames = ["legend","legendcolor","legendlabel"];
  //   var inputData = {
  //     "exportType": "png",
  //     "legend": false,
  //     "dataSource": this.popupDataSource.dataSource,
  //     "chartid" : "performanceRetuensExport",
  //     "type" : this.defaultActiveChart,
  //     "targetDiv" : "exportPngDiv",
  //     "chartReference": this.chart,
  //     "pngFileName" : "PerformanceReturnsRawData",
  //     "chartTitle": "Returns",      
  //     "chartDate": this.asofDates,
  //     "isIndexLegend": false,
  //     "indexLegendNames": this.popupDataSource.legend,
  //     "indexLegendColors": this.popupDataSource.colors,
  //     "indexLegendClassNames" : legendClassNames
  //  };
  //  this.idwChartExportService.export(inputData);
  //  this.popupDataSource.dataSource.chart.showlegend = "0";
  this.PerformanceRetrunChart.options.legend.display =true;
  this.PerformanceRetrunChart.update({duration :0});
  var canvas = <HTMLCanvasElement> document.getElementById('performanceRetrunChartID'); 
  var imageUrl = canvas.toDataURL("image/png");
  var base64 = imageUrl.split("base64,")[1];
  var bufferArr = Buffer.from(base64,'base64');
  var blob = new Blob([bufferArr], { type: 'image/png' });
  saveAs(blob, "PerformanceReturnsRawData.png"); 
  this.PerformanceRetrunChart.options.legend.display =false;
  this.PerformanceRetrunChart.update({duration :0}); 
  }

}


@Component({
  selector: 'performance-returns-component-dialog',
templateUrl: './performance-returns-chart.component.dialog.html'
})
export class PerformanceReturnsComponentDialog{

constructor(
public dialogRef: MatDialogRef<PerformanceReturnsComponentDialog>,
@Inject(MAT_DIALOG_DATA) public data: any) { }

ngOnInit(){
  var myLineChart = new Chart('performanceRetrun',{
    type: this.data.dataset.config.type,
    data: this.data.dataset.data,
    options: this.data.dataset.options,
    plugins: [{
      afterDraw: function(chart) {
        if (chart.data.datasets.length == 0) {
          // No data is present
          var ctx = chart.chart.ctx;
          var width = chart.chart.width;
          var height = chart.chart.height
          chart.clear();
          ctx.save();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = ' rgb(102, 102, 102)';
          ctx.font = "24px Roboto 'Helvetica Nueue'";
        ctx.fillText('No data to display', width / 2, height / 2);
           ctx.restore();
      }
      }}]
});
myLineChart.update()
      }

onClose(): void {
  this.data.dataset.options.legend.display = false; 
  //this.data.dataSource.chart.showlegend = "0"; 
    this.dialogRef.close();
}
}

@Component({
  selector: 'performance-returns-component-dialog',
templateUrl: './performance-returns-chart.component.widget.dialog.html'
})
export class PerformanceReturnsComponentWidgetDialog{

constructor(
public dialogRef: MatDialogRef<PerformanceReturnsComponentWidgetDialog>,
@Inject(MAT_DIALOG_DATA) public data: any) { }

onClose(): void {
    this.dialogRef.close();
}
}