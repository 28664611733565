import { Injectable }    from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import {DrawdownExcessReturnsAnalysis} from '../models/drawdown-excess-returns-analysis.model';
import {ReportFileDownloadModel} from '../models/report-file-download.model';

import { CoreHttpInterceptor } from './CoreHttpInterceptor';
import { ResponseModel } from '../models/response.model';

@Injectable()
export class ReportService{

    private headers = new Headers({'Content-Type': 'application/json'});
    
    constructor( private httpClient: HttpClient) { 
    }

    getIndexCharacteristicsAnalysis(asOfDate: any,indexMarkers : any,currencyCode):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/getReportIndexCharacterisitcs',
        JSON.stringify({
            AsOfDate : asOfDate,
            IndexMarkers : indexMarkers,              
            CurrencyCode :currencyCode
    }), {headers: headers})
    .toPromise()
    .then(response =>{
         return response
        })
    .catch(this.handleError);
    }

    allianzGlobalInvestmentReport(startDate: any,endDate : any):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        return this.httpClient.post(environment.dataServiceRoot + 'magellan/CheckMagellanSqlConnect',
        JSON.stringify({
            StartDate : startDate,
            EndDate : endDate           
    }), {headers: headers})
    .toPromise().then(response =>{
         return response
        }).catch(this.handleError);
    }

    quarterlyReportAMI(startDate: any,endDate : any):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        return this.httpClient.post(environment.dataServiceRoot + 'magellan/QuarterlyReportAMI',
        JSON.stringify({
            StartDate : startDate,
            EndDate : endDate           
    }), {headers: headers})
    .toPromise().then(response =>{
         return response
        }).catch(this.handleError);
    }

    reportEAMQuarterlyAssignment(startDate: any,endDate : any):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        return this.httpClient.post(environment.dataServiceRoot + 'magellan/EAMQuarterlyAssignmentReport',
        JSON.stringify({
            StartDate : startDate,
            EndDate : endDate           
    }), {headers: headers})
    .toPromise().then(response =>{
         return response
        }).catch(this.handleError);
    }
    
    parametricFTSERAFIQSRReturnsReport(startDate: any,endDate : any):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        return this.httpClient.post(environment.dataServiceRoot + 'magellan/ParametricFTSERAFIQSRReturnsReport',
        JSON.stringify({
            StartDate : startDate,
            EndDate : endDate           
    }), {headers: headers})
    .toPromise().then(response =>{
         return response
        }).catch(this.handleError);
    }

    litmanGregoryMonthlyAssignmentReport(startDate: any,endDate : any):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        return this.httpClient.post(environment.dataServiceRoot + 'magellan/LitmanGregoryMonthlyAssignmentReport',
        JSON.stringify({
            StartDate : startDate,
            EndDate : endDate           
    }), {headers: headers})
    .toPromise().then(response =>{
         return response
        }).catch(this.handleError);
    }

    getIndexCharacteristicsAnalysisToExport(asOfDate: any,indexMarkers : any,currencyCode:any):Promise<any[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        
              return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/getReportIndexCharacterisitcsListToExport',
                  JSON.stringify({
                      AsOfDate : asOfDate,
                      IndexMarkers : indexMarkers,              
                      CurrencyCode :currencyCode
              }), {headers: headers})
              .toPromise()
              .then(response =>{
                   return response as ResponseModel
                  })
              .catch(this.handleError);
    }

    getDrawdownExcessReturnsAnalysis(fromDate: any,toDate:any,indexMarkers : any,currencyCode,timePeriod:any):Promise<any[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        
              return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/getdrawdownexcessreturnsanalysislist',
                  JSON.stringify({
                      FromDate : fromDate,
                      ToDate : toDate,
                      IndexMarkers : indexMarkers,              
                      CurrencyCode :currencyCode,
                      TimePeriod: timePeriod
              }), {headers: headers})
              .toPromise()
              .then(response =>{
                   return response
                  })
              .catch(this.handleError);
    }

    getDrawdownExcessReturnsAnalysisToExport(fromDate: any,toDate:any,indexMarkers : any,currencyCode:any,timePeriod:any,custmoColumns:any[]):Promise<any[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        
              return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/getDrawdownExcessReturnsAnalysisListToExport',
                  JSON.stringify({
                      FromDate : fromDate,
                      ToDate : toDate,
                      IndexMarkers : indexMarkers,              
                      CurrencyCode :currencyCode,
                      TimePeriod: timePeriod,
                      SelectedColumns: custmoColumns
              }), {headers: headers})
              .toPromise()
              .then(response =>{
                   return response as ResponseModel
                  })
              .catch(this.handleError);
    }

    getRiskReturnList(fromDate: any,toDate:any,indexMarkers : any,currencyCode,timePeriod:any):Promise<any[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        
              return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/getriskreturnlist',
                  JSON.stringify({
                      FromDate : fromDate,
                      ToDate : toDate,
                      IndexMarkers : indexMarkers,              
                      CurrencyCode :currencyCode,
                      TimePeriod: timePeriod
              }), {headers: headers})
              .toPromise()
              .then(response =>{
                   return response as ResponseModel
                  })
              .catch(this.handleError);
    }
    getDailyTrackingReport(fromDate: any,toDate:any,indexMarkers : any,currencyCode,timePeriod:any):Promise<any[]>{
              let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
       
                    return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/getdailytrackingreport',
                        JSON.stringify({
                            FromDate : fromDate,
                            ToDate : toDate,
                            IndexMarkers : indexMarkers,              
                            CurrencyCode :currencyCode,
                            TimePeriod: timePeriod
                    }), {headers: headers})
                    .toPromise()
                    .then(response =>{
                         return response;
                        })
                    .catch(this.handleError);
          }
          getDailyTrackingReportToExport(fromDate: any,toDate:any,indexMarkers : any,currencyCode:any,timePeriod:any,customcolumns:any[]):Promise<any[]>{
       
              let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
       
                    return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/getdailytrackingreporttoexport',
                        JSON.stringify({
                            FromDate : fromDate,
                            ToDate : toDate,
                            IndexMarkers : indexMarkers,              
                            CurrencyCode :currencyCode,
                            TimePeriod: timePeriod
                    }), {headers: headers})
                    .toPromise()
                    .then(response =>{
                        var result=response  as ResponseModel;
                         return response as any[]
                        })
                    .catch(this.handleError);
          }
       
      
    getRiskReturnListToExport(fromDate: any,toDate:any,indexMarkers : any,currencyCode:any,timePeriod:any,customcolumns:any[]):Promise<any[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        
              return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/getriskreturnlisttoexport',
                  JSON.stringify({
                      FromDate : fromDate,
                      ToDate : toDate,
                      IndexMarkers : indexMarkers,              
                      CurrencyCode :currencyCode,
                      TimePeriod: timePeriod,
                      SelectedColumns: customcolumns
              }), {headers: headers})
              .toPromise()
              .then(response =>{
                   return response as ResponseModel
                  })
              .catch(this.handleError);
    }


    getTrackingReport(fromDate: any,toDate:any,indexMarkers : any,currencyCode,timePeriod:any):Promise<any[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
                
              return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/gettrackingreport',
                  JSON.stringify({
                      FromDate : fromDate,
                      ToDate : toDate,
                      IndexMarkers : indexMarkers,              
                      CurrencyCode :currencyCode,
                      TimePeriod: timePeriod
              }), {headers: headers})
              .toPromise()
              .then(response =>{
                   return response as ResponseModel
                  })
              .catch(this.handleError);
    }

    getTrackingReportToExport(fromDate: any,toDate:any,indexMarkers : any,currencyCode:any,timePeriod:any,customcolumns:any[]):Promise<any[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        
              return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/gettrackingreporttoexport',
                  JSON.stringify({
                      FromDate : fromDate,
                      ToDate : toDate,
                      IndexMarkers : indexMarkers,              
                      CurrencyCode :currencyCode,
                      TimePeriod: timePeriod,
                      SelectedColumns: customcolumns
              }), {headers: headers})
              .toPromise()
              .then(response =>{                  
                   return response as ResponseModel
                  })
              .catch(this.handleError);
    }


    gettFileUloadModelList(correlationIdList :string[], userId:any):Promise<ResponseModel>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        
              return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/getfileuloadmodellist',
                  JSON.stringify({
                    CorrelationIdList:correlationIdList,
                    UserId: userId
                  }), {headers: headers})
              .toPromise()
              .then(response =>{    
                    var result = response as ResponseModel  
                   return result.data as any;
                  })
              .catch(this.handleError);
    }
    getDate( indexMarkers:any):Promise<any[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/GetDate',
            JSON.stringify({
             
              IndexMarkers: indexMarkers,
             
            }), {headers: headers})
        .toPromise()
        .then(response =>{      
             var result = response as ResponseModel
             return result.data as any[]
            })
        .catch(this.handleError);
    }

    getFundamentalsReports(asOfDate:any, indexMarkers:any,selectedFactor):Promise<any[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/GetFundamentalsReports',
            JSON.stringify({
              AsOfDate:asOfDate,
              IndexMarkers: indexMarkers,
              SortBy:selectedFactor 
            }), {headers: headers})
        .toPromise()
        .then(response =>{      
             var result = response as ResponseModel
             return result.data as any[]
            })
        .catch(this.handleError);
    }

    reissue(userId:any,req:any):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
       return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/reissue',
           JSON.stringify({  
            UserId:userId,
            CorrolationId: req.requestId,
            StartDate : req.startDate,
            EndDate: req.endDate,
            IndexMarkers: req.indexMarkers.split(","),
            TimePeriod: req.timePeriod,
            RequestType: req.requestType
       }), {headers: headers})
       .toPromise()
       .then(response =>{
        return response as ResponseModel
           })
       .catch(this.handleError);  
    }

    getFundamentalsReportsToExport(asOfDate:any, indexMarkers:any,selectedFactor):Promise<any[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/GetFundamentalsReportsExport',
            JSON.stringify({
              AsOfDate:asOfDate,
              IndexMarkers: indexMarkers,
              SortBy:selectedFactor 
            }), {headers: headers})
        .toPromise()
        .then(response =>{      
             var result = response as ResponseModel
             return result.data as any[]
            })
        .catch(this.handleError);
    }
    getAmericanBeaconReportsToExport(startDate: any,endDate : any):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        return this.httpClient.post(environment.dataServiceRoot + 'magellan/MagellanAmericanBeacon',
        JSON.stringify({
            StartDate : startDate,
            EndDate : endDate           
    }), {headers: headers})
    .toPromise().then(response =>{
         return response
        }).catch(this.handleError);
    }
    tcwReportsToExport(startDate: any,endDate : any):Promise<any>{
        console.log(startDate)
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        return this.httpClient.post(environment.dataServiceRoot + 'magellan/TcwMonthlyReport',
        JSON.stringify({
            StartDate : startDate,
            EndDate : endDate           
    }), {headers: headers})
    .toPromise().then(response =>{
         return response
        }).catch(this.handleError);
    }
    EAMReportsToExport(startDate: any,endDate : any):Promise<any>{
        console.log(startDate)
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        return this.httpClient.post(environment.dataServiceRoot + 'magellan/EAMMonthlyReport',
        JSON.stringify({
            StartDate : startDate,
            EndDate : endDate           
    }), {headers: headers})
    .toPromise().then(response =>{
         return response
        }).catch(this.handleError);
    }
    exportBHMSReport(startDate: any,endDate : any):Promise<any>{
        console.log(startDate)
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        return this.httpClient.post(environment.dataServiceRoot + 'magellan/BHMSQuarterlyReport',
        JSON.stringify({
            StartDate : startDate,
            EndDate : endDate           
    }), {headers: headers})
    .toPromise().then(response =>{
         return response
        }).catch(this.handleError);
    }
    exportElkCreekReport(startDate: any,endDate : any):Promise<any>{
        console.log(startDate)
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        return this.httpClient.post(environment.dataServiceRoot + 'magellan/ElkCreekQuarterlyReport',
        JSON.stringify({
            StartDate : startDate,
            EndDate : endDate           
    }), {headers: headers})
    .toPromise().then(response =>{
         return response
        }).catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); 
        return Promise.reject(error.message || error);
    }
}