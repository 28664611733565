import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { CloseTopBottomHoldingsChartComponent } from '../close-holdings-top-bottom-holdings-chart/close-holdings-top-bottom-holdings-chart.component';
import { CloseTopLevelSectorChartComponent } from '../close-holdings-top-level-sector-chart/close-holdings-top-level-sector-chart.component';
import { CloseCountryWeightsChartComponent } from '../close-holdings-country-weights-chart/close-holdings-country-weights-chart.component';
import { CloseCurrencyWeightsChartComponent } from '../close-holdings-currency-weights-chart/close-holdings-currency-weights-chart.component';
import { CloseRankedHoldingsGridComponent } from '../close-holdings-ranked-holdings-grid/close-holdings-ranked-holdings-grid.component';
import { CloseHoldingPageModel } from '../../models/close-holding-page-model';
import { CloseHoldingService } from '../../services/close-holdings.service';
import { CloseHoldingsPositionGridComponent } from '../close-holdings-position-grid/close-holdings-position-grid.component';
import * as moment from 'moment';
import { DateService } from '../../services/date.service';
@Component({
  selector: 'close-holdings-page',
  templateUrl: './close-holdings-page.component.html'
})

export class CloseHoldingsPageComponent implements OnInit, AfterViewInit {

  closeHoldingPageModel: CloseHoldingPageModel;
  
  @ViewChild(CloseTopBottomHoldingsChartComponent,{static: false}) topBottomHoldingsChartComponent;
  @ViewChild(CloseCountryWeightsChartComponent,{static: false}) countryWeightsChartComponent;
  @ViewChild(CloseCurrencyWeightsChartComponent,{static: false}) currencyWeightsChartComponent;
  @ViewChild(CloseRankedHoldingsGridComponent,{static: false}) rankedHoldingsGridComponent;
  @ViewChild(CloseHoldingsPositionGridComponent,{static: false}) holdingsPositionGridComponent;
  historyResults: any[] = [];

  show: boolean = true;
  iconClass: any = 'icon-minus noTextDecoration';
  cssClass: any = 'showRow';
  defaultDateRange: any;
  constructor(private dateService: DateService, private closeHoldingsService: CloseHoldingService) { }
  ngOnInit() {
   this.defaultDateRange = this.dateService.getCalendarDate();
   this.dateService.calendarDatePublish().subscribe(dateRange => {
    this.defaultDateRange = dateRange;
    this.closeHoldingPageModel.asofdate = this.dateService.changeDateFormat(this.defaultDateRange.end);
    this.topBottomHoldingsChartComponent.update(this.closeHoldingPageModel);
    this.countryWeightsChartComponent.update(this.closeHoldingPageModel);
    this.currencyWeightsChartComponent.update(this.closeHoldingPageModel);
    this.rankedHoldingsGridComponent.update(this.closeHoldingPageModel);
    this.holdingsPositionGridComponent.update(this.closeHoldingPageModel);
   });
  }
  ngAfterViewInit(){
      this.closeHoldingPageModel.asofdate = this.dateService.changeDateFormat(this.defaultDateRange.end);
      this.closeHoldingPageModel.topBottomFlag = true;
      this.topBottomHoldingsChartComponent.update(this.closeHoldingPageModel);
      this.countryWeightsChartComponent.update(this.closeHoldingPageModel);
      this.currencyWeightsChartComponent.update(this.closeHoldingPageModel);
      this.rankedHoldingsGridComponent.update(this.closeHoldingPageModel);
      this.holdingsPositionGridComponent.update(this.closeHoldingPageModel);
  }
  onNotify(closeHoldingPageModel: CloseHoldingPageModel): void {
    this.closeHoldingPageModel = closeHoldingPageModel;
    if(undefined != this.topBottomHoldingsChartComponent && undefined != this.countryWeightsChartComponent
    && undefined != this.currencyWeightsChartComponent && undefined != this.rankedHoldingsGridComponent
    && undefined != this.holdingsPositionGridComponent){
      this.topBottomHoldingsChartComponent.update(this.closeHoldingPageModel);
      this.countryWeightsChartComponent.update(this.closeHoldingPageModel);
      this.currencyWeightsChartComponent.update(this.closeHoldingPageModel);
      this.rankedHoldingsGridComponent.update(this.closeHoldingPageModel);
      this.holdingsPositionGridComponent.update(this.closeHoldingPageModel);
    }
    

  }

  ExportXls(event): void {
    this.holdingsPositionGridComponent.ExportXls();
  }
  SelectHoldingsColums(event): void {
    this.holdingsPositionGridComponent.refreshGrid(event);

  }
  toggle() {
    this.show = !this.show;
    if (this.show) {
      this.iconClass = "icon-minus noTextDecoration ";
      this.cssClass = "showRow";
    }
    else {
      this.iconClass = "icon-plus noTextDecoration ";
      this.cssClass = "hideRow";
    }
  }
}