import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NeweupabdatesForm } from '../../models/eupabdates';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report } from '../../models/report';
import { formatDate, DatePipe } from '@angular/common';
import { eupabdatesDataService } from '../../services/eupabdates.service';
import { debug } from 'console';

@Component({
  selector: 'app-new-eupabdates-popup',
  templateUrl: './new-eupabdates-popup.component.html',
  styleUrls: ['./new-eupabdates-popup.component.scss']
})
export class NeweupabdatesPopupComponent implements OnInit {

 form: FormGroup = this.fb.group({
   indexCode: ['', Validators.required],
   indexFamilyID: [''],
    parentIndexCode: [''],
   YearOfTheDate: ['', Validators.required],
   DateOfData: ['', Validators.required]
 } as Record<keyof NeweupabdatesForm, any[]>);

  updateReport: boolean = false;
  isreportForm = false;
  constructor(private fb: FormBuilder, private eupabdatesDataService: eupabdatesDataService, public dialogRef: MatDialogRef<NeweupabdatesPopupComponent>, @Inject(MAT_DIALOG_DATA) public rowdata: any) {

    console.log(rowdata);

  }

  ngOnInit(): void {
    if (this.rowdata) {
      this.updateReport = true;
      this.form.patchValue({
        indexCode: this.rowdata.IndexCode,
        parentIndexCode: this.rowdata.ParentIndexCode,
        indexFamilyID: this.rowdata.IndexFamilyID,
        YearOfTheDate: this.rowdata.YearOfTheDate,
        DateOfData: this.rowdata.DateOfData
      })   
    }
  }

  public onClickHandler(event: any): void {
 
  }

  onSubmit(): void {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      this.dialogRef.close(data);
    }
    if (this.form.invalid) {
      this.isreportForm = true;
      return;
    }
  } 

  }
