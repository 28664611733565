import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class BiHeartBeatServiceService {

  constructor(private httpClient: HttpClient) { }

  heartBeat()
  {
      this.httpClient.get(environment.dataServiceRoot + 'ui/search/heartBeat')
    .toPromise()
    .then(response =>{        
         return response as JSON;
        })
    .catch(this.handleError);
  
  }

  private handleError(error: any): Promise<any>
    {
        console.error('error occured',error);
        return Promise.reject(error.message || error);
    }
}
