import { Component,OnInit,ViewChild,AfterViewInit,EventEmitter,Output, Inject, Input } from '@angular/core';
import {CustomTableComponent} from '../custom-table/custom-table.component';
import { LandingService } from '../../services/landing.service';
import {FilterPipe, FilterReturnTypePipe} from '../../filter.pipe';

import {ColumnChooserService} from '../../services/column-chooser.service';
import {CustomColumnsModel} from '../../models/custom-columns-model';
import { IndexReturns } from '../../models/performance-index-returns';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import * as moment from 'moment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DateService} from '../../services/date.service';
import {DateDisplayService} from '../../services/date.display.service';
import { BiService } from '../../services/BiService.service';
import{CurrencyService}from '../../services/currency.service';
import { Jsonp } from '../../../../node_modules/@angular/http';

@Component({
    selector: 'landing-grid',
    templateUrl: './landing-gird.component.html'
})
export class LandingGridComponent implements OnInit {    
    
    landingGriddidFail: boolean=false;
    constructor(private dateDisplayService: DateDisplayService,private landingService: LandingService,private dateService:DateService,private publishIndexMarkerService:PublishIndexMarkerService,
        private indexMarkerService:IndexMarkerService,private columnChooserService:ColumnChooserService,
        private exportService: ExportToExcelService,public dialog: MatDialog,private biService: BiService,
        private currencyService:CurrencyService) { }
    @ViewChild(CustomTableComponent,{static: false}) customTableComponent; 
    @Output() gouCurrencyCodeChange: EventEmitter<string> = new EventEmitter();
    @Output() gouReturnTypeChange: EventEmitter<string> = new EventEmitter();
    @Output() mcCurrencyCodeChange: EventEmitter<string> = new EventEmitter();  
    supportedFilters : any[] = [{ "key": "Hedged", "value": "H" }, { "key": "Net", "value": "N" },{ "key": "Total", "value": "T" },{ "key": "Price", "value": "P" } ];       
    defaultActiveFilter: string = "T";       
    LandingGridLoader:boolean =true;
    indexMarkers : any;   
    currencyCode : string;
    dataTableRawData : any;
    datatable : any;
    gridCustomColumns: any[];
    gridData:any;
    returnType : any;
    asOfDate :any;
    startDate:any;
     dateFormat: any;
    ngOnInit() {     
        this.dateFormat = this.dateService.getDateFormat(); 
this.dateService.dateFormatPublish().subscribe(dateFormat=>{
    this.dateFormat =  dateFormat;  }); 
        this.asOfDate = this.dateService.getCalendarDate().end.substring(0,10);
        this.startDate = this.dateService.getCalendarDate().start.substring(0,10);
        this.currencyCode=this.currencyService.getCurrencyCode();
        this.callSetup(); 
        this.dateService.calendarDatePublish().subscribe(dateRange=>{
            this.asOfDate =  this.dateService.changeDateFormat(dateRange.end);
            this.startDate =  this.dateService.changeDateFormat(dateRange.start);
            this.callSetup();
        });
                 
        this.publishIndexMarkerService.indexMarkerPublish().subscribe(res=>{         
        this.LandingGridLoader =true;
        this.indexMarkers=res;   
        this.gridSetup(this.asOfDate,res);
        }); 
        this.currencyService.currencyCodePublish().subscribe(currency=>{
            this.currencyCode =  currency;        
            this.callSetup();
        });
       
    } 
   
    callSetup(){     
        this.returnType = 'T'; 
        this.indexMarkers = this.indexMarkerService.getIndexMarkers(); 
        this.gridSetup( this.asOfDate,this.indexMarkers);   
    }
   
    gridSetup( asOfDate,indexMarkers:any) { 
       this.LandingGridLoader = true;
        this.columnChooserService.getLandingIndexReturnsCustomColumns(this.startDate,this.asOfDate).then(customs=>{
            this.gridCustomColumns = customs;
            this.landingService.indexCalculatorDataByValuesLoaded.observers=[];
            this.landingService.getIndexReturns(asOfDate,indexMarkers,asOfDate,this.startDate,this.currencyCode,this.returnType); 
            this.landingService.indexCalculatorDataByValuesLoaded.subscribe(
               (data: any) => {
                   if(null != data){
                    this.dataTableRawData = this.flattenData(data);
                    this.gridData = this.dataTableRawData;
                    this.customTableComponent.bind(this.dateDisplayService.getArrayDateDisplay(this.gridData,"asOfDate"),this.gridCustomColumns);       
                    this.LandingGridLoader =false;
                   }
                   else{
                    this.LandingGridLoader =false;
                   }
                   console.log("landing grid success");
                
               });
              this.landingService.indexCalculatorDataByValuesFailed.subscribe(
               (didFail: boolean) => this.landingGriddidFail = didFail
             ); 
        }
         ,onerror =>{
                this.LandingGridLoader =false;  
                console.log("landing grid error");     
             }); 
                    
    }    
   
    refreshGrid(customColumns:CustomColumnsModel[]){
        var event: any = {eventName: "Column Chooser Click", inputParameters: [{Name : "ColumnName", Value :JSON.stringify(customColumns)}]};
        this.biService.addEvent(event);        
        this.gridCustomColumns = customColumns;
        this.customTableComponent.bind(this.gridData,this.gridCustomColumns); 
    }

    Exportxls():void{
        var event: any = {eventName: "Export landing Grid ", inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode},{Name : "ReturnType" , Value: this.returnType}]};
         this.biService.addEvent(event);
        var visibleColumns = this.columnChooserService.getVisibleColumnsNames(this.gridCustomColumns); 
        this.landingService.indexCalculatorDataByValuesDataLoaded.observers=[];
        this.landingService.getIndexReturnsToExport(this.indexMarkers,this.asOfDate,
            this.asOfDate,this.startDate,this.currencyCode,this.returnType,visibleColumns);
        this.landingService.indexCalculatorDataByValuesDataLoaded.subscribe(
            (data: any) => {
                this.exportService.exportData(data,"LandingIndexReturnsGridData");        
            });
           this.landingService.indexCalculatorDataByValuesDataFailed.subscribe(
            (didFail: boolean) => this.landingGriddidFail = didFail
          ); 
    }

    
    PopupInfo(): void{         
        var event: any = {eventName: "Grid help dialog open", inputParameters: [{}]};
    this.biService.addEvent(event);
        let dialogRef = this.dialog.open(LandingGridComponentDialog, {
          width: '800px',
          data: this.indexMarkers                  
        });
      }


    LandingIndexReturnsCustomColumnsSelction(event):void{        
        this.refreshGrid(event);
      }
     
    

     applyFilter(filter : string)
     {
        var event: any = {eventName: "landing ReturnType change", inputParameters: [{Name: "ReturnType", Value: filter }]};
        this.biService.addEvent(event);  
         this.defaultActiveFilter = filter;
         this.returnType = filter;
         this.gridSetup(this.asOfDate,this.indexMarkers);
         this.gouReturnTypeChange.emit(this.returnType);             
     }
     getFilterCSSClass(filter : string){
        return (filter == this.defaultActiveFilter) ? "active" : "currencyLinks";
    }
     private filterData(data:any,currencyFilter:string,returnTypeFilter:string):any{
        var currencyFilterPipe = new FilterPipe();
        var currencyFiltred =  currencyFilterPipe.transform(data,this.currencyCode);        
        var returnTypeFilterPipe = new FilterReturnTypePipe();
        return returnTypeFilterPipe.transform(currencyFiltred,this.returnType);
    }

    private flattenData(data:any[]){
        var flattenedData:any[] = [];
        for(var counter = 0; counter<data.length; counter++){
           var row = {};
           var returnsWithPeriodicData = data[counter];
           for(var prop in returnsWithPeriodicData.dataSource){
               row[prop] = returnsWithPeriodicData.dataSource[prop];
           }
           if(null!= returnsWithPeriodicData.yearlyData && undefined!= returnsWithPeriodicData.yearlyData){
            for(var yearlyCounter = 0; yearlyCounter<returnsWithPeriodicData.yearlyData.length; yearlyCounter++){
                row[returnsWithPeriodicData.yearlyData[yearlyCounter].name] = returnsWithPeriodicData.yearlyData[yearlyCounter].value;
            }
           }
           if(null!= returnsWithPeriodicData.quarterlyData && undefined!= returnsWithPeriodicData.quarterlyData){
            for(var quarterlyCounter = 0; quarterlyCounter<returnsWithPeriodicData.quarterlyData.length; quarterlyCounter++){
                row[returnsWithPeriodicData.quarterlyData[quarterlyCounter].name] = returnsWithPeriodicData.quarterlyData[quarterlyCounter].value;
               }
           }    
           if(null!= returnsWithPeriodicData.monthlyData && undefined!= returnsWithPeriodicData.monthlyData){
            for(var monthlyCounter = 0; monthlyCounter<returnsWithPeriodicData.monthlyData.length; monthlyCounter++){
                row[returnsWithPeriodicData.monthlyData[monthlyCounter].name] = returnsWithPeriodicData.monthlyData[monthlyCounter].value;
               }
           }       
           
           flattenedData.push(row);
        }
        return flattenedData;
    }
}


@Component({
    selector: 'landing-grid-dialog',
templateUrl: './landing.component.widget.dialog.html'
})
export class LandingGridComponentDialog{
 
    @Input() indexes: string;
    constructor(
    public dialogRef: MatDialogRef<LandingGridComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.indexes="";
       data.forEach(element => {
           if( this.indexes.length<3)
           {
              this.indexes=element;
           }else{
          this.indexes += ", "+ element;
           }
       });   
     }
  
  onClose(): void {
      this.dialogRef.close();
}
}