import { Component } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {pluck, switchMap, tap} from 'rxjs/operators';
import {PortfoliosService} from '../../services/portfolios.service';
import {FormControl} from '@angular/forms';
import {PortfolioDetails} from '../../models/portfolio';
import {DownloadService} from '../../services/download.service';
import {GridData} from '../../models/common';
import {DatePipe} from '@angular/common'

@Component({
  selector: 'app-portfolio-details-page',
  templateUrl: './portfolio-details-page.component.html',
  styleUrls: ['./portfolio-details-page.component.scss'],
  providers: [DatePipe]
})
export class PortfolioDetailsPageComponent {
  private readonly DEFAULT_PORTFOLIO_NAME = 'portfolio';

  nameFC = new FormControl('');
  updateObject: string[][] = [];
  data: any;
  commitStatus:any;
  unsub:any;

  portfolioDetails$ = this.route.params.pipe(
    pluck('id'),
    tap((name) => this.nameFC.patchValue(name)),
    switchMap(id => this.portfoliosService.fetchPortfolioById(id)),
  );

  constructor(
    private route: ActivatedRoute,
    private portfoliosService: PortfoliosService,
    private router: Router,
    private downloadService: DownloadService,
    private datepipe: DatePipe
  ) {
  }


  save(portfolioDetails: PortfolioDetails) {
    var valid = true;
    var updatedPortfolioDetails = this.updateDatasource(portfolioDetails.model.children.data, portfolioDetails.columns, this.updateObject);
   var date = portfolioDetails.model.children.data[0]['asOfDate']
   var date2 = portfolioDetails.model.children.data[1]['asOfDate']
   var currency = portfolioDetails.model.children.data[0]['currencyCode']
   var currency2 = portfolioDetails.model.children.data[1]['currencyCode']
    for (let item of updatedPortfolioDetails) {
     if ((item.portfolioValue != 0 ? (isNaN(item.portfolioValue)) : false) ||
        (item.weight != 0 ? (isNaN(item.weight)) : false) ||
        (item.sedol != null ? (item.sedol.length != 7) : false) ||
        (item.cusip != null ? (item.cusip.length != 9) : false) ||
        (item.isin != null ? (item.isin.length != 12) : false) ||
        (item.currencyCode != null ? (item.currencyCode != currency): false)||
        (item.currencyCode != null ? (item.currencyCode != currency2): false)||
        (item.asOfDate != undefined ? (item.asOfDate != date ):false) ||
        (item.asOfDate != undefined ? (item.asOfDate != date2): false)  )
        {
        valid = false;
        alert("Please Update Valid details");
        break;
      }
     }
       
    if (valid == true) {
      for (let detail of updatedPortfolioDetails) {
        detail.portfolioValue = (detail.portfolioValue == "") ? 0 : detail.portfolioValue;
        detail.weight = (detail.weight == "") ? 0 : detail.weight;
        detail.sedol = (detail.sedol == "") ? null : detail.sedol;
        detail.cusip = (detail.cusip == "") ? null : detail.cusip;
        detail.Isin = (detail.isin == "") ? null : detail.isin;
        detail.consCode = (detail.consCode == "") ? null : detail.consCode;
        detail.constituentName = (detail.constituentName == "") ? null : detail.constituentName;
        detail.countryCode = (detail.countryCode == "") ? null : detail.countryCode;
        detail.currencyCode = (detail.currencyCode == "") ? null : detail.currencyCode;
        var date = this.datepipe.transform(detail.asOfDate , 'dd-mm-yyyy');
        detail.asOfDate = (detail.asOfDate == "") ? null : date;
      }
      this.portfoliosService.updatePortfolioDetails(updatedPortfolioDetails).then(res => {
        if (res == 1) {
          this.portfoliosService.publishClient("Updated")
        }
      });
      this.router.navigate(['pbr']);
    }    
  }
  delete(portfolioDetails: PortfolioDetails) {
    
    this.portfoliosService.deletePortfolioDetails(portfolioDetails).then(result =>{
      if(result == "1")
      {
          this.portfoliosService.publishClient("Deleted")
      } 
  });
  this.portfoliosService.GetPortfolios(0,5); 
  this.router.navigate(['pbr']);

  }

  update(updateObject: string[][]) {
    this.updateObject = updateObject;
  }

  updateDatasource(dataSource: string[][], columns: string[], updateObject: string[][] = [] , ) : any{
  var updatedData :any[] = [];
    // update current dataSource
    updateObject.forEach((row, rowIndex) => row.forEach((colValue, columnIndex) => {
      if (dataSource) {
        dataSource[rowIndex][columns[columnIndex]] = colValue;
      }
      updatedData .push(dataSource[rowIndex]);
    }));
    return updatedData;
  }

  download(portfolioDetails: PortfolioDetails) {
    const dsCopy: GridData<any> = {
      model: portfolioDetails.model.children.data,
      columns: portfolioDetails.columns.map(c => c),
      data: portfolioDetails.data.map(row => row.map(cell => cell))
    };
    // this.updateDatasource(dsCopy.data, this.updateObject);
    this.downloadService.download(dsCopy, portfolioDetails.model.name || this.DEFAULT_PORTFOLIO_NAME);
  }

  return(){
    this.router.navigate([ 'pbr', ])

  }
}
