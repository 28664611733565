import { Injectable }    from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import {CustomColumnsModel} from '../models/custom-columns-model'
import {ResponseModel} from '../models/landing-page-model';
@Injectable()
export class ColumnChooserService {

    private headers = new Headers({'Content-Type': 'application/json'});
    constructor( private httpClient: HttpClient) { 
    }

    getColumnsDescription(dataType:any,startDate:any,endDate:any,indexMarkers:any):Promise<CustomColumnsModel[]>{        
        if("OpenHoldings" == dataType){
           return this.getOpenHoldingsCustomColumns();
        }
        if("CloseHoldings" == dataType){
            return this.getCloseHoldingsCustomColumns();
         }
        if("Performance" == dataType){
            return this.getPerformanceCustomColumns(startDate,endDate);
        }
        if("LandingIndexReturns" == dataType){
            return this.getLandingIndexReturnsCustomColumns(startDate,endDate);
        }
        if("Values" == dataType){
            return this.getValuesCustomColumns();
        }
        if("IndexProperties"== dataType){
            return this.getReferenceIndexPropertiesCustomColumns(indexMarkers,endDate);
        }
       if("Factors"==dataType){
           return this.getFactorCustomColumns(startDate,endDate)
       }
    }
    getDefaultColumnsDescription(dataType:any,endDate:any,indexMarkers:any):Promise<CustomColumnsModel[]>{           
        if("OpenHoldings" == dataType){
            return this.getDefaultOpenHoldingsCustomColumns();
        }
        if("CloseHoldings" == dataType){
            return this.getDefaultCloseHoldingsCustomColumns();
         }
        if("Performance" == dataType){
            return this.getDefaultPerformanceCustomColumns();
        }
        if("LandingIndexReturns" == dataType){
            return this.getDefaultLandingIndexReturnsCustomColumns();
        }
        if("Values" == dataType){
            return this.getDefaultValuesCustomColumns();
        }
        if("IndexProperties"== dataType){
            return this.getReferenceIndexPropertiesCustomColumns(indexMarkers,endDate);
        }
    }
    
    getCloseHoldingsCustomColumns():Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/closeHoldings/getholdingscustomcolumns',
            null, {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError);  
    }
    getOpenHoldingsCustomColumns():Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/openHoldings/getholdingscustomcolumns',
            null, {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError);  
    }
    getDefaultCloseHoldingsCustomColumns():Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });        
        return this.httpClient.post(environment.dataServiceRoot + 'ui/closeHoldings/getdefaultholdingscustomcolumns',
            null, {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError);  
    }

    getDefaultOpenHoldingsCustomColumns():Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });        
        return this.httpClient.post(environment.dataServiceRoot + 'ui/openHoldings/getdefaultholdingscustomcolumns',
            null, {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError);  
    }

    getPerformanceCustomColumns(startDate:any,endDate:any):Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var myStartDate = new Date(startDate);
        var myEndDate = new Date(endDate);
        return this.httpClient.post(environment.dataServiceRoot + 'ui/performance/getperformancecustomcolumns',
            JSON.stringify({
                StartDate:myStartDate,
                EndDate: myEndDate
            }), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError);  
    }
    
    getFactorCustomColumns(startDate:any,endDate:any):Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var myStartDate = new Date(startDate);
        var myEndDate = new Date(endDate);
        return this.httpClient.post(environment.dataServiceRoot + 'ui/performance/getfactorscustomcolumns',
            JSON.stringify({
                StartDate:myStartDate,
                EndDate: myEndDate
            }), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError);  
    }
    getDefaultPerformanceCustomColumns():Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/performance/getdefaultperformancecustomcolumns',
            null, {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError);  
    }

    getLandingIndexReturnsCustomColumns(startDate:any,endDate:any):Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var myStartDate = new Date(startDate);
        var myEndDate = new Date(endDate);
        return this.httpClient.post(environment.dataServiceRoot + 'ui/landing/getlandingindexreturnscustomcolumns',
        JSON.stringify({
            StartDate:myStartDate,
            EndDate: myEndDate
        }), {headers: headers})
        .toPromise()
        .then(response =>{
                var result = response as ResponseModel;
                return result.data as CustomColumnsModel[];
            })
        .catch(this.handleError);  
    }
    getDefaultLandingIndexReturnsCustomColumns():Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/landing/getdefaultlandingindexreturnscustomcolumns',
            null, {headers: headers})
        .toPromise()
        .then(response =>{
            var result = response as ResponseModel;
            return result.data as CustomColumnsModel[];
            })
        .catch(this.handleError);  
    }

    getValuesCustomColumns():Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/values/getvaluescustomcolumns',
            null, {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError);  
    }
    getDefaultValuesCustomColumns():Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/values/getdefaultvaluescustomcolumns',
            null, {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError);  
    }
    

    getReportingDrawdownExcessRturnsAnalysisCustomColumns(indexMarkers:any):Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/getreportingdrawdownexcessreturnsanalysiscustomcolumns',
        JSON.stringify({
            IndexMarkers : indexMarkers
    }), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError); 
    }

    getReferenceIndexPropertiesCustomColumns(indexMarkers:any,endDate:any):Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reference/getReferenceIndexPropertiesCustomColumns',
         JSON.stringify({
            IndexMarkers: indexMarkers,
            ToDate: endDate
         }),
        {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError); 
    }
    

    getReportingRiskReturnsCustomColumns(indexMarkers:any):Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/getreportingriskreturnscustomcolumns',
        JSON.stringify({
            IndexMarkers : indexMarkers
    }), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError); 
    }

    getTrackingReportCustomColumns(indexMarkers:any):Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/gettrackingreportcustomcolumns',
        JSON.stringify({
            IndexMarkers : indexMarkers
    }), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as ResponseModel
            })
        .catch(this.handleError); 
    }


    getReferenceProductFamiliesCustomColumns():Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reference/getReferenceProductFamiliesCustomColumns',null, {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError); 
    }

    getReferenceProductIndexMarkerCustomColumns():Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reference/GetReferenceProductIndexMarkerCustomColumns',null, {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError); 
    }
    getDailyTrackingReportCustomColumns(indexMarkers:any):Promise<any[]>{
         let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
         return this.httpClient.post(environment.dataServiceRoot + 'ui/reporting/getdailytrackingreportcustomcolumns',
         JSON.stringify({
             IndexMarkers : indexMarkers
     }), {headers: headers})
         .toPromise()
         .then(response =>{
             var result = response as ResponseModel;
             return result.data as any[]
             })
         .catch(this.handleError); 
     } 
      
    updateColumnsDescription(dataType:any,customColumns:CustomColumnsModel[],startDate:any,endDate:any):Promise<CustomColumnsModel[]>{
        if("CloseHoldings" == dataType){
            return this.updateHoldingsCustomColumns(customColumns);
         }
         if("OpenHoldings" == dataType){
            return this.updateOpenHoldingsCustomColumns(customColumns);
         }
         if("Performance" == dataType){
             return this.updatePerformanceCustomColumns(customColumns,startDate,endDate);
         }
         if("LandingIndexReturns" == dataType){
             return this.updateLandingIndexReturnsCustomColumns(customColumns,startDate,endDate);
         }
         if("Values"== dataType){
             return this.updateValuesCustomColumns(customColumns);
         }
         if("IndexProperties"== dataType){
            return this.updateIndexCustomColumns(customColumns);
        }
    }
    updateHoldingsCustomColumns(customColumns: CustomColumnsModel[]):Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/closeHoldings/updateholdingscustomcolumns',
            JSON.stringify(customColumns), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError);  
    }
    updateOpenHoldingsCustomColumns(customColumns: CustomColumnsModel[]):Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/openHoldings/updateholdingscustomcolumns',
            JSON.stringify(customColumns), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError);  
    }

    updatePerformanceCustomColumns(customColumns: CustomColumnsModel[],startDate:any,endDate:any):Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var myStartDate = new Date(startDate);
        var myEndDate = new Date(endDate);
        return this.httpClient.post(environment.dataServiceRoot + 'ui/performance/updateperformancecustomcolumns',
            JSON.stringify({
                StartDate:myStartDate,
                EndDate: myEndDate,
                UpdatedColumns:customColumns
            }), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError);  
    }

    updateIndexCustomColumns(customColumns: CustomColumnsModel[]):Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/reference/UpdateIndexCustomColumns',
        JSON.stringify(customColumns), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError);  
    }

    updateLandingIndexReturnsCustomColumns(customColumns: CustomColumnsModel[],startDate:any,endDate:any):Promise<CustomColumnsModel[]>{
        var myStartDate = new Date(startDate);
        var myEndDate = new Date(endDate);
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/landing/updatelandingindexreturnscustomcolumns',
        JSON.stringify({
            StartDate:myStartDate,
            EndDate: myEndDate,
            UpdatedColumns:customColumns
        }), {headers: headers})
            .toPromise()
            .then(response =>{
                var result = response as ResponseModel;
                return result.data as CustomColumnsModel[];
                })
            .catch(this.handleError);  
    }

    updateValuesCustomColumns(customColumns: CustomColumnsModel[]):Promise<CustomColumnsModel[]>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(environment.dataServiceRoot + 'ui/values/updatevaluescustomcolumns',
            JSON.stringify(customColumns), {headers: headers})
        .toPromise()
        .then(response =>{
                return response as CustomColumnsModel[]
            })
        .catch(this.handleError);  
    }
  

    getVisibleColumnsNames(customColumns:any[]):string[]{
        var visibleColumns:string[] = [];
        for(var count=0;count<customColumns.length;count++){
            if(true == customColumns[count].columnVisibility){
                visibleColumns.push(customColumns[count].columnName);
            }
        }
        return visibleColumns;
    }
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}