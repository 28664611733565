import { Component,OnInit,ViewChild,AfterViewInit,EventEmitter,Output, Inject, Input } from '@angular/core';
import {CustomTableComponent} from '../custom-table/custom-table.component';
import { LandingService } from '../../services/landing.service';
import {FilterPipe, FilterReturnTypePipe} from '../../filter.pipe';
import {ColumnChooserService} from '../../services/column-chooser.service';
import {CustomColumnsModel} from '../../models/custom-columns-model';
import { IndexReturns } from '../../models/performance-index-returns';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import * as moment from 'moment';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DateService} from '../../services/date.service';
import {DateDisplayService} from '../../services/date.display.service';
import{CurrencyService}from '../../services/currency.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap'
import {PerformanceService} from '../../services/performance.service';
import { PerformancePageModel } from '../../models/performance-page-model';

@Component({
    selector: 'factors-calendar-year-return-grid',
    templateUrl: './factors-calendar-year-return-grid.component.html'
})
export class FactorsCalendarYearReturnGridComponent {
    constructor(private dateDisplayService: DateDisplayService,private landingService: LandingService,private dateService:DateService,private publishIndexMarkerService:PublishIndexMarkerService,
        private indexMarkerService:IndexMarkerService,private columnChooserService:ColumnChooserService,
        private exportService: ExportToExcelService,public dialog: MatDialog,private currencyService:CurrencyService,
        private performanceService: PerformanceService) { }
      
    @ViewChild(CustomTableComponent,{static: false}) customTableComponent;   
    
   
    defaultActiveCurrency: string = "USD";
    defaultActiveFilter: string = "T";       
    CalendarYearRetrunGridLoader:boolean =true;
    indexMarkers : any;     
    currencyCode : string;
    dataTableRawData : any;
    datatable : any;
    gridCustomColumns: any[];
    gridData:any;
    returnType : any;
    asOfDate :any;
    timePeriod:any;
    pageModel : PerformancePageModel = {currencyCode:"USD",returntype:"T",selectedDates:{start:moment().subtract(12,'years').toDate(),end:moment().subtract(1,'days').toDate()}};     
    
    ngOnInit() {              
        this.asOfDate = this.dateService.getCalendarDate().end.substring(0,10);
        this.callSetup();        
    } 
   
    callSetup(){
        this.currencyCode = this.currencyService.getCurrencyCode(); 
        this.returnType = 'T'; 
        this.timePeriod = 'DAILY';
        this.indexMarkers = this.indexMarkerService.getIndexMarkers();  
        this.gridSetup( this.asOfDate,this.indexMarkers);   
    }
    gridSetup( asOfDate,indexMarkers:any) { 
        this.CalendarYearRetrunGridLoader = true;
       
            this.columnChooserService.getFactorCustomColumns(this.pageModel.selectedDates.start,this.pageModel.selectedDates.end).then(customs=>{
                this.gridCustomColumns = customs;
                console.log(this.gridCustomColumns)
              });
                                 
                this.performanceService.getCalendarReturnsChartData(this.asOfDate,this.indexMarkers,this.timePeriod,
                    this.currencyCode,this.returnType).then(res => {
    
                          this.gridData = res["data"];
                          this.customTableComponent.bind(this.dateDisplayService.getArrayDateDisplay(this.gridData,"asofdate"),this.gridCustomColumns);
                          this.CalendarYearRetrunGridLoader =false;
                        }).catch(err=> {
                            this.CalendarYearRetrunGridLoader =false;
                        }); 
                          
     }  
    
   
    PopupInfo(): void{         
        let dialogRef = this.dialog.open(FactorsCalendarYearReturnGridComponentDialog, {
          width: '800px',
          data: this.indexMarkers                  
        });
      }

      Exportxls():void{
        this.performanceService.getCalendarReturnsChartData(this.asOfDate,this.indexMarkers,this.timePeriod,
            this.currencyCode,this.returnType).then(res => {

                this.exportService.exportData(res["data"],"Factors Returns Calendar Years GridData");
                  this.CalendarYearRetrunGridLoader =false;
                }).catch(err=> {
                    this.CalendarYearRetrunGridLoader =false;
                }); 
      }
    
}

@Component({
    selector: 'factors-calendar-year-return-grid.component.widget.dialog',
    templateUrl: './factors-calendar-year-return-grid.component.widget.dialog.html'
})
export class FactorsCalendarYearReturnGridComponentDialog {
    @Input() indexes: string;
    constructor(
    public dialogRef: MatDialogRef<FactorsCalendarYearReturnGridComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.indexes="";
       data.forEach(element => {
           if( this.indexes.length<3)
           {
              this.indexes=element;
           }else{
          this.indexes += ", "+ element;
           }
       });   
     }
  
  onClose(): void {
      this.dialogRef.close();
  }
}