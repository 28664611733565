import { Component, OnInit,Input,OnChanges,SimpleChanges,Inject,NgZone  } from '@angular/core';
import { CloseHoldingService } from '../../services/close-holdings.service';
import {CloseHoldingPageModel} from '../../models/close-holding-page-model';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {MagellanColorService} from '../../services/magellancolor.service';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import{CurrencyService}from '../../services/currency.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap'
import {DateService} from '../../services/date.service';
import {FactorsService} from '../../services/factors.service';
import { BiService } from '../../services/BiService.service';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import {ChartTypeComponent} from '../chart-type/chart-type.component';

@Component({
    selector: 'factors-cumulative-total-return-chart',
    templateUrl: './factors-cumulative-total-return-chart.component.html'
})
export class FactorsCumulativeTotalReturnChartComponent implements OnInit {

    chartAttributes: any;
    chartCategories: any;
    dataSource: any;
    CumulativeTotalReturnChartLoader:boolean=true;
    currentIndex:any;
    toollbarTitle:any;
    popupDataSource:any;

    colors : any;
    chart:any;
    constructor(private closeHoldingService: CloseHoldingService,private indexMarkerService:IndexMarkerService, private exportService: ExportToExcelService
        ,private publishIndexMarkerService:PublishIndexMarkerService, private magellanColorService : MagellanColorService
    ,private dialog: MatDialog,private currencyService:CurrencyService, private factorsService:FactorsService,
    private dateService:DateService,private biService: BiService,private zone:NgZone,
    private idwChartExportService: IDWChartExportService) { }
     asofDates : any;
     startDate:any;
    indexMarkers : any;  
    currencyCode : string;
    timePeriod:any;
    defaultActiveChart : any = "ZoomLine";

    ngOnInit(){    
        this.indexMarkers = this.indexMarkerService.getIndexMarkers();
        this.currencyCode = this.currencyService.getCurrencyCode(); 
        this.asofDates = this.dateService.getCalendarDate().end.substring(0,10);
        this.startDate = this.dateService.getCalendarDate().start.substring(0,10);
        this.timePeriod = "DAILY";
        this.currentIndex = 0; 
        this.toollbarTitle = "Cumulative Total Return -- ";
        this.colors = this.magellanColorService.getCurrentColorSchema();
        this.dataSource = {};
        this.chartSetup();           
        this.magellanColorService.colorsPublish().subscribe(color => { 
          this.chartAttributes.paletteColors = color;  
          });
    }

    chartInitialize(){
        return (eve) => { 
            this.zone.run(() => {
                this.chart =eve.sender;
            });  
            
        };
          
      }
    
      events = {
        initialized: this.chartInitialize()
    }

    private chartSetup() {
        var oneIndexMarkers:string[] = [];
        oneIndexMarkers.push(this.indexMarkers[this.currentIndex]);
        this.toollbarTitle = "Cumulative Total Return ";
       
        this.chartAttributes ={
        "caption":"Cumulative Total Return (Rebased to 100)",
        "paletteColors":this.colors,
        "showBorder": "0",
        "rotateLabels":"0",
        "showLabels":"1",
        "bgColor": "#ffffff",
        "baseFont": "Helvetica Neue,Arial",
        "showCanvasBorder": "0",
        "showShadow": "0",
        "showAlternateHGridColor": "0",
        "canvasBgColor": "#ffffff",
        "zaxisname":"0",
        "yaxisname": "Index Level",
        "yaxisminValue": "800",
        "yaxismaxValue": "1400",
        "pixelsPerPoint": "0",
        "pixelsPerLabel": "30",
        "lineThickness": "2",
        "compactdatamode": "1",
        "allowPinMode":"0",
        "dataseparator": "|",
        "labelHeight": "30",
        "scrollheight": "10",
        "flatScrollBars": "1",
        "scrollShowButtons": "0",
        "scrollColor": "#cccccc",
        "legendBgAlpha": "0",
        "legendBorderAlpha": "0",
        "legendShadow": "0",
        "legendItemFontSize": "10",
        "legendItemFontColor": "#666666",
        "inverseYaxis":"1"
            }
            
            this.factorsService.getFactorsTotalVolatilityChart(this.startDate,this.asofDates,this.indexMarkers,
                this.currencyCode,this.timePeriod).then(res=>{

                    this.dataSource["categories"] = res["data"]["growthOfUnitcategories"];
                    this.dataSource["dataset"] = res["data"]["growthOfUnitMultiDataset"];
                    this.dataSource["chart"] = this.chartAttributes;
                    this.popupDataSource = {
                      "title": this.toollbarTitle,
                      "type": this.defaultActiveChart,
                      "dataSource": this.dataSource
                    };
                    this.CumulativeTotalReturnChartLoader =false;
                });
            
          }
          
          ExportXls(event):void{
            var event: any = {eventName: "Factors Cumulitive Total Returns"};
            this.biService.addEvent(event);
            var inputData = {
              "exportType": "xls",
              "code": "factors-getFactorsPriceVolatilityData",
              "xlsFileName": "factorsCumulitiveReturnsRawData",
              "fromDate": this.startDate,
              "toDate":this.asofDates,
              "indexMarkers": this.indexMarkers,
              "currencyCode": this.currencyCode,
              "timePeriod": this.timePeriod
            };
            this.idwChartExportService.export(inputData);
        }
    
        ExportPNG(event):void{
            var event: any = {eventName: "Factors Cumulitive Total Returns TO PNG"};
            this.biService.addEvent(event);
            this.popupDataSource.dataSource.chart.showlegend = "1";
            var inputData = {
               "exportType": "png",
               "legend": false,
               "dataSource": this.popupDataSource.dataSource,
               "chartid" : "factorsCumulitiveReturnsChartExport",
               "type" : this.defaultActiveChart,
               "targetDiv" : "exportPngDiv",
               "chartReference": this.chart,
               "pngFileName" : "factorsCumulitiveReturns",
               "chartTitle": this.toollbarTitle,
               "isIndexLegend": false,
               "chartDate": this.asofDates
            };
            this.idwChartExportService.export(inputData);
          }

          openWidgetDialog():void{
            var event: any = {eventName: "Factors Cumulitive Total Return help dialog open"};
            this.biService.addEvent(event);
            let dialogRef = this.dialog.open(FactorsCumulitiveTotalReturnChartComponentWidgetDialog, {
                width: '800px' , 
                data: this.indexMarkers     
              });
          }
    
        openDialog(): void{ 
            var event: any = {eventName: "Factors Cumulitive Total Return dialog open"};
            this.biService.addEvent(event);
            this.popupDataSource.dataSource.chart.showlegend = "1"; 
            let dialogRef = this.dialog.open(FactorsCumulitiveTotalReturnChartComponentDialog, {
                width: '80%',
                height: '85%',
            data: this.popupDataSource
            });
        }
    }

    @Component({
        selector: 'factors-cumulitive-total-return-chart-dialog',
        templateUrl: './factors-cumulitive-total-return-chart.component.dialog.html'
    })
    export class FactorsCumulitiveTotalReturnChartComponentDialog{
    
        constructor(
        public dialogRef: MatDialogRef<FactorsCumulitiveTotalReturnChartComponentDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }
    
        onClose(): void {
            this.data.dataSource.chart.showlegend = "0";
            this.dialogRef.close();
      }
    }
    
    
    @Component({   
        templateUrl: './factors-cumulitive-total-return-chart.component.widget.dialog.html'
      })
      export class FactorsCumulitiveTotalReturnChartComponentWidgetDialog{
      
      @Input() indexes: string;
      constructor(
      public dialogRef: MatDialogRef<FactorsCumulitiveTotalReturnChartComponentWidgetDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
        this.indexes="";
         data.forEach(element => {
             if( this.indexes.length<3)
             {
                this.indexes=element;
             }else{
            this.indexes += ", "+ element;
             }
         });   
       }
      
      onClose(): void {
          this.dialogRef.close();
      }
      }

    

    
    
