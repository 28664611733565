import { Component, OnInit } from '@angular/core';
import { SIDataCalculationService } from '../../services/sidatacalculation.service';
import { SIDataCalculationModel, iControl,iFilter } from '../../models/sidatacalculation';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'si-datacalculation',
  templateUrl: './si-datacalculation.component.html',
  styleUrls: ['./si-datacalculation.component.scss']
})
export class SIDataCalculationComponent implements OnInit {
  isDisabled: boolean = false;
  sidatacalculation: SIDataCalculationModel;  
  
  controls: iControl[] = [];
  filter: iFilter;
  isVisible :boolean = false ;
  feedNames: string[];
  seletcedasOfDate: string;
  selectedFeed: string;
  unsub: any;
  public feedNamefilteredList: string[];
  passphrase: string = "EncryptionatRest";
  dataSource: any;

  constructor(private sidatacalculationservice: SIDataCalculationService) {
    this.unsub = this.sidatacalculationservice.clientPublish().subscribe((m: any) => {
      alert(m);
      this.GetSIFeeds();

    });
}
  ngOnInit(): void {
     if (sessionStorage.getItem("UserRoles").indexOf(environment.siAdminGroup) != -1) {
      this.isVisible=true;}
        //enable add button for testing in dev
         this.isVisible=true;
       //end
        this.GetSIFeeds();
  } 
  GetSIFeeds() {
    this.sidatacalculationservice.FetchSIFeeds().subscribe((res) => {
      console.log(res.data);
      this.feedNames = res.data;
      this.feedNamefilteredList = res.data;
      console.log(this.feedNamefilteredList)
    });
  }
 getFeedStatus()
 { this.sidatacalculationservice.publishClient("Feed Status Refreshed"); }
  
  dateChange(date: string ) { 
    if (date!= undefined) 
      {
    this.seletcedasOfDate=date;
      }
      else
      {this.seletcedasOfDate=undefined;} 
  }

  triggerFeed() {
    if (this.selectedFeed == undefined) 
      {
       alert('Please Select FeedName');
        return;
      }
      else if (this.seletcedasOfDate==undefined || this.seletcedasOfDate=='')
        {
        alert('Please Enter AsOfDate');
          return;
        }
       alert("Feed Triggered");
    this.sidatacalculationservice.fetchTriggerFeedData$(this.selectedFeed ,this.seletcedasOfDate).subscribe((res) => {
     console.log(res.data);
     this.sidatacalculationservice.publishClient("Triggered Feed Completed");
    });
    
  }
  onfeedNameChange(e) {
    console.log(e);
    if (e != undefined) {
      this.selectedFeed = e.feedName;   
      
    }
    else {
      this.selectedFeed = undefined;    
    }

  }  
  ngAfterViewInit() {
    let yourElem = <HTMLElement>document.querySelector('.fa-angle-double-left');
    if (yourElem != null) {
      yourElem.click();
    }
  }
}
