import { Injectable } from '@angular/core';
import { NewReportForm, Report, ReportData, ExportReportDetails, ExportReportDetailsColumns, ExportReportData } from '../models/report';
import { BehaviorSubject, Observable, of, from, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NeweupabindexForm } from '../models/eupabindex';
import { ResponseModel } from '../../models/landing-page-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { debug } from 'console';


@Injectable({
  providedIn: 'root'
})
export class eupabindexDataService {

  public isFormValid = true;
  constructor(private httpClient: HttpClient) { }

  private _listners = new Subject<any>();
  clientPublish(): Observable<any> {
    return this._listners.asObservable();
  }


  publishClient(client: string) {
    this._listners.next(client);
  }

  fetchReports$(pageIndex, pageSize): Observable<any> {
    return from(this.GeteupabindexData(pageIndex, pageSize));
  }
  fetcheupabindexData$(pageIndex, pageSize,filter): Observable<any> {
    return from(this.GeteupabindexDataByFilter(pageIndex, pageSize,filter));

  }
  
  createupabindex(eupabindexForm: NeweupabindexForm): Observable<string> {
    this.saveNeweupabindex(eupabindexForm).then((a) => {
      if (a.data != 0) {
        //this.publishClient("created eupabindex data successfully!");
      }
      else {
        //this.publishClient(a.meta.message);
      }
    });
    return of(eupabindexForm.IndexCode);

  }

  updateeupabindexIndex(eupabindexForm: NeweupabindexForm): Observable<string> {
    this.saveeupabindex(eupabindexForm).then((a) => {
      if (a.data != 0) {
        // this.publishClient("Updated eupabindex data successfully!");
      }
      else {
        // this.publishClient(a.data.message);
      }
    });
    return of(eupabindexForm.IndexCode);
  }

  validateeupabindex(indexCode: string): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/ValidateEUPABIndex', JSON.stringify({ indexCode: indexCode }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data
      }).catch(this.handleErr);
  }
 
  saveNeweupabindex(eupabindexForm: NeweupabindexForm): any {
    if (eupabindexForm.AsofDate != undefined ? eupabindexForm.IndexCode.toLowerCase() != eupabindexForm.IndexCode.toLowerCase() : true) {
      this.validateeupabindex(eupabindexForm.IndexCode).then((res) => {
        if (res) {
          alert('eupabindex data already exists!');
        }
        else {   
          this.isFormValid = true;
          let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          return this.httpClient.post(
            environment.dataServiceRoot + 'ui/SITableConfigurations/AddEUPABIndex',
            JSON.stringify({
              IndexCode: eupabindexForm.AsofDate,
              ParentIndexCode: eupabindexForm.IndexCode
            }),

            { headers: headers }).toPromise()
            .then(response => {
              var result = response as ResponseModel;
              if (result.data != 0) {
                this.publishClient("eupabindex data created successfully!");
              }
              else {
                this.publishClient(result.meta.message);
              }
              
            }).catch(this.handleErr);
        }
      });
    }
    else {
      alert('Index code and Parent Index code must be different');
    }
  }

  saveeupabindex(eupabindexForm: NeweupabindexForm): any {

    if (eupabindexForm.AsofDate != undefined ? eupabindexForm.IndexCode.toLowerCase() != eupabindexForm.AsofDate.toLowerCase() : true) {
      this.isFormValid = true;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(
        environment.dataServiceRoot + 'ui/SITableConfigurations/UpdateEUPABIndex',
        JSON.stringify({
          AsofDate: eupabindexForm.AsofDate,
          IndexCode: eupabindexForm.IndexCode,
          Yoy_Decarbonisation_Trajectory: eupabindexForm.Yoy_Decarbonisation_Trajectory,
          Excess_S_Decarbonisation: eupabindexForm.Excess_S_Decarbonisation,
          Active_Share_Level: eupabindexForm.Active_Share_Level ,
          Carbon_Reduction_Target_Alignment: eupabindexForm.Carbon_Reduction_Target_Alignment  ,
          Emission_Intensity: eupabindexForm.Emission_Intensity         
        }),

        { headers: headers }).toPromise()
        .then(response => {
          var result = response as ResponseModel;
          if (result.data != 0) {
            this.publishClient("Updated eupabindex data successfully!");
          }
          else {
            this.publishClient(result.meta.message);
          }
        }).catch(this.handleErr);
    } else {
      alert('Index code and Parent Index code must be different');
    }
  }

  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }



  GeteupabindexData(pageIndex, pageSize): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EUPABIndexDataList', JSON.stringify({ page: pageIndex, pageSize: pageSize }),
      { headers: headers });
  }
   
  GeteupabindexDataByFilter(pageIndex, pageSize, filter): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EUPABIndexListByFilter', JSON.stringify({ pageSize: pageSize, page: pageIndex, filter: filter }),
      { headers: headers });
  }
  GetindexList(): Observable<any> {
   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EUPABIndexList',null,
     { headers: headers });
  }


  deleteeupabindexData(eupabindexForm: any): any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DeleteEUPABIndex',
      JSON.stringify({
        IndexCode: eupabindexForm.IndexCode,
        AsofDate:eupabindexForm.AsofDate
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        this.publishClient("eupabindex data has been deleted");
        return result;
      }).catch(this.handleErr);
  }

  downloadExcel(IndexCode: string): Promise<any> {
    debugger;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DownloadEUPABIndexExcel',
      JSON.stringify({
        IndexCode: IndexCode
      }),
      { headers: headers }).toPromise()
      .then(response => {
          var result = response;
          return result;
    })
        .catch (this.handleErr);
  }

  }

