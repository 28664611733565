import {Component,ViewChild,OnInit,OnDestroy} from '@angular/core';
import { Router} from '@angular/router';
import {S3ManagerService} from '../../services/s3-manager.service';
import { environment } from '../../../environments/environment';
import {ReportService} from '../../services/report.service';
import {ReportFileDownloadModel} from '../../models/report-file-download.model';
import {SanetizeStringService} from '../../services/sanetize-string.service';
import { clearInterval } from 'timers';
import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'report-file-download',
    templateUrl: './report-file-download.component.html'
})
export class ReportFileDownloadComponent{
    ReportFileDownloadLoader:boolean =true; 
    userRequests:ReportFileDownloadModel[] = [];
    systemRequests:ReportFileDownloadModel[] = [];
    source:string = "Reissue";
    private s3Credentials:any;
    private bucketFolder = "unload/";
    private bucketDelimiter = "/";
    refreshTimer:any;
    private correlationIdArray: string[] = [];
    private correlationIdLinkArray:string[] = [];
    passphrase: string = "EncryptionatRest";
    constructor(private s3ManagerService:S3ManagerService, 
    private reportService:ReportService,private router:Router, private sanetizeStringService:SanetizeStringService){}
    ngOnInit(){
        this.ReportFileDownloadLoader = true;
           this.refreshGrid();
    }

    ngOnDestroy(){
        
    }
    private refreshGrid(){
        this.userRequests = [];
        this.systemRequests = [];
            var userLoginDetails = JSON.parse(sessionStorage.getItem("LoginDetails"));
            this.s3Credentials = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('RestMessage'),this.passphrase).toString(CryptoJS.enc.Utf8));
            var availbleS3Objects = this.s3ManagerService.getFileListForSpecificFolderInBucket(
                environment.downloadBucket
                ,this.bucketFolder, this.bucketDelimiter,this.s3Credentials).then(res=>{
               var s3Objects = res;   
               var userId = (null!= userLoginDetails["userId"] && undefined!= userLoginDetails["userId"])?
                   this.sanetizeStringService.sanitizeStringLiteral(userLoginDetails["userId"]):
                   this.sanetizeStringService.sanitizeStringLiteral(userLoginDetails["_value"]);          
               for(let s3Object of s3Objects){
                   
                   if(-1!= s3Object.Key.indexOf(userId)){
                       var request = new ReportFileDownloadModel();
                       var lastDate = new Date(s3Object.LastModified);
                       
                        var correlationId = this.createRequestId(s3Object.Key);                       
                        this.correlationIdArray.push(correlationId);
                        this.correlationIdLinkArray[correlationId] = this.createLink(s3Object.Key);

                      
                   }
               }
               if(this.correlationIdArray.length >0 ){
                   this.reportService.gettFileUloadModelList(this.correlationIdArray,userId).then(
                       reportDownloadDictionary=>{
                        for(var entry in reportDownloadDictionary){
                            var request = new ReportFileDownloadModel();
                            var fileDate = new Date(reportDownloadDictionary[entry].creationDate);
                            request.date = (fileDate.getMonth()+1).toString()+"/"+
                            fileDate.getDate().toString()+"/"+ fileDate.getFullYear().toString();
                            request.link = this.correlationIdLinkArray[reportDownloadDictionary[entry].correlationId];
                            request.requestId = reportDownloadDictionary[entry].correlationId;
                            request.loginId = reportDownloadDictionary[entry].userId;
                            request.requestType = reportDownloadDictionary[entry].requestType;
                            request.isDownloaded = reportDownloadDictionary[entry].isDownloaded;
                            request.enabler = reportDownloadDictionary[entry].enabler;
                            var tmpStartDate = "";
                            var tmpEndDate = "";
                            var tmpIndexMarkers = "";
                            var tmpTimePeriod = "";
                           var queryParamsArray = JSON.parse(JSON.stringify(reportDownloadDictionary[entry].queryParameters));
                            for(var paramCounter=0; paramCounter<reportDownloadDictionary[entry].queryParameters.length;paramCounter++){
                                if( reportDownloadDictionary[entry].queryParameters[paramCounter].name == "startDate"){
                                    tmpStartDate = reportDownloadDictionary[entry].queryParameters[paramCounter].value.split(" ")[0];
                                }
                                if( reportDownloadDictionary[entry].queryParameters[paramCounter].name == "endDate"){
                                    tmpEndDate = reportDownloadDictionary[entry].queryParameters[paramCounter].value.split(" ")[0];
                                }
                                if( reportDownloadDictionary[entry].queryParameters[paramCounter].name == "indexMarkers"){
                                    tmpIndexMarkers = reportDownloadDictionary[entry].queryParameters[paramCounter].value;
                                }
                                if( reportDownloadDictionary[entry].queryParameters[paramCounter].name == "timePeriod"){
                                    tmpTimePeriod = reportDownloadDictionary[entry].queryParameters[paramCounter].value;
                                }
                            }
                            request.startDate = tmpStartDate;
                            request.endDate = tmpEndDate;
                            request.indexMarkers = tmpIndexMarkers;
                            request.timePeriod = tmpTimePeriod;
                            console.log("request type: "+request.requestType+" enabler: "+request.enabler);
                            if(reportDownloadDictionary[entry].source != this.source){                        
                                this.userRequests.push(request);
                            }else{
                               this.systemRequests.push(request);
                            }
                            
                        }
                        this.ReportFileDownloadLoader = false;
                       }
                   ).catch(err => {
                    this.ReportFileDownloadLoader = false;
                       console.log(err);
                   });
               }else{
                this.ReportFileDownloadLoader = false;
               }
            });
    }

    ReissueOnDemand(req:any){
          var userId = this.getUserId();
          this.reportService.reissue(userId,req).then(res=> {

          }).catch(err =>{
              console.log(err);
          });
    }

    private createRequestId(key:string):string{
         var keyParts = key.split("_");
         return keyParts[1];
        
    }

    private createLink(key:string):string{
        return environment.awsBasicUrl + environment.downloadBucket + "/"+key;
    }

    RouteToS3Download(req:ReportFileDownloadModel):void{
        var data = {
            date: req.date,
            requestId: req.requestId,
            requestType:req.requestType,
            link:req.link,
            startDate:req.startDate,
            endDate:req.endDate,
            indexMarkers:req.indexMarkers,
            timePeriod : req.timePeriod,
            isDownloaded:req.isDownloaded,
            enabler:req.enabler
         }
       this.router.navigate(['/report-s3-file-download'],{queryParams: {request:JSON.stringify(data)}});
    }
		
		getUserId(){
        var userLoginDetails = JSON.parse(sessionStorage.getItem("LoginDetails")); 
        console.log("userLoginDetails: "+JSON.stringify(userLoginDetails));
        var userId = (null!= userLoginDetails["userId"] && undefined!= userLoginDetails["userId"])?
                   this.sanetizeStringService.sanitizeStringLiteral(userLoginDetails["userId"]):
                   this.sanetizeStringService.sanitizeStringLiteral(userLoginDetails["_value"]);
                   return userId;
    }
}