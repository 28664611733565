import {catchError, debounceTime, distinctUntilChanged, tap, switchMap, merge} from 'rxjs/operators';
import { Component,OnInit, EventEmitter,Output,ViewChild, Input} from '@angular/core';
import { BehaviorSubject , of, Observable} from 'rxjs';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { SearchResultModel } from '..//../models/search-model'; 
import { SearchSet } from '..//../models/search-model'; 
import { myfilter } from '..//../models/search-model'; 
import {MagellanColorService} from '../../services/magellancolor.service';
import { SearchService } from '../../services/search.service'
import { indexNamesModel } from '../../models/indexNames.model';
import {DateService } from '../../services/date.service';
import { environment } from '../../../environments/environment';
import { EnvSpecific } from '..//../models/env-specific';
import {DragDropModule, CdkDragDrop} from '@angular/cdk/drag-drop';
import { BiService } from '../../services/BiService.service';
import { BiEvent, Events, InputParameter } from '../../models/events';
import {CurrencyService} from '../../services/currency.service';
import { LandingService } from '../../services/landing.service';
import {UserTabChangeService} from '../../services/user-tab-change.service';
import {UINotificationService} from '../../services/ui-notification.service';
import {QueueStatusModel} from '../../models/queue-status-model';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import {MatSelect} from '@angular/material/select';
import{StartupPageComponent} from '../startup-page/startup-page.component';
import { LZStringService } from 'ng-lz-string';
import { Crypt, RSA } from "hybrid-crypto-js";
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ReferenceService } from '../../services/reference.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'user-profile-toolbar',
     templateUrl: './user-profile-toolbar.component.html',
     styleUrls: ['./user-profile-toolbar.component.css','./user-profile.component.scss']
   
})
export class UserProfileToolbarComponent implements OnInit {
url:string;
    constructor(private dateService : DateService,private searchService: SearchService,
        private indexMarkerService: IndexMarkerService,private publishIndexMarkerService:PublishIndexMarkerService,
        private magellanColorService : MagellanColorService, private biService: BiService,
        private dragulaService: DragDropModule,private currencyService:CurrencyService,private route: ActivatedRoute,
        private landingService: LandingService,private userTabChangeService:UserTabChangeService,
        private uiNotificationService:UINotificationService,private lz: LZStringService,public router:Router,private referenceService: ReferenceService){
            
        } 
        @Input() comp:StartupPageComponent;
     
        selectable = true;
        removable = true;
    selectedIndex: any;
    public data: Array<any>;
    currentIndex:any;
    cancel:any;
    userId: string;
    indexArray:any;
    firstValueIndexName:any;
    indexMarkers:any;
    indexNameArray: any;
    toDate:any;
    selectedIndexes :any;   
    selectIndex :any;   
    firstValue:string 
    addIndexes : any;
    searching = false;
    searchFailed = false;
    emailAddress="IDWSupport@FTSERussell.com";
    hideSearchingWhenUnsubscribed = new Observable(() => () => this.searching = false);
    @Output() getLoginStatusChange = new EventEmitter<boolean>();
    @Output() removeTabSelection = new EventEmitter<boolean>();
    @Output() columnSelection: EventEmitter<any[]> = new EventEmitter();
    supportedPeriod : any[]  = [{ "key": "D", "value": "DAILY" }, { "key": "M", "value": "MONTHLY" }, { "key": "Q", "value": "QUARTERLY" }, { "key": "Y", "value": "YEARLY" }]; 
    defaultActivePeriod : string = "DAILY";
    @Output() notifyDate: EventEmitter<any> = new EventEmitter<any>();
    @Output() timePeriod: EventEmitter<string> = new EventEmitter();
    @Output() collapseEvent: EventEmitter<string> = new EventEmitter();
    currencies: any[]= [ { "key": "", "value": "Loading" }];   
    defaultActiveCurrencyCode:string="USD";
    currencyList:any;
    defaultTabDataSourceName: string = "IndexReturns";
    availableFiles: QueueStatusModel[] = [];
    queueStatusCount :string = "";
    reissueQueueStatus: string = "";
    source:string = "Reissue";@ViewChild(NgbDropdown,{static: false})
    private dropdown: NgbDropdown;
    publicKey:string = `-----BEGIN PUBLIC KEY-----
    MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAoFflTjsq9XsgTyq5G7oG
    QC/6fIfRvr00QXyqEQPCdYK+8gsSf+16ReH6qQ0VdIDIK98c0LCBvqmyAO4cFywh
    UMRMt8GZlA27PUBQmhK2c786cY6lH6c+G+W2HkB2g/TCoKsgnunWB7aILKcJg1dR
    p4ON8GvbcSPJNLKDNnK1Us+9EoEHAwvdsJoENYVf/kGdp//3TVV8ylO4Q+s/K3cf
    eQouWj775L6pTp1oa/6u4umWJ4MAQTkCTl993he8EPVCb/uv5mF/dM2iWLH/HgM+
    YYJlsod2ZO1BXX+GF1RsGw7Ng/AK0EvKsliL5VvDX03a66RAxRe1vu6Z5B4JrZFv
    bpqVHti/yfHltID9XBlkpZ047JJTuXWr1/9nx0cPX6GNyiWSpif0fS9iBbOh6Kdm
    J1hvl7blssAZvD8g7giRtnZPS8p1SFvioIETVLeDclOo9M9SDL7hcEqX4tLBYEaa
    koAx31SkOIMr0U22ejntkergdtlPy1AS2D6aU7SY5W3ipwTiMyZin7BTr5ETdlNT
    YqyIXDz0AabGf8nHt7SFiV5yPPkitgF0ySTj2/VBlubDGvMdISZW7tC5vzMvBaX1
    aN9SKSTBetjPWmMKGf32LY/UHH+OlsNzs2yUnPFZ3j3qXEqyTukmmKL0NvGsfmLz
    Lqwbi85O5VlLZe5dMzUsJrECAwEAAQ==
    -----END PUBLIC KEY-----`;
    ngOnInit(){       
        this.filterCurrencyCode('USD');
        this.userId = sessionStorage.getItem('LoggedInUser');
        this.indexMarkers = this.indexMarkerService.getIndexMarkers();       
        this.userTabChangeService.getTabDataSourceName().subscribe(tabDataSourceName => {            
            this.toDate =this.dateService.getCalendarDate().end;
            this.defaultTabDataSourceName = tabDataSourceName;
            this.currencyList= this.landingService.getCurrencies(this.indexMarkers,this.toDate,
                this.defaultTabDataSourceName).then(data=>{       
                    this.currencies=(data.length>0)? data: [ { "key": "USD", "value": "Currency not found" }];               
                }); 
        });
     
        this.currencyList= this.landingService.getCurrencies(this.indexMarkers,this.dateService.getCalendarDate().end,
        this.defaultTabDataSourceName).then(data=>{
          this.currencies=(data.length>0)? data: [ { "key": "USD", "value": "Currency not found" }];               
      });
        this.dateService.calendarDatePublish().subscribe(dateRange=>{           
            this.toDate = dateRange.end;          
            this.currencyList= this.landingService.getCurrencies(this.indexMarkers,this.toDate,
            this.defaultTabDataSourceName).then(data=>{                                          
                this.currencies=(data.length>0)? data: [ { "key": "", "value": "Currency not found" }];               
            });            
        });
        this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
            this.selectedIndexes =result;
            this.selectIndex = result;
          });
          this.publishIndexMarkerService.indexMarkerNamesPublish().subscribe(result=>{ 
            this.indexArray =result;
            this.cleanIndexArray();
          });

          this.uiNotificationService.publishedUiNotifications().subscribe(uiAlerts =>{
            this.availableFiles = uiAlerts;
            var userDownloadCount = 0;
            for(var counter =0; counter < this.availableFiles.length;counter++){
                if(this.availableFiles[counter]["source"] == this.source){
                    this.reissueQueueStatus = "!";
                }else{
                    userDownloadCount++;
                }
            }
            this.queueStatusCount = userDownloadCount.toString();
            console.log(this.queueStatusCount);
        });
          
        this.selectedIndexes = this.indexMarkerService.getIndexMarkers();  
        this.selectIndex = this.indexMarkerService.getIndexMarkers();
        console.log(this.selectIndex) 
        this.biService.GlobalInputs.IndexMarkers = this.selectedIndexes.toString();
        this.firstValue = this.selectedIndexes[0];   
        this.indexArray=this.indexMarkerService.getIndexMarkersNames();
        this.cleanIndexArray();
        this.firstValueIndexName=this.indexArray[0];       
        this.timePeriod.emit(this.defaultActivePeriod);
       
        
  
       
                      
    }
    homeRoute() {
   }
         
        
    drop(event: CdkDragDrop<string[]>){
        if(event.currentIndex < event.previousIndex){
            for(var switchCounterDesend = (event.previousIndex -1); switchCounterDesend >= event.currentIndex;
             switchCounterDesend--)
            {
                this.selectedIndexes[switchCounterDesend+1] = this.selectedIndexes[switchCounterDesend];
            }
            
        }
        else{
            for(var switchCounterAscend = event.previousIndex;switchCounterAscend < event.currentIndex; 
                switchCounterAscend++)
                {
                    this.selectedIndexes[switchCounterAscend] = this.selectedIndexes[switchCounterAscend+1];
                }
        }
        this.selectedIndexes[event.currentIndex] = event.item.element.nativeElement.innerHTML.split(" ")[0];
        this.firstValue = this.selectedIndexes[0];   
        this.rearrangIndexArray();
        this.firstValueIndexName=this.indexArray[0];  
        this.indexMarkerService.publish(this.selectedIndexes);
        this.indexMarkerService.publishIndexNames(this.indexArray); 
    }

    removeSelection()
    {
        this.defaultTabDataSourceName ="IndexReturns";
        
        this.currencyList= this.landingService.getCurrencies(this.indexMarkers,this.dateService.getCalendarDate().end,
        this.defaultTabDataSourceName).then(data=>{
          this.currencies=(data.length>0)? data: [ { "key": "USD", "value": "Currency not found" }];               
        });
        this.removeTabSelection.emit(true);
    }

    search = (text$: Observable<string>) =>
    
    text$.pipe(
        
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      
      switchMap(term =>
        this.searchService.search(term).pipe(
          tap((response) => {   
                         this.selectedIndex = term;
                         this.referenceService.setIndexMarker(this.selectedIndex);
            this.searchFailed = false;
        }),
        
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }),
          )),
      tap(() => 
      {
          this.searching = false;
    }),
      merge(
          this.hideSearchingWhenUnsubscribed
    ),
    
   );
     selectItem(event: any)
     {console.log(event)
        if(this.selectedIndexes.length < 6)
        { console.log(this.indexArray)
            var indexMarker = event.item.split('-');
            if(!this.selectedIndexes.includes(indexMarker[0]))
        {
            this.selectedIndexes.push(indexMarker[0]); console.log(this.indexArray)
        } console.log(this.indexArray)
            this.biService.GlobalInputs.IndexMarkers = this.selectedIndexes.toString();
            var event: any = {eventName: "Add Search Tags"};
            this.biService.addEvent(event);
            this.indexMarkerService.publish(this.selectedIndexes);
            this.indexArray.push({index:indexMarker[0],indexName:indexMarker[0]});
            console.log(this.indexArray)
            this.indexMarkerService.publishIndexNames(this.indexArray);
        }
     }
     onCheck(index:any, event: any){
        // if(isChecked) {
         //   this.indexArray.push(index);console.log(this.indexArray)
         console.log(index)
         this.selectedIndexes.push(index[0])
         this.indexMarkerService.publish(this.selectedIndexes);
         this.indexArray.push({index:index[0],indexName:index[0]});
            console.log(this.indexArray)
            this.indexMarkerService.publishIndexNames(this.indexArray);
        //   } else {
        //     let index1 = this.indexArray.indexOf(index);console.log(this.indexArray)
        //     this.indexArray.splice(index1,1);
        //    }
  //  var indexMarker = event.item.split('-');
   // this.indexMarkerService.publish(index);
    // this.indexArray.push({index:indexMarker[0],indexName:indexMarker[0]});
    // console.log(this.indexArray)
    // this.indexMarkerService.publishIndexNames(this.indexArray);
  }
     apply(){
      //  window.location.href;
     
     }
     hover(event):void{
       
        let key=event.target.innerText.substring(0,(event.target.innerText.length-2)).trim();
        let index=null;
    
        for (let indexNames of this.indexArray)
        {
            if (key.startsWith(indexNames.index.trim()))
            {
                index=indexNames.indexName;
            }
        }
        if(key !=""){
               this.currentIndex=index;
               this.cancel="cancel";
            }
     }   
    delete(event,value:string):void{        
        const index = this.selectedIndexes.indexOf(value);
        if(event.target.checked){
            var indexMarker = event.item.split('-');
            this.selectedIndexes.push(value[0]); this.indexMarkerService.publish(this.selectedIndexes);
            this.indexArray.push({index:value[0],indexName:value[0]});
            console.log(this.indexArray)
            this.indexMarkerService.publishIndexNames(this.indexArray);
        }else{
           
            if(this.selectedIndexes.length > 1)
            {
            if (index >=0) {
              
              this.selectedIndexes.splice(index, 1);
              for(var i in this.indexArray)
              {
                  if(this.indexArray[i].index.indexOf(value) >=0){
                    var indexArrayDeleteIndex = i;
                    this.indexArray.splice(indexArrayDeleteIndex,1);
                    this.indexMarkerService.publish(this.selectedIndexes);
                    this.indexMarkerService.publishIndexNames(this.indexArray);
                  }    }
              }
            }
        }
        this.biService.GlobalInputs.IndexMarkers = this.selectedIndexes.toString();
        this.landingService.getCurrencies(this.selectedIndexes,this.toDate,
        this.defaultTabDataSourceName).then(data=>{                                            
            this.currencies=(data.length>0)? data: [ { "key": "", "value": "Currency not found" }];               
        });   
     }

     add():void{
       if(this.selectedIndexes.length >6){
           this.selectedIndexes.splice(this.selectedIndexes.length - 1,1); 
       } else {
        this.indexMarkerService.publish(this.selectedIndexes);
       }
       this.landingService.getCurrencies(this.selectedIndexes,this.toDate,
    this.defaultTabDataSourceName).then(data=>{                                            
        this.currencies=(data.length>0)? data: [ { "key": "", "value": "Currency not found" }];               
    }); 
     }

     rearrangIndexArray(){
         var newIndexArray = [];
         for(var counter = 0; counter <this.selectedIndexes.length;counter++){
             newIndexArray.push(this.indexArray.find(elem=>elem.index == this.selectedIndexes[counter]));
         }
         this.indexArray = newIndexArray;
     }

     cleanIndexArray(){
         for(var counter = this.indexArray.length-1; counter >=0; counter--){
             if(null == this.indexArray[counter] || undefined == this.indexArray[counter]
            || "" == this.indexArray[counter]){
                this.indexArray.splice(counter,1);
            }
         }
     }

     changeColorSchema(value:string):void{
        this.magellanColorService.setCurrentColorSchemaIdentifier(value);
        this.magellanColorService.publishColor();
     }

     changeDateFormat(value:string):void{
        this.dateService.setDateFormat(value);     
        this.dateService.publishDateFormat();   
     }


     signout():void {        
        sessionStorage.removeItem('LoginDetails');
        sessionStorage.removeItem('LoggedInUser');
        sessionStorage.removeItem('apiToken');
        sessionStorage.removeItem('AWSCredentials');
        sessionStorage.removeItem('apiURL');
        sessionStorage.removeItem('PWD');
        sessionStorage.removeItem('UserRoles');
        
        if(sessionStorage.getItem('CognitoAuthenticated') != null) {
            sessionStorage.removeItem('CognitoAuthenticated');
            this.getLoginStatusChange.emit(false);
        }
    
        if(localStorage.getItem('ADFSAuthenticated') != null) {
            localStorage.removeItem('ADFSAuthenticated');   
          //  localStorage.removeItem("samlResponse");
            window.document.cookie.split(";").forEach(function(c) { 
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");});
            var logoutURL = environment.adfsLogoutUrl + environment.relayingPartyId;        
            window.location.href = logoutURL;     
            this.getLoginStatusChange.emit(false);    
        }    
        localStorage.removeItem('samlResponse');   
        sessionStorage.removeItem('LoggedInUser');  
        sessionStorage.removeItem('PWD');
     }
    encryptMessage(message:string):any{
        var crypt = new Crypt({  });
        var rsa = new RSA({});
        var encryptedMessage = crypt.encrypt(this.publicKey,message);
        return encryptedMessage;
    }
     onNotifyDate(value:any){  
         this.notifyDate.emit(value);
      }

      getPeriodCSSClass(period:string){
        return (period == this.defaultActivePeriod) ? "active" : "currencyLinks";
      }
    
      filterPeriod(period: string) {   
        this.defaultActivePeriod = period;     
        var event: any = {eventName: "When changing the Period", inputParameters: [{Name: "Period", Value: period }]};
        this.biService.addEvent(event);
        this.timePeriod.emit(this.defaultActivePeriod);
        this.dateService.setTimePeriod(period);
      
      }
      filterCurrencyCode(currencyCode: string) {   
        this.defaultActiveCurrencyCode = currencyCode;    
        this.currencyService.setCurrencyCode(currencyCode); 
        var event: any = {eventName: "When changing the CurrencyCode", inputParameters: [{Name: "CurrencyCode", Value: currencyCode }]};
        this.biService.addEvent(event);    
            
      }

      showHideMenu(){
           this.collapseEvent.emit('') ;
      }
}
