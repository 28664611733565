import { Component, OnInit, SimpleChanges } from '@angular/core';
import { SedolAlertService } from '../../services/sedol-alert.service';
import { ActivatedRoute } from '@angular/router';
import { SedolAlertModel } from '../../models/sedol-alert-model';
import { isEmptyObject } from 'jquery';
import { AttributeModel } from '../../models/attribute-model';

@Component({
  selector: 'app-sedol-alert',
  templateUrl: './sedol-alert.component.html',
  styleUrls: ['./sedol-alert.component.css']
})
export class SedolAlertComponent implements OnInit {

  sedolData: SedolAlertModel[];
  disabled: boolean =false;
  constructor(private services:SedolAlertService,private route: ActivatedRoute) {  }
  sedolalertmodel: SedolAlertModel;
  commitStatus: any;
  asOfDate:any;
  SelectAPIName:any;
  tableData:any
  dataHeader:any;
  attributes: AttributeModel[];
  saveChangesData:null;
  ngOnInit() {
    this.getApiName(); 
    this.initilize();
    this.sedolalertmodel  = {
           method:"", 
           attribute: "", 
           frequency:"", 
           count:"",
           notificationEmailAddress:"",
           id:"",
          
          };

    var sub = this.route.params.subscribe(params => {
      if(isEmptyObject(params) )
      {  
        this.disabled = false
      } 
      else{
        this.disabled = true
      }

      this.sedolalertmodel.method = params['method'];
      if(params['method'] != undefined) {
        this.getAttributeNames();
      }
      this.sedolalertmodel.attribute = params['attribute'];
      this.sedolalertmodel.frequency = params['frequency'];
      this.sedolalertmodel.count = params['count'];
      this.sedolalertmodel.notificationEmailAddress=params['notificationEmailAddress'];
      this.sedolalertmodel.id = params['id'];
   
      
    });
   
  }
  initilize()
  {
    
       this.commitStatus ="";
  }
  ngOnChanges(changes: SimpleChanges){}

  saveChanges() 
  {
    if(this.sedolalertmodel.method ==null || this.sedolalertmodel.attribute ==null || this.sedolalertmodel.frequency == null ||
   this.sedolalertmodel.count == "" || this.sedolalertmodel.count == null || this.sedolalertmodel.notificationEmailAddress == null)
   {
     alert("Please fill all fields");
     return;
   }
    this.services.SedolAlert(this.sedolalertmodel).then(result => {
      alert("Save Sedol succesfull")
    window.location.href = "/sedol-search"
  
    });
   
  }
   getApiName(){
    this.services.dropDownValue().then(name =>{
      this.dataHeader=name;   
    })
  }

  getAttributeNames(){
    this.services.GetAttributes({Method: this.sedolalertmodel.method}).then(name =>{
      this.attributes=name;
      
    })
  }
 

}
 