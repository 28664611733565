
import {map} from 'rxjs/operators';
import { Injectable }    from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';

import { HeatMapChart } from '../models/performance-heatmap-chart'
import { ChartModel } from '../models/performance-chart-model'
import { MultiSeries } from '../models/performance-multi-series'
import {IndexReturns} from '../models/performance-index-returns'
import {ResponseModel} from '../models/landing-page-model';
import { Subject , BehaviorSubject } from 'rxjs';

import {SanetizeStringService} from '../services/sanetize-string.service';

@Injectable()
export class PerformanceService {
    heatMapChartLoaded = new Subject<HeatMapChart>();
    heatMapChartIsLoading = new BehaviorSubject<boolean>(false);
    heatMapChartFailed = new Subject<boolean>();

    timeSeriesChartLoaded = new Subject<ChartModel>();
    timeSeriesChartIsLoading = new BehaviorSubject<boolean>(false);
    timeSeriesChartFailed = new Subject<boolean>();

    multiSeriesChartLoaded = new Subject<MultiSeries>();
    multiSeriesChartIsLoading = new BehaviorSubject<boolean>(false);
    multiSeriesChartFailed = new Subject<boolean>();

    timeSeriesChartDataLoaded = new Subject<IndexReturns>();
    timeSeriesChartDataIsLoading = new BehaviorSubject<boolean>(false);
    timeSeriesChartDataFailed = new Subject<boolean>();

    heatMapChartDataLoaded = new Subject<IndexReturns>();
    heatMapChartDataIsLoading = new BehaviorSubject<boolean>(false);
    heatMapChartDataFailed = new Subject<boolean>();

    multiSeriesChartDataLoaded = new Subject<IndexReturns>();
    multiSeriesChartDataFailed= new BehaviorSubject<boolean>(false);
    multiSeriesChartDataIsLoading=new Subject<boolean>();


    calendarReturnsChartLoaded = new Subject<ChartModel>();
    calendarReturnsChartIsLoading = new BehaviorSubject<boolean>(false);
    calendarReturnsChartFailed = new Subject<boolean>();

    calendarReturnsChartDataLoaded = new Subject<IndexReturns>();
    calendarReturnsChartDataIsLoading = new BehaviorSubject<boolean>(false);
    calendarReturnsChartDataFailed = new Subject<boolean>();

    indexCalculatorDataByValuesLoaded = new Subject<any[]>();
    indexCalculatorDataByValuesIsLoading = new BehaviorSubject<boolean>(false);
    indexCalculatorDataByValuesFailed = new Subject<boolean>();

    
    indexCalculatorDataByValuesDataLoaded = new Subject<any[]>();
    indexCalculatorDataByValuesDataIsLoading = new BehaviorSubject<boolean>(false);
    indexCalculatorDataByValuesDataFailed = new Subject<boolean>();

    private headers = new Headers({'Content-Type': 'application/json'});
    
    constructor( private httpClient: HttpClient,
    private sanetizeStringService: SanetizeStringService) { 
    }

    getMonthlyPerformanceChart(fromDate: any,toDate: any,indexMarkers : any,timePeriod : any ,currencyCode : any,returnType : any)  {
         let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
         this.heatMapChartLoaded.next(null);
         this.heatMapChartFailed.next(false);
         this.heatMapChartIsLoading.next(true);
        return this.httpClient.post(environment.dataServiceRoot + 'ui/performance/getmonthlyperformancechart',
            JSON.stringify({
                FromDate : fromDate,
                ToDate : toDate,           
                IndexMarkers : indexMarkers,
                TimePeriod :timePeriod,
                CurrencyCode :currencyCode,
                ReturnType : returnType
        }), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
          ))
          .subscribe(
            (data) => {      
              var result = data as ResponseModel;
              var heatMapChart= result.data as HeatMapChart;
              this.heatMapChartLoaded.next(heatMapChart);                  
            },
            (error) => {
              console.log(error);
              this.heatMapChartIsLoading.next(true);
              this.heatMapChartLoaded.next(null);
            });   
    }

    getTimeSeriesChart(asOfDate: any,indexMarkers : any ,currencyCode : any,returnType : any)  {
        
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });        
        this.timeSeriesChartLoaded.next(null);
        this.timeSeriesChartFailed.next(false);
        this.timeSeriesChartIsLoading.next(true);
       return this.httpClient.post(environment.dataServiceRoot + 'ui/performance/gettimeserieschart',
           JSON.stringify({
            AsOfDate : asOfDate,
            IndexMarkers : indexMarkers,            
            CurrencyCode :currencyCode,
            ReturnType : returnType
       }), {headers: headers}).pipe(
       map(
        (response: Response) => 
        response as any
      ))
      .subscribe(
        (data) => {      
          var result = data as ResponseModel;
          var timeSeriesChart = result.data as ChartModel;
          this.timeSeriesChartLoaded.next(timeSeriesChart);                  
        },
        (error) => {
          console.log(error);
          this.timeSeriesChartIsLoading.next(true);
          this.timeSeriesChartLoaded.next(null);
        });    
   }

   getPerformance2DMultiSeriesChart(asOfDate: any,indexMarkers : any,timePeriod : any ,currencyCode : any,returnType : any)  {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });    
    this.multiSeriesChartLoaded.next(null);
    this.multiSeriesChartFailed.next(false);
    this.multiSeriesChartIsLoading.next(true);
   return this.httpClient.post(environment.dataServiceRoot + 'ui/performance/getperformancemultiserieschart',
       JSON.stringify({
        AsOfDate : asOfDate,
        IndexMarkers : indexMarkers,
        TimePeriod :timePeriod,
        CurrencyCode :currencyCode,
        ReturnType : returnType
   }), {headers: headers}).pipe(
   map(
    (response: Response) => 
    response as any
  ))
  .subscribe(
    (data) => {      
      var result = data as ResponseModel;
      var multiSeriesChart = result.data as MultiSeries;
      this.multiSeriesChartLoaded.next(multiSeriesChart);                  
    },
    (error) => {
      console.log(error);
      this.multiSeriesChartIsLoading.next(true);
      this.multiSeriesChartLoaded.next(null);
    });      
}
 getMonthlyPerformanceChartData(fromDate: any,toDate: any,indexMarkers : any,timePeriod : any ,currencyCode : any,returnType : any):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
        this.heatMapChartDataLoaded.next(null);
        this.heatMapChartDataFailed.next(false);
        this.heatMapChartDataIsLoading.next(true);
                return this.httpClient.post(environment.dataServiceRoot + 'ui/performance/getmonthlyperformancechartdata',
                    JSON.stringify({
                        FromDate : fromDate,
                        ToDate : toDate,           
                        IndexMarkers : indexMarkers,
                        TimePeriod :timePeriod,
                        CurrencyCode :currencyCode,
                        ReturnType : returnType
                }), {headers: headers})
                .toPromise()
        .then(response =>{
                return response as any
            })
        .catch(this.handleError);  
    }

    getTimeSeriesChartData(asOfDate: any,indexMarkers : any,timePeriod : any ,currencyCode : any,returnType : any):Promise<any>  {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });        
   return this.httpClient.post(environment.dataServiceRoot + 'ui/performance/GetTimeSeriesChartData',
       JSON.stringify({
        AsOfDate : asOfDate,
        IndexMarkers : indexMarkers,
        TimePeriod :timePeriod,
        CurrencyCode :currencyCode,
        ReturnType : returnType
   }), {headers: headers})
   .toPromise()
        .then(response =>{
                return response as any
            })
        .catch(this.handleError);   
}
getPerformance2DMultiSeriesChartData(asOfDate: any,indexMarkers : any,timePeriod : any ,currencyCode : any,returnType : any):Promise<any>{
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });    
       return this.httpClient.post(environment.dataServiceRoot + 'ui/performance/getperformancemultiserieschartdata',
           JSON.stringify({
            AsOfDate : asOfDate,
            IndexMarkers : indexMarkers,
            TimePeriod :timePeriod,
            CurrencyCode :currencyCode,
            ReturnType : returnType
       }), {headers: headers})
       .toPromise()
       .then(response =>{
               return response as any
           })
       .catch(this.handleError); 
}
     getCalendarReturnsChartData(asOfDate: any,indexMarkers : any,timePeriod : any ,currencyCode : any,returnType : any):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });        
        this.calendarReturnsChartDataLoaded.next(null);
        this.calendarReturnsChartDataFailed.next(false);
        this.calendarReturnsChartDataIsLoading.next(true);
           return this.httpClient.post(environment.dataServiceRoot + 'ui/performance/getcalendarreturnschartdata',
               JSON.stringify({
                   AsOfDate : asOfDate,
                   IndexMarkers : indexMarkers,
                   TimePeriod :timePeriod,
                   CurrencyCode :currencyCode,
                   ReturnType : returnType
           }), {headers: headers})
           .toPromise()
           .then(response =>{
                   return response as any
               })
           .catch(this.handleError); 
    }


    getCalendarReturnsChart(asOfDate: any,indexMarkers : any,currencyCode : any,returnType : any) {
        
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });        
        this.calendarReturnsChartLoaded.next(null);
        this.calendarReturnsChartFailed.next(false);
        this.calendarReturnsChartIsLoading.next(true);
       return this.httpClient.post(environment.dataServiceRoot + 'ui/performance/getcalendarreturnschart',
           JSON.stringify({
               AsOfDate: asOfDate,               
               IndexMarkers : indexMarkers,
               CurrencyCode :currencyCode,
               ReturnType : returnType
       }), {headers: headers}).pipe(
       map(
        (response: Response) => 
        response as any
      ))
      .subscribe(
        (data) => {      
          var result = data as ResponseModel;
          var calendarReturnsChart = result.data as ChartModel;
          this.calendarReturnsChartLoaded.next(calendarReturnsChart);                  
        },
        (error) => {
          console.log(error);
          this.calendarReturnsChartIsLoading.next(true);
          this.calendarReturnsChartLoaded.next(null);
        });      
    }

    queueRequest(startDate:any,endDate:any,timePeriod:any,indexMarkers:any):Promise<any>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var userId =  sessionStorage.getItem("LoggedInUser");
        var sanetizedUser = this.sanetizeStringService.sanitizeStringLiteral(userId);
        return this.httpClient.post(environment.dataServiceRoot + 'ui/Performance/queuerequest',
            JSON.stringify({
                StartDate: startDate,
                EndDate: endDate,
                TimePeriod: timePeriod,
                IndexMarkers: indexMarkers,
                UserId: sanetizedUser
                }),{headers :headers})
        .toPromise()
        .then(response =>{
          console.log(response)  
                return response as any
            })
        .catch(this.handleError);  
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); 
        return Promise.reject(error.message || error);
    }
}

