import { Injectable } from '@angular/core';
import { NewReportForm, Report, ReportData, ExportReportDetails, ExportReportDetailsColumns, ExportReportData } from '../models/report';
import { BehaviorSubject, Observable, of, from, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewSovereignConfigurationForm } from '../models/sovereignconfiguration';
import { NewSovereignConfigurationIndexForm } from '../models/sovereignconfiguration';
import { ResponseModel } from '../../models/landing-page-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { debug } from 'console';


@Injectable({
  providedIn: 'root'
})
export class SovereignConfigurationDataService {

  public isFormValid = true;
  constructor(private httpClient: HttpClient) { }

  private _listners = new Subject<any>();
  clientPublish(): Observable<any> {
    return this._listners.asObservable();
  }


  publishClient(client: string) {
    this._listners.next(client);
  }

  fetchReports$(pageIndex, pageSize): Observable<any> {
    return from(this.GetSovereignConfigurationData(pageIndex, pageSize));
  }
  fetchSovereignConfigurationData$(pageIndex, pageSize,filter): Observable<any> {
    return from(this.GetSovereignConfigurationDataByFilter(pageIndex, pageSize,filter));

  }
  
  creatSovereignConfiguration(sovereignConfigurationForm: NewSovereignConfigurationForm): Observable<string> {
    this.saveNewSovereignConfiguration(sovereignConfigurationForm).then((a) => {
      if (a.data != 0) {
        //this.publishClient("created esgconfiguration data successfully!");
      }
      else {
        //this.publishClient(a.meta.message);
      }
    });
    return of(sovereignConfigurationForm.indexCode);

  }

  updateSovereignConfiguration(sovereignConfigurationForm: NewSovereignConfigurationForm): Observable<string> {
    this.saveSovereignConfiguration(sovereignConfigurationForm).then((a) => {
      if (a.data != 0) {
        // this.publishClient("Updated esgconfiguration data successfully!");
      }
      else {
        // this.publishClient(a.data.message);
      }
    });
    return of(sovereignConfigurationForm.indexCode);
  }

  validateSovereignConfiguration(indexCode: string, parentIndexCode:string): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/ValidateSovereignConfiguration', JSON.stringify({ indexCode: indexCode, parentIndexCode: parentIndexCode }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data
      }).catch(this.handleErr);
  }
 
  saveNewSovereignConfiguration(sovereignConfigurationForm: NewSovereignConfigurationForm): any {
    if (sovereignConfigurationForm.parentIndexCode != undefined ? sovereignConfigurationForm.indexCode.toLowerCase() != sovereignConfigurationForm.parentIndexCode.toLowerCase() : true) {
      this.validateSovereignConfiguration(sovereignConfigurationForm.indexCode, sovereignConfigurationForm.parentIndexCode).then((res) => {
        if (res) {
          alert('Sovereign Configuration data already exists!');
        }
        else {   
          this.isFormValid = true;
          let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          return this.httpClient.post(
            environment.dataServiceRoot + 'ui/SITableConfigurations/AddSovereignConfiguration',
            JSON.stringify({
              IndexCode: sovereignConfigurationForm.indexCode,
              ParentIndexCode: sovereignConfigurationForm.parentIndexCode,
              Currency: sovereignConfigurationForm.currency                          
            }),

            { headers: headers }).toPromise()
            .then(response => {
              var result = response as ResponseModel;
              if (result.data != 0) {
                this.publishClient("SovereignConfiguration data created successfully!");
              }
              else {
                this.publishClient(result.meta.message);
              }
              
            }).catch(this.handleErr);
        }
      });
    }
    else {
      alert('Index code and Parent Index code must be different');
    }
  }

  saveSovereignConfiguration(sovereignConfigurationForm: NewSovereignConfigurationForm): any {

  if (sovereignConfigurationForm.parentIndexCode !=undefined ? sovereignConfigurationForm.indexCode.toLowerCase() != sovereignConfigurationForm.parentIndexCode.toLowerCase() : true) {
      this.isFormValid = true;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(
        environment.dataServiceRoot + 'ui/SITableConfigurations/UpdateSovereignConfiguration',
        JSON.stringify({
          IndexCode: sovereignConfigurationForm.indexCode,
          ParentIndexCode: sovereignConfigurationForm.parentIndexCode,
          Currency: sovereignConfigurationForm.currency          
        }),

        { headers: headers }).toPromise()
        .then(response => {
          var result = response as ResponseModel;
          if (result.data != 0) {
            this.publishClient("Updated SovereignConfiguration data successfully!");
          }
          else {
            this.publishClient(result.meta.message);
          }
        }).catch(this.handleErr);
    } else {
      alert('Index code and Parent Index code must be different');
    }
  }

  fetchReportsSovereign$(pageIndex, pageSize): Observable<any> {
    return from(this.GetSovereignConfigurationIndexData(pageIndex, pageSize));
  }
  fetchSovereignConfigurationIndexData$(pageIndex, pageSize, filter): Observable<any> {
    return from(this.GetSovereignConfigurationIndexDataByFilter(pageIndex, pageSize, filter));

  }

  creatSovereignConfigurationIndex(sovereignConfigurationForm: NewSovereignConfigurationIndexForm): Observable<string> {
    this.saveNewSovereignConfigurationIndex(sovereignConfigurationForm).then((a) => {
      if (a.data != 0) {
        //this.publishClient("created esgconfiguration data successfully!");
      }
      else {
        //this.publishClient(a.meta.message);
      }
    });
    return of(sovereignConfigurationForm.indexCode);

  }

  updateSovereignConfigurationIndex(sovereignConfigurationForm: NewSovereignConfigurationIndexForm): Observable<string> {
    this.saveSovereignConfigurationIndex(sovereignConfigurationForm).then((a) => {
      if (a.data != 0) {
        // this.publishClient("Updated esgconfiguration data successfully!");
      }
      else {
        // this.publishClient(a.data.message);
      }
    });
    return of(sovereignConfigurationForm.indexCode);
  }

  validateSovereignConfigurationIndex(sovereignConfigurationForm: NewSovereignConfigurationIndexForm): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/ValidateSovereignConfigurationIndex', JSON.stringify({ indexCode: sovereignConfigurationForm.indexCode, indexName: sovereignConfigurationForm.indexName, indexFamilyId: sovereignConfigurationForm.indexFamilyId }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data
      }).catch(this.handleErr);
  }

  saveNewSovereignConfigurationIndex(sovereignConfigurationForm: NewSovereignConfigurationIndexForm): any {
    this.validateSovereignConfigurationIndex(sovereignConfigurationForm).then((res) => {
        if (res) {
          alert('Sovereign Configuration Index data already exists!');
        }
        else {
          this.isFormValid = true;
          let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          return this.httpClient.post(
            environment.dataServiceRoot + 'ui/SITableConfigurations/AddSovereignConfigurationIndex',
            JSON.stringify({
              IndexCode: sovereignConfigurationForm.indexCode,
              IndexFamilyId: sovereignConfigurationForm.indexFamilyId,
              IndexName: sovereignConfigurationForm.indexName
            }),

            { headers: headers }).toPromise()
            .then(response => {
              var result = response as ResponseModel;
              if (result.data != 0) {
                this.publishClient("SovereignConfiguration Index data created successfully!");
              }
              else {
                this.publishClient(result.meta.message);
              }

            }).catch(this.handleErr);
        }
      });   
  }

  saveSovereignConfigurationIndex(sovereignConfigurationForm: NewSovereignConfigurationIndexForm): any {   
      this.isFormValid = true;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(
        environment.dataServiceRoot + 'ui/SITableConfigurations/UpdateSovereignConfigurationIndex',
        JSON.stringify({
          IndexCode: sovereignConfigurationForm.indexCode,
          IndexFamilyId: sovereignConfigurationForm.indexFamilyId,
          IndexName: sovereignConfigurationForm.indexName         
        }),

        { headers: headers }).toPromise()
        .then(response => {
          var result = response as ResponseModel;
          if (result.data != 0) {
            this.publishClient("Updated SovereignConfiguration Index data successfully!");
          }
          else {
            this.publishClient(result.meta.message);
          }
        }).catch(this.handleErr);
  }

  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  GetSovereignConfigurationData(pageIndex, pageSize): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/SovereignConfigurationList', JSON.stringify({ page: pageIndex, pageSize: pageSize }),
      { headers: headers });
  }

  GetSovereignConfigurationDataByFilter(pageIndex, pageSize, filter): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/SovereignConfigurationListByFilter', JSON.stringify({ pageSize: pageSize, page: pageIndex, filter: filter }),
      { headers: headers });
  }
  GetindexList(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/SovereignConfigurationIndexCodeList', null,
      { headers: headers });
  }
  GetSovereignIndexList(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/SovereignIndexList', null,
      { headers: headers });
  }
  GetSovereignConfigurationIndexData(pageIndex, pageSize): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/SovereignConfigurationIndexList', JSON.stringify({ page: pageIndex, pageSize: pageSize }),
      { headers: headers });
  }
    GetTableConfigurations(): Observable<any> {
      
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/TableConfigurations', null,
      { headers: headers });
  }
  GetSovereignConfigurationIndexDataByFilter(pageIndex, pageSize, filter): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/SovereignConfigurationIndexListByFilter', JSON.stringify({ pageSize: pageSize, page: pageIndex, filter: filter }),
      { headers: headers });
  }
  GetindexFixedIncomeList(): Observable<any> {
   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/SovereignConfigurationIndexIndexList',null,
     { headers: headers });
  }

 getCurrencies():Observable<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'ui/SITableConfigurations/GetCurrenciesList',null,
      {headers: headers});
  }

  deleteSovereignConfigurationData(sovereignConfigurationForm: any): any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DeleteSovereignConfiguration',
      JSON.stringify({
        IndexCode: sovereignConfigurationForm.IndexCode,
        ParentIndexCode: sovereignConfigurationForm.ParentIndexCode
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        this.publishClient("SovereignConfiguration data has been deleted");
        return result;
      }).catch(this.handleErr);
  }

  downloadExcel(indexCode:string): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DownloadSovereignConfigurationExcel',
      JSON.stringify({
      IndexCode: indexCode
      }),
      { headers: headers }).toPromise()
      .then(response => {
          var result = response;
          return result;
    })
        .catch (this.handleErr);
  }
  deleteSovereignConfigurationIndexData(sovereignConfigurationForm: any): any {
    debugger
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DeleteSovereignConfigurationIndex',
      JSON.stringify({
        IndexCode: sovereignConfigurationForm.IndexCode,
        IndexName: sovereignConfigurationForm.IndexName,
        IndexFamilyId: sovereignConfigurationForm.IndexFamilyId,
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        this.publishClient("SovereignConfiguration Index data has been deleted");
        return result;
      }).catch(this.handleErr);
  }

  downloadSovereignConfigurationExcel(indexCode: string): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DownloadSovereignConfigurationIndexExcel',
      JSON.stringify({
        IndexCode: indexCode
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        return result;
      })
      .catch(this.handleErr);
  }

  }

