import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { indexNamesModel } from '../models/indexNames.model';
@Injectable()
export class UserTabChangeService
{
  private subject = new Subject<any>();
  private tabDataSourceNameCollection = new Subject<string>();
  publish(userTabDataSourceName: any){
      this.subject.next(userTabDataSourceName);
  }

  getTabDataSourceName():Observable<any>{
      return this.subject.asObservable();
  }
}
