import { Component, OnInit,Input,EventEmitter,Output, Inject,NgZone } from '@angular/core';
import { LandingService } from '../../services/landing.service';
import { CloseHoldingService } from '../../services/close-holdings.service';
import {CloseHoldingPageModel} from '../../models/close-holding-page-model';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import {DateService} from '../../services/date.service';
import { BiService } from '../../services/BiService.service';
import {CurrencyService} from '../../services/currency.service';
import * as moment from 'moment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
    selector: 'landing-top-bottom-grid',
    templateUrl: './landing-top-bottom-grid.component.html',
    styleUrls: ['./landing-top-bottom-grid.component.css']
})
export class LandingTopBottomGridComponent implements OnInit {
    @Output() notifyPageModel : EventEmitter<CloseHoldingPageModel> = new EventEmitter<CloseHoldingPageModel>();
    asofDates : any;
    indexMarkers : any;  
    currencyCode : string;  
    chartAttributes: any;  
    topHoldings: boolean;
    bottomHoldings: boolean;
    topholdingsDataset: any;
    bottomholdingsDataset: any;
    currentIndex:any;
    toollbarTitle:any;
    chart:any;
    selectedIndex:any;
    
    constructor(private landingService: LandingService,private closeHoldingService:CloseHoldingService,private dateService: DateService,
        private indexMarkerService:IndexMarkerService, private exportService: ExportToExcelService,
        private publishIndexMarkerService:PublishIndexMarkerService,private biService: BiService,private currencyService:CurrencyService,public dialog: MatDialog) {  this.topHoldings = true; }

    
    toggleRankedHoldings(topRankedHoldings: boolean) {        
        this.topHoldings = topRankedHoldings;
        this.bottomHoldings = !topRankedHoldings;       
    }
      
    ngOnInit(){    
        console.log("Landig topBttom");
        this.indexMarkers = this.indexMarkerService.getIndexMarkers();
        this.currencyCode = this.currencyService.getCurrencyCode(); 
        this.currentIndex = 0;
      
        this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
            this.indexMarkers = result;
            this.chartSetup();
        });
       
        this.asofDates = this.dateService.getCalendarDate().end;
        this.chartSetup();
        
        this.dateService.calendarDatePublish().subscribe(dateRange=>{
          this.asofDates =  this.dateService.changeDateFormat(dateRange.end);
          this.chartSetup();
      });
      this.currencyService.currencyCodePublish().subscribe(currency=>{
        this.currencyCode =  currency;        
       this.chartSetup();
    });  
    }
   
    openWidgetDialog():void{
        var event: any = {eventName: "Top 5 Holdings help dialog open"};
        console.log("landing top bottom popup");
        this.biService.addEvent(event);
        let dialogRef = this.dialog.open(LandingTopBottomGridComponentDialog, {
            width: '800px' , 
            data: this.indexMarkers     
          });
      }

    carouselPrevious():void{
        var event: any = {eventName: "TopBottom Previous Carousel", inputParameters: [{Name: "Previous IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() }]};
        this.biService.addEvent(event);
        if(0 == this.currentIndex){
           this.currentIndex = this.indexMarkers.length -1;
        }else{
            this.currentIndex = this.currentIndex - 1;
        }
        this.chartSetup();
        
    }
    
    carouselNext():void{
        var event: any = {eventName: "TopBottom Next Carousel",  inputParameters: [{Name: "Next IndexMarker", Value:  this.indexMarkers[this.currentIndex].toString() }]};
        this.biService.addEvent(event);
       if((this.indexMarkers.length -1) == this.currentIndex){
            this.currentIndex = 0;
        }else{
            this.currentIndex = this.currentIndex + 1;
        }
        this.chartSetup();
        
    }

    
      private chartSetup() {
        var oneIndexMarkers:string[] = [];
        if(this.indexMarkers[this.currentIndex] == undefined)
        {
            this.currentIndex=0;
        }
        oneIndexMarkers.push(this.indexMarkers[this.currentIndex]);  
        this.selectedIndex = this.indexMarkers[this.currentIndex];
        this.toollbarTitle = "Top 5 Holdings "  ;     
        this.landingService.getTopBottomHoldings( this.asofDates,oneIndexMarkers ,this.currencyCode ).then(data => {
          this.topholdingsDataset = data.topHoldings[0].data;                      
        });
    }

    ExportXls(event):void{
        var event: any = {eventName: "Export TopBottom Grid", inputParameters: [{Name : "Exported IndexMarker" , Value: this.indexMarkers[this.currentIndex].toString()}
        ,{Name : "CurrencyCode" , Value: this.currencyCode}]};
        this.biService.addEvent(event);
        var oneIndexMarkers:string[] = [];
        oneIndexMarkers.push(this.indexMarkers[this.currentIndex]);
      this.closeHoldingService.getTopBottomHoldingsData(this.asofDates,oneIndexMarkers,
        this.currencyCode,(this.topHoldings == false)?"ASC":"DESC").then(data=>{
          this.exportService.exportData(data,"topBottomHoldingsRawData");
      }).catch(err=>{
          console.log(err);
      });
    }
}

@Component({ 
    selector: 'top-bottom',   
    templateUrl: './landing-top-bottom-grid.component.widget.dialog.html'
})
export class LandingTopBottomGridComponentDialog{
  
  @Input() indexes: string;
  constructor(
  public dialogRef: MatDialogRef<LandingTopBottomGridComponentDialog>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
    this.indexes="";
     data.forEach(element => {
         if( this.indexes.length<3)
         {
            this.indexes=element;
         }else{
        this.indexes += ", "+ element;
         }
     });   
   }

  onClose(): void {
      this.dialogRef.close();
}
}