import { Component, EventEmitter, Input, OnInit, Inject,Output, ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {SelectionModel} from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { ConnectContactLens } from 'aws-sdk';
import { ExportReportData, Report, ReportData } from '../../models/report';
import { MasterDataService } from '../../services/masterdatapack.service';
import { DownloadService } from '../../services/download.service';
import {S3ManagerService} from '../../../services/s3-manager.service';
import { saveAs } from 'file-saver/FileSaver';
import { ArrayDataSource } from '@angular/cdk/collections';
import * as CryptoJS from "crypto-js";
import * as AWS from 'aws-sdk';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';
import { SiMetricIndex } from '../../models/metric';
import { environment } from '../../../../environments/environment';

enum ColumnNames {
  index = 'Index',
  indexCode = 'IndexCode',
  indexFamily = 'IndexFamily',
  indexFamilyId = 'IndexFamilyId',
  parentIndex = 'ParentIndex',
  parentIndexCode = 'ParentIndexCode',
  assetClass ="AssetClass",
  comments = 'Comments',
  ModifiedUser = 'ModifiedUser',
  ModifiedTime = 'ModifiedTime',
  isUrgent = 'UrgentFlag',
  controls = 'controls'
}

@Component({
  selector: 'master-data-grid',
  templateUrl: './master-data-grid.component.html',
  styleUrls: ['./master-data-grid.component.scss']
})
export class MasterDataGridComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() edit = new EventEmitter<Report>();
  isVisible :boolean = false ;
  dataSource: any;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25];
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 5;
  pageCount: number = 0;
  pager: any = {};
  unsub: any;
  siMetricIndexes: SiMetricIndex[] = [];
  data: MatTableDataSource<any>;
  oldPageLimit: number;
  response: any;
  selectedIndex: any;
  selectedValue: any;
  activePageDataChunk: any;
  pageLength: number;
  filterForm: any;
  isupdate :boolean = false ;
  exportresultArray: ExportReportData[] = [];
  selectedreports: number[] = [];
  passphrase: string = "EncryptionatRest";
  isLoading: boolean = false;
  indexcodes: string[];
  public indexcodefilteredList: string[];
  displayColumnNameMap = new Map<ColumnNames, string>([
    //[ColumnNames.asOfDate, 'AsOfDate'],
    [ColumnNames.isUrgent,'Urgent\nFlag'],
    [ColumnNames.index, 'Index'],
    [ColumnNames.indexCode, 'Index\nCode'],
    [ColumnNames.indexFamily, 'Index Family'],
    [ColumnNames.indexFamilyId, 'Index Family Id'],
    [ColumnNames.parentIndex, 'Parent Index'],
    [ColumnNames.parentIndexCode, 'Parent Index Code'],
    [ColumnNames.assetClass, 'Asset Class'],
    [ColumnNames.comments, 'Comments'],
    [ColumnNames.ModifiedUser, 'Modified User'],
    [ColumnNames.ModifiedTime, 'Modified date']
  ]);
  displayedColumns: ColumnNames[] = [
    ColumnNames.isUrgent,
    ColumnNames.indexCode,
    ColumnNames.index,
    ColumnNames.indexFamily,
    ColumnNames.indexFamilyId,
    ColumnNames.parentIndex,
    ColumnNames.parentIndexCode,
    ColumnNames.assetClass,
    ColumnNames.comments,
    ColumnNames.ModifiedUser,
    ColumnNames.ModifiedTime,
    ColumnNames.controls
  ];
  skipColumnsAutoRender = new Set<ColumnNames>([
    ColumnNames.controls
  ]);
  constructor(private router: Router, private masterDataService: MasterDataService, private downloadService: DownloadService, private fb: FormBuilder, private s3ManagerService:S3ManagerService) {
    this.unsub = this.masterDataService.clientPublish().subscribe((m: any) => {
      alert(m);
      this.getMasterData();

    });
  }

  ngOnInit(): void {
    if (sessionStorage.getItem("UserRoles").indexOf(environment.siAdminGroup) != -1) {
            this.isVisible=true;
        }
    this.filterForm = this.fb.group({
     // asOfDate: [""],
     Index:  [""],
     IndexCode:  [""],
     IndexFamily:  [""],
     IndexFamilyId:  [""],
     ParentIndex:  [""],
      ParentIndexCode: [""],
      AssetClass :[""],
      Comments: [""],
      ModifiedUser: [""],
      ModifiedTime: [""],
     UrgentFlag : [""]
 

    });
    this.filterForm.valueChanges.subscribe(value => {
      console.log(this.dataSource);
      /*var filter1 = this.dataSource.filter(cp => cp.indexMarker.toLowerCase().indexOf(value.indexMarker.toLowerCase()) != -1 && cp.parentIndexMarker.toLowerCase().indexOf(value.parentIndexMarker.toLowerCase()) != -1
        && cp.currency.toLowerCase().indexOf(value.currency.toLowerCase()) != -1 
        );
      this.activePageDataChunk = filter1.slice(0, this.pageSize);
      filter1.paginator = this.paginator;
      this.pageLength = filter1.length;*/
    });    
    this.getMasterData();
    this.GetindexList();
  }

  public onClickHandler(event: any, metricData:any): void {
    if (confirm("Do you want to update urgent flag?")) {
      metricData.UrgentFlag = event.checked;
      this.masterDataService.saveMetricUrgent(metricData).subscribe(result => {
        this.getMasterData();
      });
    }
  }

  getColumnsViewName(column: ColumnNames): string {
    return this.displayColumnNameMap.get(column) || '';
  }
  

  trim(text: any): string | any {
    if (typeof text === 'string') {
      return text.length < 28 ? text : text.slice(0, 25).trim() + '...';
    }
    return text;
  }
  GetindexList() {
    this.masterDataService.GetindexList().subscribe((res) => {
      this.siMetricIndexes = res.Data;
      this.indexcodes = res.Data.map(x => x.IndexCode);
      this.indexcodefilteredList = res.Data.map(x => x.IndexCode);
    });
  }
  getMasterData()
  {
    if (this.selectedValue != undefined) {
      this.getMasterDataByfilter(this.selectedValue);     
    }
    else {
      this.isLoading = true;
      this.masterDataService.fetchReports$(this.pageIndex, this.pageSize).subscribe((res) => {
        this.response = res;
        this.dataSource = this.response.Data;

        console.log("getMasterData");
        console.log(this.dataSource);
        this.isLoading = false;
        this.activePageDataChunk = this.dataSource;//.slice(0, this.pageSize);
        for (let item of this.activePageDataChunk) {
          item.ModifiedTime = (item.ModifiedTime).substring(0, 10)
        }
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].TotalCount : 0;

      });
    }
  }
  getMasterDataByfilter(filter) {
    this.isLoading = true;
      this.masterDataService.fetchMasterData$(this.pageIndex, this.pageSize, filter).subscribe((res) => {
        this.response = res;
        this.dataSource = this.response.Data;

        console.log("getMasterData");
        console.log(this.dataSource);
        this.isLoading = false;
        this.activePageDataChunk = this.dataSource;//.slice(0, this.pageSize);
        for (let item of this.activePageDataChunk) {
          item.ModifiedTime = (item.ModifiedTime).substring(0, 10)
        }
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].TotalCount : 0;
      

    });
  }
  onIndexChange(selectedIndex) {
    debugger;
    if (selectedIndex != undefined) {
      this.selectedValue = selectedIndex;
      this.getMasterDataByfilter(selectedIndex);
    }
    else {
      this.selectedValue = undefined;
      this.getMasterData();
      
      //this.GetindexList();
     
    }

  }

  openEditMetric(metric: any) {
    this.isupdate = true ;
    this.edit.emit(metric);
  }

 
  onPageChanged(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getMasterData();
  }
  ngOnDestroy() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
  }
  delete(metric : any) {
    if (confirm("Are you sure to delete Index Data?")) {
      this.masterDataService.deleteMasterData(metric);     
    }
  }
}
