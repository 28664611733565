import {Component, NgModule, OnInit,Output,EventEmitter} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import { Daterangepicker,DaterangepickerConfig } from 'ng2-daterangepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',  
})

export class DatePickerComponent implements OnInit{

    @Output() notifyDate: EventEmitter<any> = new EventEmitter<any>();
    OneDayAgo:any = moment().subtract(1,'days').toDate();
    
    public mainInput = {
        start: this.OneDayAgo     
    }    
    ngOnInit(){
        
       this.notifyDate.emit(this.mainInput);
    }
    constructor(private daterangepickerOptions: DaterangepickerConfig) {
        this.daterangepickerOptions.settings = {
            startDate:this.OneDayAgo,
            endDate:this.OneDayAgo,   
            maxDate:this.OneDayAgo,
            locale: { format: 'MM/DD/YYYY' },
            alwaysShowCalendars: false,
            singleDatePicker:true,
            autoApply: true,
            linkedCalendars: false
        };        
        this.notifyDate.emit(this.mainInput);       
    }  
    
    public selectedDate(value: any, dateInput: any) {
        dateInput.end = value.start;
        this.notifyDate.emit(value);
    }   
}
