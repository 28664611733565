import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {GeneralError} from '../../models/common';

@Component({
  selector: 'app-error-notification',
  templateUrl: './error-notification.component.html',
  styleUrls: ['./error-notification.component.scss']
})
export class ErrorNotificationComponent implements OnInit {
  readonly DEFAULT_NAME = 'Unknown error';
  readonly DEFAULT_MESSAGE = 'No error message found';

  name: string;
  message: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: GeneralError,
    private matSnackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.name = this.data.name || this.DEFAULT_NAME;
    this.message = this.data.message || this.DEFAULT_MESSAGE;
  }
  close() {
    this.matSnackBar.dismiss();
  }
}
