import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { indexNamesModel } from '../models/indexNames.model';
@Injectable()
export class PublishIndexMarkerService
{
  private subject = new Subject<any>();
  private subjectIndexNames = new Subject<indexNamesModel>();
  private productcodeSubject = new Subject<any>();
  publish(indexMarkers: any){
      this.subject.next(indexMarkers);
  }

  publishProdoctCodes(productCodes: any){
    this.productcodeSubject.next(productCodes);
  }
  indexMarkerPublish():Observable<any>{
      return this.subject.asObservable();
  }
  publishIndexNames(indexMarkersNames: any){
    this.subjectIndexNames.next(indexMarkersNames);
    }
indexMarkerNamesPublish():Observable<any>{
  return this.subjectIndexNames.asObservable();
  }
}