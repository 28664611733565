import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {IndexMarkerData} from '../../services/benchmarks.service';

@Component({
  selector: 'app-market-index-select',
  templateUrl: './select-index-marker.component.html',
  styleUrls: ['./select-index-marker.component.scss']
})
export class SelectIndexMarkerComponent implements OnInit, OnDestroy {
  private readonly sub;
  private _markers: IndexMarkerData[];

  fc = new FormControl('');

  @Input() set markers(values: IndexMarkerData[]) {
    this._markers = values;
    this.groups = [...new Set(this._markers.map(({type}) => type))];
    this.fc.patchValue(this._markers.map(({index}) => 'FBLSMJPN'));
  }

  @Output() update = new EventEmitter<string[]>();
  groups: string[];

  constructor() {
    this.sub = this.fc.valueChanges.subscribe((marker) => {
      this.update.emit(marker);
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line:no-unused-expression
    this.sub && this.sub.unsubscribe();
  }

  filterByType(type: string) {
    return this._markers.filter(m => m.type === type);
  }

}
