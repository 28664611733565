import { Component, EventEmitter, Input, OnInit, Inject, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ExportReportData, Report, ReportData } from '../../models/report';
import { SIDataCalculationService } from '../../services/sidatacalculation.service';

enum ColumnNames {
  asOfDate = 'asOfDate',
  feedName = 'feedName',
  status = 'status',  
  error = 'error',
  createDate = 'createDate',
  modifiedDate ='modifiedDate',
  controls = 'controls',
}

@Component({
  selector: 'sidatacalculation-data-grid',
  templateUrl: './sidatacalculation-data-grid.component.html',
  styleUrls: ['./sidatacalculation-data-grid.component.scss']
})
export class SIDataCalculationGridComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() edit = new EventEmitter<Report>();
  dataSource: any;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25];
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 15;
  unsub: any;
  data: MatTableDataSource<any>;
  oldPageLimit: number;
  response: any;
  activePageDataChunk: any;
  pageLength: number;
  filterForm: any;
  isupdate :boolean = false ;
  exportresultArray: ExportReportData[] = [];
  selectedreports: number[] = [];
  passphrase: string = "EncryptionatRest";
  displayColumnNameMap = new Map<ColumnNames, string>([
    [ColumnNames.asOfDate, 'AsOfDate'],
    [ColumnNames.feedName, 'Feed Name'],
    [ColumnNames.status, 'Calculation Status'],
    [ColumnNames.error, 'Failure Cause'],
    [ColumnNames.createDate, 'Create Date'],
    [ColumnNames.modifiedDate, 'Modified Date']
  ]);
  displayedColumns: ColumnNames[] = [
    ColumnNames.asOfDate,
    ColumnNames.feedName,
    ColumnNames.status,
    ColumnNames.error,
    ColumnNames.createDate,
    ColumnNames.modifiedDate  
  ];
  skipColumnsAutoRender = new Set<ColumnNames>([
    ColumnNames.controls
  ]);
  constructor(private router: Router, private sidatacalculationservice: SIDataCalculationService, private fb: FormBuilder) {
    this.unsub = this.sidatacalculationservice.clientPublish().subscribe((m: any) => {
      this.sidatacalculationservice.GetFeedTriggers().subscribe(res => {
        this.response = res;
        this.dataSource = this.response.data;
        console.log(this.dataSource);
        if (this.dataSource.length % this.pageSize == 1 && (this.pageIndex == (Math.floor(this.dataSource.length / this.pageSize) - 1))) {
          this.pageIndex = this.pageIndex + 1;
        }
        let firstCut = this.pageIndex * this.pageSize;
        let secondCut = firstCut + this.pageSize;   
        this.activePageDataChunk = this.dataSource.slice(firstCut, secondCut);
        for(let item of this.activePageDataChunk){
          item.createDate = (item.createDate).substring(0, 19),
            item.modifiedDate = (item.modifiedDate).substring(0, 19),
            item.feedName = (item.feedName),
            item.asOfDate = (item.asOfDate).substring(0, 10),
            item.status = item.status,
            item.error = item.error
        }
        console.log(this.dataSource);
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length;
      });
    });
  }

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      asOfDate: [""],
      feedName: [""],
      status: [""],
      error: [""],
      createDate: [""],
      modifiedDate:[""]
    });
    this.filterForm.valueChanges.subscribe(value => {
      console.log(this.dataSource);
      var filter1 = this.dataSource.filter(cp => cp.feedName.toLowerCase().indexOf(value.feedName.toLowerCase()) != -1);
      this.activePageDataChunk = filter1.slice(0, this.pageSize);
      filter1.paginator = this.paginator;
      this.pageLength = filter1.length;
    });
    this.sidatacalculationservice.GetFeedTriggers().subscribe((res) => {
      this.response = res;
      console.log(res.data);
      this.dataSource = this.response.data;

      if (this.dataSource.length > this.pageSize) {
        this.activePageDataChunk = this.dataSource.slice(0, this.pageSize);
      }
      else {
        this.activePageDataChunk = this.dataSource;
      }      
      for(let item of this.activePageDataChunk){
        item.createDate = (item.createDate).substring(0, 19),
          item.modifiedDate = (item.modifiedDate).substring(0, 19),
          item.feedName = (item.feedName),
          item.asOfDate = (item.asOfDate).substring(0, 10),
          item.status = item.status,
          item.error = item.error
          
      }
      this.dataSource.paginator = this.paginator;
      this.pageLength = this.dataSource.length;
    });
  }

  getColumnsViewName(column: ColumnNames): string {
    return this.displayColumnNameMap.get(column) || '';
  }
  

  trim(text: any): string | any {
    if (typeof text === 'string') {
      return text.length < 28 ? text : text.slice(0, 25).trim() + '...';
    }
    return text;
  }
 
  onPageChanged(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    let firstCut = e.pageIndex * e.pageSize;
    let secondCut = firstCut + e.pageSize;
    this.activePageDataChunk = this.dataSource.slice(firstCut, secondCut);
    for(let item of this.activePageDataChunk){
      item.createDate = (item.createDate),
        item.modifiedDate = (item.modifiedDate),
        item.feedName = (item.feedName),
        item.asOfDate = (item.asofDate).substring(0, 10),
        item.status = item.status ,
        item.error = item.error
        
    }
  }
         
  ngOnDestroy() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
  }
}
