import { Component,ViewChild, OnInit } from '@angular/core';
import { GeographicalBarChartsService } from '../../services/geographical-bar-charts.service';
import * as moment from 'moment';
import {DateService} from '../../services/date.service';
import { FilterTelemetryProducts } from '../../filter.pipe';
  import { from } from 'rxjs';
import { data } from 'jquery';
import { ChartsDetails,NameDetails,TelemetryCountryInput, CountryDetails, EventDetails, MapData, ExportExcel } from '../../models/gerographical-charts';
import { groupBy } from 'rxjs/operators';
import { ExportToExcelService } from '../../services/exportToExcel.service';
import { BiService } from '../../services/BiService.service';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {CustomTableComponent} from '../custom-table/custom-table.component';
import { PagerService } from '../../services/pager.service';
// import { TelemetryIndexMaker} from '../../services/geographical-bar-charts.service';






@Component({
  selector: 'app-maps',
  templateUrl: './telemetry-logs.component.html',
  styleUrls: ['./telemetry-logs.component.css']
})
export class TelemetryLogs implements OnInit {
@ViewChild(CustomTableComponent,{static: false}) customTableComponent; 
pager: any = {};
isProductIndexMarker:boolean = false;
page:any = 1;
referenceTabLoader : boolean=false;
filesPaged: any[] =[];
telemetryIndexMarkerFilter : any = {};
// TelemetryCountryInput:TelemetryCountryInput


gridData:any;   
totalEntries :any = 0;
pageSize :any = 4;




  dataSource: any;
  chartConfig: Object;
  asOfDate : any;
  SelectAPIName:any;
  targetUser:any;
  dataSources:any;
  tableTitle: any;
  noOfCalls: any;
  total: any; 
  tableData: ChartsDetails[];
  exportExcel: ExportExcel[];
  count: any;
  country: any;
  chartDetailsCountry: CountryDetails[];
  eventDetails: EventDetails[];
  dataHeader = [];  
  todayDate: any;
  createDateFilter: any;
  userIDFilter: any;
  eventIDFilter: any;
  ipAddressFilter: any;
  countryFilter: any;
  cityFilter: any;
  continentFilter: any;
  regionNameFilter: any;
  nameFilter: any;
  valueFilter: any;
  endDate: any;
  mapData: MapData[];
  tableHeader: any;
result: any;
  constructor(private service:GeographicalBarChartsService,private dateService:DateService, private biService: BiService,
    private pagerService: PagerService, 
    private exportService: ExportToExcelService) {
    // this.getProducts();
    this.getApiName();
    this.chartConfig = {
      width: '700',
      height: '400',
      type: 'column2d',
      dataFormat: 'json',
  };

  this.dataSource = {
      "chart": {
        // "caption": "Data",
        
        "xAxisName": "Country",
        "yAxisName": "Calls (MMbbl)",
        // "numberSuffix": "K",
        "theme": "fusion",
      }
    
    };
  }

  ngOnInit() {
    var that = this;
    this.dateService.calendarDatePublish().subscribe(result=>{
      this.endDate =moment(result.end).format('MM/DD/YYYY');
  this.todayDate = moment(result.start).format('MM/DD/YYYY'); 
      that.asOfDate = moment(result.end).format('MM/DD/YYYY');
      that.getLocationData();
      //this.getCountryWiseData();    
  });
  this.tableHeader = "API Logs "
  
  this.exportExcel = [{
    parameterName: "",
    parameterValue: "",
    companyName: "",
    callDate: ""
  }];
    this.asOfDate = this.dateService.getCalendarDate().end;
    this.endDate = moment(this.dateService.endDate).format('MM/DD/YYYY');
    this.todayDate = moment(this.dateService.startDate).format('MM/DD/YYYY'); 
  //  this.getLocationData();  
    this.tableTitle = "Country";
    this.noOfCalls = "No of Calls ";
    this.total = "Total";  
}
      
      getLocationData() {
        
        this.service.getMapData(this.asOfDate,this.SelectAPIName).
        then((data: ChartsDetails[]) => {      
          //this.dataSource = data;
          this.tableData=data["data"];
          // console.log('datasource',this.dataSource);   
          this.getCountryWiseData();    
   
   this.mapDataForMe();
        });

      }  

      dataChange(createDateFilter, userIDFilter, eventIDFilter, ipAddressFilter,countryFilter, cityFilter, continentFilter, regionNameFilter){
        var chartFilterPipe = new FilterTelemetryProducts();
        var chartFiltred =  chartFilterPipe.transform(this.chartDetailsCountry,createDateFilter, userIDFilter, eventIDFilter, ipAddressFilter,countryFilter, cityFilter, continentFilter, regionNameFilter);
        this.groupByChartDate(chartFiltred);
        console.log(chartFiltred);

        this.telemetryIndexMarkerFilter = {
          CreateDate: this.createDateFilter,
          UserID: this.userIDFilter,
          EventID : this.eventIDFilter, 
          IPAddress: this.ipAddressFilter,
          Country: this.countryFilter,
          City: this.cityFilter,
          ContinentName:this.continentFilter,
          RegionName:this.regionNameFilter
  
        };
       this.page = 1;
        this.service.TelemetryIndexMaker(this.page,this.pageSize, 
          this.telemetryIndexMarkerFilter, this.todayDate,this.endDate,this.SelectAPIName).then
                (data=>{
                  this.gridData = data["pagedTelemetryLogs"];
                  this.filesPaged = this.gridData;
                  this.totalEntries = data["totalResults"];
                  this.setPage(this.page);
                  
                // console.log('test for fatch page ');
        
                });
   

      }

      getCountryWiseData() {
        this.page = 1;
        this.service.TelemetryIndexMaker(this.page,this.pageSize, 
          this.telemetryIndexMarkerFilter, this.todayDate,this.endDate,this.SelectAPIName).then
                (data=>{
          this.gridData = data["pagedTelemetryLogs"];

      this.fetchPage(1);
      
        })
      }

mapDataForMe(){
  this.service.getMapDataByCountry(this.todayDate, this.endDate,this.SelectAPIName)
          .then((result: CountryDetails[]) =>{
        
          this.chartDetailsCountry = result ;
          
        this.groupByChartDate(this.chartDetailsCountry);
          })

}

      groupByChartDate(chartDetails) {
        var that =  this;
        if(chartDetails && chartDetails.length > 0)  {
          that.dataSource.data = undefined;
        }
        else{
          that.dataSource.data = '';
        }
        
        
        chartDetails.forEach(function(a){
          if(!that.dataSource.data){
          that.dataSource.data =[];
            that.dataSource.data.push({ Label: a.country, value: "1", ToolText: a.country + "1", Color:""});
          }
          else {
            if(!that.isCountryExists(a)) {
              that.dataSource.data.push({ Label: a.country, value: "1", ToolText: a.country + "1", Color:""});
            }            
          }
        });
   

        console.log('data', this.dataSource);
      }

      isCountryExists(a) {
        var isExists = 0;
        this.dataSource.data.forEach(function(b){
              if(b.Label == a.country){
                b.value = (Number.parseInt(b.value) + 1).toString();
                isExists =1;
                return ;
              }
            })
        return isExists;
      }

      getEventWiseData(element){
        this.service.getDataByEventParameter(element.eventID).then((data: EventDetails[]) =>{
          this.eventDetails = data ;
          console.log('event wise data ...', this.eventDetails);
        })
      }

      getApiName(){
        this.service.dropDownValue().then(name =>{
          // console.log('dropDoen value', name);
          this.dataHeader=name;
          this.SelectAPIName=this.dataHeader;
        })
      }

      Exportxls():void{
        var that = this;
      
        this.telemetryIndexMarkerFilter = {
          CreateDate: this.createDateFilter,
          UserID: this.userIDFilter,
          EventID : this.eventIDFilter, 
          IPAddress: this.ipAddressFilter,
          Country: this.countryFilter,
          City: this.cityFilter,
          ContinentName:this.continentFilter,
          RegionName:this.regionNameFilter

          
        };
          this.service.getTelemetryLogsToExport(this.telemetryIndexMarkerFilter, 
            this.todayDate , this.SelectAPIName, this.endDate).then(res=>{
         this.exportService.exportData(res["data"],"TelemetryLogsFile");
          
         }); 
        
       
      }

      setPage(page: number) {    
        this.pager = this.pagerService.getPager(this.totalEntries, page, this.pageSize );    
        // , this.pageSize  
      }
      fetchPage(page: number){
        this.page = page;
        
        this.telemetryIndexMarkerFilter = {
          CreateDate: this.createDateFilter,
          UserID: this.userIDFilter,
          EventID : this.eventIDFilter, 
          IPAddress: this.ipAddressFilter,
          Country: this.countryFilter,
          City: this.cityFilter,
          ContinentName:this.continentFilter,
          RegionName:this.regionNameFilter

          
        };
     
 this.service.TelemetryIndexMaker(this.page,this.pageSize, 
  this.telemetryIndexMarkerFilter, this.todayDate,this.endDate,this.SelectAPIName).then
        (data=>{
          this.gridData = data["pagedTelemetryLogs"];
          this.filesPaged = this.gridData;
          this.totalEntries = data["totalResults"];
          this.setPage(this.page);
          
        // console.log('test for fatch page ');

        });
      
      
      }
      }

    






