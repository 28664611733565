import {Component, Pipe, Input} from '@angular/core';
import { FilterPipe } from '../../filter.pipe';
import {DateService } from '../../services/date.service';

@Component({
    selector: 'custom-table',
    templateUrl: './custom-table.component.html',
    styleUrls:['custom-table.component.css']
})
export class CustomTableComponent{
    customColumns:any[];
    @Input() data:any;
     @Input() visibleColums:any[]
    decimalvalue:any='.2-2';
     dateFormat: any;
     sortedArray:any;
     sortedColumn:any;
     isAscending:boolean=true;
    constructor(private dateService : DateService){

    }
    ngOnInit(){
        this.dateFormat = this.dateService.getDateFormat(); 
        this.dateService.dateFormatPublish().subscribe(dateFormat=>{
            this.dateFormat =  dateFormat;                          
        });
     this.decimalvalue='.2-2';
     }
     columntoSort(columnname:any)
     {
        this.sortedColumn=columnname;
        this.decimalvalue='.2-2';
        if(this.isAscending)
        {
          this.sortedArray =  this.data.sort(function(a,b){  
            if(a[columnname] == null || null == b[columnname]) return 0;                        
            return a[columnname] >b[columnname]?1:a[columnname] <b[columnname]?-1:0
           }) 
           this.isAscending=false;
        }
        else{
            this.sortedArray =  this.data.sort(function(b,a){    
                if(a[columnname] == null || null == b[columnname]) return 0;                      
                return a[columnname] >b[columnname]?1:a[columnname] <b[columnname]?-1:0
               }) 
               this.isAscending=true;
        }       
        this.bind( this.sortedArray, this.customColumns,this.decimalvalue)   ;
     }

    bind(targetData:any, targetColumns:any[],decimalvalue:any='.2-2'){
      this.customColumns = targetColumns;
      this.visibleColums = []; 
      this.decimalvalue= decimalvalue;
      for(let elem of this.customColumns){  
          if(elem.columnVisibility){
              if(elem.columnName!="Select All")
              {
              var visibleElem =  {UIDisplayValue: elem.uiDisplayValue,ColumnCssClass:elem.columnCssClass,
                ColumnName:elem.columnName.substring(0,1).toLowerCase()+elem.columnName.substring(1),
             HasFilter: elem.hasFilter, Filter:elem.filter};
              this.visibleColums.push(visibleElem);
            }
          }
      }
      this.data = targetData;    
    }
}