import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import * as moment from 'moment';
@Injectable()
export class DateService {
  private subject = new Subject<any>();
  private subjectTimePeriod = new Subject<any>();
  private dateFormatSubject = new Subject<any>(); 
  startDate: any;
  endDate: any;
  public calenderInput = {
    start: '',
    end: '',

  }

  currentDateFormat = "USA";
  constructor() { }

  setTransactionDate(asOfDate: any): void {
    localStorage.setItem('TransactionDate', asOfDate);
  }

  setStartDateAndEndDate(startDate: any, endDate: any) : void
  {
    localStorage.setItem('StartDate', startDate);
    localStorage.setItem('EndDate', endDate);
  }

  getStartDate() {
    return localStorage.getItem('StartDate');
  }

  getEndDate() {
    return localStorage.getItem('EndDate');
  }

  getTransactionDate() {
    return localStorage.getItem('TransactionDate');
  }

  setCalendarDate(calendarDate: any): void {
    calendarDate.start = new Date(calendarDate.start);
    calendarDate.end = new Date(calendarDate.end.toString().substring(3, 15));
    localStorage.setItem("CalendarRange", JSON.stringify(calendarDate));
    this.publish(calendarDate);
  }

  getCalendarDate() {
    var dateRange = JSON.parse(localStorage.getItem("CalendarRange"));
    this.startDate = dateRange == null ? new Date() : new Date(dateRange.start);
    this.endDate = dateRange == null ? new Date() : new Date(dateRange.end);
    this.calenderInput.start = moment(this.startDate).format('MM/DD/YYYY');
    this.calenderInput.end = moment(this.endDate).format('MM/DD/YYYY');
    return JSON.parse(JSON.stringify(this.calenderInput));
  }
  publish(calendarDate: any) {
    this.subject.next(calendarDate);
  }

  calendarDatePublish(): Observable<any> {
    return this.subject.asObservable();
  }


  setTimePeriod(timePeriod: any): void {
    localStorage.setItem("TimePeriod", timePeriod);
    this.publishTimePeriod(timePeriod);
  }

  getTimePeriod() {
    return localStorage.getItem("TimePeriod");
  }

  publishTimePeriod(timePeriod: any) {
    this.subjectTimePeriod.next(timePeriod);
  }

  timePeriodPublish(): Observable<any> {
    return this.subjectTimePeriod.asObservable();
  }
  changeDateFormat(value)
  {
    return moment(value).format('MM/DD/YYYY');
  }
  changeDateFormatUSA(value)
  {
    let dateParts=value.split("/");
    return dateParts[1]+"/"+dateParts[0]+"/"+dateParts[2];
  }
  changeDateFormatUK(value)
  {
    return moment(value).format('DD/MM/YYYY');
  }

  setDateFormat(dateFormat){
    localStorage.setItem("DateFormat", dateFormat);
  }

  getDateFormat(){
    if(!localStorage.getItem('DateFormat'))
    this.setDateFormat(this.currentDateFormat);
    return localStorage.getItem('DateFormat');
  }

  publishDateFormat(){
    this.dateFormatSubject.next(this.getDateFormat());
  }

  dateFormatPublish():Observable<any>{
    return this.dateFormatSubject.asObservable();
  }   
}
