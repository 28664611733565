import { Component, OnInit,ViewChild  } from '@angular/core';
import {GrowthOfUnitComponent} from '../landing-growth-of-unit/growth-of-unit.component';
import {MarketCapReturnsChartComponent} from '../landing-market-cap-returns-chart/market-cap-returns-chart.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {DateService } from '../../services/date.service';

@Component({
    selector: 'landing-page-charts',
    templateUrl: './landing-page-charts.component.html',
    styleUrls: ['./landing-page-charts.component.css']
})
export class LandingPageChartsComponent  implements OnInit{
    
  @ViewChild(GrowthOfUnitComponent,{static: false}) growthOfUnitComponent;
  @ViewChild(MarketCapReturnsChartComponent,{static: false}) marketCapReturnsChartComponent;   
  
  constructor(private dateService : DateService){}
  
  ngOnInit(){            
  }
     
  ExportXls(event):void{
       this.growthOfUnitComponent.ExportXls();
   }

   ExportPNG(event):void{
    this.growthOfUnitComponent.ExportPNG();
   }

    openWidgetDialog():void{
      this.growthOfUnitComponent.openWidgetDialog();
   }

    growthOfUnitCurrencyCodeChange(currencyCode:any){
      this.growthOfUnitComponent.growthOfUnitCurrencyCodeChange(currencyCode);
    }

    growthOfUnitReturnTypeChange(returnType:any){      
      this.growthOfUnitComponent.growthOfUnitReturnTypeChange(returnType);
      
    }
    marketCapCurrencyCodeChange(currencyCode:any){
      this.marketCapReturnsChartComponent.marketCapCurrencyCodeChange(currencyCode);
    }
    
      
 }