import { Component,ViewChild,OnInit, AfterViewInit } from '@angular/core';
import {PerformancePageModel} from '../../models/performance-page-model';
import {PerformanceHeatChartComponent} from '../performance-heat-chart/performance-heat-chart.component';
import {PerformanceReturnsComponent} from '../performance-returns-chart/performance-returns-chart.component';
import {PerformanceTimeSeriesComponent} from '../performance-time-series-chart/performance-time-series-chart.component';
import {PerformanceCalendarReturnsComponent} from '../performance-calendar-return-chart/performance-calendar-return-chart.component';
import {DateService} from '../../services/date.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
@Component({
  selector: 'performance-page',
  templateUrl: './performance-page.component.html'
})

export class PerformancePageComponent implements OnInit,AfterViewInit {

  performancePageModel: PerformancePageModel;
  
   @ViewChild(PerformanceHeatChartComponent,{static: false}) performanceHeatChartComponent;
   @ViewChild(PerformanceReturnsComponent,{static: false}) performanceReturnsComponent;
   @ViewChild(PerformanceTimeSeriesComponent,{static: false}) performanceTimeSeriesComponent;
   @ViewChild(PerformanceCalendarReturnsComponent,{static: false}) performanceCalendarReturnsComponent;
     show:boolean = true;
     iconClass:any = 'icon-minus noTextDecoration';
     cssClass:any = 'showRow';   
     defaultDateRange:any; 
    constructor(private dateService: DateService){
      
    }
   
   
    ngOnInit(){
      this.defaultDateRange = this.dateService.getCalendarDate();
      this.dateService.calendarDatePublish().subscribe(dateRange=>{
        this.defaultDateRange = dateRange;
      });
     
    }

    ngAfterViewInit(){
      this.performancePageModel.selectedDates.start = this.dateService.changeDateFormat(this.defaultDateRange.start);
      this.performancePageModel.selectedDates.end = this.dateService.changeDateFormat(this.defaultDateRange.end);
      this.performanceHeatChartComponent.update(this.performancePageModel );
      this.performanceReturnsComponent.update(this.performancePageModel );
      this.performanceTimeSeriesComponent.update(this.performancePageModel );
        this.performanceCalendarReturnsComponent.update(this.performancePageModel);
    }
   
    onNotify(performancePageModel: PerformancePageModel):void{    
      this.performancePageModel = performancePageModel;
      if(undefined != this.performanceHeatChartComponent && undefined != this.performanceReturnsComponent
      && undefined != this.performanceTimeSeriesComponent && undefined != this.performanceCalendarReturnsComponent){
        this.performanceHeatChartComponent.update(this.performancePageModel );
        this.performanceReturnsComponent.update(this.performancePageModel );
        this.performanceTimeSeriesComponent.update(this.performancePageModel );
        this.performanceCalendarReturnsComponent.update(this.performancePageModel);
      }
      
      
    }

    toggle() 
    {
      this.show = !this.show;
      if(this.show)  
      {
        this.iconClass = "icon-minus noTextDecoration ";
        this.cssClass="showRow";        
      }
      else
      {
         this.iconClass = "icon-plus noTextDecoration ";
        this.cssClass="hideRow";      
      }       
    }
 
 }