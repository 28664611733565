export class ReportFilter{
    ReportCode:any;
    ReportCustColumnsCode:any;
    AsOfDate:any;
    FromDate:any;
    ToDate:any;
    CurrencyCode:any;
    ReturnType:any;
    TimePeriod:any;
    CustomColums:any[];
}

export class SearchSecurity{
    srNo: any;
    securityIdentifier:any;
    securityName:any;
    show: boolean = false;
    indexDetailsModel :IndexDetailsModel[];
}

export class IndexDetailsModel{
    indexName:any;
    indexMarker:any;
    securityId:any;
}