import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewCoverageConfigForm } from '../../models/coverageconfig';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report } from '../../models/report';
import { formatDate, DatePipe } from '@angular/common';
import { CoverageConfigDataService } from '../../services/coverageconfig.service';
import { debug } from 'console';

@Component({
  selector: 'app-new-coverageconfigdata-popup',
  templateUrl: './new-coverageconfigdata-popup.component.html',
  styleUrls: ['./new-coverageconfigdata-popup.component.scss']
})
export class NewCoverageConfigPopupComponent implements OnInit {

  form: FormGroup = this.fb.group({
    parameterName: ['', Validators.required],
    manualOverrideflag: [''],
    classificationAttributeId: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
    percentageCut: ['', [Validators.required,Validators.pattern("^[0-9]+(.[0-9]{1,6})?$")]],
    classificationId: ['',[Validators.required,Validators.pattern("^[0-9]*$")]],
    dateToBeUsed: [''],
    classificationKeycolumn: ['',Validators.required],
    percentageThreshold: ['',Validators.pattern("^[0-9]*$")],
    parameterId: ['',Validators.pattern("^[0-9]*$")],
  } as Record<keyof NewCoverageConfigForm, any[]>);


  updateReport: boolean = false;
  isreportForm = false;
    isManualOverrideflag: boolean = false;

  constructor(private fb: FormBuilder, private coverageConfigDataService: CoverageConfigDataService, public dialogRef: MatDialogRef<NewCoverageConfigPopupComponent>, @Inject(MAT_DIALOG_DATA) public rowdata: any) {

    console.log(rowdata);

  }

  ngOnInit(): void {
    if (this.rowdata) {
      this.updateReport = true;
      this.form.patchValue({
        parameterName: this.rowdata.ParameterName,
        classificationAttributeId: this.rowdata.ClassificationAttributeId,
        classificationId: this.rowdata.ClassificationId,
        manualOverrideflag: this.rowdata.ManualOverrideflag,
        percentageCut: this.rowdata.PercentageCut,
        dateToBeUsed: this.rowdata.DateToBeUsed,
        percentageThreshold: this.rowdata.PercentageThreshold,
        classificationKeycolumn: this.rowdata.ClassificationKeycolumn,
        parameterId:this.rowdata.ParameterId
      })      
    }
     this.isManualOverrideflag = this.rowdata.ManualOverrideflag;

  }
    public onClickHandler(event: any): void {
    this.isManualOverrideflag = event.checked;
  }
  onSubmit(): void {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      data.manualOverrideflag = this.isManualOverrideflag;    

      this.dialogRef.close(data);
    }
    if (this.form.invalid) {
      this.isreportForm = true;
      return;
    }
  }
 } 
