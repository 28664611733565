
import {catchError, map} from 'rxjs/operators';
import { Injectable} from '@angular/core';
import { Http} from '@angular/http';
import { environment } from '../../environments/environment';
import { SearchRequest,indexAttribute } from '../models/search-model'
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ResponseModel} from '../models/landing-page-model';

@Injectable()
export class SearchService
{
    constructor(private httpClient: HttpClient){}
    search(term: string) {
        if (term === '') {
          return of([]);
        }
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });    
        return this.httpClient.post(environment.dataServiceRoot + 'ui/search/filterindexdescription',
        JSON.stringify({FilterValue: term}), {headers: headers}).pipe(
          map(response =>{
            var result = response as ResponseModel;
            var searchResults = result.data as any[];                    
            return searchResults;}),
          catchError(this.handleError),); 
      }

      searchAndGetDistinctValues(searchRequest: SearchRequest) {
        if (searchRequest.selectedField  === '') {
          return of([]);
        }

        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });    
        return this.httpClient.post(environment.dataServiceRoot + 'ui/search/SearchAndGetDistinctValues',
        JSON.stringify(searchRequest), {headers: headers}).pipe(
        map(response =>{
          var result = response as ResponseModel;
          var searchResults = result.data as any[];                    
          return searchResults;
        }),
        catchError(this.handleError),); 
      }

      searchAll(page:any,pageSize:any,searchRequest: any): Promise<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });    
        return this.httpClient.post(environment.dataServiceRoot + 'ui/search/GetIndexAttributes',
        JSON.stringify({
          Page: page,
          PageSize: pageSize,
          searchRequest 
      }), {headers: headers}).toPromise()
        .then(response =>{
          return response as any
         })
     .catch(this.handleError); 
      }

      getFiltredValues(searchFilter: any): Promise<indexAttribute[]>
      {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        
              return this.httpClient.post(environment.dataServiceRoot + 'ui/search/getfiltredvalues',
              JSON.stringify({Indexes: searchFilter.Indexes}), {headers: headers})
              .toPromise()
              .then(response =>{
                var result = response as ResponseModel;
                var searchResults = result.data as indexAttribute[]; 
                return searchResults;
                  })
              .catch(this.handleError); 
      }

    private handleError(error: any): Promise<any>
    {
        console.error('error occured',error);
        return Promise.reject(error.message || error);
    }
    
}

