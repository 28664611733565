import { Injectable } from '@angular/core';
import { NewReportForm, Report, ReportData, ExportReportDetails, ExportReportDetailsColumns, ExportReportData } from '../models/report';
import { BehaviorSubject, Observable, of, from, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewEsgconfigurationfixedincomeForm } from '../models/esgconfigurationfixedincome';
import { ResponseModel } from '../../models/landing-page-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { debug } from 'console';


@Injectable({
  providedIn: 'root'
})
export class EsgconfigurationfixedincomeDataService {

  public isFormValid = true;
  constructor(private httpClient: HttpClient) { }

  private _listners = new Subject<any>();
  clientPublish(): Observable<any> {
    return this._listners.asObservable();
  }


  publishClient(client: string) {
    this._listners.next(client);
  }

  fetchReportsFixedIncome$(pageIndex, pageSize): Observable<any> {
    return from(this.GetEsgconfigurationFixedIncomeData(pageIndex, pageSize));
  }
  fetchEsgconfigurationFixedIncomeData$(pageIndex, pageSize, filter): Observable<any> {
    return from(this.GetEsgconfigurationFixedIncomeDataByFilter(pageIndex, pageSize, filter));

  }

  creatEsgconfigurationFixedIncome(esgconfigurationForm: NewEsgconfigurationfixedincomeForm): Observable<string> {
    this.saveNewEsgconfigurationFixedIncome(esgconfigurationForm).then((a) => {
      if (a.data != 0) {
        //this.publishClient("created esgconfiguration data successfully!");
      }
      else {
        //this.publishClient(a.meta.message);
      }
    });
    return of(esgconfigurationForm.indexCode);

  }

  updateEsgconfigurationFixedIncome(esgconfigurationForm: NewEsgconfigurationfixedincomeForm): Observable<string> {
    this.saveEsgconfigurationFixedIncome(esgconfigurationForm).then((a) => {
      if (a.data != 0) {
        // this.publishClient("Updated esgconfiguration data successfully!");
      }
      else {
        // this.publishClient(a.data.message);
      }
    });
    return of(esgconfigurationForm.indexCode);
  }

  validateEsgconfigurationFixedIncome(indexCode: string): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/ValidateEsgconfigurationsFixedIncome', JSON.stringify({ indexCode: indexCode }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data
      }).catch(this.handleErr);
  }

  saveNewEsgconfigurationFixedIncome(esgconfigurationForm: NewEsgconfigurationfixedincomeForm): any {
    if (esgconfigurationForm.parentIndexCode != undefined ? esgconfigurationForm.indexCode.toLowerCase() != esgconfigurationForm.parentIndexCode.toLowerCase() : true) {
      this.validateEsgconfigurationFixedIncome(esgconfigurationForm.indexCode).then((res) => {
        if (res) {
          alert('Esg configuration fixed income data already exists!');
        }
        else {
          this.isFormValid = true;
          let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          return this.httpClient.post(
            environment.dataServiceRoot + 'ui/SITableConfigurations/AddEsgconfigurationsFixedIncome',
            JSON.stringify({
              IndexCode: esgconfigurationForm.indexCode,
              IndexFamilyId: esgconfigurationForm.indexFamilyId,
              ParentIndexCode: esgconfigurationForm.parentIndexCode,
              Currency: esgconfigurationForm.currency
                        }),

            { headers: headers }).toPromise()
            .then(response => {
              var result = response as ResponseModel;
              if (result.data != 0) {
                this.publishClient("Esgconfiguration fixed income data created successfully!");
              }
              else {
                this.publishClient(result.meta.message);
              }

            }).catch(this.handleErr);
        }
      });
    }
    else {
      alert('Index code and Parent Index code must be different');
    }
  }

  saveEsgconfigurationFixedIncome(esgconfigurationForm: NewEsgconfigurationfixedincomeForm): any {

    if (esgconfigurationForm.parentIndexCode != undefined ? esgconfigurationForm.indexCode.toLowerCase() != esgconfigurationForm.parentIndexCode.toLowerCase() : true) {
      this.isFormValid = true;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(
        environment.dataServiceRoot + 'ui/SITableConfigurations/UpdateEsgconfigurationsFixedIncome',
        JSON.stringify({
          IndexCode: esgconfigurationForm.indexCode,
          IndexFamilyId: esgconfigurationForm.indexFamilyId,
          ParentIndexCode: esgconfigurationForm.parentIndexCode,
          Currency: esgconfigurationForm.currency
        }),

        { headers: headers }).toPromise()
        .then(response => {
          var result = response as ResponseModel;
          if (result.data != 0) {
            this.publishClient("Updated Esgconfiguration Fixed Income data successfully!");
          }
          else {
            this.publishClient(result.meta.message);
          }
        }).catch(this.handleErr);
    } else {
      alert('Index code and Parent Index code must be different');
    }
  }

  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  
  GetEsgconfigurationFixedIncomeData(pageIndex, pageSize): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EsgconfigurationsFixedIncomeList', JSON.stringify({ page: pageIndex, pageSize: pageSize }),
      { headers: headers });
  }
    GetTableConfigurations(): Observable<any> {
      
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/TableConfigurations', null,
      { headers: headers });
  }
  GetEsgconfigurationFixedIncomeDataByFilter(pageIndex, pageSize, filter): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EsgconfigurationsFixedIncomeListByFilter', JSON.stringify({ pageSize: pageSize, page: pageIndex, filter: filter }),
      { headers: headers });
  }
  GetindexFixedIncomeList(): Observable<any> {
   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/EsgconfigurationsFixedIncomeIndexList',null,
     { headers: headers });
  }

 getCurrencies():Observable<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'ui/SITableConfigurations/GetCurrenciesList',null,
      {headers: headers});
  }
   getCountries():Observable<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'ui/SITableConfigurations/GetCountriesList',null,
      {headers: headers});
  }

  deleteEsgconfigurationFixedIncomeData(esgconfigurationForm: any): any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DeleteEsgconfigurationsFixedIncome',
      JSON.stringify({
        IndexCode: esgconfigurationForm.IndexCode
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        this.publishClient("Esgconfiguration Fixed Income data has been deleted");
        return result;
      }).catch(this.handleErr);
  }

  downloadFixedIncomeExcel(indexCode: string): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DownloadEsgconfigurationsFixedIncomeExcel',
      JSON.stringify({
        IndexCode: indexCode
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        return result;
      })
      .catch(this.handleErr);
  }

  }

