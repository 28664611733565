import { Component,OnInit } from '@angular/core';
import {MagellanColorService} from '../../services/magellancolor.service';

@Component({
    selector: 'sector-contribution-chart',
    templateUrl: './landing-sector-contribution-chart.component.html'
})
export class LandingSectorContributionChartComponent implements OnInit {      
    
     
    constructor( private magellanColorService : MagellanColorService) { }
    chartData : any;
    chartOptions : any;
    colors : any;
    chartLabels : any;
    datasetOverride : any;

    ngOnInit() {        
        this.colors = this.magellanColorService.getCurrentColorSchema().split(",");  
        this.magellanColorService.colorsPublish().subscribe(color => {            
            this.colors = color.split(",");                                
             this.chartData[0].backgroundColor =  this.colors;   
            this.chartData[1].backgroundColor =  this.colors; 
         });

        this.chartLabels = ["January", "February", "March", "April", "May", "June"],        
        this.chartData = [
                {data: [65, 59, 80, 81, 56, 55],
                    backgroundColor:  this.colors,
                },
                {data : [28, 48, 40, 19, 86, 27],
                    backgroundColor:  this.colors,
                }
            ],
        this.chartOptions = {
            scales: {
                yAxes: [{
                    ticks: {
                        fontSize: 9
                    }
                }
                ],
                xAxes: [{
                    ticks: {
                        maxRotation: 0,
                        fontSize: 9
                    }
                }]
            }
        }       
     }
}