import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { APIKeyModel } from '../../models/entitlement.model';
import { LicenceBandModel } from '../../models/entitlement.model';
import { CompanyModel } from '../../models/entitlement.model';
import { EntitlementService } from '../../services/entitlement.service';
import { ActivatedRoute } from '@angular/router';
// import {requireCheckboxesToBeCheckedValidator} from './require-checkboxes-to-be-checked.validator';

@Component({
  selector: 'app-api-keys',
  templateUrl: './api-keys.component.html',
  styleUrls: ['./api-keys.component.css']
})

export class ApiKeysComponent implements OnInit, OnChanges {
  apikeys: APIKeyModel;
  commitStatus: any;
  companies: CompanyModel[];
  filteredCompanies: CompanyModel[];
  selectedCompany: any;
  selectedBand: any;
  licenceBands: LicenceBandModel[];
  filteredBands: LicenceBandModel[];
  LimitValue = 0;
  apikeysearch: APIKeyModel[];
  constructor(private entitlementService: EntitlementService, private route: ActivatedRoute) { }

  ngOnInit() {

    this.initilize();
    this.entitlementService.getCompanies().then(result => {
      this.companies = result;
      console.log(this.companies);
    });
    this.entitlementService.getLicenceBands().then(result => {
      this.licenceBands = result;

      var sub = this.route.params.subscribe(params => {

        this.apikeys.companyId = JSON.parse(params['companyId']);
        this.apikeys.companyName = params['companyName'];
        this.apikeys.apiKeyId = params['apiKeyId'];
        this.apikeys.apiKeyName = params['apiKeyName'];
        this.apikeys.apiKeyDescription = params['apiKeyDescription'];
        this.apikeys.apiKeyValue = params['apiKeyValue'];
        this.apikeys.apiId = params['apiId'];
        this.apikeys.isEnabled = JSON.parse(params['isEnabled']);
        this.apikeys.isTermsCheckReq = JSON.parse(params['isTermsCheckReq']);
        this.apikeys.isConfirmed = JSON.parse(params['isConfirmed']);
        this.apikeys.isHistoryAPI = JSON.parse(params['isHistoryAPI']);
        this.apikeys.isMasterAPI = JSON.parse(params['isMasterAPI']);
        if (this.apikeys.isTermsCheckReq) {
          if (this.apikeys.apiKeyId != null) {
            this.apikeys.isResend = true;
          }
        }
        else {
          this.apikeys.isResend = false;
        }
        if (this.apikeys.apiKeyId == null) {
          this.apikeys.isDisabled = false;
        }
        else {
          this.apikeys.isDisabled = true
        }
        this.apikeys.stageName = params['stageName'];
        this.apikeys.usagePlanId = params['usagePlanId'];
        this.apikeys.usagePlanName = params['usagePlanName'];
        this.apikeys.usagePlanDescription = params['usagePlanDescription'];
        this.apikeys.burstLimit = params['burstLimit'];
        this.apikeys.rateLimit = params['rateLimit'];
        this.apikeys.id = params['id'];
        this.apikeys.licenceBand = params['licenceBand'];

      });
    });

  }
  onlyNumber(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  initilize() {
    this.apikeys = {
      apiKeyId: "", apiKeyName: "", apiKeyDescription: "",
      apiKeyValue: "", isDisabled: false, apiId: "", isEnabled: false, isTermsCheckReq: false,
      isConfirmed: false, stageName: "", burstLimit: null, rateLimit: null, companyId: "",
      companyName: "", usagePlanId: null, usagePlanKeyId: null, usagePlanName: "",
      usagePlanDescription: "", id: 0, isResend: false, licenceBand: "", isHistoryAPI: false, isMasterAPI: false
    };
    this.commitStatus = "";
  }

  ngOnChanges(changes: SimpleChanges) { }
  onCompanyChange(selectedCompany) {
    var filteredCompanies = this.companies.filter(x => x.companyId == selectedCompany);
    this.apikeys.companyId = filteredCompanies[0].companyId;
    this.apikeys.companyName = filteredCompanies[0].companyName;
  }
  onBandChange(selectedBand) {
    this.commitStatus = "";
    var filteredBand = this.licenceBands.filter(x => x.licenceBand == selectedBand);
    this.apikeys.licenceBand = filteredBand[0].licenceBand;
  }

  saveChanges() {
    if (this.apikeys.apiKeyValue == undefined) {
      this.apikeys.apiKeyValue = "";
    }
    if (this.apikeys.apiKeyId == undefined) {
      this.apikeys.apiKeyId = "";
    }

    this.entitlementService.getAPIKeys().then(result => {
      this.apikeysearch = result;
       console.log(result);
      if (this.apikeys.companyName == "" || this.apikeys.apiKeyName == "" ||
        this.apikeys.apiId == "" || this.apikeys.stageName == "" ||
        this.apikeys.usagePlanName == "" || this.apikeys.licenceBand == "") {

        this.commitStatus = "Please enter mandatory fields"
      }
      else if (this.apikeys.burstLimit == null || this.apikeys.rateLimit == null) {
        this.commitStatus = "Please enter numeric values for BurstLimit and RateLimit"
      }
      else if (this.apikeys.burstLimit <= this.LimitValue || this.apikeys.rateLimit <= this.LimitValue) {
        this.commitStatus = "Please enter a minimum value of 1 for BurstLimit and RateLimit"
      }

      else if (this.apikeys.isHistoryAPI == false && this.apikeys.isMasterAPI == false) {
        this.commitStatus = "At least one checkbox required between isHistoryApi and isMasterApi "
      }
      else if (this.apikeys.isHistoryAPI == true && (this.apikeys.licenceBand == "Band A" || this.apikeys.licenceBand == "Band B" || this.apikeys.licenceBand == "Band C")) {
        this.commitStatus = "You need to upgrade your licence band to access History API "
      }
      else if(this.apikeys.id == '' && this.apikeysearch.find(x => x.companyName == this.apikeys.companyName) != null) {
        this.commitStatus = "API Key for " +this.apikeys.companyName + " is already exist"
      }
      else {
        this.entitlementService.saveAPIKeys(this.apikeys).then(result => {

          if (result == "1") {
            alert("API Key: " + this.apikeys.apiKeyName + " " + 'Saved successfully');
            window.location.href = "/apikey-search"
            this.commitStatus = "Saved successfully"
          }
          else {
            this.commitStatus = "Not saved"
          }
        });
      }
    })
  }

  resendEmail() {
    if (this.apikeys.apiKeyValue == undefined) {
      this.apikeys.apiKeyValue = "";
    }
    this.entitlementService.resendEmail(this.apikeys).then(result => {
      if (result == "1") {
        alert("API Key: " + this.apikeys.apiKeyName + " " + 'Email resent successfully');
        window.location.href = "/apikey-search"
      }
      else {
        this.commitStatus = "Error while resending email"
      }
    });
  }
}
