import { Component, EventEmitter, Input, OnInit, Inject,Output, ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {SelectionModel} from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { ConnectContactLens } from 'aws-sdk';
import { ExportReportData, Report, ReportData } from '../../models/report';
import { CoverageConfigDataService } from '../../services/coverageconfig.service';
import { DownloadService } from '../../services/download.service';
import {S3ManagerService} from '../../../services/s3-manager.service';
import { saveAs } from 'file-saver/FileSaver';
import { ArrayDataSource } from '@angular/cdk/collections';
import * as CryptoJS from "crypto-js";
import * as AWS from 'aws-sdk';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';
import { Coverageconfig } from '../../models/coverageconfig';
import { environment } from '../../../../environments/environment';

enum ColumnNames {
  parameterName = 'ParameterName',
  classificationAttributeId = 'ClassificationAttributeId',
  classificationId = 'ClassificationId',
  manualOverrideflag = 'ManualOverrideflag',
  percentageCut = 'PercentageCut',
  dateToBeUsed = 'DateToBeUsed',
  percentageThreshold ="PercentageThreshold",
  classificationKeycolumn = 'ClassificationKeycolumn',
  parameterId='ParameterId',
  ModifiedUser = 'ModifiedUser',
  ModifiedTime = 'ModifiedTime',
  isUrgent = 'UrgentFlag',
  controls = 'controls'
}

@Component({
  selector: 'coverageconfig-data-grid',
  templateUrl: './coverageconfig-data-grid.component.html',
  styleUrls: ['./coverageconfig-data-grid.component.scss']
})
export class CoverageconfigDataGridComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() edit = new EventEmitter<Report>();
   @Output() classificationIdEvent = new EventEmitter<string>();
  dataSource: any;
  isVisible :boolean = false;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25];
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 5;
  pageCount: number = 0;
  pager: any = {};
  unsub: any;
  serchtext: any = '';
  coverageconfig: Coverageconfig[] = [];
  data: MatTableDataSource<any>;
  oldPageLimit: number;
  response: any;
  selectedId: any;
  selectedValue: any;
  activePageDataChunk: any;
  pageLength: number;
  filterForm: any;
  isupdate :boolean = false ;
  exportresultArray: ExportReportData[] = [];
  selectedreports: number[] = [];
  passphrase: string = "EncryptionatRest";
  isLoading: boolean = false;
  classificationId: any[];
  public classificationIdList: any[];
  displayColumnNameMap = new Map<ColumnNames, string>([
    [ColumnNames.parameterName,'Parameter Name'],
    [ColumnNames.classificationAttributeId, 'Classification Attribute Id'],
    [ColumnNames.classificationId, 'Classification Id'],
    [ColumnNames.manualOverrideflag, 'Manual Override Flag'],
    [ColumnNames.percentageCut, 'Percentage Cut'],
    [ColumnNames.dateToBeUsed, 'Date To Be Used'],
    [ColumnNames.percentageThreshold, 'Percentage Thresho Id'],
    [ColumnNames.classificationKeycolumn, 'Classification Key Column'],
    [ColumnNames.parameterId, 'Parameter Id'],
    [ColumnNames.ModifiedUser, 'Modified User'],
    [ColumnNames.ModifiedTime, 'Modified date']
  ]);
  displayedColumns: ColumnNames[] = [
    ColumnNames.parameterName,
    ColumnNames.classificationAttributeId,
    ColumnNames.classificationId,
    ColumnNames.manualOverrideflag,
    ColumnNames.percentageCut,
    ColumnNames.dateToBeUsed,
    ColumnNames.percentageThreshold,
    ColumnNames.classificationKeycolumn,
    ColumnNames.parameterId,
    ColumnNames.ModifiedUser,
    ColumnNames.ModifiedTime,
    ColumnNames.controls
  ];
  skipColumnsAutoRender = new Set<ColumnNames>([
    ColumnNames.controls
  ]);
  constructor(private router: Router, private coverageConfigDataService: CoverageConfigDataService, private downloadService: DownloadService, private fb: FormBuilder, private s3ManagerService:S3ManagerService) {
    this.unsub = this.coverageConfigDataService.clientPublish().subscribe((m: any) => {
      alert(m);
      this.getCoverageConfigData();
   
    });
  }

  ngOnInit(): void {
    this.filterForm = this.fb.group({
     ParameterName:  [""],
     ClassificationAttributeId:  [""],
     ClassificationId:  [""],
     ManualOverrideflag:  [""],
     PercentageCut:  [""],
      DateToBeUsed: [""],
      PercentageThreshold :[""],
      ClassificationKeycolumn: [""],
      ModifiedUser: [""],
      ModifiedTime: [""],
     ParameterId : [""]
 

    });
    this.filterForm.valueChanges.subscribe(value => {
      console.log(this.dataSource);
    
    });
        if (sessionStorage.getItem("UserRoles").indexOf(environment.siAdminGroup) != -1) {
            this.isVisible=true;
        }
        //enable update/delete button for testing in dev
        // this.isVisible=true;
       //end
    this.getCoverageConfigData();
    this.getClassificationIdList();
  }
   onClassificationIdChange(selectedId) {
    if (selectedId != undefined) {
      this.selectedValue = selectedId;
      this.getCoverageConfigDataByfilter(selectedId);
      this.classificationIdEvent.emit(selectedId);
    }
    else {
      this.selectedValue = undefined;
      this.getCoverageConfigData();
      this.classificationIdEvent.emit("0");
      this.serchtext = '';
      this.classificationIdList = this.classificationId;
     
    }
  }
  filterIndexList(val) {
    this.classificationIdList = this.classificationId.filter((id) => id.includes(val));
  }
  getClassificationIdList() {
    this.coverageConfigDataService.GetClassificationIdList().subscribe((res) => {
      this.classificationId = res.data;
      this.classificationIdList = res.data;
    });
  }
  getColumnsViewName(column: ColumnNames): string {
    return this.displayColumnNameMap.get(column) || '';
  }
  

  trim(text: any): string | any {
    if (typeof text === 'string') {
      return text.length < 28 ? text : text.slice(0, 25).trim() + '...';
    }
    return text;
  }
  
  getCoverageConfigData()
  {
    if (this.selectedValue != undefined) {
     this.getCoverageConfigDataByfilter(this.selectedValue);     
    }
    else {
      this.isLoading = true;
      this.coverageConfigDataService.fetchReports$(this.pageIndex, this.pageSize).subscribe((res) => {
        this.response = res;
        this.dataSource = this.response.Data;

        console.log("getCoverageConfigData");
        console.log(this.dataSource);
        this.isLoading = false;
        this.activePageDataChunk = this.dataSource;//.slice(0, this.pageSize);
        for (let item of this.activePageDataChunk) {
          item.ModifiedTime = (item.ModifiedTime).substring(0, 10);
          if(item.DateToBeUsed != undefined)
          item.DateToBeUsed = (item.DateToBeUsed).substring(0, 10)

        }
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].TotalCount : 0;

      });
    }
  }
  getCoverageConfigDataByfilter(filter)
  {
      this.isLoading = true;
      this.coverageConfigDataService.fetchReportsData$(this.pageIndex, this.pageSize, filter).subscribe((res) => {
        this.response = res;
        this.dataSource = this.response.Data;

        console.log("getCoverageConfigData");
        console.log(this.dataSource);
        this.isLoading = false;
        this.activePageDataChunk = this.dataSource;//.slice(0, this.pageSize);
        for (let item of this.activePageDataChunk) {
          item.ModifiedTime = (item.ModifiedTime).substring(0, 10);
          if(item.DateToBeUsed != undefined)
          item.DateToBeUsed = (item.DateToBeUsed).substring(0, 10)

        }
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].TotalCount : 0;

      });
    
  }
  
  openEditMetric(coverageConfig: any) {
    this.isupdate = true ;
    this.edit.emit(coverageConfig);
  }

 
  onPageChanged(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getCoverageConfigData();
  }
  ngOnDestroy() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
  }
  delete(coverageConfig : any) {
    if (confirm("Are you sure to delete coverage config Data?")) {
      this.coverageConfigDataService.deleteCoverageConfigData(coverageConfig);     
    }
  }
}
