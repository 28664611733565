import { Injectable }    from '@angular/core';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class MagellanColorService {
    colorsTable:string[] = new Array()
        
  constructor( ){
    this.colorsTable["IDW"] = "#7FBA00,#00B294,#FF8C00,#0072C6,#68217A,#00188F,#808080";
    this.colorsTable["FTSE"] = "#531732,#005B67,#71621D,#7F1340,#2C6C24,#866243,#696D6F";
    this.colorsTable["LSEG"] = "#16202C,#806734,#A69F90,#3B5676,#6B8DB5,#C6AA72,#737980";
    this.colorsTable["LSEGGREY"] = "#1D303F,#81693B,#546984,#8F8887,#E07C3C,#5A8D61,#A87DA7";
    this.colorsTable["Theme_1"]= "#F8B15B,#6895A6,#974465,#92C091,#7F7F7F,#9B7E6B";
    this.colorsTable["Theme_2"]= "#531732,#696D6F,#005B67,#F8B15B,#71621D,#1F9EC4";
  }
  private colorSubject = new Subject<any>(); 
 
  currentColorSchema = "IDW";

  setCurrentColorSchemaIdentifier(colorSchema : any) {   
    this.currentColorSchema = colorSchema;
    localStorage.setItem('colorSchemaChange',JSON.stringify(this.colorsTable[colorSchema]));
  }

  getCurrentColorSchema () {
    if(!JSON.parse(localStorage.getItem('colorSchemaChange')))
    this.setCurrentColorSchemaIdentifier(this.currentColorSchema);
    return JSON.parse(localStorage.getItem('colorSchemaChange')) ;       
  }

  publishColor(){
    this.colorSubject.next(this.getCurrentColorSchema());
  }

 colorsPublish():Observable<any>{
    return this.colorSubject.asObservable();
  }   

}
