import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ClientIndexMappings } from '../models/client-index-mapping';
import { ClientIndexDetails, DEFAULT_COLUMNS, newIndexMappingDetails, ClientIndexMappingColumns, ClientIndexMappingForm, ClientIndex } from '../models/client-index-mapping-details';
import { GridData } from '../pbr/models/common';
import { catchError, map } from 'rxjs/operators';
import { iResponseModel } from '../models/response.model';


export function createIndexMappingDetails({ data, columns, model }: Partial<ClientIndexDetails> = {}): ClientIndexDetails {
  return {
    columns: columns || [...DEFAULT_COLUMNS],
    data: data || [[]],
    model: newIndexMappingDetails(model),
  };
}

@Injectable({
  providedIn: 'root'
})
export class ClientIndexMappingService {
  readonly separator = ',';
  readonly escapeSign = '"';
  constructor(private httpClient: HttpClient) { }

  GetClientIndex(pageIndex, pageSize): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<any>(environment.dataServiceRoot + 'ui/ClientIndexMapping/GetClientIndex',
      JSON.stringify({ pageIndex: pageIndex, pageSize: pageSize }),
      { headers: headers });
  }
  GetClientIndexMappingDetails(companyId, reportTypeId): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<any>(environment.dataServiceRoot + 'ui/ClientIndexMapping/GetClientIndexDetails',
      JSON.stringify({ companyId: companyId, reportTypeId: reportTypeId }),
      { headers: headers });
  }

  async fetchClientIndexById(companyId, reportTypeId): Promise<ClientIndexDetails> {
    const model = await this.GetClientIndexById(companyId, reportTypeId);
    const clientIndexDetails = createIndexMappingDetails({ model });
    return (clientIndexDetails);
  }

  GetClientIndexById(companyId, reportTypeId): any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/ClientIndexMapping/GetClientIndexById',
      JSON.stringify({ companyId: companyId, reportTypeId: reportTypeId }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as iResponseModel;
        return result.data;
      }).catch(this.handleErr);

  }

  updateClientIndexMappingDetails(clientIndexForm: ClientIndexMappingForm, children: any) {
    console.log(children);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/ClientIndexMapping/UpdateClientIndexMappingDetails', JSON.stringify({ 
        Id: clientIndexForm.id,
        CompanyId: clientIndexForm.company,
        Company: clientIndexForm.reportGroup,
        ReportTypeId: clientIndexForm.reportTypeId,
        ReportGroup: clientIndexForm.reportGroup,
        ReportTypes: clientIndexForm.reportType,
        Children: children
      }),
      { headers: headers }).toPromise()
      .then(response => {
        return response as iResponseModel;
      }).catch(this.handleErr);
  }

  deleteClientIndexMappingDetails(deleteIds: number[]) {
    console.log(JSON.stringify({ deleteIds: deleteIds }));
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/ClientIndexMapping/DeleteClientIndexMappingDetails',
      JSON.stringify({ deleteIds: deleteIds }),
      { headers: headers }).toPromise()
      .then(response => {
        return response as iResponseModel;
      }).catch(this.handleErr);
  }

  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  private _listners = new Subject<any>();
  clientPublish(): Observable<any> {
    return this._listners.asObservable();
  }
  publishClient(client: string) {
    this._listners.next(client);
  }
  private handleError(error: any): Promise<any> {
    alert(error);
    return Promise.reject(error.message || error);
  }
  private brakeLineInToValues(row: string): string[] {
    const line = [];
    let value = '';
    let stack = [];
    for (let i = 0; i < row.length; i++) {
      if (!(stack.length % 2) && this.separator === row[i]) {
        line.push(value);
        stack = [];
        value = '';
      } else {
        if (row[i] === this.escapeSign) {
          stack.push(row[i]);
        }
        value += row[i];
      }
    }
    if (value) {
      line.push(value);
    }
    return line;
  }

  readClientIndexFileData$<T>(file: File): Observable<GridData<T>> {
    const fileData$ = new Subject<GridData<T>>();
    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = (evt) => {
      if (!(evt.target) || typeof (evt.target as any).result !== 'string') {
        return;
      }
      const fileContent: string = (evt.target as any).result;
      const lines = fileContent.split(/\r\n|[\n\v\f\r\x85\u2028\u2029]/)
        .filter(Boolean)
        .map((v) => this.brakeLineInToValues(v))
        .map(line => line.map((value = '') => {
          const unwrappedStr = (value[0] === '"' && value[value.length - 1] === '"') ? value.slice(1, -1) : value;
          return unwrappedStr.replace(/""/g, '"');
        }));
      const [documentColumns, ...documentData] = lines;
      const fieldsMapping = {
        'indexmarker': ClientIndexMappingColumns.indexMarker,
        'indexfamily': ClientIndexMappingColumns.indexFamily,
        'currency': ClientIndexMappingColumns.currency,
      };
      const columns = [...Object.keys(ClientIndexMappingColumns)];
      const columnsMap = columns.reduce((acc, item, i) => {
        acc[item] = i;
        return acc;
      }, {});
      const { length } = documentData;
      const data = Array.from({ length }, () => []);

      documentData.forEach((row, rowIndex) =>
        row.forEach((columnValue, docColumnIndex) => {
          const documentColumnName = documentColumns[docColumnIndex];
          if (documentColumns.length != 3) {
            return fileData$.error("File uploaded is not valid. Please download Sample file and fill in the data");
          }
          if (documentColumnName in fieldsMapping) {
            const columnIndex = columnsMap[fieldsMapping[documentColumnName]];
            if (!data[rowIndex][documentColumnName]) {
              const parser = this.getClientIndexParser(fieldsMapping[documentColumnName]);
              data[rowIndex][documentColumnName] = parser(columnValue);
            }
          }
          else {
            return fileData$.error(documentColumnName + " is not valid. Please download Sample file and fill in the data");
          }
        }
        ));
      fileData$.next({ columns, data, model: null });
    };
    return fileData$.asObservable();
  }
  getClientIndexParser(fieldName: ClientIndexMappingColumns): (rawValue: string) => string {
    // const DEFAULT_PRECISION = 12;
    const parserMapper: Partial<Record<ClientIndexMappingColumns, (rawValue: string) => string>> = {
      [ClientIndexMappingColumns.currency]: (rawValue) => {
        return (rawValue);
      }
    };
    return parserMapper[fieldName] || ((s) => s);
  }

  createClientIndex$(clientIndexMappingForm: ClientIndexMappingForm): Observable<string> {
    console.log(clientIndexMappingForm);
    var indexMarkers = [], result = [];
    return this.readClientIndexFileData$<ClientIndex>(clientIndexMappingForm.file).pipe(
      map((data) => {
        console.log(data.data);
        data.data.forEach(item => {
          indexMarkers.push(Object.assign({}, item));
        });
        if (indexMarkers.length > 0) {
          var valid: boolean = true;

          var row = 2;
          for (let detail of indexMarkers) {
            var test: ClientIndexMappings = this.mapClientIndexToMappings(detail);
            if (test.indexMarker == "") {
              this.publishClient(`Row ${row}:Invalid Index Marker`)
              valid = false;
              break;
            }
            else if (test.indexMarker.length > 50) {
              this.publishClient(`Row ${row}:Invalid Index Marker`)
              valid = false;
              break;
            }
            else if (test.indexFamily == "") {

              this.publishClient(`Row ${row}:Invalid Index Family`)
              valid = false;
              break;
            }
            else if (test.indexFamily.length > 25) {

              this.publishClient(`Row ${row}:Invalid Index Family`)
              valid = false;
              break;
            }
            else if (test.currency.length > 5) {
              this.publishClient(`Row ${row}:Invalid Currency`)
              valid = false;
              break;
            }
            result.push(test);
            row++;
          }
        }
        else {
          this.publishClient("Invalid");
        }

        if (valid == true) {
          this.saveClientIndex(clientIndexMappingForm, result).then((response: iResponseModel) => {
            if (response.uiNotification.length <= 0)
            this.publishClient('Saved');
            else this.publishClient(response.uiNotification.join('\n'));
            
          });
        }
        return clientIndexMappingForm.company;
      }),
      catchError(this.handleError)
    );
  }

  mapClientIndexToMappings(data: any): ClientIndexMappings {
    var clientIndexData: ClientIndexMappings = {
      indexMarker: "",
      indexFamily: "",
      currency: "",
      isActive: true
    };

    clientIndexData.indexFamily = data.indexfamily == undefined ? "" : data.indexfamily;
    clientIndexData.indexMarker = data.indexmarker == undefined ? "" : data.indexmarker;
    clientIndexData.currency = data.currency == undefined ? "" : data.currency;
    return clientIndexData;
  }

  saveClientIndex(clientIndexForm: ClientIndexMappingForm, children: any): any {
    if (clientIndexForm.id == null) {
      clientIndexForm.id = 0
    }
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/ClientIndexMapping/SaveClientIndexMapping',
      JSON.stringify({
        Id: clientIndexForm.id,
        CompanyId: clientIndexForm.company,
        Company: clientIndexForm.reportGroup,
        ReportTypeId: clientIndexForm.reportTypeId,
        ReportGroup: clientIndexForm.reportGroup,
        ReportTypes: clientIndexForm.reportType.filter(x => x !== 0),
        Children: children
      }),

      { headers: headers }).toPromise()
      .then(response => {
        return response as iResponseModel;
      }).catch(this.handleError);
  }

  updateClientIndexMapping(updated: ClientIndexMappingForm) {
    console.log(updated);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/ClientIndexMapping/SaveClientIndexMapping', JSON.stringify({
        CompanyId: updated.company,
        ReportTypeId: updated.reportType,
        ReportGroup: updated.reportGroup,
        PreviousCompanyId: Number(localStorage.getItem('previousCompanyId')),
        PreviousReportTypeId: Number(localStorage.getItem('previousReportTypeId'))
      }),
      { headers: headers }).toPromise()
      .then(response => {
        return response as iResponseModel;
      }).catch(this.handleErr);
  }
}

