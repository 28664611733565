import { Component, Input,Output,EventEmitter  } from '@angular/core';

@Component({
    selector: 'chart-options',
    templateUrl: './chart-options.component.html'
})
export class ChartOptionsComponent {

    @Input() visible: boolean = true;
    @Input() popUpVisible: boolean = true;
    @Input() graphVisible: boolean = true;
    @Input() settingVisible: boolean = true;
    @Input() widgetVisible: boolean = true;
    @Input() exportPNGVisible: boolean = true;    
    @Output() exportElement: EventEmitter<string> = new EventEmitter();
    @Output() exportPNG: EventEmitter<string> = new EventEmitter();
    @Output() popupElement: EventEmitter<string> = new EventEmitter();
    @Output() widgetElement: EventEmitter<string> = new EventEmitter();
    ExportXLS():void{
        this.exportElement.emit(' ');
    }
    ExportPNG():void{
        this.exportPNG.emit(' ');
    }
    PopupChart():void{
        this.popupElement.emit(' ');
    }
    PopupWidget():void{
        this.widgetElement.emit(' ');
    }

    
}