import { Injectable }    from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import {ValuesGrowthOfUnit} from '../models/values-growth-of-unit-model';
import {ValuesConstituentCount} from '../models/values-constituent-count-model';
import {ValuesMarketCap} from '../models/values-market-cap-model';
import {IndexValues} from '../models/index-values';
import {ReturnsCalculation} from '../models/returns-calculation.model';
import {ResponseModel} from '../models/landing-page-model';

import {SanetizeStringService} from '../services/sanetize-string.service';

@Injectable()
export class ValuesService {

  private headers = new Headers({'Content-Type': 'application/json'});
  
  constructor( private httpClient: HttpClient,
private sanetizeStringService:SanetizeStringService) { 
  }

  getGrowthofUnitByValues(fromDate: any,toDate:any,indexMarkers : any,currencyCode : any,timePeriod : any,returnType : any) : Promise<ValuesGrowthOfUnit> {
       
       let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
       sessionStorage.setItem('apiURL',environment.swaggerApiUrl);     
      return this.httpClient.post(environment.dataServiceRoot + 'ui/GrowthChart/getgrowthofunitbyvalues',
          JSON.stringify({
              FromDate : fromDate,
              ToDate : toDate,
              IndexMarkers : indexMarkers,              
              CurrencyCode :currencyCode,
              TimePeriod : timePeriod,
              ReturnType : returnType
      }), {headers: headers})
      .toPromise()
      .then(response =>{
           return response as ResponseModel
          })
      .catch(this.handleError);    
  }


 getIndexValuesConstituentCountMSChart(asOfDate:any,indexMarkers : any,currencyCode : any) : Promise<ResponseModel> {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         

   return this.httpClient.post(environment.dataServiceRoot + 'ui/values/getindexvaluesconstituentcountmschart',
       JSON.stringify({  
           AsOfDate:asOfDate,         
           IndexMarkers : indexMarkers,
           CurrencyCode :currencyCode          
   }), {headers: headers})
   .toPromise()
   .then(response =>{
        return response as ResponseModel
       })
   .catch(this.handleError);    
}

getIndexValuesMSChart(asOfDate:any,indexMarkers : any,currencyCode : any) : Promise<ResponseModel> {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         

   return this.httpClient.post(environment.dataServiceRoot + 'ui/values/getindexvaluesmschart',
       JSON.stringify({   
           AsOfDate:asOfDate,        
           IndexMarkers : indexMarkers,
           CurrencyCode :currencyCode         
   }), {headers: headers})
   .toPromise()
   .then(response =>{
        return response as ResponseModel
       })
   .catch(this.handleError);    
}

getValues(asOfDate:any,indexMarkers : any,currencyCode : any,timePeriod:any) : Promise<Array<any>> {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         

   return this.httpClient.post(environment.dataServiceRoot + 'ui/values/getvaluesgrid',
       JSON.stringify({  
           AsOfDate:asOfDate,         
           IndexMarkers : indexMarkers,
           CurrencyCode :currencyCode,
           TimePeriod : timePeriod         
   }), {headers: headers})
   .toPromise()
   .then(response =>{
        return response as ResponseModel
       })
   .catch(this.handleError);    
}

getValuesToExport(asOfDate:any,indexMarkers : any,currencyCode : any,timePeriod:any,selectedColumns:string[]):Promise<any>{
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         
    
       return this.httpClient.post(environment.dataServiceRoot + 'ui/values/getvaluesgridtoexport',
           JSON.stringify({     
               AsOfDate:asOfDate,      
               IndexMarkers : indexMarkers,
               CurrencyCode :currencyCode,
               TimePeriod : timePeriod,
               SelectedColumns:selectedColumns          
       }), {headers: headers})
       .toPromise()
       .then(response =>{
            return response as ResponseModel
           })
       .catch(this.handleError);
}

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); 
    return Promise.reject(error.message || error);
}

  getGrowthofUnitByValuesData(fromDate: any,toDate:any,indexMarkers : any,currencyCode : any,timePeriod : any,returnType : any) : Promise<ResponseModel> {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         

   return this.httpClient.post(environment.dataServiceRoot + 'ui/values/getgrowthofunitbyvalueschartdata',
       JSON.stringify({
           FromDate : fromDate,
           ToDate : toDate,
           IndexMarkers : indexMarkers,              
           CurrencyCode :currencyCode,
           TimePeriod : timePeriod,
           ReturnType : returnType
   }), {headers: headers})
   .toPromise()
   .then(response =>{
        return response as ResponseModel
       })
   .catch(this.handleError);    
}

getIndexValuesConstituentCountMSChartData(asOfDate:any,indexMarkers : any,currencyCode : any) : Promise<ResponseModel> {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         

   return this.httpClient.post(environment.dataServiceRoot + 'ui/values/getindexvaluesconstituentcountmschartdata',
       JSON.stringify({    
           AsOfDate:asOfDate,       
           IndexMarkers : indexMarkers,
           CurrencyCode :currencyCode         
   }), {headers: headers})
   .toPromise()
   .then(response =>{
        var res =response as ResponseModel;
        return res.data;
       })
   .catch(this.handleError);    
}

getIndexValuesMSChartData(asOfDate:any,indexMarkers : any,currencyCode : any) : Promise<ResponseModel> {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });         

   return this.httpClient.post(environment.dataServiceRoot + 'ui/values/getindexvaluesmschartdata',
       JSON.stringify({ 
           AsOfDate:asOfDate,          
           IndexMarkers : indexMarkers,
           CurrencyCode :currencyCode         
   }), {headers: headers})
  .toPromise()
   .then(response =>{
        var res =response as ResponseModel;
        return res.data;

       })
   .catch(this.handleError);    
}

queueRequest(startDate:any,endDate:any,timePeriod:any,indexMarkers:any):Promise<any>{
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    var userId =  sessionStorage.getItem("LoggedInUser");
    var sanetizedUser = this.sanetizeStringService.sanitizeStringLiteral(userId);

    return this.httpClient.post(environment.dataServiceRoot + 'ui/Values/QueueRequest',
        JSON.stringify({
            StartDate : startDate,
            EndDate:endDate,
            TimePeriod:timePeriod,
            IndexMarkers : indexMarkers,
            UserId:sanetizedUser                   
    }), {headers: headers})
    .toPromise()
    .then(response =>{
            return response as ResponseModel
        })
    .catch(this.handleError);  
}


}
