import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';


@Pipe({
  name: 'filterCurrency'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, search: any): any {
    if (search === undefined || value ===undefined) return search;    
    return value.filter(x => x.currencycode == search) ;   
      
    }
}


@Pipe({
  name: 'filterReturnType'
})
export class FilterReturnTypePipe implements PipeTransform {

  transform(value: any, search: any): any {
    if (search === undefined || value === undefined ) return search;
    return value.filter(function (x){       
      return x.returntype.includes(search);
    });
  }

}



@Pipe({
  name: 'filterHoldingsCurrency'
})
export class FilterHoldingsCurrencyTypePipe implements PipeTransform {

  transform(value: any, search: any): any {
    if (search === undefined || value === undefined ) return search;
    return value.filter(function (x){       
      return x.priceCurrency.includes(search);
    });
  }

}

@Pipe(
  {
    name: 'filterDDS'
  }
)
export class FilterDDSPipe implements PipeTransform
{
  transform(ddsFiles: any, 
    feedLoadGroupFilter: string, fileNameFilter: string, lastModifiedDateFilter: string, s3BucketPathFilter: string,versionNumberFilter: string, ddsPathFilter: string ): any{
      if(ddsFiles && ddsFiles.length)
      {
        return ddsFiles.filter(item => {
          if (fileNameFilter && item.fileName.toLowerCase().indexOf(fileNameFilter.toLowerCase()) === -1){
            return false;
          }
          if (lastModifiedDateFilter && item.lastModifiedDate.toLowerCase().indexOf(lastModifiedDateFilter.toLowerCase()) === -1){
            return false;
          }
          if (feedLoadGroupFilter && item.feedLoadGroup.toLowerCase().indexOf(feedLoadGroupFilter.toLowerCase()) === -1){
            return false;
          }
          if (s3BucketPathFilter && item.s3BucketPath.toLowerCase().indexOf(s3BucketPathFilter.toLowerCase()) === -1){
            return false;
          }
          if (versionNumberFilter && item.versionNumber.toLowerCase().indexOf(versionNumberFilter.toLowerCase()) === -1){
            return false;
          }
          if (ddsPathFilter && item.ddsPath.toLowerCase().indexOf(ddsPathFilter.toLowerCase()) === -1){
            return false;
          }
            return true;
          })
          }
          else
          {
            return ddsFiles;
          }
         }
       }

@Pipe(
  {
    name: 'filterReviewSchedules'
  }
)
export class FilterReviewSchedulePipe implements PipeTransform
{
  transform(
    reviewSchedules: any, 
    indexFilter: string, 
    indexSeriesFilter: string,
    universeBenchmarkFilter: string,
    cutOffDateFilter: string,
    CutOffCalendarDateFilter: string,
    announceDateFilter: string,
    announceCalenderDateFilter: string,
    techNoticeDeliveryDateFilter: string,
    techNoticeDeliveryCalenderDateFilter: string): any {
   if(reviewSchedules && reviewSchedules.length)
   {
     var filtersResutl =  reviewSchedules.filter(item => {
      if (indexFilter && item.index.toLowerCase().indexOf(indexFilter.toLowerCase()) === -1){
        return false;
     }
     if (indexSeriesFilter && item.indexSeries.toLowerCase().indexOf(indexSeriesFilter.toLowerCase()) === -1){
      return false;
    }
    if (universeBenchmarkFilter && item.universeBenchmark.toLowerCase().indexOf(universeBenchmarkFilter.toLowerCase()) === -1){
    return false;
    }
    if (cutOffDateFilter && item.cutOffDate.toLowerCase().indexOf(cutOffDateFilter.toLowerCase()) === -1){
      return false;
    }
    if (CutOffCalendarDateFilter && item.CutOffCalendarDate.toLowerCase().indexOf(CutOffCalendarDateFilter.toLowerCase()) === -1){
      return false;
    }
    if (announceDateFilter && item.announceDate.toLowerCase().indexOf(announceDateFilter.toLowerCase()) === -1){
      return false;
    }
    if (announceCalenderDateFilter && item.announceCalenderDate.toLowerCase().indexOf(announceCalenderDateFilter.toLowerCase()) === -1){
      return false;
    }
    if (techNoticeDeliveryDateFilter && item.techNoticeDeliveryDate.toLowerCase().indexOf(techNoticeDeliveryDateFilter.toLowerCase()) === -1){
      return false;
    }
    if (techNoticeDeliveryCalenderDateFilter && item.techNoticeDeliveryCalenderDate.toLowerCase().indexOf(techNoticeDeliveryCalenderDateFilter.toLowerCase()) === -1){
      return false;
    }
    return true;
   })
   }
   else
   {
     return reviewSchedules;
   }
   return filtersResutl;
  }
}

@Pipe({
  name: 'filterReviewProducts'
})
export class FilterReviewProductsPipe implements PipeTransform {

  transform(reviewProducts: any, descriptionFilter: string, productCodeFilter: string, reviewProductDateFilter: string, calendarDateFilter: string, webMenuFilter: string): any {    
      if (reviewProducts && reviewProducts.length){
          return reviewProducts.filter(item =>{             
              if (descriptionFilter && item.description.toLowerCase().indexOf(descriptionFilter.toLowerCase()) === -1){
                  return false;
              }
              if (productCodeFilter && item.productCode.toLowerCase().indexOf(productCodeFilter.toLowerCase()) === -1){
                  return false;
              }
              if (reviewProductDateFilter && item.productDate.toLowerCase().indexOf(reviewProductDateFilter.toLowerCase()) === -1){
                  return false;
              }
              if (calendarDateFilter && item.calendarDate.toLowerCase().indexOf(calendarDateFilter.toLowerCase()) === -1){
                return false;
              }
              if (webMenuFilter && item.webMenu.toLowerCase().indexOf(webMenuFilter.toLowerCase()) === -1){
                  return false;
              }
              return true;
          })
      }
      else{
          return reviewProducts;
      }
  }

}


@Pipe({
  name: 'filterTelemetryValues'
})
export class FilterTelemetryProducts implements PipeTransform {

  transform(chartDetailsCountry: any, createDateFilter: string, userIDFilter: string, eventIDFilter: string, ipAddressFilter: string,countryFilter:string, cityFilter: string, continentFilter: string, regionNameFilter: string): any {    
      if (chartDetailsCountry && chartDetailsCountry.length){
          return chartDetailsCountry.filter(item =>{             
              if (createDateFilter && item.createDate.toLowerCase().indexOf(createDateFilter.toLowerCase()) === -1){
                  return false;
              }
              if (userIDFilter && item.userID.toLowerCase().indexOf(userIDFilter.toLowerCase()) === -1){
                  return false;
              }
              if (eventIDFilter && item.eventID.toLowerCase().indexOf(eventIDFilter.toLowerCase()) === -1){
                  return false;
              }
              if (ipAddressFilter && item.ipAddress.toLowerCase().indexOf(ipAddressFilter.toLowerCase()) === -1){
                return false;
              }
              if (countryFilter && item.country.toLowerCase().indexOf(countryFilter.toLowerCase()) === -1){
                return false;
              }
              if (cityFilter && item.city.toLowerCase().indexOf(cityFilter.toLowerCase()) === -1){
                  return false;
              }
              if (continentFilter && item.continentName.toLowerCase().indexOf(continentFilter.toLowerCase()) === -1){
                return false;
              }
              if (regionNameFilter && item.regionName.toLowerCase().indexOf(regionNameFilter.toLowerCase()) === -1){
                return false;
            }



              return true;
          })
      }
      else{
          return chartDetailsCountry;
      }
  }

}

@Pipe({
  name: 'filterEvent'
})
export class FilterEvents implements PipeTransform {

  transform(eventDetails: any, nameFilter: string, valueFilter: string): any {    
      if (eventDetails && eventDetails.length){
          return eventDetails.filter(item =>{             
              if (nameFilter && item.name.toLowerCase().indexOf(nameFilter.toLowerCase()) === -1){
                  return false;
              }
              if (valueFilter && item.value.toLowerCase().indexOf(valueFilter.toLowerCase()) === -1){
                  return false;
              }
             
              return true;
          })
      }
      else{
          return eventDetails;
      }
  }

}


@Pipe({
  name: 'ukDateFormat'
})
export class UKDateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, 'dd/MM/yyyy');
  }
}


@Pipe({
  name: 'usDateFormat'
})
export class USDateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, 'MM/dd/yyyy');
  }
}

@Pipe(
  {
    name: 'productIndexMarkerFilter'
  }
)
export class ProductIndexMarkerPipe implements PipeTransform
{
  transform(productIndexMarkers: any, 
    filePrefixFilter: string, fileDescriptionFilter: string, fileTypeFilter: string, folderPathFilter: string,indexMarkerFilter: string): any{
      if(productIndexMarkers && productIndexMarkers.length)
      {
        return productIndexMarkers.filter(item => {
          if (filePrefixFilter && item.filePrefix.toLowerCase().indexOf(filePrefixFilter.toLowerCase()) === -1){
            return false;
          }
          if (fileDescriptionFilter && item.fileDescription.toLowerCase().indexOf(fileDescriptionFilter.toLowerCase()) === -1){
            return false;
          }
          if (fileTypeFilter && item.fileType.toLowerCase().indexOf(fileTypeFilter.toLowerCase()) === -1){
            return false;
          }
          if (folderPathFilter && item.folderPath.toLowerCase().indexOf(folderPathFilter.toLowerCase()) === -1){
            return false;
          }
          if (indexMarkerFilter && item.indexMarker.toLowerCase().indexOf(indexMarkerFilter.toLowerCase()) === -1){
            return false;
          }
            return true;
          })
          }
          else
          {
            return productIndexMarkers;
          }
         }
       }
       @Pipe(
        {
          name: 'searchRequest'
        }
      )
      export class SearchIndexMarkerPipe implements PipeTransform
      {
        transform(searchIndexMarkers: any, 
          indexMarkerModel: string, indexNameModel: string, productModel: string): any{
            if(searchIndexMarkers && searchIndexMarkers.length)
            {
              return searchIndexMarkers.filter(item => {
                if (indexMarkerModel && item.indexMarker.toLowerCase().indexOf(indexMarkerModel.toLowerCase()) === -1){
                  return false;
                }
                if (indexNameModel && item.indexName.toLowerCase().indexOf(indexNameModel.toLowerCase()) === -1){
                  return false;
                }
                if (productModel && item.fileType.toLowerCase().indexOf(productModel.toLowerCase()) === -1){
                  return false;
                }
                
                  return true;
                })
                }
                else
                {
                  return searchIndexMarkers;
                }
               }
             }
       @Pipe(
        {
          name: 'indexMarkerPropertiesFilter'
        }
      )
      export class IndexMarkerPropertiesPipe implements PipeTransform
      {
        transform(indexMarkerProperties: any, 
          indexMarkerFilter: string, indexNameFilter: string, assetClassCodeFilter: string, assetClassFilter: string,geographyCodeFilter: string,
        geographyFilter:string,styleFilter:string,styleCodeFilter:string,sizeCodeFilter:string): any{
            if(indexMarkerProperties && indexMarkerProperties.length)
            {
              return indexMarkerProperties.filter(item => {
                if (indexMarkerFilter && item.indexMarker.toLowerCase().indexOf(indexMarkerFilter.toLowerCase()) === -1){
                  return false;
                }
                if (indexNameFilter && item.indexName.toLowerCase().indexOf(indexNameFilter.toLowerCase()) === -1){
                  return false;
                }
                if (assetClassCodeFilter && item.assetClassCode.toLowerCase().indexOf(assetClassCodeFilter.toLowerCase()) === -1){
                  return false;
                }
                if (assetClassFilter && item.assetClass.toLowerCase().indexOf(assetClassFilter.toLowerCase()) === -1){
                  return false;
                }
                if (geographyCodeFilter && item.geographyCode.toLowerCase().indexOf(geographyCodeFilter.toLowerCase()) === -1){
                  return false;
                }
                if (geographyFilter && item.geography.toLowerCase().indexOf(geographyFilter.toLowerCase()) === -1){
                  return false;
                }
                if (styleFilter && item.style.toLowerCase().indexOf(styleFilter.toLowerCase()) === -1){
                  return false;
                }
                if (styleCodeFilter && item.styleCode.toLowerCase().indexOf(styleCodeFilter.toLowerCase()) === -1){
                  return false;
                }
                if (sizeCodeFilter && item.sizeCode.toLowerCase().indexOf(sizeCodeFilter.toLowerCase()) === -1){
                  return false;
                }
                  return true;
                })
                }
                else
                {
                  return indexMarkerProperties;
                }
               }
             }
