import { Component, OnInit ,Input,AfterViewInit,Inject,NgZone} from '@angular/core';
import { ValuesService } from '../../services/values.service';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { ValuePageModel } from '../../models/value-page-model';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {MagellanColorService} from '../../services/magellancolor.service';
import { LandingService } from '../../services/landing.service';
import {DateService} from '../../services/date.service';
import { BiService } from '../../services/BiService.service';
import {CurrencyService} from '../../services/currency.service';
import * as moment from 'moment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import{Chart} from 'chart.js';
import { saveAs } from 'file-saver/FileSaver';
import { Buffer } from 'buffer';

@Component({
  selector: 'landing-market-cap',
  templateUrl: './market-cap-returns-chart.component.html'
})
export class MarketCapReturnsChartComponent  { 
  
  asofDates : any;
  indexMarkers : any;  
  marketCapHeight:any;
  @Input() currencyCode : string;  
  chartAttributes: any;  
  dataSource: any;
  marketCapData : any;
  MarketCapChartLoader:boolean=true; 
  indexValuesMSChart : any;
  colors : any;
  chart:any;
  events:any;
  MarketCapChart:any;
  constructor(private landingService: LandingService,private dateService:DateService,private valuesService: ValuesService,
     private exportService: ExportToExcelService,private publishIndexMarkerService:PublishIndexMarkerService,
     private indexMarkerService:IndexMarkerService, private magellanColorService : MagellanColorService,private biService: BiService,
     private zone:NgZone,private currencyService:CurrencyService,public dialog: MatDialog,
     private idwChartExportService: IDWChartExportService) { }

  ngOnInit() {    
    this.indexMarkers = this.indexMarkerService.getIndexMarkers();
    this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
      this.MarketCapChartLoader=true;
      this.indexMarkers=result;
      this.chartSetup(result);
    });
    
    this.colors = this.magellanColorService.getCurrentColorSchema().split(',');
    this.magellanColorService.colorsPublish().subscribe(color => { 
      this.colors=color.split(',');
      this.MarketCapChart.data.datasets[0].backgroundColor= this.colors;
        this.MarketCapChart.update();
  })
   
      this.indexMarkers = this.indexMarkerService.getIndexMarkers();
      this.currencyCode = this.currencyService.getCurrencyCode();     

      this.asofDates = this.dateService.getCalendarDate().end;
      this.chartSetup(this.indexMarkers);
      
      this.dateService.calendarDatePublish().subscribe(dateRange=>{
        this.asofDates =  this.dateService.changeDateFormat(dateRange.end);
        this.chartSetup(this.indexMarkers);
    });
    this.events = {
      initialized: this.chartInitialized(event)
  }
    this.currencyService.currencyCodePublish().subscribe(currency=>{
      this.currencyCode =  currency;        
     this.chartSetup(this.indexMarkers);
  });  
      
  }

  openWidgetDialog():void{
    var event: any = {eventName: "MarketCap help dialog open"};
    this.biService.addEvent(event);
    let dialogRef = this.dialog.open(MarketCapReturnsChartComponentDialog, {
        width: '800px' , 
        data: this.indexMarkers     
      });
  }

  chartInitialized(event){
    return (eve, arg) => { 
        this.zone.run(() => {
            this.chart =eve.sender;
        });  
        
    };
      
  }

  
  private chartSetup(indexMarkers:any) {
    this.valuesService.getIndexValuesMSChart(this.asofDates,indexMarkers ,this.currencyCode).then(data => {     
      if(0 < data.data.dataset[0].data.length){        
      this.marketCapData = data.data;
      this.bindMarketCap(this.marketCapData);   
      this.MarketCapChart.update();
    }
    else{
      //this.dataSource = null;
      this.MarketCapChart.data.datasets[0].data = [];
      this.MarketCapChart.update();
      //this.bindMarketCap(this.dataSource);
    }    
    },onerror =>{
        this.MarketCapChartLoader =false;       
     });

    // this.chartAttributes = {
    //     "paletteColors":  this.colors,
    //     "bgColor": "#ffffff",
    //     "maxBarHeight" :"6",
    //     "showBorder": "0",
    //     "showCanvasBorder": "0",
    //     "usePlotGradientColor": "0",
    //     "showalternatehgridcolor": "0",
    //     "showplotborder": "0",
    //     "plotBorderAlpha": "10",
    //     "showValue": "0",
    //     "showValues": "0",
    //     "axisLineAlpha": "25",
    //     "divLineAlpha": "10",
    //     "alignCaptionWithCanvas": "0",
    //     "showAlternateVGridColor": "0",
    //     "captionFontSize": "14",
    //     "subcaptionFontSize": "14",
    //     "subcaptionFontBold": "0",
    //     "toolTipColor": "#ffffff",
    //     "toolTipBorderThickness": "0",
    //     "toolTipBgColor": "#000000",
    //     "toolTipBgAlpha": "80",
    //     "toolTipBorderRadius": "2",
    //     "toolTipPadding": "5",
    //     "chartRightMargin": "-10",
    //     "exportEnabled": "1",
    //     "exportShowMenuItem": "0",
    //     "formatNumberScale":"1"
    // };

   

  }

  bindMarketCap(data: any){
    // this.dataSource = {
    //   "chart": this.chartAttributes,
    //   "categories": data.categories,
    //   "data": data.dataset[0].data
    // } 
    var i;
    var value: any[] = new Array();
    var label: any[] = new Array();
    for(i=0;i<data.dataset[0].data.length;i++)
    {
      value[i]=data.dataset[0].data[i].value ;
      label[i]=data.dataset[0].data[i].label;
    }
  if (this.MarketCapChart) this.MarketCapChart.destroy();
    this.MarketCapChart = new Chart('MarketCapChart', {
      type: 'horizontalBar',
      data: {
          labels: label,
          datasets: [{
              label: '',
              barThickness: 6,
              barPercentage: 1, 
              data: value,              
              backgroundColor: this.colors,
              borderColor: this.colors,
              borderWidth: 0,
          }]
      },
      options: {
          legend: {
              display: false
          },
          tooltips: {      
            callbacks: {
              label: function(tooltipItem, data) {
                  var value = (data.datasets[0].data[tooltipItem.index] /Math.pow(10,12));
                  return value.toFixed(2)+ 'Trillion';
              }
        }
        },
          maintainAspectRatio: false,
          scales: {
              xAxes: [{
                  maxTicksLimit: 6,
                  ticks: {
                      beginAtZero: true,
                      callback: function(value, index, values) {
                          return  value/ 1000000000000 + ' Trillion';
                      },  
                      fontSize: 9,
                      fontColor: '#000000',
                      fontFamily: 'Roboto',
                      autoSkip: true,
                      maxRotation: 0,
                      minRotation: 0,
                     
                  }
                }],
              yAxes: [{
                  stacked: true,
                  angleLines: {
                      display: false
                    },
                  ticks: {
                      fontSize: 9,
                      fontColor: '#000000',
                      fontFamily: 'Roboto',
                  },
              }],
             
          },       
      },
      plugins: [{
        afterDraw: function(chart) {
          if (chart.data.datasets[0].data.length == 0) {
            // No data is present
            var ctx = chart.chart.ctx;
            var width = chart.chart.width;
            var height = chart.chart.height
            chart.clear();
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.font = "20px Roboto 'Helvetica Nueue'";
          ctx.fillText('No data to display', width / 2, height / 2);
             ctx.restore();
        }
        }
  }]
  });     
    this.MarketCapChartLoader=false;   
  }


  ExportXls(event):void{
    var event: any = {eventName: "Export MarketCap ", inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode}]};
    this.biService.addEvent(event);
    var inputData = {
      "exportType": "xls",
      "code": "value-getIndexValuesMSChartData",
      "xlsFileName": "MarketCapRawData",
      "asOfDate": this.asofDates,
      "indexMarkers": this.indexMarkers,
      "currencyCode": this.currencyCode
    };
    this.idwChartExportService.export(inputData);
  }

  ExportPNG(event):void{
    var event: any = {eventName: "Export Export MarketCap TO PNG", inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode}]};
    this.biService.addEvent(event);
  //   var inputData = {
  //     "exportType": "png",
  //     "legend": false,
  //     "dataSource": this.dataSource,
  //     "chartid" : "indexValuesMSChartExport",
  //     "type" : "bar2d",
  //     "targetDiv" : "exportPngDiv",
  //     "chartReference": this.chart,
  //     "pngFileName" : "MarketCapRawData",
  //     "chartTitle": "Market Cap",
  //     "chartDate": this.asofDates
  //  };
  //  this.idwChartExportService.export(inputData);
  var canvas = <HTMLCanvasElement> document.getElementById('MarketCapChart');  
  var imageUrl = canvas.toDataURL("image/png");
   var base64 = imageUrl.split("base64,")[1];
   var bufferArr = Buffer.from(base64,'base64');
   var blob = new Blob([bufferArr], { type: 'image/png' });
  saveAs(blob, "Market Cap.png");
}
  marketCapCurrencyCodeChange(currencyCode:any){
    this.currencyCode = currencyCode;
    this.chartSetup(this.indexMarkers);
}
}


@Component({   
  templateUrl: './market-cap-returns-chart.component.widget.dialog.html'
})
export class MarketCapReturnsChartComponentDialog{

@Input() indexes: string;
constructor(
public dialogRef: MatDialogRef<MarketCapReturnsChartComponentDialog>,
@Inject(MAT_DIALOG_DATA) public data: any) {
  this.indexes="";
   data.forEach(element => {
       if( this.indexes.length<3)
       {
          this.indexes=element;
       }else{
      this.indexes += ", "+ element;
       }
   });   
 }

onClose(): void {
    this.dialogRef.close();
}
}