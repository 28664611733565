import { Component, ElementRef, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import Chart from 'chart.js';

@Component({
  selector: 'app-report-performance-chart',
  templateUrl: './report-performance-chart.component.html',
  styleUrls: ['./report-performance-chart.component.css']
})
export class ReportPerformanceChartComponent implements OnInit, OnChanges {
  @Input() data: number[];
  @Input() labels: string[];
  @Input() chartOptions: object;
  @Input() color: string;
  @Input() PortfolioType:string;
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement> | undefined;
  constructor() { }

  ngOnInit(): void {
    if (this.PortfolioType=='B')
    {
    for (let i = 0; i < this.labels.length; i++) 
    {
   if(this.labels[i]=='Portfolio vs BM')
   this.labels[i]='Index vs BM';
  }
}
    //console.log(this.data);
    this.chartSetup();
  }


  ngOnChanges(changes: any) {

    // console.log(this.data);
    // console.log(changes);

    this.chartSetup();
  }

  chartSetup(): void {
    Chart.plugins.register({
      beforeDraw: function (chartInstance) {
        const ctx = chartInstance.chart.ctx;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
      }
    });   
    const optionsBarGreen = {
      type: 'horizontalBar',
      data: {
        labels: this.labels,
        datasets: [{
          data: this.data,
          backgroundColor: this.color,
          borderColor: "#FFFFFF",
            borderWidth: 1
        }]
      },
      options: {
        ...this.chartOptions,
        "animation": {
          "duration": 1,
          "onComplete": function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.fillStyle = "rgba(0, 0, 0, 1)";            
            if (this.data.datasets[0].data.length == 3) {
              this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                  var data = dataset.data[index];
                  ctx.fillText(data.toFixed(1) + "%", bar._model.x - 5, bar._model.y - 25);
                });
              });
            }
            else {
              this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                  var data = dataset.data[index];
                  ctx.fillText(data.toFixed(1) + "%", bar._model.x - 5, bar._model.y - 5,);
                });
              });
            }
          }
        },
      },
    };
    const chart = new Chart(this.canvas.nativeElement, optionsBarGreen);
  }
}
