import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject ,  Observable ,  BehaviorSubject } from 'rxjs';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import { BiService } from '../services/BiService.service';
import { UserProfileModel } from '../models/entitlement.model';
import * as AWS from 'aws-sdk';
import * as CryptoJS from 'crypto-js';
declare var idwApigClientFactory : any;

import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { utf8Encode } from '../../../node_modules/@angular/compiler/src/util';


@Injectable()
export class AuthenticateService {
  authIsLoading = new BehaviorSubject<boolean>(true);
  authDidFail = new BehaviorSubject<boolean>(false);
  authStatusChanged = new Subject<boolean>();
  registeredUser: CognitoUser;
  identityId: any;
  userId = new BehaviorSubject<string>(null);
  secretAccessKey: any;
  accessKeyId: any;
  sessionToken: any;
  signedHeaders : any;
  passphrase: string = "EncryptionatRest";


  constructor( private biservice: BiService) { }
  refreshSignIn(userId) : void {
    var credentials  =  JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('RestMessage'),this.passphrase).toString(CryptoJS.enc.Utf8));
    this.secretAccessKey = credentials.SecretKey;
    this.sessionToken = credentials.SessionToken;
    this.accessKeyId = credentials.AccessKeyId;
    this.authStatusChanged.next(true);
    this.authDidFail.next(false);
    this.authIsLoading.next(false);
    this.userId.next(userId);
  }

  cognitoSignIn(userId, pwd): void {
    this.authIsLoading.next(true);

    const POOL_DATA = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.clientId
    };

    const userPool = new CognitoUserPool(POOL_DATA);

    const authData = {
      Username: userId,
      Password: pwd
    };

    const authDetails = new AuthenticationDetails(authData);
    const userData = {
      Username: userId,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    const that = this;

    cognitoUser.authenticateUser(authDetails, {
      onSuccess (result: CognitoUserSession) {
        console.log("authenticateUser success");
        sessionStorage.setItem('CognitoAuthenticated', 'true');
        sessionStorage.setItem('LoggedInUser', authData.Username);
        sessionStorage.setItem('PWD',authData.Password);
        that.biservice.addUserInfo(authData.Username);
        that.userId.next(authData.Username);
        var loginDetails ={loginType: 'cognito',userId:authData.Username};
        sessionStorage.setItem('LoginDetails', JSON.stringify(loginDetails));
        sessionStorage.setItem("UserRoles","RES-RUS-IDW-Admin");

        cognitoUser.getUserAttributes(function(err, result) {
          if (err) {
            console.log("getUserAttributes failure");
              console.log(err);
              return;
          }
          console.log("getUserAttributes success");
          for (var i = 0; i < result.length; i++) {
            if(result[i].getName()== "custom:Roles")
            {
              sessionStorage.setItem("UserRoles",result[i].getValue());
              break;
            }
          }
      });

        AWS.config.region = environment.region;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: environment.identityPool,
        });

        var Logins = {};
        const token = result.getIdToken().getJwtToken();
        console.log("token: "+token);
        sessionStorage.setItem('apiToken',token);
        Logins['cognito-idp.' + AWS.config.region + '.amazonaws.com/' + environment.cognitoUserPoolId] = token

        var request = { AccountId: environment.accountId,IdentityPoolId: environment.identityPool,Logins: Logins };
        var identityId = (AWS.config.credentials as AWS.CognitoIdentityCredentials).identityId;
        var cognitoidentity = new AWS.CognitoIdentity();
        var t= that;
        cognitoidentity.getId(request, function(err, data) {
            if (err) {
              console.log("error at get id method");

              console.log(err, err.stack); console.log("getId failure");}
            else {
              console.log("getId success");
                var identityRequest = {IdentityId: data.IdentityId,Logins: Logins };
                cognitoidentity.getCredentialsForIdentity(identityRequest, function (error, tempCredentials) {
                    if (error) {
                 console.log(("Error at getcredentials for identity"));

                        console.log("getCredentialsForIdentity failure");
                        console.log(error, error.stack);
                    } else {
                        console.log("getCredentialsForIdentity success");
                        t.secretAccessKey = tempCredentials.Credentials.SecretKey;
                        t.sessionToken = tempCredentials.Credentials.SessionToken;
                        t.accessKeyId = tempCredentials.Credentials.AccessKeyId;
                        var restMessage = CryptoJS.AES.encrypt(JSON.stringify(tempCredentials.Credentials),that.passphrase).toString();
                        sessionStorage.setItem('RestMessage', restMessage);
                        that.authStatusChanged.next(true);
                        that.authDidFail.next(false);
                        that.authIsLoading.next(false);
                        // if(null!= callback && null!= param){
                        //   callback(param);
                        // }

                    }
                });
            }
        });
      },
      onFailure(err) {
        console.log("authenticateUser failure");
        console.log(err);
        that.authDidFail.next(true);
        that.authIsLoading.next(false);
        that.authStatusChanged.next(false);
      }
    });
    this.authStatusChanged.next(true);
    return;
  };
  
  adfsSignIn(): void {
    this.authIsLoading.next(true);
    this.authStatusChanged.next(true);
    var _client = idwApigClientFactory.newClient();
    localStorage.setItem('ADFSAuthenticated', 'true');
    const identityPool = environment.identityPool;

    AWS.config.update({
                region:environment.region
            });

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPool
    });

    this.identityId = localStorage.getItem('cognitoid');
    this.loginWorkflow();
  }

  loginWorkflow():void {
    var activelogin = localStorage.getItem('activelogin');

    var samlResponse = null;
    //var expiredSamlResponseJsonString = localStorage.getItem("samlResponse");
    var expiredSamlResponseJsonString = null;
    var expiredSamlResponse = (null!= expiredSamlResponseJsonString && undefined!= expiredSamlResponseJsonString)?
    JSON.parse(expiredSamlResponseJsonString):null;
      var currentTime = new Date().getTime();
      if(expiredSamlResponse!=null && expiredSamlResponse!=undefined &&  expiredSamlResponse["samlResponse"] != null
        && expiredSamlResponse["samlResponse"]!= undefined && expiredSamlResponse["samlResponse"] != "null"
    && currentTime<expiredSamlResponse["expiry"])
    {
      samlResponse =  expiredSamlResponse["samlResponse"];
    }
    else {
      samlResponse = this.getParameterByName('SAMLResponse','');
    }
    if (activelogin=='inProgress' && samlResponse != null ){
      if (samlResponse != null){
          this.getSamlCredentials(samlResponse.replace(/\s/g, ''));
          var url = window.location.href;
          window.history.pushState(null, "", url.split('?')[0]);
      }
    }
    else if (activelogin === null || samlResponse === null   ) {
      //First page visit. Redirect to ADFS login.
      this.connectionRedirect();
    }
    else {
      //Credentials exist, page refresh, etc.
      console.log('activelogin already exists in session and the value is ' + activelogin);
    }
  }

  getParameterByName(name, url) {
    if (!url) {
      url = window.location.href;
    }

    var param = url.split('?');

    if(param.length == 1) {
      return null;
    }

    var samlResponse = url.split('?')[1].replace('SAMLResponse=','');

    console.log(samlResponse);
    var decodedURI = decodeURIComponent(decodeURIComponent(decodeURIComponent(samlResponse.replace(/\+/g, " "))));
    console.log(decodedURI);
    return decodedURI;
  }

  getSamlCredentials(samlResponse) {
    sessionStorage.setItem("UserRoles","");
    var decodedXml = atob(samlResponse);
    var xml2js = require('xml2js');
    var parser = new xml2js.Parser();
    var extractedData = "";
    var extractedRoles ="";
    parser.parseString(decodedXml, function (err, result) {
      let root = JSON.stringify(result);



      extractedData = JSON.parse(root)["samlp:Response"]["Assertion"]["0"]["Subject"][0]["NameID"][0]["_"];     
      
      const xpath = require('xml2js-xpath/build/xpath');
      //const matches1 = xpath.find(result, `//Attribute[@Name='https://aws.amazon.com/SAML/Attributes/Role']`);
      //var matches = xpath.find(result, "//Attribute[@Name='http://schemas.xmlsoap.org/claims/Group']");
      var matches = xpath.find(result, "//Attribute[@Name='http://schemas.microsoft.com/ws/2008/06/identity/claims/groups']");
      if(matches[0] != undefined && matches[0].AttributeValue != undefined) {
        extractedRoles = matches[0].AttributeValue.join();
        console.log('ADGroups:' + extractedRoles);
      }
    });

    this.userId.next(extractedData);
    sessionStorage.setItem('LoggedInUser', this.userId.value);
    var role = environment.roleSelectedArn;
    var Logins = {};
    Logins[environment.samlIdpArn] = samlResponse;
    sessionStorage.setItem('LoginDetails', JSON.stringify(this.userId));
    var cognitoidentity = new AWS.CognitoIdentity();
    const that = this;

    var request = { AccountId: environment.accountId,IdentityPoolId: environment.identityPool,Logins: Logins };
    cognitoidentity.getId(request, function(err, data) {
      if (err) {
        console.log("get samlcrendentials get id error");
        console.log(err, err.stack);

      }
      else {
          that.identityId = data.IdentityId;
          localStorage.setItem('cognitoid', data.IdentityId);
          var params = {
            IdentityId: that.identityId,
            CustomRoleArn: role,
            Logins
          };
          console.log('IdentityId:' + that.identityId);
          console.log(params);
          const t = that;

          cognitoidentity.getCredentialsForIdentity(params, function(err, data) {
            if (err){
              console.log("get saml credentials from idendity error");
              console.log(err, err.stack);
            }
            else {
              AWS.config.credentials.accessKeyId = data.Credentials.AccessKeyId;
              AWS.config.credentials.secretAccessKey = data.Credentials.SecretKey;
              AWS.config.credentials.sessionToken = data.Credentials.SessionToken;
              that.biservice.addUserInfo(that.userId.value);
              that.secretAccessKey = data.Credentials.SecretKey;
              that.sessionToken = data.Credentials.SessionToken;
              that.accessKeyId = data.Credentials.AccessKeyId;
              var restMessage = CryptoJS.AES.encrypt(JSON.stringify(data.Credentials),that.passphrase).toString();
              sessionStorage.setItem('RestMessage', restMessage);
              sessionStorage.setItem("UserRoles", extractedRoles);
              t.authStatusChanged.next(true);
              t.authDidFail.next(false);
              t.authIsLoading.next(false);
            }
          });
      }
    });
  }

  getSignedHeaders(url, method, body) {
    var _client = idwApigClientFactory.newClient();
    var params = {
        AccessKey: this.accessKeyId,
        SecretKey: this.secretAccessKey,
        SessionToken: this.sessionToken,
        Url:url,
        region: environment.region,
        header: {'Content-Type': 'application/json' }
    };

    var headers = _client.getHeader(params, JSON.parse(body), method);


    this.signedHeaders = headers;
    return headers;
  }

  updateUserAttribute(userModel: UserProfileModel): void{

    const POOL_DATA = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.clientId
    };

    const userPool = new CognitoUserPool(POOL_DATA);

    var userData = {
      Username: userModel.userName,
      Pool: userPool
    };
    var authData = {
      Username: userModel.userName,
      Password: userModel.password
    };

    var cognitoUser = new CognitoUser(userData);
    var authDetails = new AuthenticationDetails(authData);

    cognitoUser.authenticateUser(authDetails, {
      onSuccess (result: CognitoUserSession) {
        var attributeList = [];
        var attribute = { Name : 'email', Value : userModel.email};
        var attributeEmail = new CognitoUserAttribute(attribute);
        var genderAttribute = {Name : 'gender',Value : userModel.gender};
        attributeList.push(new CognitoUserAttribute(genderAttribute));
        var phoneNumberAttribute = {Name : 'phone_number',Value : userModel.phoneNumber};
        attributeList.push(new CognitoUserAttribute(phoneNumberAttribute));
        var nameAttribute = {Name : 'name',Value : userModel.name};
        attributeList.push(new CognitoUserAttribute(nameAttribute));
        var companyNameAttribute = {Name : 'custom:companyname',Value : userModel.companyName};
        attributeList.push(new CognitoUserAttribute(companyNameAttribute));
        var ddsAccountAttribute = {Name : 'custom:DDSAccountNumber',Value : userModel.ddsAccountNumber};
        attributeList.push(new CognitoUserAttribute(ddsAccountAttribute));
        var roles = {Name : 'custom:Roles',Value : userModel.roles};
        attributeList.push(new CognitoUserAttribute(roles));
        attributeList.push(attributeEmail);
        cognitoUser.updateAttributes(attributeList,  function(err, result) {
          if (err) {
              alert(err);
              this.handleError(err);
          }
          console.log(result);
          alert("User details updated.");
      });

      },
      onFailure(err) {
        console.log(err);
      }
    });
  }

  registerUserProfile(userModel: UserProfileModel) : void
  {
    const POOL_DATA = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.clientId
    };

    const userPool = new CognitoUserPool(POOL_DATA);

    var attribute = { Name : 'email', Value : userModel.email};
    var attributeList = [];
    var attributeEmail = new CognitoUserAttribute(attribute);
    var genderAttribute = {Name : 'gender',Value : userModel.gender};
    attributeList.push(new CognitoUserAttribute(genderAttribute));
    var phoneNumberAttribute = {Name : 'phone_number',Value : userModel.phoneNumber};
    attributeList.push(new CognitoUserAttribute(phoneNumberAttribute));
    var nameAttribute = {Name : 'name',Value : userModel.name};
    attributeList.push(new CognitoUserAttribute(nameAttribute));
    var companyNameAttribute = {Name : 'custom:companyname',Value : userModel.companyName};
    attributeList.push(new CognitoUserAttribute(companyNameAttribute));
    var ddsAccountAttribute = {Name : 'custom:DDSAccountNumber',Value : userModel.ddsAccountNumber};
    attributeList.push(new CognitoUserAttribute(ddsAccountAttribute));
    var roles = {Name : 'custom:Roles',Value : userModel.roles};
    attributeList.push(new CognitoUserAttribute(roles));
    attributeList.push(attributeEmail);
    userPool.signUp(userModel.userName, userModel.password, attributeList, null, function(err, result) {
      if (err) {
          alert(err);
          this.handleError(err);
      }
      console.log(result);
      alert("User Registered");
  });
  }

  connectionRedirect(){
    var RPID = encodeURIComponent(environment.relayingPartyId);
    var result = environment.adfsUrl ;
    localStorage.setItem('activelogin', 'inProgress');
    window.location.href = result;
  }

}
