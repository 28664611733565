import { Component,OnInit } from '@angular/core';
import { LandingService } from '../../services/landing.service';
import { LandingPageModel } from '../../models/landing-page-model';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {DateService} from '../../services/date.service';
import {MagellanColorService} from '../../services/magellancolor.service';
import{CurrencyService}from '../../services/currency.service';
import * as moment from 'moment';

@Component({
    selector: 'top-navigation-chart',
    templateUrl: './landing-top-navigation-chart.component.html',
    styleUrls: ["./landing-top-navigation-chart.component.css"]
})
export class LandingTopNavigationChartComponent implements OnInit {      
    
 constructor(private landingService: LandingService ,private publishIndexMarkerService:PublishIndexMarkerService,private indexMarkerService:IndexMarkerService,
     private magellanColorService : MagellanColorService, private dateService:DateService,private currencyService:CurrencyService) { }    
    toDate : any ;
    fromDate:any ;
    testcolors : any;
    spikeChartFTSEGlobaldata : any;
    spikeChartFTSEGloballabels : any;
    spikeChartFTSEGlobalchartOptions : any;
    spikeChartFTSEDevelopedlabels : any;
    spikeChartFTSEDevelopeddata : any;
    spikeChartFTSEDevelopedchartOptions : any;
    spikeChartFTSESecondarylabels : any;                 
    spikeChartFTSESecondarydata : any;  
    spikeChartFTSESecondarychartOptions : any;
    spikeChartFTSEEmerginglabels  : any;                 
    spikeChartFTSEEmergingdata  : any; 
    spikeChartFTSEEmergingchartOptions : any;
    spikeChartFTSEAsialabels : any;                 
    spikeChartFTSEAsiadata : any;
    spikeChartFTSEAsiachartOptions : any;
    spikeChartFTSEAdvancedlabels : any;                 
    spikeChartFTSEAdvanceddata : any ;
    spikeChartFTSEAdvancedchartOptions : any;       

    FTSEGlobalDataindexName : any;
    FTSEGlobalDataindexValue : any;
    FTSEGlobalDataindexDifference : any;
    FTSEGlobalDatapercentChanged : any;
    FTSEGlobalDataasofDate : any;

    FTSEDevelopedDataindexName : any;
    FTSEDevelopedDataindexValue : any;
    FTSEDevelopedDataindexDifference : any;
    FTSEDevelopedDatapercentChanged : any;
    FTSEDevelopedDataasofDate : any;

    FTSESecondaryDataindexName : any;
    FTSESecondaryDataindexValue :any;
    FTSESecondaryDataindexDifference : any;
    FTSESecondaryDatapercentChanged : any;
    FTSESecondaryDataasofDate : any;

    FTSEEmergingDataindexName  : any;
    FTSEEmergingDataindexValue  : any;
    FTSEEmergingDataindexDifference : any;
    FTSEEmergingDatapercentChanged  : any;
    FTSEEmergingDataasofDate  : any;

    FTSEAsiaDataindexName : any;
    FTSEAsiaDataindexValue  : any;
    FTSEAsiaDataindexDifference  : any;
    FTSEAsiaDatapercentChanged  : any;
    FTSEAsiaDataasofDate  : any;

    FTSEAdvancedDataindexName : any;
    FTSEAdvancedDataindexValue : any;
    FTSEAdvancedDataindexDifference : any;
    FTSEAdvancedDatapercentChanged : any;
    FTSEAdvancedDataasofDate : any;

    topChartContainerFTSEGlobalClass : any;
    topChartContainerFTSEDevelopedClass : any;
    topChartContainerFTSESecondaryClass : any;
    topChartContainerFTSEEmergingClass : any;
    topChartContainerFTSEAsiaClass : any;
    topChartFTSEAdvancedClass :any ;   
    
    topNavigationchartLoader0:boolean =true;
    topNavigationchartLoader1:boolean =true;
    topNavigationchartLoader2:boolean =true;
    topNavigationchartLoader3:boolean =true;
    topNavigationchartLoader4:boolean =true;
    topNavigationchartLoader5:boolean =true;
    
    chartReady0 = false;
    chartReady1 = false;
    chartReady2 = false;
    chartReady3 = false;
    chartReady4 = false;
    chartReady5 = false;

    indexMarkers : any;  
    addindexMarkers : any;  
    delindexMarkers : any;  
    currencyCode : string;  
    returnType:string;
    sparkBindedData:LandingPageModel[] = [];

    colors : any;
    spikeChartFTSEGlobalcolors : any= [''];
    spikeChartFTSEGlobalcolorsLines: any=[''];    

    spikeChartFTSEDevelopedcolors : any= [''];  
    spikeChartFTSEDevelopedcolorsLines : any= ['']; 

    spikeChartFTSESecondarycolors : any = [''];  
    spikeChartFTSESecondarycolorsLines : any = ['']; 

    spikeChartFTSEEmergingcolors : any= [''];  
    spikeChartFTSEEmergingcolorsLines : any= ['']; 

    spikeChartFTSEAsiacolors : any= [''];    
    spikeChartFTSEAsiacolorsLines : any= ['']; 

    spikeChartFTSEAdvancedcolors : any = ['']; 
    spikeChartFTSEAdvancedcolorsLines : any = ['']; 

    dateFormat: any;


    ngOnInit() {        
        this.dateFormat = this.dateService.getDateFormat(); 
        this.dateService.dateFormatPublish().subscribe(dateFormat=>{
            this.dateFormat =  dateFormat;                          
        });
        this.currencyCode = this.currencyService.getCurrencyCode();
        this.returnType='T';
        this.spikeChartFTSEGlobaldata = [{data : [], backgroundColor: 'transparent', borderColor:[]}]; 
        this.spikeChartFTSEDevelopeddata  = [{data : [], backgroundColor: 'transparent', borderColor:[]}];
        this.spikeChartFTSESecondarydata = [{data : [] , backgroundColor: 'transparent', borderColor:[]}];
        this.spikeChartFTSEEmergingdata  = [{data : [], backgroundColor: 'transparent', borderColor:[]}];
        this.spikeChartFTSEAsiadata = [{data : [] , backgroundColor: 'transparent', borderColor:[]}];
        this.spikeChartFTSEAdvanceddata  = [{data : [] , backgroundColor: 'transparent', borderColor:[]}]; 
        this.indexMarkers = this.indexMarkerService.getIndexMarkers();
        this.publishIndexMarkerService.indexMarkerPublish().subscribe(res=>{        
            this.currencyCode = this.currencyService.getCurrencyCode();
            this.defaultSparkValues();             
            this.indexMarkers = this.indexMarkerService.getIndexMarkers();
            this.colors = this.magellanColorService.getCurrentColorSchema().split(",");
            this.setColor();
            this.chartSetup(res);
           this.chartReady0 = false;
           this.chartReady1 = false;
           this.chartReady2 = false;
           this.chartReady3 = false;
           this.chartReady4 = false;
           this.chartReady5 = false;
        });

        this.toDate = this.dateService.getCalendarDate().end;
        this.fromDate = new Date(this.dateService.getCalendarDate().end);
        this.fromDate.setDate(this.fromDate.getDate() - 30);
        this.fromDate=this.dateService.changeDateFormat(this.fromDate);
        this.dateService.calendarDatePublish().subscribe(dateRange=>{
            this.toDate =this.dateService.changeDateFormat(dateRange.end);
            this.fromDate = new Date(dateRange.end);
            this.fromDate.setDate(this.fromDate.getDate() - 30);
            this.fromDate=this.dateService.changeDateFormat(this.fromDate);
            this.setColor();
            this.chartSetup(this.indexMarkers);
        });
         
        this.colors = this.magellanColorService.getCurrentColorSchema().split(",");        
        this.setColor();
        this.chartSetup(this.indexMarkers);  
        this.magellanColorService.colorsPublish().subscribe(color => {
            this.indexMarkers = this.indexMarkerService.getIndexMarkers();                    
            this.colors = color.split(",");  
            this.setColor();
            this.chartSetup(this.indexMarkers);            
        });       
        
        this.currencyService.currencyCodePublish().subscribe(currency=>{
            this.currencyCode =  currency;                   
           this.defaultSparkValues();             
            this.indexMarkers = this.indexMarkerService.getIndexMarkers();
            this.colors = this.magellanColorService.getCurrentColorSchema().split(",");
            this.setColor();
            this.chartSetup(this.indexMarkers);
           this.chartReady0 = false;
           this.chartReady1 = false;
           this.chartReady2 = false;
           this.chartReady3 = false;
           this.chartReady4 = false;
           this.chartReady5 = false;
        });  
     }

     getPercentageCssClasses = function (strValue) {
        if ((typeof (strValue) == "string" && strValue.indexOf('-') > -1) ||
            (typeof (strValue) == "number" && 0 > strValue)) {
            return "negativePercentage";
        }
        return "positivePercentage";
    }

     setColor(){    
        for(var count =0 ; count <this.indexMarkers.length;count++){
            if(count==0){
                this.spikeChartFTSEGlobalcolors = this.colors[0];
                this.spikeChartFTSEGlobalcolorsLines.length = 0;
                this.spikeChartFTSEGlobalcolorsLines.push(this.colors[0]);                        
                if(this.colors[0]=='#7FBA00'){
                    this.topChartContainerFTSEGlobalClass = "topChartContainerFTSEGlobal";
                }
                if(this.colors[0]=='#531732'){
                    this.topChartContainerFTSEGlobalClass = "topChartContainerFTSEGlobalFTSE";
                }
                if(this.colors[0]=='#16202C'){
                    this.topChartContainerFTSEGlobalClass = "topChartContainerFTSEGlobalLSEG";
                }
                if(this.colors[0] == '#1D303F'){
                    this.topChartContainerFTSEGlobalClass = "topChartContainerFTSEGlobalLSEGGREY";
                }
                if(this.colors[0] == '#F8B15B'){
                    this.topChartContainerFTSEGlobalClass = "topChartContainerFTSEGlobalTheme_1";
                }
                if(this.colors[1] == '#696D6F'){
                    this.topChartContainerFTSEGlobalClass ="topChartContainerFTSEGlobalTheme_2";
            }
        }

            if(count==1){                      
                this.spikeChartFTSEDevelopedcolors = this.colors[1]; 
                this.spikeChartFTSEDevelopedcolorsLines.length = 0;
                this.spikeChartFTSEDevelopedcolorsLines.push(this.colors[1]); 
                if(this.colors[0]=='#7FBA00'){
                    this.topChartContainerFTSEDevelopedClass = "topChartContainerFTSEDeveloped";      
                }
                if(this.colors[0]=='#531732'){
                    this.topChartContainerFTSEDevelopedClass = "topChartContainerFTSEDevelopedFTSE";
                }
                if(this.colors[0]=='#16202C'){
                    this.topChartContainerFTSEDevelopedClass = "topChartContainerFTSEDevelopedLSEG";
                }
                if(this.colors[0] == '#1D303F'){
                    this.topChartContainerFTSEDevelopedClass = "topChartContainerFTSEDevelopedLSEGGREY";
                }
                if(this.colors[0] == '#F8B15B'){
                    this.topChartContainerFTSEDevelopedClass = "topChartContainerFTSEDevelopedTheme_1";
                }
                if(this.colors[1] == '#696D6F'){
                    this.topChartContainerFTSEDevelopedClass ="topChartContainerFTSEDevelopedTheme_2";
                }
            }

            if(count==2){             
                this.spikeChartFTSESecondarycolors = this.colors[2]; 
                this.spikeChartFTSESecondarycolorsLines.length = 0;
                this.spikeChartFTSESecondarycolorsLines.push(this.colors[2]);                            
                if(this.colors[0]=='#7FBA00'){
                    this.topChartContainerFTSESecondaryClass = "topChartContainerFTSESecondary";
                }
                if(this.colors[0]=='#531732'){
                    this.topChartContainerFTSESecondaryClass = "topChartContainerFTSESecondaryFTSE";
                }
                if(this.colors[0]=='#16202C'){
                    this.topChartContainerFTSESecondaryClass = "topChartContainerFTSESecondaryLSEG";
                }
                if(this.colors[0] == '#1D303F'){
                    this.topChartContainerFTSESecondaryClass = "topChartContainerFTSESecondaryLSEGGREY";
                }
                if(this.colors[0]=='#F8B15B'){
                    this.topChartContainerFTSESecondaryClass = "topChartContainerFTSESecondaryTheme_1";
                }
                if(this.colors[1] == '#696D6F'){
                    this.topChartContainerFTSESecondaryClass = "topChartContainerFTSESecondaryTheme_2";
                }
            }

            if(count==3){                
                this.spikeChartFTSEEmergingcolors = this.colors[3]; 
                this.spikeChartFTSEEmergingcolorsLines.length = 0;
                this.spikeChartFTSEEmergingcolorsLines.push(this.colors[3]);   
                if(this.colors[0]=='#7FBA00'){
                    this.topChartContainerFTSEEmergingClass = "topChartContainerFTSEEmerging";
                }
                if(this.colors[0]=='#531732'){
                    this.topChartContainerFTSEEmergingClass = "topChartContainerFTSEEmergingFTSE";
                }
                if(this.colors[0]=='#16202C'){
                    this.topChartContainerFTSEEmergingClass = "topChartContainerFTSEEmergingLSEG";
                }
                if(this.colors[0] == '#1D303F'){
                    this.topChartContainerFTSEEmergingClass = "topChartContainerFTSEEmergingLSEGGREY";
                }
                if(this.colors[0]=='#F8B15B'){
                    this.topChartContainerFTSEEmergingClass = "topChartContainerFTSEEmergingTheme_1";
                }
                if(this.colors[1] == '#696D6F'){
                    this.topChartContainerFTSEEmergingClass = "topChartContainerFTSEEmergingTheme_2";
                }
            }

            if(count==4){                
                this.spikeChartFTSEAsiacolors = this.colors[4]; 
                this.spikeChartFTSEAsiacolorsLines.length = 0;
                this.spikeChartFTSEAsiacolorsLines.push(this.colors[4]); 
                if(this.colors[0]=='#7FBA00'){
                    this.topChartContainerFTSEAsiaClass = "topChartContainerFTSEAsia";
                }
                if(this.colors[0]=='#531732'){
                    this.topChartContainerFTSEAsiaClass = "topChartContainerFTSEAsiaFTSE";
                }
                if(this.colors[0]=='#16202C'){
                    this.topChartContainerFTSEAsiaClass = "topChartContainerFTSEAsiaLSEG";
                }
                if(this.colors[0] == '#1D303F'){
                    this.topChartContainerFTSEAsiaClass = "topChartContainerFTSEAsiaLSEGGREY";
                }
                if(this.colors[0]=='#F8B15B'){
                    this.topChartContainerFTSEAsiaClass = "topChartContainerFTSEAsiaTheme_1";
                }
                if(this.colors[1] == '#696D6F'){
                    this.topChartContainerFTSEAsiaClass = "topChartContainerFTSEAsiaTheme_2";
                }
            }

            if(count==5){                
                this.spikeChartFTSEAdvancedcolors = this.colors[5];
                this.spikeChartFTSEAdvancedcolorsLines.length = 0;
                this.spikeChartFTSEAdvancedcolorsLines.push(this.colors[5]);
                if(this.colors[0]=='#7FBA00'){
                    this.topChartFTSEAdvancedClass = "topChartContainerFTSEAdvanced";
                }
                if(this.colors[0]=='#531732'){                    
                    this.topChartFTSEAdvancedClass = "topChartContainerFTSEAdvancedFTSE";
                }
                if(this.colors[0]=='#16202C'){
                    this.topChartFTSEAdvancedClass = "topChartContainerFTSEAdvancedLSEG";
                }
                if(this.colors[0] == '#1D303F'){
                    this.topChartFTSEAdvancedClass = "topChartContainerFTSEAdvancedLSEGGREY";
                }
                if(this.colors[0]=='#F8B15B'){
                    this.topChartFTSEAdvancedClass = "topChartContainerFTSEAdvancedTheme_1";
                }
                if(this.colors[1] == '#696D6F'){
                    this.topChartFTSEAdvancedClass = "topChartContainerFTSEAdvancedTheme_2";
                }
            }
        }
     }
    
    chartSetup(indexMarkers:any) {    
        this.landingService.getspikeChartValues(this.toDate,this.fromDate,indexMarkers ,this.currencyCode,this.returnType).then(result => {                          
            this.sparkBindedData = result;           
             this.bindSpikeChart(this.sparkBindedData);          
        }
        ,onerror =>{
        this.topNavigationchartLoader0 =false;
        this.topNavigationchartLoader1 =false;
        this.topNavigationchartLoader2 =false;
        this.topNavigationchartLoader3 =false;
        this.topNavigationchartLoader4 =false;
        this.topNavigationchartLoader5 =false;       
     });
    }

    bindSpikeChart(result:LandingPageModel[]) {  
        this.spikeChartFTSEGlobaldata = [{data : [], backgroundColor: 'transparent', borderColor:[]}]; 
        this.spikeChartFTSEDevelopeddata  = [{data : [], backgroundColor: 'transparent', borderColor:[]}];
        this.spikeChartFTSESecondarydata = [{data : [] , backgroundColor: 'transparent', borderColor:[]}];
        this.spikeChartFTSEEmergingdata  = [{data : [], backgroundColor: 'transparent', borderColor:[]}];
        this.spikeChartFTSEAsiadata = [{data : [] , backgroundColor: 'transparent', borderColor:[]}];
        this.spikeChartFTSEAdvanceddata  = [{data : [] , backgroundColor: 'transparent', borderColor:[]}];      
        for(var key=0;  key < result.length ; key++){            
            if (key == 0) {                
                this.topNavigationchartLoader0=false   
                this.chartReady0 = true;             
                this.spikeChartFTSEGloballabels = result[key].spikeChartModel.labels; 

                for (let item of result[key].spikeChartModel.dataBucketList) {                               
                   this.spikeChartFTSEGlobaldata.push({            
                        data: item.dataList, 
                        labels : result[key].spikeChartModel.labels,
                        backgroundColor: 'transparent',
                        borderColor: this.spikeChartFTSEGlobalcolorsLines
                    });                  
                }                    
                this.FTSEGlobalDataindexName =  result[key].spikeChartModel.indexName;
                this.FTSEGlobalDataindexValue =  result[key].spikeChartModel.latestIndexValue;
                this.FTSEGlobalDataindexDifference =  result[key].spikeChartModel.indexDifference > 0 ? "+" + result[key].spikeChartModel.indexDifference :result[key].spikeChartModel.indexDifference ;
                this.FTSEGlobalDatapercentChanged =  result[key].spikeChartModel.percentChanged > 0 ? "+" + result[key].spikeChartModel.percentChanged : result[key].spikeChartModel.percentChanged ;
                this.FTSEGlobalDataasofDate = moment(new Date( result[key].spikeChartModel.asOfDate)).format("MM/DD/YYYY");
                this.spikeChartFTSEGlobalchartOptions = {
                    scales: {
                        xAxes: [{
                            display: false
                        }],
                        yAxes: [{
                            display: false,
                        }],
                    },
                    tooltips: {
                        enabled: false,
                        
                    }, elements:
                        {
                            point:
                              {
                                  radius: 0,
                                  hitRadius: 3,
                                  hoverRadius: 3
                              }
                        }
                }  
           } else if (key == 1) {
               this.topNavigationchartLoader1=false
               this.chartReady1 =true;
                this.spikeChartFTSEDevelopedlabels = result[key].spikeChartModel.labels;                                     
                
               for (let item of  result[key].spikeChartModel.dataBucketList) {            
                   this.spikeChartFTSEDevelopeddata.push({            
                        data: item.dataList,   
                        labels : result[key].spikeChartModel.labels,
                        backgroundColor: 'transparent',
                        borderColor:this.spikeChartFTSEDevelopedcolorsLines     
                    });            
                }
               this.FTSEDevelopedDataindexName = result[key].spikeChartModel.indexName;
               this.FTSEDevelopedDataindexValue = result[key].spikeChartModel.latestIndexValue;
               this.FTSEDevelopedDataindexDifference = result[key].spikeChartModel.indexDifference > 0 ? "+" + result[key].spikeChartModel.indexDifference : result[key].spikeChartModel.indexDifference;
               this.FTSEDevelopedDatapercentChanged =  result[key].spikeChartModel.percentChanged > 0 ? "+" +  result[key].spikeChartModel.percentChanged : result[key].spikeChartModel.percentChanged ;
               this.FTSEDevelopedDataasofDate = moment(new Date( result[key].spikeChartModel.asOfDate)).format("MM/DD/YYYY");
               this.spikeChartFTSEDevelopedchartOptions = { 
                   scales: {
                    xAxes: [{
                        display: false
                    }],
                    yAxes: [{
                        display: false,
                    }],
                 }, tooltips: {
                    enabled: false,                        
                 }, elements:
                    {
                        point:
                        {
                            radius: 0,
                            hitRadius: 3,
                            hoverRadius: 3
                        }
                    }
                }                                              
           } else if (key == 2) {
               this.topNavigationchartLoader2=false
               this.chartReady2 =true;
               this.spikeChartFTSESecondarylabels = result[key].spikeChartModel.labels;                                    
               
               for (let item of  result[key].spikeChartModel.dataBucketList) {            
                   this.spikeChartFTSESecondarydata.push({            
                        data: item.dataList,       
                        labels : result[key].spikeChartModel.labels ,
                        backgroundColor: 'transparent',
                        borderColor: this.spikeChartFTSESecondarycolorsLines            
                    });    
               }
                this.FTSESecondaryDataindexName = result[key].spikeChartModel.indexName;
                this.FTSESecondaryDataindexValue = result[key].spikeChartModel.latestIndexValue;
                this.FTSESecondaryDataindexDifference = result[key].spikeChartModel.indexDifference > 0 ? "+" + result[key].spikeChartModel.indexDifference : result[key].spikeChartModel.indexDifference;
                this.FTSESecondaryDatapercentChanged = result[key].spikeChartModel.percentChanged  > 0 ? "+" + result[key].spikeChartModel.percentChanged  : result[key].spikeChartModel.percentChanged ;
                this.FTSESecondaryDataasofDate = moment(new Date( result[key].spikeChartModel.asOfDate)).format("MM/DD/YYYY");                
                this.spikeChartFTSESecondarychartOptions = { 
                    scales: {
                     xAxes: [{
                         display: false
                     }],
                     yAxes: [{
                         display: false,
                     }],
                  }, tooltips: {
                     enabled: false,                        
                  }, elements:
                     {
                         point:
                         {
                             radius: 0,
                             hitRadius: 3,
                             hoverRadius: 3
                         }
                     }
                 }
           } else if (key== 3) {
               this.topNavigationchartLoader3=false
               this.chartReady3 =true;
                this.spikeChartFTSEEmerginglabels = result[key].spikeChartModel.labels;                 
                for (let item of  result[key].spikeChartModel.dataBucketList) {            
                   this.spikeChartFTSEEmergingdata.push({            
                        data: item.dataList,     
                        labels : result[key].spikeChartModel.labels ,
                        backgroundColor: 'transparent',
                        borderColor:this.spikeChartFTSEEmergingcolorsLines           
                    });  
               }
                this.FTSEEmergingDataindexName =result[key].spikeChartModel.indexName;
                this.FTSEEmergingDataindexValue = result[key].spikeChartModel.latestIndexValue;
                this.FTSEEmergingDataindexDifference = result[key].spikeChartModel.indexDifference > 0 ? "+" + result[key].spikeChartModel.indexDifference : result[key].spikeChartModel.indexDifference;
                this.FTSEEmergingDatapercentChanged = result[key].spikeChartModel.percentChanged >0 ? "+" + result[key].spikeChartModel.percentChanged : result[key].spikeChartModel.percentChanged;
                this.FTSEEmergingDataasofDate = moment(new Date( result[key].spikeChartModel.asOfDate)).format("MM/DD/YYYY"); 
                this.spikeChartFTSEEmergingchartOptions  = { 
                    scales: {
                     xAxes: [{
                         display: false
                     }],
                     yAxes: [{
                         display: false,
                     }],
                  }, tooltips: {
                     enabled: false,                        
                  }, elements:
                     {
                         point:
                         {
                             radius: 0,
                             hitRadius: 3,
                             hoverRadius: 3
                         }
                     }
                 }
           } else if (key== 4) {
               this.topNavigationchartLoader4=false
               this.chartReady4 =true;
                this.spikeChartFTSEAsialabels = result[key].spikeChartModel.labels;
                for (let item of  result[key].spikeChartModel.dataBucketList) {            
                   this.spikeChartFTSEAsiadata.push({            
                        data: item.dataList,  
                        labels : result[key].spikeChartModel.labels,
                        backgroundColor: 'transparent',
                        borderColor:this.spikeChartFTSEAsiacolorsLines                  
                    });         
                }
                this.FTSEAsiaDataindexName = result[key].spikeChartModel.indexName;
                this.FTSEAsiaDataindexValue = result[key].spikeChartModel.latestIndexValue;
                this.FTSEAsiaDataindexDifference = result[key].spikeChartModel.indexDifference > 0 ? "+" + result[key].spikeChartModel.indexDifference: result[key].spikeChartModel.indexDifference;
                this.FTSEAsiaDatapercentChanged = result[key].spikeChartModel.percentChanged > 0 ? "+" + result[key].spikeChartModel.percentChanged : result[key].spikeChartModel.percentChanged;
                this.FTSEAsiaDataasofDate = moment(new Date( result[key].spikeChartModel.asOfDate)).format("MM/DD/YYYY");
                this.spikeChartFTSEAsiachartOptions  = { 
                    scales: {
                     xAxes: [{
                         display: false
                     }],
                     yAxes: [{
                         display: false,
                     }],
                  }, tooltips: {
                     enabled: false,                        
                  }, elements:
                     {
                         point:
                         {
                             radius: 0,
                             hitRadius: 3,
                             hoverRadius: 3
                         }
                     }
                 }
           } else if (key == 5) {
               this.topNavigationchartLoader5=false
               this.chartReady5 =true;
                this.spikeChartFTSEAdvancedlabels = result[key].spikeChartModel.labels;
                for (let item of  result[key].spikeChartModel.dataBucketList) {            
                   this.spikeChartFTSEAdvanceddata.push({            
                        data: item.dataList, 
                        labels : result[key].spikeChartModel.labels,
                        backgroundColor: 'transparent',
                        borderColor:this.spikeChartFTSEAdvancedcolorsLines                                     
                    });           
                }                   
                this.FTSEAdvancedDataindexName = result[key].spikeChartModel.indexName;
                this.FTSEAdvancedDataindexValue = result[key].spikeChartModel.latestIndexValue;
                this.FTSEAdvancedDataindexDifference = result[key].spikeChartModel.indexDifference > 0 ? "+" + result[key].spikeChartModel.indexDifference : result[key].spikeChartModel.indexDifference;
                this.FTSEAdvancedDatapercentChanged =   result[key].spikeChartModel.percentChanged > 0 ? "+" + result[key].spikeChartModel.percentChanged : result[key].spikeChartModel.percentChanged ;
                this.FTSEAdvancedDataasofDate = moment(new Date( result[key].spikeChartModel.asOfDate)).format("MM/DD/YYYY");
                this.spikeChartFTSEAdvancedchartOptions  = { 
                    scales: {
                     xAxes: [{
                         display: false
                     }],
                     yAxes: [{
                         display: false,
                     }],
                  }, tooltips: {
                     enabled: false,                        
                  }, elements:
                     {
                         point:
                         {
                             radius: 0,
                             hitRadius: 3,
                             hoverRadius: 3
                         }
                     }
                 }
           }
          } ;
        for(var key = result.length;  key <= 6  ; key++){
            if (key== 0) {
                this.topNavigationchartLoader0 =false;
                this.spikeChartFTSEGloballabels = [];        
                this.spikeChartFTSEGlobaldata.splice(0,2);
                this.FTSEGlobalDataindexName = "";
                this.FTSEGlobalDataindexValue = "";
                this.FTSEGlobalDataindexDifference = "";
                this.FTSEGlobalDatapercentChanged = "";
                this.FTSEGlobalDataasofDate = "";
                this.topChartContainerFTSEGlobalClass = "noTopChart";
              }
              else if (key== 1) {
              this.topNavigationchartLoader1 =false;
              this.spikeChartFTSEDevelopedlabels = [];       
              this.spikeChartFTSEDevelopeddata.splice(0,2);
              this.FTSEDevelopedDataindexName = "";
              this.FTSEDevelopedDataindexValue = "";
              this.FTSEDevelopedDataindexDifference = "";
              this.FTSEDevelopedDatapercentChanged = "";
              this.FTSEDevelopedDataasofDate = "";
              
              this.topChartContainerFTSEDevelopedClass = "noTopChart";
            } else if (key==2){
                this.topNavigationchartLoader2 =false;
                this.spikeChartFTSESecondarylabels = [];        
                this.spikeChartFTSESecondarydata.splice(0,2);
                this.FTSESecondaryDataindexName = "";
                this.FTSESecondaryDataindexValue = "";
                this.FTSESecondaryDataindexDifference = "";
                this.FTSESecondaryDatapercentChanged = "";
                this.FTSESecondaryDataasofDate = "";
                this.topChartContainerFTSESecondaryClass = "noTopChart";                
            }else if (key==3){
                this.topNavigationchartLoader3 =false;
                this.spikeChartFTSEEmerginglabels = [];        
                this.spikeChartFTSEEmergingdata.splice(0,2);
                this.FTSEEmergingDataindexName = "";
                this.FTSEEmergingDataindexValue = "";
                this.FTSEEmergingDataindexDifference = "";
                this.FTSEEmergingDatapercentChanged = "";
                this.FTSEEmergingDataasofDate = "";
                this.topChartContainerFTSEEmergingClass = "noTopChart";               
            }else if (key==4){
                this.topNavigationchartLoader4 =false;
                this.spikeChartFTSEAsialabels = [];       
                this.spikeChartFTSEAsiadata.splice(0,2);
                this.FTSEAsiaDataindexName = "";
                this.FTSEAsiaDataindexValue = "";
                this.FTSEAsiaDataindexDifference = "";
                this.FTSEAsiaDatapercentChanged = "";
                this.FTSEAsiaDataasofDate = "";
                this.topChartContainerFTSEAsiaClass = "noTopChart";                
            }else if (key==5){
                this.topNavigationchartLoader5 =false;
                this.spikeChartFTSEAdvancedlabels = [];        
                this.spikeChartFTSEAdvanceddata.splice(0,2);       
                this.spikeChartFTSEAdvancedchartOptions = {};    
                this.FTSEAdvancedDataindexName = "";
                this.FTSEAdvancedDataindexValue = "";
                this.FTSEAdvancedDataindexDifference = "";
                this.FTSEAdvancedDatapercentChanged = "";
                this.FTSEAdvancedDataasofDate = "";
                this.topChartFTSEAdvancedClass = "noTopChart";                
            }
          }  
        }
   
    defaultSparkValues() {
        this.topNavigationchartLoader0 =true;
        this.topNavigationchartLoader1 =true;
        this.topNavigationchartLoader2 =true;
        this.topNavigationchartLoader3 =true;
        this.topNavigationchartLoader4 =true;
        this.topNavigationchartLoader5 =true;
        
        this.spikeChartFTSEDevelopedlabels = [];       
        this.spikeChartFTSEDevelopeddata.splice(0,2);
        this.FTSEGlobalDataindexName = "";
        this.FTSEGlobalDataindexValue = "";
        this.FTSEGlobalDataindexDifference = "";
        this.FTSEGlobalDatapercentChanged = "";
        this.FTSEGlobalDataasofDate = "";

        this.spikeChartFTSEGloballabels = [];        
        this.spikeChartFTSEGlobaldata.splice(0,2);
        this.FTSEDevelopedDataindexName = "";
        this.FTSEDevelopedDataindexValue = "";
        this.FTSEDevelopedDataindexDifference = "";
        this.FTSEDevelopedDatapercentChanged = "";
        this.FTSEDevelopedDataasofDate = "";

        this.spikeChartFTSESecondarylabels = [];        
        this.spikeChartFTSESecondarydata.splice(0,2);
        this.FTSESecondaryDataindexName = "";
        this.FTSESecondaryDataindexValue = "";
        this.FTSESecondaryDataindexDifference = "";
        this.FTSESecondaryDatapercentChanged = "";
        this.FTSESecondaryDataasofDate = "";

        this.spikeChartFTSEEmerginglabels = [];        
        this.spikeChartFTSEEmergingdata.splice(0,2);
        this.FTSEEmergingDataindexName = "";
        this.FTSEEmergingDataindexValue = "";
        this.FTSEEmergingDataindexDifference = "";
        this.FTSEEmergingDatapercentChanged = "";
        this.FTSEEmergingDataasofDate = "";

        this.spikeChartFTSEAsialabels = [];       
        this.spikeChartFTSEAsiadata.splice(0,2);
        this.FTSEAsiaDataindexName = "";
        this.FTSEAsiaDataindexValue = "";
        this.FTSEAsiaDataindexDifference = "";
        this.FTSEAsiaDatapercentChanged = "";
        this.FTSEAsiaDataasofDate = "";

       this.spikeChartFTSEAdvancedlabels = [];        
        this.spikeChartFTSEAdvanceddata.splice(0,2);       
        this.spikeChartFTSEAdvancedchartOptions = {};    
        this.FTSEAdvancedDataindexName = "";
        this.FTSEAdvancedDataindexValue = "";
        this.FTSEAdvancedDataindexDifference = "";
        this.FTSEAdvancedDatapercentChanged = "";
        this.FTSEAdvancedDataasofDate = "";

        this.topChartFTSEAdvancedClass = "noTopChart";
        this.topChartContainerFTSEAsiaClass = "noTopChart";
        this.topChartContainerFTSEEmergingClass = "noTopChart";
        this.topChartContainerFTSESecondaryClass = "noTopChart";
        this.topChartContainerFTSEDevelopedClass = "noTopChart";
        this.topChartContainerFTSEGlobalClass = "noTopChart";
    }
}