import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from '../../models/authorization';
import { AuthorizationService } from '../../services/authorization.service';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {
  initilize(initilize: any) {
    throw new Error('Method not implemented.');
  }

  menuItemData : MenuItem
  selectedMenuType : any;
  commitStatus : any;
  MenuList : MenuItem[];
  constructor(private services:AuthorizationService, private route: ActivatedRoute) { }
  ngOnInit() {
    
   this.menuItemData ={id:"",menuitem:"", isClicked:"", routerlink:""};
   this.commitStatus ="";

  this.services.getMenuItemInfo().then(result =>{
    this.MenuList = result;

    var sub = this.route.params.subscribe(params => {
      this.menuItemData.menuitem = params['menuitem'];
      this.menuItemData.routerlink =JSON.parse(params['routerlink']);
      this.menuItemData.isClicked = JSON.parse(params['isClicked']);
      this.menuItemData.id = JSON.parse(params['id']);

    }); 
  })

      console.log('menuitem object for me', this.menuItemData);
  }


saveChanges(){
  this.services.saveMenuItem(this.menuItemData).then(result => {
    console.log('Save User Group data', this.menuItemData);
    if(result == "1")
    {
      //this.commitStatus = "Saved successfully"
      alert("User Group: " + this.menuItemData.menuitem +" "+'Saved successfully');
      window.location.href = "/menu-items"
     
    }
    else
    {
      this.commitStatus = "Error while saving"
    } 
 });
}

}
