import { Injectable } from '@angular/core';
// tslint:disable-next-line:import-blacklist

import {BehaviorSubject, Observable, of,from, Subject} from 'rxjs';
import {NewReportForm, Report, ReportData, ExportReportDetails, ExportReportDetailsColumns, ExportReportData, ExportPortfolioConfigData} from '../models/report';
import {map} from 'rxjs/operators';
import { Portfolio } from '../models/portfolio';
import { Benchmark } from '../models/benchmark';
import { ResponseModel } from '../../models/landing-page-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


const data: Report[] = [
  {
    id: 0,
    name: 'REPORT_CORP',
    portfolio: 'Portfolio 1',
    portfolioId :2,
    benchmark: 'F4GAW',
    dateCreated: '07-01-2021',
    asOfDate: '07-01-2021',
    assetsClass :'Equity',
    template :'Corporate carbon footprint',
    metrics :'Carbon intensity of revenues',
    currency :'USD',
  }
];

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  
  private reports$ = new BehaviorSubject<Report[]>(data);

  constructor( private httpClient: HttpClient) { }
  
  private _listners= new Subject<any>(); 
  clientPublish():Observable<any>{
    return this._listners.asObservable();
  }


  publishClient(client: string) {
    this._listners.next(client);
  } 

  fetchReports$(pageIndex, pageSize): Observable<any> {
    //return this.reports$.asObservable();
    return from(this.GetReports(pageIndex, pageSize));
  }

  GetReports(pageIndex, pageSize): Observable<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'client/api/mvp/GetAllMVPPortfolioReportsByPage',
      JSON.stringify({ pageIndex : pageIndex, pageSize: pageSize}),
      {headers: headers});


  }
  createReport(reportForm: NewReportForm): Observable<string> {
    //const report = this.fromReportForm(reportForm);
    this.saveReport(reportForm).then((a) =>{
      if(a.data != 0){
        this.publishClient("create Report");
      } 
      else{
          this.publishClient(a.meta.message);
      }
    });
     return of(reportForm.name); 
    // this.reports$.next([...this.reports$.value, report]);
    // console.log(this.reports$);
    // return this.reports$;
  }

  updatePortfolio(reportForm: NewReportForm): Observable<string> {
    this.saveReport(reportForm).then((a) =>{
      if(a.data !=  0){
        this.publishClient("Edit Report");
      } 
      else{
        this.publishClient(a.data.message);
      }
    });
     return of(reportForm.name);
  }
  viewReport(reportData : any): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'client/api/mvp/ViewRequestedReport',
      JSON.stringify({
        Id : reportData.id,
        PortfolioId : reportData.portfolioId,
        BenchMark : reportData.benchMark, 
        AsOfDate : reportData.asOfDate        
           }),
      {headers: headers}).toPromise()
      .then(response => {
        var result = response;
        return result; 
      }).catch(this.handleErr);
 } 

 deleteReport(reportData : any):any{
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
  return this.httpClient.post( 
    environment.dataServiceRoot + 'client/api/mvp/DeletePortfolioReport',
    JSON.stringify({
      Id : reportData.id      
         }),
    {headers: headers}).toPromise()
    .then(response => {
      var result = response;
      return result; 
    }).catch(this.handleErr);
} 

deleteReports(Ids : number[]):any{
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
  return this.httpClient.post( 
    environment.dataServiceRoot + 'client/api/mvp/DeletePortfolioReports',
    JSON.stringify({
      ids : Ids      
         }),
    {headers: headers}).toPromise()
    .then(response => {
      var result = response;
      return result; 
    }).catch(this.handleErr);
} 

  saveReport(reportForm: NewReportForm ) : any {
    if(reportForm.id==null) {
      reportForm.id =0 
    }
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
     return this.httpClient.post( 
      environment.dataServiceRoot + 'client/api/mvp/SaveMVPPortfolioReport',
       JSON.stringify({Id:reportForm.id, Name : reportForm.name,PortfolioId : reportForm.portfolioIndexMarker.id, Portfolio : reportForm.portfolioIndexMarker.name, ReportCreationDate: reportForm.date, BenchMark: reportForm.benchmarkIndexMarker, AssetClass: reportForm.assetsClass, Currency: reportForm.currency, AsOfDate: reportForm.asOfDate,Metrics: reportForm.metrics,Template: reportForm.template}),
      
      {headers: headers}).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result;
      }).catch(this.handleErr);
  }

  private fromReportForm(
    {asOfDate, portfolioIndexMarker: portfolio,template,currency ,metrics,assetsClass,date: dateCreated, benchmarkIndexMarker: benchmark, name}: NewReportForm
  ): Report {
    return {
      id: new Date().getTime(),
      asOfDate,
      name,
      portfolio : portfolio.name,
      portfolioId :portfolio.id,
      benchmark,
      dateCreated,
      assetsClass,
      template,
      metrics,
      currency
    };
  }

  getPortfolio():Observable<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot +'client/api/mvp/GetPortfolio', null,
      {headers: headers});
  }

  fetchReportCalculations$(portfolio ,benchmark ,asOfDate , assetClass , template ): Promise<ReportData> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
    environment.dataServiceRoot + 'client/api/mvp/GetPerformanceReport', JSON.stringify({ PortfolioId :portfolio ,Benchmark :benchmark ,AsOfDate : asOfDate ,AssetClass :assetClass ,Template : template}),
      {headers: headers}).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data as ReportData;
      }).catch(this.handleErr);
 }
 fetchReportCalculationsPrimeId$(portfolio ,benchmark ,asOfDate , assetClass , template ): Promise<ReportData> {
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
  return this.httpClient.post( 
  environment.dataServiceRoot + 'client/api/mvp/GetPerformanceReportPrimeId', JSON.stringify({ PortfolioId :portfolio ,Benchmark :benchmark ,AsOfDate : asOfDate ,AssetClass :assetClass ,Template : template}),
    {headers: headers}).toPromise()
    .then(response => {
      var result = response as ResponseModel;
      return result.data as ReportData;
    }).catch(this.handleErr);
}
 getPortfoliobyId(portfolioId): any {
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
  return this.httpClient.post( 
    environment.dataServiceRoot + 'client/api/mvp/GetMVPPortfoliobyId',  JSON.stringify({ Id :portfolioId}),
    {headers: headers}).toPromise()
    .then(response => {
      var result = response as ResponseModel;
      return result.data 
    }).catch(this.handleErr);
}
 private handleErr(error: any): Promise<any> {
  console.error('An error occurred', error); // for demo purposes only
  return Promise.reject(error.message || error);
}



exportReportData$(reportid, portfolio ,benchmark ,asOfDate, portfolioType, template): Promise<any> {
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
  return this.httpClient.post( 
  environment.dataServiceRoot + 'client/api/mvp/ExportPerformanceReport', JSON.stringify({Id:reportid, PortfolioId :portfolio ,Benchmark :benchmark ,AsOfDate : asOfDate, PortfolioType : portfolioType, Template : template}),
    {headers: headers}).toPromise()
    .then(response => {
      var result = response;
      return result;
    }).catch(this.handleErr);
}

	
exportReportDataForCSV$(portfolio ,benchmark ,asOfDate, portfolioType, template): Promise<ExportReportData[]> {
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
  return this.httpClient.post( 
  environment.dataServiceRoot + 'client/api/mvp/ExportPerformanceReportForCSV', JSON.stringify({ PortfolioId :portfolio ,Benchmark :benchmark ,AsOfDate : asOfDate, PortfolioType : portfolioType, Template : template}),
    {headers: headers}).toPromise()
    .then(response => {
      var result = response as ResponseModel; 
      return result.data as ExportReportData;
    }).catch(this.handleErr);
 } 
 
ReportConfigMetaData$(): Promise<ExportPortfolioConfigData[]> {
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
  return this.httpClient.post( 
  environment.dataServiceRoot + 'client/api/mvp/ReportConfigMetaData', null,
    {headers: headers}).toPromise()
    .then(response => {
      var result = response as ResponseModel;     
      return result.data as ExportPortfolioConfigData;
    }).catch(this.handleErr);
}

reportToDetails(reports: ExportReportData[] = [], report: ExportReportData = {} as ExportReportData): ExportReportDetails {
  console.log(reports);
  return reports.reduce((acc, item) => {
    acc.data.push(acc.columns.map(column => item[column]));
    return acc;
  }, <ExportReportDetails>{
    model: report,
    columns: [...Object.keys(ExportReportDetailsColumns)],
    data: []
  });
}
}