import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, of } from 'rxjs';
import { ClientIndex, ClientIndexMappingForm } from '../../models/client-index-mapping-details';
import { IndexMappingDetailColumns, indexMappingDetailsColumnsNameMap } from '../../models/client-index-mapping';
import { Router } from '@angular/router';
import { ClientIndexMappingService } from '../../services/client-index-mapping.service';
import { NewClientIndexMappingPopupComponent } from '../new-client-index-mapping-popup/new-client-index-mapping-popup.component';
import { filter, switchMap } from 'rxjs/operators';
import { iResponseModel } from '../../models/response.model';
import { environment } from '../../../environments/environment';

enum ColumnNames {
  chevron = 'chevron',
  company = 'company',
  reportType = 'reportType',
  controls = 'controls',
}

@Component({
  selector: 'app-client-index-mapping',
  templateUrl: './client-index-mapping-grid.component.html',
  styleUrls: ['./client-index-mapping-grid.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ClientIndexMappingGridComponent implements OnInit {
  retry$ = new BehaviorSubject(null);
  error$ = new BehaviorSubject<any>(null);
  response: any;
  loader: Boolean = true;
  viewOnlyAccess : Boolean = true;
  @Output() edit = new EventEmitter<ClientIndexMappingForm>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  company = new FormControl();
  columnsToDisplay: ColumnNames[] = [
    ColumnNames.chevron,
    ColumnNames.company,
    ColumnNames.reportType,
    ColumnNames.controls
  ];
  columnsViewNameMap = new Map<ColumnNames, string>([
    [ColumnNames.company, 'Company / User Id'],
    [ColumnNames.reportType, 'Report Type'],
  ]);
  skipColumnsAutoRender = new Set<ColumnNames>([
    ColumnNames.chevron,
    ColumnNames.controls
  ]);
  fixColumnswidth100 = new Set<ColumnNames>([
    ColumnNames.company,
    ColumnNames.reportType
  ]);
  fixColumnswidth120 = new Set<ColumnNames>([
  ]);

  filterForm: any;
  childrenFilterForm: any;
  dataSource: any;
  data: MatTableDataSource<ClientIndex>;
  activePageDataChunk: any;
  expandedColumnsToDisplay: IndexMappingDetailColumns[] = [
    ...Object.values(IndexMappingDetailColumns),
  ];
  expendedFilterColumns: IndexMappingDetailColumns[] = [
    ...Object.values(IndexMappingDetailColumns).filter(x => x == IndexMappingDetailColumns.indexMarker)
  ];
  expandedColumnsToDisplayHeadingMap: Record<IndexMappingDetailColumns, string> = indexMappingDetailsColumnsNameMap;
  expandedElement: ClientIndex | null = null;

  pageSizeOptions: number[] = [5, 10, 15, 20, 25];
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 5;
  pageLength: number;

  unsub: any;
  isLoading: boolean;
  pageOffset: number;
  childrenRecords: any;
  baseDialogConfig: MatDialogConfig = {
    closeOnNavigation: true,
    height:"auto",
    minHeight: "calc(100vh-90px)"
  };

  constructor(private router: Router, private fb: FormBuilder,public dialog: MatDialog, private clientIndexMappingService: ClientIndexMappingService) {
    this.unsub = this.clientIndexMappingService.clientPublish().subscribe((m: any) => {
      var result = m;
      this.clientIndexMappingService.GetClientIndex(0, 0).subscribe((res) => {
        this.dataSource = this.MapIsActive(res.data);
        if (this.dataSource.length % this.pageSize == 1 && (this.pageIndex == (Math.floor(this.dataSource.length / this.pageSize) - 1))) {
          this.pageIndex = this.pageIndex + 1;
        }
        let firstCut = this.pageIndex * this.pageSize;
        let secondCut = firstCut + this.pageSize;
        if (result == "Invalid") {
          alert("Please Upload Valid details");
        }
        else if (result == "Updated") {
          alert("Updated successfully");
        }
        else if (result == "Deleted") {
          alert("Deleted successfully");
        }
        else if (result.includes("Row")) {
          alert(result);
        }else if (result.includes("Saved")) {
          alert("Client index mapping created successfully");
        }
        else {
          alert(result);
        }
        this.loader = true;
        this.activePageDataChunk = this.dataSource.slice(firstCut, secondCut);
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].totalCount : 0;
      });
      this.data = new MatTableDataSource(this.dataSource);
    });
  }
  MapIsActive(data: any): any {
   data.forEach(header => {
    header.children.data.forEach(indexmarker => {
      indexmarker.isActive = indexmarker.isActive ? 'Yes' : 'No';
    })
   });
   return data;
  }

  ngOnInit(): void {
    if (sessionStorage.getItem("UserRoles") == environment.role 
    || sessionStorage.getItem("UserRoles").indexOf(environment.adminGroup) !== -1
    || sessionStorage.getItem("UserRoles").indexOf(environment.clientServiceOpsGroup) !== -1)
{
  this.viewOnlyAccess = false;
}
    this.filterForm = this.fb.group({
      company: [""],
      reportType: [""],
    });

    this.filterForm.valueChanges.subscribe(value => {
      this.pageIndex = 0;
      this.pageSize = 5;
      this.filterRecord(value);
    });
    this.GetClientIndex();
  }
  filterRecord(value: any) {
    var filter1 = this.dataSource.filter(cp => cp.company.toLowerCase().indexOf(value.company.toLowerCase()) != -1 && cp.reportType.toLowerCase().indexOf(value.reportType.toLowerCase()) != -1);
    this.pageOffset = this.pageIndex * this.pageSize;
    this.activePageDataChunk = filter1.slice(this.pageOffset, this.pageOffset + this.pageSize);
    filter1.paginator = this.paginator;
    this.pageLength = filter1.length;
  }
  filterChildRecord(value: any) {
    if (this.childrenRecords != undefined)
      this.dataSource.find(x => x.id == this.expandedElement.id).children.data = this.childrenRecords;

    const records = this.childrenRecords = this.dataSource.find(x => x.id == this.expandedElement.id).children.data;
    var filterChidren = records.filter(cp => cp.indexMarker.toLowerCase().indexOf(value.indexMarker.toLowerCase()) != -1);
    this.dataSource.find(x => x.id == this.expandedElement.id).children.data = filterChidren;
  }
  GetClientIndex() {
    this.isLoading = true;
    if (this.dataSource == null)
      this.clientIndexMappingService.GetClientIndex(0, 0).subscribe((res) => {
        this.dataSource = this.MapIsActive(res.data);
        this.UpdatePagination();
      });
    this.UpdatePagination();
  }

  UpdatePagination() {
    if (this.dataSource != null) {
      this.isLoading = false;
      this.pageOffset = this.pageIndex * this.pageSize;
      this.activePageDataChunk = this.dataSource.slice(this.pageOffset, this.pageOffset + this.pageSize);
      this.dataSource.paginator = this.paginator;
      this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].totalCount : 0;
    }
  }
  getColumnsViewName(column: ColumnNames): string {
    return this.columnsViewNameMap.get(column) || '';
  }

  openMappingDetail(data: ClientIndex): void {
    let param = { companyId: data.companyId, company: data.company, reportTypeId: data.reportTypeId, reportType: data.reportType };
    localStorage.setItem('ClientIndexMapping', JSON.stringify(param));
    this.router.navigate(['client-index-mapping', 'details']);
  }

  updateExpandedRow(element: any): void {
    this.expandedElement = this.expandedElement === element ? null : element;
    if ((this.expandedElement == null && this.childrenRecords != undefined)
      || (this.childrenRecords != undefined && this.expandedElement.id != this.childrenRecords[0].ClientIndexId)) {
      let records = this.dataSource.find(x => x.id == this.childrenRecords[0].clientIndexId);
      records.children.data = this.childrenRecords;
      this.childrenRecords = undefined;
    }
    else {
      this.childrenRecords = undefined;
    }
    this.childrenFilterForm = this.fb.group({
      indexMarker: [""],
    });
  }


openPortfolioEdit(indexMapping) {
  this.edit.emit(indexMapping);
  }

  editIndexMapping(indexMapping)
  {    
    const dialogRef: MatDialogRef<NewClientIndexMappingPopupComponent, ClientIndexMappingForm> = this.dialog.open(NewClientIndexMappingPopupComponent, {
      ...this.baseDialogConfig,
      data: indexMapping,
      height:"auto",
    minHeight: "calc(100vh-90px)"
  });
localStorage.setItem('previousCompanyId',indexMapping.companyId);
localStorage.setItem('previousReportTypeId',indexMapping.reportTypeId);
  dialogRef.afterClosed().subscribe((data) => {
      if (data) {        
        this.clientIndexMappingService.updateClientIndexMapping(data).then((result: iResponseModel) => {  
          if (result.uiNotification.length <= 0)
            this.clientIndexMappingService.publishClient("Updated");
          else this.clientIndexMappingService.publishClient(result.uiNotification.join('\n'));
        });
      }
    });
  }


  onPageChanged(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    const value = this.filterForm.value;
    if (value.company == '' && value.reportType == '')
      this.GetClientIndex();
    else
      this.filterRecord(value);
  }

  ngOnDestroy() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
  }


  openDialog(): void {
    const dialogRef: MatDialogRef<NewClientIndexMappingPopupComponent, ClientIndexMappingForm> = this.dialog.open(NewClientIndexMappingPopupComponent, {
      ...this.baseDialogConfig,
      data : null,
      height:"auto",
    minHeight: "calc(100vh-90px)"
    });
    
    dialogRef.afterClosed().pipe(
      filter(data => !!data),
      switchMap((data) => data ? this.clientIndexMappingService.createClientIndex$(data) : of(data)),
    ).subscribe((id) => {
      this.loader = false ;

      //alert("Client index mapping created successfully");
    });
  }

  retry() {
    this.retry$.next(new Date());
  }

  
}
