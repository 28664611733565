import { Component, OnInit, SimpleChanges,OnChanges } from '@angular/core';
import { CompanyModel, EntitlementModel,UserProfileModel } from '../../models/entitlement.model';
import { APIFieldsModel } from '../../models/entitlement.model';
import { EntitlementService } from '../../services/entitlement.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.css']
})

export class UserSearchComponent implements OnInit,OnChanges {
  users: UserProfileModel[];
  filteredUsers: UserProfileModel[];
  selectedUser: any;
  selectedEmail: any;
  selectedCompany: any;
  viewOnlyAccess : Boolean = true;
  constructor(private entitlementService: EntitlementService) { }

  ngOnInit() {
    if (sessionStorage.getItem("UserRoles") == environment.role 
    || sessionStorage.getItem("UserRoles").indexOf(environment.adminGroup) !== -1
    || sessionStorage.getItem("UserRoles").indexOf(environment.clientServiceOpsGroup) !== -1)
{
  this.viewOnlyAccess = false;
}
    this.users =[{companyName:"",email:"",ddsAccountNumber:"",gender:"",name:"",password:"",phoneNumber:"",userName:"",roles:""}];
    this.entitlementService.getUsers().then(result =>
      {
        this.users = result;
        this.filteredUsers = this.users;
      })
  }
  ngOnChanges(changes:SimpleChanges){}
  onUserNameChange()
  {
    this.filteredUsers =  this.users.filter(x => x.userName == this.selectedUser) ;   
  }

  onEmailChange()
  {
    this.filteredUsers =  this.users.filter(x => x.email == this.selectedEmail) ;   
  }

  onCompanyNameChange()
  {
    this.filteredUsers =  this.users.filter(x => x.companyName == this.selectedCompany) ;   
  }

}

