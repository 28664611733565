import { Component, EventEmitter, Input, OnInit, Inject,Output, ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {SelectionModel} from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { ConnectContactLens } from 'aws-sdk';
import { ExportReportData, Report, ReportData } from '../../models/report';
import { CoverageConfigDataService } from '../../services/coverageconfig.service';
import { DownloadService } from '../../services/download.service';
import {S3ManagerService} from '../../../services/s3-manager.service';
import { saveAs } from 'file-saver/FileSaver';
import { ArrayDataSource } from '@angular/cdk/collections';
import * as CryptoJS from "crypto-js";
import * as AWS from 'aws-sdk';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';
import { NewVanguardConfigurationsForm, VanguardConfigurations } from '../../models/coverageconfig';
import { environment } from '../../../../environments/environment';

enum ColumnNames {
  currencyCode ='CurrencyCode',
  indexCode = 'IndexCode',
  climateMetric = 'ClimateMetric',
  parentIndexCode = 'ParentIndexCode',
  excllstwgt ="Excllstwgt",
  outputRegex = 'OutputRegex',
  ModifiedUser = 'ModifiedUser',
  ModifiedTime = 'ModifiedTime',
  clientName = 'ClientName',
  controls = 'controls'
}

@Component({
  selector: 'vanguardconfiguration-data-grid',
  templateUrl: './vanguardconfiguration-data-grid.component.html',
  styleUrls: ['./vanguardconfiguration-data-grid.component.scss']
})
export class VanguardConfigurationDataGridComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() edit = new EventEmitter<Report>();
  @Output() indexCodeEvent = new EventEmitter<string>();
  dataSource: any;
  isVisible :boolean = false ;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25];
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 5;
  pageCount: number = 0;
  pager: any = {};
  unsub: any;
  esgconfiguration: VanguardConfigurations[] = [];
  data: MatTableDataSource<any>;
  oldPageLimit: number;
  response: any;
  selectedIndex: any;
  selectedValue: any;
  activePageDataChunk: any;
  pageLength: number;
  filterForm: any;
  serchtext: any='';
  isupdate :boolean = false ;
  exportresultArray: ExportReportData[] = [];
  selectedreports: number[] = [];
  passphrase: string = "EncryptionatRest";
  isLoading: boolean = false;
  indexcodes: string[];
  public indexcodefilteredList: string[];
  displayColumnNameMap = new Map<ColumnNames, string>([
    [ColumnNames.indexCode,'Index Code'],
    [ColumnNames.parentIndexCode, 'Parent\n Index Code'],
    [ColumnNames.clientName, 'Client Name'],
    [ColumnNames.currencyCode, 'Currency Code'],
    [ColumnNames.excllstwgt, 'Excllstwgt'],
    [ColumnNames.climateMetric, 'Climate Metric'],
    [ColumnNames.outputRegex, 'Output Regex'],   
    [ColumnNames.ModifiedUser, 'Modified User'],
    [ColumnNames.ModifiedTime, 'Modified date']
  ]);
  displayedColumns: ColumnNames[] = [
    ColumnNames.indexCode,
    ColumnNames.parentIndexCode,
    ColumnNames.clientName,
    ColumnNames.currencyCode,
    ColumnNames.climateMetric,
    ColumnNames.excllstwgt,
    ColumnNames.outputRegex,
    ColumnNames.ModifiedUser,
    ColumnNames.ModifiedTime,
    ColumnNames.controls
  ];
  skipColumnsAutoRender = new Set<ColumnNames>([
    ColumnNames.controls
  ]);
  constructor(private router: Router, private coverageConfigDataService: CoverageConfigDataService, private downloadService: DownloadService, private fb: FormBuilder, private s3ManagerService:S3ManagerService) {
    this.unsub = this.coverageConfigDataService.clientPublish().subscribe((m: any) => {
      alert(m);
      this.getVanguardConfigurationsData();
   
    });
  }

  ngOnInit(): void {
    if (sessionStorage.getItem("UserRoles").indexOf(environment.siAdminGroup) != -1) {
            this.isVisible=true;
        }
    //enable update/delete button for testing in dev
      // this.isVisible=true;
     //end
     this.GetindexList();
    this.getVanguardConfigurationsData();
   
  }


  getColumnsViewName(column: ColumnNames): string {
    return this.displayColumnNameMap.get(column) || '';
  }
  

  trim(text: any): string | any {
    if (typeof text === 'string') {
      return text.length < 28 ? text : text.slice(0, 25).trim() + '...';
    }
    return text;
  }
  GetindexList() {
    this.coverageConfigDataService.GetindexList().subscribe((res) => {
      this.indexcodes = res.data;
      this.indexcodefilteredList = res.data;
    });
  }
  getVanguardConfigurationsData()
  {
    if (this.selectedValue != undefined) {
      this.getVanguardConfigurationsDataByfilter(this.selectedValue);     
    }
    else {
      this.isLoading = true;
      this.coverageConfigDataService.fetchVanguardConfigurationsReports$(this.pageIndex, this.pageSize).subscribe((res) => {
        this.response = res;
        this.dataSource = this.response.Data;

        console.log(this.dataSource);
        this.isLoading = false;
        this.activePageDataChunk = this.dataSource;//.slice(0, this.pageSize);
        for (let item of this.activePageDataChunk) {
          item.ModifiedTime = (item.ModifiedTime).substring(0, 10);
        }
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].TotalCount : 0;

      });
    }
  }
  getVanguardConfigurationsDataByfilter(filter) {
    this.isLoading = true;
      this.coverageConfigDataService.fetchVanguardConfigurationsReportsData$(this.pageIndex, this.pageSize, filter).subscribe((res) => {
        this.response = res;
        this.dataSource = this.response.Data;

        console.log("getVanguardConfigurationsData");
        console.log(this.dataSource);
        this.isLoading = false;
        this.activePageDataChunk = this.dataSource;//.slice(0, this.pageSize);
        for (let item of this.activePageDataChunk) {
          item.ModifiedTime = (item.ModifiedTime).substring(0, 10);
        }
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].TotalCount : 0;
      

    });
  }
  onIndexChange(selectedIndex) {
    if (selectedIndex != undefined) {
      this.selectedValue = selectedIndex;
      this.getVanguardConfigurationsDataByfilter(selectedIndex);
      this.indexCodeEvent.emit(selectedIndex);
    }
    else {
      this.selectedValue = undefined;
      this.getVanguardConfigurationsData();
      this.indexCodeEvent.emit(null);
      this.serchtext = '';
      this.indexcodefilteredList = this.indexcodes;
    }

  }
  filterIndexList(val) {
    this.indexcodefilteredList = this.indexcodes.filter((indexCode) => indexCode.includes(val == undefined ? val : val.toUpperCase()));
  }
  openEditMetric(metric: any) {
    this.isupdate = true ;
    this.edit.emit(metric);
  }

 
  onPageChanged(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getVanguardConfigurationsData();
  }
  ngOnDestroy() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
  }
  delete(data : any) {
    if (confirm("Are you sure to delete VanguardConfigurations Data?")) {
      this.coverageConfigDataService.deleteVanguardConfigurationsData(data);     
    }
  }
}
