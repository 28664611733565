
import {map} from 'rxjs/operators';
import { Injectable} from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import { DDSFile,PagedDDSFiles } from '../models/ddstracker.model';
import { of , BehaviorSubject ,  Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ResponseModel} from '../models/landing-page-model';


@Injectable()
export class DdsService {

  constructor(private httpClient: HttpClient )
  {
  }

  getDDSInventory(startDate: any, endDate: any,IsReissued : any,pageNumber:any,pageSize:any) : Promise<PagedDDSFiles>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers.append('Access-Control-Allow-Origin', '*');headers.append('Content-Encoding', 'gzip');headers.append('Accept-Encoding', 'gzip, deflate');
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dds/GetDDSInventory',
    JSON.stringify({
        StartDate : startDate,
        EndDate : endDate,
        IsReissued : IsReissued,
        pageNumber: pageNumber,
        pageSize: pageSize
     
}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var pagedDdsFiles = result.data as any;                    
                    return pagedDdsFiles
                  })
              .catch(this.handleError);  

  }

  getDDSInventoryByFilter(startDate: any, endDate: any,IsReissued : any,pageNumber:any,pageSize:any,filters:any) : Promise<PagedDDSFiles>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers.append('Access-Control-Allow-Origin', '*');headers.append('Content-Encoding', 'gzip');headers.append('Accept-Encoding', 'gzip, deflate');
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dds/GetDDSInventoryByFilter',
    JSON.stringify({
        StartDate : startDate,
        EndDate : endDate,
        IsReissued : IsReissued,
        PageNumber: pageNumber,
        PageSize: pageSize,
        Filters: filters
     
}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var pagedDdsFiles = result.data as any;                    
                    return pagedDdsFiles
                  })
              .catch(this.handleError);  

  }

  getDDSInventoryData(startDate: any, endDate: any,IsReissued : any) : Promise<string>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers.append('Access-Control-Allow-Origin', '*');headers.append('Content-Encoding', 'gzip');headers.append('Accept-Encoding', 'gzip, deflate');
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dds/GetDDSInventoryData',
    JSON.stringify({
        StartDate : startDate,
        EndDate : endDate,
        IsReissued : IsReissued
}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var ddsFiles = result.data as any[];                    
                    return ddsFiles
                  })
              .catch(this.handleError);  

  }

  getDDSInventoryVersion(fileName: any) : Promise<DDSFile[]>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers.append('Access-Control-Allow-Origin', '*');headers.append('Content-Encoding', 'gzip');headers.append('Accept-Encoding', 'gzip, deflate');
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dds/GetDDSInventoryVersion',
    JSON.stringify({
      FileName : fileName
     
}), {headers: headers}).pipe(
        map(
            (response: Response) => 
            response as any
            ))
            .toPromise()
              .then(response =>{
                    var result = response as ResponseModel;
                    var ddsFiles = result.data as any[];                    
                    return ddsFiles
                  })
              .catch(this.handleError);  

  }

  getFilesDifference(oldFileBucket:any,oldFileName:any,oldFileVersionId:any,
    newFileBucket:any,newFileName:any,newFileVersionId:any): Promise<any> {
    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(environment.dataServiceRoot + 'ui/dds/GetFilesDifference',
    JSON.stringify({
        FirstFileBucket: oldFileBucket,
        FirstFileFileName: oldFileName,
        FirstFileVersionId : oldFileVersionId,
        SecondFileBucket: newFileBucket,
        SecondFileFileName: newFileName,
        secondFileVersionId: newFileVersionId
    }), {headers: headers})
    .toPromise()
    .then(response =>{
            return response as any
        })
    .catch(this.handleError);    
  }
  private handleError(error: any): Promise<any>
    {
        console.error('error occured',error);
        return Promise.reject(error.message || error);
    }

}
