import { Injectable }    from '@angular/core';
import * as moment from 'moment';
@Injectable()
export class DateDisplayService{

    constructor( ){}

    getArrayDateDisplay(data:any[],asofDateName:any):any[]{
        if(data.length <=0){
            return data;
        }
        if(!data[0][asofDateName]){
            return data;
        }
        for(var counter=0;counter<data.length;counter++){
            var displayDate = moment(new Date(data[counter][asofDateName])).format("MM/DD/YYYY");
            data[counter][asofDateName] = displayDate;
        }
       
        return data;
    }
}