import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {detailsColumnsNameMap} from '../../models/benchmark';
import { formatDate } from '@angular/common'; 

@Component({
  selector: 'app-excel-like-grid',
  templateUrl: './excel-like-grid.component.html',
  styleUrls: ['./excel-like-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExcelLikeGridComponent implements OnInit {
  updateObject: any[][] = [];

  // tslint:disable-next-line:variable-name
  private _dataSource: any[] | null = null;
  @Output() update = new EventEmitter<any>();
  @Input() columnsNameMap: {[key: string]: string} = {...detailsColumnsNameMap};
  @Input() columns: any[] | null = null;
  @Input() set dataSource(val: any[] | null) {
    this._dataSource = val;
    this.updateObject = [];
  }

  get dataSource(): any[] | null {
    return this._dataSource;
  }

  constructor() { }

  ngOnInit(): void {
  }

  updateInput($event: Event, rowIndex: number, columnIndex: number): void {
    const {value} = ($event.target as HTMLInputElement);
    if (!this.updateObject[rowIndex]) {
      this.updateObject[rowIndex] = [];
    }
    this.updateObject[rowIndex][columnIndex] = value;
    this.update.emit(this.updateObject);
  }

  getColumnValue(rowIndex, columnIndex): string {
    const updateValue = this.updateObject[rowIndex] && this.updateObject[rowIndex][columnIndex];
    if(columnIndex == 'asOfDate'){
      this.dataSource[rowIndex][columnIndex] = (this.dataSource[rowIndex][columnIndex] == "") ? null : formatDate(this.dataSource[rowIndex][columnIndex], 'yyyy-MM-dd', 'en-US'); 
    }
    const initialValue = this.dataSource[rowIndex][columnIndex] || '';
    return updateValue == null ? initialValue : updateValue;
  }

  getColumnName(column: string) {
    return this.columnsNameMap[column] == null ? column : this.columnsNameMap[column];
  }
}
