import { Injectable } from '@angular/core';
import { NewReportForm, Report, ReportData, ExportReportDetails, ExportReportDetailsColumns, ExportReportData } from '../models/report';
import { BehaviorSubject, Observable, of, from, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewPAIConfigurationForm } from '../models/paiconfiguration';
import { ResponseModel } from '../../models/landing-page-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { debug } from 'console';


@Injectable({
  providedIn: 'root'
})
export class paiconfigurationDataService {

  public isFormValid = true;
  constructor(private httpClient: HttpClient) { }

  private _listners = new Subject<any>();
  clientPublish(): Observable<any> {
    return this._listners.asObservable();
  }


  publishClient(client: string) {
    this._listners.next(client);
  }

  fetchReports$(pageIndex, pageSize): Observable<any> {
    return from(this.GetPAIConfigurationData(pageIndex, pageSize));
  }
  fetchpaiconfigurationData$(pageIndex, pageSize,filter): Observable<any> {
    return from(this.GetPAIConfigurationDataByFilter(pageIndex, pageSize,filter));

  }
  
  creatpaiconfiguration(paiconfigurationForm: NewPAIConfigurationForm): Observable<string> {
    this.saveNewpaiconfiguration(paiconfigurationForm).then((a) => {
      if (a.data != 0) {
        //this.publishClient("created paiconfiguration data successfully!");
      }
      else {
        //this.publishClient(a.meta.message);
      }
    });
    return of(paiconfigurationForm.indexCode);

  }

  updatepaiconfigurationIndex(paiconfigurationForm: NewPAIConfigurationForm): Observable<string> {
    this.savepaiconfiguration(paiconfigurationForm).then((a) => {
      if (a.data != 0) {
        // this.publishClient("Updated paiconfiguration data successfully!");
      }
      else {
        // this.publishClient(a.data.message);
      }
    });
    return of(paiconfigurationForm.indexCode);
  }

  validatepaiconfiguration(indexCode: string): Promise<string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/ValidatePAIConfiguration', JSON.stringify({ indexCode: indexCode }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response as ResponseModel;
        return result.data
      }).catch(this.handleErr);
  }
 
  saveNewpaiconfiguration(paiconfigurationForm: NewPAIConfigurationForm): any {
    if (paiconfigurationForm.indexCode !=undefined ? paiconfigurationForm.indexCode.toLowerCase() != "" : true) {
      this.validatepaiconfiguration(paiconfigurationForm.indexCode).then((res) => {
        if (res) {
          alert('PAI Index configuration data already exists!');
        }
        else {   
          this.isFormValid = true;
          let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          return this.httpClient.post(
            environment.dataServiceRoot + 'ui/SITableConfigurations/AddPAIConfiguration',
            JSON.stringify({
              IndexCode: paiconfigurationForm.indexCode,
              Currency: paiconfigurationForm.currency            
            }),

            { headers: headers }).toPromise()
            .then(response => {
              var result = response as ResponseModel;
              if (result.data != 0) {
                this.publishClient("PAI Index configuration data created successfully!");
              }
              else {
                this.publishClient(result.meta.message);
              }
              
            }).catch(this.handleErr);
        }
      });
    }
    else {
      alert('Index code must be non empty');
    }
  }

  savepaiconfiguration(paiconfigurationForm: NewPAIConfigurationForm): any {

  if (paiconfigurationForm.indexCode !=undefined ? paiconfigurationForm.indexCode.toLowerCase() != "" : true) {
      this.isFormValid = true;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(
        environment.dataServiceRoot + 'ui/SITableConfigurations/UpdatePAIConfiguration',
        JSON.stringify({
          IndexCode: paiconfigurationForm.indexCode,
          Currency: paiconfigurationForm.currency           
        }),

        { headers: headers }).toPromise()
        .then(response => {
          var result = response as ResponseModel;
          if (result.data != 0) {
            this.publishClient("Updated PAI Configuration data successfully!");
          }
          else {
            this.publishClient(result.meta.message);
          }
        }).catch(this.handleErr);
    } else {
      alert('Index code must non-empty');
    }
  }

  private handleErr(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }



  GetPAIConfigurationData(pageIndex, pageSize): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/PAIConfigurationList', JSON.stringify({ page: pageIndex, pageSize: pageSize }),
      { headers: headers });
  }
    GetTableConfigurations(): Observable<any> {
      
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/TableConfigurations', null,
      { headers: headers });
  }
  GetPAIConfigurationDataByFilter(pageIndex, pageSize, filter): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/PAIConfigurationListByFilter', JSON.stringify({ pageSize: pageSize, page: pageIndex, filter: filter }),
      { headers: headers });
  }
  GetindexList(): Observable<any> {
   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/PAIConfigurationIndexList',null,
     { headers: headers });
  }

 getCurrencies():Observable<any>
  {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
    return this.httpClient.post( 
      environment.dataServiceRoot + 'ui/SITableConfigurations/GetCurrenciesList',null,
      {headers: headers});
  }
  
  deletepaiconfigurationData(paiconfigurationForm: any): any {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DeletePAIConfiguration',
      JSON.stringify({
        IndexCode: paiconfigurationForm.IndexCode
      }),
      { headers: headers }).toPromise()
      .then(response => {
        var result = response;
        this.publishClient("PAI Configuration data has been deleted");
        return result;
      }).catch(this.handleErr);
  }

  downloadExcel(indexCode:string): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(
      environment.dataServiceRoot + 'ui/SITableConfigurations/DownloadPAIConfigurationExcel',
      JSON.stringify({
      IndexCode: indexCode
      }),
      { headers: headers }).toPromise()
      .then(response => {
          var result = response;
          return result;
    })
        .catch (this.handleErr);
  }

  }

