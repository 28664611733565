export interface iTable {
    id: number,
    name: string
}
export interface iControl {
    label: String,
    placeholder: string
    isVisible: boolean,
}
export interface iFilter {
    tableId: number,
    asOfDate: Date,
    indexId: string,
    requestId: number,
}
export interface iIndexMarker {
    indexId: number,
    indexMarker: string,
    displayName: string,
}

export interface iRequest 
{
    id: number,
    tableName: string;
    indexMarker: string;
    asOfDate: Date;
    status: string;
    timeTaken : number;
    requestDate: Date;
    displayRefresh: boolean;
    displayTransfer: boolean;
}

export enum Status {
    Started='started',
    ValidatingRequest='validatingrequest',
    RecordsAlreadyExists='recordsalreadyexists',
    NoRecordsFound='norecordsfound',
    InProgress='inprogress',
    Completed='completed',
    Failed='failed',
}