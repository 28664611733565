import { Component, OnInit, OnChanges,SimpleChanges } from '@angular/core';
import { CompanyModel, UserProfileModel } from '../../models/entitlement.model';
import { APIFieldsModel,EntitlementModel } from '../../models/entitlement.model';
import { EntitlementService } from '../../services/entitlement.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticateService } from '../../services/authenticate.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})

export class UserProfileComponent implements OnInit, OnChanges {
  userProfile: UserProfileModel;
  commitStatus: any;
  companies: CompanyModel[];
  mode: any;
  constructor(
     private entitlementService: EntitlementService,
     private authService: AuthenticateService,
     private route: ActivatedRoute) { 
      var sub = this.route.params.subscribe(
        params => {
          this.userProfile =
          { 
            userName:params["userName"],
            companyName :params["companyName"],
            ddsAccountNumber:params["ddsAccountNumber"],
            email:params["email"],
            gender:params["gender"],
            name:params["name"],
            password: "",
            phoneNumber:params["phoneNumber"],
            roles: params["roles"]
          };
          this.mode = params["mode"];
          this.commitStatus = "";
        }
       );
     }

  ngOnInit() {
   
    this.entitlementService.getCompanies().then(result =>
      {
        this.companies = result;
      })
  }
  ngOnChanges(changes:SimpleChanges){}
  saveChanges()
  {
    if(this.mode == "EDIT")
    {
      this.authService.updateUserAttribute(this.userProfile);
    }
    else
    {
      this.authService.registerUserProfile(this.userProfile);
    }
    this.entitlementService.saveUser(this.userProfile).then
    (result => {
      if(result == "1")
      {
       this.commitStatus = "Saved successfully. User need to reconfirm my clicking the confirmation link sent to email."
      }
      else
      {
        this.commitStatus = "Not saved"
      }
    });
    this.commitStatus = "Saved successfully"
  }
}
