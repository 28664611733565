import { Component, EventEmitter, Input, OnInit, Inject,Output, ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {SelectionModel} from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { ConnectContactLens } from 'aws-sdk';
import { ExportReportData, Report, ReportData } from '../../models/report';
import { climatedatapackconfigurationDataService } from '../../services/climatedatapackconfigurations.service';
import { DownloadService } from '../../services/download.service';
import {S3ManagerService} from '../../../services/s3-manager.service';
import { saveAs } from 'file-saver/FileSaver';
import { ArrayDataSource } from '@angular/cdk/collections';
import * as CryptoJS from "crypto-js";
import * as AWS from 'aws-sdk';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';
import { climatedatapackconfiguration } from '../../models/climatedatapackconfiguration';
import { environment } from '../../../../environments/environment';

enum ColumnNames {
  indexCode = 'IndexCode',
  parentIndexCode = 'ParentIndexCode',
  currency ="Currency",
  ModifiedUser = 'ModifiedUser',
  ModifiedTime = 'ModifiedTime',
  parentcurrency = 'parentcurrency',
  controls = 'controls'
}

@Component({
  selector: 'climatedatapackconfiguration-data-grid',
  templateUrl: './climatedatapackconfiguration-data-grid.component.html',
  styleUrls: ['./climatedatapackconfiguration-data-grid.component.scss']
})
export class climatedatapackconfigurationDataGridComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() edit = new EventEmitter<Report>();
  @Output() indexCodeEvent = new EventEmitter<string>();
  dataSource: any;
  isVisible :boolean = false ;
  pageSizeOptions: number[] = [5, 10, 15, 20, 25];
  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 5;
  pageCount: number = 0;
  pager: any = {};
  unsub: any;
  climatedatapackconfiguration: climatedatapackconfiguration[] = [];
  data: MatTableDataSource<any>;
  oldPageLimit: number;
  response: any;
  selectedIndex: any;
  selectedValue: any;
  activePageDataChunk: any;
  pageLength: number;
  filterForm: any;
  serchtext: any = '';
  isupdate :boolean = false ;
  exportresultArray: ExportReportData[] = [];
  selectedreports: number[] = [];
  passphrase: string = "EncryptionatRest";
  isLoading: boolean = false;
  indexcodes: string[];
  public indexcodefilteredList: string[];
  displayColumnNameMap = new Map<ColumnNames, string>([
    [ColumnNames.indexCode,'Index Code'],
    [ColumnNames.parentIndexCode, 'Parent\n Index Code'],
    [ColumnNames.currency, 'Currency'],
    [ColumnNames.parentcurrency, 'Parent\n Currency'],
    [ColumnNames.ModifiedUser, 'Modified User'],
    [ColumnNames.ModifiedTime, 'Modified date']
  ]);
  displayedColumns: ColumnNames[] = [
    ColumnNames.indexCode,
    ColumnNames.parentIndexCode,
    ColumnNames.currency,
    ColumnNames.parentcurrency,
    ColumnNames.ModifiedUser,
    ColumnNames.ModifiedTime,
    ColumnNames.controls
  ];
  skipColumnsAutoRender = new Set<ColumnNames>([
    ColumnNames.controls
  ]);
  constructor(private router: Router, private climatedatapackconfigurationDataService: climatedatapackconfigurationDataService, private downloadService: DownloadService, private fb: FormBuilder, private s3ManagerService:S3ManagerService) {
    this.unsub = this.climatedatapackconfigurationDataService.clientPublish().subscribe((m: any) => {
      alert(m);
      this.getclimatedatapackconfigurationData();
   
    });
  }

  ngOnInit(): void {
    this.filterForm = this.fb.group({
     IndexCode:  [""],
      ParentIndexCode: [""],
      parentcurrency :[""],
      currency: [""],
      ModifiedUser: [""],
      ModifiedTime: [""]
 

    });
    this.filterForm.valueChanges.subscribe(value => {
      console.log(this.dataSource);
    });
    if (sessionStorage.getItem("UserRoles").indexOf(environment.siAdminGroup) != -1) {
            this.isVisible=true;
        }
    //enable update/delete button for testing in dev
        //this.isVisible=true;
     //end
     this.GetindexList();
    this.getclimatedatapackconfigurationData();
   
  }


  getColumnsViewName(column: ColumnNames): string {
    return this.displayColumnNameMap.get(column) || '';
  }
  

  trim(text: any): string | any {
    if (typeof text === 'string') {
      return text.length < 28 ? text : text.slice(0, 25).trim() + '...';
    }
    return text;
  }
  GetindexList() {
    this.climatedatapackconfigurationDataService.GetindexList().subscribe((res) => {
      this.indexcodes = res.data;
      this.indexcodefilteredList = res.data;
    });
  }
  getclimatedatapackconfigurationData()
  {
    if (this.selectedValue != undefined) {
      this.getclimatedatapackconfigurationDataByfilter(this.selectedValue);     
    }
    else {
      this.isLoading = true;
      this.climatedatapackconfigurationDataService.fetchReports$(this.pageIndex, this.pageSize).subscribe((res) => {
        this.response = res;
        this.dataSource = this.response.Data;

        console.log(this.dataSource);
        this.isLoading = false;
        this.activePageDataChunk = this.dataSource;//.slice(0, this.pageSize);
        for (let item of this.activePageDataChunk) {
          item.ModifiedTime = (item.ModifiedTime).substring(0, 10);
        }
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].TotalCount : 0;

      });
    }
  }
  getclimatedatapackconfigurationDataByfilter(filter) {
    this.isLoading = true;
      this.climatedatapackconfigurationDataService.fetchclimatedatapackconfigurationData$(this.pageIndex, this.pageSize, filter).subscribe((res) => {
        this.response = res;
        this.dataSource = this.response.Data;

        console.log("getclimatedatapackconfigurationData");
        console.log(this.dataSource);
        this.isLoading = false;
        this.activePageDataChunk = this.dataSource;//.slice(0, this.pageSize);
        for (let item of this.activePageDataChunk) {
          item.ModifiedTime = (item.ModifiedTime).substring(0, 10);
        }
        this.dataSource.paginator = this.paginator;
        this.pageLength = this.dataSource.length > 0 ? this.dataSource[0].TotalCount : 0;
      

    });
  }
  onIndexChange(selectedIndex) {
    if (selectedIndex != undefined) {
      this.selectedValue = selectedIndex;
      this.getclimatedatapackconfigurationDataByfilter(selectedIndex);
      this.indexCodeEvent.emit(selectedIndex);
    }
    else {
      this.selectedValue = undefined;
      this.getclimatedatapackconfigurationData();
      this.indexCodeEvent.emit(null);
      this.serchtext = '';
      this.indexcodefilteredList = this.indexcodes;

    }

  }
  filterIndexList(val) {
    this.indexcodefilteredList = this.indexcodes.filter((indexCode) => indexCode.includes(val == undefined ? val : val.toUpperCase()));
  }

  openEditMetric(metric: any) {
    this.isupdate = true ;
    this.edit.emit(metric);
  }

 
  onPageChanged(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getclimatedatapackconfigurationData();
  }
  ngOnDestroy() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
  }
  delete(metric : any) {
    if (confirm("Are you sure to delete climatedatapackconfiguration Data?")) {
      this.climatedatapackconfigurationDataService.deleteclimatedatapackconfigurationData(metric);     
    }
  }
}
