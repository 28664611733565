import { Component,ViewChild,OnInit } from '@angular/core';
import {ReportFilter} from '../../models/report-filter.model';
import { ReportAnalyseGridComponent } from '../report-analyse-grid/report-analyse-grid.component';
import {ReportIndexCharacteristicsComponent} from '../report-index-characteristics/report-index-characteristics.component';
import {FundamentalsReportComponent} from '../fundamentals-report/fundamentals-report.component';
import {DateService} from '../../services/date.service';
import {BiService} from '../../services/BiService.service';
import { environment } from '../../../environments/environment';
import { ReportService } from '../../services/report.service';
import { ExportToExcelService } from '../../services/exportToExcel.service';
import * as xlsx from 'xlsx';
@Component({
    selector: 'reporting-page',
    templateUrl: './reporting-page.component.html' ,
    styleUrls :['./reporting-page.component.css']
  })
export class ReportingPageComponent implements OnInit{
  reportFilter:ReportFilter;
  targetDataKey: string;
  theDateService:DateService;
  showAnalyseGrid:boolean = false;
  showIndexCharacteristicsGrid:boolean= false;
  showFundamentalsReports:boolean = false;
  canDisplayToolbar: boolean = false;
  @ViewChild(ReportAnalyseGridComponent,{static: false}) reportAnalyseGridComponent;
  @ViewChild(ReportIndexCharacteristicsComponent,{static: false}) reportIndeCharacteristicsComponent;
  @ViewChild(FundamentalsReportComponent,{static: false}) fundamentalsReportComponent;
  showFiveDaysTrackerButton:boolean = false;
  showOneDayTrackerButton:boolean = false;
  startDate : any;
  endDate : any;
  exportCodeMatrix:string[] = [];
  calendarControlChangeMatrix:any[] = [];
  constructor(private dateService:DateService,private biService:BiService,private reportService:ReportService ,
    private exportService:ExportToExcelService){
    this.theDateService = this.dateService;
   this.exportCodeMatrix["report-getDrawdownExcessReturnsAnalysis"] = "report-getDrawdownExcessReturnsAnalysisToExport";
   this.exportCodeMatrix["report-getRiskReturnList"] = "report-getRiskReturnListToExport";
   this.exportCodeMatrix["report-getTrackingReport"] = "report-getTrackingReportToExport";
   this.exportCodeMatrix["report-getDailyTrackingReport"] = "report-getDailyTrackingReportToExport";

   this.calendarControlChangeMatrix["report-getDrawdownExcessReturnsAnalysis"] = this.PerformanceAnalysis;
   this.calendarControlChangeMatrix["report-getRiskReturnList"] = this.RiskReturnAnalysis;
   this.calendarControlChangeMatrix["report-getTrackingReport"] = this.TrackingReport;
   this.calendarControlChangeMatrix["report-getDailyTrackingReport"] = this.DailyTrackingReport;
  }
  ngOnInit(){
    if (sessionStorage.getItem("UserRoles") == environment.role) {
      this.showFiveDaysTrackerButton = true;
      this.showOneDayTrackerButton = true;
    }else{
      this.showFiveDaysTrackerButton = false;
      this.showOneDayTrackerButton = false;
    }
    this.startDate = this.dateService.getCalendarDate().start;
    this.endDate = this.dateService.getCalendarDate().end;
    this.reportFilter = new ReportFilter();  
    this.dateService.calendarDatePublish().subscribe(dateRange=>{
      this.startDate = this.dateService.changeDateFormat(dateRange.start);
      this.endDate = this.dateService.changeDateFormat(dateRange.end)
      this.reportFilter.FromDate = this.dateService.changeDateFormat(dateRange.start);
      this.reportFilter.ToDate = this.dateService.changeDateFormat(dateRange.end);
      this.reportFilter.AsOfDate = this.dateService.changeDateFormat(dateRange.end);
      if(this.showIndexCharacteristicsGrid){
        this.IndexCharacteristics();
     }else if(this.showFundamentalsReports){
        this.FundamentalsReport();
     }else{
       this.calendarControlChangeMatrix[this.reportFilter.ReportCode].apply(this);
     }
  });  
   this.dateService.timePeriodPublish().subscribe(timePeriod =>{
      this.reportFilter.TimePeriod = timePeriod;
      if(this.showIndexCharacteristicsGrid){
        this.IndexCharacteristics();
     }else if(this.showFundamentalsReports){
        this.FundamentalsReport();
     }else{
       this.calendarControlChangeMatrix[this.reportFilter.ReportCode].apply(this);
     }
   });
  }

  IndexCharacteristics():void{
    var event:any= {eventName: "Index Characteristics Analysis Grid "};
    this.biService.addEvent(event);
    this.canDisplayToolbar = true;
    this.showAnalyseGrid = false;
    this.showFundamentalsReports = false;
    this.showIndexCharacteristicsGrid = true;
    this.targetDataKey = "Index Characteristics";
    this.reportIndeCharacteristicsComponent.gridSetup();
  }
  PerformanceAnalysis():void{
    var event:any= {eventName: "Performance Analysis Grid "};
    this.biService.addEvent(event);
    this.showFundamentalsReports = false;
    this.showAnalyseGrid = true;
    this.showIndexCharacteristicsGrid = false;
    this.canDisplayToolbar = true;
    this.reportFilter.ReportCode = "report-getDrawdownExcessReturnsAnalysis";
    this.targetDataKey = "Performance Analysis";
    this.reportFilter.ReportCustColumnsCode ="customColumChooser-getReportingDrawdownExcessRturnsAnalysisCustomColumns";
    this.reportFilter.CurrencyCode = "USD";
    this.getReportFilterDates();
    this.getReportFilterTimePeriod();
    this.reportAnalyseGridComponent.gridSetup(this.reportFilter);
  }

  RiskReturnAnalysis():void{
    var event:any= {eventName: "Risk Returns Grid Generated"};
    this.biService.addEvent(event);
    this.showAnalyseGrid = true;
    this.showFundamentalsReports = false;
    this.showIndexCharacteristicsGrid = false;
    this.canDisplayToolbar = true;
    this.reportFilter.ReportCode = "report-getRiskReturnList";
    this.targetDataKey = "Risk Returns";
    this.reportFilter.ReportCustColumnsCode ="customColumChooser-getReportingRiskReturnsCustomColumns";
    this.reportFilter.CurrencyCode = "USD";
    this.getReportFilterDates();
    this.getReportFilterTimePeriod();
    this.reportAnalyseGridComponent.gridSetup(this.reportFilter);
  }


  
  TrackingReport():void{
    var event:any= {eventName: "Five Days tracking Grid Generated"};
    this.biService.addEvent(event);
    this.showAnalyseGrid = true;
    this.showFundamentalsReports = false;
    this.showIndexCharacteristicsGrid = false;
    this.canDisplayToolbar = true;
    this.reportFilter.ReportCode = "report-getTrackingReport";
    this.targetDataKey = "Tracking Report";
    this.reportFilter.ReportCustColumnsCode ="customColumChooser-getTrackingReportCustomColumns";
    this.reportFilter.CurrencyCode = "USD";
    this.getReportFilterDates();
    this.getReportFilterTimePeriod();
    this.reportAnalyseGridComponent.gridSetup(this.reportFilter);
  }

  DailyTrackingReport():void{
 
     this.canDisplayToolbar = true;
     this.showAnalyseGrid = true;
     this.showFundamentalsReports = false;
     this.showIndexCharacteristicsGrid = false;
        this.reportFilter.ReportCode = "report-getDailyTrackingReport";
        this.targetDataKey = "Daily Tracking Report";
        this.reportFilter.ReportCustColumnsCode ="customColumChooser-getDailyTrackingReportCustomColumns";
        this.reportFilter.CurrencyCode = "USD";
        this.getReportFilterDates();
        this.getReportFilterTimePeriod();
        this.reportAnalyseGridComponent.gridSetup(this.reportFilter);
      }
      FundamentalsReport(){
        this.canDisplayToolbar = true;
        this.showAnalyseGrid = false;

     this.showFundamentalsReports = true;
     this.showIndexCharacteristicsGrid = false;
     var event:any= {eventName: "Fundamentals Report "};
    this.biService.addEvent(event);
    this.targetDataKey = "Fundamentals Report";
     this.fundamentalsReportComponent.fetchData();
      }
  
  private getReportFilterDates():void{
    this.reportFilter.FromDate = this.dateService.getCalendarDate().start;
    this.reportFilter.ToDate = this.dateService.getCalendarDate().end;
    this.reportFilter.AsOfDate = this.dateService.getCalendarDate().end;
  }

  private getReportFilterTimePeriod():void{
    this.reportFilter.TimePeriod = this.dateService.getTimePeriod();
  }

  ReportPageCustomColumnsSelction(event):void{
    this.reportAnalyseGridComponent.gridSetup(this.reportFilter);
  }

  Exportxls(){
    var event: any;
    if("Performance Analysis" == this.targetDataKey){
      event= {eventName: "Performance Analysis Grid Exported"};
      this.reportFilter.ReportCode = "report-getDrawdownExcessReturnsAnalysis";
    }
    if("Risk Returns" == this.targetDataKey){
      event= {eventName: "Risk Returns Grid Exported"};
      this.reportFilter.ReportCode = "report-getRiskReturnList";
    }
    if("Tracking Report" == this.targetDataKey){
      event= {eventName: "Five Days Tracking Report Grid Exported"};
      this.reportFilter.ReportCode = "report-getTrackingReport";
    }
    if("Index Characteristics" == this.targetDataKey){
      event= {eventName: "Index Characteristics Report Grid Exported"};
    }
    if("Fundamentals Report" == this.targetDataKey){
      event= {eventName: "Fundamentals Report Grid Exported"};
    }
    var customColumns = this.reportAnalyseGridComponent.getCurrentCustomColumns()
    if("Daily Tracking Report" == this.targetDataKey){
       this.reportFilter.CustomColums = customColumns;
       event= {eventName: "Daily Tracking Report Grids Exported"};
     }else if("Index Characteristics" != this.targetDataKey && "Fundamentals Report"!= this.targetDataKey)
     {
      this.reportFilter.CustomColums = customColumns.data.map(cc=>cc.columnName.substring(0,1).toUpperCase()+cc.columnName.substring(1));
      }
      if(null!= event && undefined!= event){
        this.biService.addEvent(event);
      }
    if("Index Characteristics" == this.targetDataKey) {
        this.reportIndeCharacteristicsComponent.exportXls();
    }else if("Fundamentals Report" == this.targetDataKey){
        this.fundamentalsReportComponent.exportXls();
    }
    else{
      var dataReportCode = this.reportFilter.ReportCode;
      this.reportFilter.ReportCode = this.exportCodeMatrix[this.reportFilter.ReportCode];
      this.reportAnalyseGridComponent.exportData(this.reportFilter);
      this.reportFilter.ReportCode = dataReportCode;
    }
    
  }
  exportXlsAllianzGlobalReport()
  {
      var event: any = {eventName: "Export Allianz Global Investment - Monthly Characteristics Report", inputParameters: [{Name : "StartDate" , Value: this.startDate}
      ,{Name : "EndDate" , Value: this.endDate}]};
      this.biService.addEvent(event);
      this.reportService.allianzGlobalInvestmentReport(this.startDate , this.endDate ).then(res => {
          this.exportService.exportData(res["data"],"Allianz Global Investment - Monthly Characteristics Report");
      });
  }
  exportXlsParametricFTSERAFIQSRReturnsReport()
  {
      var event: any = {eventName: "Export Parametric - FTSE RAFI QSR Returns Report ", inputParameters: [{Name : "StartDate" , Value: this.startDate}
      ,{Name : "EndDate" , Value: this.endDate}]};
      this.biService.addEvent(event);
      this.reportService.parametricFTSERAFIQSRReturnsReport(this.startDate , this.endDate ).then(res => {
          this.exportService.exportData(res["data"],"Parametric - FTSE RAFI QSR Returns  Report");
      });
  }

  exportXlsLitmanGregoryMonthlyAssignmentReport()
  {
      var event: any = {eventName: "Litman Gregory - Monthly Assignment Report ", inputParameters: [{Name : "StartDate" , Value: this.startDate}
      ,{Name : "EndDate" , Value: this.endDate}]};
      this.biService.addEvent(event);
      this.reportService.litmanGregoryMonthlyAssignmentReport(this.startDate , this.endDate ).then(res => {
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(res.data.litmanGregoryDaily);
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Litman Gregory Daily');
      const ws1: xlsx.WorkSheet = xlsx.utils.json_to_sheet(res.data.litmanGregoryMonthlyReport);
      xlsx.utils.book_append_sheet(wb, ws1, 'Litman Gregory Monthly');
      xlsx.writeFile(wb, "Litman Gregory - Monthly Assignment Report.xlsx");
      });
  }
  exportXlsAmericanReport()
  {
      var event: any = {eventName: "American Beacon - Monthly Characteristics Report", inputParameters: [{Name : "StartDate" , Value: this.startDate}
      ,{Name : "EndDate" , Value: this.endDate}]};
      this.biService.addEvent(event);
      this.reportService.getAmericanBeaconReportsToExport(this.startDate , this.endDate ).then(res => {
          this.exportService.exportData(res["data"],"American Beacon - Monthly Characteristics Report");
      });
  }
  exportXlsTCWReport()
  {
      var event: any = {eventName: "TCW Monthly Assignment - Report", inputParameters: [{Name : "StartDate" , Value: this.startDate}
      ,{Name : "EndDate" , Value: this.endDate}]};
      this.biService.addEvent(event);
      this.reportService.tcwReportsToExport(this.startDate , this.endDate ).then(res => {
          this.exportService.exportData(res["data"],"TCW Monthly Assignment - Report");
      });
  }
  exportXlsEAMReport()
  {
      var event: any = {eventName: "EAM Monthly Assignment - Report", inputParameters: [{Name : "StartDate" , Value: this.startDate}
      ,{Name : "EndDate" , Value: this.endDate}]};
      this.biService.addEvent(event);
      this.reportService.EAMReportsToExport(this.startDate , this.endDate ).then(res => {
        console.log(res)
        const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(res.data.magellanEAMOutput);
        const wb: xlsx.WorkBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'EAM Monthly');
        const ws1: xlsx.WorkSheet = xlsx.utils.json_to_sheet(res.data.magellanEAMDailyOutput);
        xlsx.utils.book_append_sheet(wb, ws1, 'EAM Daily');
        xlsx.writeFile(wb, "EAM Monthly Assignment - Reportt.xlsx");
        });
 
  }
  exportXlsBHMSQuarterlyReport(){
    var event: any = {eventName: "Export BHMS-Quarterly Report", inputParameters: [{Name : "StartDate" , Value: this.startDate}
    ,{Name : "EndDate" , Value: this.endDate}]};
    this.biService.addEvent(event);
    this.reportService.exportBHMSReport(this.startDate , this.endDate ).then(res => {
        this.exportService.exportData(res["data"],"BHMS-Quarterly Report");
    });
  }
  exportXlsQuarterlyReportAMI()
  {
      var event: any = {eventName: "Export Quarterly report AMI", inputParameters: [{Name : "StartDate" , Value: this.startDate}
      ,{Name : "EndDate" , Value: this.endDate}]};
      this.biService.addEvent(event);
      this.reportService.quarterlyReportAMI(this.startDate , this.endDate ).then(res => {
          this.exportService.exportData(res["data"],"Quarterly report AMI");
      });
  }
  exportXlsElkCreekQuarterlyReport()
  {
    var event: any = {eventName: "Export Quarterly report Elk Creek", inputParameters: [{Name : "StartDate" , Value: this.startDate}
      ,{Name : "EndDate" , Value: this.endDate}]};
      this.biService.addEvent(event);
      this.reportService.exportElkCreekReport(this.startDate , this.endDate ).then(res => {
          this.exportService.exportData(res["data"],"Elk Creek-Quarterly Report");

      });
    }

  exportXlsEAMQuarterlyAssignmentReport()
  {
      var event: any = {eventName: "Export Report EAM - daily values for Quarterly Assignment ", inputParameters: [{Name : "StartDate" , Value: this.startDate}
      ,{Name : "EndDate" , Value: this.endDate}]};
      this.biService.addEvent(event);
      this.reportService.reportEAMQuarterlyAssignment(this.startDate , this.endDate ).then(res => {
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(res.data.eamQuarterlyAssignmentReportData1);
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'EAM Quarterly Report 1');
      const ws1: xlsx.WorkSheet = xlsx.utils.json_to_sheet(res.data.eamQuarterlyAssignmentReportData2);
      xlsx.utils.book_append_sheet(wb, ws1, 'EAM Quarterly Report 2');
      xlsx.writeFile(wb, "EAM - Daily Values For Quarterly Assignment Report.xlsx");
      });
  }
}