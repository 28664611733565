import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BiService } from '../../services/BiService.service';
import { DataqualityserviceService } from '../../services/dataqualityservice.service';

@Component({
  selector: 'app-data-quality-client-dropdown',
  templateUrl: './data-quality-client-dropdown.component.html',
  styleUrls: ['./data-quality-client-dropdown.component.css']
})
export class DataQualityClientDropdownComponent implements OnInit {
  clients: any[]= [ { "key": "", "value": "Loading" }]; 
  defaultClient:string="ALL";
  constructor( private biService:BiService ,private router:Router ,private route: ActivatedRoute,private dataqualityservice:DataqualityserviceService) { }

  ngOnInit(): void {
    this.dataqualityservice.getClient().then(data=>{                                      
      this.clients=(data.length>0)? data: [ { "key": "", "value": "Currency not found" }];  
  });
  var sub = this.route.queryParams.subscribe(params => {  
    this.defaultClient =params['Client'] ;
    if(this.defaultClient== undefined)
    {
      this.defaultClient= "ALL";
    }
  });
  }
  
  onClientChange(client: string) {   
    this.defaultClient = client;
    this.router.navigate([window.location.pathname],{queryParams:{Client :this.defaultClient}});
    var event: any = {eventName: "When changing the Client Name", inputParameters: [{Name: "Client", Value: client }]};
    this.biService.addEvent(event);  
    }  
}
