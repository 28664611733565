import { Injectable }    from '@angular/core';
import {CloseHoldingService} from './close-holdings.service';
import {LandingService} from './landing.service';
import {OpenHoldingService} from './open-holdings.service';
import {PerformanceService} from './performance.service';
import {ReferenceService} from './reference.service';
import {ValuesService} from './values.service';
import {WeightService} from './weight.service';
import {ExportToExcelService} from './exportToExcel.service';
import {FactorsService} from './factors.service';
import {DdsService} from './dds.service';

@Injectable()
export class IDWChartXlsExportService {

    serviceMatrix:any[] = [];
    methodParamsMatrix:any[] = [];
    methodNameMatrix:any[] = [];
    constructor(private closeHoldingService:CloseHoldingService,private landingService: LandingService,
    private openHoldingsService:OpenHoldingService,private performanceService:PerformanceService,
    private referenceService:ReferenceService,private valuesService: ValuesService,
    private weightService: WeightService,private exportToExcelService:ExportToExcelService, private factorsService: FactorsService,private ddsService: DdsService){
        this.serviceMatrix["closeHoldings"] = this.closeHoldingService;
        this.serviceMatrix["landing"] = this.landingService;
        this.serviceMatrix["openHoldings"] = this.openHoldingsService;
        this.serviceMatrix["performance"] = this.performanceService;
        this.serviceMatrix["reference"] = this.referenceService;
        this.serviceMatrix["value"] = this.valuesService;
        this.serviceMatrix["weight"] = this.weightService;
        this.serviceMatrix["factors"] = this.factorsService;
        this.serviceMatrix["dds"]= this.ddsService;
        this.methodParamsMatrix["getCloseHoldingsTopBottomHoldingsData"] = this.createCloseHoldingsTopBottomHoldingsDataParams;;
        this.methodParamsMatrix["getCloseHoldingsCountryWeightData"] = this.createtCloseHoldingsCountryWeightDataParams;
        this.methodParamsMatrix["getCloseHoldingsCurrencyWeightData"] = this.createCloseHoldingsCurrencyWeightDataParams;
        this.methodParamsMatrix["getGrowthofUnitDataToExport"] = this.createGrowthofUnitDataToExportParams;
        this.methodParamsMatrix["getIndexReturnsToExport"] = this.createIndexReturnsToExportParams;
        this.methodParamsMatrix["getOpenHoldingsTopBottomHoldingsData"] = this.createOpenHoldingsTopBottomHoldingsDataParams;
        this.methodParamsMatrix["getOpenHoldingsCountryWeightData"] = this.createtOpenHoldingsCountryWeightDataParams;
        this.methodParamsMatrix["getOpenHoldingsCurrencyWeightData"] = this.createOpenHoldingsCurrencyWeightDataParams;
        this.methodParamsMatrix["getMonthlyPerformanceChartData"] = this.createMonthlyPerformanceChartDataParams;
        this.methodParamsMatrix["getTimeSeriesChartData"] = this.createTimeSeriesChartDataParams;
        this.methodParamsMatrix["getPerformance2DMultiSeriesChartData"] = this.createPerformance2DMultiSeriesChartDataParams;
        this.methodParamsMatrix["getCalendarReturnsChartData"] = this.createCalendarReturnsChartDataParams;
        this.methodParamsMatrix["getIndexCalculatorDataByValuesToExport"] = this.createIndexCalculatorDataByValuesToExportParams;
        this.methodParamsMatrix["getIndexPropertiesGridToExport"] = this.createIndexPropertiesGridToExportParams;
        this.methodParamsMatrix["getProductIndexMarkerData"] = this.createProductIndexMarkerDataParams;
        this.methodParamsMatrix["getGrowthofUnitByValuesData"] = this.createGrowthofUnitByValuesDataParams;
        this.methodParamsMatrix["getIndexValuesConstituentCountMSChartData"] = this.createIndexValuesConstituentCountMSChartDataParams;
        this.methodParamsMatrix["getIndexValuesMSChartData"] = this.creategetIndexValuesMSChartDataParams;
        this.methodParamsMatrix["getTopLevelSectorData"] = this.createTopLevelSectorDataParams;
        this.methodParamsMatrix["getCountryWeightsExportData"] = this.createCountryWeightsExportDataParams;
        this.methodParamsMatrix["getSectorWeightsExportData"] = this.createSectorWeightsExportDataParams;
        this.methodParamsMatrix["getSubSectorWeightsExportData"] = this.creategetSubSectorWeightsExportDataParams;
        this.methodParamsMatrix["getIndustryWeightData"] = this.createIndustryWeightDataParams;
        this.methodParamsMatrix["getSuperSectorWeightData"] = this.createSuperSectorWeightDataParams;
        this.methodParamsMatrix["getWeightTabData"] = this.createWeightTabDataParams;

        this.methodParamsMatrix["getFactorsTotalVolatilityData"] = this.createFactorsTotalVolatilityDataParams;
        this.methodParamsMatrix["getFactorsPriceVolatilityData"] = this.createFactorsPriceVolatilityDataParams;
        this.methodParamsMatrix["getDDSInventoryData"] = this.createDDSInventoryDataParams;

        this.methodNameMatrix["getCloseHoldingsTopBottomHoldingsData"] = "getTopBottomHoldingsData";
        this.methodNameMatrix["getCloseHoldingsCountryWeightData"] = "getCountryWeightData";
        this.methodNameMatrix["getCloseHoldingsCurrencyWeightData"] = "getCurrencyWeightData";
        this.methodNameMatrix["getGrowthofUnitDataToExport"] = "getGrowthofUnitDataToExport";
        this.methodNameMatrix["getIndexReturnsToExport"] = "getIndexReturnsToExport";
        this.methodNameMatrix["getOpenHoldingsTopBottomHoldingsData"] = "getTopBottomHoldingsData";
        this.methodNameMatrix["getOpenHoldingsCountryWeightData"] = "getCountryWeightData";
        this.methodNameMatrix["getOpenHoldingsCurrencyWeightData"] = "getCurrencyWeightData";
        this.methodNameMatrix["getMonthlyPerformanceChartData"] = "getMonthlyPerformanceChartData";
        this.methodNameMatrix["getTimeSeriesChartData"] = "getTimeSeriesChartData";
        this.methodNameMatrix["getPerformance2DMultiSeriesChartData"] = "getPerformance2DMultiSeriesChartData";
        this.methodNameMatrix["getCalendarReturnsChartData"] = "getCalendarReturnsChartData";
        this.methodNameMatrix["getIndexCalculatorDataByValuesToExport"] = "getIndexCalculatorDataByValuesToExport";
        this.methodNameMatrix["getIndexPropertiesGridToExport"] = "getIndexPropertiesGridToExport";
        this.methodNameMatrix["getProductIndexMarkerData"] = "getProductIndexMarkerData";
        this.methodNameMatrix["getGrowthofUnitByValuesData"] = "getGrowthofUnitByValuesData";
        this.methodNameMatrix["getIndexValuesConstituentCountMSChartData"] = "getIndexValuesConstituentCountMSChartData";
        this.methodNameMatrix["getIndexValuesMSChartData"] = "getIndexValuesMSChartData";
        this.methodNameMatrix["getTopLevelSectorData"] = "getTopLevelSectorData";
        this.methodNameMatrix["getCountryWeightsExportData"] = "getCountryWeightsExportData";
        this.methodNameMatrix["getSectorWeightsExportData"] = "getSectorWeightsExportData";
        this.methodNameMatrix["getSubSectorWeightsExportData"] = "getSubSectorWeightsExportData";
        this.methodNameMatrix["getIndustryWeightData"] = "getIndustryWeightData";
        this.methodNameMatrix["getSuperSectorWeightData"] = "getSuperSectorWeightData";
        this.methodNameMatrix["getWeightTabData"] = "getWeightTabData";

        this.methodNameMatrix["getFactorsTotalVolatilityData"] = "getFactorsTotalVolatilityData";
        this.methodNameMatrix["getFactorsPriceVolatilityData"] = "getFactorsPriceVolatilityData";
        this.methodNameMatrix["getDDSInventoryData"] = "getDDSInventoryData";

    }

    exportData(inputData:any){
        var customService = this.getServiceInstance(inputData["code"]);
        var customMethod = this.getMethodsGeneratorParams(inputData["code"]);
        var customParams =  customMethod(inputData); 
        var exportService = this.exportToExcelService;
        customService[this.getMethodName(inputData["code"])].apply(customService,customParams).then(data=>{
           var dataToExport = (data.data == undefined)?data:data.data;
           exportService.exportData(dataToExport,inputData["xlsFileName"]);
        });
    }


   private createCloseHoldingsTopBottomHoldingsDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["currencyCode"]);
    return params;
   }

   private createtCloseHoldingsCountryWeightDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    return params;
   }

   private createCloseHoldingsCurrencyWeightDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    return params;
   }

   private createGrowthofUnitDataToExportParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["fromDate"]);
    params.push(inputData["toDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["currencyCode"]);
    params.push(inputData["timePeriod"]);
    params.push(inputData["returnType"]);
    return params;
   }

   private createIndexReturnsToExportParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["indexMarkers"]);
    params.push(inputData["asOfDate"]);
    params.push(inputData["selectedColumns"]);
    return params;
   }

   private createOpenHoldingsTopBottomHoldingsDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["currencyCode"]);
    return params;
   }

   private createtOpenHoldingsCountryWeightDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    return params;
   }

   private createOpenHoldingsCurrencyWeightDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    return params;
   }

   private createMonthlyPerformanceChartDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["fromDate"]);
    params.push(inputData["toDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["timePeriod"]);
    params.push(inputData["currencyCode"]);    
    params.push(inputData["returnType"]);
    return params;
   }

   private createTimeSeriesChartDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["timePeriod"]);
    params.push(inputData["currencyCode"]);    
    params.push(inputData["returnType"]);
    return params;
   }

   private createPerformance2DMultiSeriesChartDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["timePeriod"]);
    params.push(inputData["currencyCode"]);    
    params.push(inputData["returnType"]);
    return params;
   }

   private createCalendarReturnsChartDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);    
    params.push(inputData["indexMarkers"]);
    params.push(inputData["timePeriod"]);
    params.push(inputData["currencyCode"]);    
    params.push(inputData["returnType"]);
    return params;
   }

   private createIndexCalculatorDataByValuesToExportParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["fromDate"]);
    params.push(inputData["toDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["currencyCode"]);    
    params.push(inputData["returnType"]);
    params.push(inputData["selectedColumns"]);
    return params;
   }

   private createIndexPropertiesGridToExportParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["indexMarkers"]);
    params.push(inputData["asOfDate"]); 
    params.push(inputData["selectedColumns"]);
    return params;
   }

   private createProductIndexMarkerDataParams(inputData:any){
    var params:any[] = [];
    return params;
   }

   private createGrowthofUnitByValuesDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["fromDate"]);
    params.push(inputData["toDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["currencyCode"]);
    params.push(inputData["timePeriod"]);
    params.push(inputData["returnType"]);
    return params;
   }

   private createIndexValuesConstituentCountMSChartDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["currencyCode"]);
    return params;
   }

   private creategetIndexValuesMSChartDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["currencyCode"]);
    return params;
   }

   private createTopLevelSectorDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["returnType"]);
    params.push(inputData["currencyCode"]);
    params.push(inputData["fromDate"]);
    return params;
   }

   private createCountryWeightsExportDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["fromDate"]);
    params.push(inputData["timePeriod"]);
    return params;
   }

   private createSectorWeightsExportDataParams(inputData){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["returnType"]);
    params.push(inputData["fromDate"]);
    params.push(inputData["timePeriod"]);
    
    return params;
   }

   private creategetSubSectorWeightsExportDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["returnType"]);
    params.push(inputData["fromDate"]);
    params.push(inputData["timePeriod"]);
    
    return params;
   }

   private createIndustryWeightDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["returnType"]);
    params.push(inputData["currencyCode"]);    
    params.push(inputData["fromDate"]);
    params.push(inputData["timePeriod"]);
    
    return params;
   }

   private createSuperSectorWeightDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["asOfDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["returnType"]);
    params.push(inputData["currencyCode"]);    
    params.push(inputData["fromDate"]);
    params.push(inputData["timePeriod"]);
    
    return params;
   }

   private createWeightTabDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["toDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["fromDate"]);
    params.push(inputData["timePeriod"]);
    
    return params;
   }

   private createFactorsTotalVolatilityDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["fromDate"]);
    params.push(inputData["toDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["currencyCode"]); 
    params.push(inputData["timePeriod"]);
    
    return params;
   }

   private createFactorsPriceVolatilityDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["fromDate"]);
    params.push(inputData["toDate"]);
    params.push(inputData["indexMarkers"]);
    params.push(inputData["currencyCode"]); 
    params.push(inputData["timePeriod"]);
    
    return params;
   }

   private createDDSInventoryDataParams(inputData:any){
    var params:any[] = [];
    params.push(inputData["startDate"]);
    params.push(inputData["endDate"]);
    params.push(inputData["isReissued"]);    
    return params;
   }

    private getServiceInstance(code:any):any{
        var serviceName = code.split('-')[0];
        return this.serviceMatrix[serviceName];
    }

    private getMethodName(code:string):any{
        var methodCodeName = code.split('-')[1];
        return this.methodNameMatrix[methodCodeName];
    }
    private getMethodsGeneratorParams(code:string):any{
        var methodCodeName = code.split('-')[1];
        return this.methodParamsMatrix[methodCodeName];
    }
}