import { Injectable }    from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import {ResponseModel} from '../models/landing-page-model';

@Injectable()
export class FactorsService {

    private headers = new Headers({'Content-Type': 'application/json'});
    constructor( private httpClient: HttpClient) { 
    }

    getFactorsTotalVolatilityChart(startDate:any,endDate:any,indexMarkers:any,currencyCode:any,timePeriod:any): Promise<ResponseModel>{
        
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });   
        return this.httpClient.post(environment.dataServiceRoot + 'ui/factors/GetFactorsTotalVolatilityChart',
        JSON.stringify({
            FromDate : startDate,
            ToDate:endDate,
            AsOfDate: endDate,
            CurrencyCode:currencyCode,
            TimePeriod:timePeriod,
            IndexMarkers : indexMarkers                 
          }), {headers: headers})
            .toPromise()
            .then(response =>{
                return response as ResponseModel;
                })
            .catch(this.handleError);
    }

    getFactorsPriceVolatilityChart(startDate:any,endDate:any,indexMarkers:any,currencyCode:any,timePeriod:any): Promise<ResponseModel>{
        
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });  
        return this.httpClient.post(environment.dataServiceRoot + 'ui/factors/GetFactorsPriceVolatilityChart',
        JSON.stringify({
            FromDate : startDate,
            ToDate:endDate,
            AsOfDate: endDate,
            CurrencyCode:currencyCode,
            TimePeriod:timePeriod,
            IndexMarkers : indexMarkers                 
          }), {headers: headers})
            .toPromise()
            .then(response =>{
                return response as ResponseModel
                })
            .catch(this.handleError);
    }
    
    getFactorsTotalVolatilityData(startDate:any,endDate:any,indexMarkers:any,currencyCode:any,timePeriod:any):Promise<ResponseModel>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });  
        return this.httpClient.post(environment.dataServiceRoot + 'ui/factors/GetFactorsTotalVolatilityData',
        JSON.stringify({
            FromDate : startDate,
            ToDate:endDate,
            AsOfDate: endDate,
            CurrencyCode:currencyCode,
            TimePeriod:timePeriod,
            IndexMarkers : indexMarkers                 
          }), {headers: headers})
            .toPromise()
            .then(response =>{
                return response as ResponseModel
                })
            .catch(this.handleError);
    }

    getFactorsPriceVolatilityData(startDate:any,endDate:any,indexMarkers:any,currencyCode:any,timePeriod:any):Promise<ResponseModel>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });  
        return this.httpClient.post(environment.dataServiceRoot + 'ui/factors/GetFactorsPriceVolatilityData',
        JSON.stringify({
            FromDate : startDate,
            ToDate:endDate,
            AsOfDate: endDate,
            CurrencyCode:currencyCode,
            TimePeriod:timePeriod,
            IndexMarkers : indexMarkers                 
          }), {headers: headers})
            .toPromise()
            .then(response =>{
                return response as ResponseModel
                })
            .catch(this.handleError);
    }
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
      }
}