import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-expanded-row',
  templateUrl: './expanded-row.component.html',
  styleUrls: ['./expanded-row.component.scss']
})
export class ExpandedRowComponent<T> implements OnInit {

  @Input() expandedColumnNamesMap: T;
  @Input() columns: string[];
  @Input() rows: T[];
  @Input() getValue: (row: T, column: string, index: number) => string = (row, column, i) => row[column];
  @Input() filterColumns: string[] = [];
  @Input() filterForm: any;
  @Output() searchValue = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
    this.filterForm.valueChanges.subscribe(value => {
      this.searchValue.emit(value);
    });
  }

}
