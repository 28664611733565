import {GridData} from './common';


export enum BenchmarkDetailsColumns {
  sedol = 'sedol',
  cusip = 'cusip',
  isin = 'isin',
  consCode = 'consCode',
  constituentName = 'constituentName',
  countryCode = 'countryCode',
  weight = 'weight',
  portfolioValue = 'portfolioValue',
  currencyCode = 'currencyCode',
  asOfDate = 'asOfDate'
}

export const detailsColumnsNameMap: Record<BenchmarkDetailsColumns, string> = {
  [BenchmarkDetailsColumns.sedol]: 'SEDOL',
  [BenchmarkDetailsColumns.cusip]: 'CUSIP',
  [BenchmarkDetailsColumns.countryCode]: 'Country',
  [BenchmarkDetailsColumns.isin]: 'ISIN',
  [BenchmarkDetailsColumns.weight]: 'Weight',
  [BenchmarkDetailsColumns.constituentName]: 'Constituent Name',
  [BenchmarkDetailsColumns.consCode]: 'Cons Code',
  [BenchmarkDetailsColumns.portfolioValue]: 'Portfolio Value',
  [BenchmarkDetailsColumns.currencyCode]: 'Currency Code',
  [BenchmarkDetailsColumns.asOfDate]: 'AsOfDate'
};

export enum ColumnNames {
  chevron = 'chevron',
  IndexMarker = 'indexMarker',
  IndexName = 'indexName',
  AssetClass = 'assetClass',
  controls = 'controls'
}
export interface BenchmarkInfo {
  totalEntries  : number ;
  benchmarkData : Benchmark[];
}

export interface Benchmark {
  asOfDate: string;
  consCode: string;
  constituentName: string;
  countryCode: string;
  isoCode: string;
  indexMarker: string;
  marketCapCurrency: string;
  indexName: string;
  isin: string;
  weight: string;
  segmentOfAnalysis: string;
  assetClass: string;
}

export interface BenchmarkDetails extends GridData<Benchmark> {}


export interface BenchmarkParams {
  AsOfDate: string;
  IndexMarker: string;
}
