import {Component, Input, OnInit} from '@angular/core';
import {BenchmarkDetailsColumns, detailsColumnsNameMap} from '../../models/benchmark';

@Component({
  selector: 'app-expanded-row',
  templateUrl: './expanded-row.component.html',
  styleUrls: ['./expanded-row.component.scss']
})
export class ExpandedRowComponent<T> implements OnInit {

  expandedColumnNamesMap: Record<BenchmarkDetailsColumns, string> = detailsColumnsNameMap;
  @Input() columns: string[];
  @Input() rows: T[];
  @Input() getValue: (row: T, column: string, index: number) => string = (row, column, i) => row[column];
  constructor() { }

  ngOnInit(): void {}
}
