import { Component, OnInit, ViewChild } from '@angular/core';
import {EntitlementService} from '../../services/entitlement.service';
import { CompanyModel } from '../../models/entitlement.model';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'index-of-interest',
  templateUrl: './index-of-interest.component.html'
})
export class IndexOfInterestComponent implements OnInit {
  companies: CompanyModel[];
    companyName:string = "";
    indexChoices:any[] = [];
    saveMessage:any = "";
    companyId : number ;
    company : any;
    viewOnlyAccess : Boolean =true;
  constructor(private entitlementService:EntitlementService) {

  }

  ngOnInit() {
    if (sessionStorage.getItem("UserRoles") == environment.role 
    || sessionStorage.getItem("UserRoles").indexOf(environment.adminGroup) !== -1
    || sessionStorage.getItem("UserRoles").indexOf(environment.clientServiceOpsGroup) !== -1)
{
  this.viewOnlyAccess = false;
}
    this.entitlementService.getCompanies().then(result =>
      {
        this.companies = result;
      })
  }

  findIndexes(){
    this.saveMessage = "";
    this.companyId = this.company.companyId;
    this.companyName =this.company.companyName;
      this.entitlementService.getIndexOfInterest(this.companyName,new Date())
      .then(result => {
         this.companyName = result["companyName"];
         this.indexChoices = result["indexChoices"];
      });
  }

  SaveIndexChoices(){
    this.entitlementService.saveIndexOfInterest(this.companyName,this.companyId ,this.indexChoices)
    .then(result =>{
       this.saveMessage = result;
    });
  }
}