import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { KPIComponent } from './kpi/kpi.component';
import { BiService } from '../../services/BiService.service';
@Component({
  selector: 'app-lam',
  templateUrl: './lam.component.html'
})
export class LAMComponent implements OnInit, AfterViewInit {

  @ViewChild(KPIComponent,{static: false}) kpiComponent;
  today = new Date(Date.now());
  inputdate = '';

  constructor(private biService: BiService) {
    this.today.setDate(this.today.getDate() - 1);
    this.inputdate = this.today.toISOString().split('T')[0];
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    
  }

  onGetLAMResults() {
    this.biService.addEvent2("GetLAMResults", [{Name:"inputdate", Value:this.inputdate}]);
    this.kpiComponent.updateKPI();
  }
}