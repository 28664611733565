import { Injectable, OnInit }    from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import {ReportService} from './report.service';
import {ReferenceService} from './reference.service';
import {ColumnChooserService} from './column-chooser.service';
import {ReportFilter} from '../models/report-filter.model';

@Injectable()
export class ReportAnalyseMethodGenerator implements OnInit{

    serviceMatrix:any[] = [];
    methodParamsMatrix:any[] = [];

    constructor(private reportService: ReportService, private columnChooserService:ColumnChooserService
        ,private referenceService : ReferenceService) {
        this.serviceMatrix["report"] =  this.reportService;
        this.serviceMatrix["reference"] = this.referenceService;
        this.serviceMatrix["customColumChooser"] = this.columnChooserService;
        this.methodParamsMatrix["getDrawdownExcessReturnsAnalysis"] = this.createGetDrawdownExcessReturnsAnalysisParams;
        this.methodParamsMatrix["getReportingDrawdownExcessRturnsAnalysisCustomColumns"] = this.createDrawdownExcessReturnsAnalysisCustomColumnsParams;
        this.methodParamsMatrix["getDrawdownExcessReturnsAnalysisToExport"] = this.createGetDrawdownExcessReturnsAnalysisParamsToExport;
        this.methodParamsMatrix["getRiskReturnList"] = this.createGetRiskReturnListParams;
        this.methodParamsMatrix["getRiskReturnListToExport"] = this.createGetRiskReturnListToExport;
        this.methodParamsMatrix["getReportingRiskReturnsCustomColumns"] = this.createRiskReturnListCustomColumnsParams;
        this.methodParamsMatrix["getIndexPropertiesList"] = this.creategetreportingriskreturnscustomcolumns;
        this.methodParamsMatrix["getReferenceIndexPropertiesCustomColumns"] = this.creategetreportingriskreturnscustomcolumns;
        this.methodParamsMatrix["updateReportingDrawdownExcessRturnsAnalysisCustomColumn"] = this.createUpdateDrawdownExcessReturnsAnalysisCustomColumnsParams;
        this.methodParamsMatrix["getReferenceProductFamiliesCustomColumns"] = this.createGetReferenceProductFamiliesCustomColumns;
        this.methodParamsMatrix["getDistinctProductFamilies"]= this.createGetDistinctProductFamiliesParams;
        this.methodParamsMatrix["getIndexChangeTrackerCustomColumns"] = this.createGetIndexChangeTrackersCustomColumns;
        this.methodParamsMatrix["getIndexChangeTrackerList"] = this.createGetIndexChangeTrackerParams;
        this.methodParamsMatrix["getTrackingReportCustomColumns"] = this.createTrackingReportCustomColumnsParams;
        this.methodParamsMatrix["getTrackingReport"] = this.createGetTrackingReportParams;
        this.methodParamsMatrix["getTrackingReportToExport"] = this.createGetTrackingReportToExport;
        this.methodParamsMatrix["getDailyTrackingReportCustomColumns"] = this.createDailyTrackingReportCustomColumnsParams;
      this.methodParamsMatrix["getDailyTrackingReport"] = this.createGetDailyTrackingReportParams;
      this.methodParamsMatrix["getDailyTrackingReportToExport"] = this.createGetDailyTrackingReportToExport;
       
      
    }

    ngOnInit(){
        
    }

    
    private createGetTrackingReportToExport(reportFilter: ReportFilter,indexMarkers:string[]){
        var params:any[] = [];
        params.push(reportFilter.FromDate);
        params.push(reportFilter.ToDate);
        params.push(indexMarkers);
        params.push(reportFilter.CurrencyCode);
        params.push(reportFilter.TimePeriod);
        params.push(reportFilter.CustomColums);
        return params;
    }
  private createTrackingReportCustomColumnsParams(reportFilter:ReportFilter,indexMarkers:string[]):any[]{
    var params:any[] = [];
    params.push(indexMarkers);
    return params;        
}

private createGetTrackingReportParams(reportFilter:ReportFilter,indexMarkers:string[]):any[]{
    var params:any[] = [];
    params.push(reportFilter.FromDate);
    params.push(reportFilter.ToDate);
    params.push(indexMarkers);
    params.push(reportFilter.CurrencyCode);
    params.push(reportFilter.TimePeriod);
    return params;
}


    private createGetDailyTrackingReportToExport(reportFilter: ReportFilter,indexMarkers:string[]){
 
             var params:any[] = [];
                    params.push(reportFilter.FromDate);
                    params.push(reportFilter.ToDate);
                    params.push(indexMarkers);
                    params.push(reportFilter.CurrencyCode);
                    params.push(reportFilter.TimePeriod);
                    params.push(reportFilter.CustomColums);
                    return params;
                }
                private createDailyTrackingReportCustomColumnsParams(reportFilter:ReportFilter,indexMarkers:string[]):any[]{
                    var params:any[] = [];
                    params.push(indexMarkers);
                    return params;        
                }
                private createGetDailyTrackingReportParams(reportFilter:ReportFilter,indexMarkers:string[]):any[]{
                    var params:any[] = [];
                    params.push(reportFilter.FromDate);
                    params.push(reportFilter.ToDate);
                    params.push(indexMarkers);
                    params.push(reportFilter.CurrencyCode);
                    params.push(reportFilter.TimePeriod);
                    return params;
              } 
      
    private createGetRiskReturnListParams(reportFilter:ReportFilter,indexMarkers:string[]):any{
        var params:any[] = [];
        params.push(reportFilter.FromDate);
        params.push(reportFilter.ToDate);
        params.push(indexMarkers);
        params.push(reportFilter.CurrencyCode);
        params.push(reportFilter.TimePeriod);
        return params;
    }

    private createGetRiskReturnListToExport(reportFilter: ReportFilter,indexMarkers:string[]){
        var params:any[] = [];
        params.push(reportFilter.FromDate);
        params.push(reportFilter.ToDate);
        params.push(indexMarkers);
        params.push(reportFilter.CurrencyCode);
        params.push(reportFilter.TimePeriod);
        params.push(reportFilter.CustomColums);
        return params;
    }

    private createRiskReturnListCustomColumnsParams(reportFilter:ReportFilter,indexMarkers:string[]):any[]{
        var params:any[] = [];
        params.push(indexMarkers);
        return params;        
  }
  private createGetDistinctProductFamiliesParams():any[]{
      return null;
  }

  private createGetIndexChangeTrackerParams(reportFilter:ReportFilter,indexMarkers:string[]):any[]{
    var params:any[] = [];
    params.push(reportFilter.AsOfDate);
    params.push(indexMarkers);
    return params;
  }

  private creategetreportingriskreturnscustomcolumns():any[]{ 
    return null;        
}

private createGetReferenceProductFamiliesCustomColumns():any[]{
    return null;
}

private createGetIndexChangeTrackersCustomColumns():any[]{
    return null;
}

    private createDrawdownExcessReturnsAnalysisCustomColumnsParams(reportFilter:ReportFilter,indexMarkers:string[]):any{
        var params:any[] = [];
        params.push(indexMarkers);
        return params;
    }

    private createUpdateDrawdownExcessReturnsAnalysisCustomColumnsParams(reportFilter: ReportFilter,indexMarkers:string[]):any{
        var params:any[] = [];
        params.push(indexMarkers);
        params.push(reportFilter.CustomColums);
        return params;
    }
    private createGetDrawdownExcessReturnsAnalysisParams(reportFilter:ReportFilter,indexMarkers:string[]):any[]{
          var params:any[] = [];
          params.push(reportFilter.FromDate);
          params.push(reportFilter.ToDate);
          params.push(indexMarkers);
          params.push(reportFilter.CurrencyCode);
          params.push(reportFilter.TimePeriod);
          return params;
    }

    private createGetDrawdownExcessReturnsAnalysisParamsToExport(reportFilter:ReportFilter,indexMarkers:string[]):any[]
    {
        var params:any[] = [];
        params.push(reportFilter.FromDate);
        params.push(reportFilter.ToDate);
        params.push(indexMarkers);
        params.push(reportFilter.CurrencyCode);
        params.push(reportFilter.TimePeriod);
        params.push(reportFilter.CustomColums);
        return params;
    }

    private createGetDrawdownExcessReturnsAnalysisToExport(reportFilter: ReportFilter,indexMarkers:string[]){
        var params:any[] = [];
        params.push(reportFilter.FromDate);
        params.push(reportFilter.ToDate);
        params.push(indexMarkers);
        params.push(reportFilter.CurrencyCode);
        params.push(reportFilter.TimePeriod);
        params.push(reportFilter.CustomColums);
        return params;
    }

    
    getServiceInstance(code:any):any{
        var serviceName = code.split('-')[0];
        return this.serviceMatrix[serviceName];
    }

    getMethodName(code:string):any{
        return code.split('-')[1];
    }
    getMethodsGeneratorParams(code:string):any{
        var methodName = this.getMethodName(code);
        return this.methodParamsMatrix[methodName];
    }

}