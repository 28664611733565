import { Component, OnInit,Input,OnChanges,SimpleChanges,Inject,NgZone  } from '@angular/core';
import { CloseHoldingService } from '../../services/close-holdings.service';
import {CloseHoldingPageModel} from '../../models/close-holding-page-model';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {MagellanColorService} from '../../services/magellancolor.service';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import{CurrencyService}from '../../services/currency.service';
import {PerformanceService} from '../../services/performance.service';
import {DateService} from '../../services/date.service';
import { BiService } from '../../services/BiService.service';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import {ChartTypeComponent} from '../chart-type/chart-type.component';

@Component({
    selector: 'factors-calendar-year-return-chart',
    templateUrl: './factors-calendar-year-return-chart.component.html'
})
export class FactorsCalendarYearReturnChartComponent implements OnInit {

    chartAttributes: any;
    chartCategories: any;
    dataSource: any;
    CalanderYearReturnChartLoader:boolean=true;
    currentIndex:any;
    toollbarTitle:any;
    popupDataSource:any;
    defaultActiveChart = "dragcolumn2D";
    chart:any;
    colors : any;
    constructor(private closeHoldingService: CloseHoldingService,private indexMarkerService:IndexMarkerService, private exportService: ExportToExcelService
        ,private publishIndexMarkerService:PublishIndexMarkerService, private magellanColorService : MagellanColorService
    ,private dialog: MatDialog,private currencyService:CurrencyService,private performanceService: PerformanceService,
    private dateService: DateService,private zone:NgZone,private biService: BiService,
    private idwChartExportService: IDWChartExportService) { }
    @Input() asofDates : any;
    indexMarkers : any;  
    currencyCode : string;
    returnType:any;

    ngOnInit(){    
        this.indexMarkers = this.indexMarkerService.getIndexMarkers();
        this.currencyCode = this.currencyService.getCurrencyCode();
        this.asofDates = this.dateService.getCalendarDate().end.substring(0,10); 
        this.currentIndex = 0; 
        this.toollbarTitle = "Calendar Year Returns -- "; 
        this.returnType = 'T';
        this.dataSource= {};
        this.colors = this.magellanColorService.getCurrentColorSchema();
            this.chartSetup();
       
        this.magellanColorService.colorsPublish().subscribe(color => { 
          this.chartAttributes.paletteColors = color;  
          });
    }   
    
    chartInitialize(){
        return (eve) => { 
            this.zone.run(() => {
                this.chart =eve.sender;
            });  
            
        };
          
      }
    
      events = {
        initialized: this.chartInitialize()
    }

    private chartSetup() {
        var oneIndexMarkers:string[] = [];
        oneIndexMarkers.push(this.indexMarkers[this.currentIndex]);
        this.toollbarTitle = "Calendar Year Returns" 
        this.chartAttributes ={
            "caption": "Calendar Year Returns",
            "paletteColors": this.colors,
            "bgColor": "#ffffff",
            "showAlternateHGridColor": "0",
            "showBorder": "0",
            "showCanvasBorder": "0",
            "baseFontColor": "#333333",
            "baseFont": "Helvetica Neue,Arial",
            "captionFontSize": "14",
            "subcaptionFontSize": "14",
            "subcaptionFontBold": "0",
            "usePlotGradientColor": "0",
            "toolTipColor": "#ffffff",
            "toolTipBorderThickness": "0",
            "toolTipBgColor": "#000000",
            "toolTipBgAlpha": "80",
            "toolTipBorderRadius": "2",
            "toolTipPadding": "5",
            "legendBgAlpha": "0",
            "legendBorderAlpha": "0",
            "legendShadow": "0",
            "legendItemFontSize": "10",
            "legendItemFontColor": "#666666",
            "legendCaptionFontSize": "9",
            "divlineAlpha": "100",
            "divlineColor": "#999999",
            "divlineThickness": "1",
            "divLineDashed": "1",
            "divLineDashLen": "1"
        }
            
        this.performanceService.getCalendarReturnsChart(this.asofDates,this.indexMarkers,
            this.currencyCode,this.returnType);
        this.performanceService.calendarReturnsChartLoaded.subscribe(res =>{
            this.dataSource["categories"] = res["categories"];
            this.dataSource["dataset"] = res["multiDataset"];
            this.dataSource["chart"] = this.chartAttributes;
            this.popupDataSource={
                "title":this.toollbarTitle,
                "type": this.defaultActiveChart,
                "dataSource": this.dataSource                    
                }
        });   
          
        
        this.CalanderYearReturnChartLoader=false;         
        }

        ExportXls(event):void{
            var event: any = {eventName: "Factors Calendar Years Returns"};
            this.biService.addEvent(event);
            var inputData = {
              "exportType": "xls",
              "code": "performance-getCalendarReturnsChartData",
              "xlsFileName": "calendarYearsReturnsRawData",
              "asOfDate":this.asofDates,
              "indexMarkers": this.indexMarkers,
              "currencyCode": this.currencyCode,
              "returnType": this.returnType
            };
            this.idwChartExportService.export(inputData);
        }
    
        ExportPNG(event):void{
            var event: any = {eventName: "Calendar Years Returns TO PNG"};
            this.biService.addEvent(event);
            this.popupDataSource.dataSource.chart.showlegend = "1";
            var inputData = {
               "exportType": "png",
               "legend": false,
               "dataSource": this.popupDataSource.dataSource,
               "chartid" : "factorsCalendarYearsReturnsChartExport",
               "type" : this.defaultActiveChart,
               "targetDiv" : "exportPngDiv",
               "chartReference": this.chart,
               "pngFileName" : "factorsCalendarYearsReturns",
               "chartTitle": this.toollbarTitle,
               "isIndexLegend": false,
               "chartDate": this.asofDates
            };
            this.idwChartExportService.export(inputData);
          }

          openWidgetDialog():void{
            var event: any = {eventName: "Factors Calendar Year Return help dialog open"};
            this.biService.addEvent(event);
            let dialogRef = this.dialog.open(FactorsCalendarYearReturnChartComponentWidgetDialog, {
                width: '800px' , 
                data: this.indexMarkers     
              });
          }
    
        openDialog(): void{ 
            var event: any = {eventName: "Factors Calendar Year Returns dialog open"};
            this.biService.addEvent(event);
            this.popupDataSource.dataSource.chart.showlegend = "1"; 
            let dialogRef = this.dialog.open(FactorsCalendarYearReturnChartComponentDialog, {
                width: '80%',
                height: '85%',
            data: this.popupDataSource
            });
        }
        
        }

        @Component({
            selector: 'factors-calendar-year-return-chart-dialog',
            templateUrl: './factors-calendar-year-return-chart.component.dialog.html'
        })
        export class FactorsCalendarYearReturnChartComponentDialog{
        
            constructor(
            public dialogRef: MatDialogRef<FactorsCalendarYearReturnChartComponentDialog>,
            @Inject(MAT_DIALOG_DATA) public data: any) { }
        
            onClose(): void {
                this.data.dataSource.chart.showlegend = "0";
                this.dialogRef.close();
          }
        }
        
        
        @Component({   
            templateUrl: './factors-calendar-year-return-chart.component.widget.dialog.html'
          })
          export class FactorsCalendarYearReturnChartComponentWidgetDialog{
          
          @Input() indexes: string;
          constructor(
          public dialogRef: MatDialogRef<FactorsCalendarYearReturnChartComponentWidgetDialog>,
          @Inject(MAT_DIALOG_DATA) public data: any) {
            this.indexes="";
             data.forEach(element => {
                 if( this.indexes.length<3)
                 {
                    this.indexes=element;
                 }else{
                this.indexes += ", "+ element;
                 }
             });   
           }
          
          onClose(): void {
              this.dialogRef.close();
          }
          }

   
    
    
    

