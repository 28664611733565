import { Component,ViewChild,OnInit } from '@angular/core';
import {CloseTopBottomHoldingsChartComponent} from '../close-holdings-top-bottom-holdings-chart/close-holdings-top-bottom-holdings-chart.component';
import {CloseTopLevelSectorChartComponent} from '../close-holdings-top-level-sector-chart/close-holdings-top-level-sector-chart.component';
import {FactorsDrawdownChartComponent} from '../factors-drawdown-chart/factors-drawdown-chart.component';
import {FactorsCalendarYearReturnChartComponent} from '../factors-calendar-year-return-chart/factors-calendar-year-return-chart.component';
import {FactorsCalendarYearReturnGridComponent} from '../factors-calendar-year-return-grid/factors-calendar-year-return-grid.component';
import {FactorsRiskReturnGridComponent} from '../factors-risk-return-grid/factors-risk-return-grid.component';
import {FactorsReturnTimePeriodGridComponent} from '../factors-return-time-period-grid/factors-return-time-period-grid.component';
import {FactorsPerformanceRatiosGridComponent} from '../factors-performance-ratios-grid/factors-performance-ratios-grid.component';
import {FactorsCumulativeTotalReturnChartComponent} from '../factors-cumulative-total-return-chart/factors-cumulative-total-return-chart.component';
import {CloseCurrencyWeightsChartComponent} from '../close-holdings-currency-weights-chart/close-holdings-currency-weights-chart.component';
import {CloseRankedHoldingsGridComponent} from '../close-holdings-ranked-holdings-grid/close-holdings-ranked-holdings-grid.component';
import {CloseHoldingPageModel} from '../../models/close-holding-page-model';
import {CloseHoldingsPositionGridComponent} from '../close-holdings-position-grid/close-holdings-position-grid.component';
import * as moment from 'moment';
import {DateService} from '../../services/date.service';
@Component({
  selector: 'factors-page',
  templateUrl: './factors-page.component.html'
})

export class FactorsPageComponent implements OnInit{ 

  closeHoldingPageModel : CloseHoldingPageModel;

  @ViewChild(CloseTopBottomHoldingsChartComponent,{static: false}) topBottomHoldingsChartComponent;
  @ViewChild(FactorsDrawdownChartComponent,{static: false}) factorsDrawdownChartComponent;
  @ViewChild(FactorsCalendarYearReturnChartComponent,{static: false}) factorsCalendarYearReturnChartComponent;
  @ViewChild(FactorsCalendarYearReturnGridComponent,{static: false}) factorsCalendarYearReturnGridComponent;
  @ViewChild(CloseCurrencyWeightsChartComponent,{static: false}) currencyWeightsChartComponent;
  @ViewChild(CloseRankedHoldingsGridComponent,{static: false}) rankedHoldingsGridComponent;
  @ViewChild(CloseHoldingsPositionGridComponent,{static: false}) holdingsPositionGridComponent;
 
  constructor(private dateService: DateService){}
  ngOnInit(){
    this.dateService.calendarDatePublish().subscribe(dateRange=>{
      this.closeHoldingPageModel.asofdate =this.dateService.changeDateFormat(dateRange.end);
      this.topBottomHoldingsChartComponent.update(this.closeHoldingPageModel );
      this.factorsDrawdownChartComponent.update(this.closeHoldingPageModel );
      this.factorsCalendarYearReturnChartComponent.update(this.closeHoldingPageModel );
      this.factorsCalendarYearReturnGridComponent.update(this.closeHoldingPageModel);
      this.currencyWeightsChartComponent.update(this.closeHoldingPageModel);
      this.rankedHoldingsGridComponent.update(this.closeHoldingPageModel);
      this.holdingsPositionGridComponent.update(this.closeHoldingPageModel);
    });
  }
  onNotify(closeHoldingPageModel: CloseHoldingPageModel):void{      
    this.closeHoldingPageModel = closeHoldingPageModel;
    this.topBottomHoldingsChartComponent.update(this.closeHoldingPageModel );
    this.factorsDrawdownChartComponent.update(this.closeHoldingPageModel );
    this.factorsCalendarYearReturnChartComponent.update(this.closeHoldingPageModel);
    this.factorsCalendarYearReturnGridComponent.update(this.closeHoldingPageModel);
    this.currencyWeightsChartComponent.update(this.closeHoldingPageModel);
    this.rankedHoldingsGridComponent.update(this.closeHoldingPageModel);
    this.holdingsPositionGridComponent.update(this.closeHoldingPageModel);
    
  } 
 
 ExportXls(event):void{
   this.holdingsPositionGridComponent.ExportXls();
 }
 SelectHoldingsColums(event):void{
   this.holdingsPositionGridComponent.refreshGrid(event);
   
 }
}