import { Injectable }    from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvSpecific } from '../models/env-specific';
import { environment } from '../../environments/environment';
import {LogDetails} from './../models/log-details';
import { ResponseModel } from '../models/landing-page-model';





@Injectable()
export class GeographicalInformationService {
  private headers = new Headers({'Content-Type': 'application/json'});
  constructor( private httpClient: HttpClient) { } 
  
  getLocation(asofdate: any): Promise<LogDetails[]>{
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); 
   
 return this.httpClient.post(environment.dataServiceRoot + 'ui/logs/GetTelemetryValues',
        JSON.stringify({            
            AsOfDate: asofdate,
            APIName: "GetSedolDetails"                
          }), 
             {headers: headers})
            .toPromise()
            .then(response =>{ 
              var result = response as ResponseModel;
                return result.data as LogDetails[];
                })
            .catch(this.handleError);
   
  }
  
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}