import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {BenchmarksService} from '../../services/benchmarks.service';
import {ExpandedElement, ExpendableDataService} from '../../services/expendable-data.service';
// tslint:disable-next-line:import-blacklist
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {Benchmark, BenchmarkParams} from '../../models/benchmark';
import {throwError} from 'rxjs/internal/observable/throwError';
import {DownloadService} from '../../services/download.service';

@Component({
  selector: 'app-benchmarks-page',
  templateUrl: './benchmarks-page.component.html',
  styleUrls: ['./benchmarks-page.component.scss'],
  providers: [ExpendableDataService]
})
export class BenchmarksPageComponent implements OnInit {
  date$ = new BehaviorSubject<string>('');
  marker$ = new BehaviorSubject<string[]>([]);
  // error$ = new BehaviorSubject<Error | null>(null);
  // reload$ = new BehaviorSubject(null);
date : any;
marker :any;
show:boolean = true;
iconClass:any = 'icon-minus noTextDecoration';
cssClass:any = 'showRow';
  indexMarkers$ = this.benchmarksService.getAvailableIndexMarkers$();

  // dataSource$: Observable<Benchmark[]> = combineLatest([
  //   this.date$,
  //   this.marker$,
  //   this.reload$
  // ]).pipe(
  //   filter(([AsOfDate, IndexMarker]) => !!AsOfDate && !!IndexMarker && !!IndexMarker.length),
  //   switchMap(([AsOfDate, IndexMarker]) => this.fetchBenchmarks$(IndexMarker.map(im => ({
  //     IndexMarker: im,
  //     AsOfDate
  //   })))),
  // );

  // expandedElement$: Observable<ExpandedElement<Benchmark[]>> = this.expendableDataService.getData$();

  // data$ = combineLatest([
  //   this.expandedElement$,
  //   this.dataSource$,
  //   this.error$
  // ]).pipe(
  //   map(([expandedElement, dataSource, error]) => ({
  //     expandedElement, dataSource, error
  //   }))
  // );

  // updateExpandableElement(id: BenchmarkParams) {
  //   this.expendableDataService.fetchData(id);
  // }

  constructor(
    private benchmarksService: BenchmarksService,
    private expendableDataService: ExpendableDataService<Benchmark[]>,
    private downloadService: DownloadService, private cdref: ChangeDetectorRef
  ) {
    // this.expendableDataService.init((params) => {
    //   this.error$.next(null);
    //   return this.benchmarksService.fetchBenchmarks$(params).pipe(
    //     catchError((error) => {
    //       this.error$.next(error);
    //       return throwError(error);
    //     }),
    //   );
    // });
  }

  ngOnInit(): void {}

  dateChange(date: string) {
    this.date$.next(date);
    this.benchmarksService.publishDateOrIndexMarker(date);
    this.date = date;
  }

  markerChange(marker: string[]) {
    this.marker$.next(marker);
    this.benchmarksService.publishDateOrIndexMarker(marker);
    this.marker = marker;
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  // fetchBenchmarks$(params: BenchmarkParams | BenchmarkParams[]) {
  //   this.error$.next(null);
  //   return this.benchmarksService.fetchBenchmarksGroups$(params).pipe(
  //     catchError((error) => {
  //       this.error$.next(error);
  //       return of([]);
  //     })
  //   );
  // }
  // retry() {
  //   this.reload$.next(new Date());
  // }

  // async export(benchmark: Benchmark) {
  //   const bms = await this.benchmarksService.fetchBenchmarks$({
  //     AsOfDate: benchmark.asOfDate,
  //     IndexMarker: benchmark.indexMarker
  //   }).toPromise();
  //   const data = this.benchmarksService.benchmarksToDetails(bms);
  //   this.downloadService.download(data, benchmark.indexMarker);
  // }

  toggle() 
  {
    this.show = !this.show;
    if(this.show)  
    {
      this.iconClass = "icon-minus noTextDecoration ";
      this.cssClass="showRow";        
    }
    else
    {
       this.iconClass = "icon-plus noTextDecoration ";
      this.cssClass="hideRow";      
    }       
  }

}
