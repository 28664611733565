import { Component, OnInit ,Input, Inject,NgZone } from '@angular/core';
import { ValuesService } from '../../services/values.service';
import {Subscription} from 'rxjs'
import { IndexMarkerService } from '../../services/indexmarker.service';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { ValuePageModel } from '../../models/value-page-model';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {MagellanColorService} from '../../services/magellancolor.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { BiService } from '../../services/BiService.service';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import { ValuesGrowthOfUnit } from '../../models/values-growth-of-unit-model';
import { DateService } from '../../services/date.service'; 
import { saveAs } from 'file-saver/FileSaver';
import{Chart} from 'chart.js';
import { Buffer } from 'buffer';
@Component({
  selector: 'values-growth-of-unit-price',
  templateUrl: './values-growth-of-unit-price.component.html'
})
export class ValuesGrowthofUnitPriceComponent {

  
  indexMarkers : any;
  currencyCode : string;  
  timePeriod : any;
  fromDate : any;
  chartAttributes: any;  
  dataSource: any;  
  toDate : any;
  returnType : any;
  growthofunitData : any;
  GOUPriceLoader:boolean=true;
  colors : any;
  popupDataSource : any;
  chart:any;
  dateFormat: string;
  checkLoadFirstTime: boolean;
  dateFormatUK: boolean;
  dateFormatUSA: boolean;
  tempData: any;
  growthofUnitPrice:any;
  constructor(private valuesService: ValuesService, private exportService: ExportToExcelService
    ,private publishIndexMarkerService:PublishIndexMarkerService ,private indexMarkerService:IndexMarkerService 
    , private magellanColorService : MagellanColorService,private dialog: MatDialog,private biService: BiService,
    private zone:NgZone,private idwChartExportService: IDWChartExportService, private dateService: DateService ) { }
  
   openDialog(): void{ 
    var event: any = {eventName: "GOU Price dialog open"};
    this.biService.addEvent(event);
    let dialogRef = this.dialog.open(ValuesGrowthofUnitPriceComponentDialog, {
      width: '80%',
      height: '85%',
      data: this.growthofUnitPrice
    });
  }

  openWidgetDialog():void{
    var event: any = {eventName: "GOU Price help dialog open"};
    this.biService.addEvent(event);
    let dialogRef = this.dialog.open(ValuesGrowthofUnitPriceComponentWidgetDialog, {
        width: '800px' , 
        data: this.indexMarkers     
      });
  }

  chartInitialize(event){
    return (eve, arg) => { 
        this.zone.run(() => {
            this.chart =eve.sender;
        });  
        
    };
      
  }

  events = {
    initialized: this.chartInitialize(event)
}

  ngOnInit() {
    this.dateFormat=this.dateService.getDateFormat();
    this.dateService.dateFormatPublish().subscribe(result=>
      {
        this.dateFormat=result;
        this.bindGrowthofUnit(this.tempData);
      }
    );
    this.indexMarkers = this.indexMarkerService.getIndexMarkers();
        this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{
      this.GOUPriceLoader=true; 
      this.indexMarkers=result;
      this.chartSetup(result);
    });

    this.colors = this.magellanColorService.getCurrentColorSchema().split(',');
    this.magellanColorService.colorsPublish().subscribe(color => {  
      this.colors= color.split(',');  
      
      for(var i=0;i<this.indexMarkers.length;i++){
        const canvas = <HTMLCanvasElement> document.getElementById('growthofUnitPrice');
        const ctx = canvas.getContext('2d');
        var gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0,this.colors[i] );
        gradient.addColorStop(0.7, 'rgba(255, 255, 255, 0)');
        gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
        this.growthofUnitPrice .data.datasets[i].backgroundColor=gradient;
        this.growthofUnitPrice .data.datasets[i].borderColor =this.colors[i];
        this.growthofUnitPrice.update(); 
      }  
    });
         
  }


  update(valuePageMode:ValuePageModel){
    this.GOUPriceLoader=true; 
    this.returnType = 'PRICE';  
    this.toDate = valuePageMode.selectedDates.end;
    this.fromDate = valuePageMode.selectedDates.start;         
    this.timePeriod = valuePageMode.timePeriod.toUpperCase();
    this.currencyCode = valuePageMode.currencyCode;
    this.chartSetup(this.indexMarkers);
  }


  private chartSetup(indexMarkers:any) {
    this.valuesService.getGrowthofUnitByValues( this.fromDate,this.toDate,indexMarkers ,this.currencyCode,this.timePeriod,this.returnType).then(data => {      
      this.growthofunitData = data;  
      console.log(this.growthofunitData) 
      //  if(0 < data.data.growthOfUnitMultiDataset.length){   
         this.checkLoadFirstTime=true;
       this.bindGrowthofUnit(this.growthofunitData); 
       this.GOUPriceLoader=false; 
    },onerror =>{
        this.GOUPriceLoader =false;       
     });

    // this.chartAttributes = {
    //     "theme": "fint",
    //     "exportShowMenuItem": "0",
    //     "exportenabled": "1",
    //     "exportatclient": "0",
    //     "paletteColors": this.colors ,
    //     "bgColor": "#ffffff",
    //     "bgAlpha": "0",
    //     "canvasBorderAlpha": "0",
    //     "canvasBgColor": "#ffffff",
    //     "showBorder": "0",
    //     "showCanvasBorder": "0",
    //     "divlineThickness": "1",
    //     "yAxisMinValue": "85",
    //     "anchoralpha": "100",
    //     "anchorgbalpha": "50",
    //     "linethickness": "1",
    //     "showvalues": "0",
    //     "formatnumberscale": "0",
    //     "anchorradius": "2",
    //     "divlinecolor": "#c0c0c0",
    //     "divlinealpha": "30",
    //     "divlineisdashed": "0",
    //     "bgcolor": "FFFFFF",
    //     "showalternatehgridcolor": "0",
    //     "labelpadding": "10",
    //     "canvasborderthickness": "1",
    //     "legendshadow": "0",
    //     "showborder": "0",
    //     "showLegend": "0",
    //     "xAxisLineColor": "#c0c0c0",
    //     "yAxisLineColor": "#c0c0c0"
    // };  

  }
  bindGrowthofUnit(growthofunitData: any){
    if(growthofunitData.data==null){
      this.growthofUnitPrice.data.datasets[0].data = [];
      this.growthofUnitPrice.update();
    }else{
      this.changeDateFormat(growthofunitData);
    for(var i=0;i<growthofunitData.data.dataSet.length;i++){
      const canvas = <HTMLCanvasElement> document.getElementById('growthofUnitPrice');
      const ctx = canvas.getContext('2d');
      var gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0,this.colors[i] );
      gradient.addColorStop(0.7, 'rgba(255, 255, 255, 0)');
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
      growthofunitData.data.dataSet[i].borderColor =this.colors[i];
      growthofunitData.data.dataSet[i].backgroundColor =gradient;
    }
    if (this.growthofUnitPrice) this.growthofUnitPrice.destroy();
    this.growthofUnitPrice = new Chart('growthofUnitPrice', {
      type: 'line',
  data: {
    labels: growthofunitData.data.xLabe1,
    showInLegend: true,
    datasets: growthofunitData.data.dataSet,
  },
  options: {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      xAxes: [{
        time: {
          unit: 'date'
        },
        gridLines: {
          display: true,
          drawBorder: false
        },
        ticks: {
          maxTicksLimit: 15,
          fontColor: '#000000',
          fontFamily: 'Roboto',
          fontSize: 8,
          maxRotation: 90,
          minRotation: 90,
        }
      }],
      yAxes: [{
        ticks: {
          min: growthofunitData.data.yAxisMinValue,
          max : growthofunitData.data.yAxisMaxValue,
          padding: 5,
          fontColor: '#000000',
                    fontFamily: 'Roboto',
                    fontSize: 9,
          callback: function (value, index, values) {
            return (value);
          }
        },
        gridLines: {
          color: "rgb(234, 236, 244)",
          zeroLineColor: "rgb(193, 193, 193)",
          drawBorder: false,
          borderDash: [0],
          zeroLineBorderDash: [0]
        },
        // ticks: {
        //   fontColor: '#000000',
        //   fontFamily: 'Roboto',
        //   fontSize: 10
        // }
      }],
    },
    legend: {
      display: false,
      lineWidth: 0,
      position: 'top',
      labels: {
        filter: function (legendItem, data) {
          return legendItem.index != 1
        }
      }
    },
    tooltips: {
      backgroundColor: "rgb(255,255,255)",
      bodyFontColor: "#858796",
      titleMarginBottom: 10,
      titleFontColor: '#6e707e',
      titleFontSize: 14,
      borderColor: '#dddfeb',
      borderWidth: 1,
      xPadding: 15,
      yPadding: 15,
      displayColors: false,
      intersect: false,
      mode: 'index',
      caretPadding: 10,
      callbacks: {
        label: function (tooltipItem, chart) {
          var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
          return datasetLabel + ':' + tooltipItem.yLabel;
        }
      }
    }
  },
  plugins: [{
    afterDraw: function(chart) {
      if (chart.data.datasets[0].data.length == 0) {
        // No data is present
        var ctx = chart.chart.ctx;
        var width = chart.chart.width;
        var height = chart.chart.height
        chart.clear();
        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = "bold 20px Roboto 'Helvetica Nueue'";
        ctx.fillStyle = "black";
         ctx.fillText('No data to display', width / 2, height / 2);
         ctx.restore();
    }
    }
}]
});
    }
    // this.tempData=data.data;
    // this.chartAttributes["yAxisMinValue"] = data.data.yAxisMinValue;
    // this.chartAttributes["yAxisMaxValue"] = data.data.yAxisMaxValue;
    // this.changeDateFormat(data.data);
    // this.dataSource = {
    //   "chart": this.chartAttributes,
    //   "categories": data.data.growthOfUnitcategories,
    //   "dataset": data.data.growthOfUnitMultiDataset
    // };
    // this.GOUPriceLoader=false; 
    // this.popupDataSource={     
    //   "dataSource": this.dataSource,
    //   "legend": this.indexMarkerService.getIndexMarkersNames(), 
    //   "colors":this.colors.split(','),
    //   "asOfDate": this.toDate      
    // }
  }

 private changeDateFormat(data: any) {
    if (this.dateFormat == "UK" && this.checkLoadFirstTime == false) {
      if (this.dateFormatUK == true) {
        for (var i = 0; i < data.growthOfUnitcategories[0].category.length; i++) {
          data.growthOfUnitcategories[0].category[i].label = this.dateService.changeDateFormatUK(data.growthOfUnitcategories[0].category[i].label);
        }
        for (var i = 0; i < data.growthOfUnitMultiDataset.length; i++) {
          for (var j = 0; j < data.growthOfUnitMultiDataset[i].data.length; j++) {
            let splitTooltext = data.growthOfUnitMultiDataset[i].data[j].tooltext.split(",");
            data.growthOfUnitMultiDataset[i].data[j].tooltext = splitTooltext[0] + "," + this.dateService.changeDateFormatUK(splitTooltext[1]) + "," + splitTooltext[2] + "," + splitTooltext[3];;
          }
        }
      }
      this.dateFormatUK = false;
      this.dateFormatUSA = true;
    }
    else {
      if (this.checkLoadFirstTime == false && this.dateFormatUSA == true) {
        for (var i = 0; i < data.growthOfUnitcategories[0].category.length; i++) {
          data.growthOfUnitcategories[0].category[i].label = this.dateService.changeDateFormatUSA(data.growthOfUnitcategories[0].category[i].label);
        }
        for (var i = 0; i < data.growthOfUnitMultiDataset.length; i++) {
          for (var j = 0; j < data.growthOfUnitMultiDataset[i].data.length; j++) {
            let splitTooltext = data.growthOfUnitMultiDataset[i].data[j].tooltext.split(",");
            data.growthOfUnitMultiDataset[i].data[j].tooltext = splitTooltext[0] + "," + this.dateService.changeDateFormatUSA(splitTooltext[1]) + "," + splitTooltext[2] + "," + splitTooltext[3];;
          }
        }
      }
      this.dateFormatUK = true;
      this.dateFormatUSA = false;
      this.checkLoadFirstTime = false;
    }
  }
  private reallignDataWithIndexMarkers(data: ValuesGrowthOfUnit){
    var associativeDatasets = [];
    for(var counter = 0 ; counter< data["data"].growthOfUnitMultiDataset.length;counter++){
      associativeDatasets[data["data"].growthOfUnitMultiDataset[counter]["seriesName"]]=
      data["data"].growthOfUnitMultiDataset[counter];
    }
    data["data"].growthOfUnitMultiDataset = [];
    for(var indexmarker of this.indexMarkers){
      data["data"].growthOfUnitMultiDataset.push(associativeDatasets[indexmarker]);
    }
    return data;
}
    ExportXls(event):void{
      var event: any = {eventName: "Export GOU Price", inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode},{Name : "TimePeriod" , Value: this.timePeriod},{Name : "ReturnType" , Value: this.returnType}]};
      this.biService.addEvent(event);
    var inputData = {
      "exportType": "xls",
      "code": "value-getGrowthofUnitByValuesData",
      "xlsFileName": "GrowthOfUnitPriceRawData",
      "fromDate": this.fromDate,
      "toDate": this.toDate,
      "indexMarkers": this.indexMarkers,
      "currencyCode": this.currencyCode,
      "timePeriod": this.timePeriod,
      "returnType": this.returnType
    };
    this.idwChartExportService.export(inputData);
  }

  ExportPNG(event):void{
    var event: any = {eventName: "Export GOU Price Chart TO PNG",  inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode},{Name : "TimePeriod" , Value: this.timePeriod},{Name : "ReturnType" , Value: this.returnType}]};
    this.biService.addEvent(event);
  //   this.popupDataSource.dataSource.chart.showlegend = "1";
  //   var legendClassNames = ["legend","legendcolor","legendlabel"];
  //   var inputData = {
  //     "exportType": "png",
  //     "legend": false,
  //     "dataSource": this.popupDataSource.dataSource,
  //     "chartid" : "growthofUnitPriceExport",
  //     "type" : "msline",
  //     "targetDiv" : "exportPngDiv",
  //     "chartReference": this.chart,
  //     "pngFileName" : "GrowthOfUnitPriceRawData",
  //     "chartTitle": "Growth of Unit (Price)",
  //     "chartDate": this.toDate,
  //     "isIndexLegend": false,
  //     "indexLegendNames": this.popupDataSource.legend,
  //     "indexLegendColors": this.popupDataSource.colors,
  //     "indexLegendClassNames" : legendClassNames
  //  };
  //  this.idwChartExportService.export(inputData);
  //  this.popupDataSource.dataSource.chart.showlegend = "0";
  this.growthofUnitPrice.options.legend.display =true;
  this.growthofUnitPrice.update({duration :0});
  var canvas = <HTMLCanvasElement> document.getElementById('growthofUnitPrice'); 
     var imageUrl = canvas.toDataURL("image/png");
     var base64 = imageUrl.split("base64,")[1];
     var bufferArr = Buffer.from(base64,'base64');
     var blob = new Blob([bufferArr], { type: 'image/png' });
     saveAs(blob, "GrowthOfUnit.png"); 
     this.growthofUnitPrice.options.legend.display =false;
  this.growthofUnitPrice.update({duration :0});
  }
  
}


@Component({
    selector: 'values-growth-of-unit-price-dialog',
	templateUrl: './values-growth-of-unit-price.component.dialog.html'
})
export class ValuesGrowthofUnitPriceComponentDialog{

    constructor(
    public dialogRef: MatDialogRef<ValuesGrowthofUnitPriceComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    ngOnInit(){
      var myLineChart = new Chart('data',{
        type: 'line',
        data: this.data.data,
        options: this.data.options
    });
    myLineChart.update()
          }
    onClose(): void {
      this.data.options.legend.display = false; 
        this.dialogRef.close();
  }
}

@Component({
templateUrl: './values-growth-of-unit-price.component.widget.dialog.html'
})
export class ValuesGrowthofUnitPriceComponentWidgetDialog{

constructor(
public dialogRef: MatDialogRef<ValuesGrowthofUnitPriceComponentWidgetDialog>,
@Inject(MAT_DIALOG_DATA) public data: any) { }

onClose(): void {
    this.dialogRef.close();
}
}