import { Component, OnInit,Inject,NgZone, ViewChild } from '@angular/core';
import {PerformanceIndicatorsReportService} from '../../services/performance-indicators-report.service';
import {DateService} from '../../services/date.service';
import * as moment from 'moment';

@Component({
  selector: 'performance-indicators-report',
  templateUrl: './performance-indicators-report.component.html'
})

export class PerformanceIndicatorsReportComponent implements OnInit { 
  
    title: string = "API Test Report";
    dataProfiling:string = "Data Validation";
    dataProfiles: any[] = [];
    subjects: any[] = [];
    dataStatus:string = "Data Validation Status";
    dataValidations:any[] = [];
    indicatorsReport :any;
    currentDate :any;
    selectedWave:any;
    selectedEmptyIndexArray:string[] = [];
    targetUser:any;
    showScreen:boolean = false;
    showEmptyColumnArray:boolean[] = [];
    emptyColumnsArray:any[] = [];
    entryToInitialize:string = "";
    currentApiMethodNameArray:string[] = [];
    indexsToSelect :any[] = [];
    performanceIndicatorLoader:boolean =false;
    performanceIndicatorEmptyColumnsLoaderArray:boolean[] =[];
    constructor(private dateService: DateService, private performanceIndicatorsReportservice: PerformanceIndicatorsReportService){
        
    }
    ngOnInit(){
        this.currentDate = moment(this.dateService.endDate).format("YYYY-MM-DD");
         this.dateService.calendarDatePublish().subscribe(dateRange => {
           this.currentDate = moment(dateRange.end).format("YYYY-MM-DD");
         });
    } 

    onGetResults(){
        this.performanceIndicatorLoader = true;
        var that = this;
        this.showScreen = false;
        this.performanceIndicatorsReportservice.getPerformanceIndicators(this.dateService.getCalendarDate().end ,
         this.targetUser,this.selectedWave).then(indicatorsReport =>{
          this.performanceIndicatorLoader = false;
                this.dataProfiles = indicatorsReport.data.dataProfiles;
                this.subjects = indicatorsReport.data.subjects;
                this.dataValidations = indicatorsReport.data.dataValidations;
                this.dataValidations.forEach(function(dataValidation,index){
                  that.performanceIndicatorEmptyColumnsLoaderArray[dataValidation.apiMethodName] = false;
                  dataValidation["availableIndexes"].forEach(function(aemptyIndex,aIndex) {
                    that.showEmptyColumnArray[aemptyIndex["indexMarker"]] = false;
                    this.showScreen = true;
                  });
                  
            });
    }).catch(err=>{
      this.performanceIndicatorLoader = false;
      this.showScreen = true;
    });
  }

    toggleEmptyColumns(apiMethodName,indexmarker:any){
      var that = this;
      this.performanceIndicatorEmptyColumnsLoaderArray[apiMethodName] = true;
      this.currentApiMethodNameArray[apiMethodName] = undefined;
      this.showEmptyColumnArray[indexmarker] = (this.showEmptyColumnArray[indexmarker] == false)?true:false;
      if(""!= indexmarker && undefined == this.emptyColumnsArray[apiMethodName+indexmarker] ){
      this.performanceIndicatorsReportservice.getPerformanceIndicatorsEmptyColumns(
        this.currentDate,indexmarker,apiMethodName)
        .then(res=>{
          that.performanceIndicatorEmptyColumnsLoaderArray[apiMethodName] = false;
                   that.currentApiMethodNameArray[apiMethodName] = apiMethodName + indexmarker;
                   that.emptyColumnsArray[apiMethodName+indexmarker]= res.data;
        })
        .catch(err =>{
          this.performanceIndicatorEmptyColumnsLoaderArray[apiMethodName]= false;
          this.currentApiMethodNameArray[apiMethodName] = undefined;
          that.emptyColumnsArray[that.currentApiMethodNameArray[apiMethodName]] = [];
        })
      }else{
        this.performanceIndicatorEmptyColumnsLoaderArray[apiMethodName] = false;
        this.currentApiMethodNameArray[apiMethodName] = undefined;
        this.emptyColumnsArray[that.currentApiMethodNameArray[apiMethodName]] = [];
      }
    }

    getVisibility(){
      return (this.showScreen == true)?'visvisible':'visHidden';
    }

    SelectEmptyIndex(value,apiMethodName){
      this.entryToInitialize = this.currentApiMethodNameArray[apiMethodName];
       this.selectedEmptyIndexArray[apiMethodName]= value;
       this.toggleEmptyColumns(apiMethodName,value);
    }
}
