import { Component,OnInit ,Inject,NgZone, ViewChild, AfterViewInit} from '@angular/core';
import { PerformanceService } from '../../services/performance.service';
import {PerformancePageModel} from '../../models/performance-page-model';
import {PublishIndexMarkerService} from '../../services/publish-index-marker.service';
import { IndexMarkerService } from '../../services/indexmarker.service';
import {ExportToExcelService} from '../../services/exportToExcel.service';
import {MagellanColorService} from '../../services/magellancolor.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DateService } from '../../services/date.service';
import { IndexReturns } from '../../models/performance-index-returns';
import { ChartModel } from '../../models/performance-chart-model';
import { BiService } from '../../services/BiService.service';
import {IDWChartExportService} from '../../services/idw-chart-export.service';
import {ChartTypeComponent} from '../chart-type/chart-type.component';

@Component({
  selector: 'performance-time-series-chart',
  templateUrl: './performance-time-series-chart.component.html',
})
export class PerformanceTimeSeriesComponent implements OnInit,AfterViewInit {
      
    timeSeriesChartdidFail: boolean=false;
    chartDatadidFail: boolean=false;
    timeseriesreturnsChart: any;   
    TimeSeriesChartLoader:boolean =true;
    asofDates : any;
    indexMarkers : any;  
    currencyCode : string;
    returnType : any;
    timeseriesChart  : any;
    timePeriod : any;
    colors : any;
    chartAttributes : any;
    popupDataSource: any;
    chart:any;
    @ViewChild(ChartTypeComponent,{static: false}) chartTypeComponent;    
    defaultActiveChart: string  = "msColumn2D";
    datasetType = "multiple";
    iscarousel: boolean= false;

    constructor(private dateService: DateService,private performanceService: PerformanceService,
      private publishIndexMarkerService:PublishIndexMarkerService,private indexMarkerService:IndexMarkerService,
       private exportService: ExportToExcelService,private magellanColorService : MagellanColorService,
       public dialog: MatDialog,private biService: BiService,private zone:NgZone,
       private idwChartExportService: IDWChartExportService) { }
    

    openDialog(): void{ 
      var event: any = {eventName: "Performance Time Series Returns dialog open"};
      this.biService.addEvent(event);       
      this.popupDataSource.dataSource.chart.showlegend = "1";     
      let dialogRef = this.dialog.open(PerformanceTimeSeriesComponentDialog, {
        width: '80%',
        height: '90%',
        data: this.popupDataSource
      });
    }

    
    openWidgetDialog(): void{     
      var event: any = {eventName: "Performance Time Series Returns help dialog open"};
      this.biService.addEvent(event);                     
      let dialogRef = this.dialog.open(PerformanceTimeSeriesComponentWidgetDialog, {
        width: '800px'      
      });
    }

    chartInitialize(event){
      return (eve, arg) => { 
          this.zone.run(() => {
              this.chart =eve.sender;
          });  
          
      };
        
    }
  
    events = {
      initialized: this.chartInitialize(event)
  }

    ngOnInit(){   
      this.asofDates= this.dateService.getTransactionDate();
      this.indexMarkers = this.indexMarkerService.getIndexMarkers();

      
       
      this.colors = this.magellanColorService.getCurrentColorSchema();
      this.magellanColorService.colorsPublish().subscribe(color => {
        this.colors=color; 
        this.chartAttributes.paletteColors = this.colors; 
        this.popupDataSource={     
          "dataSource": this.timeseriesreturnsChart,
          "asOfDate": this.asofDates,          
          "colors":this.colors ,
          "chartType"    : this.defaultActiveChart    
        } 
    });
    
   }

   ngAfterViewInit(){
    this.chartTypeComponent.defaultChartType=this.defaultActiveChart;
    this.publishIndexMarkerService.indexMarkerPublish().subscribe(result=>{ 
      this.TimeSeriesChartLoader =true;
      this.indexMarkers=result;
      this.chartSetup(result);
    });
   }
    update(performancePageModel:PerformancePageModel){    
      this.returnType = performancePageModel.returntype;
      this.currencyCode = performancePageModel.currencyCode;
      this.asofDates = performancePageModel.selectedDates.end;
      this.chartSetup(this.indexMarkers);
    }
  
    
	onChartTypeChange(chart: string) {                
    this.defaultActiveChart = chart;       
    this.chartSetup(this.indexMarkers);
} 

    private chartSetup(indexMarkers:any) {     
      this.performanceService.timeSeriesChartLoaded.observers=[];
      this.performanceService.getTimeSeriesChart(this.asofDates,indexMarkers  ,this.currencyCode ,this.returnType)
      this.performanceService.timeSeriesChartLoaded.subscribe(
        (data:ChartModel) => {
          if(null != data){
            if(0< data.multiDataset.length){
              this.bindTimeSeriesChart(data);
            }  else{
              this.timeseriesreturnsChart = {};
              this.TimeSeriesChartLoader =false;   
            } 
          }
          else{
            this.timeseriesreturnsChart = {};
              this.TimeSeriesChartLoader =false; 
          }
           
        });
      this.performanceService.timeSeriesChartFailed.subscribe(
        (didFail: boolean) => this.timeSeriesChartdidFail = didFail
      ); 
    }

     bindTimeSeriesChart(data){
      this.chartAttributes = {
        "paletteColors":  this.colors,
        "baseFontColor": "#000000",
        "baseFont": "sans-serif",
        "showBorder": "0",
        "bgColor": "#ffffff",
        "showShadow": "0",
        "canvasBgColor": "#ffffff",
        "canvasBorderAlpha": "0",
        "divlineAlpha": "100",
        "divlineColor": "#999999",
        "usePlotGradientColor": "0",
        "showplotborder": "0",
        "valueFontColor": "#ffffff",
        "showValues": "0",
        "showHoverEffect": "1",
        "showAlternateHGridColor": "0",
        "chartRightMargin": "-10",
        "divLineColor": "#d1d4d7",
        "showYAxisLine": "1",
        "showXAxisLine": "1",
        "xAxisLineColor": "#c0c0c0",
        "yAxisLineColor": "#c0c0c0",
        "showLegend": "0",
        "showYAxisValues": "1",
        "exportenabled": "1",
        "exportatclient": "0",
        "exportShowMenuItem": "0",
        "showAlternateVGridColor": "0",
        "labelDisplay": "None",
        
        "theme": "fint",        
        "bgAlpha": "0",       
        "showCanvasBorder": "0",
        "divlineThickness": "1",
        "yAxisMinValue": "85",
        "anchoralpha": "100",
        "anchorgbalpha": "50",
        "linethickness": "1",
        "showvalues": "0",
        "formatnumberscale": "0",
        "anchorradius": "2",
        "divlinecolor": "#c0c0c0",
        "divlinealpha": "30",
        "divlineisdashed": "0",
        "bgcolor": "#FFFFFF",
        "showalternatehgridcolor": "0",
        "labelpadding": "10",
        "canvasborderthickness": "1",
        "legendshadow": "0",
        "showborder": "0"       
        }  
        
    this.timeseriesreturnsChart = {                   
      "dataset": data.multiDataset,       
      "categories": data.categories,
      "chart":  this.chartAttributes       
    }  
    this.TimeSeriesChartLoader =false;

    this.popupDataSource={     
      "dataSource": this.timeseriesreturnsChart,
       "colors":this.colors.split(',') ,
      "chartType"    : this.defaultActiveChart,
      "asOfDate": this.asofDates     
    }
  }

    ExportXls(event):void{
      var event: any = {eventName: "Export Peformance Time Series Returns", inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode},{Name : "ReturnType" , Value: this.returnType}]};
      this.biService.addEvent(event);
      var inputData = {
        "exportType": "xls",
        "code": "performance-getTimeSeriesChartData",
        "xlsFileName": "PerformanceTimeSeriesRawData",
        "asOfDate": this.asofDates,
        "indexMarkers": this.indexMarkers,
        "timePeriod": this.timePeriod,
        "currencyCode": this.currencyCode,
        "returnType": this.returnType
      };
      this.idwChartExportService.export(inputData);
    }

    ExportPNG(event):void{
      var event: any = {eventName: "Export Peformance Time Series Returns Chart TO PNG",  inputParameters: [{Name : "CurrencyCode" , Value: this.currencyCode},{Name : "ReturnType" , Value: this.returnType}]};
      this.biService.addEvent(event);
      this.popupDataSource.dataSource.chart.showlegend = "1"; 
      var legendClassNames = ["legend","legendcolor","legendlabel"];
      var inputData = {
        "exportType": "png",
        "legend": false,
        "dataSource": this.popupDataSource.dataSource,
        "chartid" : "timeseriesChartExport",
        "type" : this.defaultActiveChart,
        "targetDiv" : "exportPngDiv",
        "chartReference": this.chart,
        "pngFileName" : "PerformanceTimeSeriesRawData",
        "chartTitle": "Time Series Returns",
        "chartDate": this.asofDates,
        "isIndexLegend": false,
        "indexLegendNames": this.popupDataSource.legend,
        "indexLegendColors": this.popupDataSource.colors,
        "indexLegendClassNames" : legendClassNames
     };
     this.idwChartExportService.export(inputData);
     this.popupDataSource.dataSource.chart.showlegend = "0"; 
    }
}


@Component({
    selector: 'performance-time-series-chart-dialog',
    templateUrl: './performance-time-series-chart.component.dialog.html'
})
export class PerformanceTimeSeriesComponentDialog{

    constructor(
    public dialogRef: MatDialogRef<PerformanceTimeSeriesComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    onClose(): void {
      this.data.dataSource.chart.showlegend = "0"; 
        this.dialogRef.close();
  }
}


@Component({
  selector: 'performance-time-series-chart-dialog',
  templateUrl: './performance-time-series-chart.component.widget.dialog.html'
})
export class PerformanceTimeSeriesComponentWidgetDialog{

  constructor(
  public dialogRef: MatDialogRef<PerformanceTimeSeriesComponentWidgetDialog>,
  @Inject(MAT_DIALOG_DATA) public data: any) { }

  onClose(): void {
      this.dialogRef.close();
}
}