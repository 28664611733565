export class CompanyModel {
  companyId: any;
  companyValue: any;
  companyName: any;
  isActive: any;
  parentCompany: any;
  emailAddress: any;
  phoneNumber: any;
  address: any;

}
export class LicenceBandModel {
  licenceBand: any;
  sedolCount: any;
}

export class APIKeyModel {
  id: any;
  apiId: any;
  apiKeyId: any;
  apiKeyName: any;
  apiKeyDescription: any;
  apiKeyValue: any;
  isEnabled: any;
  stageName: any;
  usagePlanId: any;
  usagePlanKeyId: any;
  usagePlanName: any;
  usagePlanDescription: any;
  burstLimit: any;
  rateLimit: any;
  isTermsCheckReq: any;
  isConfirmed: any;
  companyId: any;
  companyName: any;
  isResend: any;
  isDisabled: boolean;
  licenceBand: any;
  isMasterAPI: any;
  isHistoryAPI: any;
}
export class DataModel {
  apiMethodName: any;
  emailOption: any;
  emailId: any;
  inputParameters: APIFieldsModel[];
}


export class APIFieldsModel {
  apiName: any;
  fieldName: any;
  dataType: any;
  isInputField: any;
  isOutputField: any;
  value: any;
  selected: boolean;
  userid: any;
}

export class EntitlementModel {
  companyId: any;
  companyName: any;
  apiName: any;
  subscriptionStartDate: any;
  subscriptionEndDate: any;
  isActive: any;
  inputParameters: APIFieldsModel[];
  outputParameters: APIFieldsModel[];
}

export class UserProfileModel {
  userName: any;
  password: any;
  email: any;
  phoneNumber: any;
  name: any;
  companyName: any;
  ddsAccountNumber: any;
  gender: any;
  roles: any;
}

export class UserClientDetails {
  userid: any;
  
}

export class ReportTypeModel
{
  reportTypeId : number;
  reportType : any;
}