import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.css']
})
export class ReportTableComponent implements OnInit {
  @Input() columnNames:string[];
  @Input() rowData: any[];
  @Input()  calculation:string='';
  @Input() totalData : any[];
  @Input() caltype:string='';  
  @Input() matrxtype:string='';
  constructor() { }

  ngOnInit(): void {
 console.log(this.calculation);
 console.log(this.caltype);
  }

}
